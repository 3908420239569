import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';

@Injectable({
  providedIn: 'root'
})
export class CostcalculatorsettingdetailsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'costcalculator/costCalculatorDetails.do';

  public costCalculatorDetails(costObjId: string) {
    return this.http.post<MasterPortCostDetails>(this.appUrl, costObjId);
  }


}
