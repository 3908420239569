import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  private appUrl = ShipStreetProps.API_PATH + 'containersupplier/findAllContainerSupplierbyCompany';
  private lstUrl = ShipStreetProps.API_PATH + 'containersupplier/findAllContainerSupplierByOrder';

  constructor(private http: HttpClient) { }

  public findAllContainerSupplierbyCompany() {
    return this.http.get<ContainerSupplier[]>(this.appUrl);
  }

  public findAllContainerSupplierByOrder() {
    return this.http.get<ContainerSupplier[]>(this.lstUrl);
  }
}
