import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterPortVesselOperatorCosts } from 'src/app/model/masterportvesseloperatorcosts.model';

@Injectable({
  providedIn: 'root'
})
export class VesseloperatorcostsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'costcalculator/saveVesselSlotCost.do';

  public saveVesselSlotCost(vocostObj: MasterPortVesselOperatorCosts) {
    return this.http.post<string>(this.appUrl, vocostObj);
  }

}
