import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../utils/rs.constants';
import { SurviceRequests } from '../model/survicerequests.model';

@Injectable({
  providedIn: 'root'
})
export class HelpcentreserviceService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'helpcentre/Savehelpcentre.do';

  public helpsave(serviceRequest: SurviceRequests) {
    return this.http.post<string>(this.appUrl, serviceRequest);
  }

}
