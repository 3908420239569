import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Company } from 'src/app/model/company.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { Jobs } from 'src/app/model/jobs.model';
import { Marketplace } from 'src/app/model/marketplace.model';
import { Candidates } from 'src/app/model/candidate.model';
import { CompanyFollow } from 'src/app/model/companyFollow.model';
import { CompanyIndustry } from 'src/app/model/companyIndustry.model';
import { Dashboard } from 'src/app/model/dashboard.model';
import { Feeds } from 'src/app/model/feeds.model';
import { RecruiterCompany } from 'src/app/model/recruiterCompany.model';
import { FileUploadResponse } from 'src/app/model/fileuploadResponse.model';
import { ContainerCompany } from 'src/app/model/containercompany.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';


@Injectable({
  providedIn: 'root'
})
export class CompanyeditService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'company.do';
  private appUrlEmployee = ShipStreetProps.API_PATH + 'companyEmployeeList.do';
  private appUrlJob = ShipStreetProps.API_PATH + 'companyJobList.do';
  private appUrlCompany = ShipStreetProps.API_PATH + 'companyMarketplaceItemList.do';
  private appUrlFollow = ShipStreetProps.API_PATH + 'companyFollowList.do';
  private companyFeedUrl = ShipStreetProps.API_PATH + 'getFeedsForLoggedCompany.do';
  private updateCompanyDescUrl = ShipStreetProps.API_PATH + 'updateCompanyDescription.do';
  private updateCompanyNamecUrl = ShipStreetProps.API_PATH + 'updateCompanyName.do';
  private updateCompanySummaryUrl = ShipStreetProps.API_PATH + 'updateCompanySummary.do';
  private compIndustryUrl = ShipStreetProps.API_PATH + 'getcompanyindustry.do';
  private compDetailsUrl = ShipStreetProps.API_PATH + 'updateCompanyDetails.do';
  private comEmplistUrl = ShipStreetProps.API_PATH + 'companyEmployeeWaitingList.do';
  private comRecListUrl = ShipStreetProps.API_PATH + 'companyRecruitersWaitingList.do';
  private pubCompUrl = ShipStreetProps.API_PATH + 'postNewCompanyFeed.do';
  private compEmpWaitUrl = ShipStreetProps.API_PATH + 'companyEmloyeeApprove.do';
  private compEmpSaveUrl = ShipStreetProps.API_PATH + 'addCompanyEmployee.do';
  private markadminUrl = ShipStreetProps.API_PATH + 'markcompanyadmin.do';
  private markmoderatorUrl = ShipStreetProps.API_PATH + 'markcompanymoderator.do';
  private companyLogoUploadUrl = ShipStreetProps.API_PATH + 'companyImageUpload.do';
  private updateCompanyLogoUrl = ShipStreetProps.API_PATH + 'updateCompanyLogo.do';
  private feedImageUplaodUrl = ShipStreetProps.API_PATH + 'FeedImageUpload.do';
  private companyPostImageUrl = ShipStreetProps.API_PATH + 'postNewCompanyImage.do';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';
  private enrollUrl = ShipStreetProps.API_PATH + 'containercompany/enrollcontainercompany';
  private primUrl = ShipStreetProps.API_PATH + 'makePrimaryCompany';
  


   public getCompanyByID(companyId: Company) {
    return this.http.post<Company>(this.appUrl , companyId);
  }

    public getCompanyEmployee(companyId: Company) {
    return this.http.post<CompanyEmployee[]>(this.appUrlEmployee , companyId);
  }
    public getCompanyJobList(companyId: Company) {
    return this.http.post<Jobs[]>(this.appUrlJob , companyId);

  }
      public getCompanyMarketplace(companyId: Marketplace) {
    return this.http.post<Marketplace[]>(this.appUrlCompany , companyId);

  }
  public getFollowList(candidateId: Candidates) {
    return this.http.post<CompanyFollow[]>(this.appUrlFollow , candidateId);
  }
  public getCompanyIndustries() {
    return this.http.get<CompanyIndustry[]>(this.compIndustryUrl);
  }
  public getCompanyFeeds(companyId: string) {
    return this.http.post<Dashboard>(this.companyFeedUrl , companyId);
  }

   public updateCompanyName(company: Company) {
    return this.http.post<Company>(this.updateCompanyNamecUrl , company);
  }

   public updateCompanyDescription(company: Company) {
    return this.http.post<Company>(this.updateCompanyDescUrl , company);
  }

  public updateCompanySummary(company: Company) {
    return this.http.post<string>(this.updateCompanySummaryUrl , company);
  }

   public updateCompanyDetails(company: Company) {
    return this.http.post<Company>(this.compDetailsUrl , company);
  }
   public publishcompanypost(newpost: Feeds) {
    return this.http.post<Dashboard>(this.pubCompUrl , newpost);
  }
  public getCompanyEmployeeWaitingList(companyId: string) {
    return this.http.post<CompanyEmployee[]>(this.comEmplistUrl , companyId);
  }
  public getCompanyRecruiterWaitingList(companyId: string) {
    return this.http.post<RecruiterCompany[]>(this.comRecListUrl , companyId);
  }

   public companyEmloyeeApprove(waiting: CompanyEmployee) {
    return this.http.post<CompanyEmployee[]>(this.compEmpWaitUrl , waiting);
  }

   public addCompanyEmployee(compEmp: CompanyEmployee) {
    return this.http.post<CompanyEmployee[]>(this.compEmpSaveUrl , compEmp);
  }

  public markcompanyadmin(compEmp: CompanyEmployee) {
    return this.http.post<string>(this.markadminUrl , compEmp);
  }

  public markcompanymoderator(compEmp: CompanyEmployee) {
    return this.http.post<CompanyEmployee[]>(this.markmoderatorUrl , compEmp);
  }

   public companyLogoUpload(selectedFile: FormData) {
    return this.http.post<FileUploadResponse>(this.companyLogoUploadUrl , selectedFile);
  }

  public updateCompanyLogo(company: Company) {
    return this.http.post<string>(this.updateCompanyLogoUrl , company);
  }

  public postImageUpload( selectedFile: FormData) {
    return this.http.post<FileUploadResponse>(this.feedImageUplaodUrl, selectedFile);
  }

    public postNewCompanyImage(newpost: Feeds) {
    return this.http.post<Dashboard>(this.companyPostImageUrl , newpost);
  }

  public enrollcontainercompany(company: ContainerCompany) {
    return this.http.post<string>(this.enrollUrl , company);
  }

  public makePrimaryCompany(companyId: number) {
    return this.http.post<string>(this.primUrl , companyId);
  }

  viewCountry() {
    return this.http.get<CountryInfo[]>(this.appUrls);
  }
}
