import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalcChargeHeads } from 'src/app/model/calcchargeheads.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class LocalchargeheadsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'calcchargeheads/findAllCalculatorChargeHeads.do';

  findAllCalculatorChargeHeads() {
    return this.http.get<CalcChargeHeads[]>(this.appUrl);
  }
}
