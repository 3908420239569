import { Marketplace } from '../../../model/marketplace.model';
import { MarketplaceOrder } from '../../../model/marketplaceOrder.model';
import { MarketplaceMyorderService } from './marketplace-myorder.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Component({
  selector: 'app-marketplace-myorder',
  templateUrl: './marketplace-myorder.component.html',
  styleUrls: ['./marketplace-myorder.component.css']
})
export class MarketplaceMyorderComponent implements OnInit {
  orderdetails: MarketplaceOrder[];

  constructor(private marketplaceMyorderService: MarketplaceMyorderService , private router: Router) { }

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  ngOnInit() {
    this.marketplaceMyorderService.getMyOrderDetails()
      .subscribe( data => {
        this.orderdetails = data;
     //   console.log(JSON.stringify(this.orderdetails));
    },
     error => {
       this.router.navigate(['']);
        });
  }

  trackMyOrder(orderId) {
       this.router.navigate(['m/trackMyOrder' , orderId]);
  }

}
