import { MyBoxSearchDTO } from './../../model/myboxsearchDTO.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
@Injectable({
  providedIn: 'root'
})
export class MyboxesService {
  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'mybox/myboxsearches.do';

  public boxsearch(myboxsearch: MyBoxSearchDTO) {
    return this.http.post<TrackBoxContainer[]>(this.appUrl, myboxsearch);
  }
   }

