import { Jobs } from '../../model/jobs.model';
import { JobdetailsService } from './jobdetails.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-jobdetails',
  templateUrl: './jobdetails.component.html',
  styleUrls: ['./jobdetails.component.css']
})
export class JobdetailsComponent implements OnInit {
  jobList: Jobs[];
  similarJobList: Jobs[];

  job: Jobs;
   imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  constructor(private jobDetailService: JobdetailsService , private route: ActivatedRoute , private router: Router , 
    private commonService: CommonService) { }

  sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() { 
    if(this.sessionCandidate == null || this.sessionCandidate.id == null || this.sessionCandidate.id == 0){
      this.router.navigate(['/o/offlinejob' , this.route.snapshot.params['id']]);
    }


    this.jobDetailService.getJobById(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.job = data;
        this.jobDetailService.getSimilarJobList(this.job)
        .subscribe( data1 => {
          this.similarJobList = data1;
          },
          error => {
            this.router.navigate(['']);
            });
      },
      error => {
        this.router.navigate(['']);
        });

     this.jobDetailService.morejobs(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.jobList = data;
      },
      error => {
        this.router.navigate(['']);
        });

        this.jobDetailService.updateJobViews(this.route.snapshot.params['id'])
      .subscribe( data => {
      },
      error => {
        this.router.navigate(['']);
        });
  }

  jobapply(jobId) {
     this.jobDetailService.jobapply(jobId)
      .subscribe( data => {
        this.router.navigate(['/m/myjobs']);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  companyjobapply(jobId , jobsUrl) {
     this.jobDetailService.jobapply(jobId)
      .subscribe( data => {
        if( (jobsUrl != 'null' || jobsUrl != null || jobsUrl !='' || !jobsUrl )){
        window.open(jobsUrl);
      }
        this.router.navigate(['/m/myjobs']);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  companySite(jobsUrl) {
    // this.jobDetailService.jobapply(jobId)
     // .subscribe( data => {
        if( (jobsUrl != 'null' || jobsUrl != null || jobsUrl !='' || !jobsUrl )){
        window.open(jobsUrl);
      }
      //  this.router.navigate(['/m/myjobs']);
     // },
   //   error => {
    //    this.router.navigate(['']);
     //   });
  }

}
