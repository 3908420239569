import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/model/person.model';
import { CandidateCount } from 'src/app/model/candidatecount.model';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginpageService } from '../loginpage/loginpage.service';
import { CookieService } from 'ngx-cookie-service';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-externallogin',
  templateUrl: './externallogin.component.html',
  styleUrls: ['./externallogin.component.css']
})
export class ExternalloginComponent implements OnInit {

  newPerson: Person;
  person: Person;
  loading = true;
  loading1 = true;
  isDisabled: boolean;
  errorMessageDiv: boolean;
  loginErrorDetails: string;
  cookieValue: string;
  friendsRequestCount: CandidateCount;

  constructor(private loginpageService: LoginpageService , private router: Router, private route: ActivatedRoute ,
              private cookieService: CookieService , private searchBarService: SearchbarService ,
              private commonService: CommonService) { }

sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() {

    this.person = new Person();
    this.newPerson = new Person();
    this.loading = false;
    this.loading1 = false;
    this.isDisabled = false;
    this.errorMessageDiv = false;


    this.searchBarService.connectionCount()
      .subscribe( data => {
          this.friendsRequestCount = data;
    },
      error => {
        this.router.navigate(['']);
        });
  }

  checkAndSubmitLogin() {
    this.loading = true;
    this.isDisabled = true;
    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (this.person.userName !== '' && (this.person.userName.length <= 5 || !EMAIL_REGEXP.test(this.person.userName))) {
        console.log( 'Please provide a valid email' );
    } else {
       // alert( "Please provide a valid email" );
       console.log( 'valid email' );

       this.loginpageService.saveType(this.person)
      .subscribe( data => {
        // if (data == null && data.id == null ) {
        //   this.loginErrorDetails = 'User name or password mismatch';
        //    this.loading = false;
        //   this.router.navigate(['']);
        // }else
        if (data.status === false) {
          this.errorMessageDiv = true;
          this.loginErrorDetails = data.message;
          this.loading = false;
        } else {

          this.cookieService.set( 'API_SESSION_ID' , data.sessionId );
          this.cookieService.set( 'CAND_ID', '' + data.id );
          this.cookieService.set( 'CAND_FN', data.firstName );
          this.cookieService.set( 'CAND_LN', data.lastName );
          this.cookieService.set( 'CAND_DESIG', data.designation );
          this.cookieService.set( 'CAND_COMPNM', data.companyName );
          this.cookieService.set( 'CAND_EA', data.emailAddress );
          this.cookieService.set('CAND_CONN' , '' + data.connectionCount);
          this.cookieService.set('CAND_FOLL' , '' + data.followingCount);
          this.cookieService.set('FIRST_FEED' , data.firstFeed);
          this.cookieService.set('VERI_STAT' , '' + data.verified);
          this.cookieService.set('MSG_CNT' , '' + this.friendsRequestCount.messages);
          this.cookieService.set('CONN_CNT' , '' + this.friendsRequestCount.connection);
          this.cookieService.set('CART_CNT' , '' + this.friendsRequestCount.shoppingcart);
          // this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
          this.router.navigate(['m/jobdetails/' , this.route.snapshot.paramMap.get('id')]);

        }

        this.loading = false;
      },
      error => {
        this.router.navigate(['']);
        });
      }
    }
   checkAndSubmitSignin(newPerson: Person) {
//     this.loading1 = true;
//     this.isDisabled = true;
//    //  let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
//  //   if (this.person.userName !== '' &&  !EMAIL_REGEXP.test(this.person.userName)) {
//   //      console.log( 'Please provide a valid email' );
//  //   } else {
//        // alert( "Please provide a valid email" );
//   //     console.log( 'valid email' );
//     this.loginpageService.signupType(this.newPerson)
//       .subscribe( data => {console.log(JSON.stringify(data))
//       //  alert(statusMessage)
//         if (data == null && data.id == null ) {
//           this.router.navigate(['']);
//         }
//         // else if(data[status] == false ) {
//         //       alert("hi");
//         // }
//         else {
//          this.cookieService.set( 'API_SESSION_ID' , data.sessionId );
//           this.cookieService.set( 'CAND_ID', '' + data.id );
//           this.cookieService.set( 'CAND_FN', data.firstName );
//           this.cookieService.set( 'CAND_LN', data.lastName );
//           this.cookieService.set( 'CAND_DESIG', data.designation );
//           this.cookieService.set( 'CAND_COMPNM', data.companyName );
//           this.cookieService.set( 'CAND_EA', data.emailAddress );
//           this.cookieService.set('CAND_CONN' , '' + data.connectionCount);
//           this.cookieService.set('CAND_FOLL' , '' + data.followingCount);
//           this.cookieService.set('FIRST_FEED' , data.firstFeed);
//           this.cookieService.set('VERI_STAT' , '' + data.verified);
//            this.cookieService.set('MSG_CNT' , '' + this.friendsRequestCount.messages);
//           this.cookieService.set('CONN_CNT' , '' + this.friendsRequestCount.connection);
//           this.cookieService.set('CART_CNT' , '' + this.friendsRequestCount.shoppingcart);
//         //  this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
//         this.router.navigate(['m/jobdetails/' , this.route.snapshot.paramMap.get("id")]);
//         }
//         this.loading = false;
//       },
//       error => {
//         this.router.navigate(['']);
//         });
//      // }
   }

  onKeydownLogin(event , person: Person) {

  if (event.key === 'Enter') {
    this.loading = true;
    this.isDisabled = true;
    this.loginpageService.saveType(this.person)
      .subscribe( data => {
        if (data == null && data.id == null ) {
          this.router.navigate(['']);
        } else if (data.status === false) {
          this.errorMessageDiv = true;
          this.loginErrorDetails = data.message;
          this.loading = false;
        } else {
          this.cookieService.set( 'API_SESSION_ID' , data.sessionId );
          this.cookieService.set( 'CAND_ID', '' + data.id );
          this.cookieService.set( 'CAND_FN', data.firstName );
          this.cookieService.set( 'CAND_LN', data.lastName );
          this.cookieService.set( 'CAND_DESIG', data.designation );
          this.cookieService.set( 'CAND_COMPNM', data.companyName );
          this.cookieService.set( 'CAND_EA', data.emailAddress );
          this.cookieService.set('CAND_CONN' , '' + data.connectionCount);
          this.cookieService.set('CAND_FOLL' , '' + data.followingCount);
          this.cookieService.set('FIRST_FEED' , data.firstFeed);
          this.cookieService.set('VERI_STAT' , '' + data.verified);
          this.cookieService.set('MSG_CNT' , '' + this.friendsRequestCount.messages);
          this.cookieService.set('CONN_CNT' , '' + this.friendsRequestCount.connection);
          this.cookieService.set('CART_CNT' , '' + this.friendsRequestCount.shoppingcart);
          // this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
          this.router.navigate(['m/jobdetails/' , this.route.snapshot.paramMap.get('id')]);
        }
        this.loading1 = false;
      },
      error => {
        this.router.navigate(['']);
        });
  }
}
  onKeydownSignin(event , newPerson: Person) {
//   if (event.key === 'Enter') {
//     this.loading1 = true;
//     this.isDisabled = true;
//       this.loginpageService.signupType(this.newPerson)
//       .subscribe( data => {
//         if (data == null && data.id == null ) {
//           this.router.navigate(['']);
//         } else {
//          this.cookieService.set( 'API_SESSION_ID' , data.sessionId );
//           this.cookieService.set( 'CAND_ID', '' + data.id );
//           this.cookieService.set( 'CAND_FN', data.firstName );
//           this.cookieService.set( 'CAND_LN', data.lastName );
//           this.cookieService.set( 'CAND_DESIG', data.designation );
//           this.cookieService.set( 'CAND_COMPNM', data.companyName );
//           this.cookieService.set( 'CAND_EA', data.emailAddress );
//           this.cookieService.set('CAND_CONN' , '' + data.connectionCount);
//           this.cookieService.set('CAND_FOLL' , '' + data.followingCount);
//           this.cookieService.set('FIRST_FEED' , data.firstFeed);
//           this.cookieService.set('VERI_STAT' , '' + data.verified);
//            this.cookieService.set('MSG_CNT' , '' + this.friendsRequestCount.messages);
//           this.cookieService.set('CONN_CNT' , '' + this.friendsRequestCount.connection);
//           this.cookieService.set('CART_CNT' , '' + this.friendsRequestCount.shoppingcart);
//          // this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
//         this.router.navigate(['m/jobdetails/' , this.route.snapshot.paramMap.get("id")]);
//         }
//         this.loading1 = false;
//       },
//       error => {
//         this.router.navigate(['']);
//         });
//   }
 }


}
