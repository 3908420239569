import { Company } from './company.model';
import { ContainerSales } from './containersales.model';
import { ContainerPurchasedUnits } from './containerpurchasedunits.model';

export class ContainerSalesUnits {
    id: number;
    company: Company;
    sales: ContainerSales;
    purchaseUnit: ContainerPurchasedUnits;
    salesDate: Date;
    equipmentNo: number;
    unitPrice: number;
    vatAmount: number;
}
