import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/model/person.model';
import { AccountactivationfromloginService } from './accountactivationfromlogin.service';
import { Router } from '@angular/router';
import { Candidates } from 'src/app/model/candidate.model';

@Component({
  selector: 'app-accountactivationfromlogin',
  templateUrl: './accountactivationfromlogin.component.html',
  styleUrls: ['./accountactivationfromlogin.component.css']
})
export class AccountactivationfromloginComponent implements OnInit {

    person: Person;
    candidate: Candidates;
    
  constructor(private accountactivationfromloginService: AccountactivationfromloginService , private router: Router) { }

  ngOnInit() {
      this.person = new Person;
  }

  activateAccount() {
      this.accountactivationfromloginService.accountactivate(this.person)
				.subscribe(data => {
                    this.candidate = data;
                    this.router.navigate(['/accountactivationcodeverification' , this.candidate.id]);
                });
  }

}
