import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from '../utils/rs.constants';
import { DashboardCountDto } from '../model/dashboardcountdto.model';
import { BoxCountByContainerTypeDTO } from '../model/boxcountbycontainertypeDto';

@Injectable({
  providedIn: 'root'
})
export class TrackmyboxService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxdashboard/getAllDashboardCounts.do';
  private appUrltypecount = ShipStreetProps.API_PATH + 'trackboxdashboard/getBoxCountByContainerType.do';

  getAllDashboardCounts() {
    return this.http.get<DashboardCountDto>(this.appUrl);
  }

// return this.http.post<TrackBoxContainer[]>(this.appUrl, myboxsearch);


getAllBoxCountByContainerType(containerStatus) {
  return this.http.post<BoxCountByContainerTypeDTO[]>(this.appUrltypecount, containerStatus );
}

  getAllBoxCountByContainerTypeOld() {
    return this.http.get<BoxCountByContainerTypeDTO[]>(this.appUrltypecount);
  }
}
