import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { SlideshowModule } from 'ng-simple-slideshow';
import { CookieService } from 'ngx-cookie-service';
import { ChartsModule } from 'ng2-charts';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import * as  XLSX from 'xlsx';
import {
  MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
  MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
  MatBottomSheetModule,
  MatSlideToggleModule
} from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { CKEditorModule } from 'ckeditor4-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {
  NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  NGX_POSITIONS, SPINNER_TYPES, PB_DIRECTIONS
} from 'ngx-ui-loader';
import { LoginpageComponent } from './login/loginpage/loginpage.component';
import { Page1Component } from './login/page1/page1.component';
import { Page3Component } from './login/page3/page3.component';
import { Page2Component } from './login/page2/page2.component';
import { PrivacyComponent } from './login/privacy/privacy.component';
import { UseragreementComponent } from './login/useragreement/useragreement.component';
import { CookiepolicyComponent } from './login/cookiepolicy/cookiepolicy.component';
import { SignupprivacyComponent } from './login/signupprivacy/signupprivacy.component';
import { ForgotpasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { ExternalloginComponent } from './login/externallogin/externallogin.component';
import { ExternalpostloginComponent } from './login/externalpostlogin/externalpostlogin.component';
import { AccountdeactivatedmessageComponent } from './login/accountdeactivatedmessage/accountdeactivatedmessage.component';
import { AccountactivateloginComponent } from './login/accountactivatelogin/accountactivatelogin.component';
import { AccountactivationfromloginComponent } from './login/accountactivationfromlogin/accountactivationfromlogin.component';
import { AccountactivationcodeverifyComponent } from './login/accountactivationcodeverify/accountactivationcodeverify.component';
import { JoinnowComponent } from './login/joinnow/joinnow.component';
import { LoginpopComponent } from './login/loginpop/loginpop.component';
import { SignuppopComponent } from './login/signuppop/signuppop.component';
import { RefundloginComponent } from './login/refundlogin/refundlogin.component';
import { BlsearchresultComponent } from './login/blsearchresult/blsearchresult.component';
import { BlsearchComponent } from './login/blsearch/blsearch.component';
import { AppsdisplayComponent } from './login/appsdisplay/appsdisplay.component';
import { RigStreetHttpInt } from './utils/rig.httpinterceptor';
import { TrackboxlayoutComponent } from './_layouts/trackboxlayout/trackboxlayout.component';
import { TrackmyboxComponent } from './trackmybox/trackmybox.component';
import { BookingcontainersaddComponent } from './trackmybox/bookings/bookingcontainersadd/bookingcontainersadd.component';
import { TbagentlayoutComponent } from './_layouts/tbagentlayout/tbagentlayout.component';
import { TbagentheaderComponent } from './_layouts/tbagentheader/tbagentheader.component';
import { TrackboxmobnavComponent } from './trackboxmobnav/trackboxmobnav.component';

import { TrackmyboxModule } from './trackmybox/trackmybox.module';
import { ContainersModule } from './containers/containers.module';
import { TbagentModule } from './trackmybox/agents/tbagent.module';
import { ContainersComponent } from './containers/containers.component';
import { TrackboxheaderComponent } from './_layouts/trackboxheader/trackboxheader.component';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { AnweighModule } from './anweigh/anweigh.module';
import { BlsearchofflineComponent } from './login/blsearchoffline/blsearchoffline.component';
import { BlsearchresulfofflineComponent } from './login/blsearchresulfoffline/blsearchresulfoffline.component';
import { HelpLayoutComponent } from './_layouts/help-layout/help-layout.component';
import { HelpHeaderComponent } from './_layouts/help-header/help-header.component';
import { HelpnavComponent } from './_layouts/helpnav/helpnav.component';
import { HelpModule } from './help/help.module';
import { SalesforceComponent } from './salesforce/salesforce.component';
import { SalesforceModule } from './salesforce/salesforce.module';
import { SalesforcelayoutComponent } from './_layouts/salesforcelayout/salesforcelayout.component';
import { SalesforceheaderComponent } from './_layouts/salesforceheader/salesforceheader.component';
import { AdminModule } from './admin/admin.module';
import { CalculatorComponent } from './calculator/calculator.component';
import { CalculatorheaderComponent } from './_layouts/calculatorheader/calculatorheader.component';
import { CalculatorlayoutComponent } from './_layouts/calculatorlayout/calculatorlayout.component';
import { CalculatorModule } from './calculator/calculator.module';
import { CalculatorintroComponent } from './calculatorintro/calculatorintro.component';
import { CalculatorinfopricingComponent } from './calculatorintro/calculatorinfopricing/calculatorinfopricing.component';
import { CalculatornoaccessintroComponent } from './calculatorintro/calculatornoaccessintro/calculatornoaccessintro.component';
import { TrackerComponent } from './tracker/tracker.component';
import { TrackerheaderComponent } from './_layouts/trackerheader/trackerheader.component';
import { TrackerlayoutComponent } from './_layouts/trackerlayout/trackerlayout.component';
import { TrackerModule } from './tracker/tracker.module';
import { CalculatorheadmobnavComponent } from './_layouts/calculatorheader/calculatorheadmobnav/calculatorheadmobnav.component';
import { AccountverificationComponent } from './login/accountverification/accountverification.component';
import { AppRoutingModule } from './app-routing.module';
import { ContainerbycountrydetailsComponent } from './containersearch/containerbycountrydetails/containerbycountrydetails.component';
import { ContainersearchComponent } from './containersearch/containersearch.component';
import { ContainersearchlayoutComponent } from './_layouts/containersearchlayout/containersearchlayout.component';
import { ContainersearchheaderComponent } from './_layouts/containersearchheader/containersearchheader.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { PasscodecheckingComponent } from './login/forgotpassword/passcodechecking/passcodechecking.component';
import { PasswordresettingComponent } from './login/forgotpassword/passwordresetting/passwordresetting.component';
import { ContainersearchesComponent } from './containersearches/containersearches.component';
import { AccountsModule } from './accounts/accounts.module';
import { AccountslayoutComponent } from './_layouts/accountslayout/accountslayout.component';
import { AccountsheaderComponent } from './_layouts/accountsheader/accountsheader.component';
import { AccountsComponent } from './accounts/accounts.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: 'white',
  fgsPosition: NGX_POSITIONS.centerCenter,
  fgsSize: 40,
  fgsType: SPINNER_TYPES.foldingCube,
  pbDirection: PB_DIRECTIONS.leftToRight,
  pbThickness: 2
}

@NgModule({
  declarations: [
    LoginpageComponent, Page1Component, Page3Component, Page2Component, PrivacyComponent,
    UseragreementComponent, CookiepolicyComponent, SignupprivacyComponent, ForgotpasswordComponent, MarketplaceComponent,
    ExternalloginComponent, ExternalpostloginComponent, AccountdeactivatedmessageComponent, AccountactivateloginComponent,
    AccountactivationfromloginComponent, AccountactivationcodeverifyComponent, JoinnowComponent, LoginpopComponent,
    SignuppopComponent, RefundloginComponent, BlsearchresultComponent, BlsearchComponent,
    AppComponent, TrackboxlayoutComponent, TrackmyboxComponent, BookingcontainersaddComponent,
    LoginLayoutComponent, TbagentlayoutComponent, TbagentheaderComponent, TrackboxmobnavComponent,
    AppsdisplayComponent, TrackboxheaderComponent, BlsearchofflineComponent, BlsearchresulfofflineComponent,
    HelpLayoutComponent, HelpHeaderComponent, HelpnavComponent, SalesforceComponent, SalesforcelayoutComponent,
    SalesforceheaderComponent, CalculatorComponent, CalculatorheaderComponent, CalculatorlayoutComponent,
    CalculatorintroComponent, CalculatorinfopricingComponent, CalculatornoaccessintroComponent,
    TrackerComponent, TrackerheaderComponent, TrackerlayoutComponent, CalculatorheadmobnavComponent,
    AccountverificationComponent, ContainerbycountrydetailsComponent, ContainersearchComponent,
    ContainersearchlayoutComponent, ContainersearchheaderComponent, PasscodecheckingComponent,
    PasswordresettingComponent, ContainersearchesComponent, AccountslayoutComponent , AccountsheaderComponent, AccountsComponent    
  ],
  imports: [
    AppRoutingModule,
    HelpModule, SalesforceModule, CalculatorModule, TrackerModule,
    // LoginModule, CompanyModule, RecruiterModule, MarketModule,
    ContainersModule, AdminModule,AccountsModule,
    //  BrowserModule, CommonModule, InfiniteScrollModule, CmsModule, OfflineModule, , EventsModule,
    //  ContainerloginModule,
      TrackmyboxModule, TbagentModule,
    AnweighModule, BrowserModule,
    // MatLinkPreviewModule.forRoot(),
    // MatPasswordStrengthModule.forRoot(),
    BrowserAnimationsModule, HttpClientModule, ChartsModule,
    // NgDatepickerModule ,
    LayoutModule, FlexLayoutModule, DragDropModule,
    MatToolbarModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule,
    MatButtonModule, MatBadgeModule, MatStepperModule, MatBottomSheetModule,
    MatSidenavModule, SlideshowModule, // PopoverModule,
    MatIconModule, MatTooltipModule, MatSliderModule,
    MatListModule, FormsModule, ReactiveFormsModule, MatSnackBarModule,
    // CustomFormsModule ,
    MatGridListModule,
    // Ng2GoogleChartsModule,
    MatCardModule,
    MatMenuModule, MatTabsModule,
    MatTableModule, MatRadioModule,
    MatPaginatorModule, MatCheckboxModule,
    MatSortModule, MatSelectModule,
    MatFormFieldModule, MatInputModule,
    NgxPaginationModule, MatDialogModule, MatExpansionModule, MatSlideToggleModule, CKEditorModule, CarouselModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
  ],
  exports: [CalculatorinfopricingComponent],
  entryComponents: [
    LoginpopComponent, AppsdisplayComponent
  ],
  providers: [CookieService, {
    provide: HTTP_INTERCEPTORS,
    useClass: RigStreetHttpInt,
    multi: true
  }],
  bootstrap: [AppComponent],

})
export class AppModule { }
