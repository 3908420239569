import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { TrackBoxContainerSupplier } from 'src/app/model/trackboxcontainersupplier.model';

@Injectable({
  providedIn: 'root'
})

export class TbagenteditsupplierService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'trackboxcontainersupplier/trackboxsuppliersViewbyId.do';
 private updateUrl = ShipStreetProps.API_PATH + 'trackboxcontainersupplier/updatetbsuppliers.do';
 public trackboxsuppliersViewbyId( id: string) {

  return this.http.post<TrackBoxContainerSupplier>(this.appUrl, id);
  }
  public updatetbsupplier(tbsupplier: TrackBoxContainerSupplier) {

      return this.http.post<string>(this.updateUrl , tbsupplier);

}
}
