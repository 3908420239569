import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forcastreports',
  templateUrl: './forcastreports.component.html',
  styleUrls: ['./forcastreports.component.css']
})
export class ForcastreportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
