import { Candidates } from '../../../model/candidate.model';
import { Company } from '../../../model/company.model';
import { Marketplace } from '../../../model/marketplace.model';
import { MarketplaceOrder } from '../../../model/marketplaceOrder.model';
import { MarketplaceorderService } from './marketplaceorder.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { MarketShoppingCart } from '../../../model/marketshoppingcart.model';

@Component({
  selector: 'app-marketplaceorder',
  templateUrl: './marketplaceorder.component.html',
  styleUrls: ['./marketplaceorder.component.css']
})
export class MarketplaceorderComponent implements OnInit {
  marketplaceorder: MarketplaceOrder;
  quantity: number;
  marketplace: Marketplace;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  shoppingCart: MarketShoppingCart[];
  totalamount: number;
  constructor(private marketplaceorderService: MarketplaceorderService , private route: ActivatedRoute , private router: Router) { }

  ngOnInit() {
    this.marketplaceorder = new MarketplaceOrder;
    this.marketplaceorder.candidate = new Candidates;
    //  this.marketplaceorderService.getmarketplaceorder(this.route.snapshot.params['id'])
    //   .subscribe( data => {
    //     this.marketplace = data;
    //   },
    //   error => {
    //     this.router.navigate(['']);
    //     });

      this.marketplaceorderService.getmarketplacecart()
      .subscribe( data => {
        this.shoppingCart = data;
      },
      error => {
        this.router.navigate(['']);
        });


  //  let total = 0;
  //   for (var i = 0; i < this.shoppingCart.length; i++) {alert("for")
  //       if (this.shoppingCart[i].item.price) {alert("if")
  //           total += this.shoppingCart[i].item.price;
  //           this.totalamount = total;
  //       }
  //   }


  }

getTotal() {
    let total = 0;
    for (let i = 0; i < this.shoppingCart.length; i++) {
        if (this.shoppingCart[i].item.price) {
            total += this.shoppingCart[i].item.price * this.shoppingCart[i].itemQuantity;
            this.totalamount = total;
        }
    }
    return total;
}

  saveMarketplaceOrder(marketplaceorder) {
    marketplaceorder.marketplace = this.marketplace;
    marketplaceorder.company = this.marketplace.company;
    marketplaceorder.ownerId = this.marketplace.candidate;
    this.marketplaceorderService.saveMarketplaceOrder(marketplaceorder)
      .subscribe( data => {
       // alert("hi")
        this.router.navigate(['/m/marketplaceMyorder']);
     },
     error => {
       this.router.navigate(['']);
        });
  }

  confirmBooking() {
    this.marketplaceorderService.confirmBooking()
      .subscribe( data => {
        this.router.navigate(['/m/marketplaceMyorder']);
     },
     error => {
       this.router.navigate(['']);
        });
  }

  continueShopping() {
    this.marketplaceorderService.updatecartitems(this.shoppingCart)
      .subscribe( data => {
        this.router.navigate(['/m/marketplaces']);
     },
     error => {
       this.router.navigate(['']);
        });
  }

  updatecartitems() {
   this.marketplaceorderService.updatecartitems(this.shoppingCart)
      .subscribe( data => {
        this.router.navigate(['/m/addresspage']);
     },
     error => {
       this.router.navigate(['']);
        });
}

  removeFromCart(cartId) {
    this.marketplaceorderService.removeCartItem(cartId)
      .subscribe( data => {
  let updatedArray = [];
      for (let el of this.shoppingCart) {
    if (el.id !== cartId) {
        updatedArray.push(el);
    }
}
this.shoppingCart = updatedArray;
     },
     error => {
       this.router.navigate(['']);
        });
  }



}
