import { Component, OnInit } from '@angular/core';
import { Pipeline } from 'src/app/model/pipeline.model';

@Component({
  selector: 'app-scheduleactivity',
  templateUrl: './scheduleactivity.component.html',
  styleUrls: ['./scheduleactivity.component.css']
})
export class ScheduleactivityComponent implements OnInit {
  pipeline: Pipeline;

  constructor() { }

  ngOnInit() {
    this.pipeline = new Pipeline();
  }

}
