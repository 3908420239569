import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountscategoryService } from './accountscategory.service';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';

@Component({
  selector: 'app-accountscategory',
  templateUrl: './accountscategory.component.html',
  styleUrls: ['./accountscategory.component.css']
})
export class AccountscategoryComponent implements OnInit {
  cateList: AccountsProductCategory[];

  constructor(private accountscategoryService: AccountscategoryService, private router: Router) { }

  ngOnInit() {
    this.accountscategoryService.getAllCategoryList()
      .subscribe(data => {
        this.cateList = data;
      // },
      //   error => {
      //     this.router.navigate(['']);
        });
  }

}
