import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class SalesbycustomerreportService {

  constructor(private http: HttpClient) { }

  private getUrl = ShipStreetProps.API_PATH + 'containersales/getSalesByCustomer.do';
  private appUrl = ShipStreetProps.API_PATH + 'containercustomer/containerCustomerList.do';

  public containerCustomerList() {
    return this.http.get<ContainerCustomer[]>(this.appUrl);
  }
  public getSalesByCustomer(sales: ContainerSales) {
    return this.http.post<ContainerSales[]>(this.getUrl, sales);
  }
}
