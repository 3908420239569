import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPresales } from 'src/app/model/containerpresales.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class PresalesdetailsService {

  private objUrl = ShipStreetProps.API_PATH + 'containerpresales/findSingleContainerPreSales';
  private markUrl = ShipStreetProps.API_PATH + 'containerpresales/containermarkassales';
  private updUrl = ShipStreetProps.API_PATH + 'containerpresales/updatePreSalesPricenQty';


  constructor(private http: HttpClient) { }

  public findSingleContainerPreSales(salesId: string) {
    return this.http.post<ContainerPresales>(this.objUrl, salesId);
  }

  public containermarkassales(sales: ContainerPresales) {
    return this.http.post<string>(this.markUrl, sales);
  }

  public updatePreSalesPricenQty(sales: ContainerPresales) {
    return this.http.post<string>(this.updUrl, sales);
  }
}
