import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { MyBoxSearchDTO } from 'src/app/model/myboxsearchDTO.model';
import { NewequipmentcontrolService } from '../../equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { TrackBoxCarrier } from 'src/app/model/trackboxcarrier.model';
import { map } from 'rxjs/operators';
import { MyboxesService } from '../../myboxes/myboxes.service';
import { DepotsService } from '../../master/depots/depots.service';
import { PortsService } from '../../master/ports/ports.service';
import { ContainerType } from 'src/app/model/containertype.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';

@Component({
  selector: 'app-tbagentmyboxes',
  templateUrl: './tbagentmyboxes.component.html',
  styleUrls: ['./tbagentmyboxes.component.css']
})
export class TbagentmyboxesComponent implements OnInit {

  p = 1;
  myControl = new FormControl();
  myControl1 = new FormControl();
  myControl2 = new FormControl();
  myControl3 = new FormControl();
  containerTypeFC = new FormControl();
  noRecordsMsg: string;
  myboxsearch: MyBoxSearchDTO;
  countryId: number;
  countryList: CountryInfo[];
  filteredOptions: Observable<MasterPortsDepot[]>;
  filteredOptionsport: Observable<MasterPortsDepot[]>;
  filteredOptionscarrier: Observable<TrackBoxCarrier[]>;
  filteredOptionscountry: Observable<CountryInfo[]>;
  masterDepotList: MasterPortsDepot[];
  ContainerTypeList: ContainerType[];
  tbcarrier: TrackBoxCarrier[];
  masterPortList: any;
  searchResultList: TrackBoxContainer[];

  constructor(private myboxesService: MyboxesService, private router: Router,
              private route: ActivatedRoute, private newequipmentcontrolService: NewequipmentcontrolService,
              private depotsService: DepotsService, private portService: PortsService) { }

  ngOnInit() {
    this.noRecordsMsg = '';
    this.myboxsearch = new MyBoxSearchDTO();
    this.myboxsearch.country = 0;
    this.myboxsearch.carrier = 0;
    this.myboxsearch.containerType = [];
    this.myboxsearch.depot = 0;
    this.myboxsearch.port = 0;

    this.newequipmentcontrolService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );

        //      console.log(this.filteredOptions);
      },
        error => {
          this.router.navigate(['']);
        });

    this.depotsService.getAllMyDepots()
      .subscribe(data => {
        this.masterDepotList = data;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter1(val))
        );

        console.log(this.filteredOptions);
      },
        error => {
          this.router.navigate(['']);
        });

    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterDepotList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.newequipmentcontrolService.viewcontainerType()
      .subscribe(data => {
        this.ContainerTypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newequipmentcontrolService.viewCarrier()
      .subscribe(data => {
        this.tbcarrier = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.filteredOptionsport = this.myControl1.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter2(val))
    );
    this.filteredOptionscarrier = this.myControl2.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter3(val))
    );
  }

  public displayCountry(cntr: CountryInfo): string {

    if (cntr && cntr.name) {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.country = cntr.id;
      this.countryId = cntr.id;
      return cntr.name;
    } else {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.country = 0;
      return '';
    }
  }

  public displayPort(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.port = cntr.id;
      return cntr.name;
    } else {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.port = 0;
      return '';
    }
  }

  public displayDepot(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.depot = cntr.id;
      return cntr.name;
    } else {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); } this.myboxsearch.depot = 0;
      return '';
    }
  }
  public displayCarrier(cntr: TrackBoxCarrier): string {
    if (cntr && cntr.name) {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.carrier = cntr.id;
      return cntr.name;
    } else {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); } this.myboxsearch.carrier = 0;
      return '';
    }
  }

   public _filter1(value: string): MasterPortsDepot[] {
    const filterValue = value;
    //  console.log('=====>>' + filterValue);

    //  console.log(this.filteredOptions);
    console.log(this.masterDepotList.filter(depot => depot.name.toLowerCase().indexOf(filterValue) === 0 ||
      depot.portCode.toLowerCase().indexOf(filterValue) === 0));

    return this.masterDepotList.filter(depot => depot.name.toLowerCase().indexOf(filterValue) === 0 ||
      depot.name.toUpperCase().indexOf(filterValue) === 0 ||
      depot.portCode.toLowerCase().indexOf(filterValue) === 0);
  }

  public _filter2(value: string): MasterPortsDepot[] {
    const filterValue2 = value.toLowerCase();
    // console.log('=====>>' + filterValue2);

    return this.masterPortList.filter(port => port.name.toLowerCase().indexOf(filterValue2) === 0 ||
      port.name.toUpperCase().indexOf(filterValue2) === 0 ||
      port.portCode.toLowerCase().indexOf(filterValue2) === 0);

  }
  public _filter3(value: string): TrackBoxCarrier[] {
    const filterValue3 = value;
    //  console.log('=====>>' + filterValue3);

    return this.tbcarrier.filter(carrier => carrier.name.toLowerCase().indexOf(filterValue3) === 0 ||
      carrier.name.toUpperCase().indexOf(filterValue3) === 0 ||
      carrier.lineCode.toLowerCase().indexOf(filterValue3) === 0);
    //    return this.masterDepotList.filter(team => team.name.toLowerCase().indexOf(filterValue) === 0 ||
    // team.portCode.toLowerCase().indexOf(filterValue) ===  );
  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    //  console.log('=====>>' + filterValue3);

    return this.countryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
    //    return this.masterDepotList.filter(team => team.name.toLowerCase().indexOf(filterValue) === 0 ||
    // team.portCode.toLowerCase().indexOf(filterValue) ===  );
  }


  public onSearch() {
    this.myboxsearch = new MyBoxSearchDTO();
    if (this.myControl3 && this.myControl3.value && this.myControl3.value.id) {
      this.myboxsearch.country = this.myControl3.value.id;
    } else {
      this.myboxsearch.country = 0;
    }

    if (this.myControl1 && this.myControl1.value && this.myControl1.value.id) {
      this.myboxsearch.port = this.myControl1.value.id;
    } else {
      this.myboxsearch.port = 0;
    }

    if (this.myControl && this.myControl.value && this.myControl.value.id) {
      this.myboxsearch.depot = this.myControl3.value.id;
    } else {
      this.myboxsearch.depot = 0;
    }

    if (this.myControl2 && this.myControl2.value && this.myControl2.value.id) {
      this.myboxsearch.carrier = this.myControl2.value.id;
    } else {
      this.myboxsearch.carrier = 0;
    }

    if (this.containerTypeFC && this.containerTypeFC.value) {
      this.myboxsearch.containerType = this.containerTypeFC.value;
    } else {
      this.myboxsearch.containerType = [];
    }

    // console.log(JSON.stringify(this.myboxsearch));
    this.myboxesService.boxsearch(this.myboxsearch)
      .subscribe(data => {
        this.searchResultList = data;
        //  alert(JSON.stringify(this.searchResultList));
        if (this.searchResultList && this.searchResultList.length > 0) {
          this.noRecordsMsg = '';
        } else {
          this.noRecordsMsg = 'No Records for Search';
        }
      },
        error => {
          this.router.navigate(['']);
        });

  }

   exportAsXLSX(): void {}

   generatePDF() {}

}
