import { Component, OnInit } from '@angular/core';
import { VieweventsService } from '../viewevents/viewevents.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Events } from 'src/app/model/events.model';

@Component({
  selector: 'app-addeventguest',
  templateUrl: './addeventguest.component.html',
  styleUrls: ['./addeventguest.component.css']
})
export class AddeventguestComponent implements OnInit {
  event: Events;

  constructor(private vieweventsService: VieweventsService , private route: ActivatedRoute , private router: Router) { }

  ngOnInit() {
     this.vieweventsService.getJobById(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.event = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }




}
