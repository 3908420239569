import { Component, OnInit } from '@angular/core';
import { BookingsService } from '../bookings/bookings.service';
import { Router } from '@angular/router';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

@Component({
  selector: 'app-upcommingbooking',
  templateUrl: './upcommingbooking.component.html',
  styleUrls: ['./upcommingbooking.component.css']
})
export class UpcommingbookingComponent implements OnInit {
  bookingList: TrackBoxBooking[];

  constructor(private bookingsService: BookingsService , private router: Router) { }

  ngOnInit() {
     this.bookingsService.getAllUpcommingBookings()
      .subscribe(data => {
        this.bookingList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
