import { BlupdateComponent } from './../blupdate/blupdate.component';
import { TrackBoxBookingContainers } from './../../../model/trackboxbookingcontainers.model';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingcontainersviewService } from './bookingcontainersview.service';
import { Component, OnInit } from '@angular/core';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { MatDialog } from '@angular/material';
import { UpdatebookingComponent } from '../updatebooking/updatebooking.component';
import { UpdateequipmentComponent } from '../../equipmentcontrol/equipmentdetails/updateequipment/updateequipment.component';

@Component({
  selector: 'app-bookingcontainersview',
  templateUrl: './bookingcontainersview.component.html',
  styleUrls: ['./bookingcontainersview.component.css']
})
export class BookingcontainersviewComponent implements OnInit {

  trackboxbooking: TrackBoxBooking;
  trackboxbookingcontainersList: TrackBoxBookingContainers[];
  trackboxbookingcontainer: TrackBoxBookingContainers;
  daysTravelled: number;
  DiffDatebet:any;
  DiffDatesmal :any;
      enddate : any;
      currentDate: any;
      pol;
      pod;
  message: string;
  constructor(private bookingcontainersviewService: BookingcontainersviewService, private router: Router,
              private route: ActivatedRoute, public dialog: MatDialog) { }

  updateContainerStatus(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(UpdateequipmentComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.bookingcontainersviewService.getBookingContainerDetailsByID(this.route.snapshot.params.id)
          .subscribe(data1 => {
            this.trackboxbookingcontainersList = data1;
          },
            error => {
              this.router.navigate(['']);
            });
    });
  }
  blupdate(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(BlupdateComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.bookingcontainersviewService.getBookingContainerDetailsByID(this.route.snapshot.params.id)
          .subscribe(data1 => {
            this.trackboxbookingcontainersList = data1;
          },
            error => {
              this.router.navigate(['']);
            });
    });
  }


  ngOnInit() {
    this.trackboxbooking = new TrackBoxBooking();
    this.trackboxbookingcontainer = new TrackBoxBookingContainers();

    this.bookingcontainersviewService.getBookingsDetailsByID(this.route.snapshot.params.id)
          .subscribe(data => {
            this.trackboxbooking = data;
            // let no: number = Math.abs(this.trackboxbooking.polEta - this.trackboxbooking.polEtd);
            this.currentDate = new Date();
            //  alert(JSON.stringify(this.startDate));
             if(this.currentDate.valueOf() > this.trackboxbooking.polEtd.valueOf() && this.currentDate.valueOf() < this.trackboxbooking.podEta.valueOf())
             {

                  this.enddate = Math.abs(this.trackboxbooking.podEta.valueOf() - this.currentDate.valueOf());
                  this.DiffDatebet =  Math.ceil(this.enddate / (1000 * 3600 * 24))-1;
                   this.pol= this.trackboxbooking.pod.name;
                   this.message = "Days for Reaching To";

             }
             else if(this.currentDate.valueOf() < this.trackboxbooking.polEtd.valueOf())
             {
              this.enddate = Math.abs(this.trackboxbooking.polEtd.valueOf() - this.currentDate.valueOf());
              this.DiffDatebet =  Math.ceil(this.enddate / (1000 * 3600 * 24))-1;
              this.pol= this.trackboxbooking.pol.name;
              this.message = "Days For Loading To";

          }
                else{

                  this.enddate = Math.abs( this.currentDate.valueOf() - this.trackboxbooking.podEta.valueOf());
                  this.DiffDatebet =  Math.ceil(this.enddate / (1000 * 3600 * 24))-1;
                  this.pol= this.trackboxbooking.pod.name;
                  this.message = "Days Over After Delivery To";

                }



            // this.trackboxbooking.podEta.valueOf() - this.trackboxbooking.polEta.valueOf();
            //  Math.ceil(this.enddate / (1000 * 3600 * 24));

        //     this.enddate=new Date (this.trackboxbooking.polEta.getFullYear(),this.trackboxbooking.polEta.getMonth(),this.trackboxbooking.polEta.getDay());

        // this.startDate=new Date (this.trackboxbooking.polEtd.getFullYear(),this.trackboxbooking.polEtd.getMonth(),this.trackboxbooking.polEtd.getDay());

        // this.DiffDate=Math.floor((Date.UTC(this.enddate.getFullYear(),this.enddate.getMonth(),this.enddate.getDate())-Date.UTC(this.startDate.getFullYear(),this.startDate.getMonth(),this.startDate.getDate()) )/(1000 * 60 * 60 * 24));

        //this.DiffDate=(this.DiffDate/86400000);
            // tslint:disable-next-line: max-line-length
         //   this.daysTravelled = Number(this.trackboxbooking.voyage.voyage.podEta) - Number(this.trackboxbooking.voyage.voyage.vesselStartsOn);
          },
            error => {
              this.router.navigate(['']);
            });

    this.bookingcontainersviewService.getBookingContainerDetailsByID(this.route.snapshot.params.id)
          .subscribe(data1 => {
            this.trackboxbookingcontainersList = data1;
          },
            error => {
              this.router.navigate(['']);
            });
  }

}
