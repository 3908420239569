import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offlinenav',
  templateUrl: './offlinenav.component.html',
  styleUrls: ['./offlinenav.component.css']
})
export class OfflinenavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
