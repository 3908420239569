import { TrackBoxBooking } from './../../../model/trackboxbooking.model';
import { TrackBoxBookingContainersDTO } from './../../../model/trackboxbookingcontainersDTO.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class BookingcontainersaddService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/trackboxaddcontainertobooking.do';
private updateUrl = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/trackboxbookingcontainersave.do';
private appUrlsbooking = ShipStreetProps.API_PATH + 'TrackboxBookings/TrackboxBookingsViewbyId.do';

public getContainers(bookingId: string) {
  return this.http.post<TrackBoxContainer[]>(this.appUrl, bookingId);
}

public getBookingsByID( bookingId: string) {
return this.http.post<TrackBoxBooking>(this.appUrlsbooking, bookingId);
}

public updateBookingEquipment(containerBooking: TrackBoxBookingContainersDTO) {
  return this.http.post<string>(this.updateUrl, containerBooking);
}
}


