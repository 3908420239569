import { Component, OnInit } from '@angular/core';
import { Taxes } from 'src/app/model/taxes.model';
import { CommonService } from 'src/app/utils/common.service';
import { Router } from '@angular/router';
import { NewtaxesService } from './newtaxes.service';
import { TaxagenciesService } from '../taxagencies/taxagencies.service';
import { TaxAgency } from 'src/app/model/taxagency.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ContainerCompany } from 'src/app/model/containercompany.model';
import { Company } from 'src/app/model/company.model';

@Component({
  selector: 'app-newtaxes',
  templateUrl: './newtaxes.component.html',
  styleUrls: ['./newtaxes.component.css']
})
export class NewtaxesComponent implements OnInit {
  tax: Taxes;
  taxagencies: TaxAgency[];

  sessionCandidate: SessionCandidate;
  // customer: ContainerCustomer;

  constructor(private newtaxesService: NewtaxesService, private router: Router, private commonService: CommonService,
              private taxagenciesService: TaxagenciesService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.tax = new Taxes();
    this.tax.taxAgency = new TaxAgency();
    this.tax.company = new Company();

    this.taxagenciesService.findAllTaxAgency()
      .subscribe(data => {
        this.taxagencies = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveTaxes() {
    this.tax.company.id = this.sessionCandidate.containerCompany;
    this.newtaxesService.saveTaxes(this.tax)
      .subscribe(data => {
        this.router.navigate(['containers/taxes']);
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
