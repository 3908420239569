import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentupdatecontainerstatuswithbookingService {

constructor(private http: HttpClient) { }

private appUrlupdateStatwithBooking = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/updateTrackboxcontainerStatusWithBooking.do';

public updateContainerStatusWithBooking(contStat: TrackBoxBookingContainers) {
    // alert(JSON.stringify(contStat));
   return this.http.post<string>(this.appUrlupdateStatwithBooking , contStat);
 }
}
