import { PostComment } from '../../model/PostComment.model';
import { Posts } from '../../model/posts.model';
import { PostdetailsService } from './postdetails.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-postdetails',
  templateUrl: './postdetails.component.html',
  styleUrls: ['./postdetails.component.css']
})
export class PostdetailsComponent implements OnInit {
  showButton: boolean;
  postDetails: Posts;
  postId: string;

  constructor(private postdetailsService: PostdetailsService , private route: ActivatedRoute , private router: Router , 
    private commonService: CommonService) { }
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

   sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() {
    if(this.sessionCandidate == null || this.sessionCandidate.id == null || this.sessionCandidate.id == 0){
      this.router.navigate(['o/offlinepost' , this.route.snapshot.params['id']]);
    }
    this.postDetails = new Posts;
    this.postDetails.newComments = new PostComment;
    this.showButton = true;
    this.postId = this.route.snapshot.paramMap.get('id');
    this.postdetailsService.getAPost(this.postId)
      .subscribe( data => {
        this.postDetails = data;
        this.postDetails.postCommentList = data.postCommentList;
      },
      error => {
        this.router.navigate(['']);
        });
  }
  likePost() {
     this.postdetailsService.likePost(this.postDetails.id)
      .subscribe( data => {
        this.postDetails = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }
  unLikePost(){
    this.postdetailsService.unLikePost(this.postDetails.id)
      .subscribe( data => {
        this.postDetails = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }
  savePostComment(){
    this.postdetailsService.savePostComment(this.postDetails)
      .subscribe( data => {
         this.postDetails = data;
//        this.router.navigate(['editprofile']);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  deletePostComment(commid) {
    this.postdetailsService.deletePostComment(commid)
      .subscribe( data => {
         this.postDetails = data;
//        this.router.navigate(['editprofile']);
      // },
      // error => {
      //   this.router.navigate(['']);
        });
  }

}
