import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlupdateComponent } from './bookings/blupdate/blupdate.component';
import { NewtrackboxcustomersComponent } from './admin/trackboxcustomers/newtrackboxcustomers/newtrackboxcustomers.component';
import { OffhireequipmentComponent } from './equipmentcontrol/offhireequipment/offhireequipment.component';
import { NewtrackboxsupplierComponent } from './admin/trackboxsupplier/newtrackboxsupplier/newtrackboxsupplier.component';
import { from } from 'rxjs';
// import { BookingcontainersviewComponent } from './bookings/bookingcontainersview/bookingcontainersview.component';
import { RouterModule, Routes } from '@angular/router';
import { TrackboxlayoutComponent } from '../_layouts/trackboxlayout/trackboxlayout.component';
import { TrackmyboxComponent } from './trackmybox.component';
import { EquipmentcontrolComponent } from './equipmentcontrol/equipmentcontrol.component';
import { NewequipmentcontrolComponent } from './equipmentcontrol/newequipmentcontrol/newequipmentcontrol.component';
import { EquipmentcontrollistComponent } from './equipmentcontrollist/equipmentcontrollist.component';
import { BoxemployeesComponent } from './admin/boxemployees/boxemployees.component';
import { NewboxemployeeComponent } from './admin/boxemployees/newboxemployee/newboxemployee.component';
import { BoxagentsComponent } from './admin/boxagents/boxagents.component';
import { NewboxagentComponent } from './admin/boxagents/newboxagent/newboxagent.component';
import { ContainertypesComponent } from './master/containertypes/containertypes.component';
import { NewcontainertypeComponent } from './master/containertypes/newcontainertype/newcontainertype.component';
import { PortsComponent } from './master/ports/ports.component';
import { NewportComponent } from './master/ports/newport/newport.component';
import { DepotsComponent } from './master/depots/depots.component';
import { NewdepotComponent } from './master/depots/newdepot/newdepot.component';
import { LocationtypeComponent } from './master/locationtype/locationtype.component';
import { NewlocationtypeComponent } from './master/locationtype/newlocationtype/newlocationtype.component';
import { EquipmentdetailsComponent } from './equipmentcontrol/equipmentdetails/equipmentdetails.component';
import { MyboxesComponent } from './myboxes/myboxes.component';
import { BookingsComponent } from './bookings/bookings.component';
import { CompanyvesseloperatorComponent } from './master/companyvesseloperator/companyvesseloperator.component';
// tslint:disable-next-line: max-line-length
import { NewcompanyvesseloperatorComponent } from './master/companyvesseloperator/newcompanyvesseloperator/newcompanyvesseloperator.component';
import { CompanyvesselsComponent } from './master/companyvessels/companyvessels.component';
import { NewcompanyvesselComponent } from './master/companyvessels/newcompanyvessel/newcompanyvessel.component';
import { VoyageComponent } from './master/voyage/voyage.component';
import { NewvoyageComponent } from './master/voyage/newvoyage/newvoyage.component';
import { SavesheetComponent } from './savesheet/savesheet.component';
import { TrackboxcarriersComponent } from './admin/trackboxcarriers/trackboxcarriers.component';
import { NewtrackboxcarrierComponent } from './admin/trackboxcarriers/newtrackboxcarrier/newtrackboxcarrier.component';
import { NewbookingComponent } from './bookings/newbooking/newbooking.component';
import { BookingcontainersaddComponent } from './bookings/bookingcontainersadd/bookingcontainersadd.component';
import { VoyagedetailsComponent } from './master/voyage/voyagedetails/voyagedetails.component';
import { BookingcontainersviewComponent } from './bookings/bookingcontainersview/bookingcontainersview.component';
import { ExistingemployeesComponent } from './admin/boxemployees/existingemployees/existingemployees.component';
import { VoyagestatusdetailsComponent } from './voyagestatusdetails/voyagestatusdetails.component';
import { VoyagerouteComponent } from './master/voyage/voyageroute/voyageroute.component';
import { EditvesseloperatorComponent } from './master/companyvesseloperator/editvesseloperator/editvesseloperator.component';
import { UpdatebookingComponent } from './bookings/updatebooking/updatebooking.component';
import { UpdateequipmentComponent } from './equipmentcontrol/equipmentdetails/updateequipment/updateequipment.component';
import { TrackboxsupplierComponent } from './admin/trackboxsupplier/trackboxsupplier.component';
import { EditequipmentcontrollerComponent } from './equipmentcontrol/editequipmentcontroller/editequipmentcontroller.component';
import { SaleequipmentComponent } from './equipmentcontrol/saleequipment/saleequipment.component';
import { TrackboxcustomersComponent } from './admin/trackboxcustomers/trackboxcustomers.component';
import { EquipmentcontroltypelistComponent } from './equipmentcontrol/equipmentcontroltypelist/equipmentcontroltypelist.component';
import { SearchboxagentComponent } from './admin/boxagents/searchboxagent/searchboxagent.component';
import { EditbookingComponent } from './bookings/editbooking/editbooking.component';
import { RequestfromagentComponent } from './bookings/requestfromagent/requestfromagent.component';
import { TrackmyboxsitesComponent } from './trackmyboxsites/trackmyboxsites.component';
import { ChargeheadsComponent } from './master/chargeheads/chargeheads.component';
import { NewchargeheadsComponent } from './master/chargeheads/newchargeheads/newchargeheads.component';
import { CurrencysettingsComponent } from './admin/currencysettings/currencysettings.component';
import { EditportsComponent } from './master/ports/editports/editports.component';
import { EditdepotsComponent } from './master/depots/editdepots/editdepots.component';
import { TrackmyboxpricingComponent } from './trackmyboxpricing/trackmyboxpricing.component';
import { EdittrackboxsupplierComponent } from './admin/trackboxsupplier/edittrackboxsupplier/edittrackboxsupplier.component';
import { EdittrackboxcustomersComponent } from './admin/trackboxcustomers/edittrackboxcustomers/edittrackboxcustomers.component';
import { TrackmyboxsubscriptioncheckoutComponent } from './trackmyboxsubscriptioncheckout/trackmyboxsubscriptioncheckout.component';



export const trackmyboxRoutes: Routes = [
  {
    path: 'trackmybox',
    component: TrackboxlayoutComponent,
    children: [
      { path: '', component: TrackmyboxComponent },
      { path: 'trackboxdashboard', component: TrackmyboxComponent },
      { path: 'equipmentcontroller', component: EquipmentcontrolComponent },
      { path: 'newequipmentcontroller', component: NewequipmentcontrolComponent },
      { path: 'boxemployees', component: BoxemployeesComponent },
      { path: 'boxexistingemployees', component: ExistingemployeesComponent },
      { path: 'newboxemployee', component: NewboxemployeeComponent },
      { path: 'boxagents', component: BoxagentsComponent },
      { path: 'newboxagent', component: NewboxagentComponent },
      { path: 'searchboxagent', component: SearchboxagentComponent },
     // { path: 'viewagentrequest', component: ViewagentrequestComponent },
      { path: 'containertypes', component: ContainertypesComponent },
      { path: 'newcontainertype', component: NewcontainertypeComponent },
      { path: 'ports', component: PortsComponent },
      { path: 'newport', component: NewportComponent },
      { path: 'depots', component: DepotsComponent },
      { path: 'newdepot', component: NewdepotComponent },
      { path: 'locationtypes', component: LocationtypeComponent },
      { path: 'newlocationtype', component: NewlocationtypeComponent },
      { path: 'equipmentdetails/:id', component: EquipmentdetailsComponent },
      { path: 'savesheet', component: SavesheetComponent },
      { path: 'myboxes', component: MyboxesComponent },
      { path: 'bookings', component: BookingsComponent },
      { path: 'newbooking', component: NewbookingComponent },
      { path: 'tmbcompanyvesseloperator', component: CompanyvesseloperatorComponent },
      { path: 'newtmbcompanyvesseloperator', component: NewcompanyvesseloperatorComponent },
      { path: 'editvesseloperator/:id', component: EditvesseloperatorComponent },
      { path: 'companyvessels', component: CompanyvesselsComponent },
      { path: 'newcompanyvessel', component: NewcompanyvesselComponent },
      { path: 'voyages', component: VoyageComponent },
      { path: 'newvoyages', component: NewvoyageComponent },
      { path: 'trackboxcarriers', component: TrackboxcarriersComponent },
      { path: 'newtrackboxcarrier', component: NewtrackboxcarrierComponent },
      { path: 'bookingcontainersadd/:id', component: BookingcontainersaddComponent },
      { path: 'trackboxcustomers', component: TrackboxcustomersComponent },
      { path: 'newtrackboxcustomers', component: NewtrackboxcustomersComponent },
      { path: 'voyagedetails/:id', component: VoyagedetailsComponent },
      { path: 'bookingcontainersview/:id', component: BookingcontainersviewComponent },
      { path: 'voyagestatusdetails', component: VoyagestatusdetailsComponent },
      { path: 'voyagerouter/:id', component: VoyagerouteComponent },
      { path: 'updatebooking/:id', component: UpdatebookingComponent },
      { path: 'updateequipment/:id', component: UpdateequipmentComponent },
      { path: 'trackboxsupplier', component: TrackboxsupplierComponent },
      { path: 'newtrackboxsupplier', component: NewtrackboxsupplierComponent },
      { path: 'editequipmentcontroller/:id', component: EditequipmentcontrollerComponent },
      { path: 'equipmentcontrollist/:text', component: EquipmentcontrollistComponent },
      { path: 'equipmentcontroltypelist/:text', component: EquipmentcontroltypelistComponent },
      { path: 'offhireequipment/:id', component: OffhireequipmentComponent },
      { path: 'saleequipment/:id', component: SaleequipmentComponent },
      { path: 'blupdate/:id' , component: BlupdateComponent },
      { path: 'editbooking/:id' , component: EditbookingComponent },
      { path: 'viewrequests' , component: RequestfromagentComponent },
      { path: 'trackmyboxsites' , component: TrackmyboxsitesComponent },
      { path: 'chargeheads' , component: ChargeheadsComponent },
      { path: 'newchargeheads' , component: NewchargeheadsComponent },
      { path: 'trackboxcurrencysettings' , component: CurrencysettingsComponent },
      { path: 'editports/:id' , component: EditportsComponent },
      { path: 'editdepots/:id' , component: EditdepotsComponent },
      { path: 'trackmyboxpricing' , component: TrackmyboxpricingComponent }, 
      { path: 'edittrackboxsupplier/:id', component: EdittrackboxsupplierComponent }, 
      { path: 'edittrackboxcustomers/:id', component: EdittrackboxcustomersComponent },  
      { path: 'trackmyboxsubscriptioncheckout', component: TrackmyboxsubscriptioncheckoutComponent },     
    ]
  }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,RouterModule.forRoot(trackmyboxRoutes)
  ],
  exports: [RouterModule]
})
export class TrackmyboxRouterModule { }
