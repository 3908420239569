import { Component, OnInit } from '@angular/core';
import { ContainercompanyService } from './containercompany.service';
import { Router } from '@angular/router';
import { Company } from 'src/app/model/company.model';

@Component({
  selector: 'app-containercompany',
  templateUrl: './containercompany.component.html',
  styleUrls: ['./containercompany.component.css']
})
export class ContainercompanyComponent implements OnInit {

  p = 1;
  company: Company;

  constructor(private containercompanyService: ContainercompanyService , private router: Router) { }

  ngOnInit() {
    this.containercompanyService.containerCustomerList()
      .subscribe( data => {
        this.company = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
