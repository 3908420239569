import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CompanyJobSlot } from 'src/app/model/companyjobslot.model';

@Injectable({
  providedIn: 'root'
})
export class JobplanService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'newBasicBuy.do';
  private preUrl = ShipStreetProps.API_PATH + 'newPremiumBuy.do';
  private ultUrl = ShipStreetProps.API_PATH + 'newUltimateBuy.do';
  private quoteUrl = ShipStreetProps.API_PATH + 'getPricingQuote.do';

  public newBasicBuy(jobSlot: CompanyJobSlot) {
    return this.http.post<string>(this.appUrl , jobSlot);
  }

  public newPremiumBuy(jobSlot: CompanyJobSlot) {
    return this.http.post<string>(this.preUrl , jobSlot);
  }

  public newUltimateBuy(jobSlot: CompanyJobSlot) {
    return this.http.post<string>(this.ultUrl , jobSlot);
  }

  public getPricingQuote() {
    return this.http.get<string>(this.quoteUrl);
  }
}
