import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tbagentbooking',
  templateUrl: './tbagentbooking.component.html',
  styleUrls: ['./tbagentbooking.component.css']
})
export class TbagentbookingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
