import { Candidates } from '../../../model/candidate.model';
import { CandidateMessage } from '../../../model/candidateMessage.model';
import { CandidateMessageMessage } from '../../../model/candidateMessageMessage.model';
import { CandidatemessageService } from './candidatemessage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { Observable , interval} from 'rxjs';
import { CommonService } from '../../../utils/common.service';
import { SessionCandidate } from '../../../model/sessioncandidate.model';

@Component({
  selector: 'app-candidatemessage',
  templateUrl: './candidatemessage.component.html',
  styleUrls: ['./candidatemessage.component.css']
})
export class CandidatemessageComponent implements OnInit {
  candidateMessagesPrev: CandidateMessageMessage[];
  msgmessage: CandidateMessageMessage;
  candidateMessages: CandidateMessage[];
    imagePath = ShipStreetProps.AWS_IMAGE_PATH;
    showme: boolean;


  constructor(private candidatemessageService: CandidatemessageService , private route: ActivatedRoute , private router: Router ,
              private commonServices: CommonService) { }

  // Observable.interval(10000).takeWhile(() => true).subscribe(() => this.function());


   loadmessageId: any;

  ngOnInit() {

    // this.sesCandidate = this.commonServices.getSessionCandidate();

    this.candidatemessageService.candidateMessages()
      .subscribe( data => {
        this.candidateMessages = data;
        this.viewPrevDetails(this.candidateMessages && this.candidateMessages[0].id);
        this.loadmessageId = this.candidateMessages[0].id;
      },
      error => {
        this.router.navigate(['']);
        });
    this.msgmessage = new CandidateMessageMessage();
    this.msgmessage.messageId = new CandidateMessage();
    this.msgmessage.sender = new Candidates();
   // this.msgmessage.messageId.id = +this.route.snapshot.paramMap.get('id');
   // this.msgmessage.sender.id = +this.route.snapshot.paramMap.get('canId');


    // this.candidatemessageService.updateReadStatus(this.route.snapshot.paramMap.get('id'))
    //   .subscribe( data => {
    //     this.candidateMessages = data;
    //   },
    //   error => {
    //     this.router.navigate(['']);
    //     });

    // interval(1000).subscribe(
    //      (value: number) => {
    //        this.candidatemessageService.candidateMessagesView(this.loadmessageId)
    //         .subscribe( data => {
    //          // this.msgmessage.messageId.id = this.messageId;
    //           this.candidateMessagesPrev = data;
    //         },
    //         error => {
    //           this.router.navigate(['']);
    //           });
    //         });

  }
  viewDetails(messageId) {
    this.loadmessageId = messageId;
    this.candidatemessageService.candidateMessagesView(messageId)
      .subscribe( data => {
        this.msgmessage.messageId.id = messageId;
        this.candidateMessagesPrev = data;
        this.candidatemessageService.updateReadStatus(messageId)
      .subscribe( data1 => {
        this.candidateMessages = data1;
      },
      error => {
        this.router.navigate(['']);
        });
      },
      error => {
        this.router.navigate(['']);
        });
  }

  viewPrevDetails(messageId) {
    this.candidatemessageService.candidateMessagesView(messageId)
      .subscribe( data => {
        this.msgmessage.messageId.id = messageId;
        this.candidateMessagesPrev = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }


  sendReplyMsg() { // alert(JSON.stringify(message));
  //  this.msgmessage.messageId = message;
    this.candidatemessageService.candidateMessageReply(this.msgmessage)
      .subscribe( data => {
         this.candidateMessagesPrev = data;
         this.msgmessage.message = '';
      },
      error => {
        this.router.navigate(['']);
        });
 }

 markUnread(messageId) {
    this.candidatemessageService.markUnread(messageId)
      .subscribe( data => {
         this.candidateMessages = data;
      },
      error => {
        this.router.navigate(['']);
        });
 }

 delete(messageId) {
    this.candidatemessageService.delete(messageId)
      .subscribe( data => {
         this.candidateMessages = data;
      },
      error => {
        this.router.navigate(['']);
        });
 }


}
