import { CandidateSkill } from '../../model/candidateSkill.model';
import { SkillsService } from './skills.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  showskill: boolean;
  skil: CandidateSkill;
  skilDiv: boolean;
  candidateSkills: CandidateSkill[];
  newskill: CandidateSkill;
  showme: boolean;


  constructor(private skillsService: SkillsService, private router: Router) { }

  ngOnInit() {
    this.showskill = false;
    this.skilDiv = true;
    this.newskill = new CandidateSkill();
    this.skillsService.candidateSkills()
      .subscribe(data => {
        this.candidateSkills = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveSkills() {
    this.skillsService.saveType(this.newskill)
      .subscribe(data => {
        this.candidateSkills.push(this.newskill);
        this.newskill = new CandidateSkill();
        this.showskill = false;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateCandidateSkill(skil: CandidateSkill) {
    this.skillsService.updateCandidateSkill(skil)
      .subscribe(data => {
        skil.skilDiv = false;
      },
        error => {
          this.router.navigate(['']);
        });
  }
  deleteSkill(skil) {
    this.skillsService.deleteSkill(skil)
      .subscribe(data => {
        this.candidateSkills = data;
        skil.skilDiv = false;
      },
        error => {
          this.router.navigate(['']);
        });
  }
}
