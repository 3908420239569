import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackerDesignation } from 'src/app/model/trackerdesignation.module';

@Injectable({
  providedIn: 'root'
})
export class NewtrackerdesignationService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/trackermaster/saveTrackerDesignation.do';

  public saveTrackerDesignation(trackerdesignation: TrackerDesignation) {
    return this.http.post<string>(this.appUrl, trackerdesignation);
  }
}
