import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PurchasedcontainersService } from './purchasedcontainers.service';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ContainerPurchaseDTO } from 'src/app/model/ContainerPurchaseDTO.model';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';

@Component({
  selector: 'app-purchasedcontainers',
  templateUrl: './purchasedcontainers.component.html',
  styleUrls: ['./purchasedcontainers.component.css']
})
export class PurchasedcontainersComponent implements OnInit {

  isOpen: false;
  purchasedUnits: ContainerPurchasedUnits[];
  containerpurchasedto: ContainerPurchaseDTO;

  constructor(private containerpurchasedetailsService: PurchasedcontainersService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.containerpurchasedto = new ContainerPurchaseDTO();
    this.containerpurchasedto.purchase = new ContainerPurchase();
    this.containerpurchasedto.purchaseUnit = new ContainerPurchasedUnits();

    this.containerpurchasedetailsService.getAllPurchasedContainers(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.purchasedUnits = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  removeContainerFromPurchase(purchaseUnitId) {
    this.containerpurchasedto.purchase.id = Number(this.route.snapshot.paramMap.get('id'));
    this.containerpurchasedto.purchaseUnit.id = purchaseUnitId;
    this.containerpurchasedetailsService.removeContainerFromPurchase(this.containerpurchasedto)
      .subscribe(data => {
        this.purchasedUnits = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
