import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerleaseService } from './containerlease.service';
import { ContainerLease } from 'src/app/model/containerlease.model';

@Component({
  selector: 'app-containerlease',
  templateUrl: './containerlease.component.html',
  styleUrls: ['./containerlease.component.css']
})
export class ContainerleaseComponent implements OnInit {
  leaseList: ContainerLease[];
  p = 1;

  constructor(private containerleaseService: ContainerleaseService, private router: Router) { }

  ngOnInit() {
    this.containerleaseService.findAllContainerLeaseListByCompany()
      .subscribe(data => {
        this.leaseList = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
