import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsInvoice } from 'src/app/model/accountsnvoice.model';
import { SalesService } from './sales.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {

  saleInvoiceListAll: AccountsInvoice[];
  saleInvoiceList: AccountsInvoice[];
  p = 1;

  constructor(private salesService: SalesService, private router: Router) { }

  ngOnInit() {

    this.salesService.getSalesInvoicesList()
            .subscribe(data => {
                this.saleInvoiceListAll = data;
                this.saleInvoiceList = this.saleInvoiceListAll;
            },
                error => {
                    this.router.navigate(['']);
                });
  }


}
