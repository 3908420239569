import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';
import { MasterPortVesselOperatorCosts } from 'src/app/model/masterportvesseloperatorcosts.model';

@Injectable({
  providedIn: 'root'
})
export class CostcalculatorService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'costcalculator/fetchPortCostData.do';
  private appFinalUrl = ShipStreetProps.API_PATH + 'costcalculator/fetchFinalPortCostData.do';
  private applegoneUrl = ShipStreetProps.API_PATH + 'costcalculator/fetchSlotCostLegOneData.do';
  private applegtwoUrl = ShipStreetProps.API_PATH + 'costcalculator/fetchSlotCostLegTwoData.do';
  private emptyRepoUrl = ShipStreetProps.API_PATH + 'costcalculator/fetchEmptyRepoData.do';
  private emptyRepoFinalUrl = ShipStreetProps.API_PATH + 'costcalculator/fetchEmptyRepoFinalPort.do';
  private exportPodUrl = ShipStreetProps.API_PATH + 'costcalculator/fetchExportFromPodData.do';
  private transhipmentUrl = ShipStreetProps.API_PATH + 'costcalculator/fetchTranshipmentPortData.do';

  public fetchPortCostData(costObj: MasterPortCostDetails) {
    return this.http.post<MasterPortCostDetails>(this.appUrl, costObj);
  }

  public fetchFinalPortCostData(costObj: MasterPortCostDetails) {
    return this.http.post<MasterPortCostDetails>(this.appFinalUrl, costObj);
  }

  public fetchSlotCostLegOneData(costObj: MasterPortCostDetails) {
    return this.http.post<MasterPortVesselOperatorCosts>(this.applegoneUrl, costObj);
  }

  public fetchSlotCostLegTwoData(costObj: MasterPortCostDetails) {
    return this.http.post<MasterPortVesselOperatorCosts>(this.applegtwoUrl, costObj);
  }

  public fetchEmptyRepoData(costObj: MasterPortCostDetails) {
    return this.http.post<MasterPortVesselOperatorCosts>(this.emptyRepoUrl, costObj);
  }

  public fetchEmptyRepoFinalPort(costObj: MasterPortCostDetails) {
    return this.http.post<MasterPortCostDetails>(this.emptyRepoFinalUrl, costObj);
  }

  public fetchExportFromPodData(costObj: MasterPortCostDetails) {
    return this.http.post<MasterPortCostDetails>(this.exportPodUrl, costObj);
  }

  public fetchTranshipmentPortData(costObj: MasterPortCostDetails) {
    return this.http.post<MasterPortCostDetails>(this.transhipmentUrl, costObj);
  }
}
