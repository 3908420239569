import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewcontainertypeService } from 'src/app/containers/master/containertype/newcontainertype/newcontainertype.service';
import { Containers } from 'src/app/model/containers.model';
import { ContainerType } from 'src/app/model/containertype.model';

@Component({
  selector: 'app-newcontainertype',
  templateUrl: './newcontainertype.component.html',
  styleUrls: ['./newcontainertype.component.css']
})
export class NewcontainertypeComponent implements OnInit {
  containers: Containers;
  containerType: ContainerType;

  constructor( private newcontainertypeService: NewcontainertypeService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.containerType = new ContainerType();
  }
  saveContainerType() {
    this.newcontainertypeService.saveContainerType(this.containerType)
    .subscribe(data => {
    this.router.navigate(['/trackmybox/containertypes']);
     },
       error => {
         this.router.navigate(['']);
       });
  //  alert(this.containers)
  }

  }



