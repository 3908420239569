import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';

@Injectable({
  providedIn: 'root'
})
export class AddpurchasecontainerService {

  private saveUrl = ShipStreetProps.API_PATH + 'containerpurchasedunits/saveContainerPurchasedUnits';
  constructor(private http: HttpClient) { }

  public saveContainerPurchasedUnits(units: ContainerPurchasedUnits) {
    return this.http.post<string>(this.saveUrl , units);
  }

}
