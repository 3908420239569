import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';
import { VendorsService } from '../../admin/vendors/vendors.service';
import { PurchasebysupplierreportService } from '../purchasebysupplierreport/purchasebysupplierreport.service';
import { SalesbycustomerreportService } from './salesbycustomerreport.service';

@Component({
  selector: 'app-salesbycustomerreport',
  templateUrl: './salesbycustomerreport.component.html',
  styleUrls: ['./salesbycustomerreport.component.css']
})
export class SalesbycustomerreportComponent implements OnInit {

  vendors: ContainerSupplier[];
  myControlCustomer = new FormControl('', [Validators.required]);
  filteredOptionsCustomer: Observable<ContainerCustomer[]>;
  p = 1;
  customerList: ContainerCustomer[];
  sales: ContainerSales;
  salesList: ContainerSales[];

  constructor(private router: Router , private salesbycustomerreportService: SalesbycustomerreportService) { }

  ngOnInit() {
    this.sales = new ContainerSales();
    this.salesbycustomerreportService.containerCustomerList()
      .subscribe(data => {
        this.customerList = data;
        this.filteredOptionsCustomer = this.myControlCustomer.valueChanges.pipe(
          map(val => this._filtersupp(val))
        );
      },
        () => {
          this.router.navigate(['']);
        });

  }

  public _filtersupp(value: string): ContainerCustomer[] {
    const filterValuecust = value;
    return this.customerList.filter(customerList => customerList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
    customerList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
    customerList.name.toUpperCase().indexOf(filterValuecust) === 0);
  }

  public displayCustomer(cntr: ContainerSupplier): string {
    if (cntr && cntr.name) {
      return cntr.name;
    }
  }

  getSalesByCustomer() {
    this.salesbycustomerreportService.getSalesByCustomer(this.sales)
      .subscribe(data => {
        this.salesList = data;
      },
        () => {
          this.router.navigate(['']);
        });
  }

}
