import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalcChargeHeads } from 'src/app/model/calcchargeheads.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditlocalchargeheadsService {

  constructor(private http: HttpClient) { }

  private findUrl = ShipStreetProps.API_PATH + 'calcchargeheads/findCalcChargeHead.do';
  private appUrl = ShipStreetProps.API_PATH + 'calcchargeheads/createChargeHeads.do';

  findCalcChargeHead(chargehead: string) {
    return this.http.post<CalcChargeHeads>(this.findUrl , chargehead);
  }
  createChargeHeads(chargeheads: CalcChargeHeads) {
    return this.http.post<string>(this.appUrl , chargeheads);
  }
}
