import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidatebyname',
  templateUrl: './candidatebyname.component.html',
  styleUrls: ['./candidatebyname.component.css']
})
export class CandidatebynameComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
