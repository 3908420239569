import { Component, OnInit } from '@angular/core';
import { TrackBoxAgentSearchDTO } from 'src/app/model/trackboxagentsearchDTO.model';
import { SearchboxprincipleService } from './searchboxprinciple.service';
import { Router } from '@angular/router';
import { Company } from 'src/app/model/company.model';

@Component({
  selector: 'app-searchboxprinciple',
  templateUrl: './searchboxprinciple.component.html',
  styleUrls: ['./searchboxprinciple.component.css']
})
export class SearchboxprincipleComponent implements OnInit {
  newtrackboxagent: TrackBoxAgentSearchDTO;
  companyList: Company[];
  noRecordsMsg = '';
  p = 1;

  constructor(private searchboxprincipleService: SearchboxprincipleService, private router: Router) { }

  ngOnInit() {
    this.newtrackboxagent = new TrackBoxAgentSearchDTO();
  }

  findPrincipleCompany() {
    this.searchboxprincipleService.findPrincipleCompany(this.newtrackboxagent)
      .subscribe(data => {
        this.companyList = data;
        // this.router.navigate(['trackmybox/searchboxagent']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  addprinciple(principleId) {}

}
