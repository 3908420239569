import { TrackBoxCarrier } from './../../../model/trackboxcarrier.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TrackboxcarriersService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'trackboxcarriersave/viewCarrier.do';

  getAllMyCarriers() {
    return this.http.get<TrackBoxCarrier[]>(this.appUrl );
  }

}
