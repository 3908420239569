import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackerEmployees } from 'src/app/model/trackeremployees.model';

@Injectable({
  providedIn: 'root'
})
export class NewtrackeremployeeService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + '/trackeremployee/saveTrackerEmployee.do';

  public saveTrackerEmployee(trackeremployee: TrackerEmployees) {
    return this.http.post<string>(this.appUrl, trackeremployee);
  }
}
