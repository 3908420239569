import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsAccount } from 'src/app/model/accountsaccount.model';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';
import { AccountsProductServices } from 'src/app/model/accountsproductservices.model';
import { AccountsTax } from 'src/app/model/accountstax.model';
import { NewproductservicedetailsService } from '../newproductservices/newproductservicedetails/newproductservicedetails.service';
import { EditproductservicesService } from './editproductservices.service';

@Component({
  selector: 'app-editproductservices',
  templateUrl: './editproductservices.component.html',
  styleUrls: ['./editproductservices.component.css']
})
export class EditproductservicesComponent implements OnInit {

  categoryList: AccountsProductCategory[];
  assetsList: AccountsAccount[];
  incomeList: AccountsAccount[];
  expensseList: AccountsAccount[];
  taxList: AccountsTax[];
  productnservice: AccountsProductServices;
  constructor(private editproductservicesService: EditproductservicesService, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
  //  this.productnservice = new AccountsProductServices();
    this.productnservice.purchaseTax = new AccountsTax();
    this.productnservice.expenseAccount = new AccountsAccount();
    this.productnservice.salesTax = new AccountsTax();
    this.productnservice.incomeAccount = new AccountsAccount();
    this.productnservice.inventoryAssets = new AccountsAccount();
    this.productnservice.category = new AccountsProductCategory();

    // this.editproductservicesService.getSalesProductAndService(this.route.snapshot.paramMap.get('id'))
    //   .subscribe(data => {
    //     this.productnservice = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    // this.editproductservicesService.getAccountsCategory()
    //   .subscribe(data => {
    //     this.categoryList = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    // this.editproductservicesService.getAccountsInventoryAssets()
    //   .subscribe(data => {
    //     this.assetsList = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    // this.editproductservicesService.getAccountsIncome()
    //   .subscribe(data => {
    //     this.incomeList = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    // this.editproductservicesService.getAccountsExpenses()
    //   .subscribe(data => {
    //     this.expensseList = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    // this.editproductservicesService.getAccountsTaxList()
    //   .subscribe(data => {
    //     this.taxList = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

  }

  newproductnservice() {
    // this.editproductservicesService.newproductnservice(this.productnservice)
    //   .subscribe(data => {
    //   //  this.taxList = data;
    //   this.router.navigate(['/accounts/salesproductsnservices']);
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });
  }

}
