import { TbagentblupdateService } from './tbagentblupdate.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { BlUpdateDTO } from 'src/app/model/blupdateDTO.model';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

export interface DialogData {
  bookingId: number;
}
@Component({
  selector: 'app-tbagentblupdate',
  templateUrl: './tbagentblupdate.component.html',
  styleUrls: ['./tbagentblupdate.component.css']
})
export class TbagentblupdateComponent implements OnInit {

  tbbooking: BlUpdateDTO;
  trackboxbookingcontainersList: TrackBoxBookingContainers[];
  booking: TrackBoxBooking;

  constructor(private tbagentblupdateService: TbagentblupdateService, private router: Router, private route: ActivatedRoute,
    public dialogoutRef: MatDialogRef<TbagentblupdateComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.tbbooking = new BlUpdateDTO();
    this.booking = new TrackBoxBooking();
    this.booking.id = this.data.bookingId;
    // this.tbbooking.containers = new TrackBoxContainer();
    this.tbagentblupdateService.getbookedContainers(this.data.bookingId)
      .subscribe(data => {
        this.trackboxbookingcontainersList = data;
        //   alert(JSON.stringify(this.trackboxbookingcontainersList));
      },
        error => {
          this.router.navigate(['']);
        });
  }

  blupdate() {
    // this.tbbooking.bookingId = this.data.leaseUnitId;
    console.log(JSON.stringify(this.trackboxbookingcontainersList));
    this.tbbooking = { booking: this.booking, containers: this.trackboxbookingcontainersList, blNumber: this.tbbooking.blNumber };

    this.tbagentblupdateService.updateBLNumber(this.tbbooking)
      .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 500,

        });
        this.dialogoutRef.close(0);
      },

        error => {
          this.router.navigate(['']);
        });

  }


  onNoClick(): void {
    this.dialogoutRef.close();

  }


}
