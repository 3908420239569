import { Component, OnInit } from '@angular/core';
import { ContainersalesreleaseunitService } from './containersalesreleaseunit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ContainerSalesUnits } from 'src/app/model/containersalesunits.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ContainerReleaseUnitDTO } from 'src/app/model/containerreleaseunitDTO.model';

@Component({
  selector: 'app-containersalesreleaseunit',
  templateUrl: './containersalesreleaseunit.component.html',
  styleUrls: ['./containersalesreleaseunit.component.css']
})
export class ContainersalesreleaseunitComponent implements OnInit {
  purchaseUnitList: ContainerPurchasedUnits[];
  saleunit: ContainerSalesUnits;
  saledtounit: ContainerReleaseUnitDTO;


  constructor(private containersalesreleaseunitService: ContainersalesreleaseunitService, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.saleunit = new ContainerSalesUnits();
    this.saleunit.sales = new ContainerSales();
    this.saleunit.purchaseUnit = new ContainerPurchasedUnits();
    //  this.containerList = new ContainerPurchasedUnits();

    this.containersalesreleaseunitService.getavailablesalesunits(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.purchaseUnitList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  addContainertoSales() {
    let containerList: Array<ContainerPurchasedUnits> = [];
    for (var product of this.purchaseUnitList) {
      if (product.selectForBooking) {
        //  alert(JSON.stringify(product));
        containerList.push(product);
        //  alert(JSON.stringify(containerList));
      }
      // console.log(containerList)
    }

    this.saledtounit = {
      purchaseUnitId: this.saleunit.purchaseUnit.id, containers: containerList, salesDate: this.saleunit.salesDate,
      salesId: Number(this.route.snapshot.paramMap.get('id')), vatAmount: this.saleunit.vatAmount
    };
    this.containersalesreleaseunitService.addContainertoSales(this.saledtounit)
      .subscribe(data => {
        //    this.unitList = data;
        alert('ADDED SUCCESSFULLY!!!')
        this.router.navigate(['containers/containersales']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
