import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Company } from 'src/app/model/company.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { NewboxprincipleService } from './newboxprinciple.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-newboxprinciple',
  templateUrl: './newboxprinciple.component.html',
  styleUrls: ['./newboxprinciple.component.css']
})
export class NewboxprincipleComponent implements OnInit {
  hecked = false;


  newboxagent: TrackBoxAgent[];
  countryList: CountryInfo[];
  countryid: CountryInfo;
  companyList: Company[];
  companies: Company;
  newtrackboxagent: TrackBoxAgent;
  filteredOptionsContry: Observable<CountryInfo[]>;
  myControl1 = new FormControl();

  myControl = new FormControl('1');
  books: Array<{ id: string; title: string }> = [
    { id: '1', title: 'Book 1' },
    { id: '2', title: 'Book 2' },
    { id: '3', title: 'Book 3' },
    { id: '4', title: 'Book 4' },
  ];
  filteredOptionsCompany: Observable<Company[]>;
  //filteredOptionsCompanys: Company[];
  myControl2 = new FormControl();

  constructor(private newboxprincipleService: NewboxprincipleService, private router: Router) { }

  ngOnInit() {
    this.newtrackboxagent = new TrackBoxAgent();
    this.newtrackboxagent.company = new Company();
    this.newtrackboxagent.country = new CountryInfo();



    this.newboxprincipleService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newboxprincipleService.viewprincipleCompany()
      .subscribe(data => {
        this.companyList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.filteredOptionsContry = this.myControl1.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter1(val))
    );

    this.filteredOptionsCompany = this.myControl2.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter2(val))
    );
  }

  public _filter1(value: string): CountryInfo[] {
    const filterValue1 = value;
    return this.countryList.filter(country => country.name.toLowerCase().indexOf(filterValue1) === 0 ||
      country.name.toLowerCase().indexOf(filterValue1) === 0 ||
      country.name.toUpperCase().indexOf(filterValue1) === 0);
  }

  public _filter2(value: string): Company[] {
    const filterValue2 = value;
    return this.companyList.filter(company => company.companyName.toLowerCase().indexOf(filterValue2) === 0 ||
      company.companyName.toLowerCase().indexOf(filterValue2) === 0 ||
      company.companyName.toUpperCase().indexOf(filterValue2) === 0);
  }

  displayCountry(cntr: CountryInfo): string {
    return cntr && cntr.name ? cntr.name + ' - ' + cntr.code2 : '';
  }

  displayFn(user: Company): string {
    return user && user.companyName ? user.companyName : '';
  }

  saveTrackBoxPrinciple() {
    this.newboxprincipleService.saveTrackBoxPrinciple(this.newtrackboxagent)
      .subscribe(data => {
        this.router.navigate(['tbagentmode/boxprinciples']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  findPrincipleCompany() {
    this.newboxprincipleService.findPrincipleCompany(this.newtrackboxagent)
      .subscribe(data => {
        this.companyList = data;
        this.router.navigate(['tbagentmode/boxprinciples']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
}
