import { Candidates } from './candidate.model';
import { Posts } from './posts.model';
export class PostComment {
      id: number;
      post: Posts;
      candidate: Candidates;
      comments: string;
      commentedOn: Date;
      commentByLogCand: boolean;
      commentStatus: string;
}
