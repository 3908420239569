import { Company } from './company.model';

export class CompanyJobSlot {
    id: number;
    company: Company;
    slot: boolean;
    numberSlot: number;
    duration: number;
    usedCount: number;
    startDate: Date;
    endDate: Date;
    active: boolean;
    amount: number;
    basicSlot: number;
    premiumSlot: number;
    ultimateSlot: number;
    basicDuration: number;
    premiumDuration: number;
    ultimateDuration: number;
}
