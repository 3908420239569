import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpCentre } from '../model/helpcentre.model';
import { HelpcentreserviceService } from './helpcentreservice.service';
import { SurviceRequests } from '../model/survicerequests.model';

@Component({
  selector: 'app-helpcentre',
  templateUrl: './helpcentre.component.html',
  styleUrls: ['./helpcentre.component.css']
})
export class HelpcentreComponent implements OnInit {
  serviceRequest: SurviceRequests;

  constructor(private helpcentreservice: HelpcentreserviceService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.serviceRequest = new SurviceRequests();
  }
  onSubmitHelp() {
      this.helpcentreservice.helpsave(this.serviceRequest)
        .subscribe(data => {
          this.router.navigate(['/m/survicerequestlist']);
        },
          error => {
            this.router.navigate(['']);
          });
    
  }

}
