import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchaseBalance } from 'src/app/model/containerpurchasebalance.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerpurchaseexpenseService {

  private listUrl = ShipStreetProps.API_PATH + 'containerpurchasebalance/containerPurchaseBalanceList';

  constructor(private http: HttpClient) { }

  public containerPurchaseBalanceList() {
    return this.http.get<ContainerPurchaseBalance[]>(this.listUrl);
  }


}
