import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { EditdepotsService } from './editdepots.service';

@Component({
  selector: 'app-editdepots',
  templateUrl: './editdepots.component.html',
  styleUrls: ['./editdepots.component.css']
})

export class EditdepotsComponent implements OnInit {
  depot: MasterPortsDepot;
  services = new FormControl();
  CountryList: CountryInfo[];
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;

  serviceList: string[] = ['Container Storage', 'Container repair and maintenance', 'Container trading', 'Container Modification'
    , 'Transportation Services', 'Container Inspection', 'Reefer PTI', 'Clip on Genset Rental'];
  

  constructor(private editdepotsService: EditdepotsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.depot = new MasterPortsDepot();
    this.depot.country = new CountryInfo();

    
    this.editdepotsService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter4(val))
    );

      },
        error => {
          this.router.navigate(['']);
        });
    
        this.editdepotsService.getDepots(this.route.snapshot.paramMap.get('id'))
        .subscribe(data => {
            this.depot = data;
        },
            error => {
                this.router.navigate(['']);
            });
  }

        public _filter4(value: string): CountryInfo[] {
          const filterValue4 = value;
          return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
            country.name.toLowerCase().indexOf(filterValue4) === 0 ||
            country.name.toUpperCase().indexOf(filterValue4) === 0);
        }
      
        public displayCountry(cntr: CountryInfo): string {
          if (cntr && cntr.name) {
            return cntr.name + '-' + cntr.code2;
      
          }
        }
   

updateDepots() {
    
    this.editdepotsService.updateDepots(this.depot)
        .subscribe(data => {
            this.router.navigate(['/trackmybox/depots']);
        },
            error => {
                this.router.navigate(['']);
            });
          }
        }
        export function RequireMatch(control: AbstractControl) {
          const selection: any = control.value;
          // alert(selection)
          if (typeof selection === 'string') {
              return { incorrect: true };
          }
          return null;
        }
        