import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';

@Injectable({
  providedIn: 'root'
})
export class PurchasebysupplierreportService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'containerpurchase/getPurchaseBySupplier.do';

  public getPurchaseBySupplier(purchase: ContainerPurchase) {
    return this.http.post<ContainerPurchase[]>(this.appUrl, purchase);
  }
}
