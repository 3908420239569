import { TrackBoxContainer } from './../../../../model/trackboxcontainer.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UpdateequipmentService } from './updateequipment.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackboxContainerStatus } from 'src/app/trackmybox/trackboxcontainerstatus.model';
export interface DialogData {
  leaseUnitId: number;
}

@Component({
  selector: 'app-updateequipment',
  templateUrl: './updateequipment.component.html',
  styleUrls: ['./updateequipment.component.css']
})
export class UpdateequipmentComponent implements OnInit {

  trackboxeqpcontainer: TrackBoxContainer;

  // trackboxbookingcontainer: TrackBoxBookingContainers;
  containerstatusList: TrackboxContainerStatus[];

  constructor(private updateequipmentService: UpdateequipmentService, private router: Router, private route: ActivatedRoute,
              public dialogoutRef: MatDialogRef<UpdateequipmentComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.trackboxeqpcontainer = new TrackBoxContainer();
    this.trackboxeqpcontainer.containerStatus = new TrackboxContainerStatus();
    // this.containerstatusList = new TrackboxContainerStatus();
  //  this.updateequipmentService.getTbeqpContainersByID(this.route.snapshot.params.id);
    //   .subscribe(data => {
    //     this.bookingcontainers = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    this.updateequipmentService.getContainerStatus()
      .subscribe(data => {
        this.containerstatusList = data;
        // alert(JSON.stringify(this.containerstatusList));
      },
        error => {
          this.router.navigate(['']);
        });

  }

  updateContainerStatus() {
    this.trackboxeqpcontainer.id = this.data.leaseUnitId;
    this.updateequipmentService.updateContainerStatus(this.trackboxeqpcontainer)
      .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 2000,

        });
        this.dialogoutRef.close(0);

      },

        error => {
          this.router.navigate(['']);
        });

  }


  onNoClick(): void {
    this.dialogoutRef.close();

  }

}
