import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-useragreement',
  templateUrl: './useragreement.component.html',
  styleUrls: ['./useragreement.component.css']
})
export class UseragreementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
