import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxContainerSupplier } from 'src/app/model/trackboxcontainersupplier.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EdittrackboxsupplierService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxcontainersupplier/updatetbsuppliers';
  private appUrls = ShipStreetProps.API_PATH + 'trackboxcontainersupplier/getallSuppliers';

public updateSupplier(trackboxsupplier: TrackBoxContainerSupplier) {
  return this.http.post<string>(this.appUrl, trackboxsupplier);
}

public getallSuppliers(id: string) {
  return this.http.post<TrackBoxContainerSupplier>(this.appUrls, id);
}

}
