import { Candidates } from './candidate.model';
import { Marketplace } from './marketplace.model';
import { Company } from './company.model';
export class MarketplaceOrder {
    id: number;
    candidate: Candidates;
    createdOn: Date;
    orderNumber: String;
    marketplace: Marketplace;
    company: Company;
    quantity: number;
}
