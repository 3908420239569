import { CountryInfo } from "./countryInfo.model";

export class SearchByCountryDTO {
   country: CountryInfo;
  //  port: MasterPortsDepot;
  //  depot: MasterPortsDepot;
  //  containerType: ContainerType;
  //  carrier: TrackBoxCarrier; */

  //  country: number;
  //  port: number;
  //  depot: number;
  //  containerType = [] ;
  //  carrier: number;
 }
