import { Component, OnInit } from '@angular/core';
import { ContainertypeService } from './containertype.service';
import { Router } from '@angular/router';
import { ContainerType } from 'src/app/model/containertype.model';

@Component({
  selector: 'app-containertype',
  templateUrl: './containertype.component.html',
  styleUrls: ['./containertype.component.css']
})
export class ContainertypeComponent implements OnInit {
  containertype: ContainerType[];
  p = 1;

  constructor(private containertypeService: ContainertypeService, private router: Router) { }

  ngOnInit() {
    this.containertypeService.findAllContainerType()
      .subscribe(data => {
        this.containertype = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
