import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewtrackeremployeeService } from './newtrackeremployee.service';
import { TrackerEmployees } from 'src/app/model/trackeremployees.model';
import { TrackerDepartment } from 'src/app/model/trackerdepartment.model';
import { TrackerDesignation } from 'src/app/model/trackerdesignation.module';
import { TrackerdepartmentService } from '../../master/trackerdepartment/trackerdepartment.service';
import { TrackerdesignationService } from '../../master/trackerdesignation/trackerdesignation.service';

@Component({
  selector: 'app-newtrackeremployee',
  templateUrl: './newtrackeremployee.component.html',
  styleUrls: ['./newtrackeremployee.component.css']
})
export class NewtrackeremployeeComponent implements OnInit {
  trackeremployee: TrackerEmployees;
  trackerDepartmentList: TrackerDepartment[];
  trackerDesignationList: TrackerDesignation[];


  constructor(private newtrackeremployeeService: NewtrackeremployeeService, private router: Router,
    private trackerdepartmentService: TrackerdepartmentService, private trackerdesignationService: TrackerdesignationService) { }

  ngOnInit() {
    this.trackeremployee = new TrackerEmployees();
    this.trackeremployee.department = new TrackerDepartment();
    this.trackeremployee.designation = new TrackerDesignation();

    this.trackerdepartmentService.getAllTrackerDepartmentList()
      .subscribe(data => {
        this.trackerDepartmentList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.trackerdesignationService.getAllTrackerDesignationList()
      .subscribe(data => {
        this.trackerDesignationList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }
  saveTrackerEmployee() {
    this.newtrackeremployeeService.saveTrackerEmployee(this.trackeremployee)
      .subscribe(data => {
        this.router.navigate(['tracker/trackeremployees']);
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
