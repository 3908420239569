import { SessionCandidate } from './../../model/sessioncandidate.model';
import { Candidates } from '../../model/candidate.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CandidateEmailNotification } from 'src/app/model/candidateemailnotification.model';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {
 constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'sessionCandidate.do';
  private preferenceUrl = ShipStreetProps.API_PATH + 'candidateEmailPreferences.do';
  private upPassUrl = ShipStreetProps.API_PATH + 'updateCandidatePassWord.do';
  private savephoneUrl = ShipStreetProps.API_PATH + 'savePhone.do';
  private deactivateUrl = ShipStreetProps.API_PATH + 'candidateAccountDeactivate.do';
  private connectionEmailUrl = ShipStreetProps.API_PATH + 'updateConnectionEmailSettings.do';
  private followEmailUrl = ShipStreetProps.API_PATH + 'updateFollowEmailSettings.do';
  private feedsEmailUrl = ShipStreetProps.API_PATH + 'updateFeedsEmailSettings.do';
  private feedsshareEmailUrl = ShipStreetProps.API_PATH + 'updateFeedShareEmailSettings.do';
  private feedsCommentsEmailUrl = ShipStreetProps.API_PATH + 'updateFeedCommentsEmailSettings.do';
  private feedsLikesEmailUrl = ShipStreetProps.API_PATH + 'updateFeedLikesEmailSettings.do';
  private jobsEmailUrl = ShipStreetProps.API_PATH + 'updateJobsEmailSettings.do';
  private postsEmailUrl = ShipStreetProps.API_PATH + 'updatePostsEmailSettings.do';
  private postLikeEmailUrl = ShipStreetProps.API_PATH + 'updatePostLikeEmailSettings.do';
  private postCommentsEmailUrl = ShipStreetProps.API_PATH + 'updatePostCommentEmailSettings.do';

  public sessionCandidate() {
    return this.http.get<Candidates>(this.appUrl);
  }

  public candidateEmailPreferences() {
    return this.http.get<CandidateEmailNotification>(this.preferenceUrl);
  }

   public updateCandidatePassWord(newpassword: string) {
    return this.http.post<string>(this.upPassUrl , newpassword);
  }

  /**
   * savephone
   */
  public savephone(candidate: Candidates) {
    return this.http.post<string>(this.savephoneUrl , candidate);
  }
  public accountDeactivate(candidate: Candidates) {
    return this.http.post<Candidates>(this.deactivateUrl , candidate);
  }

  public updateConnectionEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.connectionEmailUrl , candidateemail);
  }

  public updateFollowEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.followEmailUrl , candidateemail);
  }

  public updateFeedsEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.feedsEmailUrl , candidateemail);
  }

  public updateFeedShareEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.feedsshareEmailUrl , candidateemail);
  }

  public updateFeedCommentsEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.feedsCommentsEmailUrl , candidateemail);
  }

  public updateFeedLikesEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.feedsLikesEmailUrl , candidateemail);
  }

  public updateJobsEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.jobsEmailUrl , candidateemail);
  }

  public updatePostsEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.postsEmailUrl , candidateemail);
  }

  public updatePostLikeEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.postLikeEmailUrl , candidateemail);
  }

  public updatePostCommentEmailSettings(candidateemail: CandidateEmailNotification) {
    return this.http.post<string>(this.postCommentsEmailUrl , candidateemail);
  }
}
