import { Candidates } from '../../model/candidate.model';
import { CandidateaboutService } from './candidateabout.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-candidateabout',
  templateUrl: './candidateabout.component.html',
  styleUrls: ['./candidateabout.component.css']
})
export class CandidateaboutComponent implements OnInit {
  appReqDiv: boolean;
  candidate: Candidates;
  bntStyle: string;
  myControl = new FormControl();
  sexList = [
        {value: 'Male', text: 'Male'},
        {value: 'Female', text: 'Female'}
    ];
  maritalStatusList = [
        {value: 'Single', text: 'Single'},
        {value: 'Married', text: 'Married'},
        {value: 'Separated', text: 'Separated'},
        {value: 'Divorced', text: 'Divorced'},
        {value: 'Widowed', text: 'Widowed'}
    ];

  constructor(private candidateAboutService: CandidateaboutService , private router: Router) { }

  ngOnInit() {
    this.appReqDiv = true;

    this.candidateAboutService.sessioncandidate()
      .subscribe( data => {
        this.candidate = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }
  updateAbout() {
    this.candidateAboutService.updateAbout(this.candidate)
      .subscribe( data => {
        this.candidate = data;
        this.appReqDiv = true;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
