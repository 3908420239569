import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EdittbagentbookingService {

constructor(private http: HttpClient) { }
private updateUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/updateBookings.do';
public updateBookings(tbbooking: TrackBoxBooking) {
  return this.http.post<string>(this.updateUrl , tbbooking);
}
}
