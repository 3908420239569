import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newmasterproducts',
  templateUrl: './newmasterproducts.component.html',
  styleUrls: ['./newmasterproducts.component.css']
})
export class NewmasterproductsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
