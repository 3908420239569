import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newlocationtype',
  templateUrl: './newlocationtype.component.html',
  styleUrls: ['./newlocationtype.component.css']
})
export class NewlocationtypeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
