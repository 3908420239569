import { CandidateExperience } from '../../model/candidateExperience.model';
import { Company } from '../../model/company.model';
import { ExperienceService } from './experience.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { CompaniesService } from 'src/app/company/companies/companies.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent implements OnInit {
  showExpIcon: boolean;
  showme: boolean;
  expDiv: boolean;
  companies: Company[];
  experience: CandidateExperience;
  myControl = new FormControl();
  newexperience: CandidateExperience;
  candidateExperience: CandidateExperience[];
  formControl: FormControl;

  constructor(private experienceService: ExperienceService, private companiesService: CompaniesService, private router: Router) { }

  ngOnInit() {
    this.showme = false;
    this.expDiv = true;
    this.showExpIcon = false;
    this.newexperience = new CandidateExperience();
    this.newexperience.company = new Company();
    this.formControl = new FormControl();

    this.experienceService.candidateExperience()
      .subscribe(data => {
        this.candidateExperience = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.companiesService.getAllCompanies()
      .subscribe(data => {
        this.companies = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }
  saveExperience() {
    this.experienceService.saveType(this.newexperience)
      .subscribe(data => {
        this.candidateExperience = data;
        this.newexperience = new CandidateExperience();
        this.newexperience.company = new Company();
        this.showme = false;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateExperience(experience: CandidateExperience) {
    this.experienceService.updateExperience(experience)
      .subscribe(data => {
        experience.expDiv = false;
      },
        error => {
          this.router.navigate(['']);
        });
  }
  deleteExperience(experience: CandidateExperience) {
    this.experienceService.deleteExperience(experience)
      .subscribe(data => {
        this.candidateExperience = data;
        //   experience.expDiv = false;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  newcompany() {
    alert("hi");
  }

}
