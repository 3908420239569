import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerSales } from 'src/app/model/containersales.model';
import { PresalesService } from './presales.service';

@Component({
  selector: 'app-presales',
  templateUrl: './presales.component.html',
  styleUrls: ['./presales.component.css']
})
export class PresalesComponent implements OnInit {

  
  p = 1;
  panelOpenState: boolean;
  searchText: string;
  presalesList: any;
  presalesListAll: any;
  

  constructor(private presalesService: PresalesService, private router: Router) { }

  ngOnInit() {
    this.presalesService.findAllContPreSalesByCompany()
      .subscribe(data => {
        this.presalesListAll = data;
        this.presalesList = this.presalesListAll;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  searchcntsales() {
    if (this.searchText !== '') {
      this.presalesList = this.presalesListAll.filter(res => {
        return res.customer.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
          res.type.eqType.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    } else if (this.searchText === '') {
      this.presalesList = this.presalesListAll;
    }
  }

}
