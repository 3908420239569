import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewsoldsalesunitsService } from './viewsoldsalesunits.service';
import { ContainerSalesUnits } from 'src/app/model/containersalesunits.model';
import { ContainerSoldUnits } from 'src/app/model/containersoldunit.model';

@Component({
  selector: 'app-viewsoldsalesunits',
  templateUrl: './viewsoldsalesunits.component.html',
  styleUrls: ['./viewsoldsalesunits.component.css']
})
export class ViewsoldsalesunitsComponent implements OnInit {

  saleUnits: ContainerSalesUnits[];
  soldunit: ContainerSoldUnits;

  constructor(private viewsoldsalesunitsService: ViewsoldsalesunitsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.soldunit = new ContainerSoldUnits();
      this.viewsoldsalesunitsService.findSoldSalesUnits(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.saleUnits = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  DeleteSalesUnit(saleUnit , purchaseUnit) {
  // alert(saleUnit +"--"+ purchaseUnit);
  this.soldunit.salesId = Number(this.route.snapshot.paramMap.get('id'));
    this.soldunit.saleunit = saleUnit;
    this.soldunit.purchaseunit = purchaseUnit;
    this.viewsoldsalesunitsService.DeleteSalesUnit(this.soldunit)
      .subscribe(data => {
    //    this.saleUnits = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
