import { CompanyPortDepot } from 'src/app/model/companyportdepot.model';
import { Company } from 'src/app/model/company.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Injectable({
  providedIn: 'root'
})
export class TbagentdepotService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'masterdepots/masterDepotsAgentView.do';
  private appsaveManagedDepot = ShipStreetProps.API_PATH + 'masterdepots/saveManagedDepot.do';

  getAllMyDepots() {
    return this.http.get<MasterPortsDepot[]>(this.appUrl);
  }
  public saveManagedDepot(depotId: CompanyPortDepot) {
    // alert(JSON.stringify(depotId));
    return this.http.post<CompanyPortDepot>(this.appsaveManagedDepot, depotId);
  }
}
