import { MasterVesselOperator } from './mastervesseloperator.model';

export class MasterVessels {
    id: number;
    vesselOperator: MasterVesselOperator;
    name: string;
    imoNumber: string;
    built: Date;
    teusCapacity: number;
    grossTonage: number;
    netTonnage: number;
    deadWeightTonnage: number;
    length: number;
    portOfRegistry: string;
    callSign: string;
    mmsi: number;
    builder: string;
    cost: number;
    beam: string;
    depth: string;
    installedPower: string;
    propulsion: string;
    speed: string;
    crew: number;
    laidDown: Date;
    launched: Date;
    inService: Date;
    status: string;

}
