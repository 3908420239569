import { Company } from '../../model/company.model';
import { Jobs } from '../../model/jobs.model';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class JobdetailsService {

  constructor(private http: HttpClient) { }
   private appUrl = ShipStreetProps.API_PATH + 'jobObjectById.do';
  private jobListUrl = ShipStreetProps.API_PATH + 'jobListByCompanyID.do';
  private similarJobList = ShipStreetProps.API_PATH + 'similarJobList.do';
  private applyJobList = ShipStreetProps.API_PATH + 'candidateJobApply.do';
  private morejobsList = ShipStreetProps.API_PATH + 'jobListByCompanyID.do';
  private jobviewurl = ShipStreetProps.API_PATH + 'saveViewJobs.do';

  public getJobById(id: string) {
    return this.http.post<Jobs>(this.appUrl , id);
  }

  public jobListByCompanyID(compId: Company) {
    return this.http.post<Company[]>(this.jobListUrl , compId);
  }
  public getSimilarJobList(job: Jobs) {
    return this.http.post<Jobs[]>(this.similarJobList , job);
  }
  public jobapply(job: string) {
    return this.http.post<string>(this.applyJobList , job);
  }
   public morejobs(job: string) {
    return this.http.post<Jobs[]>(this.morejobsList , job);
  }
  public updateJobViews(job: string) {
    return this.http.post<string>(this.jobviewurl , job);
  }
}
