import { ImportbookingService } from './importbooking.service';
import { Component, OnInit } from '@angular/core';
import { BookingsService } from 'src/app/trackmybox/bookings/bookings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

@Component({
  selector: 'app-importbooking',
  templateUrl: './importbooking.component.html',
  styleUrls: ['./importbooking.component.css']
})
export class ImportbookingComponent implements OnInit {
  bookingsList: TrackBoxBooking[];
  p = 1;
  bookingsListAll: TrackBoxBooking[];
  searchText: string;

  constructor(private importbookingService: ImportbookingService, private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    // alert(JSON.stringify(this.route.snapshot.params.id))
    this.importbookingService.getAllMyImportBookings()
      .subscribe(data => {
        this.bookingsListAll = data;
        this.bookingsList = this.bookingsListAll;
      },
        error => {
          this.router.navigate(['']);
        });
  }

    searchbar() {
     if (this.searchText !== '') {
      this.bookingsList = this.bookingsListAll.filter(res => {
        return res.bookingNo.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.vessel.masterVessel.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.voyageNumber.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.pol.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.pod.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())
      });
    } else if (this.searchText === '') {
      this.bookingsList = this.bookingsListAll;
    } 
  }


}
