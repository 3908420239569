import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountsPaymentDTO } from 'src/app/model/accountspaymentDTO.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class IncomeService {

  private listUrl = ShipStreetProps.API_PATH + 'containersales/containerSalesbyId';
  private payUrl = ShipStreetProps.API_PATH + 'containersales/containersalesmakepayment';

  constructor(private http: HttpClient) { }

  public containerSalesBalancbyId(containersalesbalId: string) {
    return this.http.post<ContainerSales[]>(this.listUrl, containersalesbalId);
  }

   public salesmakepayment(accountsPaymentDTO: AccountsPaymentDTO) {
    return this.http.post<ContainerSales[]>(this.payUrl, accountsPaymentDTO);
  }

}
