import { CountryInfo } from 'src/app/model/countryInfo.model';
export class MasterPortsDepot {
  id: number;
  name: string;
  portCode: string;
  location: string;
  phone: string;
  fax: string;
  city: string;
  state: string;
  country: CountryInfo;
  webSite: string;
  postBox: string;
  unloCode: string;
  operatedBy: string;
  portStartedOn: Date;
  noTerminals: number;
  availableBirths: number;
  annualContainerVolume: number;
  volumeUpdatedOn: Date;
  coordinatesNorth: string;
  coordinatesEast: string;
  coordinatesSouth: string;
  coordinatesWest: string;
  selectManagedDepo:boolean;
  errorString: string;
}
