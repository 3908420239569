import { Component, OnInit } from '@angular/core';
import { NewtrackerdesignationService } from './newtrackerdesignation.service';
import { Router } from '@angular/router';
import { TrackerDesignation } from 'src/app/model/trackerdesignation.module';

@Component({
  selector: 'app-newtrackerdesignation',
  templateUrl: './newtrackerdesignation.component.html',
  styleUrls: ['./newtrackerdesignation.component.css']
})
export class NewtrackerdesignationComponent implements OnInit {

  trackerdesignation: TrackerDesignation;

  constructor(private newtrackerdesignationService: NewtrackerdesignationService, private router: Router) { }

  ngOnInit() {
    this.trackerdesignation = new TrackerDesignation();
  }

  saveTrackerDesignation() {
    this.newtrackerdesignationService.saveTrackerDesignation(this.trackerdesignation)
      .subscribe(data => {
        this.router.navigate(['tracker/trackerdesignations']);
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
