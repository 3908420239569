import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { PoliciesContent } from 'src/app/model/policiescontent.model';

@Injectable({
  providedIn: 'root'
})
export class PrivacypolicyService {

  constructor(private http: HttpClient) { }

  private fetchUrl = ShipStreetProps.API_PATH + '/policy/findaAllPolicy.do';

  public findaAllPolicy() {
    return this.http.get<PoliciesContent[]>(this.fetchUrl);
  }

}
