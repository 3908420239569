import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgentPrincipleFreight } from 'src/app/model/agentprinciplefreight.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class RequestfromagentService {

  constructor(private http: HttpClient) { }


  private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/viewAgentRequests.do';
  private agentUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/getAgentPriceRequests.do';
  private appurlDetails = ShipStreetProps.API_PATH + 'trackboxagent/viewRequestDetailsById.do';
  private appUrlconfirm = ShipStreetProps.API_PATH + 'TrackboxBookings/updateconfirmFreight.do';

  getAgentRequests() {
    return this.http.get<AgentPrincipleFreight[]>(this.appUrl);
  }
  getAgentPriceRequests(bookingId: number) {
    return this.http.post<AgentPrincipleFreight[]>(this.agentUrl , bookingId);
  }
  getRequestDetailsById(bookingId: number) {
    return this.http.post<AgentPrincipleFreight>(this.appurlDetails, bookingId);
  }

  public confirmApprove(freightId: number) {
    return this.http.post<AgentPrincipleFreight>(this.appUrlconfirm, freightId);
  }
}
