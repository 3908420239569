import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerpurchasedetailsService {

   private objUrl = ShipStreetProps.API_PATH + 'containerpurchase/getSingleContainerPurchase';

  constructor(private http: HttpClient) { }

  public getSingleContainerPurchase(purchaseId: string) {
    return this.http.post<ContainerPurchase>(this.objUrl , purchaseId);
  }
}
