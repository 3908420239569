import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConsigneepaymentapprovalsService {

  constructor(private http: HttpClient) { }

    // private appUrl = DepotProps.API_PATH + '/accountdashboard/getConsigneePaymentApprovalsList.do';
    // private appRepUrl = DepotProps.API_PATH + 'repairBillApprove.do';

    // public getConsigneePaymentApprovalsList() {
    //     return this.http.get<Estimate[]>(this.appUrl);
    // }

    // public repairBillApprove(custBillId: number) {
    //     return this.http.post<string>(this.appRepUrl , custBillId);
    // }
}
