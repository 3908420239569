import { Candidates } from './candidate.model';
import { Company } from './company.model';
import { FeedsLike } from './feedLike.model';
import { FeedsComments } from './feedsComments.model';
import { Jobs } from './jobs.model';
export class Feeds {
    id: number;

    candidate: Candidates;
    emoji: number;
    feedscomment: FeedsComments[];
    feedsLikeList: FeedsLike[];
    job: Jobs;
    feedsContent: string;
    noLike: number;
    noComments: number;
    postedOn: string;
    feedImage: string;
    comlimit = 3;
    company: Company;
    feedBy: string;
    ownerId: number;
    sharingCount: number;
    originalPost: Feeds;
    feedByLogCand: boolean;
    // String feedType="text/plain";
    feedType: string;
    postTime: string;
    feedOwner: Candidates;
    sharedPost: boolean;
    likeByLoggedInCandidate: boolean;
    showMoreComments: boolean;
    comdiv = false;
    newComments: FeedsComments;
    noEmojiLike: boolean;
    noEmojiLove: boolean;
    noEmojiHaha: boolean;
    noEmojiWow: boolean;
    noEmojiSad: boolean;

    emojiLike: boolean;
    emojiLove: boolean;
    emojiHaha: boolean;
    emojiWow: boolean;
    emojiSad: boolean;
    originalOwnerCompany: boolean;
}
