import { Component, OnInit } from '@angular/core';
import { PrivacypolicyService } from './privacypolicy.service';
import { Router } from '@angular/router';
import { PoliciesContent } from 'src/app/model/policiescontent.model';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {
  policyContentList: PoliciesContent[];
  p = 1;

  constructor(private privacypolicyService: PrivacypolicyService, private router: Router) { }

  ngOnInit() {
    this.privacypolicyService.findaAllPolicy()
        .subscribe(data => {
          this.policyContentList = data;
        },
          error => {
            this.router.navigate(['']);
          });
  }

}
