import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Posts } from 'src/app/model/posts.model';

@Injectable({
  providedIn: 'root'
})
export class RecentpostsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'getAllNews.do';

  public getPosts() {
    return this.http.get<Posts[]>(this.appUrl);
  }
}
