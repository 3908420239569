import { ContainerType } from './containertype.model';
import { ContainerCustomer } from './containercustomer.model';
import { ContainerCompany } from './containercompany.model';
import { ContainerPurchase } from './containerpurchase.model';
import { Taxes } from './taxes.model';
import { AccountsPaymentMode } from './accountspaymentmode.model';

export class ContainerSales {
    id: number;
    customer: ContainerCustomer;
    company: ContainerCompany;
    purchase: ContainerPurchase;
    type: ContainerType;
    vatPaid: Taxes;
    paymentMode : AccountsPaymentMode;
    quantity: number;
    unitPrice: number;
    total: number;
    salesDate: Date;
    vatAmount: number;
    grandTotal: number;
    invoiceNumber: number;
    invoiceDate: Date;
    selectForIncome: boolean;
    receivedAmount: number;
    balanceAmount: number;
    quantitySold: number;
    stackChange: boolean;
}
