import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})


export class EquipmentcontroltypelistService {

constructor(private http: HttpClient) { }

private appUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/getEquipmentcontrolTypeList.do';

public getEquipmentcontrolTypeList(containertypelist: string) {
  //  alert(JSON.stringify(containertypelist));
    return this.http.post<TrackBoxContainer[]>(this.appUrl, containertypelist);
  }
}
