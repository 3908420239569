import { Component, OnInit } from '@angular/core';
import { CandidateSkill } from '../../../model/candidateSkill.model';
import { AddskillsService } from './addskills.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addskills',
  templateUrl: './addskills.component.html',
  styleUrls: ['./addskills.component.css']
})
export class AddskillsComponent implements OnInit {
  newskill: CandidateSkill;
  candidateSkills: CandidateSkill[];
  showskill: boolean;

  constructor(private addSkillsService: AddskillsService , private router: Router) { }

  ngOnInit() {
    this.newskill = new CandidateSkill;
  }

  saveSkills() {
    this.addSkillsService.saveType(this.newskill)
      .subscribe( data => {
       // this.candidateSkills = data;
       this.newskill.skillCategoryExp = '';
        this.candidateSkills.push(this.newskill);
        this.showskill = false;
      },
      error => {
        this.router.navigate(['']);
        });
    }

}
