import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomersService } from 'src/app/containers/admin/customers/customers.service';
import { ContainertypeService } from 'src/app/containers/master/containertype/containertype.service';
import { TaxesService } from 'src/app/containers/master/taxes/taxes.service';
import { ContainerCompany } from 'src/app/model/containercompany.model';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { Taxes } from 'src/app/model/taxes.model';
import { CommonService } from 'src/app/utils/common.service';
import { ContainerstackchangeService } from './containerstackchange.service';

@Component({
  selector: 'app-containerstackchange',
  templateUrl: './containerstackchange.component.html',
  styleUrls: ['./containerstackchange.component.css']
})
export class ContainerstackchangeComponent implements OnInit {

  isOpen: false;
  purchasedUnits: ContainerPurchasedUnits[];
  customers: ContainerCustomer[];
  sales: ContainerSales;
  conttypeList: ContainerType[];
  taxeslist: Taxes[];
  myControlcustomer = new FormControl('', [Validators.required , RequireMatch]);
  filteredOptionscustomer: Observable<ContainerCustomer[]>;

  constructor(private containerstackchangeService: ContainerstackchangeService, private router: Router,
              private route: ActivatedRoute, private customersService: CustomersService,
              private containertypeService: ContainertypeService, private commonService: CommonService,
              private taxesService: TaxesService) { }

  sessionCandidate = this.commonService.getSessionCandidate();
  ngOnInit() {
    this.sales = new ContainerSales();
    this.sales.company = new ContainerCompany();
    this.sales.customer = new ContainerCustomer();
    this.sales.type = new ContainerType();
    this.sales.purchase = new ContainerPurchase();
    this.sales.vatPaid = new Taxes();

    this.customersService.containerCustomerList()
      .subscribe(data => {
        this.customers = data;
        this.filteredOptionscustomer = this.myControlcustomer.valueChanges.pipe(
          map(val => this._filtercust(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });

    this.containertypeService.findAllContainerTypeByOrder()
      .subscribe(data => {
        this.conttypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.taxesService.findAllTaxes()
      .subscribe(data => {
        this.taxeslist = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  sellthisunit() {

  }

  saveContainerStackChange() {
    this.sales.company.id = this.sessionCandidate.containerCompany;
    this.sales.purchase.id = Number(this.route.snapshot.paramMap.get('id'));
    this.containerstackchangeService.saveContainerStackChange(this.sales)
      .subscribe(data => {
        this.router.navigate(['containers/containersales']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

    public _filtercust(value: string): ContainerCustomer[] {
    const filterValuecust = value;
    return this.customers.filter(customer => customer.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      customer.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      customer.name.toUpperCase().indexOf(filterValuecust) === 0);
  }

  public displayCustomer(cntr: ContainerCustomer): string {
    if (cntr && cntr.name) {
      return cntr.name;
    }
    }
}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}