import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-market-layout',
  templateUrl: './market-layout.component.html',
  styleUrls: ['./market-layout.component.css']
})
export class MarketLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
