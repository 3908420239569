import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobs113',
  templateUrl: './jobs113.component.html',
  styleUrls: ['./jobs113.component.css']
})
export class Jobs113Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
