import { Component, OnInit } from '@angular/core';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';
import { Router } from '@angular/router';
import { VesseloperatorcostinglistService } from './vesseloperatorcostinglist.service';
import { MasterPortVesselOperatorCosts } from 'src/app/model/masterportvesseloperatorcosts.model';

@Component({
  selector: 'app-vesseloperatorcostinglist',
  templateUrl: './vesseloperatorcostinglist.component.html',
  styleUrls: ['./vesseloperatorcostinglist.component.css']
})
export class VesseloperatorcostinglistComponent implements OnInit {

  portcosts: MasterPortCostDetails[];
  searchText: string;
  p = 1;
  vocosts: MasterPortVesselOperatorCosts[];
  vocostsAll: MasterPortVesselOperatorCosts[];

  constructor(private vesseloperatorcostinglistService: VesseloperatorcostinglistService, private router: Router) { }

  ngOnInit() {

    this.vesseloperatorcostinglistService.getVesselOperatorCosts()
      .subscribe(data => {
        this.vocostsAll = data;
        this.vocosts = this.vocostsAll;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  searchbar() { // alert("hi");
    if (this.searchText !== '') {
      this.vocosts = this.vocostsAll.filter(res => {
        return (res.vesselOperator !== null && res.vesselOperator.name.toLowerCase().match(this.searchText.toLocaleLowerCase())) ||
        (res.loadport !== null && res.loadport.name.toLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.finalport !== null && res.finalport.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.vocosts = this.vocostsAll;
    }
  }

}
