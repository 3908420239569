import { Candidates } from '../../model/candidate.model';
import { ConnectionList } from '../../model/connectionList.model';
import { SearchbarService } from './searchbar.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CandidateCount } from '../../model/candidatecount.model';
import { CommonService } from '../../utils/common.service';
import { CookieService } from 'ngx-cookie-service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent implements OnInit {
  friendsRequestCount: CandidateCount;
  leng: ConnectionList[];
  candidate: Candidates;
  searchTxt: string;

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  sessionCandidate: SessionCandidate;

  constructor(private searchBarService: SearchbarService, private router: Router, private commonService: CommonService) { }

  ngOnInit() {
    this.searchBarService.sessionCandidate()
      .subscribe(data => {
        this.candidate = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.sessionCandidate = this.commonService.getSessionCandidate();


    // this.searchBarService.requestedConnections()
    //   .subscribe( data => { // alert(data.length)
    //      // this.friendsRequestCount = data.length;
    // },
    //   error => {
    //     this.router.navigate(['']);
    //     });

    // this.searchBarService.connectionCount()
    //   .subscribe( data => {
    //       this.friendsRequestCount = data;
    // },
    //   error => {
    //     this.router.navigate(['']);
    //     });
  }

  globalSearch() {
  //  alert('globalSearch');
    this.router.navigate(['/m/globelsearch', this.searchTxt]);
  }

  onKeydown(event) {
  //  alert('onKeydown');
    if (event.key === 'Enter') {
      this.router.navigate(['/m/globelsearch', this.searchTxt]);
    }
  }

  logout() {
    this.searchBarService.logout()
      .subscribe(data => {
        this.commonService.removeRSCookieValues();
        // this.sessioncandidate = data;
        this.router.navigate(['']);
      },
        error => {
          this.router.navigate(['']);
        });

  }

  aboutWindow() {
    window.open('http://about.anweigh.com/');
  }

  businessWindow() {
    window.open('http://business.anweigh.com/');
  }

  helpWindow() {
    window.open('http://help.anweigh.com/');
  }


}
