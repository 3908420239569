import { Company } from './company.model';
import { CountryInfo } from './countryInfo.model';
export class TrackBoxAgent {
    id: number;
    company: Company;
    country: CountryInfo;
    agent: Company;
    createdOn: Date;
    licenseCode: string;
    status: string;
}
