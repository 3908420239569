import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { Taxes } from 'src/app/model/taxes.model';

@Component({
  selector: 'app-editpresales',
  templateUrl: './editpresales.component.html',
  styleUrls: ['./editpresales.component.css']
})
export class EditpresalesComponent implements OnInit {
  sales: ContainerSales;
  conttypeList: ContainerType[];
  taxeslist: Taxes[];
  myControlcustomer = new FormControl();

  constructor() { }

  ngOnInit() {
    this.sales = new ContainerSales();
  //  this.conttypeList = new ContainerType();
  }

  updateContainerSales(){}

}
