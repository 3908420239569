import { Company } from '../../model/company.model';
import { CompaniesService } from './companies.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {
  comp: Company;
  companiesList: Company[];
  mycompanies: Company[];
  companies: Company[];
  p = 1;
  unfollowBut = false;
  searchText: string;
  skipicon: number;
  breakpoint: number;
  constructor(private companiesService: CompaniesService , private router: Router) { }
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  ngOnInit() {

     this.companiesService.getAllCompanies()
      .subscribe( data => {
        this.companies = data;
      },
      error => {
        this.router.navigate(['']);
        });

        this.breakpointFun(event)

  }

  onResize(event) {
    this.breakpointFun(event)
  }

  breakpointFun(event?: any): void{
    if(event){
      this.breakpoint = (event.target.innerWidth <= 681) ? 2 : (event.target.innerWidth <= 1000) ? 4 : (event.target.innerWidth <= 1300) ? 4 : 4;
    } else {
      this.breakpoint = (window.innerWidth <= 681) ? 2 : (window.innerWidth <= 1000) ? 4 : (window.innerWidth <= 1300) ? 4 : 4;
    }
  }

  companyFollow(companyId) {
    this.companiesService.companyFollow(companyId)
      .subscribe( data => {
        this.comp = data;
        this.companiesList = this.companies;
        for ( let i = 0; i < this.companiesList.length ; i++) {
        // alert(JSON.stringify(companiesList[i]));
         if (this.companiesList[i].id === companyId) {
           this.companies[i].followedByCandidate = this.comp.followedByCandidate;
           this.companies[i].employeeCount = this.comp.employeeCount;
           this.companies[i].followCount = this.comp.followCount;
          }
       }
      },
      error => {
        this.router.navigate(['']);
        });
  }

  companyUnFollow(comId) {
    this.companiesService.companyUnFollow(comId)
      .subscribe( data => {
        this.comp = data;
        this.companiesList = this.companies;
        for ( let i = 0; i < this.companiesList.length ; i++) {
        // alert(JSON.stringify(companiesList[i]));
         if (this.companiesList[i].id === comId) {
           this.companies[i].followedByCandidate = this.comp.followedByCandidate;
           this.companies[i].employeeCount = this.comp.employeeCount;
           this.companies[i].followCount = this.comp.followCount;
          }
       }
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
