import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { calculatorRoutes } from './calculator.router';

import {
  MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
  MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
  MatBottomSheetModule, MatAutocompleteModule, MatDatepickerModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { CostcalculatorsettingsComponent } from './costcalculatorsettings/costcalculatorsettings.component';
import { CostcalculatorComponent } from './costcalculator/costcalculator.component';
import { CostcalculatorsettinglistComponent } from './costcalculatorsettinglist/costcalculatorsettinglist.component';
import { CostcalculatorsettingdetailsComponent } from './costcalculatorsettingdetails/costcalculatorsettingdetails.component';
import { VesseloperatorcostsComponent } from './vesseloperatorcosts/vesseloperatorcosts.component';
import { EditcostcalculatorsettingComponent } from './editcostcalculatorsetting/editcostcalculatorsetting.component';
import { VesseloperatorcostdetailsComponent } from './vesseloperatorcostdetails/vesseloperatorcostdetails.component';
import { VesseloperatorcosteditComponent } from './vesseloperatorcostedit/vesseloperatorcostedit.component';
import { VesseloperatorcostinglistComponent } from './vesseloperatorcostinglist/vesseloperatorcostinglist.component';
import { MastersettingsComponent } from './mastersettings/mastersettings.component';
import { CalcvesseloperatorComponent } from './calcvesseloperator/calcvesseloperator.component';
import { AddcalcvesseloperatorComponent } from './calcvesseloperator/addcalcvesseloperator/addcalcvesseloperator.component';
import { EditcalcvesseloperatorComponent } from './calcvesseloperator/editcalcvesseloperator/editcalcvesseloperator.component';
import { CalculatorusersComponent } from './calculatorusers/calculatorusers.component';
import { AddcalculatorusersComponent } from './calculatorusers/addcalculatorusers/addcalculatorusers.component';
import { NewcalculatorusersComponent } from './newcalculatorusers/newcalculatorusers.component';
import { CalculatorRouterModule } from './calculator-router.module';
import { CalcequipmenttypesComponent } from './mastersettings/calcequipmenttypes/calcequipmenttypes.component';
import { NewcalcequipmenttypeComponent } from './mastersettings/calcequipmenttypes/newcalcequipmenttype/newcalcequipmenttype.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { CalculatorpricingComponent } from './calculatorpricing/calculatorpricing.component';
import { CalculatorsubscriptioncheckoutComponent } from './calculatorsubscriptioncheckout/calculatorsubscriptioncheckout.component';
import { LocalchargeheadsComponent } from './mastersettings/localchargeheads/localchargeheads.component';
import { NewlocalchargeheadsComponent } from './mastersettings/localchargeheads/newlocalchargeheads/newlocalchargeheads.component';
import { EditlocalchargeheadsComponent } from './mastersettings/localchargeheads/editlocalchargeheads/editlocalchargeheads.component';
import { CalculatorlocalchargesComponent } from './calculatorlocalcharges/calculatorlocalcharges.component';
import { CalculatornewlocalchargesComponent } from './calculatorlocalcharges/calculatornewlocalcharges/calculatornewlocalcharges.component';


@NgModule({
  declarations: [CostcalculatorComponent, CostcalculatorsettingsComponent, CostcalculatorsettinglistComponent,
    EditcostcalculatorsettingComponent, CostcalculatorsettingdetailsComponent, VesseloperatorcostsComponent,
    VesseloperatorcostinglistComponent, VesseloperatorcosteditComponent, VesseloperatorcostdetailsComponent,
    MastersettingsComponent, CalcvesseloperatorComponent, AddcalcvesseloperatorComponent, EditcalcvesseloperatorComponent, 
    CalculatorusersComponent, AddcalculatorusersComponent, NewcalculatorusersComponent, CalcequipmenttypesComponent,
    NewcalcequipmenttypeComponent,
    CalculatorpricingComponent,
    CalculatorsubscriptioncheckoutComponent,
    LocalchargeheadsComponent,
    NewlocalchargeheadsComponent,
    EditlocalchargeheadsComponent,
    CalculatorlocalchargesComponent,
    CalculatornewlocalchargesComponent],
  imports: [
    CommonModule, CalculatorRouterModule, MatToolbarModule, MatButtonModule,
    MatSidenavModule, MatIconModule, MatListModule,MatProgressSpinnerModule, MatSlideToggleModule,
    MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
    MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
    MatBottomSheetModule, MatAutocompleteModule, MatDatepickerModule, FlexLayoutModule,
    FormsModule, ReactiveFormsModule, NgxPaginationModule, NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule
  ]
})
export class CalculatorModule { }
