import { Candidates } from '../../model/candidate.model';
import { CandidateConnect } from '../../model/candidateConnect.model';
import { Dashboard } from '../../model/dashboard.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class YourupdatesService {

   constructor(private http: HttpClient) { }

   private appUrl = ShipStreetProps.API_PATH + 'sessionCandidate.do';
  private myFeedsUrl = ShipStreetProps.API_PATH + 'getMyFeeds.do';
  private myFriendListUrl = ShipStreetProps.API_PATH + 'myFriendsList.do';

  public sessioncandidate() {
    return this.http.get<Candidates>(this.appUrl);
  }

   public getMyFeeds() {
    return this.http.get<Dashboard>(this.myFeedsUrl);
  }
   public getMyFriends() {
    return this.http.get<CandidateConnect[]>(this.myFriendListUrl);
  }
}
