import { Candidates } from '../../model/candidate.model';
import { CandidateMessage } from '../../model/candidateMessage.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {
//  canMessage: CandidateMessage;

  private canUrl = ShipStreetProps.API_PATH + 'candidate.do';
  private followUrl = ShipStreetProps.API_PATH + 'candidateFollowing.do';
  private unfollowUrl = ShipStreetProps.API_PATH + 'unFollowCandidate.do';
  private newmessageUrl = ShipStreetProps.API_PATH + 'sendNewMessage.do';
  private connectUrl = ShipStreetProps.API_PATH + 'sendConnectionRequestFromCandidate.do';

  constructor(private http: HttpClient) { }

  public candidateById(candidate: string) {
    return this.http.post<Candidates>(this.canUrl , candidate);
  }
  public candidatefollow(candidate: string) {
    return this.http.post<Candidates>(this.followUrl , candidate);
  }
   public candidateUnfollow(candidate: string) {
    return this.http.post<Candidates>(this.unfollowUrl , candidate);
  }
   public candidateconnect(candidate: Candidates) {
    return this.http.post<Candidates>(this.connectUrl , candidate);
  }
   public sendMessage(message: CandidateMessage ) {
    return this.http.post<string>(this.newmessageUrl , message);
  }

}
