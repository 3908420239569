import { Component, OnInit } from '@angular/core';
import { Candidates } from 'src/app/model/candidate.model';
import { AccountactivationcodeverifyService } from './accountactivationcodeverify.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-accountactivationcodeverify',
  templateUrl: './accountactivationcodeverify.component.html',
  styleUrls: ['./accountactivationcodeverify.component.css']
})
export class AccountactivationcodeverifyComponent implements OnInit {

    candidate: Candidates;
    id: number;

  constructor(private accountactivationcodeverifyService: AccountactivationcodeverifyService, private router: Router ,
    private route: ActivatedRoute) { }

  ngOnInit() {
      this.candidate = new Candidates;
     //  this.id = ;
      this.candidate.id = parseInt(this.route.snapshot.paramMap.get("id"));

  }

  activateAccount() {
      this.accountactivationcodeverifyService.accountactivate(this.candidate)
				.subscribe(data => {
                    this.router.navigate(['/accountactivated']);
                }); 
  }

}
