import { Component, OnInit } from '@angular/core';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';
import { EditcontainercustomerService } from './editcontainercustomer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-editcontainercustomer',
  templateUrl: './editcontainercustomer.component.html',
  styleUrls: ['./editcontainercustomer.component.css']
})
export class EditcontainercustomerComponent implements OnInit {

  sessionCandidate: SessionCandidate;
  customer: ContainerCustomer;

  constructor(private editcontainercustomerService: EditcontainercustomerService, private router: Router,
              private commonService: CommonService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();

    this.editcontainercustomerService.getContainerCustomerById(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.customer = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateContainerCustomer() {
  //  this.customer.company.id = this.sessionCandidate.containerCompany;
    this.editcontainercustomerService.updateContainerCustomer(this.customer)
      .subscribe(data => {
        this.router.navigate(['containers/customers']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
