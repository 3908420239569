import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from 'src/app/model/company.model';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CommonService } from 'src/app/utils/common.service';
import { NewcustomerService } from './newcustomer.service';

@Component({
  selector: 'app-newcustomer',
  templateUrl: './newcustomer.component.html',
  styleUrls: ['./newcustomer.component.css']
})
export class NewcustomerComponent implements OnInit {

 sessionCandidate: SessionCandidate;
 customer: ContainerCustomer;

  constructor(private newcustomerService: NewcustomerService, private router: Router, private commonService: CommonService) { }

  ngOnInit() {
   this.sessionCandidate = this.commonService.getSessionCandidate();
   this.customer = new ContainerCustomer();
   this.customer.company = new Company();
  }

  saveContainerCustomer() {
    this.customer.company.id = this.sessionCandidate.containerCompany;
    this.newcustomerService.saveContainerCustomer(this.customer)
      .subscribe(data => {
        this.router.navigate(['containers/customers']);
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
