import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';

@Injectable({
  providedIn: 'root'
})
export class EditcalcvesseloperatorService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'vesseloperator/updateCompanyVesselOperator.do';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';
  private appGetUrl = ShipStreetProps.API_PATH + 'vesseloperator/vesseloperatorbyid.do';

  public getVesselOperator(masterVesselOperator: string) {
    return this.http.post<MasterVesselOperator>(this.appGetUrl, masterVesselOperator);
  }

  public updateCompanyVesselOperator(masterVesselOperator: MasterVesselOperator) {
    return this.http.post<string>(this.appUrl, masterVesselOperator);
  }

  viewCountry() {
    return this.http.get<CountryInfo[]>(this.appUrls);
  }
}
