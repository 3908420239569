import { Component, OnInit } from '@angular/core';
import { ContainerOnewayMove } from 'src/app/model/containeronewaymove.model';
import { Router } from '@angular/router';
import { ContainertypeService } from '../../master/containertype/containertype.service';
import { ContainerType } from 'src/app/model/containertype.model';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { LocationtypeService } from '../../master/locationtype/locationtype.service';
import { NewmovecontainersService } from './newmovecontainers.service';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ContainerCompany } from 'src/app/model/containercompany.model';

@Component({
  selector: 'app-newmovecontainers',
  templateUrl: './newmovecontainers.component.html',
  styleUrls: ['./newmovecontainers.component.css']
})
export class NewmovecontainersComponent implements OnInit {
  onewaymove: ContainerOnewayMove;
  containertype: ContainerType[];
  containerLocationType: ContainerLocationType[];
  sessionCandidate: SessionCandidate;

  constructor(private containertypeService: ContainertypeService, private router: Router,
              private newmovecontainersService: NewmovecontainersService, private locationtypeService: LocationtypeService,
              private commonService: CommonService) { }

  ngOnInit() {
    this.onewaymove = new ContainerOnewayMove();
    this.onewaymove.containerType = new ContainerType();
    this.onewaymove.locationType = new ContainerLocationType();
    this.onewaymove.company = new ContainerCompany();
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.containertypeService.findAllContainerTypeByOrder()
      .subscribe(data => {
        this.containertype = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.locationtypeService.findAllContainerLocationTypeByOrder()
      .subscribe(data => {
        this.containerLocationType = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  saveContainerOnewayMove() {
    this.onewaymove.company.id = this.sessionCandidate.containerCompany;
    this.newmovecontainersService.saveContainerOnewayMove(this.onewaymove)
      .subscribe(data => {
        this.router.navigate(['/containers/movecontainers']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
