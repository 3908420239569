import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsPaymentTerms } from 'src/app/model/accountspaymentterms.model';
import { AccountsSalesLocation } from 'src/app/model/accountssaleslocation.model';
import { AccountsLocation } from 'src/app/model/accountslocation.model';
import { Invoice } from 'src/app/model/invoice.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewinvoiceService {

  constructor(private http: HttpClient) { }

  private termsUrl = ShipStreetProps.API_PATH + 'getAccountsPaymentTerms.do';
  private salelocUrl = ShipStreetProps.API_PATH + 'getSalesLocation.do';
  private locUrl = ShipStreetProps.API_PATH + 'getAllLocations.do';
  private saveUrl = ShipStreetProps.API_PATH + 'saveinvoice.do';

  public getAllPaymentTerms() {
    return this.http.get<AccountsPaymentTerms[]>(this.termsUrl);
  }

  public getSalesLocation() {
    return this.http.get<AccountsSalesLocation[]>(this.salelocUrl);
  }

  public getAllLocations() {
    return this.http.get<AccountsLocation[]>(this.locUrl);
  }

   public saveinvoice(invoice: Invoice) {
    return this.http.post<string>(this.saveUrl , invoice);
  }
}
