import { CountryInfo } from './countryInfo.model';
import { ContainerLocationType } from './containerlocationtype.model';

export class ContainerPurchaseLocation {
    id: number;
    locationType: ContainerLocationType;
    name: string;
    city: string;
    state: string;
    country: CountryInfo;
}
