import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Candidates } from 'src/app/model/candidate.model';
import { AccountverificationService } from './accountverification.service';

@Component({
  selector: 'app-accountverification',
  templateUrl: './accountverification.component.html',
  styleUrls: ['./accountverification.component.css']
})
export class AccountverificationComponent implements OnInit {

  verifiedStatus: string;
  candidate: Candidates;

  constructor(private accountverificationService: AccountverificationService, private router: Router,
    private cookieService: CookieService, private snackBar: MatSnackBar, private route: ActivatedRoute) { }
 // @Output() verificationOutVar = new EventEmitter<string>();
  ngOnInit() {
    this.candidate = new Candidates();
  }
  candidateVerification() {
    this.candidate.id = Number(this.route.snapshot.params.id);
    this.accountverificationService.candidateVerification(this.candidate)
      .subscribe(data => {
        if (data.status != false) {
          this.cookieService.set('VERI_STAT', 'true');
          this.router.navigate(['m/dashboard']);
        } else {
    //      this.verificationOutVar.emit('false');
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  resendverification() {
    this.candidate.id = Number(this.route.snapshot.params.id);
    this.accountverificationService.resendVerification(this.candidate)
      .subscribe(data => {
        this.snackBar.open('Verification Code Sent Successfully', '', {
          duration: 5000
        });
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
