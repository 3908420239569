import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingsService } from '../bookings/bookings.service';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

@Component({
  selector: 'app-currentbooking',
  templateUrl: './currentbooking.component.html',
  styleUrls: ['./currentbooking.component.css']
})
export class CurrentbookingComponent implements OnInit {
  bookingList: TrackBoxBooking[];
  panelOpenState: boolean;

  constructor(private bookingsService: BookingsService, private router: Router) { }

  ngOnInit() {
     this.bookingsService.getAllCurrentBookings()
      .subscribe(data => {
        this.bookingList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
