import { TrackBoxCarrier } from './../../../../model/trackboxcarrier.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewtrackboxcarrierService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxcarriersave/saveTrackBoxCarrier.do';

  public saveTrackBoxCarrier(trackboxcarrier: TrackBoxCarrier) {
    return this.http.post<TrackBoxCarrier>(this.appUrl, trackboxcarrier);
  }
}
