import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { ContainerEmployee } from 'src/app/model/containeremployee.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private appUrl =  ShipStreetProps.API_PATH + 'containeremployees/getAllContainerEmployeesByCompanyId';
  // private empUrl = '/rigcmscore/employees/getEmployeesById';

  constructor(private http: HttpClient) { }

   public getAllEmployee() {
    return this.http.get<CompanyEmployee[]>(this.appUrl);
  }


}
