import { CountryInfo } from './countryInfo.model';

export class MasterVesselOperator {
    id: number;
    name: string;
    legalName: string;
    country: CountryInfo;
    noVessels: number;
    totalTeu: number;
    establishedOn: Date;
    headQuarters: string;
    founder: string;
    noOfEmployees: number;
    parentOrganization: string;
    descriptions: string;
}
