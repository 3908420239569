import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerLease } from 'src/app/model/containerlease.model';
import { ContainerLeasingUnit } from 'src/app/model/containerleasingunit.model';
import { ContainerleasehistorydetailsService } from './containerleasehistorydetails.service';

@Component({
  selector: 'app-containerleasehistorydetails',
  templateUrl: './containerleasehistorydetails.component.html',
  styleUrls: ['./containerleasehistorydetails.component.css']
})
export class ContainerleasehistorydetailsComponent implements OnInit {

  leaseObj: ContainerLease;
  leasingUnits: ContainerLeasingUnit[];

  constructor(private containerleasehistorydetailsService: ContainerleasehistorydetailsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.containerleasehistorydetailsService.findSingleContainerLeaseRecord(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.leaseObj = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.containerleasehistorydetailsService.findallcontainers(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.leasingUnits = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  openDialog(id: number) {}

}
