import { Router, ActivatedRoute } from '@angular/router';
import { BlupdateService } from './blupdate.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { BlUpdateDTO } from 'src/app/model/blupdateDTO.model';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

export interface DialogData {
  leaseUnitId: number;
}
@Component({
  selector: 'app-blupdate',
  templateUrl: './blupdate.component.html',
  styleUrls: ['./blupdate.component.css']
})
export class BlupdateComponent implements OnInit {
  tbbooking: BlUpdateDTO;
  trackboxbookingcontainersList: TrackBoxBookingContainers[];
  booking: TrackBoxBooking;

  constructor(private blupdateService: BlupdateService, private router: Router, private route: ActivatedRoute,
              public dialogoutRef: MatDialogRef<BlupdateComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.tbbooking = new BlUpdateDTO();
    this.booking = new TrackBoxBooking();
    this.booking.id = this.data.leaseUnitId;
   // this.tbbooking.containers = new TrackBoxContainer();
    this.blupdateService.getbookedContainers(this.data.leaseUnitId)
      .subscribe(data => {
        this.trackboxbookingcontainersList = data;
     //   alert(JSON.stringify(this.trackboxbookingcontainersList));
      },
        error => {
          this.router.navigate(['']);
        });
  }

  blupdate() {
   // this.tbbooking.bookingId = this.data.leaseUnitId;
    console.log(JSON.stringify(this.trackboxbookingcontainersList));
    this.tbbooking = { booking: this.booking, containers: this.trackboxbookingcontainersList,blNumber:this.tbbooking.blNumber };

    this.blupdateService.updateBLNumber(this.tbbooking)
      .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 500,

        });
        this.dialogoutRef.close(0);
      },

        error => {
          this.router.navigate(['']);
        });

  }


  onNoClick(): void {
    this.dialogoutRef.close();

  }

}
