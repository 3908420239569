import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Customer } from 'src/app/model/customer.model';

@Injectable({
  providedIn: 'root'
})
export class NewcustomerService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/accountscustomer/addnewcustomer.do';

  public addnewcustomer(customer: Customer) {
    return this.http.post<string>(this.appUrl , customer);
  }
}
