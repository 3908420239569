import { Posts } from '../../model/posts.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class PostdetailsService {
  constructor(private http: HttpClient) { }
  private postUrl = ShipStreetProps.API_PATH + 'getAPost.do';
  private postLikeUrl = ShipStreetProps.API_PATH + 'likePost.do';
  private postUnLikeUrl = ShipStreetProps.API_PATH + 'unLikePost.do';
  private savePostCommentUrl = ShipStreetProps.API_PATH + 'submitNewPostComment.do';
  private deletePostCommentUrl = ShipStreetProps.API_PATH + 'deletePostComment.do';

  public getAPost(postId: string) {
    return this.http.post<Posts>(this.postUrl, postId);
  }

   public likePost(postId: number) {
    return this.http.post<Posts>(this.postLikeUrl, postId);
  }

   public unLikePost(postId: number) {
    return this.http.post<Posts>(this.postUnLikeUrl, postId);
  }

   public savePostComment(posts: Posts) {
    return this.http.post<Posts>(this.savePostCommentUrl, posts);
  }

  public deletePostComment(postcommid: string) {
    return this.http.post<Posts>(this.deletePostCommentUrl, postcommid);
  }
}
