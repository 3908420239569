export class MyBoxSearchExport {
    slno: number;
    containerNumber: string;
    typeCode: string;
    yom: Date;
    gateIn: Date;
    containerStatus: string;
    port: string;
    country: string;
    carrier: string;

}

