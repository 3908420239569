import { CompanyVoyage } from './../../../model/companyvoyage.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
// import { MasterVoyage } from 'src/app/model/mastervoyage.model';

@Injectable({
  providedIn: 'root'
})
export class VoyageService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'voyage/findAllCompanyVoyage.do';
  private currVoyageUrl = ShipStreetProps.API_PATH + 'voyage/getCurrentVoyages.do';

  getAllMyVoyages() {
    return this.http.get<CompanyVoyage[]>(this.appUrl);
  }

  getCurrentVoyages() {
    return this.http.get<CompanyVoyage[]>(this.currVoyageUrl);
  }
}
