import { TrackBoxContainerSupplier } from './../../../model/trackboxcontainersupplier.model';
import { TrackBoxPurchaseStatus } from './../../../model/trackboxpurchasestatus.model';
import { TrackBoxCarrier } from './../../../model/trackboxcarrier.model';
import { ContainerLocationType } from './../../../model/containerlocationtype.model';
import { ContainerType } from './../../../model/containertype.model';
import { CountryInfo } from './../../../model/countryInfo.model';
import { Containers } from './../../../model/containers.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxContainerDTO } from 'src/app/model/trackboxcontainerDTO.model';
import { TrackboxContainerStatus } from '../../trackboxcontainerstatus.model';

@Injectable({
  providedIn: 'root'
})
export class NewequipmentcontrolService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/newcontainersadd.do';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';
  private appUrlcontainer = ShipStreetProps.API_PATH + 'containertype/findAllContainerType.do';
  private appUrllocation = ShipStreetProps.API_PATH + 'containerLocationType/findAllContainerLocationType.do';
  private appUrlviewstatus = ShipStreetProps.API_PATH + 'trackboxcontainerStatus/tbviewStatus.do';
  private appUrlcarrier = ShipStreetProps.API_PATH + 'trackboxcarriersave/viewCarrier.do';
  private appUrlpurchasestatus = ShipStreetProps.API_PATH + 'trackboxpurchasestatus/findAllTrackBoxPurchaseStatus.do';
  private appUrlcontsuplier = ShipStreetProps.API_PATH + 'trackboxcontainersupplier/findAlltrackboxContainerSupplierbyCompany.do';

  public save(trackboxcontainerDto: TrackBoxContainerDTO) {
    return this.http.post<Containers>(this.appUrl, trackboxcontainerDto);
  }

  viewCountry() {
    return this.http.get<CountryInfo[]>(this.appUrls);
  }
  viewPurchaseStatus() {
    return this.http.get<TrackBoxPurchaseStatus[]>(this.appUrlpurchasestatus);

  }

  viewcontainerType() {
    return this.http.get<ContainerType[]>(this.appUrlcontainer);

  }
  viewcontainerLocationType() {
    return this.http.get<ContainerLocationType[]>(this.appUrllocation);
  }
  viewcontainerStatus() {
    return this.http.get<TrackboxContainerStatus[]>(this.appUrlviewstatus);
  }
  viewCarrier() {
    return this.http.get<TrackBoxCarrier[]>(this.appUrlcarrier);
  }
 getAllMySupplier() {
  // alert('hi');
   return this.http.get<TrackBoxContainerSupplier[]>(this.appUrlcontsuplier );
  }


}
