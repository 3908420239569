import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculatorComponent } from './calculator.component';
import { RouterModule, Routes } from '@angular/router';
import { CalculatorlayoutComponent } from '../_layouts/calculatorlayout/calculatorlayout.component';
import { CostcalculatorComponent } from './costcalculator/costcalculator.component';
import { CostcalculatorsettingsComponent } from './costcalculatorsettings/costcalculatorsettings.component';
import { CostcalculatorsettinglistComponent } from './costcalculatorsettinglist/costcalculatorsettinglist.component';
import { CostcalculatorsettingdetailsComponent } from './costcalculatorsettingdetails/costcalculatorsettingdetails.component';
import { EditcostcalculatorsettingComponent } from './editcostcalculatorsetting/editcostcalculatorsetting.component';
import { VesseloperatorcostinglistComponent } from './vesseloperatorcostinglist/vesseloperatorcostinglist.component';
import { VesseloperatorcostsComponent } from './vesseloperatorcosts/vesseloperatorcosts.component';
import { VesseloperatorcosteditComponent } from './vesseloperatorcostedit/vesseloperatorcostedit.component';
import { VesseloperatorcostdetailsComponent } from './vesseloperatorcostdetails/vesseloperatorcostdetails.component';
import { MastersettingsComponent } from './mastersettings/mastersettings.component';
import { CalcvesseloperatorComponent } from './calcvesseloperator/calcvesseloperator.component';
import { AddcalcvesseloperatorComponent } from './calcvesseloperator/addcalcvesseloperator/addcalcvesseloperator.component';
import { EditcalcvesseloperatorComponent } from './calcvesseloperator/editcalcvesseloperator/editcalcvesseloperator.component';
import { CalculatorusersComponent } from './calculatorusers/calculatorusers.component';
import { AddcalculatorusersComponent } from './calculatorusers/addcalculatorusers/addcalculatorusers.component';
import { NewcalculatorusersComponent } from './newcalculatorusers/newcalculatorusers.component';
import { CalcequipmenttypesComponent } from './mastersettings/calcequipmenttypes/calcequipmenttypes.component';
import { NewcalcequipmenttypeComponent } from './mastersettings/calcequipmenttypes/newcalcequipmenttype/newcalcequipmenttype.component';
import { CalculatorpricingComponent } from './calculatorpricing/calculatorpricing.component';
import { CalculatorsubscriptioncheckoutComponent } from './calculatorsubscriptioncheckout/calculatorsubscriptioncheckout.component';
import { LocalchargeheadsComponent } from './mastersettings/localchargeheads/localchargeheads.component';
import { NewlocalchargeheadsComponent } from './mastersettings/localchargeheads/newlocalchargeheads/newlocalchargeheads.component';
import { EditlocalchargeheadsComponent } from './mastersettings/localchargeheads/editlocalchargeheads/editlocalchargeheads.component';
import { CalculatorlocalchargesComponent } from './calculatorlocalcharges/calculatorlocalcharges.component';
import { CalculatornewlocalchargesComponent } from './calculatorlocalcharges/calculatornewlocalcharges/calculatornewlocalcharges.component';
export const calculatorRoutes: Routes = [

  {
    path: 'calculator',
    component: CalculatorlayoutComponent,
    children: [
      { path: '', component: CalculatorComponent },
      { path: 'costcalculator', component: CostcalculatorComponent },
      { path: 'costcalculatorsettings', component: CostcalculatorsettingsComponent },
      { path: 'costcalculatorsettingslist', component: CostcalculatorsettinglistComponent },
      { path: 'costcalculatorlocalcharges', component: CalculatorlocalchargesComponent },
      { path: 'costcalculatornewlocalcharges', component: CalculatornewlocalchargesComponent },
      { path: 'costcalculatorsettingdetails/:id', component: CostcalculatorsettingdetailsComponent },
      { path: 'editcostcalculatorsetting/:id', component: EditcostcalculatorsettingComponent },
      { path: 'vesseloperatorcostslist', component: VesseloperatorcostinglistComponent },
      { path: 'vesseloperatorcosts', component: VesseloperatorcostsComponent },
      { path: 'vesseloperatorcostedit/:id', component: VesseloperatorcosteditComponent },
      { path: 'vesseloperatorcostdetails/:id', component: VesseloperatorcostdetailsComponent },
      { path: 'mastersettings', component: MastersettingsComponent },
      { path: 'calcvesseloperator', component: CalcvesseloperatorComponent },
      { path: 'addcalcvesseloperator', component: AddcalcvesseloperatorComponent },
      { path: 'editcalcvesseloperator/:id', component: EditcalcvesseloperatorComponent },
      { path: 'calculatorusers', component: CalculatorusersComponent },
      { path: 'addcalculatorusers', component: AddcalculatorusersComponent },
      { path: 'newcalculatorusers', component: NewcalculatorusersComponent },
      { path: 'calcequipmenttypes', component: CalcequipmenttypesComponent },
      { path: 'newcalcequipmenttype', component: NewcalcequipmenttypeComponent },
      { path: 'calculatorpricing', component: CalculatorpricingComponent },
      { path: 'calculatorsubscriptioncheckout', component: CalculatorsubscriptioncheckoutComponent },
      { path: 'callocalchargeheads', component: LocalchargeheadsComponent },
      { path: 'newcallocalchargeheads', component: NewlocalchargeheadsComponent },
      { path: 'editcallocalchargeheads/:id', component: EditlocalchargeheadsComponent },
    ]

  }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,RouterModule.forRoot(calculatorRoutes)
  ],
  exports: [RouterModule]
})
export class CalculatorRouterModule { }
