import { Candidates } from '../../model/candidate.model';
import { Posts } from '../../model/posts.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private http: HttpClient) {}
  private appUrl = ShipStreetProps.API_PATH + 'getAllNews.do';
  private blogUrl = ShipStreetProps.API_PATH + 'getAllBlogs.do';
  private postAuthUrl = ShipStreetProps.API_PATH + 'getTopPostAuthors.do';
  private deleteUrl = ShipStreetProps.API_PATH + 'deletePosts.do';

   public getAllNews() {
    return this.http.get<Posts[]>(this.appUrl);
  }
   public getAllBlogs() {
    return this.http.get<Posts[]>(this.blogUrl);
  }
   public getTopPostAuthors() {
    return this.http.get<Candidates[]>(this.postAuthUrl);
  }
  public deletePost(post: string) {
    return this.http.post<string>(this.deleteUrl, post);
  }

}
