import { Injectable } from '@angular/core';
import { CandidateSkill } from '../../../model/candidateSkill.model';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddskillsService {

  constructor(private http: HttpClient) { }

  private saveUrl = ShipStreetProps.API_PATH + 'newSkill.do';

  public saveType(skills: CandidateSkill) {
    return this.http.post<string>(this.saveUrl, skills);
  }
}
