export class ContainerReturnedDetailsDTO {

  bookedContainerId:number;
  currentLocation:number;
  returnedLocation: number;
  //  returnedLocationPort: number;
  returnedDate: Date;
  location: number;
  returnedContainerStatus:number;

}
