import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  sessionCandidate: SessionCandidate;
  mycompanies: CompanyEmployee[];

  constructor(private commonService: CommonService) {}

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();

  }

}
