import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from 'src/app/model/customer.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/accountscustomer/getAllCustomers';
  private custUrl = ShipStreetProps.API_PATH + '/accountscustomer/getCustomerByID';

  public getAllCustomers() {
      return this.http.get<Customer[]>(this.appUrl);
  }

  public getCustomerByID(customerId: string) {
      return this.http.post<Customer>(this.custUrl , customerId);
  }
}
