import { Component, OnInit } from '@angular/core';
import { VieweventsService } from './viewevents.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Events } from 'src/app/model/events.model';

@Component({
  selector: 'app-viewevents',
  templateUrl: './viewevents.component.html',
  styleUrls: ['./viewevents.component.css']
})
export class VieweventsComponent implements OnInit {
  event: Events;

  constructor(private vieweventsService: VieweventsService , private route: ActivatedRoute , private router: Router) { }

  ngOnInit() {
    this.vieweventsService.getJobById(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.event = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
