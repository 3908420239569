import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companylayout',
  templateUrl: './companylayout.component.html',
  styleUrls: ['./companylayout.component.css']
})
export class CompanylayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
