import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewcompanyvesselService } from './newcompanyvessel.service';
import { MasterVessels } from 'src/app/model/mastervessels.model';
import { CompanyvesseloperatorService } from '../../companyvesseloperator/companyvesseloperator.service';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';

@Component({
  selector: 'app-newcompanyvessel',
  templateUrl: './newcompanyvessel.component.html',
  styleUrls: ['./newcompanyvessel.component.css']
})
export class NewcompanyvesselComponent implements OnInit {
  masterVessel: MasterVessels;
  companyVesselOperatorList: MasterVesselOperator[];

  constructor(private newcompanyvesselService: NewcompanyvesselService, private router: Router,
              private companyvesseloperatorService: CompanyvesseloperatorService) { }

  ngOnInit() {
    this.masterVessel = new MasterVessels();
    this.masterVessel.vesselOperator = new MasterVesselOperator();

    this.companyvesseloperatorService.getAllVesselOperator()
      .subscribe(data => {
        this.companyVesselOperatorList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveCompanyVessel() {
    this.newcompanyvesselService.saveCompanyVessel(this.masterVessel)
    .subscribe(data => {
       this.router.navigate(['/trackmybox/companyvessels']);
       if (data == null) {
         alert('Already Exist');
       }
     },
       error => {
         this.router.navigate(['']);
       });

  }

}
