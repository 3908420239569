import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class DashboardpurchaselistService {

  private purUrl = ShipStreetProps.API_PATH + 'containerpurchase/findPurchaseByConType';

  constructor(private http: HttpClient) { }

  public findPurchaseByType(purchase: ContainerPurchase) {
    return this.http.post<ContainerPurchase[]>(this.purUrl, purchase);
  }
}