import { Component, OnInit } from '@angular/core';
import { ContainerpurchaseexpenseService } from './containerpurchaseexpense.service';
import { Router } from '@angular/router';
import { ContainerPurchaseBalance } from 'src/app/model/containerpurchasebalance.model';

@Component({
  selector: 'app-containerpurchaseexpense',
  templateUrl: './containerpurchaseexpense.component.html',
  styleUrls: ['./containerpurchaseexpense.component.css']
})
export class ContainerpurchaseexpenseComponent implements OnInit {
  purchaseBalanaceList: ContainerPurchaseBalance[];
  total = 0;
  private value;

  constructor(private containerpurchaseexpenseService: ContainerpurchaseexpenseService, private router: Router) { }

  ngOnInit() {
    this.containerpurchaseexpenseService.containerPurchaseBalanceList()
      .subscribe(data => {
        this.purchaseBalanaceList = data;
        this.findsum(this.purchaseBalanaceList);
      },
        error => {
          this.router.navigate(['']);
        });
  }


  findsum(data) {
  //  debugger
    this.value = data
    for (let j = 0; j < data.length; j++) {
      this.total += this.value[j].totalAmount;
    }
  }



}
