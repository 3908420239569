import { Component, OnInit } from '@angular/core';
import { CandidatestrengthService } from './candidatestrength.service';
import { CandidateProfileStrength } from 'src/app/model/candidateprofilestrength.model';
import { Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Component({
  selector: 'app-candidatestrength',
  templateUrl: './candidatestrength.component.html',
  styleUrls: ['./candidatestrength.component.css']
})
export class CandidatestrengthComponent implements OnInit {

  strength: CandidateProfileStrength;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  previewFile: any;
  selectedFile: File;
  showme: boolean;
  isLinear = false;
   firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  _formBuilder: any;
  stepIndex = 0;
  pictureDiv = true;
  desigDiv = true;
  dobDiv = true;
  aboutDiv = true;
  expDiv = true;
  eduDiv = true;
  strengthCard = true;


  constructor(private candidateStrengthSearvice: CandidatestrengthService, private router: Router) { }

  ngOnInit() {
    this.candidateStrengthSearvice.getCandidateStrength()
      .subscribe(data => {
        this.strength = data;
      });

      this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

  }

  onFileChanged(event) {
    if (event.target) {
      var reader = new FileReader();
      this.selectedFile = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event1: any) => { // called once readAsDataURL is completed
       console.log('Read As Data UR is Completed :' + event);
        this.previewFile = event1.target.result;
        this.showme = true;
      };
    }

  }

   onUpload() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.candidateStrengthSearvice.updateProfileImage(uploadData).subscribe( data => {
        this.candidateStrengthSearvice.updateProfileStrength().subscribe( data1 => {
        },
        error => {
          this.router.navigate(['']);
          });

    },
    error => {
      this.router.navigate(['']);
      });

  }

}
