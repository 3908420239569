import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerReturnedDetailsDTO } from 'src/app/model/containerreturneddetailsDTO.model';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ContainerreturneddetailsService {

  constructor(private http: HttpClient) { }

  private appUrlcontainer = ShipStreetProps.API_PATH + '/TrackboxBookingContainer/getTbbookingcontainersbyId.do';
  private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/saveReturnedDetails.do';

  public getTbbookingcontainersbyId(bookingId: number) {
    // alert(JSON.stringify(bookingId));
    return this.http.post<TrackBoxBookingContainers>(this.appUrlcontainer, bookingId);
  }


  public saveReturnedDetails(containerreturnedDetails: ContainerReturnedDetailsDTO) {
    //  alert(JSON.stringify(containerreturnedDetails));
    return this.http.post<ContainerReturnedDetailsDTO>(this.appUrl, containerreturnedDetails);
  }
}
