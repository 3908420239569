import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { TaxAgency } from 'src/app/model/taxagency.model';

@Injectable({
  providedIn: 'root'
})
export class TaxagenciesService {

  private appUrl = ShipStreetProps.API_PATH + 'taxagent/findAllTaxAgency';
  private lstUrl = ShipStreetProps.API_PATH + 'taxagent/findAllTaxAgencyByOrder';

  constructor(private http: HttpClient) { }

  public findAllTaxAgency() {
    return this.http.get<TaxAgency[]>(this.appUrl);
  }

  public findAllTaxAgencyByOrder() {
    return this.http.get<TaxAgency[]>(this.lstUrl);
  }

}
