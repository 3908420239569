import { Component, OnInit } from '@angular/core';
import { ContainersalesService } from './containersales.service';
import { Router } from '@angular/router';
import { ContainerSales } from 'src/app/model/containersales.model';

@Component({
  selector: 'app-containersales',
  templateUrl: './containersales.component.html',
  styleUrls: ['./containersales.component.css']
})
export class ContainersalesComponent implements OnInit {
  salesList: ContainerSales[];
  p = 1;
  panelOpenState: boolean;
  searchText: string;
  salesListAll: ContainerSales[];

  constructor(private containersalesService: ContainersalesService, private router: Router) { }

  ngOnInit() {
    this.containersalesService.findAllContSalesByCompany()
      .subscribe(data => {
        this.salesListAll = data;
        this.salesList = this.salesListAll;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  searchcntsales() {
    if (this.searchText !== '') {
      this.salesList = this.salesListAll.filter(res => {
        return res.customer.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
          res.type.eqType.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    } else if (this.searchText === '') {
      this.salesList = this.salesListAll;
    }
  }

}
