import { CompanydetailsService } from './companydetails.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Feeds } from 'src/app/model/feeds.model';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { Marketplace } from 'src/app/model/marketplace.model';
import { Jobs } from 'src/app/model/jobs.model';
import { CompanyFollow } from 'src/app/model/companyFollow.model';
import { Company } from 'src/app/model/company.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CompanyeditService } from '../companyedit/companyedit.service';
import { YourcompaniesService } from '../yourcompanies/yourcompanies.service';
import { CompaniesService } from '../companies/companies.service';
import { FeedsComments } from 'src/app/model/feedsComments.model';
import { DashboardService } from 'src/app/anweigh/dashbaord/dashboard.service';

@Component({
  selector: 'app-companydetails',
  templateUrl: './companydetails.component.html',
  styleUrls: ['./companydetails.component.css']
})
export class CompanydetailsComponent implements OnInit {
  imagePostComment: string;
  wtill: boolean;
  loggedCandidateAnEmployeeMsg: string;
  companyRec: any;
  comp: CompanyEmployee;
  companyEmployees: CompanyEmployee[];
  marketplaceList: Marketplace[];
  jobList: Jobs[];
  companyfollowlist: CompanyFollow[];
  unfollowBut = false;
  company: Company;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  pageType: string;
  companies: Company[];
  selectedCompany: number;
  showfeed: boolean;
  dashboard: any;
  feedlist: any;
  candidate: any;
  loggedTime: any;
  pageNo: number;
  newpost: Feeds;
  newpost1: Feeds;
  showimg: boolean;
  showFeedtext:boolean;
  showImgwindow:boolean;
  showImgFeed: boolean;
  comdiv: boolean;
  prevButt: boolean;
  commLimit: number;
  pageStart = 0;
  customOptions: OwlOptions;
  mycompanies: CompanyEmployee[];
  showme: boolean;
  show: boolean;
  feedcom: Feeds;
  jsoncomlist: any;
  feed: Feeds;
  jsonlist: any;
  jsonflikelist: any;
  showm: boolean;

  constructor(private companyDetailsService: CompanydetailsService, private route: ActivatedRoute, private router: Router,
    private snackBar: MatSnackBar, private cookieService: CookieService, private companiesService: CompaniesService,
    private companyeditService: CompanyeditService,private yourcompaniesService: YourcompaniesService,
    private dashboardService: DashboardService) { }

    selectedFile: File;
  previewFile: any;
  onFileChanged(event) {
    if (event.target) {
      const reader = new FileReader();
      this.selectedFile = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event1: any) => { // called once readAsDataURL is completed
        console.log('Read As Data UR is Completed :' + event);
        this.previewFile = event1.target.result;
      };
    }

  }
    onUploadFeed() {

      const uploadData = new FormData();
      uploadData.append('file', this.selectedFile);
      this.companyeditService.postImageUpload(uploadData).subscribe(data => {
        if (this.newpost1.feedsContent === '') {
          this.newpost1.feedImage = data.fileName;
          this.newpost1.feedType = data.fileType;
        } else {
          this.newpost1.feedImage = data.fileName;
          this.newpost1.feedType = data.fileType;
        }
        this.newpost1.feedsContent = this.imagePostComment;
        this.newpost1.company.id = this.route.snapshot.params.id;
        this.companyeditService.postNewCompanyImage(this.newpost1).subscribe(data1 => {
          this.feedlist = data1.dtoList;
          this.showimg = false;
          //   this.mediaHere = "";
          //  this.newpost="";
          this.showImgFeed = true;
        //  this.showfeed = true;
       //   this.showFeedtext = true;
        });
      },
        error => {
          this.router.navigate(['']);
        });
  
    }

  ngOnInit() {
    this.wtill = true;
    this.comp = new CompanyEmployee();
    this.pageType = this.route.snapshot.params.type;
    this.selectedCompany = this.route.snapshot.params.id;
    this.newpost = new Feeds();
    this.newpost.company = new Company();
    this.showfeed = true;
    this.newpost1 = new Feeds();
    this.newpost1.company = new Company();
    this.showimg = false;
    this.showImgFeed = true;
    this.comdiv = false;
    this.prevButt = true;
    this.commLimit = 4;

    this.companiesService.getAllCompanies()
      .subscribe(data => {
        this.companies = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.yourcompaniesService.getAllMyCompanies()
        .subscribe( data => {
          this.mycompanies = data;
        },
        error => {
          this.router.navigate(['']);
          });

    this.companyDetailsService.getCompanyByID(this.route.snapshot.params.id)
      .subscribe(data => {
        this.company = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.companyDetailsService.companyFollowList(this.route.snapshot.params.id)
      .subscribe(data => {
        this.companyfollowlist = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.companyDetailsService.companyJobList(this.route.snapshot.params.id)
      .subscribe(data => {
        this.jobList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.companyDetailsService.companyMarketplaceItemList(this.route.snapshot.params.id)
      .subscribe(data => {
        this.marketplaceList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.companyDetailsService.companyEmployeesList(this.route.snapshot.params.id)
      .subscribe(data => {
        this.companyEmployees = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.companyeditService.getCompanyFeeds(this.route.snapshot.params.id)
      .subscribe(data => {
        this.dashboard = data;
        this.feedlist = this.dashboard.dtoList;
        this.candidate = this.dashboard.candidate;
        this.loggedTime = this.dashboard.loggedInTime;
        this.pageNo = 1;
        //   },
        // error => {
        //   this.router.navigate(['']);
      });


      this.customOptions = {
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: true,
        navSpeed: 700,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        autoplay: false,
        autoplayHoverPause: false,
        responsive: {
          420: {
            items: 2
          },
          590: {
            items: 6
          }
        },
        nav: false
      };
  }
  toggle(f: Feeds) {
    f.comdiv = !f.comdiv;
  }
  showfeedtext() {
    this.showfeed = true;
    this.showimg = false;
  }
  showimgwindow() {
    this.showImgwindow = true;
  }
  openInput(event) {
    // your can use ElementRef for this later
    document.getElementById('fileInput').click();

  }

  companyFollow(companyId) {
    this.companyDetailsService.companyFollow(companyId)
      .subscribe(data => {
        this.company = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }
  companyUnFollow(companyId) {
    this.companyDetailsService.companyUnFollow(companyId)
      .subscribe(data => {
        this.company = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  employementWithCompany() {
    this.comp.company = this.company;
    this.companyDetailsService.employementWithCompany(this.comp)
      .subscribe(data => {
        this.company = data;
        this.loggedCandidateAnEmployeeMsg = 'You have successfully sent a request to join with';
      },
        error => {
          this.router.navigate(['']);
        });

  }
  recruiterWithCompany() {
    this.companyRec.company = this.company;
    this.companyDetailsService.recruiterWithCompany(this.companyRec)
      .subscribe(data => {
        this.company = data;
        this.loggedCandidateAnEmployeeMsg = 'You have successfully sent a request to join with';
      },
        error => {
          this.router.navigate(['']);
        });
  }

  // checkboxchange(e) { //  alert(e);

  // }

  companydetails(companyId) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/m/companydetails', companyId, 'general']);
    });

  }

  publishcompanypost() {
    this.newpost.company.id = this.route.snapshot.params.id;
    this.companyeditService.publishcompanypost(this.newpost)
      .subscribe(data => {
        this.feedlist = data.dtoList;
        this.showFeedtext = false;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  showImage(imageName) {
    if (null == imageName) {
      return false;
    }
    const k = imageName.indexOf('video');
    if (k === -1) {
      return true;
    } else {
      return false;
    }
  }

  loadNextPage() {

  }

  enrollCompanyTrackMyBox() {
    this.companyDetailsService.enrollCompanyTrackMyBox(this.company)
      .subscribe(data => {
        this.cookieService.set('TMB_STAT', this.company.companyStatus, null, null, null, null, null);
        this.snackBar.open('TRACKMYBOX ENROLLED SUCCESSFULLY !!!', 'End now', {
          duration: 2000,
        });
      },
        error => {
          this.router.navigate(['']);
        });
  }

  enrollCompanyTradeMyBox() {
    this.companyDetailsService.enrollCompanyTradeMyBox(this.company)
      .subscribe(data => {
        this.cookieService.set('TRD_STAT', 'true', null, null, null, null, null);
        this.snackBar.open('TRADE MY BOX ENROLLED SUCCESSFULLY !!!', 'End now', {
          duration: 2000,
        });
      },
        error => {
          this.router.navigate(['']);
        });
  }

  enrollCompanyCostCalculator() {
    this.companyDetailsService.enrollCompanyCostCalculator(this.company)
      .subscribe(data => {
        this.cookieService.set('CST_CALC_CMPI', '' + this.company.id, null, null, null, null, null);
        this.cookieService.set('CST_CALC_CAND', '' + true, null, null, null, null, null);
        this.snackBar.open('EASY CALC ENROLLED SUCCESSFULLY !!!', 'End now', {
          duration: 2000,
        });
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateCalcCompId(companyId) {
    this.cookieService.set('CST_CALC_CMPI', '' + companyId, null, null, null, null, null);
    this.router.navigate(['/calculator/costcalculator']);
}

newData() {}
deleteFeedComment(fid:number , commid:number ,com:number) {}
deleteFeed(f) {}
reportFeed(f) {}
prevData(feedscomment, commLimit) {}
onKeydownComment(event, f: Feeds) {
  if (f.newComments.comments == null || f.newComments.comments === '') {
    return;
  } else {
    if (event.key === 'Enter') {
      this.dashboardService.saveFeedComment(f)
        .subscribe(data => {
          this.feedcom = data;
          this.jsoncomlist = this.feedlist;
          f.comdiv = false;
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.feedlist.length; i++) {
            const obj = this.feedlist[i];
            if (obj.id === f.id) {
              this.feedlist[i].noComments = this.feedcom.noComments;
              this.feedlist[i].feedscomment = this.feedcom.feedscomment;
              this.feedlist[i].showMoreComments = this.feedcom.showMoreComments;
              this.feedlist[i].newComments = new FeedsComments();
              break;
            }
          }
        },
          error => {
            this.router.navigate(['']);
          });
    }
  }
}
saveFeedComment(f: Feeds) {
  if (f.newComments.comments == null || f.newComments.comments === '') {
    return;
  } else { 
    this.dashboardService.saveFeedComment(f)
      .subscribe(data => {
        this.feedcom = data;
        this.jsoncomlist = this.feedlist;
        f.comdiv = false;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.feedlist.length; i++) {
          const obj = this.feedlist[i];
          if (obj.id === f.id) {
            this.feedlist[i].noComments = this.feedcom.noComments;
            this.feedlist[i].feedscomment = this.feedcom.feedscomment;
            this.feedlist[i].showMoreComments = this.feedcom.showMoreComments;
            this.feedlist[i].newComments = new FeedsComments();
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }
}
sharepost(f: Feeds) {
  this.dashboardService.sharepost(f)
      .subscribe(data => {
        this.feedlist = data.dtoList;
        //        this.router.navigate(['editprofile']);
      },
        error => {
          this.router.navigate(['']);
        });
}
blockFeed(f){}

likefeed(f: Feeds, em: number) {
  f.emoji = em;
  this.dashboardService.likefeed(f)
    .subscribe(data => {
      this.feed = data;
      this.jsonlist = this.feedlist;
      for (let i = 0; i < this.jsonlist.length; i++) {
        const obj = this.jsonlist[i];
        if (obj.id === f.id) {
          this.feedlist[i].noLike = this.feed.noLike;
          this.feedlist[i].likeByLoggedInCandidate = this.feed.likeByLoggedInCandidate;
          this.feedlist[i].feedsLikeList = this.feed.feedsLikeList;
          if (em === 1) {
            // this.feedlist[i].noEmojiLike = true;
            this.feedlist[i].emojiLike = true;
          } else if (em === 2) {
            // this.feedlist[i].noEmojiLove = true;
            this.feedlist[i].emojiLove = true;
          } else if (em === 3) {
            //  this.feedlist[i].noEmojiHaha = true;
            this.feedlist[i].emojiHaha = true;
          } else if (em === 4) {
            //  this.feedlist[i].noEmojiWow = true;
            this.feedlist[i].emojiWow = true;
          } else if (em === 5) {
            //   this.feedlist[i].noEmojiSad = true;
            this.feedlist[i].emojiSad = true;
          }
        }
      }
    },
      error => {
        this.router.navigate(['']);
      });
}

deleteFeedLike(f, em: number) {
  f.emoji = em;
  this.dashboardService.deleteFeedLike(f)
    .subscribe(data => {
      this.jsonflikelist = this.feedlist;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.feedlist.length; i++) {
        const obj = this.feedlist[i];
        if (obj.id === f.id) {
          this.feedlist[i].noLike = data.noLike;
          this.feedlist[i].likeByLoggedInCandidate = data.likeByLoggedInCandidate;
          this.feedlist[i].feedsLikeList = data.feedsLikeList;
          if (em === 1) {
            // this.feedlist[i].noEmojiLike = false;
            this.feedlist[i].emojiLike = false;
          } else if (em === 2) {
            // this.feedlist[i].noEmojiLove = false;
            this.feedlist[i].emojiLove = false;
          } else if (em === 3) {
            //  this.feedlist[i].noEmojiHaha = false;
            this.feedlist[i].emojiHaha = false;
          } else if (em === 4) {
            //  this.feedlist[i].noEmojiWow = false;
            this.feedlist[i].emojiWow = false;
          } else if (em === 5) {
            //  this.feedlist[i].noEmojiSad = false;
            this.feedlist[i].emojiSad = false;
          }
          break;
        }
      }
    },
      error => {
        this.router.navigate(['']);
      });
}

}
