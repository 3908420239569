import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newbillingproducts',
  templateUrl: './newbillingproducts.component.html',
  styleUrls: ['./newbillingproducts.component.css']
})
export class NewbillingproductsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
