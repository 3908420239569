import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { PoliciesContent } from 'src/app/model/policiescontent.model';

@Injectable({
  providedIn: 'root'
})
export class EditprivacypolicyService {

  constructor(private http: HttpClient) { }

  private fetchUrl = ShipStreetProps.API_PATH + '/policy/fetchPolicy.do';
  private updateUrl = ShipStreetProps.API_PATH + '/policy/updatePolicy.do';
  private appUrl = ShipStreetProps.API_PATH + '/policy/approvePolicy.do';

  public fetchPolicy(policyId: string) {
    return this.http.post<PoliciesContent>(this.fetchUrl , policyId);
  }

  public updatePolicy(policyContent: PoliciesContent) {
    return this.http.post<string>(this.updateUrl , policyContent);
  }

  public approvePolicy(policyContent: PoliciesContent) {
    return this.http.post<string>(this.appUrl , policyContent);
  }
}
