import { Candidates } from './candidate.model';
import { Company } from './company.model';
export class CandidateExperience {
    id: number;
    candidate: Candidates;
    positionTitle: string;
    company: Company;
    startDate: Date;
    endDate: Date;
    location: string;
    present: string;
    monthlySalaryCurrency: string;
    monthlySalaryAmount: string;
    description: string;
    expDiv: false;
}
