import { CompanyVessels } from './../../../model/companyvessels.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CompanyvesselsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'vessels/findAllCompanyVessels.do';

  getAllMyCompanyVessels() {
    return this.http.get<CompanyVessels[]>(this.appUrl );

  }
}
