import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { Containers } from 'src/app/model/containers.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditcontainerinfoService {

  private saveUrl = ShipStreetProps.API_PATH + 'containerpurchasedunits/updateContainerInfo';
  private sappUrl = ShipStreetProps.API_PATH + 'containerpurchasedunits/getcontainerbyid';

  constructor(private http: HttpClient) { }

  public updateContainerInfo(container: Containers) {
    return this.http.post<string>(this.saveUrl , container);
  }

  public getcontainerbyid(containerId: number) {
    return this.http.post<Containers>(this.sappUrl , containerId);
  }
}
