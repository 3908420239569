import { Injectable } from '@angular/core';
import { HttpHeaders } from '../../../node_modules/@angular/common/http';
import { SessionCandidate } from '../model/sessioncandidate.model';
import { CookieService } from 'ngx-cookie-service';
import { Candidates } from '../model/candidate.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

    private baseURL = 'http://rigstreet-v2.us-west-2.elasticbeanstalk.com/';
    private sessionCandidate = new SessionCandidate();
    private candidate: Candidates;
    constructor(private cookieService: CookieService) { }

    public fullServiceURL( serviceURL: string) {
        return  this.baseURL + serviceURL;
      }

     getSessionCandidate(): SessionCandidate {
       // this.cookieService.get( 'API_SESSION_ID' );
        this.sessionCandidate.id = Number(this.cookieService.get( 'CAND_ID'));
        this.sessionCandidate.firstName = this.cookieService.get( 'CAND_FN' );
        this.sessionCandidate.lastName = this.cookieService.get( 'CAND_LN' );
        this.sessionCandidate.designation = this.cookieService.get( 'CAND_DESIG' );
        this.sessionCandidate.companyName = this.cookieService.get( 'CAND_COMPNM' );
        this.sessionCandidate.emailAddress = this.cookieService.get( 'CAND_EA' );
        this.sessionCandidate.connectionCount = Number(this.cookieService.get( 'CAND_CONN' ));
        this.sessionCandidate.followingCount = Number(this.cookieService.get( 'CAND_FOLL' ));
        this.sessionCandidate.firstFeed = this.cookieService.get( 'FIRST_FEED' );
        this.sessionCandidate.verified = this.cookieService.get( 'VERI_STAT' );
        this.sessionCandidate.newMessageCount = Number(this.cookieService.get( 'MSG_CNT' ));
        this.sessionCandidate.newConnectionCount = Number(this.cookieService.get( 'CONN_CNT' ));
        this.sessionCandidate.cartCount = Number(this.cookieService.get( 'CART_CNT' ));
        this.sessionCandidate.containerCompany = Number(this.cookieService.get('CONT_CMP'));
        this.sessionCandidate.trackmyboxStatus = this.cookieService.get('TMB_STAT');
        this.sessionCandidate.trackmyboxCompany = Number(this.cookieService.get('TMB_COMP'));
        this.sessionCandidate.trackmyboxCompanyName = this.cookieService.get('TMB_COMP_NM');
        this.sessionCandidate.adminStatus = this.cookieService.get('ADM_STAT');
        this.sessionCandidate.tradingStatus = Boolean(this.cookieService.get('TRD_STAT') === 'true');
        this.sessionCandidate.tradingCompany = Number(this.cookieService.get('TRD_CMP_ID'));
        this.sessionCandidate.companyCostCalculator = Boolean(this.cookieService.get('CST_CALC_COMP'));
        this.sessionCandidate.costCalculatorCompanyId = Number(this.cookieService.get('CST_CALC_CMPI'));
        this.sessionCandidate.candidateCostCalculator = Boolean(this.cookieService.get('CST_CALC_CAND') === 'true');
        // this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
        console.log(this.sessionCandidate);
        return this.sessionCandidate;
     }

    //  getCandidateFromSession(): Candidates {
    //    this.sessionCandidate = this.getSessionCandidate();
    //   this.candidate.id = this.sessionCandidate.id;
    //   this.candidate.firstName = this.sessionCandidate.firstName;
    //   this.candidate.lastName = this.sessionCandidate.lastName;
    //   this.candidate.designation.name = this.sessionCandidate.designation;
    //   this.candidate.company.companyName = this.sessionCandidate.companyName;
    //   this.candidate.email = this.sessionCandidate.emailAddress;
    //   return this.candidate;
    //  }

     public removeRSCookieValues() {
        this.cookieService.delete('CAND_ID');
        this.cookieService.delete('CAND_FN');
        this.cookieService.delete('CAND_LN');
        this.cookieService.delete('CAND_DESIG');
        this.cookieService.delete('CAND_COMPNM');
        this.cookieService.delete('CAND_EA');
        this.cookieService.delete('CAND_CONN');
        this.cookieService.delete('CAND_FOLL');
        this.cookieService.delete('FIRST_FEED');
        this.cookieService.delete('VERI_STAT');
        this.cookieService.delete('MSG_CNT');
        this.cookieService.delete('CONN_CNT');
        this.cookieService.delete('CART_CNT');
        this.cookieService.delete('CONT_CMP');
        this.cookieService.delete('TMB_STAT');
        this.cookieService.delete('TMB_COMP');
        this.cookieService.delete('TMB_COMP_NM');
        this.cookieService.delete('ADM_STAT');
        this.cookieService.delete('TRD_STAT');
        this.cookieService.delete('TRD_CMP_ID');
        this.cookieService.delete('CST_CALC_COMP');
        this.cookieService.delete('CST_CALC_CMPI');
        this.cookieService.delete('CST_CALC_CAND');
     }

     public getCookieForCounts() {

     }




      // tslint:disable-next-line:member-ordering
      public static readonly httpOptions = {
        headers: new HttpHeaders({
       //   'userid': '1'
        })
      };


}
