import { Component, OnInit } from '@angular/core';
import { BoxemployeesService } from './boxemployees.service';
import { Router } from '@angular/router';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Component({
  selector: 'app-boxemployees',
  templateUrl: './boxemployees.component.html',
  styleUrls: ['./boxemployees.component.css']
})
export class BoxemployeesComponent implements OnInit {
  p: number;
  panelOpenState: boolean;
  tbEmployeesList: CompanyEmployee[];
  constructor(private boxemployeesService: BoxemployeesService, private router: Router) { }

  ngOnInit() {
    this.boxemployeesService.getAllMyEmployees()
      .subscribe(data => {
        this.tbEmployeesList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
