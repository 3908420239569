import { Component, OnInit } from '@angular/core';
import { AddresspageService } from './addresspage.service';
import { Router } from '@angular/router';
import { Candidates } from '../../../model/candidate.model';
import { CandidateBillingAddress } from '../../../model/candidatebillingaddress.model';
import { CandidateDeliveryAddress } from '../../../model/candidatedeliveryaddress.model';
import { CandidateAddress } from '../../../model/candidateaddress.model';

@Component({
  selector: 'app-addresspage',
  templateUrl: './addresspage.component.html',
  styleUrls: ['./addresspage.component.css']
})
export class AddresspageComponent implements OnInit {

  panelOpenState = false;
  candidate: Candidates;
  billing: CandidateBillingAddress;
  delivered: CandidateDeliveryAddress;
  candidateAddress: CandidateAddress;

  constructor(private addresspageService: AddresspageService , private router: Router) { }

  ngOnInit() {
    this.billing = new CandidateBillingAddress();
    this.billing.candidate = new Candidates();
    this.delivered = new CandidateDeliveryAddress();
    this.delivered.candidate = new Candidates();
    this.candidateAddress = new CandidateAddress();

    this.addresspageService.getCandidateAddress()
      .subscribe(data => {
        this.candidate = data;
        console.log(this.candidate);
      // },
      // error => {
      //   this.router.navigate(['']);
        });

  }

  createAddress() {
    this.candidateAddress.billingDto =  this.billing;
    this.candidateAddress.deliveryDto = this.delivered;
    this.addresspageService.createAddress(this.candidateAddress)
      .subscribe(data => {
       // this.property = data;
        this.router.navigate(['/m/bookingconfirmation']);
      },
      error => {
        this.router.navigate(['']);
      });

  }

}
