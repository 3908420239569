import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerSales } from 'src/app/model/containersales.model';

@Injectable({
  providedIn: 'root'
})
export class NewcontainersalesService {

  private saveUrl = ShipStreetProps.API_PATH + 'containersales/saveContainerSales';
  constructor(private http: HttpClient) { }

  public saveContainerSales(sales: ContainerSales) {
    return this.http.post<string>(this.saveUrl , sales);
  }
}
