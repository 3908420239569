import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'connectionFilter'
})
export class ConnectionPipe implements PipeTransform {

  transform(friendsList: any[], searchText: string): any[] {
    if (!friendsList) {
        return friendsList;
    }
    if (!searchText) {
        return friendsList;
    }
    searchText = searchText.toLowerCase().trim();
  return friendsList.filter( candidate => {
        if ( candidate && candidate.name.toLowerCase().indexOf(searchText) != -1 ) {
            return true;
        } else {
            return false;
        }
    });
  }

}
