import { CandidateSkill } from '../../../model/candidateSkill.model';
import { CandidateskillsService } from './candidateskills.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export interface DialogData {
  animal: string;
  name: string;
}


@Component({
  selector: 'app-candidateskills',
  templateUrl: './candidateskills.component.html',
  styleUrls: ['./candidateskills.component.css']
})
export class CandidateskillsComponent implements OnInit {
  candidate: string;
  candidateSkills: CandidateSkill[];
  breakpoint: number;

  constructor(private candidateskillsService: CandidateskillsService , private route: ActivatedRoute , private router: Router ,
    public dialog: MatDialog ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get("id");
    this.candidate = id;
    
     this.candidateskillsService.candidateSkills(this.candidate)
      .subscribe( data => {
        this.candidateSkills = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  sendMessage() {


    // const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
    //   width: '250px',
    //   data: {name: this.name, animal: this.animal}
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   this.animal = result;
    // });



    this.candidateskillsService.sendNoSkillAlert(this.route.snapshot.paramMap.get("id"))
      .subscribe( data => {
      //  this.candidateSkills = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 3;
  }

}


// @Component({ 
//   selector: 'dialog-overview-example-dialog',
//   templateUrl: 'dialog-overview-example-dialog.html',
// })
// export class DialogOverviewExampleDialog {

//   constructor(
//     public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

// }
