import { Candidates } from "./candidate.model";

export class PassWordResetDTO {
    id: number;
	candidate: Candidates;
	emailAddress: string;
	passCode: string;
	passWord: string;
	createdDate: Date;
	status: string;
}