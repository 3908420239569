import { Component, OnInit } from '@angular/core';
import { NewcontainerleaseService } from './newcontainerlease.service';
import { CustomersService } from '../../admin/customers/customers.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/utils/common.service';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';
import { ContainerLease } from 'src/app/model/containerlease.model';
import { ContainerLeasingUnit } from 'src/app/model/containerleasingunit.model';
import { ContainertypeService } from '../../master/containertype/containertype.service';
import { ContainerType } from 'src/app/model/containertype.model';
import { ContainerCompany } from 'src/app/model/containercompany.model';

@Component({
  selector: 'app-newcontainerlease',
  templateUrl: './newcontainerlease.component.html',
  styleUrls: ['./newcontainerlease.component.css']
})
export class NewcontainerleaseComponent implements OnInit {
  customers: ContainerCustomer[];
  leasing: ContainerLease;
  leasingUnit: ContainerLeasingUnit;
  containertype: ContainerType[];

  constructor(private newcontainerleaseService: NewcontainerleaseService, private customersService: CustomersService,
              private router: Router, private commonService: CommonService, private containertypeService: ContainertypeService) { }

  sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() {
    this.leasing = new ContainerLease();
    this.leasing.customer = new ContainerCustomer();
    this.leasing.containerType = new ContainerType();
    this.leasing.company = new ContainerCompany();
    this.leasingUnit = new ContainerLeasingUnit();

    this.customersService.containerCustomerList()
      .subscribe(data => {
        this.customers = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.containertypeService.findAllContainerTypeByOrder()
      .subscribe(data => {
        this.containertype = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveContainerLease() {
    this.leasing.company.id = this.sessionCandidate.containerCompany;
    this.newcontainerleaseService.saveContainerLease(this.leasing)
      .subscribe(data => {
        this.router.navigate(['containers/continerleasing']);
      },
        error => {
          this.router.navigate(['']);
        });
  }



}
