import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { adminRoutes } from './admin-router.config';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CKEditorModule } from 'ckeditor4-angular';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule , MatInputModule , MatSelectModule , MatNativeDateModule , MatCheckboxModule ,
  MatRadioModule , MatTabsModule, MatDividerModule} from '@angular/material';
import { AdmincandidatesComponent } from './admincandidates/admincandidates.component';
import { MasterproductsComponent } from './master/masterproducts/masterproducts.component';
import { NewmasterproductsComponent } from './master/masterproducts/newmasterproducts/newmasterproducts.component';
import { BillingproductsComponent } from './billing/billingproducts/billingproducts.component';
import { NewbillingproductsComponent } from './billing/billingproducts/newbillingproducts/newbillingproducts.component';
import { PrivacypolicyComponent } from './policy/privacypolicy/privacypolicy.component';
import { NewprivacypolicyComponent } from './policy/privacypolicy/newprivacypolicy/newprivacypolicy.component';
import { EditprivacypolicyComponent } from './policy/privacypolicy/editprivacypolicy/editprivacypolicy.component';


@NgModule({
  imports: [
    CommonModule , LayoutModule, FlexLayoutModule ,RouterModule,
    MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
    MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatFormFieldModule , MatInputModule , MatSelectModule , MatNativeDateModule , MatCheckboxModule ,
    MatRadioModule , MatTabsModule , MatDividerModule, CKEditorModule, FormsModule , ReactiveFormsModule
  ],
  declarations: [AdmincandidatesComponent, MasterproductsComponent, NewmasterproductsComponent,
    BillingproductsComponent, NewbillingproductsComponent, PrivacypolicyComponent, NewprivacypolicyComponent,
    EditprivacypolicyComponent]
})
export class AdminModule { }
