import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Events } from 'src/app/model/events.model';

@Injectable({
  providedIn: 'root'
})
export class VieweventsService {

   constructor(private http: HttpClient) { }
   
   private appUrl = ShipStreetProps.API_PATH + 'eventObjectById.do';


  public getJobById(id: string) {
    return this.http.post<Events>(this.appUrl , id);
  }
}
