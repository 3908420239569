import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'companyfilter'
})
export class CompanyFilter implements PipeTransform {
  transform(companies: any[], searchText: string): any[] {
    if (!companies) {
        return companies;
    }
    if (!searchText) {
        return companies;
    }
  searchText = searchText.toLowerCase().trim();
  return companies.filter( company => {
        if (company && company.companyName.toLowerCase().indexOf(searchText) !== -1 ) {
            return true;
        } else {
            return false;
        }
    });
   }
}
