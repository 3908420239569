import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { NewtrackboxcustomersService } from './newtrackboxcustomers.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewequipmentcontrolService } from 'src/app/trackmybox/equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-newtrackboxcustomers',
  templateUrl: './newtrackboxcustomers.component.html',
  styleUrls: ['./newtrackboxcustomers.component.css']
})
export class NewtrackboxcustomersComponent implements OnInit {
  CountryList: CountryInfo[];
  trackboxcustomers = new TrackBoxCustomers();
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
  constructor(private newtrackboxcustomersService: NewtrackboxcustomersService, private router: Router, private route: ActivatedRoute,
              private newequipmentcontrolService: NewequipmentcontrolService) { }

  ngOnInit() {
    this.trackboxcustomers = new TrackBoxCustomers();
    this.trackboxcustomers.country = new CountryInfo();
    this.newequipmentcontrolService.viewCountry()
  .subscribe(data => {
    this.CountryList = data;
    this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter4(val))
    );

    console.log(this.filteredOptionscountry);
},
    error => {
      this.router.navigate(['']);
    });
  }
  onSubmit() {
    this.newtrackboxcustomersService.save(this.trackboxcustomers)
    .subscribe(data => {
    this.router.navigate(['trackmybox/trackboxcustomers']);
     },
       error => {
         this.router.navigate(['']);
       });
  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }
  }

}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}
