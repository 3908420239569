import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refundlogin',
  templateUrl: './refundlogin.component.html',
  styleUrls: ['./refundlogin.component.css']
})
export class RefundloginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
