import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Component({
  selector: 'app-salesforceheader',
  templateUrl: './salesforceheader.component.html',
  styleUrls: ['./salesforceheader.component.css']
})
export class SalesforceheaderComponent implements OnInit {
  sessionCandidate: SessionCandidate;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  isGuest: boolean;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();

  }
  switchprofile() { }
  logout() { }

}
