import { CandidateExperience } from '../../../model/candidateExperience.model';
import { CandidateexperienceService } from './candidateexperience.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-candidateexperience',
  templateUrl: './candidateexperience.component.html',
  styleUrls: ['./candidateexperience.component.css']
})
export class CandidateexperienceComponent implements OnInit {
  candidateExperience: CandidateExperience[];
  candidate: string;
  breakpoint: number;

  constructor(private candidateexperienceService: CandidateexperienceService , private route: ActivatedRoute , private router: Router) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get("id");
    this.candidate = id;
    this.candidateexperienceService.candidateExperience(this.candidate)
      .subscribe( data => {
        this.candidateExperience = data;
      },
      error => {
        this.router.navigate(['']);
        });

        this.breakpointFun(event)
  }

  onResize(event) {
    this.breakpointFun(event)
  }

  breakpointFun(event?: any): void{
    if(event){
      this.breakpoint = (event.target.innerWidth <= 681) ? 1 : (event.target.innerWidth <= 1000) ? 2 : (event.target.innerWidth <= 1300) ? 2 : 2;
    } 
    else {
      this.breakpoint = (window.innerWidth <= 681) ? 1 : (window.innerWidth <= 1000) ? 2 : (window.innerWidth <= 1300) ? 2 : 2;
    }
  }


  sendMessage() {
    this.candidateexperienceService.sendNoExperienceAlert(this.route.snapshot.paramMap.get("id"))
      .subscribe( data => {
        this.candidateExperience = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
