import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerOnewayMoveRequest } from 'src/app/model/containeronewaymoverequest.model';

@Injectable({
  providedIn: 'root'
})
export class OnewaymoverequestService {

  private saveUrl = ShipStreetProps.API_PATH + 'containeronewaymoverequest/saveContainerOnewayMoveRequest';

  constructor(private http: HttpClient) { }

  public saveContainerOnewayMoveRequest(moverequest: ContainerOnewayMoveRequest) {
    return this.http.post<string>(this.saveUrl , moverequest);
  }
}
