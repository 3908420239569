import { Component, OnInit } from '@angular/core';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CommonService } from 'src/app/utils/common.service';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';
import { ViewprinciplerequestService } from 'src/app/trackmybox/agents/boxprinciples/viewprinciplerequest/viewprinciplerequest.service';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';

@Component({
  selector: 'app-tbagentheader',
  templateUrl: './tbagentheader.component.html',
  styleUrls: ['./tbagentheader.component.css']
})
export class TbagentheaderComponent implements OnInit {
  isGuest: boolean;
  sessionCandidate: SessionCandidate;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  boxrequestList: TrackBoxAgent[];

  constructor(private commonService: CommonService , private cookieService: CookieService, private router: Router,
              private searchBarService: SearchbarService, private viewprinciplerequestService: ViewprinciplerequestService) { }

  ngOnInit() {
    this.isGuest = true;

    this.sessionCandidate = this.commonService.getSessionCandidate();

    this.viewprinciplerequestService.getAllMyPrincipleRequests()
    .subscribe(data => {
      this.boxrequestList = data;
    },
      error => {
        this.router.navigate(['']);
      });
  }

  switchprofile() {
    this.cookieService.delete('TMB_COMP');
    this.cookieService.delete('TMB_COMP_NM');
    this.router.navigate(['/m/trackboxprofileintro']);
  }

  logout() {
     this.searchBarService.logout()
      .subscribe( data => {
        this.commonService.removeRSCookieValues();
         // this.sessioncandidate = data;
        this.router.navigate(['']);
        },
      error => {
        this.router.navigate(['']);
        });
  }

}
