import { MatDialog } from '@angular/material';
import { DashboardService } from './dashboard.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { SharedialogComponent } from './sharedialog/sharedialog.component';
// import {timer} from 'rxjs';
import { FirstfeednotificationService } from './firstfeednotification/firstfeednotification.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/utils/common.service';
import { Feeds } from 'src/app/model/feeds.model';
import { FeedsComments } from 'src/app/model/feedsComments.model';
import { Candidates } from 'src/app/model/candidate.model';
import { Dashboard } from 'src/app/model/dashboard.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { FileUploadResponse } from 'src/app/model/fileuploadResponse.model';
import { SharedeletedialogComponent } from './sharedeletedialog/sharedeletedialog.component';


const URL = '/rigstreet/FeedImageUpload.do';

@Component({
  selector: 'app-dashbaord',
  templateUrl: './dashbaord.component.html',
  styleUrls: ['./dashbaord.component.css']
})
export class DashbaordComponent implements OnInit {

  constructor(private dashboardService: DashboardService, private router: Router,
              private commonService: CommonService, public dialog: MatDialog, private cookieService: CookieService,
              private firstfeednotificationService: FirstfeednotificationService) { }

  selectedFile: File;
  previewFile: any;
  previewFileTemp: any;
  pageStart = 0;
  prevButt: boolean;
  feedList: Feeds[];
  feedCommentList: FeedsComments[];
  firstFeedCheck: string;
  loading = true;
  isDisabled: boolean;
  jsonflikelist: Feeds[];
  imgurl: any;
  showimg: boolean;
  verifiedStatus: boolean;
  jsoncomlist: Feeds[];
  feedcom: Feeds;
  feed: Feeds;
  jsonlist: Feeds[];
  showbutton: boolean;
  showfeed: boolean;
  f: Feeds;
  comdiv: boolean;
  pageNo: number;
  newpost: Feeds;
  newpost1: Feeds;
  imagePostComment: string;
  candidate: Candidates;
  dashboard: Dashboard;
  feedlist: Feeds[];
  temfeedlist: Feeds[];
  responseStr: FileUploadResponse;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;  // ShipStreetProps.AWS_IMAGE_PATH;
  commLimit: number;

  sessionCandidate = this.commonService.getSessionCandidate();
  froalaEditor: any;

  // sub = this.source.subscribe( cnt => {
  // this.candidate = commonService.getCookieForCounts();
  // });


  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });

  // public editorOptions: Object = { embedlyKey: 'te1C2sD6D6F6F4D3E4jeqD-13ionxH-8kC7bmnxE2F2G2B1B10C2E2E6D1E1=='};

  responseStr$: FileUploadResponse;


  onFileChanged(event) {
    if (event.target) {
      const reader = new FileReader();
      const target1: any = event.target;
      this.selectedFile = target1.files[0];

      reader.readAsDataURL(target1.files[0]);

      const fileReader: FileReader = new FileReader();

      //    let previewFile = target1.files[0];
      reader.onload = (event1: any) => {
        if (event1 && event1.target && event1.target.result) {
          this.previewFileTemp = event1.target.result;

        }


      };
    }

  }

  shareDialog(): void {
    const dialogRef = this.dialog.open(SharedialogComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  sharedeleteDialog(): void {
    const dialogRef = this.dialog.open(SharedeletedialogComponent, {
      width: '250px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onUpload() {
    this.loading = true;
    this.isDisabled = true;
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
  //  console.log(uploadData);
  //  alert(uploadData);
    this.dashboardService.postImageUpload(uploadData).subscribe(data => {
   //   console.log(JSON.stringify(data));
   //   alert("this.newpost1.feedsContent"+this.newpost1.feedsContent);
    //  if (this.newpost1.feedsContent === '') {
        this.newpost1.feedImage = data.fileName;
        this.newpost1.feedType = data.fileType;
      // } else {
      //   this.newpost1.feedImage = data.fileName;
      //   this.newpost1.feedType = data.fileType;
      // }
      this.loading = false;
    //  this.shareDialog();
   //   this.newpost1.feedsContent = this.imagePostComment;
    //  alert(this.newpost1);
      this.dashboardService.postNewImage(this.newpost1).subscribe(data1 => {
        this.feedlist = data1.dtoList;
        this.showimg = false;
        //   this.mediaHere = "";
        //  this.newpost="";
        this.showfeed = false;
      });
    },
      error => {
        this.router.navigate(['']);
      });



  }


  ngOnInit() {
    // this.froalaEditor = new Froala;

    if (this.sessionCandidate == null || this.sessionCandidate.id == null || this.sessionCandidate.id === 0) {
      this.router.navigate(['']);
    }
    this.isDisabled = false;
    this.loading = false;
    this.newpost = new Feeds();
    this.newpost1 = new Feeds();
    this.imagePostComment = '';
    this.commLimit = 4;
    this.prevButt = true;
    //    this.uploader.onAfterAddingFile = (file) => {//alert("onAfterAddingFile");
    //      console.log(file);
    //      console.log(this.uploader)
    //      file.withCredentials = false;
    //      this.showimg = true;
    //      this.imgurl = file.file.name;
    //      console.log(this.imgurl);
    //    };


    //    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    //      this.responseStr$ = JSON.parse(response);
    //      this.showimg = true;
    //   //   alert("onCompleteItem");
    //      console.log('File Name :'+this.responseStr$.fileName.valueOf() );
    //      console.log('File Name to Store : ', this.responseStr$.fileName , this.responseStr$.fileType);
    //      this.newpost.feedImage = this.responseStr$.fileName;
    //      this.newpost.feedType = this.responseStr$.fileType;
    //
    //      this.dashboardService.postNewImage(this.newpost)
    //      .subscribe( data => {
    //        this.feedlist = data.dtoList;
    //        this.showimg = true;
    //      },
    //      error => {
    //        this.router.navigate(['']);
    //        });
    //
    //
    //
    //     };

    this.showfeed = false;
    this.showbutton = false;
    this.newpost = new Feeds();
    this.newpost1 = new Feeds();
    this.verifiedStatus = true;
    this.dashboardService.getDashboardFeeds()
      .subscribe(data => {
        this.dashboard = data;
        this.candidate = this.dashboard.candidate;
        this.feedlist = this.dashboard.dtoList;
        this.pageNo = 1;
      },
        error => {
          this.router.navigate(['']);
        });

    //  this.firstFeedCheck = this.commonService.getCandidateFirstFeed();

    // this.dashboardService.getVerifiedStatus()
    //   .subscribe( data => {
    //     this.verifiedStatus =  data.verified;
    //   },
    //   error => {
    //     this.router.navigate(['']);
    //     });

  }

  showImage(imageName) {
    if (null == imageName) {
      return false;
    }
    const k = imageName.indexOf('video');
    if (k === -1) {
      return true;
    } else {
      return false;
    }
  }

  publishpost() {
    this.dashboardService.publishpost(this.newpost)
      .subscribe(data => {
        this.feedlist = data.dtoList;
        this.newpost.feedsContent = '';
        this.showfeed = false;
        this.pageNo = 1;
        //        this.router.navigate(['editprofile']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
  likefeed(f: Feeds, em: number) {
    f.emoji = em;
    this.dashboardService.likefeed(f)
      .subscribe(data => {
        this.feed = data;
        this.jsonlist = this.feedlist;
        for (let i = 0; i < this.jsonlist.length; i++) {
          const obj = this.jsonlist[i];
          if (obj.id === f.id) {
            this.feedlist[i].noLike = this.feed.noLike;
            this.feedlist[i].likeByLoggedInCandidate = this.feed.likeByLoggedInCandidate;
            this.feedlist[i].feedsLikeList = this.feed.feedsLikeList;
            if (em === 1) {
              // this.feedlist[i].noEmojiLike = true;
              this.feedlist[i].emojiLike = true;
            } else if (em === 2) {
              // this.feedlist[i].noEmojiLove = true;
              this.feedlist[i].emojiLove = true;
            } else if (em === 3) {
              //  this.feedlist[i].noEmojiHaha = true;
              this.feedlist[i].emojiHaha = true;
            } else if (em === 4) {
              //  this.feedlist[i].noEmojiWow = true;
              this.feedlist[i].emojiWow = true;
            } else if (em === 5) {
              //   this.feedlist[i].noEmojiSad = true;
              this.feedlist[i].emojiSad = true;
            }
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }
  saveFeedComment(f: Feeds) {
    if (f.newComments.comments == null || f.newComments.comments === '') {
      return;
    } else {
      this.dashboardService.saveFeedComment(f)
        .subscribe(data => {
          this.feedcom = data;
          this.jsoncomlist = this.feedlist;
          f.comdiv = false;
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.feedlist.length; i++) {
            const obj = this.feedlist[i];
            if (obj.id === f.id) {
              this.feedlist[i].noComments = this.feedcom.noComments;
              this.feedlist[i].feedscomment = this.feedcom.feedscomment;
              this.feedlist[i].showMoreComments = this.feedcom.showMoreComments;
              this.feedlist[i].newComments = new FeedsComments();
              break;
            }
          }
        },
          error => {
            this.router.navigate(['']);
          });
    }
  }

  onKeydownComment(event, f: Feeds) {
    if (f.newComments.comments == null || f.newComments.comments === '') {
      return;
    } else {
      if (event.key === 'Enter') {
        this.dashboardService.saveFeedComment(f)
          .subscribe(data => {
            this.feedcom = data;
            this.jsoncomlist = this.feedlist;
            f.comdiv = false;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.feedlist.length; i++) {
              const obj = this.feedlist[i];
              if (obj.id === f.id) {
                this.feedlist[i].noComments = this.feedcom.noComments;
                this.feedlist[i].feedscomment = this.feedcom.feedscomment;
                this.feedlist[i].showMoreComments = this.feedcom.showMoreComments;
                this.feedlist[i].newComments = new FeedsComments();
                break;
              }
            }
          },
            error => {
              this.router.navigate(['']);
            });
      }
    }
  }
  sharepost(f: Feeds) {
    this.dashboardService.sharepost(f)
      .subscribe(data => {
        this.feedlist = data.dtoList;
        //        this.router.navigate(['editprofile']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  showLikedCandidate() {

  }

  toggle(f: Feeds) {
    f.comdiv = !f.comdiv;
  }


  loadNextPage() {
    this.pageNo = this.pageNo + 1;
    this.dashboardService.loadNextPage(this.pageNo)
      .subscribe(data => {
        this.temfeedlist = data.dtoList;
        this.feedlist = this.feedlist.concat(this.temfeedlist);
        //        this.router.navigate(['editprofile']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  deleteFeed(f) {
    this.dashboardService.deleteFeed(f)
      .subscribe(data => {
        const feedList = this.feedlist;

        for (let i = 0, size = feedList.length; i < size; i++) {

          if (f.id === feedList[i].id) {
            this.feedlist.splice(i, 1);
            break;
          }

        }
        this.sharedeleteDialog();
      },
        error => {
          this.router.navigate(['']);
        });

  }

  blockFeed(f) {
    this.dashboardService.blockFeed(f)
      .subscribe(data => {
        const feedList = this.feedlist;

        for (let i = 0, size = feedList.length; i < size; i++) {

          if (f.id === feedList[i].id) {
            this.feedlist.splice(i, 1);
            break;
          }

        }
        this.sharedeleteDialog();
      },
        error => {
          this.router.navigate(['']);
        });

  }

  reportFeed(f) {
    this.dashboardService.reportFeed(f)
      .subscribe(data => {
        const feedList = this.feedlist;

        for (let i = 0, size = feedList.length; i < size; i++) {

          if (f.id === feedList[i].id) {
            this.feedlist.splice(i, 1);
            break;
          }

        }

      },
        error => {
          this.router.navigate(['']);
        });
  }

  openInput(event) {
    // your can use ElementRef for this later
    document.getElementById('fileInput').click();

  }

  deleteFeedLike(f, em: number) {
    f.emoji = em;
    this.dashboardService.deleteFeedLike(f)
      .subscribe(data => {
        this.jsonflikelist = this.feedlist;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.feedlist.length; i++) {
          const obj = this.feedlist[i];
          if (obj.id === f.id) {
            this.feedlist[i].noLike = data.noLike;
            this.feedlist[i].likeByLoggedInCandidate = data.likeByLoggedInCandidate;
            this.feedlist[i].feedsLikeList = data.feedsLikeList;
            if (em === 1) {
              // this.feedlist[i].noEmojiLike = false;
              this.feedlist[i].emojiLike = false;
            } else if (em === 2) {
              // this.feedlist[i].noEmojiLove = false;
              this.feedlist[i].emojiLove = false;
            } else if (em === 3) {
              //  this.feedlist[i].noEmojiHaha = false;
              this.feedlist[i].emojiHaha = false;
            } else if (em === 4) {
              //  this.feedlist[i].noEmojiWow = false;
              this.feedlist[i].emojiWow = false;
            } else if (em === 5) {
              //  this.feedlist[i].noEmojiSad = false;
              this.feedlist[i].emojiSad = false;
            }
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  candidateVerification(code) {// alert(code)
    this.dashboardService.candidateVerification(code)
      .subscribe(data => {
        alert(data);
        //  this.verifiedStatus = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  resendverification() {
    this.dashboardService.resendVerification()
      .subscribe(data => {
        alert(data);
        //  this.verifiedStatus = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  prevData(feedscomment, commLimit) {
    this.pageStart = commLimit;
    if (feedscomment.length - this.commLimit < 4) {
      this.prevButt = false;
    }
    this.commLimit = commLimit + 4;
  }

  newData() {
    this.pageStart = 0;
    this.commLimit = 4;
    this.prevButt = true;
  }



  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value >= 2) {
      return Math.round(value / 2);
    }

    return value;
  }

  deleteFeedComment(feedId, feedCommentId, comment) {
    this.dashboardService.deleteFeedComment(comment)
      .subscribe(data => {
        this.feedList = this.feedlist;
        // let isRemoved = false;
        for (let i = 0, size = this.feedList.length; i < size; i++) {
          if (feedId === this.feedList[i].id) {
            this.feedCommentList = this.feedList[i].feedscomment;
            for (let j = 0, size1 = this.feedCommentList.length; j < size1; j++) { //alert("jloop")
              if (this.feedCommentList[j].id === feedCommentId) { //alert(this.feedCommentList[j].id +"---"+feedCommentId);
                this.feedCommentList.splice(j, 1);
             //   this.feedlist.splice(i, 1);
                //     isRemoved = true;
                break;
              }
            }
          }

        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  OncLickFirstFeed(e: string) {
    this.cookieService.set('FIRST_FEED', 'false', null, null, null, null, null);
    this.sessionCandidate.firstFeed = e;
  }

  onClickVerification(ev: string) {
    if (ev === 'false') {
      alert('please wrong data' + ev);
    } else {
      this.sessionCandidate.verified = ev;
    }
  }

  showfeedtext() {
    this.showfeed = true;
    this.showimg = false;
  }
  showimgwindow() {
    this.showfeed = false;
    this.showimg = true;
  }





  firstFeed() {
    this.firstfeednotificationService.firstFeed()
      .subscribe(data => {
       // this.cookieService.set('FIRST_FEED', 'false');
        this.cookieService.set('FIRST_FEED', 'false', null, null, null, null, null);
        //  this.router.navigate(['editprofile']);
        this.sessionCandidate.firstFeed = 'false';
      },
        error => {
          this.router.navigate(['']);
        });
  }
}
