import { Candidates } from './candidate.model';
export class CandidateSkill {
    id: number;
    candidate: Candidates;
    skillCategoryExp: string;
    proficiency: string;
    lastUpdatedOn: string;
    skilDiv: false;
    primarySkill: boolean;
}
