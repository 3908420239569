import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class SearchbycontainertypeService {

  private purUrl = ShipStreetProps.API_PATH + 'containerpurchase/findPurchaseByConType';
  private salUrl = ShipStreetProps.API_PATH + 'containersales/findSalesByConType';

  constructor(private http: HttpClient) { }

  public findPurchaseByType(purchase : ContainerPurchase) { // alert(JSON.stringify(conType));
    return this.http.post<ContainerPurchase[]>(this.purUrl , purchase);
  }

  public findSalesByType(sales: ContainerSales) {
    return this.http.post<ContainerSales[]>(this.salUrl , sales);
  }

}
