import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate131',
  templateUrl: './candidate131.component.html',
  styleUrls: ['./candidate131.component.css']
})
export class Candidate131Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
