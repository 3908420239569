import { Component, OnInit } from '@angular/core';
import { OnewaymovedetailsService } from './onewaymovedetails.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ContainerOnewayMove } from 'src/app/model/containeronewaymove.model';

@Component({
  selector: 'app-onewaymovedetails',
  templateUrl: './onewaymovedetails.component.html',
  styleUrls: ['./onewaymovedetails.component.css']
})
export class OnewaymovedetailsComponent implements OnInit {

  onewayobj: ContainerOnewayMove;

  constructor(private onewaymovedetailsService: OnewaymovedetailsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.onewaymovedetailsService.findSingleContainerOnewayMove(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.onewayobj = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
