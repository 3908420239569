import { ShipStreetProps } from './../../../utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Injectable({
  providedIn: 'root'
})
export class DepotsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'masterdepots/masterDepotsView.do';

  getAllMyDepots() {
    return this.http.get<MasterPortsDepot[]>(this.appUrl);
  }
}
