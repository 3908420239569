import { Component, OnInit } from '@angular/core';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { RequireMatch } from 'src/app/containers/tradings/containerpurchase/newcontainerpurchase/newcontainerpurchase.component';
import { Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { EditcalcvesseloperatorService } from './editcalcvesseloperator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-editcalcvesseloperator',
  templateUrl: './editcalcvesseloperator.component.html',
  styleUrls: ['./editcalcvesseloperator.component.css']
})
export class EditcalcvesseloperatorComponent implements OnInit {

  masterVesselOperator: MasterVesselOperator;
  CountryList: CountryInfo[];
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
  constructor(private editcalcvesseloperatorService: EditcalcvesseloperatorService, private router: Router, 
              private route: ActivatedRoute) { }

  ngOnInit() {
   this.masterVesselOperator = new MasterVesselOperator();
   this.masterVesselOperator.country = new CountryInfo();

    this.editcalcvesseloperatorService.getVesselOperator(this.route.snapshot.params.id)
      .subscribe(data => {
      this.masterVesselOperator = data;
      //  this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
        //  map(val => this._filter4(val))
       // );
      },
        error => {
          this.router.navigate(['']);
        });


    this.editcalcvesseloperatorService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          map(val => this._filter4(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateCompanyVesselOperator() {
    this.editcalcvesseloperatorService.updateCompanyVesselOperator(this.masterVesselOperator)
      .subscribe(data => {
        this.router.navigate(['/calculator/calcvesseloperator']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }

  }

}
