import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Candidates } from 'src/app/model/candidate.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class AccountverificationService {

  private appUrl = ShipStreetProps.API_PATH + 'candidateVerification.do';
  private resendUrl = ShipStreetProps.API_PATH + 'resendVerificationCode.do';
  constructor(private http: HttpClient) { }

  public candidateVerification(candidate: Candidates) {
    return this.http.post<SessionCandidate>(this.appUrl , candidate);
  }
  public resendVerification(candidate: Candidates) {
    return this.http.post<string>(this.resendUrl , candidate);
  }
}
