import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainertypeService } from 'src/app/containers/master/containertype/containertype.service';
import { Containers } from 'src/app/model/containers.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { CommonService } from 'src/app/utils/common.service';
import { EditcontainerinfoService } from './editcontainerinfo.service';

@Component({
  selector: 'app-editcontainerinfo',
  templateUrl: './editcontainerinfo.component.html',
  styleUrls: ['./editcontainerinfo.component.css']
})
export class EditcontainerinfoComponent implements OnInit {

  conttypeList: ContainerType[];
  container: Containers;
  ContainerStatus: string[] = ['AVAILABLE', 'IDLE'];

  constructor(private editcontainerinfoService: EditcontainerinfoService, private router: Router, 
    private containertypeService: ContainertypeService, private commonService: CommonService,
    private route: ActivatedRoute) { }

  sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() {
    this.container = new Containers();
    this.container.equipmentType = new ContainerType();

    this.editcontainerinfoService.getcontainerbyid(this.route.snapshot.params.id)
      .subscribe(data => {
        this.container = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.containertypeService.findAllContainerTypeByOrder()
      .subscribe(data => {
        this.conttypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });


  }

  updateContainerInfo() {
    this.editcontainerinfoService.updateContainerInfo(this.container)
      .subscribe(data => {
        this.router.navigate(['containers/viewpurchasecontainers' , 'ALL']);
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
