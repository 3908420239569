import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsInvoice } from 'src/app/model/accountsnvoice.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class AccountsreceivesalespaymentService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'accountssalesinvoice/findaSalesInvoiceDetails';
  private recPayUrl = ShipStreetProps.API_PATH + 'accountssalesinvoice/accountReceivePayment';

  public findaSalesInvoiceDetails(salesId: string) {
    return this.http.post<AccountsInvoice>(this.appUrl , salesId);
  }

  public accountReceivePayment(saleInvoice: AccountsInvoice) {
    return this.http.post<AccountsInvoice>(this.recPayUrl , saleInvoice);
  }
}
