import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { TbagentresponsetoprincipleService } from './tbagentresponsetoprinciple.service';
import { AgentPrincipleFreightDetails } from 'src/app/model/agentprinciplefreightdetails.model';
import { Router } from '@angular/router';
import { AgentPrincipleFreight } from 'src/app/model/agentprinciplefreight.model';
import { Company } from 'src/app/model/company.model';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

export interface DialogData {
  principleName: string;
  principleId: number;
  bookingId: number;
  freightId: number;
}
@Component({
  selector: 'app-tbagentresponsetoprinciple',
  templateUrl: './tbagentresponsetoprinciple.component.html',
  styleUrls: ['./tbagentresponsetoprinciple.component.css']
})
export class TbagentresponsetoprincipleComponent implements OnInit {

  companyName = this.data.principleName;
  companyId = this.data.principleId;
  tbrequestView: AgentPrincipleFreightDetails[];
  tbfreightRequest: AgentPrincipleFreightDetails;

  constructor(private tbagentresponsetoprincipleService: TbagentresponsetoprincipleService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData, private router: Router,
    private _bottomSheetRef: MatBottomSheetRef<TbagentresponsetoprincipleComponent>) { }

  ngOnInit() {
    this.tbfreightRequest = new AgentPrincipleFreightDetails();
    this.tbfreightRequest.agentPrincipleFreight = new AgentPrincipleFreight();
    this.tbfreightRequest.agentPrincipleFreight.principleId = new Company();
    this.tbfreightRequest.agentPrincipleFreight.bookingId = new TrackBoxBooking();

    this.tbagentresponsetoprincipleService.getAllFreightDetailsById(this.data.freightId)
      .subscribe(data => {
        this.tbrequestView = data;
        console.log("sdsd"+this.tbrequestView)
      },
        error => {
          this.router.navigate(['']);
        });
  }

  responseToPrinciple() {
    this.tbfreightRequest.agentPrincipleFreight.id = this.data.freightId;
    this.tbfreightRequest.agentPrincipleFreight.principleId.id = this.data.principleId;
    this.tbfreightRequest.agentPrincipleFreight.bookingId.id = this.data.bookingId;
    this.tbagentresponsetoprincipleService.responseToPrinciple(this.tbfreightRequest)
      .subscribe(data => {
        alert("hi"+this.data.freightId);
       // this._bottomSheetRef.dismiss();
      //  this.tbagentresponsetoprincipleService.getAllFreightDetailsById(this.data.freightId)
      // .subscribe(data1 => {
      //   this.tbrequestView = data1;
      // },
      //   error => {
      //     this.router.navigate(['']);
      //   });
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onNoClick() { this._bottomSheetRef.dismiss(); }

}
