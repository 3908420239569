import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentbookingdetailsService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/TrackboxBookingsViewbyId.do';


public getBookingsDetailsByID( id: string) {
  return this.http.post<TrackBoxBooking>(this.appUrl, id);
  }

}
