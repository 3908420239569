import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackboxChargeHeads } from 'src/app/model/trackboxchargeheads.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewchargeheadsService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'TrackboxChargeHeads/trackboxChargeheadssave.do';
public chargeHeadsave(chargehead: TrackboxChargeHeads) {
  return this.http.post<string>(this.appUrl, chargehead);
}
}
