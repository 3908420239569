import { CompanyeditService } from './companyedit.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Feeds } from 'src/app/model/feeds.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { RecruiterCompany } from 'src/app/model/recruiterCompany.model';
import { CompanyIndustry } from 'src/app/model/companyIndustry.model';
import { Candidates } from 'src/app/model/candidate.model';
import { Dashboard } from 'src/app/model/dashboard.model';
import { Company } from 'src/app/model/company.model';
import { Jobs } from 'src/app/model/jobs.model';
import { Marketplace } from 'src/app/model/marketplace.model';
import { CompanyFollow } from 'src/app/model/companyFollow.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';

@Component({
  selector: 'app-companyedit',
  templateUrl: './companyedit.component.html',
  styleUrls: ['./companyedit.component.css']
})
export class CompanyeditComponent implements OnInit {
  imagePostComment: string;
  newpost1: Feeds;
  comdiv: boolean;
  loggedTime: string;
  companyId: number;
  compEmp: CompanyEmployee;
  newpost: Feeds;
  companyWaitingRecruiters: RecruiterCompany[];
  companyWaitingEmployees: CompanyEmployee[];
  nameDiv: boolean;
  showimg: boolean;
  showbutton: boolean;
  showfeed: boolean;
  companyindustries: CompanyIndustry[];
  appReqDiv: boolean;
  descDiv: boolean;
  candidate: Candidates;
  feedlist: Feeds[];
  pageNo: number;
  dashboard: Dashboard;
  company: Company;
  companyEmployees: CompanyEmployee[];
  jobList: Jobs[];
  marketplaces: Marketplace[];
  companyfollowlist: CompanyFollow[];
  masterportlist: MasterPortsDepot[];
  myControl = new FormControl();
  myControl2 = new FormControl();
  showExpIcon: boolean;
  showme: boolean;
  showFeed: boolean;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;

  myControl4 = new FormControl();
  filteredOptionseditport: Observable<MasterPortsDepot[]>;
 

  companytype = [
    { value: 'Port', text: 'Port' },
    { value: 'Depot', text: 'Depot' },
    { value: 'Shipping Lane', text: 'Shipping Lane' },
    { value: 'NVOCC', text: 'NVOCC' },
    { value: 'Leasing', text: 'Leasing' },
    { value: 'Logistics', text: 'Logistics' },
    { value: 'Transports', text: 'Transports' },
    { value: 'Trader', text: 'Trader' },
    { value: 'Other', text: 'Other' }
  ];
  compsize = [
    { value: '1', text: '1' },
    { value: '2-10', text: '2-10' },
    { value: '11-50', text: '11-50' },
    { value: '51-200', text: '51-200' },
    { value: '201-500', text: '201-500' },
    { value: '501-1,000', text: '501-1,000' },
    { value: '1,001-5,000', text: '1,001-5,000' },
    { value: '5,000+', text: '5,000+' }
  ];
  companystatus = [
    { value: 'PRINCIPLE', text: 'PRINCIPLE' },
    { value: 'AGENT', text: 'AGENT' },
  ];

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  descriptionDiv: boolean;
  showImgFeed: boolean;
  countryList: CountryInfo[];
  jsonList: CompanyEmployee[];

  constructor(private companyeditService: CompanyeditService, private route: ActivatedRoute, private router: Router,
    private portsService:PortsService) { }

  selectedFile: File;
  previewFile: any;
  onFileChanged(event) {
    // console.log(event.target.files[0]);
    // console.log(event.target);
    // console.log(event.target.file);
    this.showfeed = false;

    if (event.target) {
      const reader = new FileReader();
      this.selectedFile = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event1: any) => { // called once readAsDataURL is completed
        console.log('Read As Data UR is Completed :' + event);
        this.previewFile = event1.target.result;
      };
    }

  }


  onUpload() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    // console.log(uploadData)
    this.companyeditService.companyLogoUpload(uploadData).subscribe(data => {
      this.company.companyLogo = data.fileName;
      // console.log(this.company);
      this.companyeditService.updateCompanyLogo(this.company).subscribe(data1 => {
        this.showimg = false;
      });
    },
      error => {
        this.router.navigate(['']);
      });

  }


  onUploadFeed() {

    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    //  console.log(uploadData);
    this.companyeditService.postImageUpload(uploadData).subscribe(data => {
      //  console.log(data);
      // alert(JSON.stringify(this.newpost));
      // alert(JSON.stringify(this.newpost.feedsContent));
      if (this.newpost1.feedsContent === '') {
        this.newpost1.feedImage = data.fileName;
        this.newpost1.feedType = data.fileType;
      } else {
        this.newpost1.feedImage = data.fileName;
        this.newpost1.feedType = data.fileType;
      }
      this.newpost1.feedsContent = this.imagePostComment;
      this.newpost1.company.id = this.route.snapshot.params.id;
      //  alert(JSON.stringify(this.newpost));
      this.companyeditService.postNewCompanyImage(this.newpost1).subscribe(data1 => {
        this.feedlist = data1.dtoList;
        this.showimg = false;
        //   this.mediaHere = "";
        //  this.newpost="";
        this.showImgFeed = true;
        this.showfeed = true;
      });
    },
      error => {
        this.router.navigate(['']);
      });

  }

  ngOnInit() {
  
    this.compEmp = new CompanyEmployee();
    this.compEmp.candidate = new Candidates();
    this.compEmp.company = new Company();

    this.newpost1 = new Feeds();
    this.newpost1.company = new Company();
    this.imagePostComment = '';
    this.comdiv = false;
    this.companyId = this.route.snapshot.params.id;
    this.newpost = new Feeds();
    this.newpost.company = new Company();
    this.descDiv = true;
    this.descriptionDiv = true;
    this.appReqDiv = true;
    this.company = new Company();
    this.company.defaultPort=new MasterPortsDepot();
    this.showfeed = true;
    this.showbutton = true;
    this.showimg = false;
    this.nameDiv = true;

    this.company.industry = new CompanyIndustry();
    this.companyeditService.getCompanyByID(this.route.snapshot.params.id)
      .subscribe(data => {
        this.company = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.companyeditService.getCompanyEmployee(this.route.snapshot.params.id)
      .subscribe(data => {
        this.companyEmployees = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.companyeditService.getCompanyJobList(this.route.snapshot.params.id)
      .subscribe(data => {
        this.jobList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.companyeditService.getCompanyMarketplace(this.route.snapshot.params.id)
      .subscribe(data => {
        this.marketplaces = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.companyeditService.getFollowList(this.route.snapshot.params.id)
      .subscribe(data => {
        this.companyfollowlist = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.companyeditService.getCompanyFeeds(this.route.snapshot.params.id)
      .subscribe(data => {
        this.dashboard = data;
        this.feedlist = this.dashboard.dtoList;
        this.candidate = this.dashboard.candidate;
        this.loggedTime = this.dashboard.loggedInTime;
        this.pageNo = 1;
        //   },
        // error => {
        //   this.router.navigate(['']);
      });
    this.companyeditService.getCompanyIndustries()
      .subscribe(data => {
        this.companyindustries = data;
        //   },
        // error => {
        //   this.router.navigate(['']);
      });
    this.companyeditService.getCompanyEmployeeWaitingList(this.route.snapshot.params.id)
      .subscribe(data => {
        this.companyWaitingEmployees = data;
        //   },
        // error => {
        //   this.router.navigate(['']);
      });
    this.companyeditService.getCompanyRecruiterWaitingList(this.route.snapshot.params.id)
      .subscribe(data => {
        this.companyWaitingRecruiters = data;
        //   },
        // error => {
        //   this.router.navigate(['']);
      });
    this.companyeditService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );
    
        console.log(this.filteredOptionscountry);
      },
        error => {
          this.router.navigate(['']);
        });

        this.portsService.getAllMyPorts()
      .subscribe(data => {
        this.masterportlist = data;
        
      },
        error => {
          this.router.navigate(['']);
        });

        this.filteredOptionseditport = this.myControl4.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter5(val))
        );

  }

  updateCompanyName() {
    this.companyeditService.updateCompanyName(this.company)
      .subscribe(data => {
        this.nameDiv = true;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateCompanyDescription() {
    this.companyeditService.updateCompanyDescription(this.company)
      .subscribe(data => {
        //    this.companyfollowlist = data;
        this.descriptionDiv = true;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateCompanySummary() {
    this.companyeditService.updateCompanySummary(this.company)
      .subscribe(data => {
        //    this.companyfollowlist = data;
        this.descDiv = true;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateCompanyDetails() {
    this.companyeditService.updateCompanyDetails(this.company)
      .subscribe(data => {
        this.company = data;
        this.appReqDiv = true;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  publishcompanypost() {
    this.newpost.company.id = this.route.snapshot.params.id;
    this.companyeditService.publishcompanypost(this.newpost)
      .subscribe(data => {
        this.feedlist = data.dtoList;

      },
        error => {
          this.router.navigate(['']);
        });
  }

  companyEmloyeeApprove(waiting) {
    this.companyeditService.companyEmloyeeApprove(waiting)
      .subscribe(data => {
        this.companyWaitingEmployees = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  companyEmloyeeReject(waiting) {

  }
  addCompanyEmployee(candidate) {
    this.compEmp = new CompanyEmployee();
    this.compEmp.candidate = new Candidates();
    this.compEmp.company = new Company();
    this.compEmp.candidate.id = candidate;
    this.compEmp.company.id = this.route.snapshot.params.id;

    this.companyeditService.addCompanyEmployee(this.compEmp)
      .subscribe(data => {
        this.companyWaitingEmployees = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  markcompanyadmin(candidateId) {
    this.compEmp.candidate.id = candidateId;
    this.compEmp.company.id = this.route.snapshot.params.id;
    this.companyeditService.markcompanyadmin(this.compEmp)
      .subscribe(data => {
        this.jsonList = this.companyEmployees;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.candidate.id === candidateId) {
            this.companyEmployees[i].userType = 1;
            break;
          }
        }


      },
        error => {
          this.router.navigate(['']);
        });
  }

  markcompanymoderator(candidateId) {
    this.compEmp.candidate.id = candidateId;
    this.compEmp.company.id = this.route.snapshot.params.id;
    this.companyeditService.markcompanymoderator(this.compEmp)
      .subscribe(data => {
        this.jsonList = this.companyEmployees;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.candidate.id === candidateId) {
            this.companyEmployees[i].userType = 2;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }


  showImage(imageName) {
    if (null == imageName) {
      return false;
    }
    const k = imageName.indexOf('video');
    if (k === -1) {
      return true;
    } else {
      return false;
    }
  }

  loadNextPage() {

  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.countryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public _filter5(value: string): MasterPortsDepot[] {
    const filterValue5 = value;
    return this.masterportlist.filter(defaultPort => defaultPort.name.toLowerCase().indexOf(filterValue5) === 0 ||
    defaultPort.name.toLowerCase().indexOf(filterValue5) === 0 ||
    defaultPort.name.toUpperCase().indexOf(filterValue5) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    return cntr && cntr.name ? cntr.name : '';
    // if (cntr && cntr.name) {
    //   return cntr.name;
      
    //   // + '-' + cntr.code2;

    // }

}
public displayFn(user: MasterPortsDepot): string {
  return user && user.name ? user.name : '';
}

makePrimaryCompany(companyId) {
  this.companyeditService.makePrimaryCompany(companyId)
      .subscribe(data => {
    this.router.navigate(['/m/companydetails/'+companyId+'/true']);
      },
        error => {
          this.router.navigate(['']);
        });
}

  // enrollcontainercompany() {
  //    this.containersignupService.companysignup(this.employee)
  //     .subscribe(data => {
  //       this.cookieService.set('CAND_ID', '' + data.id);
  //       // this.cookieService.set('CAND_FN', data.firstName);
  //       // this.cookieService.set('CAND_LN', data.lastName);
  //       // this.cookieService.set('COMP_ID', '' + data.company.id);
  //       // this.cookieService.set('BRCH_ID', '' + data.branch.id);
  //       this.router.navigate(['/cntsignupcodeverification', data.id, data.company.id, data.branch.id]);
  //     },
  //       error => {
  //         this.router.navigate(['']);
  //       });
  // }

}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}