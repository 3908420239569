import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Candidates } from 'src/app/model/candidate.model';

@Injectable({
  providedIn: 'root'
})
export class TrackboxheaderService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'sessionCandidate.do';

  public sessionCandidate() {
    return this.http.get<Candidates>(this.appUrl);
  }

}
