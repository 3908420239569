import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feed101',
  templateUrl: './feed101.component.html',
  styleUrls: ['./feed101.component.css']
})
export class Feed101Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
