import { Component, OnInit } from '@angular/core';
import { CostcalculatorsettingdetailsService } from './costcalculatorsettingdetails.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';

@Component({
  selector: 'app-costcalculatorsettingdetails',
  templateUrl: './costcalculatorsettingdetails.component.html',
  styleUrls: ['./costcalculatorsettingdetails.component.css']
})
export class CostcalculatorsettingdetailsComponent implements OnInit {

  costCalObj: MasterPortCostDetails;

  constructor(private costcalculatorsettingdetailsService: CostcalculatorsettingdetailsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.costcalculatorsettingdetailsService.costCalculatorDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.costCalObj = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
