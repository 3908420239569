import { CompanyheaderService } from './companyheader.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CommonService } from 'src/app/utils/common.service';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';

@Component({
  selector: 'app-companyheader',
  templateUrl: './companyheader.component.html',
  styleUrls: ['./companyheader.component.css']
})
export class CompanyheaderComponent implements OnInit {
  imagePath = ShipStreetProps.AWS_IMAGE_PATH; // ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private companyheaderService: CompanyheaderService , private router: Router , private commonService: CommonService ,
    private searchBarService: SearchbarService) { }
  sessionCandidate = this.commonService.getSessionCandidate();
  ngOnInit() {

  }
  logout() {
    this.searchBarService.logout()
      .subscribe( data => {
        this.commonService.removeRSCookieValues();
         // this.sessioncandidate = data;
        this.router.navigate(['']);
        },
      error => {
        this.router.navigate(['']);
        });

  }

}
