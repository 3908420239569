import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountsPaymentMode } from 'src/app/model/accountspaymentmode.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewaccountspaymentmodeService {

  private saveUrl = ShipStreetProps.API_PATH + 'containersaccounts/saveAccountsPaymentMode';
  constructor(private http: HttpClient) { }

  public saveAccountsPaymentMode(paymentMode: AccountsPaymentMode) {
    return this.http.post<string>(this.saveUrl , paymentMode);
  }
}
