import { Company } from './company.model';
import { Candidates } from './candidate.model';

export class ContainerCompany {
    id: number;
    company: number;
    // candidate: Candidates;
    createdOn: Date;
    firstLogin: boolean;
    freeTrail: boolean;
    freeTrailStart: Date;
    freeTrailEnd: Date;
    planType: string;
    planStartsFrom: Date;
    planEndTo: Date;
}
