import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TaxagenciesService } from './taxagencies.service';
import { TaxAgency } from 'src/app/model/taxagency.model';

@Component({
  selector: 'app-taxagencies',
  templateUrl: './taxagencies.component.html',
  styleUrls: ['./taxagencies.component.css']
})
export class TaxagenciesComponent implements OnInit {
  taxagencies: TaxAgency[];
  p = 1;

  constructor(private taxagenciesService: TaxagenciesService , private router: Router) { }

  ngOnInit() {
    this.taxagenciesService.findAllTaxAgency()
      .subscribe( data => {
        this.taxagencies = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
