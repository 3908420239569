import { MyBoxSearchDTO } from './../../model/myboxsearchDTO.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MyboxesService } from './myboxes.service';
import { Component, OnInit } from '@angular/core';
import { NewequipmentcontrolService } from '../equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { PortsService } from '../master/ports/ports.service';
import { DepotsService } from '../master/depots/depots.service';
import { ContainerType } from 'src/app/model/containertype.model';
import { TrackBoxCarrier } from 'src/app/model/trackboxcarrier.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { isNull } from 'util';
import { MyBoxSearchExport } from 'src/app/model/mybox.export.model';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as FileSaver from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-myboxes',
  templateUrl: './myboxes.component.html',

  styleUrls: ['./myboxes.component.css']
})
export class MyboxesComponent implements OnInit {

  countryList: CountryInfo[];
  countryid: CountryInfo;
  masterDepotList: MasterPortsDepot[];
  masterPortList: MasterPortsDepot[];
  ContainerTypeList: ContainerType[];
  tbcarrier: TrackBoxCarrier[];
  p: number;

  countryId = 0;
  portId = 0;
  containerTypes = [];
  myboxsearch: MyBoxSearchDTO;
  // boxsearch: MyBoxSearchDTO[];
  myControl = new FormControl();
  myControl1 = new FormControl();
  myControl2 = new FormControl();
  myControl3 = new FormControl();
  containerTypeFC = new FormControl();

  filteredOptions: Observable<MasterPortsDepot[]>;
  filteredOptionsport: Observable<MasterPortsDepot[]>;
  filteredOptionscarrier: Observable<TrackBoxCarrier[]>;
  filteredOptionscountry: Observable<CountryInfo[]>;
  searchResultList: TrackBoxContainer[];
  fileName = 'ExcelSheet.xlsx';
  noRecordsMsg = '';

  printList: MyBoxSearchExport[];
  myBox: MyBoxSearchExport;
  constructor(private myboxesService: MyboxesService, private router: Router,
              private route: ActivatedRoute, private newequipmentcontrolService: NewequipmentcontrolService,
              private depotsService: DepotsService, private portService: PortsService) { }

  ngOnInit() {
    this.noRecordsMsg = '';
    this.myboxsearch = new MyBoxSearchDTO();
    this.myboxsearch.country = 0;
    this.myboxsearch.carrier = 0;
    this.myboxsearch.containerType = [];
    this.myboxsearch.depot = 0;
    this.myboxsearch.port = 0;
    // this.myboxsearch.country = new CountryInfo();


    // this.myboxsearch.port = new MasterPortsDepot();
    //  this.myboxsearch.depot = new MasterPortsDepot();
    // this.myboxsearch.carrier = new TrackBoxCarrier();

    // this.myboxsearch.tbcontainers = new  TrackBoxContainer();
    // this.myboxsearch.tbcontainers.container = new Containers();

    this.newequipmentcontrolService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );

        console.log(this.filteredOptions);
      },
        error => {
          this.router.navigate(['']);
        });

    this.depotsService.getAllMyDepots()
      .subscribe(data => {
        this.masterDepotList = data;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter1(val))
        );

        console.log(this.filteredOptions);
      },
        error => {
          this.router.navigate(['']);
        });

    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.newequipmentcontrolService.viewcontainerType()
      .subscribe(data => {
        this.ContainerTypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newequipmentcontrolService.viewCarrier()
      .subscribe(data => {
        this.tbcarrier = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.filteredOptionsport = this.myControl1.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter2(val))
    );
    this.filteredOptionscarrier = this.myControl2.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter3(val))
    );
  }


  public _filter1(value: string): MasterPortsDepot[] {
    const filterValue = value;
    //  console.log('=====>>' + filterValue);

    //  console.log(this.filteredOptions);
    console.log(this.masterDepotList.filter(depot => depot.name.toLowerCase().indexOf(filterValue) === 0 ||
      depot.portCode.toLowerCase().indexOf(filterValue) === 0));

    return this.masterDepotList.filter(depot => depot.name.toLowerCase().indexOf(filterValue) === 0 ||
      depot.name.toUpperCase().indexOf(filterValue) === 0 ||
      depot.portCode.toLowerCase().indexOf(filterValue) === 0);
  }

  public _filter2(value: string): MasterPortsDepot[] {
    const filterValue2 = value.toLowerCase();
    // console.log('=====>>' + filterValue2);

    return this.masterPortList.filter(port => port.name.toLowerCase().indexOf(filterValue2) === 0 ||
      port.name.toUpperCase().indexOf(filterValue2) === 0 ||
      port.portCode.toLowerCase().indexOf(filterValue2) === 0);

  }
  public _filter3(value: string): TrackBoxCarrier[] {
    const filterValue3 = value;
    //  console.log('=====>>' + filterValue3);

    return this.tbcarrier.filter(carrier => carrier.name.toLowerCase().indexOf(filterValue3) === 0 ||
      carrier.name.toUpperCase().indexOf(filterValue3) === 0 ||
      carrier.lineCode.toLowerCase().indexOf(filterValue3) === 0);
    //    return this.masterDepotList.filter(team => team.name.toLowerCase().indexOf(filterValue) === 0 ||
    // team.portCode.toLowerCase().indexOf(filterValue) ===  );
  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    //  console.log('=====>>' + filterValue3);

    return this.countryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
    //    return this.masterDepotList.filter(team => team.name.toLowerCase().indexOf(filterValue) === 0 ||
    // team.portCode.toLowerCase().indexOf(filterValue) ===  );
  }
  public displayCountry(cntr: CountryInfo): string {

    if (cntr && cntr.name) {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.country = cntr.id;
      this.countryId = cntr.id;
      return cntr.name;
    } else {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.country = 0;
      return '';
    }
  }

  public displayPort(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.port = cntr.id;
      return cntr.name;
    } else {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.port = 0;
      return '';
    }
  }

  public displayDepot(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.depot = cntr.id;
      return cntr.name;
    } else {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); } this.myboxsearch.depot = 0;
      return '';
    }
  }
  public displayCarrier(cntr: TrackBoxCarrier): string {
    if (cntr && cntr.name) {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
      this.myboxsearch.carrier = cntr.id;
      return cntr.name;
    } else {
      if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); } this.myboxsearch.carrier = 0;
      return '';
    }
  }

  public onSearch() {
    this.myboxsearch = new MyBoxSearchDTO();
    if (this.myControl3 && this.myControl3.value && this.myControl3.value.id) {
      this.myboxsearch.country = this.myControl3.value.id;
    } else {
      this.myboxsearch.country = 0;
    }

    if (this.myControl1 && this.myControl1.value && this.myControl1.value.id) {
      this.myboxsearch.port = this.myControl1.value.id;
    } else {
      this.myboxsearch.port = 0;
    }

    if (this.myControl && this.myControl.value && this.myControl.value.id) {
      this.myboxsearch.depot = this.myControl3.value.id;
    } else {
      this.myboxsearch.depot = 0;
    }

    if (this.myControl2 && this.myControl2.value && this.myControl2.value.id) {
      this.myboxsearch.carrier = this.myControl2.value.id;
    } else {
      this.myboxsearch.carrier = 0;
    }

    if (this.containerTypeFC && this.containerTypeFC.value) {
      this.myboxsearch.containerType = this.containerTypeFC.value;
    } else {
      this.myboxsearch.containerType = [];
    }

    // console.log(JSON.stringify(this.myboxsearch));
    this.myboxesService.boxsearch(this.myboxsearch)
      .subscribe(data => {
        this.searchResultList = data;
        //  alert(JSON.stringify(this.searchResultList));
        if (this.searchResultList && this.searchResultList.length > 0) {
          this.noRecordsMsg = '';
        } else {
          this.noRecordsMsg = 'No Records for Search';
        }
      },
        error => {
          this.router.navigate(['']);
        });

  }


  // exportexcel(): void {
  //   /* table id is passed over here */
  //   const element = document.getElementById('customers');
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   /* save to file */
  //   XLSX.writeFile(wb, this.fileName);

  // }

  exportAsXLSX(): void {
    // this.exportAsExcelFile(this.tbcontainerList, 'sample');
    // printList: [];

    this.printList = new Array();

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.searchResultList.length; i = i + 1) {

      this.myBox = new MyBoxSearchExport();
      this.myBox.containerNumber = this.searchResultList[i].container.containerNumber;
      this.myBox.typeCode = this.searchResultList[i].container.equipmentType.typeCode;
      this.myBox.country = this.searchResultList[i].country.name;
      this.myBox.port = this.searchResultList[i].port.name;
      this.myBox.carrier = this.searchResultList[i].carrier.name;
      // this.myBox.gateIn = this.tbcontainerList[i].gateIn;
      // this.myBox.yom =this.tbcontainerList[i].container.manufacturingDate;
      // this.myBox.containerStatus =this.searchResultList[i].containerStatus.name;
      this.printList.push(this.myBox);


      // this.printList.push({ this.tbcontainerList[i].gateIn, this.tbcontainerList[i].gateOut})
    }
    this.exportAsExcelFile(this.printList, 'sample');

  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION);
  }

  //  exportAsPDF(generatePDF) {
  //   const data = document.getElementById('customers');
  //   html2canvas(data).then(canvas => {
  //     const contentDataURL = canvas.toDataURL('image/png');
  //     const pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
  //     // let pdf = new jspdf('1', 'cm', 'a4'); //Generates PDF in portrait mode
  //     pdf.addImage(contentDataURL, 'PNG', 0, 1, 15, 2);
  //     pdf.save('Filename.pdf');
  //   });

  // tslint:disable-next-line: member-ordering
  pdfContent: string;
  // tslint:disable-next-line: member-ordering
  tableContent = [];
  // tslint:disable-next-line:member-ordering
  rowContent = [];
  generatePDF() {


    this.tableContent.push([{ text: 'Container Name', bold: true, color: 'red' }, { text: 'ISO Code', bold: true },
    { text: 'Country ', bold: true, color: 'red' }, { text: 'Carrier', bold: true, color: 'red' },
    { text: 'Port', bold: true, color: 'red' }]);

    this.pdfContent = '<table ><tr>td>COntainer No</td><td>Code</td></tr>';

    this.printList = new Array();
    this.myBox = new MyBoxSearchExport();
    this.myBox.containerNumber = 'Continer Number';
    this.myBox.containerStatus = 'containerStatus';
    this.myBox.carrier = 'carrier';
    this.myBox.country = 'country';
    this.myBox.port = 'port';

    this.myBox.typeCode = 'ISO Code';
    this.printList.push(this.myBox);



    // this.printList.push({p.container.containerNumber, p.container.equipmentType.typeCode})
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.searchResultList.length; i = i + 1) {

      // this.rowContent.push([this.searchResultList[i].container.containerNumber,
      // this.searchResultList[i].container.equipmentType.typeCode]);
      // this.pdfContent = this.pdfContent+this.searchResultList[i].container.containerNumber;
      // this.pdfContent = this.pdfContent+this.searchResultList[i].containerStatus.name;
      // this.pdfContent = this.pdfContent+this.searchResultList[i].container.equipmentType.typeCode;
      // this.pdfContent= this.pdfContent+"\n";
      this.myBox = new MyBoxSearchExport();
      this.myBox.containerNumber = this.searchResultList[i].container.containerNumber;
      this.myBox.typeCode = this.searchResultList[i].container.equipmentType.typeCode;
      this.myBox.country = this.searchResultList[i].country.name;
      this.myBox.carrier = this.searchResultList[i].carrier.name;
      this.myBox.port = this.searchResultList[i].port.name;
      // this.myBox.gateIn = this.tbcontainerList[i].gateIn;
      // this.myBox.yom =this.tbcontainerList[i].container.manufacturingDate;
      // this.myBox.containerStatus =this.searchResultList[i].containerStatus.name;
      this.printList.push(this.myBox);
      //  alert(this.printList);
      // content: 'Sample PDF generated with Angular and PDFMake for C#Corner Blog'
    }
    this.tableContent.push(this.rowContent);
    const docDefinition1 = {

      content: '' + this.pdfContent

      //   content: this.searchResultList
    };

    console.log(this.printList);

    const docDefinition = {
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        }
      },
      content: [
        {
          text: 'Equipment Search Results',
          style: 'sectionHeader'
        },




        {
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
            body: // this.tableContent
              this.printList.map(p => ([p.containerNumber, p.typeCode, p.port, p.carrier, p.country]))
          }
        }
      ]



    };


    pdfMake.createPdf(docDefinition).open();

    // let docDefinition = {

    // content:  this.searchResultList



    //   pdfMake.createPdf(docDefinition).open();

  }
}


