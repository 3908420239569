import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companyheadermob',
  templateUrl: './companyheadermob.component.html',
  styleUrls: ['./companyheadermob.component.css']
})
export class CompanyheadermobComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
