import { Component, OnInit } from '@angular/core';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';
import { NewaccountscategoryService } from './newaccountscategory.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newaccountscategory',
  templateUrl: './newaccountscategory.component.html',
  styleUrls: ['./newaccountscategory.component.css']
})
export class NewaccountscategoryComponent implements OnInit {
  category: AccountsProductCategory;

  constructor(private newaccountscategoryService: NewaccountscategoryService, private router: Router) { }

  ngOnInit() {
    this.category = new AccountsProductCategory();

  }

  save() {
     this.newaccountscategoryService.save(this.category)
            .subscribe(data => {
              this.router.navigate(['/accounts/categorylist']);
            },
                error => {
                    this.router.navigate(['']);
                });
  }
}
