import { Component, OnInit } from '@angular/core';
import { CompanyvesseloperatorService } from './companyvesseloperator.service';
import { Router } from '@angular/router';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';

@Component({
  selector: 'app-companyvesseloperator',
  templateUrl: './companyvesseloperator.component.html',
  styleUrls: ['./companyvesseloperator.component.css']
})
export class CompanyvesseloperatorComponent implements OnInit {

  companyVesselOperatorList: MasterVesselOperator[];
  panelOpenState: boolean;
  p: number;

  constructor(private companyvesseloperatorService: CompanyvesseloperatorService, private router: Router) { }

  ngOnInit() {
    this.companyvesseloperatorService.getAllVesselOperator()
      .subscribe(data => {
        this.companyVesselOperatorList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
