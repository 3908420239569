import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Person } from 'src/app/model/person.model';
import { Candidates } from 'src/app/model/candidate.model';

@Injectable({
  providedIn: 'root'
})
export class AccountactivationfromloginService {

  private activateURL = ShipStreetProps.API_PATH + 'accountActivatedFromLogin.do';

  constructor(private http: HttpClient) { }

  public accountactivate(person: Person) {
    return this.http.post<Candidates>(this.activateURL, person);
  }
}
