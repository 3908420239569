import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Candidates } from 'src/app/model/candidate.model';
import { Company } from 'src/app/model/company.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CompanyeditService } from '../companyedit/companyedit.service';

@Component({
  selector: 'app-managecompanyemployees',
  templateUrl: './managecompanyemployees.component.html',
  styleUrls: ['./managecompanyemployees.component.css']
})
export class ManagecompanyemployeesComponent implements OnInit {
  companyEmployees: CompanyEmployee[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  breakpoint: number;
  compEmp: CompanyEmployee;
  jsonList: CompanyEmployee[];
  searchTxt: string;

  constructor(private companyeditService: CompanyeditService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.compEmp = new CompanyEmployee();
    this.compEmp.candidate = new Candidates();
    this.compEmp.company = new Company();
    this.companyeditService.getCompanyEmployee(this.route.snapshot.params.id)
    .subscribe(data => {
      this.companyEmployees = data;
    },
      error => {
        this.router.navigate(['']);
      });

      this.breakpointFun(event)
  }

  onResize(event) {
    this.breakpointFun(event)
  }

  breakpointFun(event?: any): void{
    if(event){
      this.breakpoint = (event.target.innerWidth <= 681) ? 1 : (event.target.innerWidth <= 1000) ? 3 : (event.target.innerWidth <= 1300) ? 3 : 4;
    } 
    else {
      this.breakpoint = (window.innerWidth <= 681) ? 1 : (window.innerWidth <= 1000) ? 3 : (window.innerWidth <= 1300) ? 3 : 4;
    }
  }


  markcompanyadmin(candidateId) {
    this.compEmp.candidate.id = candidateId;
    this.compEmp.company.id = this.route.snapshot.params.id;
    this.companyeditService.markcompanyadmin(this.compEmp)
      .subscribe(data => {
        this.jsonList = this.companyEmployees;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.candidate.id === candidateId) {
            this.companyEmployees[i].userType = 1;
            break;
          }
        }


      },
        error => {
          this.router.navigate(['']);
        });
  }

  markcompanymoderator(candidateId) {
    this.compEmp.candidate.id = candidateId;
    this.compEmp.company.id = this.route.snapshot.params.id;
    this.companyeditService.markcompanymoderator(this.compEmp)
      .subscribe(data => {
        this.jsonList = this.companyEmployees;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.candidate.id === candidateId) {
            this.companyEmployees[i].userType = 2;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onKeydown(event) {}

}
