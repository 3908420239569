import { Candidates } from '../../model/candidate.model';
import { HelpHeaderService } from './help-header.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';

@Component({
  selector: 'app-help-header',
  templateUrl: './help-header.component.html',
  styleUrls: ['./help-header.component.css']
})
export class HelpHeaderComponent implements OnInit {
  candidate: Candidates;
  isGuest: boolean;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private helpHeaderService: HelpHeaderService , private router: Router) { }

  ngOnInit() {
    this.isGuest =  true;
    this.helpHeaderService.sessionCandidate()
      .subscribe( data => {
        this.candidate = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  toggleLeft() {}

  logout() {}

}
