import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPresales } from 'src/app/model/containerpresales.model';
import { ContainerPresalesConversation } from 'src/app/model/containerpresalesconversation.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class PresalesconversationsService {

  private saveUrl = ShipStreetProps.API_PATH + 'containerpresalesconversation/saveContainerPreSalesConversation';
  private listUrl = ShipStreetProps.API_PATH + 'containerpresalesconversation/findConvestionsByPreSales';

  constructor(private http: HttpClient) { }

  public saveContainerPreSalesConversation(conversation: ContainerPresalesConversation) {
    return this.http.post<string>(this.saveUrl , conversation);
  }

  public findConvestionsByPreSales(presalesid: string) {
    return this.http.post<ContainerPresalesConversation[]>(this.listUrl , presalesid);
  }
  
}
