import { Posts } from '../../model/posts.model';
import { NewpostService } from './newpost.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-newpost',
  templateUrl: './newpost.component.html',
  styleUrls: ['./newpost.component.css']
})
export class NewpostComponent implements OnInit {

  post: Posts;
  showimg: boolean;

  constructor(private newpostService: NewpostService , private router: Router, private route: ActivatedRoute) { }

  selectedFile: File;
  previewFile: any;

   public editorOptions: Object = { key: 'tE1B3C1B5B5D5oF4I4B3B9A3A5B4B2A3F3bmifqwvg1csD3jef==' };
  onFileChanged(event) {

    if (event.target) {
      var reader = new FileReader();
      this.selectedFile = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event1: any) => { // called once readAsDataURL is completed
       // console.log("Read As Data UR is Completed :"+ event)
        this.previewFile = event1.target.result;
      };
    }

  }

  ngOnInit() {
    this.post = new Posts;
  }
  submitPost() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
   // console.log(uploadData)
    this.newpostService.postCoverImageUpload(uploadData).subscribe( data => {

     this.post.coverImage = data.fileName;
     this.newpostService.submitPost(this.post)
      .subscribe( data1 => {
        this.router.navigate(['/m/viewposts']);
      },
      error => {
        this.router.navigate(['']);
        });
      });
  }

}
