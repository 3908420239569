import { Component, OnInit } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CommonService } from 'src/app/utils/common.service';
import { Router } from '@angular/router';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.css']
})
export class EventHeaderComponent implements OnInit {

  isGuest: boolean;

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  sessionCandidate: SessionCandidate;

  constructor(private searchBarService: SearchbarService , private router: Router , private commonService: CommonService) { }

  ngOnInit() {

  this.sessionCandidate = this.commonService.getSessionCandidate();

  //   this.searchBarService.sessionCandidate()
  //     .subscribe( data => {
  //         this.candidate = data;
  //         if(this.candidate == null)
  //             this.isGuest = true;
  //         else
  //             this.isGuest = false;
  //     },
  //     error => {
  //       this.router.navigate(['']);
  //       });
   }

logout() {
    this.searchBarService.logout()
      .subscribe( data => {
        this.commonService.removeRSCookieValues();
         // this.sessioncandidate = data;
        this.router.navigate(['']);
        },
      error => {
        this.router.navigate(['']);
        });

  }

}
