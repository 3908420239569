import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class SavesheetService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'uploadsheet/uploadData.do';

  public postFile(uploadData: FormData) {
    return this.http.post<File>(this.appUrl, uploadData);
  }

}
