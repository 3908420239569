import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerLease } from 'src/app/model/containerlease.model';
import { ContainerleasehistoryService } from './containerleasehistory.service';

@Component({
  selector: 'app-containerleasehistory',
  templateUrl: './containerleasehistory.component.html',
  styleUrls: ['./containerleasehistory.component.css']
})
export class ContainerleasehistoryComponent implements OnInit {

  leaseList: ContainerLease[];
  p = 1;

  constructor(private containerleasehistoryService: ContainerleasehistoryService, private router: Router) { }

  ngOnInit() {
    this.containerleasehistoryService.findAllContainerLeaseListByCompany()
      .subscribe(data => {
        this.leaseList = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
