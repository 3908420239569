import { Component, OnInit } from '@angular/core';

import { Jobs } from '../../model/jobs.model';
import { JobsService } from '../jobs.service';
import { SearchjobsService } from './searchjobs.service';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';


@Component({
  selector: 'app-searchjobs',
  templateUrl: './searchjobs.component.html',
  styleUrls: ['./searchjobs.component.css']
})
export class SearchjobsComponent implements OnInit {
  jobList: Jobs[];
  clickMessage: string;
  searchText: string;

  constructor(private searchjobsService: SearchjobsService , private router: Router) { }

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  ngOnInit() {
    this.searchjobsService.getAllJobs()
      .subscribe( data => {
        this.jobList = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }
  jobviews(jobid) {
    this.searchjobsService.jobviews(jobid)
      .subscribe( data => {
        this.router.navigate(['/m/jobdetails' , jobid]);
      },
      error => {
        this.router.navigate(['']);
        });
  }


  wishlist(jobId) {
    this.searchjobsService.wishlist(jobId)
      .subscribe( data => {
        this.router.navigate(['/m/myjobs']);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  unwishlist(jobId) {
    this.searchjobsService.unwishlist(jobId)
      .subscribe( data => {
        this.router.navigate(['/m/myjobs']);
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
