import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscriptiondetails',
  templateUrl: './subscriptiondetails.component.html',
  styleUrls: ['./subscriptiondetails.component.css']
})
export class SubscriptiondetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
