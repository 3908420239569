import { CacheObject } from '../../model/cacheobject.model';
import { Company } from '../../model/company.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { FileUploadResponse } from 'src/app/model/fileuploadResponse.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Injectable({
  providedIn: 'root'
})
export class NewcompanyService {

  constructor(private http: HttpClient) { }
  private saveUrl = ShipStreetProps.API_PATH + 'newcompany.do';
  private industryUrl = ShipStreetProps.API_PATH + 'getcompanyindustry.do';
  private companyImageUrl = ShipStreetProps.API_PATH + 'companyImageUpload.do';
  private appUrl = ShipStreetProps.API_PATH + 'viewportNames.do';

  public companyIndustries() {
    return this.http.get<CacheObject>(this.industryUrl);
  }
  public saveType(company: Company) {
    return this.http.post<string>(this.saveUrl, company);
  }

  public companyImageUpload(selectedFile: FormData) {
    return this.http.post<FileUploadResponse>(this.companyImageUrl , selectedFile);
  }
  // public viewportNames() {
  //   return this.http.get<MasterPortsDepot[]>(this.appUrl);
  // }
}
