import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { ViewsaledcontainerService } from './viewsaledcontainer.service';

@Component({
  selector: 'app-viewsaledcontainer',
  templateUrl: './viewsaledcontainer.component.html',
  styleUrls: ['./viewsaledcontainer.component.css']
})
export class ViewsaledcontainerComponent implements OnInit {
  panelOpenState: boolean;
  p = 1;
  itemsPerPage: 1;
saledUnits: ContainerPurchasedUnits[];
saledUnitstList: ContainerPurchasedUnits;
  constructor(private viewsaledcontainerService: ViewsaledcontainerService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

this.saledUnitstList = new ContainerPurchasedUnits();
this.saledUnitstList.containerType = new ContainerType();
this.viewsaledcontainerService.getAllSaledContainers()
      .subscribe(data => {
        this.saledUnits = data;
        // alert(JSON.stringify(this.purchasedUnits));
      },
        error => {
          this.router.navigate(['']);
        });
  }

  }


