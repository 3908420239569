import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';

@Injectable({
  providedIn: 'root'
})
export class PrinciplerequestdetailsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/getMyPrincipleRequests.do';
  private appUrlconfirm = ShipStreetProps.API_PATH + 'trackboxagent/confirmRequest.do';

  getMyPrincipleRequests(id: number) {
    return this.http.post<TrackBoxAgent>(this.appUrl, id);
  }

  public confirmRequest(agentId: number) {
    return this.http.post<TrackBoxAgent>(this.appUrlconfirm, agentId);
  }

}
