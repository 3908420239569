import { Component, OnInit } from '@angular/core';
import { VendorsService } from '../../admin/vendors/vendors.service';
import { Router } from '@angular/router';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { PurchasebysupplierreportService } from './purchasebysupplierreport.service';

@Component({
  selector: 'app-purchasebysupplierreport',
  templateUrl: './purchasebysupplierreport.component.html',
  styleUrls: ['./purchasebysupplierreport.component.css']
})
export class PurchasebysupplierreportComponent implements OnInit {
  vendors: ContainerSupplier[];
  myControlsupplier = new FormControl('', [Validators.required]);
  filteredOptionssupplier: Observable<ContainerSupplier[]>;
  supplierList: ContainerSupplier[];
  purchase: ContainerPurchase;
  purchaseList: ContainerPurchase[];
  p = 1;

  constructor(private vendorsService: VendorsService, private purchasebysupplierreportService: PurchasebysupplierreportService,
    private router: Router) { }

  ngOnInit() {
    this.purchase = new ContainerPurchase();
    this.vendorsService.findAllContainerSupplierbyCompany()
      .subscribe(data => {
        this.supplierList = data;
        this.filteredOptionssupplier = this.myControlsupplier.valueChanges.pipe(
          map(val => this._filtersupp(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
  }

  public _filtersupp(value: string): ContainerSupplier[] {
    const filterValuecust = value;
    return this.supplierList.filter(supplierList => supplierList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      supplierList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      supplierList.name.toUpperCase().indexOf(filterValuecust) === 0);
  }

  public displayCustomer(cntr: ContainerSupplier): string {
    if (cntr && cntr.name) {
      return cntr.name;
    }
  }

  getPurchaseBySupplier() {
    this.purchasebysupplierreportService.getPurchaseBySupplier(this.purchase)
      .subscribe(data => {
        this.purchaseList = data;
        // this.filteredOptionssupplier = this.myControlsupplier.valueChanges.pipe(
        //   map(val => this._filtersupp(val))
        // );
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
