import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-layout',
  templateUrl: './help-layout.component.html',
  styleUrls: ['./help-layout.component.css']
})
export class HelpLayoutComponent implements OnInit {

  searchTxt: string;

  constructor() { }

  ngOnInit() {
  }

  onKeydown(e) {}
  globalSearch(search) {}
}
