import { Component, OnInit } from '@angular/core';
import { TaxesService } from './taxes.service';
import { Router } from '@angular/router';
import { Taxes } from 'src/app/model/taxes.model';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.css']
})
export class TaxesComponent implements OnInit {
  taxes: Taxes[];
  p = 1;

  constructor(private taxesService: TaxesService , private router: Router) { }

  ngOnInit() {
    this.taxesService.findAllTaxes()
      .subscribe( data => {
        this.taxes = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
