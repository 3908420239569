import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PassWordResetDTO } from 'src/app/model/passwordresetdto.model';
import { Person } from 'src/app/model/person.model';
import { PasswordresettingService } from './passwordresetting.service';

@Component({
  selector: 'app-passwordresetting',
  templateUrl: './passwordresetting.component.html',
  styleUrls: ['./passwordresetting.component.css']
})
export class PasswordresettingComponent implements OnInit {

  reply: string;
  errorDetails: any;
  passcode: string;
  reqPassCode = true;
  person: Person;
  passWordReset: PassWordResetDTO;
  constructor(private passwordresettingService: PasswordresettingService , private router: Router , 
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.person = new Person();
    this.passWordReset = new PassWordResetDTO();
  }

  validatePasscode() {
    this.passWordReset.candidate.id = Number(this.route.snapshot.paramMap.get('id'));
     this.passwordresettingService.validatePasscode(this.passWordReset)
      .subscribe( data => {
        
       if(data.status == "Active"){
        this.router.navigate(['/passwordresetting' , data.id]);
     }else{
     //  this.errorDetails = data.errMsg;
       //alert("Error Message :"+errorDetails);
     }
      },
      error => {
        this.router.navigate(['']);
        });
  }

  resetPasscode() {
      this.passwordresettingService.resetPasscode(this.passcode)
      .subscribe( data => {
      //  this.reply = data;
       // if(this.reply.status == true){
       this.reqPassCode = true;
       this.router.navigate(['']);

//      }else{
//        this.errorDetails = data.errMsg;
//        alert("Error Message :"+errorDetails);
 //     }
      },
      error => {
        this.router.navigate(['']);
        });
  }

  onKeydownLogin(e , person) {}

  checkAndSubmitLogin() {}

  requestResetPasscode() {}

}
