import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentPrincipleFreight } from 'src/app/model/agentprinciplefreight.model';
// import { AgentPrincipleFreight } from 'src/app/model/agentprinciplefreight.model';
import { TbagentviewrequestsService } from './tbagentviewrequests.service';
import { MatBottomSheet } from '@angular/material';
import { TbagentrequesttoprincipleComponent } from '../tbagentrequesttoprinciple/tbagentrequesttoprinciple.component';
import { TbagentaddcontainerstobookingService } from '../tbagentaddcontainerstobooking/tbagentaddcontainerstobooking.service';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { TbagentresponsetoprincipleComponent } from '../tbagentresponsetoprinciple/tbagentresponsetoprinciple.component';

@Component({
  selector: 'app-tbagentviewrequests',
  templateUrl: './tbagentviewrequests.component.html',
  styleUrls: ['./tbagentviewrequests.component.css']
})
export class TbagentviewrequestsComponent implements OnInit {
  tbrequestView: AgentPrincipleFreight[];
  p: number;
  bookingId: number;
  principleCompanies: TrackBoxAgent[];

  constructor(private tbagentviewrequestsService: TbagentviewrequestsService, private router: Router, private route: ActivatedRoute,
    private _bottomSheet: MatBottomSheet, private _bottomSheet1: MatBottomSheet,
    private tbagentaddcontainerstobookingService: TbagentaddcontainerstobookingService) { }

  ngOnInit() {
    this.bookingId = this.route.snapshot.params.id;
    // this.tbagentviewrequestsService.getAllMyRequests(this.route.snapshot.params.id)
    //   .subscribe(data => {
    //     this.tbrequestView = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    this.tbagentaddcontainerstobookingService.getPrincipleCompanies()
      .subscribe(data => {
        this.principleCompanies = data;
        // alert(JSON.stringify(this.principleCompanies));
      },
        error => {
          this.router.navigate(['']);
        });
  }

  requestToPrinciple(principleName, principleId, bookingId ): void {
    this._bottomSheet.open(TbagentrequesttoprincipleComponent, {
      data: { principleName, principleId, bookingId },
    });

  }

  responseToPrinciple(principleName, principleId, bookingId , freightId): void {
    this._bottomSheet1.open(TbagentresponsetoprincipleComponent, {
      data: { principleName, principleId, bookingId , freightId},
    });
  }

}


