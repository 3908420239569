import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile21',
  templateUrl: './profile21.component.html',
  styleUrls: ['./profile21.component.css']
})
export class Profile21Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
