import { Candidates } from '../../model/candidate.model';
import { CandidateJobs } from '../../model/candidatejobs.model';
import { JobWishlist } from '../../model/jobwishlist.model';
import { MyjobsService } from './myjobs.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CandidateMessage } from 'src/app/model/candidateMessage.model';
import { CandidateMessageMessage } from 'src/app/model/candidateMessageMessage.model';

@Component({
  selector: 'app-myjobs',
  templateUrl: './myjobs.component.html',
  styleUrls: ['./myjobs.component.css']
})
export class MyjobsComponent implements OnInit {
  wishlists: JobWishlist[];
  candidate: Candidates;
  appliedJobs: CandidateJobs[];
 // candidate: SessionCandidate;
  receivedMessage: CandidateMessage[];
  recMsgdiv: boolean;
  receivedMessageDetail: CandidateMessageMessage[];

  constructor(private myjobsService: MyjobsService , private router: Router, private commonService: CommonService) { }

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  ngOnInit() {
    this.recMsgdiv = true;
   // this.candidate = this.commonService.getSessionCandidate();
    this.myjobsService.sessionCandidate()
      .subscribe( data => {
        this.candidate = data;
      },
      error => {
        this.router.navigate(['']);
        });

    this.myjobsService.jobAppliedBySessionCandidate()
      .subscribe( data => {
        this.appliedJobs = data;
      },
      error => {
        this.router.navigate(['']);
        });

    this.myjobsService.getLoggedWishList()
      .subscribe( data => {
        this.wishlists = data;
      },
      error => {
        this.router.navigate(['']);
        });

    this.myjobsService.getCandidateJobReceivedMessages()
      .subscribe( data => {
        this.receivedMessage = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

    receivedMessageDetails(messageId) {
    this.myjobsService.receivedMessageDetails(messageId)
      .subscribe( data => {
        this.recMsgdiv = false;
        this.receivedMessageDetail = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  closeWindow() {
    this.recMsgdiv = true;
  }

}
