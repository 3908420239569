import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ViewsaledcontainerService {

constructor(private http: HttpClient) { }

private orderlistUrl = ShipStreetProps.API_PATH + 'containersales/findAllSaledContainerRecords';

public getAllSaledContainers() {
  return this.http.get<ContainerPurchasedUnits[]>(this.orderlistUrl);

}
}
