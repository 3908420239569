import { DeliveryorderdateupdateService } from './deliveryorderdateupdate.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DeliveryOrderUpdateDTO } from 'src/app/model/deliveryorderupdateDTO.model';
export interface DialogData {
  bookingId: number;
}
@Component({
  selector: 'app-deliveryorderdateupdate',
  templateUrl: './deliveryorderdateupdate.component.html',
  styleUrls: ['./deliveryorderdateupdate.component.css']
})
export class DeliveryorderdateupdateComponent implements OnInit {

  deliveryorderDate:DeliveryOrderUpdateDTO;
  constructor(private deliveryorderdateupdateService: DeliveryorderdateupdateService, private router: Router,
    private route: ActivatedRoute, private snackBar: MatSnackBar,public dialogoutRef: MatDialogRef<DeliveryorderdateupdateComponent>,
    @Inject(MAT_DIALOG_DATA)public data: DialogData) { }

  ngOnInit() {
    this.deliveryorderDate = new DeliveryOrderUpdateDTO();
    this.deliveryorderDate.bookingId = this.data.bookingId;
  }

  updateDeliveryOrderDate(){
    // alert(JSON.stringify(this.data.bookingId));
    this.deliveryorderDate.bookingId = this.data.bookingId;
   this.deliveryorderdateupdateService.updateDeliveryOrderDate(this.deliveryorderDate)
     .subscribe(data => {
       this.snackBar.open('DO DATE SUCCESSFULLY UPDATED!!', 'End now', {
         duration: 2000,

       });
       this.dialogoutRef.close(0);

     },

       error => {
         this.router.navigate(['']);
       });

 }
  onNoClick(): void {
    this.dialogoutRef.close();

  }
}
