import { Component, OnInit } from '@angular/core';
import { AddcalcvesseloperatorService } from './addcalcvesseloperator.service';
import { Router } from '@angular/router';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { Validators, FormControl, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-addcalcvesseloperator',
  templateUrl: './addcalcvesseloperator.component.html',
  styleUrls: ['./addcalcvesseloperator.component.css']
})
export class AddcalcvesseloperatorComponent implements OnInit {

  masterVesselOperator: MasterVesselOperator;
  CountryList: CountryInfo[];
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
  constructor(private addcalcvesseloperatorService: AddcalcvesseloperatorService, private router: Router) { }

  ngOnInit() {
    this.masterVesselOperator = new MasterVesselOperator();
    this.masterVesselOperator.country = new CountryInfo();
    // this.trackboxcarrier.country = new CountryInfo();



    this.addcalcvesseloperatorService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveCompanyVesselOperator() {
    this.addcalcvesseloperatorService.saveCalcVesselOperator(this.masterVesselOperator)
      .subscribe(data => {
        this.router.navigate(['/calculator/calcvesseloperator']);
        if (data == null) {
          alert('Already Exist');
        }

      },
        error => {
          this.router.navigate(['']);
        });
  }
  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }

  }
}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}