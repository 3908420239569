import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { BookingBLNumber } from './bookingblnumber.model';
import { TrackBoxBooking } from './trackboxbooking.model';

export class TrackBoxBookingContainers {
    id: number;
    bookingId: TrackBoxBooking;
    container: TrackBoxContainer;
    blNumber: BookingBLNumber;
    selectForBooking: boolean;
    availableDate: Date;
    returnedDate: Date;
    statusUpdatedOn: Date;
    blNumberUpdatedOn: Date;
}
