import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-depotxpressinfo',
  templateUrl: './depotxpressinfo.component.html',
  styleUrls: ['./depotxpressinfo.component.css']
})
export class DepotxpressinfoComponent implements OnInit {
  customOptions: OwlOptions;
  customOption: OwlOptions;

  constructor() { }

  ngOnInit() {
      this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 900,
      autoplay: true,
      autoplayHoverPause: false,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 1
        },
        740: {
          items: 1
        },
        940: {
          items: 1
        },
        1024: {
          items: 1
        }
      },
      nav: false,
    };

    this.customOption = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      autoplay: true,
      autoplayHoverPause: false,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 3
        },
        740: {
          items: 3
        },
        940: {
          items: 3
        },
        1024: {
          items: 3
        }
      },
      nav: true
    };
  }

}
