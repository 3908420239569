import { Router, ActivatedRoute } from '@angular/router';
import { NewdepotService } from './newdepot.service';
import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-newdepot',
  templateUrl: './newdepot.component.html',
  styleUrls: ['./newdepot.component.css']
})
export class NewdepotComponent implements OnInit {
  masterDepots: MasterPortsDepot;
  CountryList: CountryInfo[];
  hide = true;
  services = new FormControl();
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;


  serviceList: string[] = ['Container Storage', 'Container repair and maintenance', 'Container trading', 'Container Modification'
  , 'Transportation Services', 'Container Inspection', 'Reefer PTI', 'Clip on Genset Rental'];
  constructor(private newdepotService: NewdepotService,  private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.masterDepots = new MasterPortsDepot();
    this.masterDepots.country = new CountryInfo();

    this.newdepotService.viewCountry()
        .subscribe(data => {
          this.CountryList = data;
          this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
            // startWith(''),
            map(val => this._filter4(val))
          );
      },
          error => {
            this.router.navigate(['']);
          });
  }
  onSubmit() {
    this.newdepotService.save(this.masterDepots)
    .subscribe(data => {
    this.router.navigate(['/trackmybox/depots']);
     },
       error => {
         this.router.navigate(['']);
       });
      }
    // alert(this.masterDepots);
    public _filter4(value: string): CountryInfo[] {
      const filterValue4 = value;
      return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
        country.name.toLowerCase().indexOf(filterValue4) === 0 ||
        country.name.toUpperCase().indexOf(filterValue4) === 0);
    }

    public displayCountry(cntr: CountryInfo): string {
      if (cntr && cntr.name) {
        return cntr.name + '-' + cntr.code2;

      }

  }



}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}
