import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerType } from 'src/app/model/containertype.model';
import { ContainertypesService } from './containertypes.service';

@Component({
  selector: 'app-containertypes',
  templateUrl: './containertypes.component.html',
  styleUrls: ['./containertypes.component.css']
})
export class ContainertypesComponent implements OnInit {

  containerTypeList: ContainerType[];
  p = 1;
  panelOpenState: boolean;
  constructor(private containertypesService: ContainertypesService, private router: Router) { }

  ngOnInit() {

    this.containertypesService.getAllMyContainerTypes()
      .subscribe(data => {
        this.containerTypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
