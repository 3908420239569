import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Taxes } from 'src/app/model/taxes.model';

@Injectable({
  providedIn: 'root'
})
export class TaxesService {

  private appUrl = ShipStreetProps.API_PATH + 'taxes/findAllTaxes';

  constructor(private http: HttpClient) { }

  public findAllTaxes() {
    return this.http.get<Taxes[]>(this.appUrl);
  }


}
