import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgentTypeListDTO } from 'src/app/model/agenttypelistDTO.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentequipmentlistService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/getPrincipleEquipmentcontrolTypeList.do';

public getEquipmentcontrolTypeList(containertypelist: AgentTypeListDTO) {
    // alert(JSON.stringify(containertypelist));

    return this.http.post<TrackBoxContainer[]>(this.appUrl, containertypelist);
    // return this.http.post<TrackBoxContainer[]>(this.appUrl, principleId);
  }

}
