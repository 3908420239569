import { Component, OnInit } from '@angular/core';
import { AdmincandidatesService } from './admincandidates.service';
import { Candidates } from 'src/app/model/candidate.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admincandidates',
  templateUrl: './admincandidates.component.html',
  styleUrls: ['./admincandidates.component.css']
})
export class AdmincandidatesComponent implements OnInit {
  candidates: Candidates[];

  constructor(private admincandidatesService: AdmincandidatesService , private router: Router) { }

  ngOnInit() {
    this.admincandidatesService.getAllCandidatesForAdmin()
      .subscribe( data => {
        this.candidates = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
