import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offlineheader',
  templateUrl: './offlineheader.component.html',
  styleUrls: ['./offlineheader.component.css']
})
export class OfflineheaderComponent implements OnInit {
  searchTxt; string

  constructor() { }

  ngOnInit() {
  }
  
  logout(){
    
  }

}
