import { ContainerPurchase } from './containerpurchase.model';
import { ContainerLocationType } from './containerlocationtype.model';
import { ContainerPurchaseLocation } from './containerpurchaselocation.model';
import { ContainerType } from './containertype.model';
import { Containers } from './containers.model';


export class ContainerPurchasedUnits {
    id: number;
    purchase: ContainerPurchase;
    container: Containers;
    locationType: ContainerLocationType;
    containerNumber: string;
    location: ContainerPurchaseLocation;
    salesStatus: boolean;
    containerType: ContainerType;
    containerStatus: string;
    selectForBooking: boolean;
    salesDate: Date;
}
