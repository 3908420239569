import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUploadResponse } from 'src/app/model/fileuploadResponse.model';
import { CacheObject } from 'src/app/model/cacheobject.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Company } from 'src/app/model/company.model';

@Injectable({
  providedIn: 'root'
})
export class NewcompanyService {

  constructor(private http: HttpClient) { }
  private saveUrl = ShipStreetProps.API_PATH + 'newcompany.do';
  private industryUrl = ShipStreetProps.API_PATH + 'getcompanyindustry.do';
  private companyImageUrl = ShipStreetProps.API_PATH + 'companyImageUpload.do';

  public companyIndustries() {
    return this.http.get<CacheObject>(this.industryUrl);
  }
  public saveType(company: Company) {
    return this.http.post<Company>(this.saveUrl, company);
  }

  public companyImageUpload(selectedFile: FormData) {
    return this.http.post<FileUploadResponse>(this.companyImageUrl , selectedFile);
  }
}
