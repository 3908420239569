import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerPresales } from 'src/app/model/containerpresales.model';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { PresalesdetailsService } from './presalesdetails.service';

@Component({
  selector: 'app-presalesdetails',
  templateUrl: './presalesdetails.component.html',
  styleUrls: ['./presalesdetails.component.css']
})
export class PresalesdetailsComponent implements OnInit {

  isOpen: boolean;
  sales: ContainerPresales;
  constructor(private presalesdetailsService: PresalesdetailsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.sales = new ContainerPresales();
    this.presalesdetailsService.findSingleContainerPreSales(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.sales = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  containermarkassales() {
    this.presalesdetailsService.containermarkassales(this.sales)
      .subscribe(data => {
        this.router.navigate(['/containers/presales']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updatePreSalesPricenQty() {
    this.presalesdetailsService.updatePreSalesPricenQty(this.sales)
      .subscribe(data => {
        this.router.navigate(['/containers/presales']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
