import { Component, OnInit } from '@angular/core';
import { ContainerPurchase } from '../model/containerpurchase.model';
import { ShipStreetProps } from '../utils/rs.constants';
import { Router } from '@angular/router';
import { ContainersService } from './containers.service';
import { CommonService } from '../utils/common.service';
import { SessionCandidate } from '../model/sessioncandidate.model';
import { ContainerDashboardCountDto } from '../model/containerdashboardcountDTO.model';
import { TrackmyboxService } from '../trackmybox/trackmybox.service';
import { BoxCountByContainerTypeDTO } from '../model/boxcountbycontainertypeDto';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-containers',
  templateUrl: './containers.component.html',
  styleUrls: ['./containers.component.css']
})
export class ContainersComponent implements OnInit {

  purchaseList: ContainerPurchase[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  sessionCandidate: SessionCandidate;
  showme: boolean;
  dashboardCount: ContainerDashboardCountDto;
  boxcountbycontainertype: BoxCountByContainerTypeDTO[];
  customOptions: OwlOptions;

  constructor(private containersService: ContainersService, private router: Router, private commonService: CommonService,
    private trackmyboxService: TrackmyboxService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();

    this.containersService.containerPurchaseList()
      .subscribe(data => {
        this.purchaseList = data;
      },
        error => {
          this.router.navigate(['']);
        });


    this.containersService.getAllDashboardCounts()
      .subscribe(data => {
        this.dashboardCount = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.containersService.getBoxCountByContainerType('ALL')
      .subscribe(data => {
        this.boxcountbycontainertype = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  getContinerCountByStatus(typ) { }


}
