import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-licensepurchased',
  templateUrl: './licensepurchased.component.html',
  styleUrls: ['./licensepurchased.component.css']
})
export class LicensepurchasedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
