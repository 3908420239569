import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CostcalculatorsettinglistService } from './costcalculatorsettinglist.service';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';

@Component({
  selector: 'app-costcalculatorsettinglist',
  templateUrl: './costcalculatorsettinglist.component.html',
  styleUrls: ['./costcalculatorsettinglist.component.css']
})
export class CostcalculatorsettinglistComponent implements OnInit {
  portcosts: MasterPortCostDetails[];
  searchText: string;
  p = 1;
  portcostsALL: MasterPortCostDetails[];

  constructor(private costcalculatorsettinglistService: CostcalculatorsettinglistService, private router: Router) { }

  ngOnInit() {

    this.costcalculatorsettinglistService.getAllCostCalculatorDetails()
      .subscribe(data => {
        this.portcostsALL = data;
        this.portcosts = this.portcostsALL;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  searchbar() {
    if (this.searchText !== '') {
      this.portcosts = this.portcostsALL.filter(res => {
        return (res.port !== null && res.port.name.toLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.portcosts = this.portcostsALL;
    }
  }

}
