import { FirstfeednotificationService } from './firstfeednotification.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-firstfeednotification',
  templateUrl: './firstfeednotification.component.html',
  styleUrls: ['./firstfeednotification.component.css']
})
export class FirstfeednotificationComponent implements OnInit {

  constructor(private firstfeednotificationService: FirstfeednotificationService , private router: Router,
              private cookieService: CookieService) { }

    @Output() firstFeedOutVar = new EventEmitter<string>();
  ngOnInit() {
  }

  firstFeed() {
     this.firstfeednotificationService.firstFeed()
       .subscribe( data => {// alert("done");
         this.cookieService.set('FIRST_FEED' , 'false');

         this.firstFeedOutVar.emit('false');
         this.router.navigate(['editprofile']);
       },
       error => {
         this.router.navigate(['']);
         });
     }
}
