import { Component, OnInit } from '@angular/core';
import { NewagentemployeeService } from './newagentemployee.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Candidates } from 'src/app/model/candidate.model';

@Component({
  selector: 'app-newagentemployee',
  templateUrl: './newagentemployee.component.html',
  styleUrls: ['./newagentemployee.component.css']
})
export class NewagentemployeeComponent implements OnInit {
  trackboxemployee: Candidates;

  constructor(private newagentemployeeService: NewagentemployeeService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
     this.trackboxemployee = new Candidates();
  }

  onSubmit() {
    this.newagentemployeeService.save(this.trackboxemployee)
      .subscribe(data => {
        this.router.navigate(['/tbagentmode/boxagentemployees']);
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
