import { CandidateConnect } from '../../model/candidateConnect.model';
import { ConnectionList } from '../../model/connectionList.model';
import { ConnectionsService } from './connections.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.css']
})
export class ConnectionsComponent implements OnInit {
  friendsList: CandidateConnect[];
  candidates: ConnectionList[];
  searchText: string;
  friendsListAll: CandidateConnect[];

  constructor(private connectionsService: ConnectionsService , private router: Router) { }

   imagePath = ShipStreetProps.AWS_IMAGE_PATH;

   customOptions: OwlOptions = {
    autoWidth: true,
    autoplay: false,
    autoplayTimeout: 6000,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 100,
    navText: [ '<i class="material-icons">chevron_left</i>', '<i class="material-icons">chevron_right</i>' ],
    responsive: {
      0: {
        items: 3
      },
      320: {
        items: 4
      },
      370: {
        items: 4
      },
      400: {
        items: 5
      },
      600: {
        items: 6
      },

    },
    nav: true
  };

  ngOnInit() {
    this.connectionsService.connectionCandidate()
      .subscribe( data => {
        this.candidates = data;
      },
      error => {
        this.router.navigate(['']);
        });

    this.connectionsService.myFriendsList()
      .subscribe( data => {
        this.friendsListAll = data;
        this.friendsList = this.friendsListAll;
      },
      error => {
        this.router.navigate(['']);
        });
  }


   search() {
    if (this.searchText !== '') {
      this.friendsList = this.friendsListAll.filter(res => {
        return (res.connectedBy !== null &&
          res.connectedBy.firstName.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.connectedBy !== null &&
            res.connectedBy.lastName.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.friendsList = this.friendsListAll;
    }
  }

}
