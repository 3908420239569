import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/utils/common.service';
import { NewvendorService } from './newvendor.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';
import { Company } from 'src/app/model/company.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-newvendor',
  templateUrl: './newvendor.component.html',
  styleUrls: ['./newvendor.component.css']
})
export class NewvendorComponent implements OnInit {

  vendor: ContainerSupplier;
  sessionCandidate: SessionCandidate;
  CountryList: CountryInfo[];
  myControl3 = new FormControl();
  filteredOptionscountry: Observable<CountryInfo[]>;

  constructor(private newvendorService: NewvendorService, private router: Router, private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.vendor = new ContainerSupplier();
    this.vendor.company = new Company();
    this.vendor.country = new CountryInfo();

    this.newvendorService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          startWith(''),
          map(val => this._filter4(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveContainerSupplier() {
    this.vendor.company.id = this.sessionCandidate.tradingCompany;
    this.newvendorService.saveContainerSupplier(this.vendor)
      .subscribe(data => {
        this.router.navigate(['containers/suppliers']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }
  }

}
