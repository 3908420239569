import { Component, OnInit } from '@angular/core';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';
import { Router } from '@angular/router';
import { VendorsService } from './vendors.service';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements OnInit {

  vendors: ContainerSupplier[];
  searchText: string;
  p = 1;
  vendorsAll: ContainerSupplier[];

  constructor(private vendorsService: VendorsService , private router: Router) { }

  ngOnInit() {
    this.vendorsService.findAllContainerSupplierbyCompany()
      .subscribe( data => {
        this.vendorsAll = data;
        this.vendors = this.vendorsAll;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  searchsupplier() {
    if (this.searchText !== '') {
      this.vendors = this.vendorsAll.filter(res => {
        return res.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
          res.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    } else if (this.searchText === '') {
      this.vendors = this.vendorsAll;
    }
  }
}
