import { Component, OnInit } from '@angular/core';
import { ContainerheaderService } from './containerheader.service';
import { Router } from '@angular/router';
import { Candidates } from 'src/app/model/candidate.model';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CookieService } from 'ngx-cookie-service';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';

@Component({
  selector: 'app-containerheader',
  templateUrl: './containerheader.component.html',
  styleUrls: ['./containerheader.component.css']
})
export class ContainerheaderComponent implements OnInit {
  
  isGuest: boolean;
  candidate: Candidates;
  sessionCandidate: SessionCandidate;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  searchText: string;

  constructor(private containerheaderService: ContainerheaderService , private router: Router, private commonService: CommonService,
              private cookieService: CookieService, private searchBarService: SearchbarService) { }

  ngOnInit() {
    this.isGuest = true;
    this.sessionCandidate = this.commonService.getSessionCandidate();

    this.containerheaderService.sessionCandidate()
      .subscribe( data => {
        this.candidate = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  onKeydown(event) {
      if (event.key === 'Enter') {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/containers/tradecontainersearch', this.searchText]);
      });
      }
    }

  viewpurchasecontainers() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/containers/viewpurchasecontainers', 'ALL']);
  });
  }

  switchprofile() {
    this.cookieService.delete('TRD_CMP_ID');
    this.router.navigate(['/m/containertradingprofile']);
  }

  logout() {
     this.searchBarService.logout()
      .subscribe( data => {
        this.commonService.removeRSCookieValues();
         // this.sessioncandidate = data;
        this.router.navigate(['']);
        },
      error => {
        this.router.navigate(['']);
        });
  }

  helpWindow() {}

}
