import { TrackBoxContainer } from './../../../../model/trackboxcontainer.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackboxContainerStatus } from 'src/app/trackmybox/trackboxcontainerstatus.model';

@Injectable({
  providedIn: 'root'
})
export class UpdateequipmentService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'trackboxcontainerStatus/tbviewStatus.do';
private appUrlsbooking = ShipStreetProps.API_PATH + 'cntequipmentcontainers/containersDetails.do';
private appUrlupdateStat = ShipStreetProps.API_PATH + 'cntequipmentcontainers/updateTrackboxcontainer.do';

public getTbeqpContainersByID( ContainerId: string) {
  return this.http.post<TrackBoxContainer>(this.appUrlsbooking, ContainerId);
  }
  public getContainerStatus( ) {
    return this.http.get<TrackboxContainerStatus[]>(this.appUrl);
    }

    public updateContainerStatus(contStat: TrackBoxContainer) {
      // alert(JSON.stringify(contStat));
       return this.http.post<string>(this.appUrlupdateStat , contStat);
     }

}
