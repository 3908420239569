import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruiter74',
  templateUrl: './recruiter74.component.html',
  styleUrls: ['./recruiter74.component.css']
})
export class Recruiter74Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
