import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerLease } from 'src/app/model/containerlease.model';
import { ContainerLeasingUnit } from 'src/app/model/containerleasingunit.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerleasedetailsService {

  private listUrl = ShipStreetProps.API_PATH + 'containerlease/findSingleContainerLeaseRecord';
  private contUrl = ShipStreetProps.API_PATH + 'containerleaseunit/findallcontainerswithcalc';

  constructor(private http: HttpClient) { }

  public findSingleContainerLeaseRecord(leaseId: string) {
    return this.http.post<ContainerLease>(this.listUrl , leaseId);
  }

  public findallcontainers(leaseId: string) {
    return this.http.post<ContainerLeasingUnit[]>(this.contUrl , leaseId);
  }
}
