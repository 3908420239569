import { CacheObject } from '../../model/cacheobject.model';
import { Candidates } from '../../model/candidate.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditprofileService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'sessionCandidate.do';
  private canUrl = ShipStreetProps.API_PATH + 'candidate.do';
  private updateCanNameUrl = ShipStreetProps.API_PATH + 'updateCandidateName.do';
  private updateCanDesgUrl = ShipStreetProps.API_PATH + 'updateCandidateDesignation.do';
  private updateCompanyUrl = ShipStreetProps.API_PATH + 'updateCandidateProfile.do';
  private desgURL = ShipStreetProps.API_PATH + 'fetchDesignationCache.do';
  private updateProfileImageUrl = ShipStreetProps.API_PATH + 'updateProfileImage.do';
  private updateResumeUrl = ShipStreetProps.API_PATH + 'uploadResume.do';

  public candidate() {
    return this.http.get<Candidates>(this.appUrl);
  }
   public getDesignation() {
    return this.http.get<CacheObject[]>(this.desgURL);
  }
  public updateCandidateName(candidate: Candidates) {
    return this.http.post<Candidates>(this.updateCanNameUrl , candidate);
  }
public updateCandidateDesignation(candidate: Candidates) {
    return this.http.post<Candidates>(this.updateCanDesgUrl , candidate);
  }
  public updateCandidateProfile(candidate: Candidates) {
    return this.http.post<Candidates>(this.updateCompanyUrl , candidate);
  }

   public updateProfileImage(selectedFile: FormData) {
    return this.http.post<string>(this.updateProfileImageUrl , selectedFile);
  }

  public updateResume(uploadFile: FormData) {
    return this.http.post<string>(this.updateResumeUrl , uploadFile);
  }

}
