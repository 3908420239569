import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Candidates } from 'src/app/model/candidate.model';
import { ConnectionList } from 'src/app/model/connectionList.model';

@Injectable({
  providedIn: 'root'
})
export class PeopleknowService {

  constructor(private http: HttpClient) { }

   private appUrl = ShipStreetProps.API_PATH + 'connectionCandidate.do';

    public getPeopleYouMayKnow() {
    return this.http.get<ConnectionList[]>(this.appUrl);
  }
}
