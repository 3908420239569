import { Component, OnInit } from '@angular/core';
import { MatDialog, MatBottomSheet } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingIncome } from 'src/app/model/bookingIncome.model';
import { AgentPrincipleFreight } from 'src/app/model/agentprinciplefreight.model';
import { RequestfromagentService } from './requestfromagent.service';
import { ReponsetoagentComponent } from '../reponsetoagent/reponsetoagent.component';

@Component({
  selector: 'app-requestfromagent',
  templateUrl: './requestfromagent.component.html',
  styleUrls: ['./requestfromagent.component.css']
})
export class RequestfromagentComponent implements OnInit {

  agentreq: AgentPrincipleFreight;
  bookingincome: BookingIncome;
  currentDate: any;
  tbrequestView: AgentPrincipleFreight[];
  confirmReq: AgentPrincipleFreight;
  p: number;

  constructor(private requestfromagentService: RequestfromagentService, private router: Router, private route: ActivatedRoute,
              private _bottomSheet: MatBottomSheet,) { }

  ngOnInit() {

    this.requestfromagentService.getAgentPriceRequests(this.route.snapshot.params.id)
      .subscribe(data => {
        this.tbrequestView = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }



  confirmApprove(freightId) {
    this.requestfromagentService.confirmApprove(freightId)
      .subscribe(data => {
        this.router.navigate(['/trackmybox/viewrequests']);
      },

        error => {
          this.router.navigate(['']);
        });

  }


  responseToAgent(agentName , freightId) {
    this._bottomSheet.open(ReponsetoagentComponent, {
      data: {agentName , freightId},
    });
  }
}
