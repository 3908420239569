import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EquipmentcontrollistService {

constructor(private http: HttpClient) { }


private appUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/getContainersWithStatus.do';

public getTransitContainers(tbstatcontainers: string) {
  // alert(JSON.stringify(transit));
    return this.http.post<TrackBoxContainer[]>(this.appUrl, tbstatcontainers);
  }
}
