import { CandidateEducation } from '../../../model/candidateEducation.model';
import { CandidateeducationService } from './candidateeducation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-candidateeducation',
  templateUrl: './candidateeducation.component.html',
  styleUrls: ['./candidateeducation.component.css']
})
export class CandidateeducationComponent implements OnInit {
  candidateId: string;
  candidateEducations: CandidateEducation[];
  breakpoint: number;

  constructor(private candidateeducationService: CandidateeducationService , private route: ActivatedRoute , private router: Router) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get("id");
    this.candidateId = id;
    this.candidateeducationService.candidateEducation(this.candidateId)
      .subscribe( data => {
        this.candidateEducations = data;
      },
      error => {
        this.router.navigate(['']);
        });

        this.breakpointFun(event)
  }

  onResize(event) {
    this.breakpointFun(event)
  }

  breakpointFun(event?: any): void{
    if(event){
      this.breakpoint = (event.target.innerWidth <= 681) ? 1 : (event.target.innerWidth <= 1000) ? 2 : (event.target.innerWidth <= 1300) ? 2 : 2;
    } 
    else {
      this.breakpoint = (window.innerWidth <= 681) ? 1 : (window.innerWidth <= 1000) ? 2 : (window.innerWidth <= 1300) ? 2 : 2;
    }
  }

   sendMessage() {
    this.candidateeducationService.sendNoEducationAlert(this.route.snapshot.paramMap.get("id"))
      .subscribe( data => {
        this.candidateEducations = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
