import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addtrackeremployeeproperty',
  templateUrl: './addtrackeremployeeproperty.component.html',
  styleUrls: ['./addtrackeremployeeproperty.component.css']
})
export class AddtrackeremployeepropertyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
