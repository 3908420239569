import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';

@Injectable({
  providedIn: 'root'
})
export class BoxprinciplesService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/getAllTbAgentPrinciples.do';

  getAllMyAgents() {
    return this.http.get<TrackBoxAgent[]>(this.appUrl );
  }
}
