import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-masterproducts',
  templateUrl: './masterproducts.component.html',
  styleUrls: ['./masterproducts.component.css']
})
export class MasterproductsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
