import { Component, OnInit } from '@angular/core';
import { Pipeline } from 'src/app/model/pipeline.model';
import { MatSnackBar } from '@angular/material';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { NewmypipelineService } from '../newmypipeline/newmypipeline.service';

@Component({
  selector: 'app-qualifiedpipeline',
  templateUrl: './qualifiedpipeline.component.html',
  styleUrls: ['./qualifiedpipeline.component.css']
})
export class QualifiedpipelineComponent implements OnInit {

  showme: boolean;
  pipeline: Pipeline;
  pipelineList: Pipeline[];

  constructor(private newmypipelineService: NewmypipelineService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.pipeline = new Pipeline();
    this.pipeline.customer = new TrackBoxCustomers();

    this.newmypipelineService.getnewpipeline('QUALIFIED').subscribe(data => {
      this.pipelineList = data;
    });

  }

  addnewpipeline() {
    this.pipeline.pipelineStatus = 'QUALIFIED';
    this.newmypipelineService.addnewpipeline(this.pipeline).subscribe(data => {
      this.pipelineList.push(this.pipeline);
      this.showme = false;
      this.snackBar.open('Pipeline Added Successfully', '', {
        duration: 5000
      });
    });
  }

}
