import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, ThemeService } from 'ng2-charts';
import { ContainerSalesReportDTO } from 'src/app/model/containerSalesReportDTO.model';
import { YearlysalesreportsService } from './yearlysalesreports.service';

@Component({
  selector: 'app-yearlysalesreports',
  templateUrl: './yearlysalesreports.component.html',
  styleUrls: ['./yearlysalesreports.component.css']
})
export class YearlysalesreportsComponent implements OnInit {
  salesList: ContainerSalesReportDTO[];
  dataList = [];

  barChartOptions: ChartOptions = {
    responsive: true,
};

barChartLabels: Label[] = [];
barChartType: ChartType = 'bar';
barChartLegend = true;
barChartPlugins = [];

barChartData: ChartDataSets[] = [];
  currentYear: number;

  constructor(private yearlysalesreportsService: YearlysalesreportsService , private router: Router,
    private themeService: ThemeService) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.yearlysalesreportsService.getYearSalesReportByCustomer(this.currentYear)
    .subscribe(data => {
      this.salesList = data;
    //  this.equipmentDetails = data;
                this.salesList.forEach((item) => {
                    if (item != null && Number(item.totalSoldQty) > 0) {
                        this.barChartLabels.push(item.customer.name);
                        this.dataList.push(Number(item.totalSoldQty));
                    }
                });

                this.barChartData = [
                    { data: this.dataList, label: '' }
                ];
    },
      error => {
        this.router.navigate(['']);
      });
  }

  searchyearlycntsales() {
    this.barChartLabels = [];
    this.dataList = [];
    this.yearlysalesreportsService.getYearSalesReportByCustomer(this.currentYear)
    .subscribe(data => {
      this.salesList = data;
    //  this.equipmentDetails = data;
                this.salesList.forEach((item) => {
                    if (item != null && Number(item.totalSoldQty) > 0) {
                        this.barChartLabels.push(item.customer.name);
                        this.dataList.push(Number(item.totalSoldQty));
                    }
                });

                this.barChartData = [
                    { data: this.dataList, label: '' }
                ];
    },
      error => {
        this.router.navigate(['']);
      });
  }

}
