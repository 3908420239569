import { MasterPortsDepot } from './../../../../../model/masterportsdepot.model';
import { CompanyPortDepot } from './../../../../../model/companyportdepot.model';
import { Router, ActivatedRoute } from '@angular/router';
import { TbagentaddmanagedportlistService } from './tbagentaddmanagedportlist.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tbagentaddmanagedportlist',
  templateUrl: './tbagentaddmanagedportlist.component.html',
  styleUrls: ['./tbagentaddmanagedportlist.component.css']
})
export class TbagentaddmanagedportlistComponent implements OnInit {


  addPorts: CompanyPortDepot;
  portlist: MasterPortsDepot[];


  constructor(private tbagentaddmanagedportlistService:TbagentaddmanagedportlistService,private router: Router, private route:ActivatedRoute) { }

  ngOnInit() {
    this.addPorts = new CompanyPortDepot();
    this.addPorts.portId = new MasterPortsDepot()

    this.tbagentaddmanagedportlistService.getAllMyNearByPorts()
      .subscribe(data => {
        this.portlist = data;
        console.log ( this.portlist)
      },
        error => {
          this.router.navigate(['']);
        });
  }

  addPort() {
    // alert(JSON.stringify(this.portlist));
    console.log(this.portlist);
    this.tbagentaddmanagedportlistService.addPort(this.addPorts)
      .subscribe(data => {
        this.router.navigate(['/tbagentmode/tbagentmanagedportlist']);
      },
        error => {
          this.router.navigate(['']);
        });

  }


}

