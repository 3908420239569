import { Component, OnInit } from '@angular/core';
import { PoliciesContent } from 'src/app/model/policiescontent.model';
import { Router, ActivatedRoute } from '@angular/router';
import { EditprivacypolicyService } from './editprivacypolicy.service';

@Component({
  selector: 'app-editprivacypolicy',
  templateUrl: './editprivacypolicy.component.html',
  styleUrls: ['./editprivacypolicy.component.css']
})
export class EditprivacypolicyComponent implements OnInit {
  policyContent: PoliciesContent;

  constructor(private editprivacypolicyService: EditprivacypolicyService, private router: Router , private route: ActivatedRoute) { }

  ngOnInit() {
    this.policyContent = new PoliciesContent();
    this.editprivacypolicyService.fetchPolicy(this.route.snapshot.params.id)
        .subscribe(data => {
          this.policyContent = data;
        },
          error => {
            this.router.navigate(['']);
          });
  }

  updatePolicy() {
      this.editprivacypolicyService.updatePolicy(this.policyContent)
        .subscribe(data1 => {
          this.router.navigate(['/adminpanel/privacypolicy']);
        },
          error => {
            this.router.navigate(['']);
          });


  }

  approvePolicy() {
      this.editprivacypolicyService.approvePolicy(this.policyContent)
        .subscribe(data1 => {
          this.router.navigate(['/adminpanel/privacypolicy']);
        },
          error => {
            this.router.navigate(['']);
          });


  }

}
