import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { TrackBoxBookingContainers } from './../../../model/trackboxbookingcontainers.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class BookingcontainersviewService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/TrackboxBookingsViewbyId.do';
private appUrlcontainer = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/trackboxviewbookedcontainerdetails.do';

public getBookingsDetailsByID( id: string) {
  return this.http.post<TrackBoxBooking>(this.appUrl, id);
  }

  public getBookingContainerDetailsByID( bookingId: string) {
    return this.http.post<TrackBoxBookingContainers[]>(this.appUrlcontainer, bookingId);
    }


}
