import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { ReponsetoagentService } from './reponsetoagent.service';
import { AgentPrincipleFreightDetails } from 'src/app/model/agentprinciplefreightdetails.model';
import { Router } from '@angular/router';
import { AgentPrincipleFreight } from 'src/app/model/agentprinciplefreight.model';
import { RequestfromagentService } from '../requestfromagent/requestfromagent.service';
export interface DialogData {
  agentName: string;
  principleId: number;
  bookingId: number;
  freightId: number;
}
@Component({
  selector: 'app-reponsetoagent',
  templateUrl: './reponsetoagent.component.html',
  styleUrls: ['./reponsetoagent.component.css']
})
export class ReponsetoagentComponent implements OnInit {
  agentCompany: string;
  tbrequestView: AgentPrincipleFreightDetails[];
  tbfreightRequest: AgentPrincipleFreightDetails;
  trequestView: number;
  constructor(private reponsetoagentService: ReponsetoagentService, private router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData, private requestfromagentService: RequestfromagentService,
    private _bottomSheetRef: MatBottomSheetRef<ReponsetoagentComponent>) { }

  ngOnInit() {
    this.trequestView = this.data.freightId;
    this.agentCompany = this.data.agentName;
    this.tbfreightRequest = new AgentPrincipleFreightDetails();
    this.tbfreightRequest.agentPrincipleFreight = new AgentPrincipleFreight();

    // setTimeout(() => { this.ngOnInit() }, 1000 * 10)

    this.reponsetoagentService.getAllFreightDetailsById(this.data.freightId)
      .subscribe(data => {
        this.tbrequestView = data;
      },
        error => {
          this.router.navigate(['']);
        });


  }

  responseToAgent() {
    this.tbfreightRequest.agentPrincipleFreight.id = this.data.freightId;
    this.reponsetoagentService.responseToAgent(this.tbfreightRequest)
      .subscribe(data => {
        this.tbfreightRequest.requestFreight = '';
        this.reponsetoagentService.getAllFreightDetailsById(this.data.freightId)
          .subscribe(data1 => {
            this.tbrequestView = data1;
          },
            error => {
              this.router.navigate(['']);
            });
        this._bottomSheetRef.dismiss;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onKeydownFrightAmount(event) {
    if (event.key === 'Enter') {
      this.tbfreightRequest.agentPrincipleFreight.id = this.data.freightId;
      this.reponsetoagentService.responseToAgent(this.tbfreightRequest)
        .subscribe(data => {
          this.tbrequestView.push(this.tbfreightRequest);
          this.tbfreightRequest.requestFreight = '';
         // this.ngOnInit();
          // this.reponsetoagentService.getAllFreightDetailsById(this.data.freightId)
          //   .subscribe(data1 => {
          //     this.tbrequestView = data1;
          //   },
          //     error => {
          //       this.router.navigate(['']);
          //     });
      //    this._bottomSheetRef.dismiss;
        },
          error => {
            this.router.navigate(['']);
          });
    }
  }


  confirmApprove(freightId) {
    //  alert(JSON.stringify(freightId))
    this.requestfromagentService.confirmApprove(freightId)
      .subscribe(data => {
        this._bottomSheetRef.dismiss;
      },

        error => {
          this.router.navigate(['']);
        });

  }

  onNoClick() { this._bottomSheetRef.dismiss(); }

}
