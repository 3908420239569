import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyVoyage } from 'src/app/model/companyvoyage.model';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { MasterVoyage } from 'src/app/model/mastervoyage.model';
import { MasterVoyageRoute } from 'src/app/model/mastervoyageroute.model';
import { NewequipmentcontrolService } from 'src/app/trackmybox/equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { NewvoyageService } from '../newvoyage/newvoyage.service';
import { VoyagedetailsService } from '../voyagedetails/voyagedetails.service';
import { VoyagerouteService } from './voyageroute.service';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Component({
  selector: 'app-voyageroute',
  templateUrl: './voyageroute.component.html',
  styleUrls: ['./voyageroute.component.css']
})
export class VoyagerouteComponent implements OnInit {

  voyagedetails: CompanyVoyage;
  portList: MasterPortsDepot[];
  location: ContainerLocationType[];
  mastervoyageroute: MasterVoyageRoute;
  constructor(private voyagerouteService: VoyagerouteService, private router: Router, private route: ActivatedRoute,
              private voyagedetailsService: VoyagedetailsService, private newvoyageService: NewvoyageService,
              private newequipmentcontrolService: NewequipmentcontrolService) { }

  ngOnInit() {
    this.voyagedetails = new CompanyVoyage();
    this.voyagedetails.voyage = new MasterVoyage();
    this.mastervoyageroute = new MasterVoyageRoute();
    this.mastervoyageroute.voyageId = new CompanyVoyage();
    this.mastervoyageroute.portCalling = new MasterPortsDepot();
    this.mastervoyageroute.transhipment = new MasterPortsDepot();
    this.mastervoyageroute.returnedLocationId = new ContainerLocationType();

    this.voyagedetailsService.getVoyageDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.voyagedetails = data;
        // alert(JSON.stringify(this.voyagedetails));
      },
        error => {
          this.router.navigate(['']);
        });

    this.newvoyageService.viewPort()
      .subscribe(data => {
        this.portList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newequipmentcontrolService.viewcontainerLocationType()
      .subscribe(data => {
        this.location = data;

      },
        error => {
          this.router.navigate(['']);
        });
  }
  onSubmit() {
    this.voyagerouteService.save(this.mastervoyageroute)
      .subscribe(data => {
        this.router.navigate(['/trackmybox/voyages']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
