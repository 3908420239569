import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewpurchaselocationService } from './newpurchaselocation.service';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ContainerPurchaseLocation } from 'src/app/model/containerpurchaselocation.model';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { startWith } from 'rxjs/operators';


@Component({
  selector: 'app-newpurchaselocation',
  templateUrl: './newpurchaselocation.component.html',
  styleUrls: ['./newpurchaselocation.component.css']
})
export class NewpurchaselocationComponent implements OnInit {

  sessionCandidate: SessionCandidate;
  codes: string[];
  purchase: ContainerPurchaseLocation;
  locationType: ContainerLocationType[];
  CountryList: CountryInfo[];
  countryId = 0;
  myControl3 = new FormControl();
  filteredOptionscountry: Observable<CountryInfo[]>;


  constructor(private newpurchaselocationService: NewpurchaselocationService, private router: Router,
              private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.purchase = new ContainerPurchaseLocation();
    this.purchase.country = new CountryInfo();
    this.purchase.locationType = new ContainerLocationType();
    this.newpurchaselocationService.getLocationType()
      .subscribe(data => {
        this.locationType = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.newpurchaselocationService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          startWith(''),
          map(val => this._filter4(val))
        );


      },
        error => {
          this.router.navigate(['']);
        });

  }

  saveContainerPurchaseLocation() {
    this.newpurchaselocationService.saveContainerPurchaseLocation(this.purchase)
      .subscribe(data => {
        this.router.navigate(['containers/containerpurchaselocation']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }
  }

}
