import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { Company } from './company.model';
import { Candidates } from './candidate.model';
import { CompanyVessels } from './companyvessels.model';
import { MasterPortsDepot } from './masterportsdepot.model';
import { ContainerType } from './containertype.model';
import { DangarousGoodsClasses } from './dangarousgoodsclasses.model';
import { DangarousGoodsSubclasses } from './dangarousgoodssubclasses.model';

export class TrackBoxBooking {
    id: number;
    company: Company;
    vessel: CompanyVessels;
    candidate: Candidates;
    bookingNo: string;
    voyageNumber: string;
    // noOfSlots: number;
    bookingOn: Date;
    createdOn: Date;
    // noSlot20: number;
    // noSlot40: number;
    // noSlot45: number;
    dangarousGoods: boolean;
    polFreetime: number;
    podFreetime: number;
    overGaugeCargo: boolean;
    blNumber: string;
    pol: MasterPortsDepot;
    pod: MasterPortsDepot;
    transhipment: MasterPortsDepot;
    polEta: Date;
    polLoading: Date;
    polCutoff: Date;
    polCutoffTime: Date;
    vesselStartsOn: Date;
    polEtd: Date;
    podEta: Date;
    podReachedOn: Date;
    podDischargedOn: Date;
    customer: TrackBoxCustomers;
    noOfTEUs: number;
    containerType: ContainerType;
    consignee: TrackBoxCustomers;
    clearingAgent: TrackBoxAgent;
    deliveryOrderDate: Date;
    blNumberUpdatedOn: Date;
    freightConfirmed: Company;
    fpod: MasterPortsDepot;
    dgClasses: DangarousGoodsClasses;
	dgSubalsses: DangarousGoodsSubclasses;
}
