import { Candidates } from '../../model/candidate.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CandidateaboutService {

  constructor(private http: HttpClient) { }

   private appUrl = ShipStreetProps.API_PATH + 'sessionCandidate.do';
   private canUrl = ShipStreetProps.API_PATH + 'candidate.do';
  private udateAboutUrl = ShipStreetProps.API_PATH + 'updateCandidateAbout.do';

  public sessioncandidate() {
    return this.http.get<Candidates>(this.appUrl);
  }

   public updateAbout(candidate: Candidates) {
    return this.http.post<Candidates>(this.udateAboutUrl , candidate);
  }
}
