import { PostComment } from './PostComment.model';
import { Candidates } from './candidate.model';
export class Posts {
    id: number;
    candidate: Candidates;
    postTitle: string;
    postContent: string;
    postType: string;
    createdOn: string;
    publishedOn: string;
    noLike: string;
    noComments: string;
    noViews: string;
    newComments: PostComment;
    comlimit: number;
    postCommentList: PostComment[];
    postLikes: string;
    active: string;
    coverImage: string;
}
