import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Candidates } from 'src/app/model/candidate.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewemployeeService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'containeremployees/findAllContainerCompanyEmployees.do';
  private saveUrl = ShipStreetProps.API_PATH + 'containeremployees/asignContainerTradingPermission.do';
  private remeUrl = ShipStreetProps.API_PATH + 'containeremployees/removeContainerTradingPermission.do';

  findAllContainerCompanyEmployees() {
    return this.http.get<CompanyEmployee[]>(this.appUrl);
  }

  asignContainerTradingPermission(comemployee: CompanyEmployee) {
    return this.http.post<CompanyEmployee[]>(this.saveUrl , comemployee);
  }

  removeContainerTradingPermission(comemployee: CompanyEmployee) {
    return this.http.post<CompanyEmployee[]>(this.remeUrl , comemployee);
  }
}
