import { TrackBoxBooking } from './../../../model/trackboxbooking.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CompanyVessels } from 'src/app/model/companyvessels.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Injectable({
  providedIn: 'root'
})
export class NewbookingService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/trackboxbookingssave.do';
  private appUrls = ShipStreetProps.API_PATH + 'vessels/findAllCompanyVessels.do';
  private appPortUrls = ShipStreetProps.API_PATH + 'masterports/masterportsview.do';

 public save(trackboxbooking: TrackBoxBooking) {
    return this.http.post<TrackBoxBooking>(this.appUrl, trackboxbooking);
  }

  getAllMasterVessel() {
    return this.http.get<CompanyVessels[]>(this.appUrls);
  }

   viewPort() {
    return this.http.get<MasterPortsDepot[]>(this.appPortUrls);
  }
}
