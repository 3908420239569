import { CompanyVoyage } from './../../model/companyvoyage.model';
import { TrackBoxBooking } from './../../model/trackboxbooking.model';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingsService } from './bookings.service';
import { Component, OnInit } from '@angular/core';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { RequestfromagentService } from './requestfromagent/requestfromagent.service';
import { AgentPrincipleFreight } from 'src/app/model/agentprinciplefreight.model';
import { CompanyVessels } from 'src/app/model/companyvessels.model';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {
  bookingsList: TrackBoxBooking[];
  bookingsLists: TrackBoxBooking;
  pol: MasterPortsDepot;
  voyage: CompanyVoyage;
  p: number;
  panelOpenState: boolean;
  tbrequestView: AgentPrincipleFreight[];
  tbrequestViews: AgentPrincipleFreight;
  searchText: string;
  bookingsListAll: TrackBoxBooking[];

  constructor(private bookingsService: BookingsService, private requestfromagentService: RequestfromagentService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.tbrequestViews = new AgentPrincipleFreight();
    this.tbrequestViews.bookingId = new TrackBoxBooking();
    this.tbrequestViews.bookingId.pod = new MasterPortsDepot();
    this.tbrequestViews.bookingId.pol = new MasterPortsDepot();
    this.tbrequestViews.bookingId.vessel = new CompanyVessels();
    this.bookingsService.getAllPrincipleBookings()
      .subscribe(data => {
        this.bookingsListAll = data;
        this.bookingsList = this.bookingsListAll;
      },
        error => {
          this.router.navigate(['']);
        });


    this.requestfromagentService.getAgentRequests()
      .subscribe(data => {
        this.tbrequestView = data;
        console.log(this.tbrequestView)
      },
        error => {
          this.router.navigate(['']);
        });

  }
  confirmApprove(freightId) {
    //     // this.tbbooking.bookingId = this.data.leaseUnitId;
    //      console.log(JSON.stringify(this.agentreq));
    //       // this.bookingincome.agpfBooking =  this.agentreq;
    // alert(JSON.stringify(freightId));
    this.requestfromagentService.confirmApprove(freightId)
      .subscribe(data => {
        this.router.navigate(['/trackmybox/viewrequests']);
      },

        error => {
          this.router.navigate(['']);
        });

  }

    searchbar() {
     if (this.searchText !== '') {
      this.bookingsList = this.bookingsListAll.filter(res => {
        return res.bookingNo.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.vessel.masterVessel.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.voyageNumber.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.pol.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.pod.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())
      });
    } else if (this.searchText === '') {
      this.bookingsList = this.bookingsListAll;
    } 
  }

}


