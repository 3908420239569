import { Component, OnInit } from '@angular/core';
import { Events } from 'src/app/model/events.model';
import { ManageeventsService } from 'src/app/events/manageevents/manageevents.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rigevents',
  templateUrl: './rigevents.component.html',
  styleUrls: ['./rigevents.component.css']
})
export class RigeventsComponent implements OnInit {

  events: Events[];

  constructor(private manageeventsService: ManageeventsService , private router: Router) { }

  ngOnInit() {
    this.manageeventsService.getAllEvents()
      .subscribe( data => {
        this.events = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
