import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerService } from './container.service';
import { ContainerPurchase } from '../model/containerpurchase.model';
import { ShipStreetProps } from '../utils/rs.constants';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit {

 purchaseList: ContainerPurchase[];
imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private containerService: ContainerService , private router: Router) { }

  ngOnInit() {
    this.containerService.containerPurchaseList()
      .subscribe( data => {
        this.purchaseList = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  enrollCompanyTradeMyBox() {}

}
