import { Component, OnInit } from '@angular/core';
import { BlNumberSearchDTO } from 'src/app/model/blnumbersearchDTO.model';
import { BookingBLNumber } from 'src/app/model/bookingblnumber.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BlsearchresulfofflineService } from './blsearchresulfoffline.service';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { Company } from 'src/app/model/company.model';

@Component({
  selector: 'app-blsearchresulfoffline',
  templateUrl: './blsearchresulfoffline.component.html',
  styleUrls: ['./blsearchresulfoffline.component.css']
})
export class BlsearchresulfofflineComponent implements OnInit {

  blview: BlNumberSearchDTO;
  blnumber: string;
  blviews: BookingBLNumber;

  constructor(private blsearchresulfofflineService: BlsearchresulfofflineService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.blview = new BlNumberSearchDTO();
    this.blview.blnumber = new BookingBLNumber();
    this.blview.blnumber.bookingContainer = new TrackBoxBookingContainers();
    this.blview.blnumber.bookingContainer.bookingId = new TrackBoxBooking();
    this.blview.blnumber.bookingContainer.bookingId.customer = new TrackBoxCustomers();
    this.blview.blnumber.bookingContainer.bookingId.pol = new MasterPortsDepot();
    this.blview.blnumber.bookingContainer.bookingId.company = new Company();

    this.blsearchresulfofflineService.getByBLNumber(this.route.snapshot.params.id)
      .subscribe(data => {
        this.blviews = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onKeydown(event) {

        if (event.key === 'Enter') {
            this.blsearchresulfofflineService.getByBLNumber(this.blnumber)
      .subscribe(data1 => {
        this.blviews = data1;
      },
        error => {
          this.router.navigate(['']);
        });
        }
    }

}


