import { Injectable } from '@angular/core';
import { CandidateEducation } from '../../../model/candidateEducation.model';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddeducationService {

  constructor(private http: HttpClient) { }

  private saveUrl = ShipStreetProps.API_PATH + 'newEducation.do';

  public saveType(education: CandidateEducation) {
    return this.http.post<CandidateEducation[]>(this.saveUrl, education);
  }
}
