import { CandidateConnect } from '../../model/candidateConnect.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class FriendlistplugService {

  constructor(private http: HttpClient) { }
  private myFriendListUrl = ShipStreetProps.API_PATH + 'myFriendsList.do';

   public getMyFriends() {
    return this.http.get<CandidateConnect[]>(this.myFriendListUrl);
  }
}
