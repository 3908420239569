import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { Posts } from '../../model/posts.model';
import { HttpClient } from '@angular/common/http';
import { FileUploadResponse } from 'src/app/model/fileuploadResponse.model';

@Injectable({
  providedIn: 'root'
})
export class EditpostService {

  constructor(private http: HttpClient ) { }

  private postUrl = ShipStreetProps.API_PATH + 'getAPost.do';
  private imageUploadUrl = ShipStreetProps.API_PATH + 'postCoveImageUpload.do';

  private updateUrl = ShipStreetProps.API_PATH + 'updatePosts.do';


  public getAPost(postId: string) {
    return this.http.post<Posts>(this.postUrl, postId);
  }
  public postCoverImageUpload(selectedFile: FormData) {
    return this.http.post<FileUploadResponse>(this.imageUploadUrl , selectedFile);
  }


   public updatePost(post: Posts) {
     return this.http.post<Posts>(this.updateUrl , post);
   }


}
