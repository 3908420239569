import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackboxChargeHeads } from 'src/app/model/trackboxchargeheads.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ChargeheadsService {

constructor(private http: HttpClient) { }

private appUrl = ShipStreetProps.API_PATH + 'TrackboxChargeHeads/trackboxchargeheadList.do';

public getAllMyChargeheads() {

  return this.http.get<TrackboxChargeHeads[]>(this.appUrl);
}
}
