import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalcChargeHeads } from 'src/app/model/calcchargeheads.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { NewlocalchargeheadsService } from './newlocalchargeheads.service';

@Component({
  selector: 'app-newlocalchargeheads',
  templateUrl: './newlocalchargeheads.component.html',
  styleUrls: ['./newlocalchargeheads.component.css']
})
export class NewlocalchargeheadsComponent implements OnInit {
  chargeheads: CalcChargeHeads;

  constructor(private newlocalchargeheadsService: NewlocalchargeheadsService, private router: Router) { }

  ngOnInit() {
    this.chargeheads = new CalcChargeHeads();

  }

  createChargeHeads() {
    this.newlocalchargeheadsService.createChargeHeads(this.chargeheads)
      .subscribe(data => {
        this.router.navigate(['/calculator/callocalchargeheads']);
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
