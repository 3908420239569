import { Routes } from '@angular/router';
import { SalesforcelayoutComponent } from '../_layouts/salesforcelayout/salesforcelayout.component';
import { SalesforcedashboardComponent } from './salesforcedashboard/salesforcedashboard.component';
import { MypipelineComponent } from './mypipeline/mypipeline.component';
import { MyactivityComponent } from './myactivity/myactivity.component';
import { SalesreportdashboardComponent } from './reports/salesreportdashboard/salesreportdashboard.component';
import { ForcastreportsComponent } from './reports/forcastreports/forcastreports.component';
import { PipelinereportsComponent } from './reports/pipelinereports/pipelinereports.component';
import { ActivityreportsComponent } from './reports/activityreports/activityreports.component';

export const salesforceRoute: Routes = [
    {
        path: 'salesforce',
        component: SalesforcelayoutComponent,
        children: [
            { path: '', component: SalesforcedashboardComponent },
            { path: 'mypipeline', component: MypipelineComponent },
            { path: 'myactivity', component: MyactivityComponent },
            { path: 'salesreportdashboard', component: SalesreportdashboardComponent },
            { path: 'forcastreports', component: ForcastreportsComponent },
            { path: 'pipelinereports', component: PipelinereportsComponent },
            { path: 'activatereports', component: ActivityreportsComponent },
        ]
    }
];
