import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { TbagentbookingdetailsService } from '../tbagentbookingdetails/tbagentbookingdetails.service';
import { EdittbagentbookingService } from './edittbagentbooking.service';

@Component({
  selector: 'app-edittbagentbooking',
  templateUrl: './edittbagentbooking.component.html',
  styleUrls: ['./edittbagentbooking.component.css']
})
export class EdittbagentbookingComponent implements OnInit {

  trackboxbooking: TrackBoxBooking;
  constructor(private edittbagentbookingService: EdittbagentbookingService, private router: Router,
    private route: ActivatedRoute,private tbagentbookingdetailsService: TbagentbookingdetailsService) { }

  ngOnInit() {

    this.tbagentbookingdetailsService.getBookingsDetailsByID(this.route.snapshot.params.id)
    .subscribe(data => {
      this.trackboxbooking = data;
      // alert(JSON.stringify(this.trackboxbooking));
      },
      error => {
        this.router.navigate(['']);
      });

  }

  updateBookings() {
    this.edittbagentbookingService.updateBookings(this.trackboxbooking)
      .subscribe(data => {
        this.router.navigate(['/tbagentmode/tbagentbookings']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
  }


