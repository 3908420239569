import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerSalesBalance } from 'src/app/model/containersalesbalance.model';

@Injectable({
  providedIn: 'root'
})
export class ContainersalesincomeService {

   private listUrl = ShipStreetProps.API_PATH + 'containersalesbalance/containerSalesBalanceList';

  constructor(private http: HttpClient) { }

  public containerSalesBalanceList() {
    return this.http.get<ContainerSalesBalance[]>(this.listUrl);
  }


}
