import { Marketplace } from '../../../model/marketplace.model';
import { MarketplaceQuoteRequest } from '../../../model/marketplaceQuoteRequest.model';
import { MarketplacequoteService } from './marketplacequote.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-marketplacequote',
  templateUrl: './marketplacequote.component.html',
  styleUrls: ['./marketplacequote.component.css']
})
export class MarketplacequoteComponent implements OnInit {
  marketplacequote: MarketplaceQuoteRequest;
  quote: MarketplaceQuoteRequest;
  marketplace: Marketplace;

  constructor( private marketplacequoteService: MarketplacequoteService, private route: ActivatedRoute , private router: Router) { }

  ngOnInit() {
    this.quote = new MarketplaceQuoteRequest;
    this.marketplacequoteService.getmarketplaceorder(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.marketplace = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }
  quoteRequest() {
    this.quote.marketplace = this.route.snapshot.params['id'];
     this.marketplacequoteService.quoteRequest(this.quote)
      .subscribe( data => {
        this.marketplacequote = data;
        this.router.navigate(['/m/marketquoteview' , this.route.snapshot.params['id']]);
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
