import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class FirstfeednotificationService {

  private appUrl = ShipStreetProps.API_PATH + 'firstfeed.do';
  constructor(private http: HttpClient) { }

   public firstFeed() {
    return this.http.get<string>(this.appUrl);
  }
}
