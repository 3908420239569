import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { Candidates } from 'src/app/model/candidate.model';
import { Company } from 'src/app/model/company.model';
export class AgentPrincipleFreight {

  id: number;
  agentId: Company;
  principleId: Company;
  requestFreight: string;
  requestedOn: Date;
  requestedBy: Candidates;
  bookingId: TrackBoxBooking;
  status: string;
  responseBy: Candidates;
  responseFreight: string;
  responseOn: Date;

}
