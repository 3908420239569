import { Routes } from '@angular/router';

import { TrackerlayoutComponent } from '../_layouts/trackerlayout/trackerlayout.component';
import { TrackerComponent } from './tracker.component';
import { TrackeremployeesComponent } from './trackeremployees/trackeremployees.component';
import { NewtrackeremployeeComponent } from './trackeremployees/newtrackeremployee/newtrackeremployee.component';
import { EdittrackeremployeeComponent } from './trackeremployees/edittrackeremployee/edittrackeremployee.component';
import { TrackerpropertyComponent } from './trackerproperty/trackerproperty.component';
import { NewtrackerpropertyComponent } from './trackerproperty/newtrackerproperty/newtrackerproperty.component';
import { EdittrackerpropertyComponent } from './trackerproperty/edittrackerproperty/edittrackerproperty.component';
import { TrackerdesignationComponent } from './master/trackerdesignation/trackerdesignation.component';
import { TrackerdepartmentComponent } from './master/trackerdepartment/trackerdepartment.component';
import { NewtrackerdesignationComponent } from './master/trackerdesignation/newtrackerdesignation/newtrackerdesignation.component';
import { EdittrackerdesignationComponent } from './master/trackerdesignation/edittrackerdesignation/edittrackerdesignation.component';
import { NewtrackerdepartmentComponent } from './master/trackerdepartment/newtrackerdepartment/newtrackerdepartment.component';
import { EdittrackerdepartmentComponent } from './master/trackerdepartment/edittrackerdepartment/edittrackerdepartment.component';


export const trackerRoutes: Routes = [
 { path: 'tracker',
        component: TrackerlayoutComponent,
        children: [
          { path: '' , component: TrackerComponent},
          { path: 'trackeremployees' , component: TrackeremployeesComponent},
          { path: 'newtrackeremployees' , component: NewtrackeremployeeComponent},
          { path: 'edittrackeremployee/:id' , component: EdittrackeremployeeComponent},
          { path: 'trackerproperties' , component: TrackerpropertyComponent},
          { path: 'newtrackerproperty' , component: NewtrackerpropertyComponent},
          { path: 'edittrackerproperty' , component: EdittrackerpropertyComponent},
          { path: 'trackerdesignations' , component: TrackerdesignationComponent},
          { path: 'newtrackerdesignation' , component: NewtrackerdesignationComponent},
          { path: 'edittrackerdesignation' , component: EdittrackerdesignationComponent},
          { path: 'trackerdepartments' , component: TrackerdepartmentComponent},
          { path: 'newtrackerdepartment' , component: NewtrackerdepartmentComponent},
          { path: 'edittrackerdepartment' , component: EdittrackerdepartmentComponent},
        ]
  }
  ];
