import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalcChargeHeads } from 'src/app/model/calcchargeheads.model';
import { LocalchargeheadsService } from './localchargeheads.service';

@Component({
  selector: 'app-localchargeheads',
  templateUrl: './localchargeheads.component.html',
  styleUrls: ['./localchargeheads.component.css']
})
export class LocalchargeheadsComponent implements OnInit {

  p: number;
  chargeHeadList: CalcChargeHeads[];
  constructor(private localchargeheadsService: LocalchargeheadsService, private router: Router) { }

  ngOnInit() {
    this.localchargeheadsService.findAllCalculatorChargeHeads()
      .subscribe(data => {
        this.chargeHeadList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
