import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsPaymentDTO } from 'src/app/model/accountspaymentDTO.model';
import { AccountsPaymentMode } from 'src/app/model/accountspaymentmode.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { AccountspaymodeService } from '../../accountspaymode/accountspaymode.service';
import { IncomeService } from './income.service';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css']
})
export class IncomeComponent implements OnInit {

  contSales: ContainerSales[];
  p = 1;
  paymentModeList: AccountsPaymentMode[];
  paymentMode: AccountsPaymentMode;
  accountsPaymentDTO: AccountsPaymentDTO;
  constructor(private incomeService: IncomeService, private router: Router, private route: ActivatedRoute,
    private accountspaymodeService: AccountspaymodeService) { }

  ngOnInit() {
    this.paymentMode = new AccountsPaymentMode();
    this.accountsPaymentDTO = new AccountsPaymentDTO();
    this.accountsPaymentDTO.paymentMode = new AccountsPaymentMode();
 //   this.accountsPaymentDTO.contSales = ContainerSales[];
    
    this.incomeService.containerSalesBalancbyId(this.route.snapshot.params.id)
      .subscribe(data => {
        this.contSales = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.accountspaymodeService.getaccountspaymentmodelist()
      .subscribe(data => {
        this.paymentModeList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  makepayment() {
    this.accountsPaymentDTO.contSales = this.contSales;
    this.accountsPaymentDTO.paymentMode.id = this.paymentMode.id;
    this.incomeService.salesmakepayment(this.accountsPaymentDTO)
      .subscribe(data => {
        this.router.navigate(['/containers/salesincome']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
}


