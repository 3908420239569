import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountsPaymentMode } from 'src/app/model/accountspaymentmode.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class AccountspaymodeService {

  private listUrl = ShipStreetProps.API_PATH + 'containersaccounts/getallaccountspaymentmodelist';

  constructor(private http: HttpClient) { }

  public getaccountspaymentmodelist() {
    return this.http.get<AccountsPaymentMode[]>(this.listUrl);
  }
}
