import { TrackboxContainerStatus } from './../../trackboxcontainerstatus.model';
import { ContainerStatus } from './../../../model/containerstatus.model';
import { TrackBoxBookingContainers } from './../../../model/trackboxbookingcontainers.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class UpdatebookingService {

constructor(private http: HttpClient) { }

private appUrl = ShipStreetProps.API_PATH + 'trackboxcontainerStatus/tbviewStatus.do';
private appUrlsbooking = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/trackboxviewbookedcontainerbyId.do';
private appUrlupdateStat = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/updateTrackboxcontainerbooking.do';

public getBookedContainersByID( ContainerId: string) {
  return this.http.post<TrackBoxBookingContainers>(this.appUrlsbooking, ContainerId);
  }
  public getContainerStatus( ) {
    return this.http.get<TrackboxContainerStatus[]>(this.appUrl);
    }

    public updateContainerStatus(contStat: TrackBoxBookingContainers) {
     // alert(JSON.stringify(contStat));
      return this.http.post<string>(this.appUrlupdateStat , contStat);
    }


}
