import { Company } from './company.model';
import { TrackerDepartment } from './trackerdepartment.model';
import { TrackerDesignation } from './trackerdesignation.module';

export class TrackerEmployees {
    id: number;
    name: string;
    address: string;
    location: string;
    city: string;
    country: string;
    email: string;
    password: string;
    dob: Date;
    doj: Date;
    phone: string;
    mobile: string;
    companyId: Company;
    department: TrackerDepartment;
    designation: TrackerDesignation;
}
