import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ContainersalesdetailsService } from './containersalesdetails.service';

@Component({
  selector: 'app-containersalesdetails',
  templateUrl: './containersalesdetails.component.html',
  styleUrls: ['./containersalesdetails.component.css']
})
export class ContainersalesdetailsComponent implements OnInit {
  sales: ContainerSales;
  isOpen: boolean;
  salesId: number;

  
  constructor(private containersalesdetailsService: ContainersalesdetailsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.sales = new ContainerSales();
    this.sales.purchase = new ContainerPurchase();
    this.salesId = Number(this.route.snapshot.paramMap.get('id'));
    this.containersalesdetailsService.findSingleContainerSales(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.sales = data;
      //  alert(JSON.stringify( this.sales));
      },
        error => {
          this.router.navigate(['']);
        });

  }


  }


