import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { TrackboxcustomersService } from './trackboxcustomers.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-trackboxcustomers',
  templateUrl: './trackboxcustomers.component.html',
  styleUrls: ['./trackboxcustomers.component.css']
})
export class TrackboxcustomersComponent implements OnInit {

  constructor(private trackboxcustomersService: TrackboxcustomersService, private router: Router, private route: ActivatedRoute) { }
  trackboxCustomersList: TrackBoxCustomers[];
  p: number;
  panelOpenState: boolean;
  ngOnInit() {

    this.trackboxcustomersService.getAllMyCustomers()
      .subscribe(data => {
        this.trackboxCustomersList = data;
        // alert(JSON.stringify(this.trackboxsupplierList));
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
