import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalcEquipmentType } from 'src/app/model/CalcEquipmentType.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { CalcequipmenttypesService } from './calcequipmenttypes.service';

@Component({
  selector: 'app-calcequipmenttypes',
  templateUrl: './calcequipmenttypes.component.html',
  styleUrls: ['./calcequipmenttypes.component.css']
})
export class CalcequipmenttypesComponent implements OnInit {

  p: number;
  tbEmployeesList: CompanyEmployee[];
  calcEqTypes: CalcEquipmentType[];
  constructor(private calcequipmenttypesService: CalcequipmenttypesService, private router: Router) { }

  ngOnInit() {
    this.calcequipmenttypesService.findAllCalculatorEquipmentType()
      .subscribe(data => {
        this.calcEqTypes = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
