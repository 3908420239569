import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { LoginpageComponent } from './login/loginpage/loginpage.component';
import { SignupprivacyComponent } from './login/signupprivacy/signupprivacy.component';
import { Page1Component } from './login/page1/page1.component';
import { Page2Component } from './login/page2/page2.component';
import { Page3Component } from './login/page3/page3.component';
import { PrivacyComponent } from './login/privacy/privacy.component';
import { UseragreementComponent } from './login/useragreement/useragreement.component';
import { CookiepolicyComponent } from './login/cookiepolicy/cookiepolicy.component';
import { ForgotpasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { ExternalloginComponent } from './login/externallogin/externallogin.component';
import { ExternalpostloginComponent } from './login/externalpostlogin/externalpostlogin.component';
import { AccountdeactivatedmessageComponent } from './login/accountdeactivatedmessage/accountdeactivatedmessage.component';
import { AccountactivateloginComponent } from './login/accountactivatelogin/accountactivatelogin.component';
import { AccountactivationfromloginComponent } from './login/accountactivationfromlogin/accountactivationfromlogin.component';
import { AccountactivationcodeverifyComponent } from './login/accountactivationcodeverify/accountactivationcodeverify.component';
import { JoinnowComponent } from './login/joinnow/joinnow.component';
import { LoginpopComponent } from './login/loginpop/loginpop.component';
import { SignuppopComponent } from './login/signuppop/signuppop.component';
import { RefundloginComponent } from './login/refundlogin/refundlogin.component';
import { BlsearchresultComponent } from './login/blsearchresult/blsearchresult.component';
import { BlsearchofflineComponent } from './login/blsearchoffline/blsearchoffline.component';
import { BlsearchresulfofflineComponent } from './login/blsearchresulfoffline/blsearchresulfoffline.component';
import { AccountverificationComponent } from './login/accountverification/accountverification.component';
import { ContainerbycountrydetailsComponent } from './containersearch/containerbycountrydetails/containerbycountrydetails.component';
import { ContainersearchComponent } from './containersearch/containersearch.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { PasscodecheckingComponent } from './login/forgotpassword/passcodechecking/passcodechecking.component';
import { PasswordresettingComponent } from './login/forgotpassword/passwordresetting/passwordresetting.component';

export const appRoutes: Routes = [
  {
    path: '',
        component: LoginLayoutComponent,
        children: [
            { path: '', component: LoginpageComponent },
            { path: 'signupprivacy', component: SignupprivacyComponent },
            { path: 'page1', component: Page1Component },
            { path: 'page2', component: Page2Component },
            { path: 'page3', component: Page3Component },
            { path: 'privacy', component: PrivacyComponent },
            { path: 'useragreement', component: UseragreementComponent },
            { path: 'cookiepolicy', component: CookiepolicyComponent },
            { path: 'forgotpassword', component: ForgotpasswordComponent },
            { path: 'passcodechecking/:id', component: PasscodecheckingComponent },
            { path: 'passwordresetting:/id', component: PasswordresettingComponent },
            { path: 'externallogin/:id', component: ExternalloginComponent },
            { path: 'externalpostlogin/:id', component: ExternalpostloginComponent },
            { path: 'accountdeactivated/:id', component: AccountdeactivatedmessageComponent },
            { path: 'accountactivated', component: AccountactivateloginComponent },
            { path: 'accountactivatedfromlogin', component: AccountactivationfromloginComponent },
            { path: 'accountactivationcodeverification/:id', component: AccountactivationcodeverifyComponent },
            { path: 'joinnow', component: JoinnowComponent },
            { path: 'loginpop', component: LoginpopComponent },
            { path: 'signuppop', component: SignuppopComponent },
            { path: 'containerbycountry/:id', component: ContainersearchComponent },
            { path: 'refundlogin', component: RefundloginComponent },
            { path: 'blsearchresult/:id', component: BlsearchresultComponent },
            { path: 'blsearchoffline', component: BlsearchofflineComponent },
            { path: 'blsearchresultoffline/:id', component: BlsearchresulfofflineComponent },
            { path: 'accountverification/:id', component: AccountverificationComponent },
            { path: 'containerbycoutrydetails/:id', component: ContainerbycountrydetailsComponent },
            { path: 'marketplace', component: MarketplaceComponent },
            
        ]

  },
  //  { path: 'containers', loadChildren: () => import('./containers/containers.module').then(m => m.ContainersModule)},
  //  { path: 'trackmybox', loadChildren: () => import('./trackmybox/trackmybox.module').then(m => m.TrackmyboxModule)},
    // { path: 'tbagentmode', loadChildren: () => import('./trackmybox/agents/tbagent.module').then(m => m.TbagentModule)},
    // { path: 'helps', loadChildren: () => import('./help/help.module').then(m => m.HelpModule)},
    // { path: 'salesforce', loadChildren: () => import('./salesforce/salesforce.module').then(m => m.SalesforceModule)},
    // { path: 'adminpanel', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
    // { path: 'calculator', loadChildren: () => import('./calculator/calculator.module').then(m => m.CalculatorModule)},
    // { path: 'tracker', loadChildren: () => import('./tracker/tracker.module').then(m => m.TrackerModule)},

//  { path: '', loadChildren: './login/login.module#LoginModule' },
  // { path: '',
  //   loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  //  { path: 'm' , loadChildren: './login/login.module#LoginModule'},
//  { path: 'company', loadChildren: './company/company.module#CompanyModule' },
//  { path: 'recruiter', loadChildren: './recruiter/recruiter.module#RecruiterModule' },
 // { path: 'market', loadChildren: './market/market.module#MarketModule' },
 // { path: 'cms', loadChildren: './cms/cms.module#CmsModule' },
 // { path: 'o', loadChildren: './offline/offline.module#OfflineModule' },
 //
 // { path: 'containers', loadChildren: './containers/containers.module#ContainersModule' },
 // { path: 'containerlogin', loadChildren: './containers/containerlogin/containerlogin.module#ContainerloginModule' },
 // { path: 'rigvites', loadChildren: './events/events.module#EventsModule' },
  // {path:'trackmybox',loadChildren:'/trackmybox/equipmentcontrollist'}
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,RouterModule.forRoot(appRoutes , {useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
