import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class Page3Service {

  private updateProfileImageUrl = ShipStreetProps.API_PATH + 'updateProfileImage.do';

  constructor(private http: HttpClient) { }

   public updateProfileImage(selectedFile: FormData) {
    return this.http.post<string>(this.updateProfileImageUrl , selectedFile);
  }
}
