import { Marketplace } from '../../../model/marketplace.model';
import { MarketplaceQuoteRequest } from '../../../model/marketplaceQuoteRequest.model';
import { MarketplacequoteComponent } from './marketplacequote.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class MarketplacequoteService {
  private appUrl = ShipStreetProps.API_PATH + 'getmarketplace.do';
  private reqUrl = ShipStreetProps.API_PATH + 'getmarketplace.do';

  constructor(private http: HttpClient) { }
  public getmarketplaceorder(marketId: string) {
    return this.http.post<Marketplace>(this.appUrl , marketId);
  }
  public quoteRequest(quote: MarketplaceQuoteRequest) {
    return this.http.post<MarketplaceQuoteRequest>(this.reqUrl , quote);
  }
}
