import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';
import { EditaccountscategoryService } from './editaccountscategory.service';

@Component({
  selector: 'app-editaccountscategory',
  templateUrl: './editaccountscategory.component.html',
  styleUrls: ['./editaccountscategory.component.css']
})
export class EditaccountscategoryComponent implements OnInit {
  category: AccountsProductCategory;

  constructor(private editaccountscategoryService: EditaccountscategoryService, private router: Router, 
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.editaccountscategoryService.getAccountsCategory(this.route.snapshot.paramMap.get('id'))
    .subscribe(data => {
      this.category = data;
    //  this.router.navigate(['/accounts/categorylist']);
    },
        error => {
            this.router.navigate(['']);
        });

  }

  updateProductCategory() {
    this.editaccountscategoryService.updateProductCategory(this.category)
    .subscribe(data => {
      this.category = data;
      this.router.navigate(['/accounts/categorylist']);
    },
        error => {
            this.router.navigate(['']);
        });
  }


}
