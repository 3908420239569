import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewpurchasecontainersComponent } from './tradings/containerpurchase/viewpurchasecontainers/viewpurchasecontainers.component';
import { RouterModule, Routes } from '@angular/router';
import { ContainerlayoutComponent } from '../_layouts/containerlayout/containerlayout.component';
import { ContainersComponent } from './containers.component';
import { TradingsComponent } from './tradings/tradings.component';
import { ContainerpurchaseComponent } from './tradings/containerpurchase/containerpurchase.component';
import { ContainersalesComponent } from './tradings/containersales/containersales.component';
import { EmployeeComponent } from './admin/employee/employee.component';
import { VendorsComponent } from './admin/vendors/vendors.component';
import { NewemployeeComponent } from './admin/employee/newemployee/newemployee.component';
import { NewcustomerComponent } from './admin/customers/newcustomer/newcustomer.component';
import { NewvendorComponent } from './admin/vendors/newvendor/newvendor.component';
import { NewcontainerpurchaseComponent } from './tradings/containerpurchase/newcontainerpurchase/newcontainerpurchase.component';
import { ContainertypeComponent } from './master/containertype/containertype.component';
import { NewcontainertypeComponent } from './master/containertype/newcontainertype/newcontainertype.component';
// tslint:disable-next-line: max-line-length
import { ContainerpurchasepaymentComponent } from './tradings/containerpurchase/containerpurchasepayment/containerpurchasepayment.component';
import { NewcontainersalesComponent } from './tradings/containersales/newcontainersales/newcontainersales.component';
import { CustomersComponent } from './admin/customers/customers.component';
import { PurchaselocationComponent } from './master/purchaselocation/purchaselocation.component';
import { NewpurchaselocationComponent } from './master/purchaselocation/newpurchaselocation/newpurchaselocation.component';
// tslint:disable-next-line: max-line-length
import { ContainerpurchasedetailsComponent } from './tradings/containerpurchase/containerpurchasedetails/containerpurchasedetails.component';
import { LocationtypeComponent } from './master/locationtype/locationtype.component';
import { ContainersalesincomeComponent } from './accounts/containersalesincome/containersalesincome.component';
import { ContainerpurchaseexpenseComponent } from './accounts/containerpurchaseexpense/containerpurchaseexpense.component';
import { TaxesComponent } from './master/taxes/taxes.component';
import { NewtaxesComponent } from './master/taxes/newtaxes/newtaxes.component';
import { TaxagenciesComponent } from './master/taxes/taxagencies/taxagencies.component';
import { NewtaxagencyComponent } from './master/taxes/taxagencies/newtaxagency/newtaxagency.component';
import { BuycontainersComponent } from './buycontainers/buycontainers.component';
import { MovecontainersComponent } from './movecontainers/movecontainers.component';
import { NewmovecontainersComponent } from './movecontainers/newmovecontainers/newmovecontainers.component';
import { OnewaymovedetailsComponent } from './movecontainers/onewaymovedetails/onewaymovedetails.component';
import { OnewaymoverequestComponent } from './movecontainers/onewaymoverequest/onewaymoverequest.component';
import { ContainerleaseComponent } from './containerlease/containerlease.component';
import { NewcontainerleaseComponent } from './containerlease/newcontainerlease/newcontainerlease.component';
import { ContainerleasedetailsComponent } from './containerlease/containerleasedetails/containerleasedetails.component';
// tslint:disable-next-line: max-line-length
import { ContainersalesreleaseunitComponent } from './tradings/containersales/containersalesreleaseunit/containersalesreleaseunit.component';
import { ContainersalesdetailsComponent } from './tradings/containersales/containersalesdetails/containersalesdetails.component';
import { AccountsdashboardComponent } from './accounts/accountsdashboard/accountsdashboard.component';
// tslint:disable-next-line:max-line-length
import { ContainerpurchaseexpensedetailsComponent } from './accounts/containerpurchaseexpense/containerpurchaseexpensedetails/containerpurchaseexpensedetails.component';
import { ViewsaledcontainerComponent } from './tradings/containersales/viewsaledcontainer/viewsaledcontainer.component';
import { IncomeComponent } from './accounts/containersalesincome/income/income.component';
import { EdittaxesComponent } from './master/taxes/edittaxes/edittaxes.component';
import { EditcontainerpurchaseComponent } from './tradings/containerpurchase/editcontainerpurchase/editcontainerpurchase.component';
import { ContainercompanyComponent } from './admin/containercompany/containercompany.component';
import { TradecontainersearchComponent } from './reports/tradecontainersearch/tradecontainersearch.component';
import { PurchasebysupplierreportComponent } from './reports/purchasebysupplierreport/purchasebysupplierreport.component';
import { SalesbycustomerreportComponent } from './reports/salesbycustomerreport/salesbycustomerreport.component';
import { EditcontainercustomerComponent } from './admin/customers/editcontainercustomer/editcontainercustomer.component';
import { EditcontainersalesComponent } from './tradings/containersales/editcontainersales/editcontainersales.component';
import { EditcontainerinfoComponent } from './tradings/containerpurchase/editcontainerinfo/editcontainerinfo.component';
import { PresalesComponent } from './tradings/presales/presales.component';
import { NewpresalesComponent } from './tradings/presales/newpresales/newpresales.component';
import { EditpresalesComponent } from './tradings/presales/editpresales/editpresales.component';
import { PresalesdetailsComponent } from './tradings/presales/presalesdetails/presalesdetails.component';
import { ContainerleasehistoryComponent } from './containerlease/containerleasehistory/containerleasehistory.component';
import { ContainerleasehistorydetailsComponent } from './containerlease/containerleasehistorydetails/containerleasehistorydetails.component';
import { YearlysalesreportsComponent } from './reports/sales/yearlysalesreports/yearlysalesreports.component';
import { CntyearlypurchasereportsComponent } from './reports/purchase/cntyearlypurchasereports/cntyearlypurchasereports.component';
import { TrademyboxpricingComponent } from './trademyboxpricing/trademyboxpricing.component';
import { ContainerstackchangeComponent } from './tradings/containerpurchase/containerstackchange/containerstackchange.component';
import { ContainersalesbyhaulierComponent } from './tradings/containerpurchase/containersalesbyhaulier/containersalesbyhaulier.component';
import { AccountspaymodeComponent } from './accounts/accountspaymode/accountspaymode.component';
import { NewaccountspaymentmodeComponent } from './accounts/accountspaymode/newaccountspaymentmode/newaccountspaymentmode.component';

export const containerRoutes: Routes = [
  {
    path: 'containers',
    component: ContainerlayoutComponent,
    children: [
      { path: '', component: ContainersComponent },
      { path: 'tradingdashboard', component: TradingsComponent },
      { path: 'containerpurchase', component: ContainerpurchaseComponent },
      { path: 'containerpurchasepayment/:id', component: ContainerpurchasepaymentComponent },
      { path: 'newcontainerpurchase', component: NewcontainerpurchaseComponent },
      { path: 'editcontainerpurchase/:id', component: EditcontainerpurchaseComponent },
      { path: 'containersales', component: ContainersalesComponent },
      { path: 'newcontainersales', component: NewcontainersalesComponent },
      { path: 'editcontainersales/:id', component: EditcontainersalesComponent },
      { path: 'employees', component: EmployeeComponent },
      { path: 'newemployee', component: NewemployeeComponent },
      { path: 'customers', component: CustomersComponent },
      { path: 'newcustomer', component: NewcustomerComponent },
      { path: 'editcontainercustomer/:id', component: EditcontainercustomerComponent },
      { path: 'suppliers', component: VendorsComponent },
      { path: 'newsupplier', component: NewvendorComponent },
      { path: 'containertype', component: ContainertypeComponent },
      { path: 'newcontainertype', component: NewcontainertypeComponent },
      { path: 'containerpurchaselocation', component: PurchaselocationComponent },
      { path: 'newcontainerpurchaselocation', component: NewpurchaselocationComponent },
      { path: 'containerpurchasedetails/:id', component: ContainerpurchasedetailsComponent },
      { path: 'purchaselocationtype', component: LocationtypeComponent },
      { path: 'salesincome', component: ContainersalesincomeComponent },
      { path: 'purchaseexpense', component: ContainerpurchaseexpenseComponent },
      { path: 'taxes', component: TaxesComponent },
      { path: 'newtax', component: NewtaxesComponent },
      { path: 'edittaxes/:id', component: EdittaxesComponent },
      { path: 'taxagencies', component: TaxagenciesComponent },
      { path: 'newtaxagency', component: NewtaxagencyComponent },
      { path: 'buycontainers', component: BuycontainersComponent },
      { path: 'movecontainers', component: MovecontainersComponent },
      { path: 'movemycontainer', component: NewmovecontainersComponent },
      { path: 'onewaymovedetails/:id', component: OnewaymovedetailsComponent },
      { path: 'onewaymoverequest/:id', component: OnewaymoverequestComponent },
      { path: 'continerleasing', component: ContainerleaseComponent },
      { path: 'newcontinerleasing', component: NewcontainerleaseComponent },
      { path: 'continerleasingdetails/:id', component: ContainerleasedetailsComponent },
      { path: 'containerSalesReleaseUnit/:id', component: ContainersalesreleaseunitComponent },
      { path: 'salesdetails/:id', component: ContainersalesdetailsComponent },
      { path: 'accountsdashboard', component: AccountsdashboardComponent },
      { path: 'purchaseexpensedetails/:id', component: ContainerpurchaseexpensedetailsComponent },
      { path: 'viewpurchasecontainers/:typ' , component: ViewpurchasecontainersComponent},
      { path: 'Viewsaledcontainer' , component: ViewsaledcontainerComponent},
      { path: 'income/:id', component: IncomeComponent },
      { path: 'containercompany' , component: ContainercompanyComponent},
      { path: 'tradecontainersearch/:searchtext' , component: TradecontainersearchComponent},
      { path: 'purchasereportbysupplier' , component: PurchasebysupplierreportComponent},
      { path: 'salesreportbycustomer' , component: SalesbycustomerreportComponent},
      { path: 'editcontainerinfo/:id' , component: EditcontainerinfoComponent},
      { path: 'presales' , component: PresalesComponent},
      { path: 'newpresale' , component: NewpresalesComponent},
      { path: 'editpresale/:id' , component: EditpresalesComponent},
      { path: 'presalesdetails/:id' , component: PresalesdetailsComponent},
      { path: 'containerleasinghistory' , component: ContainerleasehistoryComponent},
      { path: 'containerleasinghistorydetails/:id' , component: ContainerleasehistorydetailsComponent},
      { path: 'containeryearlysalesstatistics' , component: YearlysalesreportsComponent},
      { path: 'containeryearlypurchasestatistics' , component: CntyearlypurchasereportsComponent},
      { path: 'trademyboxpricing' , component: TrademyboxpricingComponent},
      { path: 'containerstachchange/:id' , component: ContainerstackchangeComponent},
      { path: 'containersalesbuhaulier/:id' , component: ContainersalesbyhaulierComponent},
      { path: 'accountspaymode' , component: AccountspaymodeComponent},
      { path: 'newaccountspaymode' , component: NewaccountspaymentmodeComponent},
    ]

  }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,RouterModule.forRoot(containerRoutes)
  ],
  exports: [RouterModule]
})
export class ContainersRouterModule { }
