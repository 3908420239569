import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blsearchoffline',
  templateUrl: './blsearchoffline.component.html',
  styleUrls: ['./blsearchoffline.component.css']
})
export class BlsearchofflineComponent implements OnInit {

   blnumber: string;

  constructor(private router: Router) { }

  ngOnInit() {
    // this.tbcontainer = new TrackBoxBooking();
  }

   onKeydown(event) {
        if (event.key === 'Enter') {
            this.router.navigate(['blsearchresultoffline', this.blnumber]);
        }
    }

}
