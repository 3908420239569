import { CompanyPortDepot } from 'src/app/model/companyportdepot.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { Company } from 'src/app/model/company.model';
import { Component, OnInit } from '@angular/core';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TbagentdepotService } from './tbagentdepot.service';

@Component({
  selector: 'app-tbagentdepot',
  templateUrl: './tbagentdepot.component.html',
  styleUrls: ['./tbagentdepot.component.css']
})
export class TbagentdepotComponent implements OnInit {
  masterDepotList: MasterPortsDepot[];
panelOpenState: boolean;
agentCountryId: Company;
depot:MasterPortsDepot;
addmanagedDepot: CompanyPortDepot;
p = 1;

  constructor(private tbagentdepotService: TbagentdepotService, private router: Router , private route: ActivatedRoute) { }

  ngOnInit() {
    this.depot = new MasterPortsDepot();
this.addmanagedDepot = new CompanyPortDepot();
this.addmanagedDepot.portId = new MasterPortsDepot();
    this.tbagentdepotService.getAllMyDepots()
      .subscribe(data => {
        this.masterDepotList = data;
        console.log ( this.masterDepotList);
        // alert(JSON.stringify(this.masterDepotList));
      },
        error => {
          this.router.navigate(['']);
        });

  // this.tbagentdepotService.getAgentCountry()
  //     .subscribe(data => {
  //       this.agentCountryId = data;
  //       console.log ( this.agentCountryId);
  //       alert(JSON.stringify(this.agentCountryId.city));
  //     },
  //       error => {
  //         this.router.navigate(['']);
  //       });
  }

  addDepot(id) {
    this.addmanagedDepot.portId.id = id;
    this.tbagentdepotService.saveManagedDepot(this.addmanagedDepot)
      .subscribe(data => {
        this.router.navigate(['tbagentmode/tbagentmanageddepots']);
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
