import { Candidates } from './candidate.model';
export class CandidateEducation {
    id: number;
    candidate: Candidates;
    school: string;
    qualification: string;
    fieldOfStudy: string;
    startDate: string;
    endDate: string;
    major: string;
    additionalInformation: string;
    checkCurrentStudy: string;
    university: string;
  eduDiv: false;
}
