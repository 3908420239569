import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackerDepartment } from 'src/app/model/trackerdepartment.model';

@Injectable({
  providedIn: 'root'
})
export class NewtrackerdepartmentService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackermaster/saveTrackerDepartment.do';

  public saveTrackerDepartment(trackerdepartment: TrackerDepartment) {
    return this.http.post<string>(this.appUrl, trackerdepartment);
  }

}
