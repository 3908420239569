import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentupdatebookingequipmentService {

constructor(private http: HttpClient) { }

private appUrlupdateBookingEquipment = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/updateBookingEquipment.do';

public updatebookingequipment(contStat: TrackBoxBookingContainers) {
    //alert(JSON.stringify(contStat));
   return this.http.post<string>(this.appUrlupdateBookingEquipment , contStat);
 }

}
