import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule, MatDatepickerModule, MatNativeDateModule, MatButtonModule, MatSidenavModule,
  MatIconModule, MatListModule, MatGridListModule, MatCardModule, MatMenuModule, MatTabsModule,
  MatTableModule, MatRadioModule, MatPaginatorModule, MatCheckboxModule, MatSortModule, MatSelectModule,
  MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatDialogModule, MatSliderModule, MatBadgeModule,
  MatExpansionModule, MatAutocompleteModule, MatBottomSheetModule, MatSlideToggleModule } from '@angular/material';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { trackerRoutes } from './tracker.router';
import { TrackeremployeesComponent } from './trackeremployees/trackeremployees.component';
import { NewtrackeremployeeComponent } from './trackeremployees/newtrackeremployee/newtrackeremployee.component';
import { EdittrackeremployeeComponent } from './trackeremployees/edittrackeremployee/edittrackeremployee.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { TrackerpropertyComponent } from './trackerproperty/trackerproperty.component';
import { NewtrackerpropertyComponent } from './trackerproperty/newtrackerproperty/newtrackerproperty.component';
import { EdittrackerpropertyComponent } from './trackerproperty/edittrackerproperty/edittrackerproperty.component';
import { TrackeremployeepropertyComponent } from './trackeremployeeproperty/trackeremployeeproperty.component';
import { TrackeremployeepropertydetailsComponent } from './trackeremployeeproperty/trackeremployeepropertydetails/trackeremployeepropertydetails.component';
import { AddtrackeremployeepropertyComponent } from './trackeremployeeproperty/addtrackeremployeeproperty/addtrackeremployeeproperty.component';
import { TrackerdepartmentComponent } from './master/trackerdepartment/trackerdepartment.component';
import { TrackerdesignationComponent } from './master/trackerdesignation/trackerdesignation.component';
import { NewtrackerdepartmentComponent } from './master/trackerdepartment/newtrackerdepartment/newtrackerdepartment.component';
import { EdittrackerdepartmentComponent } from './master/trackerdepartment/edittrackerdepartment/edittrackerdepartment.component';
import { NewtrackerdesignationComponent } from './master/trackerdesignation/newtrackerdesignation/newtrackerdesignation.component';
import { EdittrackerdesignationComponent } from './master/trackerdesignation/edittrackerdesignation/edittrackerdesignation.component';
import { TrackeremployeedetailsComponent } from './trackeremployees/trackeremployeedetails/trackeremployeedetails.component';



@NgModule({
  declarations: [TrackeremployeesComponent, NewtrackeremployeeComponent, EdittrackeremployeeComponent, TrackerpropertyComponent, NewtrackerpropertyComponent, EdittrackerpropertyComponent, TrackeremployeepropertyComponent, TrackeremployeepropertydetailsComponent, AddtrackeremployeepropertyComponent, TrackerdepartmentComponent, TrackerdesignationComponent, NewtrackerdepartmentComponent, EdittrackerdepartmentComponent, NewtrackerdesignationComponent, EdittrackerdesignationComponent, TrackeremployeedetailsComponent],
  imports: [
    CommonModule, RouterModule.forChild(trackerRoutes) , LayoutModule, FlexLayoutModule,
     MatToolbarModule, MatDatepickerModule , MatNativeDateModule,
    MatButtonModule, MatSidenavModule,
    MatIconModule , MatListModule, MatProgressSpinnerModule,
    MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatFormFieldModule, MatInputModule, MatSelectModule, MatCheckboxModule,
    MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
    MatBottomSheetModule, MatAutocompleteModule,
    MatSlideToggleModule, FormsModule, FlexLayoutModule, NgxPaginationModule,
    ReactiveFormsModule, NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule
  ]
})
export class TrackerModule { }
