import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-containersearchlayout',
  templateUrl: './containersearchlayout.component.html',
  styleUrls: ['./containersearchlayout.component.css']
})
export class ContainersearchlayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
