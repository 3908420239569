import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { ContainertypeService } from '../master/containertype/containertype.service';
import { SearchbycontainertypeService } from './searchbycontainertype.service';


@Component({
  selector: 'app-searchbycontainertype',
  templateUrl: './searchbycontainertype.component.html',
  styleUrls: ['./searchbycontainertype.component.css']
})
export class SearchbycontainertypeComponent implements OnInit {
  purchase: ContainerPurchase;
  conttypeList: ContainerType[];
  sales: ContainerSales;
  myControlContainerType = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionsType: Observable<ContainerType[]>;
  purchaseList: ContainerPurchase[];
  salesList: ContainerSales[];
  conType: ContainerType;
  salesConType: ContainerType;

  constructor(private searchbycontainertypeService: SearchbycontainertypeService , 
    private router: Router, private containertypeService: ContainertypeService) { }

  ngOnInit() {
    this.purchase = new ContainerPurchase();
    this.purchase.containerType = new ContainerType();
    this.conType = new ContainerType();
    this.salesConType = new ContainerType();
    this.sales = new ContainerSales();
    this.sales.type = new ContainerType();

    this.containertypeService.findAllContainerTypeByOrder()
      .subscribe(data => {
        this.conttypeList = data;
        this.filteredOptionsType = this.myControlContainerType.valueChanges.pipe(
          map(val => this._filtersType(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
  }

  findPurchaseByType() {
    this.purchase.containerType.id = this.conType.id;
    this.searchbycontainertypeService.findPurchaseByType(this.purchase)
      .subscribe(data => {
        this.purchaseList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  findSalesByType() {
    this.sales.type.id = this.salesConType.id;
    this.searchbycontainertypeService.findSalesByType(this.sales)
      .subscribe(data => {
        this.salesList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  public _filtersType(value: string): ContainerType[] {
    const filterValuecust = value;
    return this.conttypeList.filter(conttypeList => conttypeList.eqType.toLowerCase().indexOf(filterValuecust) === 0 ||
    conttypeList.eqType.toLowerCase().indexOf(filterValuecust) === 0 ||
    conttypeList.eqType.toUpperCase().indexOf(filterValuecust) === 0);
  }

  public displayCustomer(cntr: ContainerType): string {
    if (cntr && cntr.eqType) {
      return cntr.eqType;
    }
    }

}

export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}