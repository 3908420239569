import { DeliveryorderdateupdateComponent } from './../deliveryorderdateupdate/deliveryorderdateupdate.component';
import { TbagentdoviewService } from './tbagentdoview.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

@Component({
  selector: 'app-tbagentdoview',
  templateUrl: './tbagentdoview.component.html',
  styleUrls: ['./tbagentdoview.component.css']
})
export class TbagentdoviewComponent implements OnInit {

  bookingId: number;
  doview: TrackBoxBooking;
  constructor(private tbagentdoviewService: TbagentdoviewService, private router: Router,
    public dialog: MatDialog, private route: ActivatedRoute) { }

  ngOnInit() {
    this.bookingId = this.route.snapshot.params.id;
    this.tbagentdoviewService.getDOviewByID(this.route.snapshot.params.id)
          .subscribe(data1 => {
            this.doview = data1;
            // alert(JSON.stringify(this.doview))
          },
            error => {
              this.router.navigate(['']);
            });




  }
  doUpdate(bookingId): void {
    //  alert(bookingId);
      const dialogoutRef = this.dialog.open(DeliveryorderdateupdateComponent, {
        width: '550px',
        data: { bookingId }
      });
      dialogoutRef.afterClosed().subscribe(result => {
        // this.tbagentblviewService.getBookingsDetailsByID(this.route.snapshot.params.id)
        //     .subscribe(data1 => {
        //       this.trackboxbooking = data1;
        //     },
        //       error => {
        //         this.router.navigate(['']);
        //       });
      });
    }

}
