export class SessionCandidate {
    id: number;
    firstName: string;
    lastName: string;
    designation: string;
    companyName: string;
    loggedInTime: Date;
    connectionCount: number;
    followingCount: number;
    emailAddress: string;
    verified: string;
    sessionId: string;
    firstFeed: string;
    newMessageCount: number;
    newConnectionCount: number;
    cartCount: number;
    status: boolean;
    message: string;
    company: number;
    containerCompany: number;
    trackmyboxStatus: string;
    trackmyboxCompany: number;
    trackmyboxCompanyName: string;
    adminStatus: string;
    tradingStatus: boolean;
    tradingCompany: number;
    companyCostCalculator: boolean;
    costCalculatorCompanyId: number;
    candidateCostCalculator: boolean;
}
