import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationtypeService } from './locationtype.service';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';

@Component({
  selector: 'app-locationtype',
  templateUrl: './locationtype.component.html',
  styleUrls: ['./locationtype.component.css']
})
export class LocationtypeComponent implements OnInit {
  containerLocationType: ContainerLocationType[];
  p = 1;

  constructor(private locationtypeService: LocationtypeService, private router: Router) { }

  ngOnInit() {
    this.locationtypeService.findAllContainerLocationType()
      .subscribe(data => {
        this.containerLocationType = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
