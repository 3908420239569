import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offlinelayout',
  templateUrl: './offlinelayout.component.html',
  styleUrls: ['./offlinelayout.component.css']
})
export class OfflinelayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
