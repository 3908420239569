import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-initialinventory',
  templateUrl: './initialinventory.component.html',
  styleUrls: ['./initialinventory.component.css']
})
export class InitialinventoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
