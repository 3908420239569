import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsPaymentMode } from 'src/app/model/accountspaymentmode.model';
import { Suppliers } from 'src/app/model/suppliers.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewexpensesService {

  constructor(private http: HttpClient) { }

   private appUrl = ShipStreetProps.API_PATH + 'supplier/getAllSuppliers.do';
   private payAccUrl = ShipStreetProps.API_PATH + 'paymentaccount/getAllPaymentAccounts.do';
  // private payModUrl = DepotProps.API_PATH + 'paymentmode/getAllPaymentModes.do';

  public getAllSuppliers() {
    return this.http.get<Suppliers[]>(this.appUrl );
  }

  // public getAllPaymentAccounts() {
  //   return this.http.get<AccountsPaymentAccount[]>(this.payAccUrl );
  // }

  // public getAllPaymentModes() {
  //   return this.http.get<AccountsPaymentMode[]>(this.payModUrl );
  // }

}
