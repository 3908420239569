import { CandidateExperience } from '../../model/candidateExperience.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {

  private expUrl = ShipStreetProps.API_PATH + 'candidateExperience.do';
  private saveUrl = ShipStreetProps.API_PATH + 'newExperience.do';
  private updateUrl = ShipStreetProps.API_PATH + 'updateCandidateExperience.do';
  private deleteUrl = ShipStreetProps.API_PATH + 'deleteCandidateExperience.do';

  constructor(private http: HttpClient) { }

  public candidateExperience() {
    return this.http.get<CandidateExperience[]>(this.expUrl);
  }
  public saveType(experience: CandidateExperience) {
    return this.http.post<CandidateExperience[]>(this.saveUrl, experience);
  }
  public updateExperience(experience: CandidateExperience) {
    return this.http.post<string>(this.updateUrl, experience);
  }
  public deleteExperience(experience: CandidateExperience) {
    return this.http.post<CandidateExperience[]>(this.deleteUrl, experience);
  }
}
