import { SearchboxagentService } from './searchboxagent.service';
import { Component, OnInit } from '@angular/core';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { Company } from 'src/app/model/company.model';
import { Observable } from 'rxjs/internal/Observable';
import { FormControl } from '@angular/forms';
import { NewboxagentService } from '../newboxagent/newboxagent.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { TrackBoxAgentSearchDTO } from 'src/app/model/trackboxagentsearchDTO.model';
import { Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-searchboxagent',
  templateUrl: './searchboxagent.component.html',
  styleUrls: ['./searchboxagent.component.css']
})
export class SearchboxagentComponent implements OnInit {

  checked = false;

  noRecordsMsg = '';
  p: number;
  newboxagent: TrackBoxAgent;
  countryList: CountryInfo[];
  countryid: CountryInfo;
  companyList: Company[];
  companies: Company;
  newtrackboxagent: TrackBoxAgentSearchDTO;
  filteredOptionsContry: Observable<CountryInfo[]>;
  myControl1 = new FormControl('', [Validators.required, RequireMatch]);


  // filteredOptionsCompany: Observable<Company[]>;
  myControl2 = new FormControl();

  constructor(private newboxagentService: NewboxagentService, private router: Router,
              private searchboxagentService: SearchboxagentService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.newtrackboxagent = new TrackBoxAgentSearchDTO();
    // this.newtrackboxagent.company = new Company();
    this.newtrackboxagent.country = new CountryInfo();
    this.newboxagent = new TrackBoxAgent();



    this.newboxagentService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    // this.newboxagentService.viewCompany()
    //   .subscribe(data => {
    //     this.companyList = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    this.filteredOptionsContry = this.myControl1.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter1(val))
    );

    // this.filteredOptionsCompany = this.myControl2.valueChanges.pipe(
    //   // startWith(''),
    //   map(val => this._filter2(val))
    // );
  }

  public _filter1(value: string): CountryInfo[] {
    const filterValue1 = value;
    return this.countryList.filter(country => country.name.toLowerCase().indexOf(filterValue1) === 0 ||
      country.name.toLowerCase().indexOf(filterValue1) === 0 ||
      country.name.toUpperCase().indexOf(filterValue1) === 0);
  }

  // public _filter2(value: string): Company[] {
  //   const filterValue2 = value;
  //   return this.companyList.filter(company => company.companyName.toLowerCase().indexOf(filterValue2) === 0 ||
  //     company.companyName.toLowerCase().indexOf(filterValue2) === 0 ||
  //     company.companyName.toUpperCase().indexOf(filterValue2) === 0);
  // }

  displayCountry(cntr: CountryInfo): string {
    return cntr && cntr.name ? cntr.name + ' - ' + cntr.code2 : '';
  }

  // displayFn(user: Company): string {
  //   return user && user.companyName ? user.companyName : '';
  // }

   addAgent(id) {
    this.searchboxagentService.saveTrackBoxAgent(id)
      .subscribe(data => {
        this.router.navigate(['trackmybox/boxagents']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  findAgentCompany() {
    this.searchboxagentService.findAgentCompany(this.newtrackboxagent)
      .subscribe(data => {
        this.companyList = data;
        // alert(JSON.stringify(this.companyList));
        // this.router.navigate(['trackmybox/searchboxagent']);
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}
