import { Marketplace } from '../../model/marketplace.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {


  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'getAllMarketplaceItems.do';
  private addcartUrl = ShipStreetProps.API_PATH + 'addToShoppingCart.do';
  private vieCountUrl = ShipStreetProps.API_PATH + 'viewMarketplaceDetails.do';

  public getAllMarketplaceItems() {
    return this.http.get<Marketplace[]>(this.appUrl);
  }

   public addtoshoppingcart(itemId: string) {
    return this.http.post<Marketplace[]>(this.addcartUrl , itemId);
  }

   public viewmarketdetails(itemId: string) {
    return this.http.post<Marketplace[]>(this.vieCountUrl , itemId);
  }
}
