import { Marketplace } from '../../../model/marketplace.model';
import { MarketplaceReview } from '../../../model/marketplacereview.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class MarketplacedetailsService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'getmarketplace.do';
  private marketplaceUrl = ShipStreetProps.API_PATH + 'marketplaceReviews.do';
  private saveReviewUrl = ShipStreetProps.API_PATH + 'saveMarketplacereview.do';

  public getmarketplace(marketId: string) {
    return this.http.post<Marketplace>(this.appUrl , marketId);
  }
  public getMarketplaceReviews(marketId: string) {
    return this.http.post<MarketplaceReview[]>(this.marketplaceUrl , marketId);
  }
  public saveMarketplaceReview(newreviews: MarketplaceReview) {// alert(JSON.stringify(newreviews));
    return this.http.post<string>(this.saveReviewUrl , newreviews);
  }
}
