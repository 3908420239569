import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { EditpostService } from './editpost.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Posts } from '../../model/posts.model';
import { UpdatepostdialogComponent } from './updatepostdialog/updatepostdialog.component';
@Component({
  selector: 'app-editpost',
  templateUrl: './editpost.component.html',
  styleUrls: ['./editpost.component.css']
})
export class EditpostComponent implements OnInit {
  postId: string;
  post: Posts;
  showimg: boolean;
  constructor(private editpostService: EditpostService, private router: Router, private route: ActivatedRoute,  public dialog: MatDialog) {}
  selectedFile: File;
  previewFile: any;
  loading: boolean;
  isDisabled: boolean;

  updateDialog(): void {
    const dialogRef = this.dialog.open(UpdatepostdialogComponent, {
      panelClass: 'my-centered-dialog',
      width: '450px',
    });
  dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  onFileChanged(event) {

    if (event.target) {
      var reader = new FileReader();
      this.selectedFile = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event1: any) => { // called once readAsDataURL is completed
       // console.log("Read As Data UR is Completed :"+ event)
        this.previewFile = event1.target.result;
      };
    }

  }

  ngOnInit() {
    this.isDisabled = false;
    this.post = new Posts;
    this.postId = this.route.snapshot.paramMap.get('id');
    this.editpostService.getAPost(this.postId)
      .subscribe( data => {
        this.post = data;

      },
      error => {
        this.router.navigate(['']);
        });
  }
  updatePost() {
   // const Data = new FormData();
   // Data.append('file', this.selectedFile);
    // this.editpostService.postCoverImageUpload(Data).subscribe( data => {
    // this.post.coverImage = data.fileName;
    this.loading = true;
    this.isDisabled = false;
    this.editpostService.updatePost(this.post)
      .subscribe( data1 => {
        this.loading = false;
        this.updateDialog();
        this.router.navigate(['/m/viewposts']);
      },
      error => {
        this.router.navigate(['']);
        });
     // });
  }

}
