import { Component, OnInit } from '@angular/core';
import { CompanyVessels } from 'src/app/model/companyvessels.model';
import { TbagentvesselsService } from './tbagentvessels.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tbagentvessels',
  templateUrl: './tbagentvessels.component.html',
  styleUrls: ['./tbagentvessels.component.css']
})
export class TbagentvesselsComponent implements OnInit {

  companyvesselList: CompanyVessels[];
  p: number;
  constructor(private tbagentvesselsService: TbagentvesselsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.tbagentvesselsService.getAllMyCompanyVessels()
      .subscribe(data => {
        this.companyvesselList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
