import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ContainerReleaseUnitDTO } from 'src/app/model/containerreleaseunitDTO.model';

@Injectable({
  providedIn: 'root'
})
export class ContainersalesreleaseunitService {

  private listUrl = ShipStreetProps.API_PATH + 'containerpurchasedunits/getavailablesalesunits';
  private orderlistUrl = ShipStreetProps.API_PATH + 'containersales/addContainertoSales';

  constructor(private http: HttpClient) { }

  public getavailablesalesunits(salesid: string) {
    return this.http.post<ContainerPurchasedUnits[]>(this.listUrl, salesid);
  }

  public addContainertoSales(saledtounit: ContainerReleaseUnitDTO) {
    return this.http.post<string>(this.orderlistUrl , saledtounit);
  }
}
