import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'src/app/model/company.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewboxagentService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/saveTrackBoxAgent.do';
  private findUrl = ShipStreetProps.API_PATH + 'trackboxagent/findAllAgentCompany.do';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';
  private appUrlcompany = ShipStreetProps.API_PATH + '/getAllCompanies.do';

  public saveTrackBoxAgent(trackboxagent: TrackBoxAgent) {
    return this.http.post<TrackBoxAgent>(this.appUrl, trackboxagent);
  }

  public findAgentCompany(trackboxagent: TrackBoxAgent) {
    return this.http.post<Company[]>(this.findUrl, trackboxagent);
  }

  viewCountry() {
    return this.http.get<CountryInfo[]>(this.appUrls);
  }

  viewCompany() {
    return this.http.get<Company[]>(this.appUrlcompany);
  }

}
