import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Suppliers } from 'src/app/model/suppliers.model';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'supplier/getAllSuppliers.do';

  public getAllSuppliers() {
    return this.http.get<Suppliers[]>(this.appUrl );
  }
}
