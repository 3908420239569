import { CompanyVoyage } from './../../../model/companyvoyage.model';
import { MasterVessels } from 'src/app/model/mastervessels.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NewbookingService } from './newbooking.service';
import { TrackBoxBooking } from './../../../model/trackboxbooking.model';
import { Component, OnInit } from '@angular/core';
import { CompanyVessels } from 'src/app/model/companyvessels.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';
import { Validators } from '@angular/forms';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { TrackboxcustomersService } from '../../admin/trackboxcustomers/trackboxcustomers.service';
@Component({
  selector: 'app-newbooking',
  templateUrl: './newbooking.component.html',
  styleUrls: ['./newbooking.component.css']
})
export class NewbookingComponent implements OnInit {
  trackboxbooking: TrackBoxBooking;
  vessels: CompanyVessels[];
  mastervessels: MasterVessels;
  companyvoyage: CompanyVoyage[];
  voyages: CompanyVoyage;
  trackboxCustomersList: TrackBoxCustomers[];
  // ogcList = ['true' , 'false'];
  ogcList = [{ key: 'Yes', value: 'true' }, { key: 'No', value: 'false' }];
  portList: MasterPortsDepot[];
  myControl1 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionsvessel: Observable<CompanyVessels[]>;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionsports: Observable<MasterPortsDepot[]>;
  myControl2 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionspod: Observable<MasterPortsDepot[]>;
  myControlcustomer = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscustomer: Observable<TrackBoxCustomers[]>;
  constructor(private newbookingService: NewbookingService, private router: Router,
     private route: ActivatedRoute, private trackboxcustomersService: TrackboxcustomersService) { }

  ngOnInit() {
    this.trackboxbooking = new TrackBoxBooking();
    this.trackboxbooking.vessel = new CompanyVessels();
    this.trackboxbooking.pod = new MasterPortsDepot();
    this.trackboxbooking.pol = new MasterPortsDepot();

    this.newbookingService.getAllMasterVessel()
      .subscribe(data => {
        this.vessels = data;

        this.filteredOptionsvessel = this.myControl1.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );

        console.log(this.filteredOptionsvessel);
      },
        error => {
          this.router.navigate(['']);
        });

    this.newbookingService.viewPort()
      .subscribe(data => {
        this.portList = data;
        this.filteredOptionsports = this.myControl3.valueChanges.pipe(
          map(val => this._filter1(val))
        );

       // console.log(this.filteredOptionsports);
      },


        error => {
          this.router.navigate(['']);
        });

        this.filteredOptionspod = this.myControl2.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter2(val))
        );

      //  console.log(this.filteredOptionspod);

    // this.newbookingService.getAllVoyages()
    //   .subscribe(data => {
    //     this.companyvoyage = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });
    this.trackboxcustomersService.getAllMyCustomers()
    .subscribe(data => {
      this.trackboxCustomersList = data;
      // alert(JSON.stringify(this.trackboxsupplierList));
      this.filteredOptionscustomer = this.myControlcustomer.valueChanges.pipe(
        // startWith(''),
        map(val => this._filtercust(val))
      );

      console.log(this.filteredOptionsports);
    },
      error => {
        this.router.navigate(['']);
      });

  }

  onSubmit() {
    this.newbookingService.save(this.trackboxbooking)
      .subscribe(data => {
        this.router.navigate(['trackmybox/bookings']);
      },
        error => {
          this.router.navigate(['']);
        });

  }
  public _filter4(value: string): CompanyVessels[] {
    const filterValue4 = value;
    return this.vessels.filter(vessel => vessel.masterVessel.name.toLowerCase().indexOf(filterValue4) === 0 ||
    vessel.masterVessel.name.toLowerCase().indexOf(filterValue4) === 0 ||
    vessel.masterVessel.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayVessel(cntr: CompanyVessels): string {
    if (cntr && cntr.masterVessel && cntr.masterVessel.name) {
      return cntr.masterVessel.name ;

    }
    }

    public _filter1(value: string): MasterPortsDepot[] {
      const filterValue1 = value;
      // console.log('=====>>' + filterValue2);

      return this.portList.filter(port => port.name.toLowerCase().indexOf(filterValue1) === 0 ||
       port.name.toUpperCase().indexOf(filterValue1) === 0 ||
       port.portCode.toLowerCase().indexOf(filterValue1) === 0);

    }
    public displayPol(cntr: MasterPortsDepot): string {
      if (cntr && cntr.name) {
        return cntr.name ;

      }
    }

    public _filter2(value: string): MasterPortsDepot[] {
      const filterValue2 = value;
      // console.log('=====>>' + filterValue2);

      return this.portList.filter(port => port.name.toLowerCase().indexOf(filterValue2) === 0 ||
       port.name.toUpperCase().indexOf(filterValue2) === 0 ||
       port.portCode.toLowerCase().indexOf(filterValue2) === 0);

    }
    public displayPod(cntr: MasterPortsDepot): string {
      if (cntr && cntr.name) {
        return cntr.name ;

      }
    }
    public _filtercust(value: string): TrackBoxCustomers[] {
      const filterValuecust = value;
      return this.trackboxCustomersList.filter(trackboxCustomersList => trackboxCustomersList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      trackboxCustomersList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      trackboxCustomersList.name.toUpperCase().indexOf(filterValuecust) === 0);
    }

    public displayCustomer(cntr: TrackBoxCustomers): string {
      if (cntr && cntr.name) {
        return cntr.name ;

      }

      }
}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}
