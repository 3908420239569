import { Component, OnInit } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { Router } from '@angular/router';
// import { PeopleViewed } from '../../model/peopleviewed.model';
import { WhoviewprofileService } from './whoviewprofile.service';
import { PeopleViewed } from 'src/app/model/peopleviewed.model';

@Component({
  selector: 'app-whoviewprofile',
  templateUrl: './whoviewprofile.component.html',
  styleUrls: ['./whoviewprofile.component.css']
})
export class WhoviewprofileComponent implements OnInit {

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  searchText: string;
  peopleviewList: PeopleViewed[];

  constructor( private whoviewprofileservice: WhoviewprofileService, private router: Router ) { }

  ngOnInit() {
     this.whoviewprofileservice.getviewprofilelist()
       .subscribe( data => {
         this.peopleviewList = data;
       },
        error => {
         this.router.navigate(['']);
       });
      }


  }




