import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlUpdateDTO } from 'src/app/model/blupdateDTO.model';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentblupdateService {

  constructor(private http: HttpClient) { }
  private appUrlblupdate = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/updateBLInBooking.do';
  private appUrlcontainer = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/trackboxviewbookedcontainerdetails.do';
  public getbookedContainers(bookingId: number) {
    // alert(JSON.stringify(bookingId));
    return this.http.post<TrackBoxBookingContainers[]>(this.appUrlcontainer, bookingId);
  }
  public updateBLNumber(blupdate: BlUpdateDTO) {
    // alert(JSON.stringify(blupdate));
    return this.http.post<BlUpdateDTO[]>(this.appUrlblupdate, blupdate);

  }

}
