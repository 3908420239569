import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suggestedcompanies',
  templateUrl: './suggestedcompanies.component.html',
  styleUrls: ['./suggestedcompanies.component.css']
})
export class SuggestedcompaniesComponent implements OnInit {

  companies = [
  {'id' :"1" , 'companyName' : 'companyName'}
  ]
  constructor() { }

  ngOnInit() {
  }

}
