import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerOnewayMove } from 'src/app/model/containeronewaymove.model';

@Injectable({
  providedIn: 'root'
})
export class OnewaymovedetailsService {

  private objUrl = ShipStreetProps.API_PATH + 'containeronewaymove/findSingleContainerOnewayMove';

  constructor(private http: HttpClient) { }

  public findSingleContainerOnewayMove(moveid: string) {
    return this.http.post<ContainerOnewayMove>(this.objUrl, moveid);
  }
}
