import { from } from 'rxjs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { tbagentRoutes } from './tbagent.router';
import { TbagentmyboxesComponent } from './tbagentmyboxes/tbagentmyboxes.component';
import { TbagentbookingComponent } from './tbagentbooking/tbagentbooking.component';
import { TbagentequipmentsComponent } from './tbagentequipments/tbagentequipments.component';
import {
  MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
  MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
  MatBottomSheetModule, MatAutocompleteModule, MatDatepickerModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoxprinciplesComponent } from './boxprinciples/boxprinciples.component';
import { ImportbookingComponent } from './tbagentbooking/importbooking/importbooking.component';
import { ExportbookingComponent } from './tbagentbooking/exportbooking/exportbooking.component';
import { NewtbagentbookingComponent } from './tbagentbooking/newtbagentbooking/newtbagentbooking.component';
import { TbagentbookingdetailsComponent } from './tbagentbooking/tbagentbookingdetails/tbagentbookingdetails.component';
import { TbagentaddclearingagentComponent } from './tbagentbooking/tbagentaddclearingagent/tbagentaddclearingagent.component';
import { TbagentaddconsigneeComponent } from './tbagentbooking/tbagentaddconsignee/tbagentaddconsignee.component';
import { EdittbagentbookingComponent } from './tbagentbooking/edittbagentbooking/edittbagentbooking.component';
import { TbagentaddcontainerstobookingComponent } from './tbagentbooking/tbagentaddcontainerstobooking/tbagentaddcontainerstobooking.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";
import { TbagentcustomersComponent } from './tbagentcustomers/tbagentcustomers.component';
import { NewtbagentcustomersComponent } from './tbagentcustomers/newtbagentcustomers/newtbagentcustomers.component';
import { TbagentsuppliersComponent } from './tbagentsuppliers/tbagentsuppliers.component';
import { NewtbagentsuppliersComponent } from './tbagentsuppliers/newtbagentsuppliers/newtbagentsuppliers.component';
import { TbagentcarriersComponent } from './tbagentcarriers/tbagentcarriers.component';
import { NewtbagentcarriersComponent } from './tbagentcarriers/newtbagentcarriers/newtbagentcarriers.component';
import { SearchboxprincipleComponent } from './boxprinciples/searchboxprinciple/searchboxprinciple.component';
import { TbagentupdatecontainerstatuswithbookingComponent } from './tbagentbooking/tbagentupdatecontainerstatuswithbooking/tbagentupdatecontainerstatuswithbooking.component';
import { TbagentrequesttoprincipleComponent } from './tbagentbooking/tbagentrequesttoprinciple/tbagentrequesttoprinciple.component';
import { TbagentviewrequestsComponent } from './tbagentbooking/tbagentviewrequests/tbagentviewrequests.component';
import { TbagentvesseloperatorComponent } from './master/tbagentvesseloperator/tbagentvesseloperator.component';
import { TbagentvesselsComponent } from './master/tbagentvessels/tbagentvessels.component';
import { TbagentnewvesseloperatorComponent } from './master/tbagentvesseloperator/tbagentnewvesseloperator/tbagentnewvesseloperator.component';
import { TbagentnewvesselComponent } from './master/tbagentvessels/tbagentnewvessel/tbagentnewvessel.component';
import { TbagentportsComponent } from './master/tbagentports/tbagentports.component';
import { TbagentnewportComponent } from './master/tbagentports/tbagentnewport/tbagentnewport.component';
import { ViewprinciplerequestComponent } from './boxprinciples/viewprinciplerequest/viewprinciplerequest.component';
import { ContainerreturneddetailsComponent } from './tbagentbooking/tbagentbookingdetails/containerreturneddetails/containerreturneddetails.component';
import { DeliveryorderdateupdateComponent } from './tbagentbooking/tbagentbookingdetails/deliveryorderdateupdate/deliveryorderdateupdate.component';
import { AgentemployeesComponent } from './admin/agentemployees/agentemployees.component';
import { NewagentemployeeComponent } from './admin/agentemployees/newagentemployee/newagentemployee.component';
import { TbagentdepotComponent } from './master/tbagentdepot/tbagentdepot.component';
import { TbagentnewdepotComponent } from './master/tbagentdepot/tbagentnewdepot/tbagentnewdepot.component';
import { TbagentresponsetoprincipleComponent } from './tbagentbooking/tbagentresponsetoprinciple/tbagentresponsetoprinciple.component';
import { TbagentequipmentlistComponent } from './tbagentequipments/tbagentequipmentlist/tbagentequipmentlist.component';
import { TbAgentManagedportListComponent } from './master/tbagentmanagedportList/tbagentmanagedportList.component';
import { TbagentaddmanagedportlistComponent } from './master/tbagentmanagedportList/tbagentaddmanagedportlist/tbagentaddmanagedportlist.component';
import { TbagentmanageddepotsComponent } from './master/tbagentdepot/tbagentmanageddepots/tbagentmanageddepots.component';
import { TbagentblupdateComponent } from './tbagentbooking/tbagentbookingdetails/tbagentblupdate/tbagentblupdate.component';
import { TbagenteditcustomerComponent } from './tbagentcustomers/tbagenteditcustomer/tbagenteditcustomer.component';
import { TbagenteditsupplierComponent } from './tbagentsuppliers/tbagenteditsupplier/tbagenteditsupplier.component';
import { TbagentupdatebookingequipmentComponent } from './tbagentbooking/tbagentupdatebookingequipment/tbagentupdatebookingequipment.component';
import {TbagentblviewComponent} from './tbagentbooking/tbagentbookingdetails/tbagentblview/tbagentblview.component';
import {TbagentdoviewComponent} from './tbagentbooking/tbagentbookingdetails/tbagentdoview/tbagentdoview.component';
import { PrinciplerequestsComponent } from './principlerequests/principlerequests.component';
import { PrinciplerequestdetailsComponent } from './boxprinciples/principlerequestdetails/principlerequestdetails.component';
import { NewboxprincipleComponent } from './boxprinciples/newboxprinciple/newboxprinciple.component';
import { TbagentpricingComponent } from './tbagentpricing/tbagentpricing.component';
import { TbagentsubscriptioncheckoutComponent } from './tbagentsubscriptioncheckout/tbagentsubscriptioncheckout.component';
@NgModule({
  declarations: [TbagentmyboxesComponent, TbagentbookingComponent, TbagentequipmentsComponent,
    BoxprinciplesComponent, NewtbagentbookingComponent, TbagentbookingdetailsComponent, TbagentaddclearingagentComponent,
    ImportbookingComponent, TbagentaddconsigneeComponent, EdittbagentbookingComponent, TbagentaddcontainerstobookingComponent,
    ExportbookingComponent, TbagentcustomersComponent, NewtbagentcustomersComponent, TbagentsuppliersComponent,
    NewtbagentsuppliersComponent, TbagentcarriersComponent, NewtbagentcarriersComponent,
    SearchboxprincipleComponent, TbagentrequesttoprincipleComponent, TbagentviewrequestsComponent,
    TbagentnewportComponent, TbagentnewdepotComponent, TbagentvesseloperatorComponent, TbagentvesselsComponent,
    TbagentnewvesseloperatorComponent, TbagentnewvesselComponent, TbagentportsComponent,
    TbagentupdatecontainerstatuswithbookingComponent, TbagentequipmentlistComponent,
    NewtbagentsuppliersComponent, TbagentcarriersComponent, NewtbagentcarriersComponent,
    TbagentupdatecontainerstatuswithbookingComponent, SearchboxprincipleComponent,
    TbagentrequesttoprincipleComponent, TbagentviewrequestsComponent, ViewprinciplerequestComponent,
    ContainerreturneddetailsComponent, DeliveryorderdateupdateComponent, AgentemployeesComponent,
    NewagentemployeeComponent, TbagentdepotComponent, TbagentresponsetoprincipleComponent,
    NewagentemployeeComponent, TbagentdepotComponent, TbAgentManagedportListComponent, TbagentaddmanagedportlistComponent,
    TbagentmanageddepotsComponent, TbagentblupdateComponent, TbagenteditcustomerComponent,
    TbagenteditsupplierComponent, TbagentupdatebookingequipmentComponent, TbagentblviewComponent, TbagentdoviewComponent,
    PrinciplerequestsComponent,
    PrinciplerequestdetailsComponent,
    NewboxprincipleComponent,
    TbagentpricingComponent,
    TbagentsubscriptioncheckoutComponent],


  imports: [
    CommonModule, RouterModule.forChild(tbagentRoutes),
    MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
    MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
    MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
    MatBottomSheetModule, MatAutocompleteModule, MatDatepickerModule, NgxPaginationModule,
    MatSlideToggleModule, MatTooltipModule, MatProgressSpinnerModule, FormsModule, ReactiveFormsModule, FlexLayoutModule,
    NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule,
  ],
  exports: [PrinciplerequestsComponent],
  entryComponents: [
    TbagentresponsetoprincipleComponent
  ]
})
export class TbagentModule { }
