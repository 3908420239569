import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ContainersalesdetailsService {
  private objUrl = ShipStreetProps.API_PATH + 'containersales/findSingleContainerSales';

  constructor(private http: HttpClient) { }

  public findSingleContainerSales(salesId: string) {
   // alert(JSON.stringify(salesId));
    return this.http.post<ContainerSales>(this.objUrl, salesId);
  }
}
