import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { ConsigneepaymentdetailsService } from './consigneepaymentdetails.service';

@Component({
  selector: 'app-consigneepaymentdetails',
  templateUrl: './consigneepaymentdetails.component.html',
  styleUrls: ['./consigneepaymentdetails.component.css']
})
export class ConsigneepaymentdetailsComponent implements OnInit {

  months: { id: number; name: string; }[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  paymode: string[];
  selPaymode: string;

  constructor(private router: Router, private consigneepaymentdetailsService: ConsigneepaymentdetailsService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.paymode = ['Cash', 'Bank'];
    // this.consigneepaymentdetailsService.getConsigneePaymentDetails(this.route.snapshot.paramMap.get('id'))
    //   .subscribe(data => {
    //     // this.estimateandItem = data;
    //     // this.consigneePaymentDetails = data.estimateItem;
    //     // this.estimate = data.estimate;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });
  }

  netTotal() {
    let total = 0;
    // for (const conPay of this.consigneePaymentDetails) {
    //   if (conPay.cashCollected) {
    //     total += (conPay.approvedMaterialQty * conPay.approvedManHour * conPay.approvedManHourRate +
    //       conPay.approvedMaterialCost * conPay.approvedMaterialQty + conPay.extraAmount);
    //   }

    // }
    return total;
  }

  approveCashCollection() {
    // this.consigneepaymentdetailsService.approveCashCollection(this.estimateandItem)
    //   .subscribe(data => {
    //     this.router.navigate(['/accounts/consigneepaymentapprovals']);
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });
  }


}
