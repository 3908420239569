import { Company } from "./company.model";


export class CalcEquipmentType {
    id: number;
    company: Company;
    eqType: string;
    isoCode: string;
    isoValue: number;
    description: string;
}