import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-network90',
  templateUrl: './network90.component.html',
  styleUrls: ['./network90.component.css']
})
export class Network90Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
