import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { NewtrackboxcustomersService } from 'src/app/trackmybox/admin/trackboxcustomers/newtrackboxcustomers/newtrackboxcustomers.service';
import { NewequipmentcontrolService } from 'src/app/trackmybox/equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { Observable } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-newtbagentcustomers',
  templateUrl: './newtbagentcustomers.component.html',
  styleUrls: ['./newtbagentcustomers.component.css']
})
export class NewtbagentcustomersComponent implements OnInit {
  CountryList: CountryInfo[];
  trackboxcustomers = new TrackBoxCustomers();
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
  constructor(private newtrackboxcustomersService: NewtrackboxcustomersService, private router: Router, private route: ActivatedRoute,
    private newequipmentcontrolService: NewequipmentcontrolService) { }

  ngOnInit() {
    this.trackboxcustomers = new TrackBoxCustomers();
    this.trackboxcustomers.country = new CountryInfo();
    this.newequipmentcontrolService.viewCountry()
  .subscribe(data => {
    this.CountryList = data;
    this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter4(val))
    );

    console.log(this.filteredOptionscountry);
},
    error => {
      this.router.navigate(['']);
    });
  }
  onSubmit() {
    this.newtrackboxcustomersService.save(this.trackboxcustomers)
    .subscribe(data => {
    this.router.navigate(['tbagentmode/tbagentcustomers']);
     },
       error => {
         this.router.navigate(['']);
       });
  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }
  }
}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}
