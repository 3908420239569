import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerLease } from 'src/app/model/containerlease.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerleaseService {
  private listUrl = ShipStreetProps.API_PATH + 'containerlease/findAllContainerLeaseListByCompany';

  constructor(private http: HttpClient) { }

  public findAllContainerLeaseListByCompany() {
    return this.http.get<ContainerLease[]>(this.listUrl);
  }

}
