import { CandidateMessage } from '../../../model/candidateMessage.model';
import { CandidateMessageMessage } from '../../../model/candidateMessageMessage.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CandidatemessageService {

  private appUrl = ShipStreetProps.API_PATH + 'candidateMessageList.do';
  private appViewUrl = ShipStreetProps.API_PATH + 'candidateMessageDetails.do';
  private replyUrl = ShipStreetProps.API_PATH + 'candidateMessageReply.do';
  private readStatusUpdateUrl = ShipStreetProps.API_PATH + 'updateReadStatus.do';
  private deleteUrl = ShipStreetProps.API_PATH + 'candidateMessageDelete.do';
  private unReadUrl = ShipStreetProps.API_PATH + 'updateUnReadStatus.do';

  constructor(private http: HttpClient) { }

  public candidateMessages() {
    return this.http.get<CandidateMessage[]>(this.appUrl);
  }
   public candidateMessagesView(message: string) {
    return this.http.post<CandidateMessageMessage[]>(this.appViewUrl , message);
  }
   public updateReadStatus(message: string) {
    return this.http.post<CandidateMessage[]>(this.readStatusUpdateUrl , message);
  }
   public candidateMessageReply(message: CandidateMessageMessage) {
    return this.http.post<CandidateMessageMessage[]>(this.replyUrl , message);
  }
   public markUnread(messageId: string) {
    return this.http.post<CandidateMessage[]>(this.unReadUrl , messageId);
  }
   public delete(messageId: string) {
    return this.http.post<CandidateMessage[]>(this.deleteUrl , messageId);
  }
}
