import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';

@Injectable({
  providedIn: 'root'
})
export class TbagentvesseloperatorService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/vesseloperator/findAllMasterVesselOperatorByIdForTrackMyBox.do';

  getAllVesselOperator() {
    return this.http.get<MasterVesselOperator[]>(this.appUrl);
  }

}
