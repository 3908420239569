import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerservicelicensesService {

  constructor(private http: HttpClient) { }

  private appListUrl = ShipStreetProps.API_PATH + 'getAllMyContainerCompaniesnotenrolled.do';
  private appUrl = ShipStreetProps.API_PATH + 'enrollcontainercompanyTrading.do';

  public getAllMyContainerCompanies() {
    return this.http.get<CompanyEmployee[]>(this.appListUrl);
  }

  public enrolltrading(companyId: number) {
    // console.log("Data Count :" +JSON.stringify(companyId));
    return this.http.post<string>(this.appUrl , companyId);

  }
}
