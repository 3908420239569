import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditcontainersalesService {

  private saveUrl = ShipStreetProps.API_PATH + 'containersales/findSingleContainerSales';
  private updateUrl = ShipStreetProps.API_PATH + 'containersales/updateContainerSales';
 
  constructor(private http: HttpClient) { }

  public findSingleContainerSales(sales: string) {
    return this.http.post<ContainerSales>(this.saveUrl , sales);
  }

  public updateContainerSales(sales: ContainerSales) {
    return this.http.post<ContainerSales>(this.updateUrl , sales);
  }
  
}
