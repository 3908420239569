import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerType } from 'src/app/model/containertype.model';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { DangarousGoodsClasses } from 'src/app/model/dangarousgoodsclasses.model';
import { DangarousGoodsSubclasses } from 'src/app/model/dangarousgoodssubclasses.model';

@Injectable({
  providedIn: 'root'
})
export class NewtbagentbookingService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/trackboxbookingssave.do';
  private appUrlcontainer = ShipStreetProps.API_PATH + 'containertype/findAllContainerType.do';
  private dgcUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/getAllDGClasses.do';
   private dgsubcUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/getAllDGSubClasses.do';

  public viewcontainerType() {
    return this.http.get<ContainerType[]>(this.appUrlcontainer);

  }
  public save(trackboxbooking: TrackBoxBooking) {
    return this.http.post<TrackBoxBooking>(this.appUrl, trackboxbooking);
  }

  public getAllDGClasses() {
    return this.http.get<DangarousGoodsClasses[]>(this.dgcUrl);
  }

  public getAllDGSubClasses(dgclass: string) {
    return this.http.post<DangarousGoodsSubclasses[]>(this.dgsubcUrl , dgclass);
  }
}
