import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxCarrier } from 'src/app/model/trackboxcarrier.model';
import { TrackboxcarriersService } from '../../admin/trackboxcarriers/trackboxcarriers.service';

@Component({
  selector: 'app-tbagentcarriers',
  templateUrl: './tbagentcarriers.component.html',
  styleUrls: ['./tbagentcarriers.component.css']
})
export class TbagentcarriersComponent implements OnInit {

  trackboxcarrierList: TrackBoxCarrier[];
p: number;
  constructor(private trackboxcarriersService: TrackboxcarriersService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.trackboxcarriersService.getAllMyCarriers()
      .subscribe(data => {
        this.trackboxcarrierList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
