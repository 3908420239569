import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class DashboardsaleslistService {

  private salUrl = ShipStreetProps.API_PATH + 'containersales/findSalesByConType';

  constructor(private http: HttpClient) { }

  public findSalesByType(sales: ContainerSales) {
    return this.http.post<ContainerSales[]>(this.salUrl , sales);
  }
  
}
