import { OffhireequipmentService } from './offhireequipment.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewequipmentcontrolService } from '../newequipmentcontrol/newequipmentcontrol.service';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';


export interface DialogData {
  leaseUnitId: number;
}
@Component({
  selector: 'app-offhireequipment',
  templateUrl: './offhireequipment.component.html',
  styleUrls: ['./offhireequipment.component.css']
})
export class OffhireequipmentComponent implements OnInit {
  trackboxeqpcontainer: TrackBoxContainer;
  CountryList: CountryInfo[];
  supplierList: ContainerSupplier[];
  countryId = 0;
  myControl3 = new FormControl();
  filteredOptionscountry: Observable<CountryInfo[]>;
  constructor(private offhireequipmentService: OffhireequipmentService, private router: Router, private route: ActivatedRoute,
              public dialogoutRef: MatDialogRef<OffhireequipmentComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private snackBar: MatSnackBar, private newequipmentcontrolService: NewequipmentcontrolService) { }

  ngOnInit() {
    this.trackboxeqpcontainer = new TrackBoxContainer();
    this.trackboxeqpcontainer.offHireLocation = new CountryInfo();
    this.trackboxeqpcontainer.offHireTo = new ContainerSupplier();

    // this.offhireequipmentService.getDetails(this.data.leaseUnitId)
    //   .subscribe(data => {
    //     this.trackboxeqpcontainer = data;
    //     // alert(JSON.stringify(this.trackboxeqpcontainer));
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    this.newequipmentcontrolService.getAllMySupplier()
      .subscribe(data => {
        this.supplierList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newequipmentcontrolService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );

        console.log(this.filteredOptionscountry);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onNoClick(): void {
    this.dialogoutRef.close();

  }

  offhireDetailsSubmit() {
    this.trackboxeqpcontainer.id = this.data.leaseUnitId;
    this.offhireequipmentService.offhireSave(this.trackboxeqpcontainer)
      .subscribe(data => {
        this.snackBar.open('OFFHIRED SUCCESSFULLY !!', 'End now', {
          duration: 2000,

        });
        this.dialogoutRef.close(0);
        this.router.navigate(['/trackmybox/equipmentcontroller']);
      },

        error => {
          this.router.navigate(['']);
        });


  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }
  }
}
