import { Injectable } from '@angular/core';
import { PeopleViewed } from '../../model/peopleviewed.model';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class WhoviewprofileService {

  private appUrl = ShipStreetProps.API_PATH + 'getwhosviewingprofile.do';

  constructor(private http: HttpClient) { }

  public getviewprofilelist() {
     return this.http.get<PeopleViewed[]>(this.appUrl);
  }

}
