import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { NewtbagentbookingService } from './newtbagentbooking.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { CompanyVessels } from 'src/app/model/companyvessels.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewbookingService } from 'src/app/trackmybox/bookings/newbooking/newbooking.service';
import { ContainerType } from 'src/app/model/containertype.model';
import { TrackboxcustomersService } from 'src/app/trackmybox/admin/trackboxcustomers/trackboxcustomers.service';
import { AbstractControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DangarousGoodsClasses } from 'src/app/model/dangarousgoodsclasses.model';
import { DangarousGoodsSubclasses } from 'src/app/model/dangarousgoodssubclasses.model';


@Component({
  selector: 'app-newtbagentbooking',
  templateUrl: './newtbagentbooking.component.html',
  styleUrls: ['./newtbagentbooking.component.css']
})
export class NewtbagentbookingComponent implements OnInit {
  trackboxbooking: TrackBoxBooking;
  vessels: CompanyVessels[];
  ContainerTypeList: ContainerType[];
  trackboxCustomersList: TrackBoxCustomers[];
  ogcList = [{ key: 'Yes', value: 'true' }, { key: 'No', value: 'false' }];
  portList: MasterPortsDepot[];
  myControl1 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionsvessel: Observable<CompanyVessels[]>;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  myControl4 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionsports: Observable<MasterPortsDepot[]>;
  myControl2 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionspod: Observable<MasterPortsDepot[]>;
  filteredOptionsfpod: Observable<MasterPortsDepot[]>;
  myControlcustomer = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscustomer: Observable<TrackBoxCustomers[]>;
  dgdivs: boolean;
  dangclasses: DangarousGoodsClasses[];
  dangsubclasses: DangarousGoodsSubclasses[];
  dgsubdivs: boolean;

  constructor(private newtbagentbookingService: NewtbagentbookingService, private router: Router, private route: ActivatedRoute,
    private newbookingService: NewbookingService, private trackboxcustomersService: TrackboxcustomersService) { }

  ngOnInit() {
    this.trackboxbooking = new TrackBoxBooking();
    this.trackboxbooking.vessel = new CompanyVessels();
    this.trackboxbooking.pod = new MasterPortsDepot();
    this.trackboxbooking.pol = new MasterPortsDepot();
    this.trackboxbooking.fpod = new MasterPortsDepot();
    this.trackboxbooking.containerType = new ContainerType();
    this.trackboxbooking.customer = new TrackBoxCustomers();
    this.trackboxbooking.dgClasses = new DangarousGoodsClasses();
    this.trackboxbooking.dgSubalsses = new DangarousGoodsSubclasses();
    this.dgdivs = false;
    this.dgsubdivs = false;


    this.newtbagentbookingService.viewcontainerType()
      .subscribe(data => {
        this.ContainerTypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.newbookingService.getAllMasterVessel()
      .subscribe(data => {
        this.vessels = data;

        this.filteredOptionsvessel = this.myControl1.valueChanges.pipe(
          map(val => this._filter4(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });

    this.newbookingService.viewPort()
      .subscribe(data => {
        this.portList = data;
        this.filteredOptionsports = this.myControl3.valueChanges.pipe(
          map(val => this._filter1(val))
        );
        this.filteredOptionspod = this.myControl2.valueChanges.pipe(
          map(val => this._filter2(val))
        );
        this.filteredOptionsfpod = this.myControl4.valueChanges.pipe(
          map(val => this._filter5(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });




    this.trackboxcustomersService.getAllMyCustomers()
      .subscribe(data => {
        this.trackboxCustomersList = data;
        this.filteredOptionscustomer = this.myControlcustomer.valueChanges.pipe(
          map(val => this._filtercust(val))
        );

        console.log(this.filteredOptionsports);
      },
        error => {
          this.router.navigate(['']);
        });


    this.newtbagentbookingService.getAllDGClasses()
      .subscribe(data => {
        this.dangclasses = data;
      },
        error => {
          this.router.navigate(['']);
        });




  }



  onSubmit() {
    this.newtbagentbookingService.save(this.trackboxbooking)
      .subscribe(data => {
        this.router.navigate(['/tbagentmode/tbagentbookings']);
      },
        error => {
          this.router.navigate(['']);
        });

  }
  public _filter4(value: string): CompanyVessels[] {
    const filterValue4 = value;
    return this.vessels.filter(vessel => vessel.masterVessel.name.toLowerCase().indexOf(filterValue4) === 0 ||
      vessel.masterVessel.name.toLowerCase().indexOf(filterValue4) === 0 ||
      vessel.masterVessel.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayVessel(cntr: CompanyVessels): string {
    if (cntr && cntr.masterVessel && cntr.masterVessel.name) {
      // alert(cntr.masterVessel.name);
      return cntr.masterVessel.name;

    }
  }

  public _filter1(value: string): MasterPortsDepot[] {
    const filterValue1 = value;
    return this.portList.filter(port => port && port.name.toLowerCase().indexOf(filterValue1) === 0 ||
      port && port.name.toUpperCase().indexOf(filterValue1) === 0 ||
      port && port.portCode.toLowerCase().indexOf(filterValue1) === 0);

  }
  public displayPol(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      return cntr.name;

    }
  }

  public _filter2(value: string): MasterPortsDepot[] {
    const filterValue2 = value;
    return this.portList.filter(port => port.name.toLowerCase().indexOf(filterValue2) === 0 ||
      port.name.toUpperCase().indexOf(filterValue2) === 0 ||
      port.portCode.toLowerCase().indexOf(filterValue2) === 0);

  }

  public _filter5(value: string): MasterPortsDepot[] {
    const filterValue5 = value;
    return this.portList.filter(port => port.name.toLowerCase().indexOf(filterValue5) === 0 ||
      port.name.toUpperCase().indexOf(filterValue5) === 0 ||
      port.portCode.toLowerCase().indexOf(filterValue5) === 0);

  }


  public displayPod(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      return cntr.name;

    }
  }

  public displayfPod(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      return cntr.name;

    }
  }

  public _filtercust(value: string): TrackBoxCustomers[] {
    const filterValuecust = value;
    return this.trackboxCustomersList.filter(trackboxCustomersList => trackboxCustomersList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      trackboxCustomersList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      trackboxCustomersList.name.toUpperCase().indexOf(filterValuecust) === 0);
  }

  public displayCustomer(cntr: TrackBoxCustomers): string {
    if (cntr && cntr.name) {
      return cntr.name;

    }

  }

  dgclasses(dgtype) {
    if (dgtype === 'true') {
      this.dgdivs = true;
    } else {
      this.dgdivs = false;
    }
  }

  dgsubclasses(dgclass) {
    // if (dgclass === 'true') {
    // //  this.dgsubdivs = "true";
    // } else {
    //   this.dgsubdivs = false;
    // }
    // alert(dgclass);
    this.newtbagentbookingService.getAllDGSubClasses(dgclass)
      .subscribe(data => {
        this.dangsubclasses = data;
        //     alert(this.dangsubclasses.length)
        if (this.dangsubclasses.length > 0) {
          this.dgsubdivs = true;
        } else {
          this.dgsubdivs = false;
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
// export function forbiddenNamesValidator(name: TrackBoxCustomers[]): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: any } | null => {
//     // alert(name)
//     // below findIndex will check if control.value is equal to one of our options or not
//     const index = name && name.findIndex(nam => {
//       return (new RegExp('\^' + nam + '\$')).test(control.value);
//     });
//     return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
//   };
//   // let result:any = this.TrackBoxCustomers.find(cust => cust.id === this.trackboxbooking.customer.id )
// }


export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}
