import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPurchaseReportDTO } from 'src/app/model/ContainerPurchaseReportDTO.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CntyearlypurchasereportsService {
  private purUrl = ShipStreetProps.API_PATH + 'containerpurchase/getYearPurchaseReportBySuppliers';

  constructor(private http: HttpClient) { }

  public getYearPurchaseReportBySuppliers(year: number) {
    return this.http.post<ContainerPurchaseReportDTO[]>(this.purUrl , year);
  }
}
