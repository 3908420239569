import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NewcalculatorusersService } from './newcalculatorusers.service';
import { Candidates } from 'src/app/model/candidate.model';

@Component({
  selector: 'app-newcalculatorusers',
  templateUrl: './newcalculatorusers.component.html',
  styleUrls: ['./newcalculatorusers.component.css']
})
export class NewcalculatorusersComponent implements OnInit {

  candidate: Candidates;

  constructor(private newcalculatorusersService: NewcalculatorusersService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.candidate = new Candidates();
  }
  onSubmit() {
    this.newcalculatorusersService.saveCalculatorEmployee(this.candidate)
      .subscribe(data => {
        this.router.navigate(['calculator/calculatorusers']);
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
