import { Candidates } from '../../model/candidate.model';
import { Company } from '../../model/company.model';
import { Jobs } from '../../model/jobs.model';
import { GlobelsearchService } from './globelsearch.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';

@Component({
  selector: 'app-globelsearch',
  templateUrl: './globelsearch.component.html',
  styleUrls: ['./globelsearch.component.css']
})
export class GlobelsearchComponent implements OnInit {
  searchTxt: string;
  companies: Company[];
  jobs: Jobs[];
  candidates: Candidates[];

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  constructor(private globelsearchService: GlobelsearchService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.searchTxt = this.route.snapshot.params.text;
    this.globelsearchService.searchPeople(this.route.snapshot.params.text)
      .subscribe(data => {
        this.candidates = data;
     //   console.log(JSON.stringify(this.candidates));
      },
        error => {
          this.router.navigate(['']);
        });

    this.globelsearchService.searchJobs(this.route.snapshot.params.text)
      .subscribe(data => {
        this.jobs = data;
      },
        error => {
          this.router.navigate(['']);
        });


    this.globelsearchService.searchCompany(this.route.snapshot.params.text)
      .subscribe(data => {// console.log(JSON.stringify(data));
        this.companies = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  onKeydown(e) { }

}
