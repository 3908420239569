import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicelicensedetails',
  templateUrl: './servicelicensedetails.component.html',
  styleUrls: ['./servicelicensedetails.component.css']
})
export class ServicelicensedetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
