import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsPaymentDTO } from 'src/app/model/accountspaymentDTO.model';
import { AccountsPaymentMode } from 'src/app/model/accountspaymentmode.model';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { AccountspaymodeService } from '../../accountspaymode/accountspaymode.service';
import { ContainerpurchaseexpensedetailsService } from './containerpurchaseexpensedetails.service';


@Component({
  selector: 'app-containerpurchaseexpensedetails',
  templateUrl: './containerpurchaseexpensedetails.component.html',
  styleUrls: ['./containerpurchaseexpensedetails.component.css']
})
export class ContainerpurchaseexpensedetailsComponent implements OnInit {
  purchaseList: ContainerPurchase[];
  p = 1;
  isOpen: boolean;
  completed: boolean;
  total = 0;
  private value;
  paymentModeList: AccountsPaymentMode[];
  paymentMode: AccountsPaymentMode;
  accountsPaymentDTO: AccountsPaymentDTO;

  constructor(private containerpurchaseexpensedetailsService: ContainerpurchaseexpensedetailsService, private router: Router,
              private route: ActivatedRoute , private accountspaymodeService: AccountspaymodeService) { }

  ngOnInit() {
    this.paymentMode = new AccountsPaymentMode();
    this.accountsPaymentDTO = new AccountsPaymentDTO();
    this.accountsPaymentDTO.paymentMode = new AccountsPaymentMode();
    this.containerpurchaseexpensedetailsService.ContainerPurchaseexpenseDetails(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.purchaseList = data;
        this.findsum(this.purchaseList);
      },
        error => {
          this.router.navigate(['']);
        });
        this.accountspaymodeService.getaccountspaymentmodelist()
        .subscribe(data => {
          this.paymentModeList = data;
        },
          error => {
            this.router.navigate(['']);
          });
  }
  check_en(v1: any) {

    if (v1 === true) {
      document.getElementById('text1').setAttribute('disabled', 'false');
    } else {
      document.getElementById('text1').removeAttribute('disabled');
    }

  }


  findsum(data) {
    //  debugger
    this.value = data
    for (let j = 0; j < data.length; j++) {
      this.total += this.value[j].balanceTotal;
    }
  }
  makepayment() {
    this.accountsPaymentDTO.contPurchase = this.purchaseList;
    this.accountsPaymentDTO.paymentMode.id = this.paymentMode.id;
    this.containerpurchaseexpensedetailsService.containerpurchasemakepayment(this.accountsPaymentDTO)
      .subscribe(data => {
        this.router.navigate(['/containers/purchaseexpense']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
}


