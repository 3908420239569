import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class AccountscategoryService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'accountsproductcategory/getAllCategoryList.do';

  public getAllCategoryList() {
    return this.http.get<AccountsProductCategory[]>(this.appUrl);
  }
}
