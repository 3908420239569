import { Component, OnInit } from '@angular/core';
import { Candidates } from 'src/app/model/candidate.model';
import { AccountdeactivateedmessageService } from './accountdeactivateedmessage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-accountdeactivatedmessage',
  templateUrl: './accountdeactivatedmessage.component.html',
  styleUrls: ['./accountdeactivatedmessage.component.css']
})
export class AccountdeactivatedmessageComponent implements OnInit {

  constructor( private accountdeactivateedmessageService: AccountdeactivateedmessageService , private route: ActivatedRoute ,
    private router: Router) { }

  newPerson: Candidates;

  ngOnInit() {
  }

  activateAccount() {
	this.accountdeactivateedmessageService.activateAccount(this.route.snapshot.params['id'])
      .subscribe(data => {
            this.router.navigate(['/accountactivated']);
      } );
  }

}
