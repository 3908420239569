import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ViewprinciplerequestService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/findAllMyPrincipleRequests.do';

  getAllMyPrincipleRequests() {
    return this.http.get<TrackBoxAgent[]>(this.appUrl);
  }

}
