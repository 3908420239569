import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { YourcompaniesService } from './yourcompanies.service';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Component({
  selector: 'app-yourcompanies',
  templateUrl: './yourcompanies.component.html',
  styleUrls: ['./yourcompanies.component.css']
})
export class YourcompaniesComponent implements OnInit {
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  mycompanies: CompanyEmployee[];
  p: number;
  skipicon: false;
  breakpoint: number;

  constructor(private yourcompaniesService: YourcompaniesService , private router: Router) { }

  ngOnInit() {
     this.yourcompaniesService.getAllMyCompanies()
      .subscribe( data => {
        this.mycompanies = data;
      },
      error => {
        this.router.navigate(['']);
        });

        this.breakpointFun(event)
  }

  onResize(event) {
    this.breakpointFun(event)
  }

  breakpointFun(event?: any): void{
    if(event){
      this.breakpoint = (event.target.innerWidth <= 681) ? 2 : (event.target.innerWidth <= 1000) ? 4 : (event.target.innerWidth <= 1300) ? 4 : 5;
    } else {
      this.breakpoint = (window.innerWidth <= 681) ? 2 : (window.innerWidth <= 1000) ? 4 : (window.innerWidth <= 1300) ? 4 : 5;
    }
  }

}
