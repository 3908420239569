import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { NewequipmentcontrolService } from 'src/app/trackmybox/equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { TbagenteditcustomerService } from './tbagenteditcustomer.service';

@Component({
  selector: 'app-tbagenteditcustomer',
  templateUrl: './tbagenteditcustomer.component.html',
  styleUrls: ['./tbagenteditcustomer.component.css']
})
export class TbagenteditcustomerComponent implements OnInit {
  trackboxcustomers:TrackBoxCustomers;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
  CountryList: CountryInfo[];
  constructor(private tbagenteditcustomerService: TbagenteditcustomerService, private router: Router,
  private route: ActivatedRoute,private newequipmentcontrolService: NewequipmentcontrolService) { }

  ngOnInit() {
    this.trackboxcustomers = new TrackBoxCustomers();
    this.tbagenteditcustomerService.trackboxcustomersViewbyId(this.route.snapshot.params.id)
    .subscribe(data => {
      this.trackboxcustomers = data;
      // alert(JSON.stringify(this.trackboxcustomers));
      },
      error => {
        this.router.navigate(['']);
      });

      this.newequipmentcontrolService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );
    
        console.log(this.filteredOptionscountry);
    },
        error => {
          this.router.navigate(['']);
        });
  }
  updateCustomer() {
    this.tbagenteditcustomerService.updateCustomer(this.trackboxcustomers)
      .subscribe(data => {
        this.router.navigate(['/tbagentmode/tbagentcustomers']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }
  }
  

}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;

  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}
