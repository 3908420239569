import { TrackBoxContainer } from './../../../model/trackboxcontainer.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentdetailsService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/containersDetails.do';
private hisUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/getContainerHistory.do';

public getDetails(containerId: string) {
    return this.http.post<TrackBoxContainer>(this.appUrl, containerId);
  }

public containerhistory(containerId: string) {
    return this.http.post<TrackBoxBookingContainers[]>(this.hisUrl, containerId);
  }
}
