import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxAgentSearchDTO } from 'src/app/model/trackboxagentsearchDTO.model';
import { Company } from 'src/app/model/company.model';

@Injectable({
  providedIn: 'root'
})
export class SearchboxprincipleService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/findPrincipleCompany.do';

  public findPrincipleCompany(newtrackboxagent: TrackBoxAgentSearchDTO) {
    return this.http.post<Company[]>(this.appUrl, newtrackboxagent);
  }
}
