import { ContainerType } from './containertype.model';

export class Containers {
    id: number;
    equipmentPrefix: string;
    equipmentNumber: number;
    equipmentType: ContainerType;
    checkDigit: number;
    manufacturingDate: string;
    tareWeight: number;
    grossWeight: number;
    maxPayLoad: number;
    cscValidity: Date;
    cscNumber: number;
    containerNumber: string;
    newNumber: string;
}

