import { TbagentupdatecontainerstatuswithbookingService } from './tbagentupdatecontainerstatuswithbooking.service';
import { Component, Inject, OnInit } from '@angular/core';
import { TrackBoxBooking } from './../../../../model/trackboxbooking.model';
import { TrackBoxContainer } from './../../../../model/trackboxcontainer.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackboxContainerStatus } from 'src/app/trackmybox/trackboxcontainerstatus.model';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { UpdateequipmentService } from 'src/app/trackmybox/equipmentcontrol/equipmentdetails/updateequipment/updateequipment.service';
export interface DialogData {

  leaseUnitId: number;
}
@Component({
  selector: 'app-tbagentupdatecontainerstatuswithbooking',
  templateUrl: './tbagentupdatecontainerstatuswithbooking.component.html',
  styleUrls: ['./tbagentupdatecontainerstatuswithbooking.component.css']
})
export class TbagentupdatecontainerstatuswithbookingComponent implements OnInit {

  trackboxeqpcontainer: TrackBoxContainer;
   trackboxbookingcontainer: TrackBoxBookingContainers;
  //  trackboxbookingId : TrackBoxBooking;
  containerstatusList: TrackboxContainerStatus[];
  constructor(private tbagentupdatecontainerstatuswithbookingService: TbagentupdatecontainerstatuswithbookingService, private router: Router, private route: ActivatedRoute,
    public dialogoutRef: MatDialogRef<TbagentupdatecontainerstatuswithbookingComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar, private updateequipmentService: UpdateequipmentService) { }

  ngOnInit() {

    this.trackboxbookingcontainer = new TrackBoxBookingContainers();
    this.trackboxbookingcontainer.container = new TrackBoxContainer();
    this.trackboxbookingcontainer.bookingId = new TrackBoxBooking();
    //  this.trackboxbookingcontainer.container.containerStatus = new TrackboxContainerStatus();
    // this.trackboxeqpcontainer.containerStatus = new TrackboxContainerStatus();
    this.trackboxbookingcontainer.container.containerStatus = new TrackboxContainerStatus();
    this.updateequipmentService.getContainerStatus()
    .subscribe(data => {
      this.containerstatusList = data;
      //  alert(JSON.stringify(this.containerstatusList));
    },
      error => {
        this.router.navigate(['']);
      });
  }

  updateContainerStatusWithBooking() {
    // alert(JSON.stringify(this.data.leaseUnitId));
   this.trackboxbookingcontainer.bookingId.id = this.data.leaseUnitId;
    // alert(JSON.stringify(this.data.leaseUnitId));
    this.tbagentupdatecontainerstatuswithbookingService.updateContainerStatusWithBooking(this.trackboxbookingcontainer)
      .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 2000,

        });
        this.dialogoutRef.close(0);

      },

        error => {
          this.router.navigate(['']);
        });

  }

  onNoClick(): void {
    this.dialogoutRef.close();

  }
}
