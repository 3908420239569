import { TbAgentClearingAgentDTO } from './../../../../model/tbagentclearingagentDTO.model';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { TbagentaddclearingagentService } from './tbagentaddclearingagent.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BoxagentsService } from 'src/app/trackmybox/admin/boxagents/boxagents.service';

export interface DialogData {
  leaseUnitId: number;
}
@Component({
  selector: 'app-tbagentaddclearingagent',
  templateUrl: './tbagentaddclearingagent.component.html',
  styleUrls: ['./tbagentaddclearingagent.component.css']
})
export class TbagentaddclearingagentComponent implements OnInit {

  trackboxAgentList: TrackBoxAgent[];
  tbbooking: TrackBoxBooking;
  agentadd:TbAgentClearingAgentDTO;
  constructor(private tbagentaddclearingagentService: TbagentaddclearingagentService, private router: Router,
    private route: ActivatedRoute ,public dialogoutRef: MatDialogRef<TbagentaddclearingagentComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar, private boxagentService: BoxagentsService) { }

  ngOnInit() {
    this.tbbooking = new TrackBoxBooking();
    this.agentadd = new TbAgentClearingAgentDTO();
    this.tbbooking.clearingAgent =new TrackBoxAgent();
    this.tbbooking.id = this.data.leaseUnitId;

    this.boxagentService.getAllMyAgents()
    .subscribe(data => {
      this.trackboxAgentList = data;
      // alert(JSON.stringify( this.trackboxAgentList));
    },
      error => {
        this.router.navigate(['']);
      });
  }
  addAgent() {
    // this.tbbooking.bookingId = this.data.leaseUnitId;
      console.log(JSON.stringify(this.agentadd));
     this.agentadd = { booking: this.data.leaseUnitId, agentId: this.agentadd.agentId};

     this.tbagentaddclearingagentService.addAgent(this.agentadd)
       .subscribe(data => {
         this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
           duration: 500,

         });
         this.dialogoutRef.close(0);
       },

         error => {
           this.router.navigate(['']);
         });

   }


   onNoClick(): void {
     this.dialogoutRef.close();

   }



}
