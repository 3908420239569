import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchase } from '../model/containerpurchase.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  private listUrl = ShipStreetProps.API_PATH + 'containerpurchase/containerPurchaseList';

  constructor(private http: HttpClient) { }

  public containerPurchaseList() {
    return this.http.get<ContainerPurchase[]>(this.listUrl);
  }

}
