import { MasterPortsDepot } from './masterportsdepot.model';
import { Company } from 'src/app/model/company.model';
export class TrackboxChargeHeads {
  id: number;
  company:Company;
  port: MasterPortsDepot;
  chargeHead: string;
  client20: number;
  client40: number;
  client40Hc: number;
  client40Std: number;
  client45: number;
 actual20: number;
  actual40: number;
  actual40Hc: number;
  actual40Std: number;
  actual45: number;

}
