import { Component, OnInit } from '@angular/core';
import { AgentemployeesService } from './agentemployees.service';
import { Router } from '@angular/router';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Component({
  selector: 'app-agentemployees',
  templateUrl: './agentemployees.component.html',
  styleUrls: ['./agentemployees.component.css']
})
export class AgentemployeesComponent implements OnInit {
  tbEmployeesList: CompanyEmployee[];
  p = 1;

  constructor(private agentemployeesService: AgentemployeesService, private router: Router) { }

  ngOnInit() {
     this.agentemployeesService.getAllMyEmployees()
      .subscribe(data => {
        this.tbEmployeesList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
