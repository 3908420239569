import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';

@Component({
  selector: 'app-mobnavbar',
  templateUrl: './mobnavbar.component.html',
  styleUrls: ['./mobnavbar.component.css']
})
export class MobnavbarComponent implements OnInit {

  sessionCandidate: SessionCandidate;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
  }
}