import { EditbookingService } from './editbooking.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingcontainersviewService } from '../bookingcontainersview/bookingcontainersview.service';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

@Component({
  selector: 'app-editbooking',
  templateUrl: './editbooking.component.html',
  styleUrls: ['./editbooking.component.css']
})
export class EditbookingComponent implements OnInit {


  trackboxbooking: TrackBoxBooking;
  constructor(private editbookingService: EditbookingService, private router: Router,
    private route: ActivatedRoute,private bookingcontainersviewService: BookingcontainersviewService) { }

  ngOnInit() {

    this.trackboxbooking = new TrackBoxBooking();
    this.bookingcontainersviewService.getBookingsDetailsByID(this.route.snapshot.params.id)
    .subscribe(data => {
      this.trackboxbooking = data;
      // alert(JSON.stringify(this.trackboxbooking));
      },
      error => {
        this.router.navigate(['']);
      });

  }
  updateBookings() {
    this.editbookingService.updateBookings(this.trackboxbooking)
      .subscribe(data => {
        this.router.navigate(['/trackmybox/bookings']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
