import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trademyboxpricing',
  templateUrl: './trademyboxpricing.component.html',
  styleUrls: ['./trademyboxpricing.component.css']
})
export class TrademyboxpricingComponent implements OnInit {

  showmeBasic: boolean;
  showmeStandard: boolean;
  showmePremium: boolean;
  constructor() { }

  ngOnInit() {
  }

}
