import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerPresales } from 'src/app/model/containerpresales.model';
import { ContainerPresalesConversation } from 'src/app/model/containerpresalesconversation.model';
import { PresalesconversationsService } from './presalesconversations.service';

@Component({
  selector: 'app-presalesconversations',
  templateUrl: './presalesconversations.component.html',
  styleUrls: ['./presalesconversations.component.css']
})
export class PresalesconversationsComponent implements OnInit {
  comment: ContainerPresalesConversation;
  conversations: ContainerPresalesConversation[];


  constructor(private presalesconversationsService: PresalesconversationsService, private router: Router,
    private route: ActivatedRoute ) { }

  ngOnInit() {
    this.comment = new ContainerPresalesConversation();
    this.comment.presale = new ContainerPresales();
    this.presalesconversationsService.findConvestionsByPreSales(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.conversations = data;
       // this.router.navigate(['containers/presales']);
      },
        error => {
          this.router.navigate(['']);
        });

  }

  saveContainerPreSalesConversation() {
    this.comment.presale.id = Number(this.route.snapshot.paramMap.get('id'));
    this.presalesconversationsService.saveContainerPreSalesConversation(this.comment)
      .subscribe(data => {
        
        this.router.navigate(['containers/presales']);
      },
        error => {
          this.router.navigate(['']);
        });
  }




}



