import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { CompanyVoyage } from './companyvoyage.model';
import { Candidates } from 'src/app/model/candidate.model';
import { MasterPortsDepot } from './masterportsdepot.model';
export class MasterVoyageRoute {
  id: number;
  candidateId: Candidates;
  voyageId: CompanyVoyage;
  portCalling: MasterPortsDepot;
  transhipment: MasterPortsDepot;
  vesselEta: Date;
  vesselLoading: Date;
  vesselCutoff: Date;
  vesselCutoffTime: Date;
  vesselStartsOn: Date;
  vesselEtd: Date;
  vesselReachedOn: Date;
  vesselDischargedOn: Date;
  congsigneePickedOn: Date;
  consigneeReturnedOn: Date;
  returnedLocationId: ContainerLocationType;
}

