import { Candidates } from '../../model/candidate.model';
import { CandidateConnect } from '../../model/candidateConnect.model';
import { Dashboard } from '../../model/dashboard.model';
import { Feeds } from '../../model/feeds.model';
import { YourupdatesService } from './yourupdates.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';

@Component({
  selector: 'app-yourupdates',
  templateUrl: './yourupdates.component.html',
  styleUrls: ['./yourupdates.component.css']
})
export class YourupdatesComponent implements OnInit {
  friendsList: CandidateConnect[];
  feedlist: Feeds[];
  dashboard: Dashboard;
  sessioncandidate: Candidates;

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;


  constructor(private yourupdatesService: YourupdatesService , private router: Router) { }

  ngOnInit() {
    this.yourupdatesService.sessioncandidate()
      .subscribe( data => {
        this.sessioncandidate = data;
      },
      error => {
        this.router.navigate(['']);
        });
    this.yourupdatesService.getMyFeeds()
      .subscribe( data => {
        this.dashboard = data;
        this.feedlist = this.dashboard.dtoList;
      },
      error => {
        this.router.navigate(['']);
        });
    this.yourupdatesService.getMyFriends()
      .subscribe( data => {
        this.friendsList = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  firstFeed(){}

}
