import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trackmyboxsubscriptioncheckout',
  templateUrl: './trackmyboxsubscriptioncheckout.component.html',
  styleUrls: ['./trackmyboxsubscriptioncheckout.component.css']
})
export class TrackmyboxsubscriptioncheckoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
