import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { BoxagentsService } from './boxagents.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-boxagents',
  templateUrl: './boxagents.component.html',
  styleUrls: ['./boxagents.component.css']
})
export class BoxagentsComponent implements OnInit {
  boxagentList: TrackBoxAgent[];
  p: number;
  constructor(private boxagentService: BoxagentsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {

    this.boxagentService.getAllMyAgents()
      .subscribe(data => {
        this.boxagentList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }
}


