import { BoxCountByContainerTypeDTO } from './../model/boxcountbycontainertypeDto';
import { Component, OnInit } from '@angular/core';
import { TrackmyboxService } from './trackmybox.service';
import { Router } from '@angular/router';
import { DashboardCountDto } from '../model/dashboardcountdto.model';
import { CommonService } from '../utils/common.service';
import { SessionCandidate } from '../model/sessioncandidate.model';
import { ShipStreetProps } from '../utils/rs.constants';
import { VoyageService } from './master/voyage/voyage.service';
import { CompanyVoyage } from '../model/companyvoyage.model';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NewequipmentcontrolService } from './equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { TrackboxContainerStatus } from './trackboxcontainerstatus.model';


@Component({
  selector: 'app-trackmybox',
  templateUrl: './trackmybox.component.html',
  styleUrls: ['./trackmybox.component.css']
})
export class TrackmyboxComponent implements OnInit {
  dashboardCount: DashboardCountDto;
  boxcountbycontainertype: BoxCountByContainerTypeDTO[];
  sessionCandidate: SessionCandidate;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  voyagesList: CompanyVoyage[];
  panelOpenState: boolean;
  showme: boolean;
  containerstatus: TrackboxContainerStatus[];
  INTRANSIT: string;
  customOptions: OwlOptions;


  constructor(private trackmyboxService: TrackmyboxService, private router: Router, private commonService: CommonService,
              private voyageService: VoyageService, private newequipmentcontrolService: NewequipmentcontrolService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();

    this.trackmyboxService.getAllDashboardCounts()
      .subscribe(data => {
        this.dashboardCount = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.trackmyboxService.getAllBoxCountByContainerType('ALL')
      .subscribe(data => {
        this.boxcountbycontainertype = data;
        //   console.log("Data Count :" +JSON.stringify(data));
      },
        error => {
          this.router.navigate(['']);
        });

    this.voyageService.getCurrentVoyages()
      .subscribe(data => {
        this.voyagesList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      autoplay: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 6
        },
        400: {
          items: 1
        },
        740: {
          items: 1
        },
        940: {
          items: 8
        },
        1024: {
          items: 7
        }
      },
      nav: true
    };

    this.newequipmentcontrolService.viewcontainerStatus()
      .subscribe(data => {
        this.containerstatus = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  getContinerCountByStatus(status) {

  }

}