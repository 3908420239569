import { Component, OnInit } from '@angular/core';
import { EnrolltrackboxcompanyService } from './enrolltrackboxcompany.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-enrolltrackboxcompany',
  templateUrl: './enrolltrackboxcompany.component.html',
  styleUrls: ['./enrolltrackboxcompany.component.css']
})
export class EnrolltrackboxcompanyComponent implements OnInit {

  constructor(private enrolltrackboxcompanyService: EnrolltrackboxcompanyService, private router: Router,
              private route: ActivatedRoute, private snackBar: MatSnackBar, private cookieService: CookieService) { }

  ngOnInit() {
  }

  enrolltrackmybox() {
    this.enrolltrackboxcompanyService.enrolltrackmybox(this.route.snapshot.params.id)
      .subscribe(data => {
        //   this.companies = data;
        this.cookieService.set('TMB_STAT', 'PROFILE', null, null, null, null, null);
        this.snackBar.open('TRACKMYBOX ENROLLED SUCCESSFULLY !!!', 'End now', {
          duration: 2000,

        });
      },
        error => {
          this.router.navigate(['']);
        });
  }
}
