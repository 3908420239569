import { Component, OnInit } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { Page3Service } from './page3.service';
import { Router } from '@angular/router';
import { Candidates } from '../../model/candidate.model';

@Component({
  selector: 'app-page3',
  templateUrl: './page3.component.html',
  styleUrls: ['./page3.component.css']
})
export class Page3Component implements OnInit {

  constructor(private page3Service: Page3Service , private router: Router) { }
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  showimg: boolean;
  showme: boolean;
  showExpIcon: boolean;
  selectedFile: File;
  previewFile: any;
  candidate: Candidates;
  onFileChanged(event) {
    if (event.target) {
      var reader = new FileReader();
      this.selectedFile = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event1: any) => { // called once readAsDataURL is completed
       console.log('Read As Data UR is Completed :' + event);
        this.previewFile = event1.target.result;
      };
    }

  }

   onUpload() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
   // alert(JSON.stringify(uploadData));
    console.log(uploadData);
    this.page3Service.updateProfileImage(uploadData).subscribe( data => {
     console.log(data);

    },
    error => {
      this.router.navigate(['']);
      });

  }

  ngOnInit() {
  }

  updateProfileImage() {
    this.router.navigate(['/m/dashboard']);
  }

}
