import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { AgentPrincipleFreight } from '../../../../model/agentprinciplefreight.model';
import { TbagentrequesttoprincipleService } from './tbagentrequesttoprinciple.service';
import { Component, Inject, OnInit } from '@angular/core';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { Company } from 'src/app/model/company.model';
import { AgentPrincipleFreightDetails } from 'src/app/model/agentprinciplefreightdetails.model';
import { AgentPrincipleFreightDTO } from 'src/app/model/agentprinciplefreightDTO.model';

export interface DialogData {
  principleName: string;
  principleId: number;
  bookingId: number;
  freightId: number;
}
@Component({
  selector: 'app-tbagentrequesttoprinciple',
  templateUrl: './tbagentrequesttoprinciple.component.html',
  styleUrls: ['./tbagentrequesttoprinciple.component.css']
})
export class TbagentrequesttoprincipleComponent implements OnInit {

  principleCompanies: TrackBoxAgent[];
  principle: number;
  tbRequest: AgentPrincipleFreight;
  companyName = this.data.principleName;
  companyId = this.data.principleId;
  tbrequestView: AgentPrincipleFreightDetails[];
  paramValue: AgentPrincipleFreightDTO;

  constructor(private router: Router, private route: ActivatedRoute,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    private snackBar: MatSnackBar, private tbagentrequesttoprincipleService: TbagentrequesttoprincipleService,
    private _bottomSheetRef: MatBottomSheetRef<TbagentrequesttoprincipleComponent>) { }

  ngOnInit() {
    this.tbRequest = new AgentPrincipleFreight();
    this.tbRequest.principleId = new Company();
    this.tbRequest.bookingId = new TrackBoxBooking();
    this.paramValue = new AgentPrincipleFreightDTO();
    this.paramValue.bookingId = this.data.bookingId;
    this.paramValue.principleId = this.data.principleId;

    this.tbagentrequesttoprincipleService.getAllFreightByBookingId(this.paramValue)
      .subscribe(data => {
        this.tbrequestView = data;
      },
        error => {
          this.router.navigate(['']);
        });


  }

  requestToPrinciple() {
    this.tbRequest.principleId.id = this.data.principleId;
    this.tbRequest.bookingId.id = this.data.bookingId;
    this.tbagentrequesttoprincipleService.requestToPrinciple(this.tbRequest)
      .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 500,

        });
        this.tbRequest.requestFreight = '';
        this.tbagentrequesttoprincipleService.getAllFreightByBookingId(this.paramValue)
            .subscribe(data1 => {
              this.tbrequestView = data1;
            },
              error => {
                this.router.navigate(['']);
              });
      },
        error => {
          this.router.navigate(['']);
        });
  }
  onNoClick(): void {
    this._bottomSheetRef.dismiss();

  }

  keyDownrequestToPrinciple(event) {
    if (event.keyCode === 13) {
      this.tbRequest.principleId.id = this.data.principleId;
      this.tbRequest.bookingId.id = this.data.bookingId;
      this.tbagentrequesttoprincipleService.requestToPrinciple(this.tbRequest)
        .subscribe(data => {
          this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
            duration: 500,

          });
          //   this._bottomSheetRef.dismiss;
          this.tbagentrequesttoprincipleService.getAllFreightByBookingId(this.paramValue)
            .subscribe(data1 => {
              this.tbrequestView = data1;
            },
              error => {
                this.router.navigate(['']);
              });
        },
          error => {
            this.router.navigate(['']);
          });
    }
  }
}
