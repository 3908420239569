import { Component, OnInit } from '@angular/core';
import { AccountsdashboardService } from './accountsdashboard.service';
import { Router } from '@angular/router';
// import { AccountBalance } from 'src/app/model/accountbalance.model';
// import { ConsigneePaymentSummaryDTO } from 'src/app/model/consigneepaymentsummarydto.modet';

@Component({
  selector: 'app-accountsdashboard',
  templateUrl: './accountsdashboard.component.html',
  styleUrls: ['./accountsdashboard.component.css']
})
export class AccountsdashboardComponent implements OnInit {
  // dashboardAccountBalance: AccountBalance[];
  // consigneeApprovCount: ConsigneePaymentSummaryDTO;

  constructor(private accountsdashboardService: AccountsdashboardService, private router: Router) { }

  ngOnInit() {
    // this.accountsdashboardService.getDashboardAccountBalance()
    //   .subscribe(data => {
    //     this.dashboardAccountBalance = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    // this.accountsdashboardService.getDashboardConsigneeThisMonthPaymentAmount()
    //   .subscribe(data => {
    //     this.consigneeApprovCount = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

   

  }
}


