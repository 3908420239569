import { Component, OnInit, Inject } from '@angular/core';
import { ContainerLeasingUnit } from 'src/app/model/containerleasingunit.model';
import { CustomersService } from '../../admin/customers/customers.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AddcontainerleasingService } from './addcontainerleasing.service';
import { ContainerLease } from 'src/app/model/containerlease.model';
export interface DialogData {
  leaseID: number;
 // branch: number;
//  tsheet: number;
}

@Component({
  selector: 'app-addcontainerleasing',
  templateUrl: './addcontainerleasing.component.html',
  styleUrls: ['./addcontainerleasing.component.css']
})
export class AddcontainerleasingComponent implements OnInit {
  leaseUnits: ContainerLeasingUnit;

  constructor(private addcontainerleasingService: AddcontainerleasingService, private customersService: CustomersService,
              private router: Router, public dialogRef: MatDialogRef<AddcontainerleasingComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.leaseUnits = new ContainerLeasingUnit();
    this.leaseUnits.leasing = new ContainerLease();
  }

  saveContainerLeaseUnit() {
    this.leaseUnits.leasing.id = this.data.leaseID;
    this.addcontainerleasingService.saveContainerLeaseUnit(this.leaseUnits)
      .subscribe(data => {
        this.dialogRef.close(0);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
