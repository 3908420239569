import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyFollow } from 'src/app/model/companyFollow.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CompanyeditService } from '../companyedit/companyedit.service';

@Component({
  selector: 'app-managecompanyfollowers',
  templateUrl: './managecompanyfollowers.component.html',
  styleUrls: ['./managecompanyfollowers.component.css']
})
export class ManagecompanyfollowersComponent implements OnInit {
  companyfollowlist: CompanyFollow[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  breakpoint: number;
  searchTxt: string;

  constructor(private companyeditService: CompanyeditService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.companyeditService.getFollowList(this.route.snapshot.params.id)
    .subscribe(data => {
      this.companyfollowlist = data;
    },
      error => {
        this.router.navigate(['']);
      });

      this.breakpointFun(event)
  }

  onResize(event) {
    this.breakpointFun(event)
  }

  breakpointFun(event?: any): void{
    if(event){
      this.breakpoint = (event.target.innerWidth <= 681) ? 1 : (event.target.innerWidth <= 1000) ? 3 : (event.target.innerWidth <= 1300) ? 3 : 4;
    } 
    else {
      this.breakpoint = (window.innerWidth <= 681) ? 1 : (window.innerWidth <= 1000) ? 3 : (window.innerWidth <= 1300) ? 3 : 4;
    }
  }

  onKeydown(event) {}

}
