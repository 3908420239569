import { CandidateConnect } from '../../model/candidateConnect.model';
import { FriendlistplugService } from './friendlistplug.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';

@Component({
  selector: 'app-friendlistplug',
  templateUrl: './friendlistplug.component.html',
  styleUrls: ['./friendlistplug.component.css']
})
export class FriendlistplugComponent implements OnInit {
  friendsList: CandidateConnect[];
   imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private friendlistplugService: FriendlistplugService , private router: Router) { }

  ngOnInit() {
     this.friendlistplugService.getMyFriends()
      .subscribe( data => {
        this.friendsList = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
