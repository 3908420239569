import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CompanyVessels } from 'src/app/model/companyvessels.model';

@Injectable({
  providedIn: 'root'
})
export class TbagentvesselsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/vessels/findAllCompanyVessels.do';

  getAllMyCompanyVessels() {
    return this.http.get<CompanyVessels[]>(this.appUrl );

  }
}
