import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MovecontainersService } from './movecontainers.service';
import { ContainerOnewayMove } from 'src/app/model/containeronewaymove.model';

@Component({
  selector: 'app-movecontainers',
  templateUrl: './movecontainers.component.html',
  styleUrls: ['./movecontainers.component.css']
})
export class MovecontainersComponent implements OnInit {
  onewaymoveList: ContainerOnewayMove[];

  constructor(private movecontainersService: MovecontainersService, private router: Router) { }

  ngOnInit() {
    this.movecontainersService.findAllContainerOnewayMoves()
      .subscribe(data => {
        this.onewaymoveList = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
