export class Customer {
    id: number;
    name: string;
    code: string;
    address: string;
    postBox: string;
    phone: string;
    equipmentNo: string;
    equipmentType: string;
    customerName: string;
    moveStatus: string;
    acceptanceId: any;
    fax: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    emailInvoice: string;
    emailReporting: string;
    emailDailyMove: string;
    emailRepair: string;
    billingAttn: string;
    billingAddress: string;
    billingPhone: string;
    billingCurrency: string;
    billingDuration: string;
    handlingInCharge20: number;
    handlingInCharge40: number;
    handlingInCharge45: number;
    inspectionCharge20: number;
    inspectionCharge40: number;
    inspectionCharge45: number;
    storageCharge20: number;
    storageCharge40: number;
    storageCharge45: number;
    labourRate: number;
    labourRateReefer: number;
    photoFee: number;
    defaultPhotoFee: boolean;
    excludeAllCharges: boolean;
    tradeLicenseExpiry: Date;
    vatTrnNo: number;
    freeDays: number;
    estimateExtraCharge: boolean;
    estimateExtraAmount: number;
    ediStatus: boolean;
    ediWithacceptance: boolean;
    ediRecipientCode: string;
    ediSenderCode: string;
    ediEmail: string;
    ediCcEmails: string;
    ediGateinTemplate: string;
    ediGateoutTemplate: string;
    shiftingCharge20: number;
    shiftingCharge40: number;
    shiftingCharge45: number;
    reeferHandle20: number;
    reeferHandle40: number;
    openTopHandle20: number;
    openTopHandle40: number;
    flatRackHandle20: number;
    flatRackHandle40: number;
    tankHandle20: number;
    tankHandle40: number;
    specialHandle20: number;
    specialHandle40: number;
    reeferStorage20: number;
    reeferStorage40: number;
    openTopSstorage20: number;
    openTopSstorage40: number;
    flatRackStorage20: number;
    flatRackStorage40: number;
    tankStorage20: number;
    tankStorage40: number;
    specialStorage20: number;
    specialStorage40: number;
}


