import { CandidateExperience } from '../../../model/candidateExperience.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CandidateexperienceService {
  private expUrl = ShipStreetProps.API_PATH + 'candidateExperience.do';
  private noexpAlertUrl = ShipStreetProps.API_PATH + 'sendNoExperienceAlert.do';

  constructor(private http: HttpClient) { }

  public candidateExperience(candidate: string) {
    return this.http.post<CandidateExperience[]>(this.expUrl , candidate);
  }

  public sendNoExperienceAlert(candidateId: string) {
    return this.http.post<CandidateExperience[]>(this.noexpAlertUrl , candidateId);
  }
}
