import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyEmployee } from '../model/companyemployee.model';
import { ShipStreetProps } from '../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TrackboxprofileintroService {

  constructor(private http: HttpClient) { }
  private appListUrl = ShipStreetProps.API_PATH + 'getAllMyTbCompanies.do';

  public getAllMyTbCompanies() {
    return this.http.get<CompanyEmployee[]>(this.appListUrl);
  }
}
