import { InviteFriends } from '../../model/inviteFriends.model';
import { InvitefriendService } from './invitefriend.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invitefriend',
  templateUrl: './invitefriend.component.html',
  styleUrls: ['./invitefriend.component.css']
})
export class InvitefriendComponent implements OnInit {
  invite: InviteFriends;
  textValue: string;

  constructor(private invitefriendService: InvitefriendService , private router: Router) { }

  ngOnInit() {
    this.invite = new InviteFriends;
    this.textValue = '<div class="fr-wrapper" dir="auto"><div class="fr-element fr-view" dir="auto" contenteditable="true" aria-disabled="false" spellcheck="true"><p>I"\'\"d like to invite you to join me on RigStreet and be a part of my network.</p><p><br></p><p><br></p><p>Shaabi Kunjumon<br></p></div></div>'
  }

  inviteFriends() {//alert(JSON.stringify(this.invite))
    this.invitefriendService.saveType(this.invite)
      .subscribe( data => {
//        this.router.navigate(['editprofile']);
      },
      error => {
        this.router.navigate(['']);
        });
    }

}
