import { PeopleknowService } from './peopleknow.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Candidates } from 'src/app/model/candidate.model';
import { ConnectionList } from 'src/app/model/connectionList.model';

@Component({
  selector: 'app-peopleknow',
  templateUrl: './peopleknow.component.html',
  styleUrls: ['./peopleknow.component.css']
})
export class PeopleknowComponent implements OnInit {
  connectioncandidates: ConnectionList[];

  constructor(private peopleknowService: PeopleknowService , private router: Router) { }
  pic: string;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  customOptions: OwlOptions = {
    autoplay: false,
    autoplayTimeout: 6000,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 100,
    navText: [ '<i class="material-icons">chevron_left</i>', '<i class="material-icons">chevron_right</i>' ],
    responsive: {
      0: {
        items: 2
      },
      320: {
        items: 3
      },
      370: {
        items: 3
      },
      400: {
        items: 3
      },
      600: {
        items: 3
      },

    },
    nav: true
  };

  ngOnInit() {
    this.peopleknowService.getPeopleYouMayKnow()
      .subscribe( data => {
        this.connectioncandidates = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  candidateconnect(ca) {

  }

}
