import { Candidates } from '../../model/candidate.model';
import { ConnectionList } from '../../model/connectionList.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CandidateCount } from '../../model/candidatecount.model';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CommonService } from '../../utils/common.service';
import { SessionCandidate } from '../../model/sessioncandidate.model';

@Injectable({
  providedIn: 'root'
})
export class SearchbarService {

  constructor(private http: HttpClient , private commonService: CommonService) { }

  private appUrl = ShipStreetProps.API_PATH + 'sessionCandidate.do';
  private canUrl = ShipStreetProps.API_PATH + 'logout.do';
   private requestedConnectionsUrl = ShipStreetProps.API_PATH + 'requestedConnections.do';
   private ConnectionCountUrl = ShipStreetProps.API_PATH + 'connectionCount.do';

  public sessionCandidate() {
    return this.http.get<Candidates>(this.appUrl);
  }

  // public sessionCandidate1() {
  //   return this.commonService.getSessionCandidate();
  // }

  public requestedConnections() {
    return this.http.get<ConnectionList[]>(this.requestedConnectionsUrl);
  }
  public connectionCount() {
    return this.http.get<CandidateCount>(this.ConnectionCountUrl);
  }
  public logout() {
    return this.http.get(this.canUrl);
  }

}
