import { Router, ActivatedRoute } from '@angular/router';
import { ViewpurchasecontainersService } from './viewpurchasecontainers.service';
import { Component, OnInit } from '@angular/core';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ContainerType } from 'src/app/model/containertype.model';

@Component({
  selector: 'app-viewpurchasecontainers',
  templateUrl: './viewpurchasecontainers.component.html',
  styleUrls: ['./viewpurchasecontainers.component.css']
})
export class ViewpurchasecontainersComponent implements OnInit {

  panelOpenState: boolean;
  p = 1;
  itemsPerPage: 1;
  purchasedUnits: ContainerPurchasedUnits[];
  purchasedUnitList: ContainerPurchasedUnits;
  searchText: string;
  purchasedUnitsAll: ContainerPurchasedUnits[];
  constructor(private viewpurchasecontainersService: ViewpurchasecontainersService, private router: Router,
              private route: ActivatedRoute) { }


  ngOnInit() {
    this.purchasedUnitList = new ContainerPurchasedUnits();
    this.purchasedUnitList.containerType = new ContainerType();

    if (this.route.snapshot.paramMap.get('typ') === 'ALL') {
      this.viewpurchasecontainersService.getAllPurchasedContainers()
      .subscribe(data => {
        this.purchasedUnitsAll = data;
        this.purchasedUnits = this.purchasedUnitsAll;
      },
        error => {
          this.router.navigate(['']);
        });
    } else {
      this.viewpurchasecontainersService.getPurchasedContainersByType(this.route.snapshot.paramMap.get('typ'))
      .subscribe(data => {
        this.purchasedUnitsAll = data;
        this.purchasedUnits = this.purchasedUnitsAll;
      },
        error => {
          this.router.navigate(['']);
        });
    }
  }

  search() { 
    if (this.searchText !== '') { 
      this.purchasedUnits = this.purchasedUnitsAll.filter(res => { 
        return res.container.containerNumber.toLowerCase().match(this.searchText.toLocaleLowerCase()) ||
          res.container.equipmentType.typeCode.toLowerCase().match(this.searchText.toLocaleLowerCase()) ||
          res.container.equipmentType.eqType.toLowerCase().match(this.searchText.toLocaleLowerCase()) ||
       //   res.container.manufacturingDate != null && res.container.manufacturingDate.toLowerCase().match(this.searchText.toLocaleLowerCase()) ||
          res.purchase.supplier.name.toLowerCase().match(this.searchText.toLocaleLowerCase()) ;
      });
    } else if (this.searchText === '') {
      this.purchasedUnits = this.purchasedUnitsAll;
    }
  }

}
