import { ChargeheadsService } from './chargeheads.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackboxChargeHeads } from 'src/app/model/trackboxchargeheads.model';

@Component({
  selector: 'app-chargeheads',
  templateUrl: './chargeheads.component.html',
  styleUrls: ['./chargeheads.component.css']
})
export class ChargeheadsComponent implements OnInit {
  chargeHeads:TrackboxChargeHeads[];
  panelOpenState: boolean;
  p = 1;
  searchText: string;
  constructor(private chargeheadsService: ChargeheadsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.chargeheadsService.getAllMyChargeheads()
    .subscribe(data => {
      this.chargeHeads = data;
      console.log(this.chargeHeads);
    },
      error => {
        this.router.navigate(['']);
      });
  }

}
