import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerpurchaseService {

  private listUrl = ShipStreetProps.API_PATH + 'containerpurchase/containerPurchaseListByCompany';
  private orderlistUrl = ShipStreetProps.API_PATH + 'containerpurchase/containerPurchaseListByOrder';
  private dtorderlistUrl = ShipStreetProps.API_PATH + 'containerpurchase/containerPurchaseListByOrderDate';

  constructor(private http: HttpClient) { }

  public containerPurchaseListByCompany() {
    return this.http.get<ContainerPurchase[]>(this.listUrl);
  }

  public containerPurchaseListByOrder() {
    return this.http.get<ContainerPurchase[]>(this.orderlistUrl);
  }

  public containerPurchaseListByOrderDate() {
    return this.http.get<ContainerPurchase[]>(this.dtorderlistUrl);
  }
}
