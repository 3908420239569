import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewinvoiceService } from './newinvoice.service';
import { AccountsPaymentTerms } from 'src/app/model/accountspaymentterms.model';
import { AccountsSalesLocation } from 'src/app/model/accountssaleslocation.model';
import { AccountsLocation } from 'src/app/model/accountslocation.model';
import { ProductservicesService } from '../../productservices/productservices.service';
import { Invoice } from 'src/app/model/invoice.model';
import { AccountsProductServices } from 'src/app/model/accountsproductservices.model';
import { CustomersService } from '../../customers/customers.service';
import { Customer } from 'src/app/model/customer.model';

@Component({
  selector: 'app-newinvoice',
  templateUrl: './newinvoice.component.html',
  styleUrls: ['./newinvoice.component.css']
})
export class NewinvoiceComponent implements OnInit {
  customerList: Customer[];
  paymentTermsList: AccountsPaymentTerms[];
  salesLocList: AccountsSalesLocation[];
  accLocList: AccountsLocation[];
  pnsList: AccountsProductServices[];
  invoice: Invoice;

  constructor( private newinvoiceService: NewinvoiceService, private customerService: CustomersService,
              private productservicesService: ProductservicesService, private router: Router) { }

  ngOnInit() {

    this.invoice = new Invoice();
    this.invoice.customer = new Customer();
    this.invoice.invoiceTerms = new AccountsPaymentTerms();
    this.customerService.getAllCustomers()
      .subscribe(data => {
        this.customerList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newinvoiceService.getAllPaymentTerms()
      .subscribe(data => {
        this.paymentTermsList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newinvoiceService.getSalesLocation()
      .subscribe(data => {
        this.salesLocList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newinvoiceService.getAllLocations()
      .subscribe(data => {
        this.accLocList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.productservicesService.getAllProductNServices()
            .subscribe(data => {
                this.pnsList = data;
            },
                error => {
                    this.router.navigate(['']);
                });
  }

  saveinvoice() {
     this.newinvoiceService.saveinvoice(this.invoice)
            .subscribe(data => {
             //   this.pnsList = data;
            },
                error => {
                    this.router.navigate(['']);
                });
  }

}
