import { Company } from './../../model/company.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerservicelicensesService } from './containerservicelicenses.service';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-containerservicelicenses',
  templateUrl: './containerservicelicenses.component.html',
  styleUrls: ['./containerservicelicenses.component.css']
})
export class ContainerservicelicensesComponent implements OnInit {
  mycompanies: CompanyEmployee[];
  containerlicence: CompanyEmployee;

  constructor(private containerservicelicensesService: ContainerservicelicensesService, private router: Router,
    private cookieService: CookieService , private snackBar: MatSnackBar) { }

  ngOnInit() {

    this.containerlicence = new CompanyEmployee();
    this.containerlicence.company = new Company();
      this.containerservicelicensesService.getAllMyContainerCompanies()
        .subscribe(data => {
          this.mycompanies = data;
        },
          error => {
            this.router.navigate(['']);
          });

  }

  checkout() {

    this.containerservicelicensesService.enrolltrading(this.containerlicence.company.id)
      .subscribe(data => {
        //   this.companies = data;
         this.router.navigate(['m/dashboard']);
        this.cookieService.set('TMB_STAT', 'PROFILE', null, null, null, null, null);
        this.snackBar.open('TRADING LICENCE ENROLLED SUCCESSFULLY !!!', 'End now', {
          duration: 2000,
          
        });
      },
        error => {
          this.router.navigate(['']);
        });

  }
}
