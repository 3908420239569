import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PassWordResetDTO } from 'src/app/model/passwordresetdto.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class PasscodecheckingService {

  private appUrl = ShipStreetProps.API_PATH + 'validatePasscode.do';
  private resetUrl = ShipStreetProps.API_PATH + 'resetPassword.rs';

  constructor(private http: HttpClient) { }

  public validatePasscode(passWordReset: PassWordResetDTO) {
    return this.http.post<PassWordResetDTO>(this.appUrl , passWordReset);
  }
  public resetPasscode(emailAddress: string) {
    return this.http.post<string>(this.resetUrl , emailAddress);
  }
}
