import { Component, OnInit } from '@angular/core';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { FormControl, Validators } from '@angular/forms';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { RequireMatch } from 'src/app/trackmybox/admin/trackboxcustomers/newtrackboxcustomers/newtrackboxcustomers.component';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { TbagentnewportService } from './tbagentnewport.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-tbagentnewport',
  templateUrl: './tbagentnewport.component.html',
  styleUrls: ['./tbagentnewport.component.css']
})
export class TbagentnewportComponent implements OnInit {

  port: MasterPortsDepot;
  services = new FormControl();
  CountryList: CountryInfo[];
  errorMessage: string;
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;

  serviceList: string[] = ['Container Storage', 'Container repair and maintenance', 'Container trading', 'Container Modification'
    , 'Transportation Services', 'Container Inspection', 'Reefer PTI', 'Clip on Genset Rental'];

  constructor(private tbagentnewportService: TbagentnewportService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.port = new MasterPortsDepot();
    this.port.country = new CountryInfo();

    this.tbagentnewportService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );

      },
        error => {
          this.router.navigate(['']);
        });

  }

  onSubmit() {
    this.tbagentnewportService.save(this.port)
      .subscribe(data => {
            
    if (data==null && data.name==null || data.errorString==='Port Already Exist' ) {
      this.errorMessage='Port Already Exist'
     }
  else{
        this.router.navigate(['/trackmybox/ports']);
  }
      },
        error => {
          this.router.navigate(['']);
        });
  }
  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }

  }
}
