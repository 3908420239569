import { Candidates } from './candidate.model';
import { Company } from './company.model';
import { CountryInfo } from './countryInfo.model';

export class ContainerSupplier {
id: number;
company: Company;
createdBy: Candidates;
name: string;
address: string;
city: string;
country: CountryInfo;
phone: string;
fax: string;
poBox: string;
webSite: string;
contactName: string;
contactEmail: string;
contactPhone: string;
createdOn: Date;
vatTrnNo: string;
}
