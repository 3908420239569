import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { ContainerreturneddetailsService } from './containerreturneddetails.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NewbookingService } from 'src/app/trackmybox/bookings/newbooking/newbooking.service';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { NewequipmentcontrolService } from 'src/app/trackmybox/equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { ContainerStatus } from 'src/app/model/containerstatus.model';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { ContainerReturnedDetailsDTO } from 'src/app/model/containerreturneddetailsDTO.model';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { DepotsService } from 'src/app/trackmybox/master/depots/depots.service';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';

export interface DialogData {
  leaseUnitId: number;
}

interface status {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-containerreturneddetails',
  templateUrl: './containerreturneddetails.component.html',
  styleUrls: ['./containerreturneddetails.component.css']
})
export class ContainerreturneddetailsComponent implements OnInit {
  masterPortList: MasterPortsDepot[];
  containerstatus: status[] = [
    { viewValue: 'AVAILABLE', value: '1' },
    { viewValue: 'UNDER REPAIR', value: '2' },

  ];

  returnedDetails: ContainerReturnedDetailsDTO;
  trackboxbookingcontainer: TrackBoxBookingContainers;
  location: ContainerLocationType[];
  masterDepotList: MasterPortsDepot[];
  constructor(private containerreturneddetailsService: ContainerreturneddetailsService, private router: Router,
              private route: ActivatedRoute, private newbookingService: NewbookingService,
              private newequipmentcontrolService: NewequipmentcontrolService,
              private snackBar: MatSnackBar, public dialogoutRef: MatDialogRef<ContainerreturneddetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData, private depotsService: DepotsService,
              private portService: PortsService) { }

  ngOnInit() {
    this.returnedDetails = new ContainerReturnedDetailsDTO();
    this.trackboxbookingcontainer = new TrackBoxBookingContainers();
    this.trackboxbookingcontainer.bookingId = new TrackBoxBooking();
    this.trackboxbookingcontainer.bookingId.pol = new MasterPortsDepot();
    this.trackboxbookingcontainer.container = new TrackBoxContainer();
    this.trackboxbookingcontainer.container.locationType = new ContainerLocationType();
    this.containerreturneddetailsService.getTbbookingcontainersbyId(this.data.leaseUnitId)
      .subscribe(data => {
        this.trackboxbookingcontainer = data;
        console.log(this.trackboxbookingcontainer);
      },
        error => {
          this.router.navigate(['']);
        });

    this.newequipmentcontrolService.viewcontainerLocationType()
      .subscribe(data => {
        this.location = data;

      },
        error => {
          this.router.navigate(['']);
        });


    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortList = data;
      },
        error => {
          this.router.navigate(['']);
        });


    this.depotsService.getAllMyDepots()
      .subscribe(data => {
        this.masterDepotList = data;
      },
        error => {
          this.router.navigate(['']);
        });


  }



  saveReturnedDetails() {

    this.returnedDetails.bookedContainerId = this.data.leaseUnitId;
    this.containerreturneddetailsService.saveReturnedDetails(this.returnedDetails)
      .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 2000,

        });
        this.dialogoutRef.close(0);

      },

        error => {
          this.router.navigate(['']);
        });

  }

  onNoClick(): void {
    this.dialogoutRef.close();

  }
}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}

