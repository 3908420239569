import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ContainerSalesReportDTO } from 'src/app/model/containerSalesReportDTO.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class YearlysalesreportsService {

  private purUrl = ShipStreetProps.API_PATH + 'containersales/getYearSalesReportByCustomer';

  constructor(private http: HttpClient) { }

  public getYearSalesReportByCustomer(year: number) {
    return this.http.post<ContainerSalesReportDTO[]>(this.purUrl , year);
  }

  
}
