import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { accRoutes } from './accRouteConfig';
import { AccountsdashboardComponent } from './accountsdashboard/accountsdashboard.component';
import { SalesComponent } from './sales/sales.component';
import {
  MatAutocompleteModule, MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule, MatRadioModule,
  MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule, MatBottomSheetModule,
  MatSnackBarModule, MatTooltipModule, MatStepperModule, MatProgressSpinnerModule, MatDatepickerModule, MatSlideToggleModule
} from '@angular/material';
import { SalesinvoiceComponent } from './sales/salesinvoice/salesinvoice.component';
import { CustomersComponent } from './sales/customers/customers.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { SuppliersComponent } from './expenses/suppliers/suppliers.component';
import { NewinvoiceComponent } from './sales/salesinvoice/newinvoice/newinvoice.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NewexpensesComponent } from './expenses/newexpenses/newexpenses.component';
import { NewsuppliersComponent } from './expenses/suppliers/newsuppliers/newsuppliers.component';
import { FormsModule } from '@angular/forms';
import { ProductservicesComponent } from './sales/productservices/productservices.component';
import { NewproductservicesComponent } from './sales/productservices/newproductservices/newproductservices.component';
// tslint:disable-next-line:max-line-length
import { NewproductservicedetailsComponent } from './sales/productservices/newproductservices/newproductservicedetails/newproductservicedetails.component';
import { AccountscategoryComponent } from './sales/productservices/accountscategory/accountscategory.component';
import { NewaccountscategoryComponent } from './sales/productservices/accountscategory/newaccountscategory/newaccountscategory.component';
import { AccountingComponent } from './accounting/accounting.component';
import { AccountstypesComponent } from './accounting/accountstypes/accountstypes.component';
import { NewaccountstypeComponent } from './accounting/accountstypes/newaccountstype/newaccountstype.component';
import { AccountsdetailstypeComponent } from './accounting/accountsdetailstype/accountsdetailstype.component';
import { NewaccountsdetailstypeComponent } from './accounting/accountsdetailstype/newaccountsdetailstype/newaccountsdetailstype.component';
import { NgxPaginationModule } from 'ngx-pagination';
// import {
//   NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
//   POSITION, SPINNER, PB_DIRECTION
// } from 'ngx-ui-loader';
import { EditcustomerComponent } from './sales/customers/editcustomer/editcustomer.component';
import { NewcustomerComponent } from './sales/customers/newcustomer/newcustomer.component';
import { ReportsComponent } from './reports/reports.component';
import { CustomerbalancesummaryComponent } from './reports/customerbalancesummary/customerbalancesummary.component';
import { AccountsreceivesalespaymentComponent } from './sales/salesinvoice/accountsreceivesalespayment/accountsreceivesalespayment.component';
import { ConsigneepaymentapprovalsComponent } from './sales/consigneepaymentapprovals/consigneepaymentapprovals.component';
import { ConsigneepaymentdetailsComponent } from './sales/consigneepaymentapprovals/consigneepaymentdetails/consigneepaymentdetails.component';
import { EditaccountscategoryComponent } from './sales/productservices/accountscategory/editaccountscategory/editaccountscategory.component';
import { EditproductservicesComponent } from './sales/productservices/editproductservices/editproductservices.component';
import { ConsigneegateinpaymentapprovalsComponent } from './sales/consigneegateinpaymentapprovals/consigneegateinpaymentapprovals.component';

// const ngxUiLoaderConfig: NgxUiLoaderConfig = {
//   fgsColor: 'white',
//   fgsPosition: POSITION.centerCenter,
//   fgsSize: 40,
//   fgsType: SPINNER.foldingCube,
//   pbDirection: PB_DIRECTION.leftToRight,
//   pbThickness: 2
// }

@NgModule({
  declarations: [AccountsdashboardComponent, SalesComponent, SalesinvoiceComponent,
    CustomersComponent, ExpensesComponent, SuppliersComponent, NewinvoiceComponent, NewexpensesComponent,
    NewsuppliersComponent, ProductservicesComponent, NewproductservicesComponent, NewproductservicedetailsComponent,
    AccountscategoryComponent, NewaccountscategoryComponent, AccountingComponent, AccountstypesComponent,
    NewaccountstypeComponent, AccountsdetailstypeComponent, NewaccountsdetailstypeComponent, EditcustomerComponent,
    NewcustomerComponent, ReportsComponent,  CustomerbalancesummaryComponent, AccountsreceivesalespaymentComponent,
    ConsigneepaymentapprovalsComponent,
    ConsigneepaymentdetailsComponent,
    EditaccountscategoryComponent,
    EditproductservicesComponent,
    ConsigneegateinpaymentapprovalsComponent],
  imports: [
    CommonModule, RouterModule.forChild(accRoutes),
    MatAutocompleteModule, MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
    MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
    MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
    MatBottomSheetModule, MatSnackBarModule, MatTooltipModule, MatStepperModule, MatProgressSpinnerModule,
    MatDatepickerModule, FlexLayoutModule, FormsModule, NgxPaginationModule, 
    //NgxUiLoaderRouterModule,
   // NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  //  NgxUiLoaderHttpModule.forRoot({ showForeground: true }), 
  MatSlideToggleModule
  ]
})
export class AccountsModule { }
