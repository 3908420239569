import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CalcEquipmentType } from 'src/app/model/CalcEquipmentType.model';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';
import { CostcalculatorsettingsService } from '../../costcalculatorsettings/costcalculatorsettings.service';
import { CalcequipmenttypesService } from '../../mastersettings/calcequipmenttypes/calcequipmenttypes.service';

@Component({
  selector: 'app-calculatornewlocalcharges',
  templateUrl: './calculatornewlocalcharges.component.html',
  styleUrls: ['./calculatornewlocalcharges.component.css']
})
export class CalculatornewlocalchargesComponent implements OnInit {

  masterPortListAll: MasterPortsDepot[];
  costObj: MasterPortCostDetails;
  pol: MasterPortsDepot;
  myControl3 = new FormControl();
  filteredOptionsports: Observable<MasterPortsDepot[]>;
  containerTypeList: CalcEquipmentType[];

  constructor(private portService: PortsService, private router: Router,
              private costcalculatorsettingsService: CostcalculatorsettingsService ,
              private calcequipmenttypesService: CalcequipmenttypesService) { }

  ngOnInit() {
    this.costObj = new MasterPortCostDetails();
    this.costObj.port = new MasterPortsDepot();
    this.costObj.containerSize = new CalcEquipmentType();

    this.calcequipmenttypesService.findAllCalculatorEquipmentType()
      .subscribe(data => {
        this.containerTypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });


    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortListAll = data;
      },
        error => {
          this.router.navigate(['']);
        });


  }

  onSubmit() {
    this.costcalculatorsettingsService.saveCalculatorCost(this.costObj)
      .subscribe(data => {
      this.router.navigate(['/calculator/costcalculatorsettingslist']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
