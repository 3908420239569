import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ExistingemployeesService {

  constructor(private http: HttpClient) {}
  
   private appUrl = ShipStreetProps.API_PATH + '/trackboxemployee/findAllCompanyEmployeeRecords.do';
   private saveUrl = ShipStreetProps.API_PATH + 'trackboxemployee/asignTrackMyBoxPrinciplePermission.do';
  private remeUrl = ShipStreetProps.API_PATH + 'trackboxemployee/removeTrackMyBoxPermission.do';

   getCompanyEmployee() {
     return this.http.get<CompanyEmployee[]>(this.appUrl);
   }

   asignTrackMyBoxPermission(comemployee: CompanyEmployee) {
    return this.http.post<CompanyEmployee[]>(this.saveUrl , comemployee);
  }

  removeTrackMyBoxPermission(comemployee: CompanyEmployee) {
    return this.http.post<CompanyEmployee[]>(this.remeUrl , comemployee);
  }
}
