import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculatorpricing',
  templateUrl: './calculatorpricing.component.html',
  styleUrls: ['./calculatorpricing.component.css']
})
export class CalculatorpricingComponent implements OnInit {

  constructor() { }
  showmeBasic: boolean;
  showmeStandard: boolean;
  showmePremium: boolean;

  ngOnInit() {
  }

}
