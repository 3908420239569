import { Component, OnInit } from '@angular/core';
import { Candidates } from 'src/app/model/candidate.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/utils/common.service';
import { TrackboxheaderService } from './trackboxheader.service';
import { CookieService } from 'ngx-cookie-service';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';

@Component({
  selector: 'app-trackboxheader',
  templateUrl: './trackboxheader.component.html',
  styleUrls: ['./trackboxheader.component.css']
})
export class TrackboxheaderComponent implements OnInit {
  isGuest: boolean;
  candidate: Candidates;
  sessionCandidate: SessionCandidate;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  constructor( private trackboxheaderService: TrackboxheaderService, private router: Router, private commonService: CommonService,
               private cookieService: CookieService, private searchBarService: SearchbarService) { }

  ngOnInit() {
    this.isGuest = true;

    this.sessionCandidate = this.commonService.getSessionCandidate();

    this.trackboxheaderService.sessionCandidate()
      .subscribe( data => {
        this.candidate = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  switchprofile() {
    this.cookieService.delete('TMB_COMP');
    this.cookieService.delete('TMB_COMP_NM');
    this.router.navigate(['/m/trackboxprofileintro']);
  }

  logout() {
     this.searchBarService.logout()
      .subscribe( data => {
        this.commonService.removeRSCookieValues();
         // this.sessioncandidate = data;
        this.router.navigate(['']);
        },
      error => {
        this.router.navigate(['']);
        });
  }



}
