import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { AccountBalance } from 'src/app/model/accountbalance.model';
// import { ConsigneePaymentSummaryDTO } from 'src/app/model/consigneepaymentsummarydto.modet';

@Injectable({
  providedIn: 'root'
})
export class AccountsdashboardService {

  constructor(private http: HttpClient) { }

  // private appUrl = DepotProps.API_PATH + '/accountdashboard/getAccountBalance.do';
  // private consigUrl = DepotProps.API_PATH + '/accountdashboard/getDashboardConsigneeThisMonthPaymentAmount.do';

  //  public getDashboardAccountBalance() {
  //   return this.http.get<AccountBalance[]>(this.appUrl );
  // }

  //  public getDashboardConsigneeThisMonthPaymentAmount() {
  //   return this.http.get<ConsigneePaymentSummaryDTO>(this.consigUrl );
  // }
}
