import { CompanyVoyage } from './../../../model/companyvoyage.model';
import { Router, ActivatedRoute } from '@angular/router';
import { VoyageService } from './voyage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voyage',
  templateUrl: './voyage.component.html',
  styleUrls: ['./voyage.component.css']
})
export class VoyageComponent implements OnInit {
 companyvoyageList: CompanyVoyage[];
  constructor(private voyageService: VoyageService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.voyageService.getAllMyVoyages()
    .subscribe(data => {
      this.companyvoyageList = data;
    },
      error => {
        this.router.navigate(['']);
      });
  }

}
