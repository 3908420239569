import { CacheObject } from '../../model/cacheobject.model';
import { Candidates } from '../../model/candidate.model';
import { EditprofileService } from './editprofile.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Company } from '../../model/company.model';
import { CompaniesService } from 'src/app/company/companies/companies.service';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.css']
})

export class EditprofileComponent implements OnInit {
  myControl: FormControl;
  designations: CacheObject[];
  compDiv: boolean;
  desgDiv: boolean;
  titleDiv: boolean;
  candidate: Candidates;
  showme: boolean;
  showExpIcon: boolean;
  bannerImg = '../../ng/img/candidatecenter-banner.png';
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  companies: Company[];
  previewFile1: any;


  constructor(private editprofileService: EditprofileService , private router: Router ,
              private companiesService: CompaniesService ,  private snackBar: MatSnackBar) { }


 selectedFile: File;
  previewFile: any;
  onFileChanged(event) {
     console.log(event.target.files[0]);
     if (event.target) {
      const reader = new FileReader();
      this.selectedFile = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event1: any) => {
        this.previewFile = event1.target.result;
      };
    }

  }

  onFileResumeChanged(event) {
     console.log(event.target.files[0]);
     if (event.target) {
      const reader = new FileReader();
      this.selectedFile = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event1: any) => {
        this.previewFile1 = event1.target.result;
      };
    }

  }

  onUpload() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    // alert(JSON.stringify(uploadData));
    // console.log(uploadData)
    this.editprofileService.updateProfileImage(uploadData).subscribe( data => {
     console.log(data);

    },
    error => {
      this.router.navigate(['']);
      });

  }


  onResumeUpload() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.editprofileService.updateResume(uploadData).subscribe( data => {
        this.snackBar.open('Resume Upload Successfully', '', {
            duration: 5000
          });

    },
    error => {
      this.router.navigate(['']);
      });

  }

  ngOnInit() {
    this.myControl = new FormControl();
    this.titleDiv = true;
    this.desgDiv = true;
    this.compDiv = true;
    this.editprofileService.candidate()
      .subscribe( data => {
        this.candidate = data;
      },
      error => {
        this.router.navigate(['']);
        });

    this.editprofileService.getDesignation()
      .subscribe( data => {
        this.designations = data;
      },
      error => {
        this.router.navigate(['']);
        });

    this.companiesService.getAllCompanies()
      .subscribe( data => {
        this.companies = data;
      },
      error => {
        this.router.navigate(['']);
        });

  }

updateCandidateName(candidate: Candidates) {
   this.editprofileService.updateCandidateName(this.candidate)
      .subscribe( data => {
        this.titleDiv = true;
      },
      error => {
        this.router.navigate(['']);
        });
}
  updateCandidateDesignation( candidate: Candidates) {
   this.editprofileService.updateCandidateDesignation(this.candidate)
      .subscribe( data => {
        this.titleDiv = true;
      },
      error => {
        this.router.navigate(['']);
        });
}
  updateCandidateProfile(candidate: Candidates) {
   this.editprofileService.updateCandidateProfile(this.candidate)
      .subscribe( data => {
     //   candidate.designation.
        this.titleDiv = true;
      },
      error => {
        this.router.navigate(['']);
        });
}

  onCreate() {
    this.router.navigate(['']);
  }

}
