import { Candidates } from './candidate.model';

export class CandidateEmailNotification {
    id: number;
    candidate: Candidates;
    feeds: boolean;
    jobs: boolean;
    comments: boolean;
    likes: boolean;
    shares: boolean;
    connection: boolean;
    follow: boolean;
    posts: boolean;
    postComment: boolean;
    postLike: boolean;
}
