import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Customer } from 'src/app/model/customer.model';

@Injectable({
  providedIn: 'root'
})
export class EditcustomerService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/accountscustomer/getCustomer.do';
  private saveUrl = ShipStreetProps.API_PATH + '/accountscustomer/updateCustomer.do';

  public getCustomer(id: string) {
    return this.http.post<Customer>(this.appUrl, id);
  }
  public updateCustomer(customer: Customer) {
    return this.http.post<string>(this.saveUrl, customer);
  }
}
