import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accountsdetailstype',
  templateUrl: './accountsdetailstype.component.html',
  styleUrls: ['./accountsdetailstype.component.css']
})
export class AccountsdetailstypeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

