import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplacequoteview',
  templateUrl: './marketplacequoteview.component.html',
  styleUrls: ['./marketplacequoteview.component.css']
})
export class MarketplacequoteviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
