import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';
import { AccountsAccount } from 'src/app/model/accountsaccount.model';
import { AccountsTax } from 'src/app/model/accountstax.model';
import { AccountsProductServices } from 'src/app/model/accountsproductservices.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewproductservicedetailsService {

  constructor(private http: HttpClient) { }

    private appUrl = ShipStreetProps.API_PATH + 'accountsproductcategory/getAllCategoryList';
    private accUrl = ShipStreetProps.API_PATH + 'getAccountsAccount';
    private taxUrl = ShipStreetProps.API_PATH + 'accountstax/getAccountsTaxList';
    private saveUrl = ShipStreetProps.API_PATH + 'productnservices/newproductnservice';

    public getAccountsCategory() {
        return this.http.get<AccountsProductCategory[]>(this.appUrl);
    }

    public getAccountsInventoryAssets() {
        return this.http.post<AccountsAccount[]>(this.accUrl , 1);
    }

    public getAccountsIncome() {
        return this.http.post<AccountsAccount[]>(this.accUrl , 2);
    }

    public getAccountsExpenses() {
        return this.http.post<AccountsAccount[]>(this.accUrl , 3);
    }

     public getAccountsTaxList() {
        return this.http.get<AccountsTax[]>(this.taxUrl);
    }

     public newproductnservice(productnservice: AccountsProductServices) {
        return this.http.post<AccountsTax[]>(this.saveUrl , productnservice);
    }
}
