import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trackeremployeeproperty',
  templateUrl: './trackeremployeeproperty.component.html',
  styleUrls: ['./trackeremployeeproperty.component.css']
})
export class TrackeremployeepropertyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
