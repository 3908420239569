import { ContainerCompany } from './containercompany.model';
import { ContainerOnewayMove } from './containeronewaymove.model';

export class ContainerOnewayMoveRequest {
    id: number;
    requestdCompany: ContainerCompany;
    onewayMove: ContainerOnewayMove;
    numberOfUnits: number;
    dateRequested: Date;
}
