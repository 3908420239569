import { Company } from './company.model';
import { Candidates } from './candidate.model';
import { MasterVessels } from './mastervessels.model';

import { ContainerStatus } from './containerstatus.model';
import { MasterPortsDepot } from './masterportsdepot.model';
import { TrackboxContainerStatus } from '../trackmybox/trackboxcontainerstatus.model';


export class MasterVoyage {
    id: number;
    company: Company;
    candi: Candidates;
    vessel: MasterVessels;
    pol: MasterPortsDepot;
    pod: MasterPortsDepot;
    transhipment: MasterPortsDepot;
    polEta: Date;
    polLoading: Date;
    polCutoff: Date;
    polCutoffTime: Date;
    vesselStartsOn: Date;
    polEtd: Date;
    podEta: Date;
    podReachedOn: Date;
    podDischargedOn: Date;
    voyageNumber: string;
    containerStatus: TrackboxContainerStatus;
    congsigneePickedOn: Date;
    congsigneeReturnedOn: Date;

}
