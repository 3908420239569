import { Component, OnInit } from '@angular/core';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { AddpurchasecontainerService } from './addpurchasecontainer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/utils/common.service';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { PurchaselocationService } from 'src/app/containers/master/purchaselocation/purchaselocation.service';
import { ContainerPurchaseLocation } from 'src/app/model/containerpurchaselocation.model';
import { LocationtypeService } from 'src/app/containers/master/locationtype/locationtype.service';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { FormControl } from '@angular/forms';
import { Containers } from 'src/app/model/containers.model';

@Component({
  selector: 'app-addpurchasecontainer',
  templateUrl: './addpurchasecontainer.component.html',
  styleUrls: ['./addpurchasecontainer.component.css']
})
export class AddpurchasecontainerComponent implements OnInit {

  status = new FormControl();
  ContainerStatus: string[] = ['AVAILABLE', 'IDLE'];
  sessionCandidate: SessionCandidate;
  units: ContainerPurchasedUnits;
  containerPurLocation: ContainerPurchaseLocation[];
  containerLocationType: ContainerLocationType[];
  isOpen: boolean;

  constructor(private addpurchasecontainerService: AddpurchasecontainerService, private router: Router,
              private commonService: CommonService, private route: ActivatedRoute,
              private purchaselocationService: PurchaselocationService, 
              private locationtypeService: LocationtypeService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.units = new ContainerPurchasedUnits();
    this.units.purchase = new ContainerPurchase();
    this.units.container = new Containers();
    this.units.locationType = new ContainerLocationType();
    this.units.location = new ContainerPurchaseLocation();

    this.purchaselocationService.findAllContainerPurchaseLocationsByOrder()
      .subscribe(data => {
        this.containerPurLocation = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.locationtypeService.findAllContainerLocationTypeByOrder()
      .subscribe(data => {
        this.containerLocationType = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveContainerPurchasedUnits() {
    this.units.purchase.id = Number(this.route.snapshot.paramMap.get('id'));
  //  this.units.container.containerNumber 
    this.addpurchasecontainerService.saveContainerPurchasedUnits(this.units)
      .subscribe(data => {
        alert('ADDED SUCCESSFULLY');
        this.isOpen = true;
        this.router.navigate(['containers/containerpurchasedetails' , this.route.snapshot.paramMap.get('id')]);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  setMonthAndYear(event , dp){}


}
