import { Component, OnInit } from '@angular/core';
import { SuppliersService } from './suppliers.service';
import { Router } from '@angular/router';
import { Suppliers } from 'src/app/model/suppliers.model';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {

  p = 1;
  searchText: string;
  supplierListAll: Suppliers[];
  supplierList: Suppliers[];

  constructor(private suppliersService: SuppliersService, private router: Router) { }

  ngOnInit() {
    this.suppliersService.getAllSuppliers()
      .subscribe(data => {
        this.supplierListAll = data;
        this.supplierList = this.supplierListAll;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  search() {
    if (this.searchText !== '') {
      this.supplierList = this.supplierListAll.filter(res => {
        return (res.companyName !== null && res.companyName.toLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.country !== null && res.country.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.state !== null && res.state.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.city !== null && res.city.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.supplierList = this.supplierListAll;
    }
  }

}
