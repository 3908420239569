import { TrackBoxPurchaseStatus } from './../../../model/trackboxpurchasestatus.model';
import { ContainerStatus } from './../../../model/containerstatus.model';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { TrackBoxContainer } from './../../../model/trackboxcontainer.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { CountryInfo } from './../../../model/countryInfo.model';
import { Router } from '@angular/router';
import { NewequipmentcontrolService } from './newequipmentcontrol.service';
import { Component, OnInit } from '@angular/core';
import { Containers } from 'src/app/model/containers.model';
import { TrackBoxContainerDTO } from './../../../model/trackboxcontainerDTO.model';
import { DepotsService } from '../../master/depots/depots.service';
import { PortsService } from '../../master/ports/ports.service';
import { TrackBoxCarrier } from './../../../model/trackboxcarrier.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { TrackboxContainerStatus } from '../../trackboxcontainerstatus.model';
import { TrackboxsupplierService } from '../../admin/trackboxsupplier/trackboxsupplier.service';
import { TrackBoxContainerSupplier } from 'src/app/model/trackboxcontainersupplier.model';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';


interface Purchase {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-newequipmentcontrol',
  templateUrl: './newequipmentcontrol.component.html',
  styleUrls: ['./newequipmentcontrol.component.css']
})
export class NewequipmentcontrolComponent implements OnInit {

  equipment: Containers;
  CountryList: CountryInfo[];
  countryid: CountryInfo;
  masterDepotList: MasterPortsDepot[];
  masterPortList: MasterPortsDepot[];
  port: MasterPortsDepot;
  ContainerTypeList: ContainerType[];
  location: ContainerLocationType[];
  containerstatus: ContainerStatus[];
  status: TrackboxContainerStatus[];
  locationType: ContainerLocationType;
  trackcontainer: TrackBoxContainer;
  purchasestat: TrackBoxPurchaseStatus[];
  purchasestatus: TrackBoxPurchaseStatus;
  trackboxcontainerDto: TrackBoxContainerDTO;
   carriers: TrackBoxCarrier;
   countryId = 0;

   myForm: FormGroup;

   containerNumberFormControl = new FormControl('', [Validators.required,Validators.pattern('[A-Z]{3}U[0-9]{7}') ]);

   myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
   supplierList: TrackBoxContainerSupplier[];


   onKeyUp(event) {
    const value = this.containerNumberFormControl.value.toUpperCase();
    this.containerNumberFormControl.setValue(value, { emitEvent: false });
    this.equipment.containerNumber = value;
  }
  


  // onFileSelected() {
  //   const inputNode: any = document.querySelector('#file');
  //   if (typeof (FileReader) !== 'undefined') {
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //        this.srcResult = e.target.result;
  //     };
  //     reader.readAsArrayBuffer(inputNode.files[0]);
  //   }
  // }
  constructor(private newequipmentcontrolService: NewequipmentcontrolService, private router: Router,
              private depotsService: DepotsService, private portService: PortsService,
              private trackboxsupplierService: TrackboxsupplierService) { }

              

  ngOnInit() {

  this.equipment = new Containers();
  this.equipment.equipmentType = new ContainerType();
  this.countryid = new CountryInfo();
  this.locationType = new ContainerLocationType();
  this.port = new MasterPortsDepot();
  this.trackcontainer = new TrackBoxContainer();
  this.trackcontainer.locationType = new ContainerLocationType();
  this.trackcontainer.country = new CountryInfo();
  this.trackcontainer.port = new MasterPortsDepot();
  this.trackcontainer.container = new Containers();
  this.trackcontainer.containerStatus = new TrackboxContainerStatus();
  this.trackcontainer.purchaseStatus = new TrackBoxPurchaseStatus();
  this.trackcontainer.leaseFrom = new TrackBoxContainerSupplier();
  this.trackcontainer.purchasedFrom = new TrackBoxContainerSupplier();
  this.carriers = new TrackBoxCarrier();

  this.trackboxcontainerDto = new TrackBoxContainerDTO();

  this.myForm = new FormGroup({
    containerNumber: this.containerNumberFormControl
  });
            

  this.newequipmentcontrolService.viewCountry()
  .subscribe(data => {
    this.CountryList = data;
    this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter4(val))
    );

    console.log(this.filteredOptionscountry);
},
    error => {
      this.router.navigate(['']);
    });

  this.depotsService.getAllMyDepots()
    .subscribe(data => {
      this.masterDepotList = data;
    },
      error => {
        this.router.navigate(['']);
      });

  this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortList = data;
      },
        error => {
          this.router.navigate(['']);
        });

  this.newequipmentcontrolService.viewcontainerType()
    .subscribe( data => {
      this.ContainerTypeList = data;
    },
    error => {
      this.router.navigate(['']);
    });
  this.newequipmentcontrolService.viewcontainerLocationType()
    .subscribe( data => {
      this.location = data;

    },
    error => {
      this.router.navigate(['']);
    });
  this.newequipmentcontrolService.viewcontainerStatus()
    .subscribe( data => {
      this.containerstatus = data;
    },
    error => {
      this.router.navigate(['']);
    });

  this.newequipmentcontrolService.viewPurchaseStatus()
    .subscribe( data => {
      this.purchasestat = data;
    },
    error => {
      this.router.navigate(['']);
    });

  this.newequipmentcontrolService.getAllMySupplier()
    .subscribe(data => {
      this.supplierList = data;
    },
      error => {
        this.router.navigate(['']);
      });

}

onSubmit() {
  if (this.myForm.valid) {
  }
  
  this.trackboxcontainerDto.container = this.equipment;
  this.trackboxcontainerDto.trackkBoxContainer = this.trackcontainer;
  this.newequipmentcontrolService.save(this.trackboxcontainerDto)
    .subscribe(data => {
    this.router.navigate(['trackmybox/equipmentcontroller']);
     },
       error => {
         this.router.navigate(['']);
       });
  }
  // trackSubmit() {
  //   this.locationType.id = Number(this.route.snapshot.paramMap.get('id'));
  //   this.depot.id = Number(this.route.snapshot.paramMap.get('id'));
  //   this.countryid.id = Number(this.route.snapshot.paramMap.get('id'));
  //   this.port.id = Number(this.route.snapshot.paramMap.get('id'));
  //   this.status.id = Number(this.route.snapshot.paramMap.get('id'));
  //   // this.carriers.id = Number(this.route.snapshot.paramMap.get('id'));
  //   this.newequipmentcontrolService.saveTrack(this.trackcontainer)
  //   .subscribe(data => {
  //   // this.router.navigate(['/trackmybox']);
  //    },
  //      error => {
  //        this.router.navigate(['']);
  //      });
  // }
  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }

}
}
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}

