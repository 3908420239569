import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';

@Component({
  selector: 'app-trackerheader',
  templateUrl: './trackerheader.component.html',
  styleUrls: ['./trackerheader.component.css']
})
export class TrackerheaderComponent implements OnInit {
  isGuest: boolean;
  sessionCandidate: SessionCandidate;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  constructor(private commonService: CommonService , private cookieService: CookieService, private router: Router,
              private searchBarService: SearchbarService) { }

  ngOnInit() {
    this.isGuest = true;

    this.sessionCandidate = this.commonService.getSessionCandidate();
  }

  logout() {
     this.searchBarService.logout()
      .subscribe( data => {
        this.commonService.removeRSCookieValues();
         // this.sessioncandidate = data;
        this.router.navigate(['']);
        },
      error => {
        this.router.navigate(['']);
        });
  }

}
