import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { CandidateConnect } from '../../../model/candidateConnect.model';

@Injectable({
  providedIn: 'root'
})
export class CandidateconnectionService {

  private connectionsUrl = ShipStreetProps.API_PATH + 'friendsList.do';

  constructor(private http: HttpClient) { }

  public candidateConnections(candidateId: string) {
    return this.http.post<CandidateConnect[]>(this.connectionsUrl , candidateId);
  }
}
