import { CalcEquipmentType } from './CalcEquipmentType.model';
import { Company } from './company.model';
import { MasterPortsDepot } from './masterportsdepot.model';
import { MasterVesselOperator } from './mastervesseloperator.model';

export class MasterPortCostDetails {
    id: number;
    company: Company;
    port: MasterPortsDepot;
    finalport: MasterPortsDepot;
    transhipment: string;
    returnType: string;
    cargos: string;
    transhipmentPort: MasterPortsDepot;
    vesselOperator: MasterVesselOperator;
    polThcCollection: number;
    polThcCost: number;
    polThcEmptyCollection: number;
    polThcEmptyCost: number;
    containerSize: CalcEquipmentType;
    polThcDgCollection: number;
    polThcDgCost: number;
    polThcOgCollection: number;
    polThcOgCost: number;
    polBlCollection: number;
    polBlCost: number;
    polChargeHeadName: string;
    polChargeHeadCollection: number;
    polChargeHeadCost: number;
    polSpecialGearChargeCollection: number;
    polSpecialGearChargeCost: number;
    polSealChargeCollection: number;
    polSealChargeCost: number;
    fpodThcCollection: number;
    fpodThcCost: number;
    fpodThcEmptyCollection: number;
    fpodThcEmptyCost: number;
    fpodDofeeCollection: number;
    fpodDofeeCost: number;
    fpodDoCreationCollection:number;
    fpodDoCreationCost: number;
    fpodPscCollection: number;
    fpodPscCost: number;
    fpodSealChargeCollection: number;
    fpodSealChargeCost: number;
    fpodSpecialEqpSurchargeCollection: number;
    fpodSpecialEqpSurchargeCost: number;
    fpodWasingChargeCollection: number;
    fpodWasingChargeCost: number;
    fpodSpecialGearingCollection: number;
    fpodSpecialGearingCost: number;
    fpodEquipmntMaintenanceCollection: number;
    fpodEquipmntMaintenanceCost: number;
    fpodChargeHeadName: string;
    fpodChargeHeadCollection: number;
    fpodChargeHeadCost: number;
    transhipentCost: number;
    transhipmentDesc1: string;
    transhipmentDesc1Cost: number;
    transhipmentDesc2: string;
    transhipmentDesc2Cost: number;
    transhipmentDesc3: string;
    transhipmentDesc3Cost: number;
    
    fpodThcDgCollection: number;
    fpodThcDgCost: number;
    
    fpodThcOgCollection: number;
    fpodThcOgCost: number;
}
