import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPresales } from 'src/app/model/containerpresales.model';
import { ContainerSales } from 'src/app/model/containersales.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class PresalesService {

//  private listUrl = ShipStreetProps.API_PATH + 'containersales/findAllContainerSales';
  private orderlistUrl = ShipStreetProps.API_PATH + 'containerpresales/findAllContPreSalesByCompany';
 // private dtorderlistUrl = ShipStreetProps.API_PATH + 'containersales/findAllContSalesByCompanyByDate';

  constructor(private http: HttpClient) { }

  // public findAllContainerSales() {
  //   return this.http.get<ContainerSales[]>(this.listUrl);
  // }

  public findAllContPreSalesByCompany() {
    return this.http.get<ContainerPresales[]>(this.orderlistUrl);
  }

  // public findAllContSalesByCompanyByDate() {
  //   return this.http.get<ContainerSales[]>(this.dtorderlistUrl);
  // }

  
}
