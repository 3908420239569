import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';

@Injectable({
  providedIn: 'root'
})
export class NewcustomerService {

 private saveUrl = ShipStreetProps.API_PATH + 'containercustomer/saveContainerCustomer';
  constructor(private http: HttpClient) { }

  public saveContainerCustomer(customer: ContainerCustomer) {
    return this.http.post<string>(this.saveUrl , customer);
  }
}
