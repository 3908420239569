import { Component, OnInit } from '@angular/core';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MasterPortVesselOperatorCosts } from 'src/app/model/masterportvesseloperatorcosts.model';
import { VesseloperatorcostsService } from './vesseloperatorcosts.service';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';
import { CalcvesseloperatorService } from '../calcvesseloperator/calcvesseloperator.service';
import { CalcequipmenttypesService } from '../mastersettings/calcequipmenttypes/calcequipmenttypes.service';
import { CalcEquipmentType } from 'src/app/model/CalcEquipmentType.model';

@Component({
  selector: 'app-vesseloperatorcosts',
  templateUrl: './vesseloperatorcosts.component.html',
  styleUrls: ['./vesseloperatorcosts.component.css']
})
export class VesseloperatorcostsComponent implements OnInit {

  masterPortListAll: MasterPortsDepot[];
  pol: MasterPortsDepot;
  myControl3 = new FormControl();
  filteredOptionsports: Observable<MasterPortsDepot[]>;
  vocostObj: MasterPortVesselOperatorCosts;
  vesselOperators: MasterVesselOperator[];
  containerTypeList: CalcEquipmentType[];

  constructor(private portService: PortsService, private calcvesseloperatorService: CalcvesseloperatorService, private router: Router,
    private vesseloperatorcostsService: VesseloperatorcostsService , private calcequipmenttypesService: CalcequipmenttypesService) { }

  ngOnInit() {
    this.vocostObj = new MasterPortVesselOperatorCosts();
    this.vocostObj.loadport = new MasterPortsDepot();
    this.vocostObj.finalport = new MasterPortsDepot();
    this.vocostObj.vesselOperator = new MasterVesselOperator();
    this.vocostObj.containerSize = new CalcEquipmentType();

    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortListAll = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.calcvesseloperatorService.findAllMasterVesselOperatorSortByName()
      .subscribe(data => {
        this.vesselOperators = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.calcequipmenttypesService.findAllCalculatorEquipmentType()
        .subscribe(data => {
          this.containerTypeList = data;
        },
          error => {
            this.router.navigate(['']);
          });
  }

  onSubmit() {
    this.vesseloperatorcostsService.saveVesselSlotCost(this.vocostObj)
      .subscribe(data => {
        this.router.navigate(['/calculator/vesseloperatorcostslist']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
