import { CandidateCertificate } from '../../model/candidateCertificate.model';
import { Certificate } from '../../model/certificate.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CacheObject } from '../../model/cacheobject.model';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  // private appUrl = ShipStreetProps.API_PATH + 'candidateCertificate.do';
  private certUrl = ShipStreetProps.API_PATH + 'certificate.do';
  private saveUrl = ShipStreetProps.API_PATH + 'newCertificate.do';
  private updateUrl = ShipStreetProps.API_PATH + 'updateCandidateCertificate.do';
  private deleteUrl = ShipStreetProps.API_PATH + 'updateCandidateCertificate.do';

  constructor(private http: HttpClient) { }

  // public candidateCertificates() {
  //   return this.http.get<CandidateCertificate[]>(this.appUrl);
  // }

   public getAllCertificates() {
    return this.http.get<CacheObject[]>(this.certUrl);
  }

   public saveType(certificate: CandidateCertificate) {
    return this.http.post<CandidateCertificate[]>(this.saveUrl, certificate);
  }
  public updateCandidateCertificate(certificate: CandidateCertificate) {
    return this.http.post<string>(this.updateUrl, certificate);
  }
   public deleteCertification(certificate: CandidateCertificate) {
    return this.http.post<CandidateCertificate[]>(this.deleteUrl, certificate);
  }
}
