import { TbagentaddcontainerstobookingComponent } from './tbagentbooking/tbagentaddcontainerstobooking/tbagentaddcontainerstobooking.component';
import { EdittbagentbookingComponent } from './tbagentbooking/edittbagentbooking/edittbagentbooking.component';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { TbagentaddconsigneeComponent } from './tbagentbooking/tbagentaddconsignee/tbagentaddconsignee.component';
import { TbagentaddclearingagentComponent } from './tbagentbooking/tbagentaddclearingagent/tbagentaddclearingagent.component';
import { NewtbagentbookingComponent } from './tbagentbooking/newtbagentbooking/newtbagentbooking.component';
import { Routes } from '@angular/router';
import { TbagentlayoutComponent } from 'src/app/_layouts/tbagentlayout/tbagentlayout.component';
import { AgentsComponent } from './agents.component';
import { TbagentmyboxesComponent } from './tbagentmyboxes/tbagentmyboxes.component';
import { TbagentbookingComponent } from './tbagentbooking/tbagentbooking.component';
import { TbagentequipmentsComponent } from './tbagentequipments/tbagentequipments.component';
import { ContainertypesComponent } from '../master/containertypes/containertypes.component';
import { PortsComponent } from '../master/ports/ports.component';
import { DepotsComponent } from '../master/depots/depots.component';
import { CompanyvesseloperatorComponent } from '../master/companyvesseloperator/companyvesseloperator.component';
import { CompanyvesselsComponent } from '../master/companyvessels/companyvessels.component';
import { BoxemployeesComponent } from '../admin/boxemployees/boxemployees.component';
import { TrackboxcarriersComponent } from '../admin/trackboxcarriers/trackboxcarriers.component';
import { TrackboxsupplierComponent } from '../admin/trackboxsupplier/trackboxsupplier.component';
import { TrackboxcustomersComponent } from '../admin/trackboxcustomers/trackboxcustomers.component';
import { BoxprinciplesComponent } from './boxprinciples/boxprinciples.component';
import { NewbookingComponent } from '../bookings/newbooking/newbooking.component';
import { TbagentbookingdetailsComponent } from './tbagentbooking/tbagentbookingdetails/tbagentbookingdetails.component';
import { BoxagentsComponent } from '../admin/boxagents/boxagents.component';
import { TbagentcustomersComponent } from './tbagentcustomers/tbagentcustomers.component';
import { NewtbagentcustomersComponent } from './tbagentcustomers/newtbagentcustomers/newtbagentcustomers.component';
import { TbagentsuppliersComponent } from './tbagentsuppliers/tbagentsuppliers.component';
import { NewtbagentsuppliersComponent } from './tbagentsuppliers/newtbagentsuppliers/newtbagentsuppliers.component';
import { TbagentcarriersComponent } from './tbagentcarriers/tbagentcarriers.component';
import { NewtbagentcarriersComponent } from './tbagentcarriers/newtbagentcarriers/newtbagentcarriers.component';
import { SearchboxprincipleComponent } from './boxprinciples/searchboxprinciple/searchboxprinciple.component';
import { TbagentrequesttoprincipleComponent } from './tbagentbooking/tbagentrequesttoprinciple/tbagentrequesttoprinciple.component';
import { TbagentviewrequestsComponent } from './tbagentbooking/tbagentviewrequests/tbagentviewrequests.component';
import { TbagentportsComponent } from './master/tbagentports/tbagentports.component';
import { TbagentnewportComponent } from './master/tbagentports/tbagentnewport/tbagentnewport.component';
import { ViewprinciplerequestComponent } from './boxprinciples/viewprinciplerequest/viewprinciplerequest.component';
import { TbagentupdatecontainerstatuswithbookingComponent } from './tbagentbooking/tbagentupdatecontainerstatuswithbooking/tbagentupdatecontainerstatuswithbooking.component';
import { DeliveryorderdateupdateComponent } from './tbagentbooking/tbagentbookingdetails/deliveryorderdateupdate/deliveryorderdateupdate.component';
import { ContainerreturneddetailsComponent } from './tbagentbooking/tbagentbookingdetails/containerreturneddetails/containerreturneddetails.component';
import { AgentemployeesComponent } from './admin/agentemployees/agentemployees.component';
import { NewagentemployeeComponent } from './admin/agentemployees/newagentemployee/newagentemployee.component';
import { TbagentdepotComponent } from './master/tbagentdepot/tbagentdepot.component';
import { TbagentnewdepotComponent } from './master/tbagentdepot/tbagentnewdepot/tbagentnewdepot.component';
import { TbagentvesseloperatorComponent } from './master/tbagentvesseloperator/tbagentvesseloperator.component';
import { TbagentnewvesseloperatorComponent } from './master/tbagentvesseloperator/tbagentnewvesseloperator/tbagentnewvesseloperator.component';
import { TbagentnewvesselComponent } from './master/tbagentvessels/tbagentnewvessel/tbagentnewvessel.component';
import { TbagentvesselsComponent } from './master/tbagentvessels/tbagentvessels.component';
import { TbagentequipmentlistComponent } from './tbagentequipments/tbagentequipmentlist/tbagentequipmentlist.component';
import { TbAgentManagedportListComponent } from './master/tbagentmanagedportList/tbagentmanagedportList.component';
import { TbagentaddmanagedportlistComponent } from './master/tbagentmanagedportList/tbagentaddmanagedportlist/tbagentaddmanagedportlist.component';
import { TbagentmanageddepotsComponent } from './master/tbagentdepot/tbagentmanageddepots/tbagentmanageddepots.component';
import { TbagentblupdateComponent } from './tbagentbooking/tbagentbookingdetails/tbagentblupdate/tbagentblupdate.component';
import { TbagenteditcustomerComponent } from './tbagentcustomers/tbagenteditcustomer/tbagenteditcustomer.component';
import { TbagenteditsupplierComponent } from './tbagentsuppliers/tbagenteditsupplier/tbagenteditsupplier.component';
import { TbagentupdatebookingequipmentComponent } from './tbagentbooking/tbagentupdatebookingequipment/tbagentupdatebookingequipment.component';
import { TbagentblviewComponent } from './tbagentbooking/tbagentbookingdetails/tbagentblview/tbagentblview.component';
import { TbagentdoviewComponent } from './tbagentbooking/tbagentbookingdetails/tbagentdoview/tbagentdoview.component';
import { ImportbookingComponent } from './tbagentbooking/importbooking/importbooking.component';
import { PrinciplerequestdetailsComponent } from './boxprinciples/principlerequestdetails/principlerequestdetails.component';
import { NewboxprincipleComponent } from './boxprinciples/newboxprinciple/newboxprinciple.component';
import { TbagentpricingComponent } from './tbagentpricing/tbagentpricing.component';
import { TbagentsubscriptioncheckoutComponent } from './tbagentsubscriptioncheckout/tbagentsubscriptioncheckout.component';
export const tbagentRoutes: Routes = [
  {
    path: 'tbagentmode',
    component: TbagentlayoutComponent,
    children: [
      { path: '', component: AgentsComponent },
      { path: 'tbagentmyboxes', component: TbagentmyboxesComponent },
      { path: 'tbagentbookings', component: TbagentbookingComponent },
      { path: 'newtbagentbooking', component: NewtbagentbookingComponent },
      { path: 'tbagentequipmentcontroller', component: TbagentequipmentsComponent },
      { path: 'containertypes', component: ContainertypesComponent },
      { path: 'tbagentports', component: TbagentportsComponent },
      { path: 'tbagentnewport', component: TbagentnewportComponent },
      { path: 'agentdepots', component: TbagentdepotComponent },
      { path: 'newagentdepot', component: TbagentnewdepotComponent },
      { path: 'agentvesseloperators', component: TbagentvesseloperatorComponent },
      { path: 'newagentvesseloperators', component: TbagentnewvesseloperatorComponent },
      { path: 'tbagentcompanyvessels', component: TbagentvesselsComponent },
      { path: 'tbagentnewvessels', component: TbagentnewvesselComponent },
      { path: 'boxagentemployees', component: AgentemployeesComponent },
      { path: 'newboxagentemployee', component: NewagentemployeeComponent },
      { path: 'boxprinciples', component: BoxprinciplesComponent },
      { path: 'searchboxprinciples', component: SearchboxprincipleComponent },
      { path: 'newboxprinciple', component: NewboxprincipleComponent },
      { path: 'tbagentcarriers', component: TbagentcarriersComponent },
      { path: 'newtbagentcarriers', component: NewtbagentcarriersComponent },
      { path: 'tbagentsuppliers', component: TbagentsuppliersComponent },
      { path: 'newtbagentsuppliers', component: NewtbagentsuppliersComponent },
      { path: 'tbagentcustomers', component: TbagentcustomersComponent },
      { path: 'newtbagentcustomers', component: NewtbagentcustomersComponent },
      { path: 'trackboxagents', component: BoxagentsComponent },
      { path: 'Tbagentbookingdetails/:id', component: TbagentbookingdetailsComponent },
      { path: 'addClearingAgent/:id', component: TbagentaddclearingagentComponent },
      { path: 'addConsignee/:id', component: TbagentaddconsigneeComponent },
      { path: 'edittbagentbooking/:id', component: EdittbagentbookingComponent },
      { path: 'tbagentaddcontainers/:id', component: TbagentaddcontainerstobookingComponent },
      { path: 'tbagentupdatecontainerstatuswithbooking/:id', component: TbagentupdatecontainerstatuswithbookingComponent },
      { path: 'tbagentrequesttoprinciple/:id', component: TbagentrequesttoprincipleComponent },
      { path: 'tbagentviewrequests/:id', component: TbagentviewrequestsComponent },
      { path: 'tbagentupdatecontainerstatuswithbooking/:id', component: TbagentupdatecontainerstatuswithbookingComponent },
      { path: 'tbagentrequesttoprinciple/:id', component: TbagentrequesttoprincipleComponent },
      { path: 'tbagentviewrequests/:id', component: TbagentviewrequestsComponent },
      { path: 'tbagentrequesttoprinciple/:id', component: TbagentrequesttoprincipleComponent },
      { path: 'tbagentviewrequests/:id', component: TbagentviewrequestsComponent },
      { path: 'viewprinciplerequest', component: ViewprinciplerequestComponent },
      { path: 'addReturnedDetails/:id', component: ContainerreturneddetailsComponent },
      { path: 'deliveryorderupdate/:id', component: DeliveryorderdateupdateComponent },
      { path: 'tbagentequipmentcontrollist/:text/:id', component: TbagentequipmentlistComponent },
      { path: 'tbagentmanagedportlist', component: TbAgentManagedportListComponent },
      { path: 'tbagentaddmanagedportlist', component: TbagentaddmanagedportlistComponent },
      { path: 'tbagentmanageddepots', component: TbagentmanageddepotsComponent },
      { path: 'tbagentblupdate', component: TbagentblupdateComponent },
      { path: 'tbagenteditcustomer/:id', component: TbagenteditcustomerComponent },
      { path: 'tbagenteditsupplier/:id', component: TbagenteditsupplierComponent },
      { path: 'tbagentupdatebookingequipment/:id/:id', component: TbagentupdatebookingequipmentComponent },
      { path: 'tbagentblview/:id', component: TbagentblviewComponent },
      { path: 'tbagentdoview/:id', component: TbagentdoviewComponent },
      { path: 'importbooking', component: ImportbookingComponent },
      { path: 'principlerequestdetails/:id', component: PrinciplerequestdetailsComponent },
      { path: 'tbagentpricing', component: TbagentpricingComponent },
      { path: 'tbagentsubscriptioncheckout', component: TbagentsubscriptioncheckoutComponent },



    ]

  }
];
