import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsreceivesalespaymentService } from './accountsreceivesalespayment.service';
import { AccountsInvoice } from 'src/app/model/accountsnvoice.model';

@Component({
  selector: 'app-accountsreceivesalespayment',
  templateUrl: './accountsreceivesalespayment.component.html',
  styleUrls: ['./accountsreceivesalespayment.component.css']
})
export class AccountsreceivesalespaymentComponent implements OnInit {

  p = 1;
  saleInvoice: AccountsInvoice;

  constructor(private accountsreceivesalespaymentService: AccountsreceivesalespaymentService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {

    this.accountsreceivesalespaymentService.findaSalesInvoiceDetails(this.route.snapshot.params.id)
            .subscribe(data => {
                this.saleInvoice = data;
            },
                error => {
                    this.router.navigate(['']);
                });
  }

  accountReceivePayment() {
    this.accountsreceivesalespaymentService.accountReceivePayment(this.saleInvoice)
            .subscribe(data => {
                this.saleInvoice = data;
                this.router.navigate(['/accounts/salesinvoice']);
            },
                error => {
                    this.router.navigate(['']);
                });
  }

}
