import { Component, OnInit } from '@angular/core';
import { AgentoperationreportsService } from './agentoperationreports.service';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agentoperationreports',
  templateUrl: './agentoperationreports.component.html',
  styleUrls: ['./agentoperationreports.component.css']
})
export class AgentoperationreportsComponent implements OnInit {

  p = 1;
  bookingsListAll: TrackBoxBooking[];
  bookingsList: TrackBoxBooking[];

  constructor(private agentoperationreportsService: AgentoperationreportsService, private router: Router) { }


  ngOnInit() {
    this.agentoperationreportsService.getAllPrincipleBookings()
      .subscribe(data => {
        this.bookingsListAll = data;
        this.bookingsList = this.bookingsListAll;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
