import { Person } from '../../model/person.model';
import { LoginpageService } from './loginpage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CandidateCount } from 'src/app/model/candidatecount.model';
import { CommonService } from 'src/app/utils/common.service';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SearchByCountryDTO } from 'src/app/model/searchbycountryDTO.model';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-loginpage',
    templateUrl: './loginpage.component.html',
    styleUrls: ['./loginpage.component.css']
})

export class LoginpageComponent implements OnInit {
    newPerson: Person;
    person: Person;
    loading = true;
    loading1 = true;
    isDisabled: boolean;
    errorMessageDiv: boolean;
    loginErrorDetails: string;
    cookieValue: string;
    catchaStatus = true;
    friendsRequestCount: CandidateCount;
    emailError: boolean;
    emailErrorMessage: string;
    showme: boolean;
    clicked: boolean;
    countryList: CountryInfo[];
    myControl3 = new FormControl();
    filteredOptionscountry: Observable<CountryInfo[]>;
    countrysearch: SearchByCountryDTO;
    hide: boolean = true;

    myFunction() {
        this.hide = !this.hide;
    }

    constructor(private loginpageService: LoginpageService, private router: Router,
        private cookieService: CookieService, private commonService: CommonService) { }

 //   sessionCandidate = this.commonService.getSessionCandidate();

    // resolved(captchaResponse: string) {
    //     this.catchaStatus = false;
    //     console.log(`Resolved captcha with response: ${captchaResponse}`);
    // }

    ngOnInit() {
        this.person = new Person();
        this.newPerson = new Person();
        this.loading = false;
        this.loading1 = false;
        this.isDisabled = false;
        this.errorMessageDiv = false;
        this.emailError = false;
        this.countrysearch = new SearchByCountryDTO();
        this.countrysearch.country = new CountryInfo();


        // if (this.sessionCandidate != null && this.sessionCandidate.id != null && this.sessionCandidate.id != 0) {
        // 	this.router.navigate(['/m/dashboard']);
        // }

        // this.searchBarService.connectionCount()
        // 	.subscribe(data => {
        // 		this.friendsRequestCount = data;
        // 	},
        // 		error => {
        // 			this.router.navigate(['']);
        // 		});

        this.loginpageService.viewCountry()
            .subscribe(data => {
                this.countryList = data;
                this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
                    // startWith(''),
                    map(val => this._filter4(val))
                );

                console.log(this.filteredOptionscountry);
            },
                error => {
                    this.router.navigate(['']);
                });

    }
    checkAndSubmitLogin() {
        this.isDisabled = true;
        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        if (this.person.userName !== '' && (this.person.userName.length <= 5 || !EMAIL_REGEXP.test(this.person.userName))) {
            console.log('Please provide a valid email');
        } else {
            this.loginpageService.saveType(this.person)
                .subscribe(data => {
                    //   if (this.sessionCandidate.id !== data.id) {
                    this.cookieService.delete('TMB_COMP');
                    this.cookieService.delete('TMB_COMP_NM');
                    //   }

                    // if (data == null && data.id == null ) {
                    //   this.loginErrorDetails = 'User name or password mismatch';
                    //    this.loading = false;
                    //   this.router.navigate(['']);
                    // }else

            if(!data.verified) {
           //     alert("not verified account"+data.id);
                this.router.navigate(['accountverification' , data.id]);
             //   alert("not verified account"+data.id);
            } else {

                    if (data.status === false) {
                        this.errorMessageDiv = true;
                        if (data.message === 'Your Account is Deactivated') {
                            this.loginErrorDetails = 'Your Account is Deactivated <a (click)=\'newLink()\'>Inscription</a>';

                            // this.loginErrorDetails = 'Your Account is Deactivated. <a href=\"/#/accountactivatedfromlogin/\"
                            // style="color:#FFF"> Activate ? </a>';

                            // // <a [routerLink]='['/accountactivatedfromlogin']'>Activate?</a>";
                        } else {
                            this.loginErrorDetails = data.message;
                        }
            
                    } else {
                        // this.cookieService.set('API_SESSION_ID', data.sessionId);
                        this.cookieService.set('CAND_ID', '' + data.id, null, null, null, null, null);
                        this.cookieService.set('CAND_FN', data.firstName, null, null, null, null, null);
                        this.cookieService.set('CAND_LN', data.lastName, null, null, null, null, null);
                        this.cookieService.set('CAND_DESIG', data.designation, null, null, null, null, null);
                        this.cookieService.set('CAND_COMPNM', data.companyName, null, null, null, null, null);
                        //   this.cookieService.set('CAND_EA', data.emailAddress, null, null, null, null, null);
                        this.cookieService.set('CAND_CONN', '' + data.connectionCount, null, null, null, null, null);
                        this.cookieService.set('CAND_FOLL', '' + data.followingCount, null, null, null, null, null);
                        this.cookieService.set('FIRST_FEED', data.firstFeed, null, null, null, null, null);
                        this.cookieService.set('VERI_STAT', '' + data.verified, null, null, null, null, null);
                        // this.cookieService.set('MSG_CNT', '' + this.friendsRequestCount.messages);
                        // this.cookieService.set('CONN_CNT', '' + this.friendsRequestCount.connection);
                        // this.cookieService.set('CART_CNT', '' + this.friendsRequestCount.shoppingcart);
                        this.cookieService.set('MSG_CNT', '' + 5, null, null, null, null, null);
                        this.cookieService.set('CONN_CNT', '' + 5, null, null, null, null, null);
                        this.cookieService.set('CART_CNT', '' + 5, null, null, null, null, null);
                        this.cookieService.set('TMB_STAT', data.trackmyboxStatus, null, null, null, null, null);
                        this.cookieService.set('TRD_STAT', '' + data.tradingStatus, null, null, null, null, null);
                        this.cookieService.set('CST_CALC_CAND', '' + data.candidateCostCalculator, null, null, null, null, null);
                      //  if (data.adminStatus) {
                            this.cookieService.set('ADM_STAT', data.adminStatus, null, null, null, null, null);
                      //  } else {
                       //     this.cookieService.set('ADM_STAT', 'CANDIDATE', null, null, null, null, null);
                       // }

                        this.router.navigate(['m/dashboard']);
                    }
                    this.loading = false;
                }
                },
                    error => {
                        this.router.navigate(['']);
                    });
        }
    }


    onKeydownLogin(event, person: Person) {

        if (event.key === 'Enter') {
            this.loading = true;
            this.isDisabled = true;
            this.loginpageService.saveType(this.person)
                .subscribe(data => {
                    //  if (this.sessionCandidate.id !== data.id) {
                    this.cookieService.delete('TMB_COMP');
                    this.cookieService.delete('TMB_COMP_NM');
                    // }

                    if (data == null && data.id == null) {
                        this.router.navigate(['']);
                    } else if (data.status === false) {
                        this.errorMessageDiv = true;
                        this.loginErrorDetails = data.message;
                        this.loading = false;
                    } else {
                        this.cookieService.set('API_SESSION_ID', data.sessionId, null, null, null, null, null);
                        this.cookieService.set('CAND_ID', '' + data.id, null, null, null, null, null);
                        this.cookieService.set('CAND_FN', data.firstName, null, null, null, null, null);
                        this.cookieService.set('CAND_LN', data.lastName, null, null, null, null, null);
                        this.cookieService.set('CAND_DESIG', data.designation, null, null, null, null, null);
                        this.cookieService.set('CAND_COMPNM', data.companyName, null, null, null, null, null);
                        //   this.cookieService.set('CAND_EA', data.emailAddress, null, null, null, null, null);
                        this.cookieService.set('CAND_CONN', '' + data.connectionCount, null, null, null, null, null);
                        this.cookieService.set('CAND_FOLL', '' + data.followingCount, null, null, null, null, null);
                        this.cookieService.set('FIRST_FEED', data.firstFeed, null, null, null, null, null);
                        this.cookieService.set('VERI_STAT', '' + data.verified, null, null, null, null, null);
                        // this.cookieService.set('MSG_CNT', '' + this.friendsRequestCount.messages);
                        // this.cookieService.set('CONN_CNT', '' + this.friendsRequestCount.connection);
                        // this.cookieService.set('CART_CNT', '' + this.friendsRequestCount.shoppingcart);
                        this.cookieService.set('MSG_CNT', '' + 5, null, null, null, null, null);
                        this.cookieService.set('CONN_CNT', '' + 5, null, null, null, null, null);
                        this.cookieService.set('CART_CNT', '' + 5, null, null, null, null, null);
                        this.cookieService.set('TMB_STAT', data.trackmyboxStatus, null, null, null, null, null);
                        this.cookieService.set('TRD_STAT', '' + data.tradingStatus, null, null, null, null, null);
                        this.cookieService.set('CST_CALC_CAND', '' + data.candidateCostCalculator, null, null, null, null, null);
                    //    if (data.adminStatus) {
                            this.cookieService.set('ADM_STAT', data.adminStatus, null, null, null, null, null);
                    //    } else {
                     //       this.cookieService.set('ADM_STAT', 'CANDIDATE', null, null, null, null, null);
                     //   }
                        // this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
                        this.router.navigate(['m/dashboard']);
                    }
                    this.loading1 = false;
                },
                    error => {
                        this.router.navigate(['']);
                    });
        }


    }


    onKeydown(event) {

        if (event.key === 'Enter') {
            this.router.navigate(['containerbycountry', this.countrysearch.country.id]);
            // alert(JSON.stringify(this.countrysearch.country.id));
        }
    }
    public _filter4(value: string): CountryInfo[] {
        const filterValue4 = value;
        return this.countryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
            country.name.toLowerCase().indexOf(filterValue4) === 0 ||
            country.name.toUpperCase().indexOf(filterValue4) === 0);
    }

    public displayCountry(cntr: CountryInfo): string {
        if (cntr && cntr.name) {
            return cntr.name + '-' + cntr.code2;

        }

    }
}
