import { Person } from '../../model/person.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { SessionCandidate } from '../../model/sessioncandidate.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';


@Injectable({
  providedIn: 'root'
})
export class LoginpageService {

  private loginURL = ShipStreetProps.API_PATH + 'loginDevice.do';
  private signupURL = ShipStreetProps.API_PATH + 'signupDevice.do';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';
  constructor(private http: HttpClient) { }

  public saveType(person: Person) {
    return this.http.post<SessionCandidate>(this.loginURL, person);
  }

   public signupType(newPerson: Person) {
    return this.http.post<SessionCandidate>(this.signupURL, newPerson);
  }
  viewCountry() {
    return this.http.get<CountryInfo[]>(this.appUrls);
  }
}
