export class MyBoxSearchDTO {
 /* country: CountryInfo;
  port: MasterPortsDepot;
  depot: MasterPortsDepot;
  containerType: ContainerType;
  carrier: TrackBoxCarrier; */

  country: number;
  port: number;
  depot: number;
  containerType = [] ;
  carrier: number;
}
