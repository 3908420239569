import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackerEmployees } from 'src/app/model/trackeremployees.model';

@Injectable({
  providedIn: 'root'
})
export class TrackeremployeesService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/trackeremployee/getAllTrackerEmployeesList.do';

  getAllTrackerEmployeesList() {
    return this.http.get<TrackerEmployees[]>(this.appUrl);
  }
}
