import { Component, OnInit } from '@angular/core';
import { CalculatorService } from '../calculator/calculator.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../utils/common.service';
import { Company } from '../model/company.model';

@Component({
  selector: 'app-calculatorintro',
  templateUrl: './calculatorintro.component.html',
  styleUrls: ['./calculatorintro.component.css']
})
export class CalculatorintroComponent implements OnInit {

  pricipleCompanies: Company[];
  breakpoint: number;
  p = 1;

  constructor(private calculatorService: CalculatorService, private router: Router,
    private cookieService: CookieService, private commonService: CommonService) { }

  ngOnInit() {
    this.calculatorService.getAllEasyCalCompanyByCandidate()
      .subscribe(data => {
        this.pricipleCompanies = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.breakpointFun(event)
  }

  onResize(event) {
    this.breakpointFun(event)
  }

  breakpointFun(event?: any): void {
    if (event) {
      this.breakpoint = (event.target.innerWidth <= 681) ? 2 : (event.target.innerWidth <= 1000) ? 4 : (event.target.innerWidth <= 1300) ? 4 : 5;
    } else {
      this.breakpoint = (window.innerWidth <= 681) ? 2 : (window.innerWidth <= 1000) ? 4 : (window.innerWidth <= 1300) ? 4 : 5;
    }
  }

  updateCalcCompId(companyId) {
    this.cookieService.set('CST_CALC_CMPI', '' + companyId, null, null, null, null, null);
    this.router.navigate(['/calculator/costcalculator']);
  }


}
