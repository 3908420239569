import { ContainerreturneddetailsComponent } from './containerreturneddetails/containerreturneddetails.component';
// import { TbagentupdatecontainerstatuswithbookingComponent } from './../tbagentupdatecontainerstatuswithbooking/tbagentupdatecontainerstatuswithbooking.component';
import { TbagentaddcontainerstobookingComponent } from './../tbagentaddcontainerstobooking/tbagentaddcontainerstobooking.component';
import { TbagentaddconsigneeComponent } from './../tbagentaddconsignee/tbagentaddconsignee.component';
import { TbagentaddclearingagentComponent } from './../tbagentaddclearingagent/tbagentaddclearingagent.component';
import { TbagentbookingdetailsService } from './tbagentbookingdetails.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatBottomSheet } from '@angular/material';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { BlupdateComponent } from 'src/app/trackmybox/bookings/blupdate/blupdate.component';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { BookingcontainersviewService } from 'src/app/trackmybox/bookings/bookingcontainersview/bookingcontainersview.service';
import { UpdateequipmentComponent } from 'src/app/trackmybox/equipmentcontrol/equipmentdetails/updateequipment/updateequipment.component';
import { TbagentrequesttoprincipleComponent } from '../tbagentrequesttoprinciple/tbagentrequesttoprinciple.component';
import { TbagentupdatecontainerstatuswithbookingComponent } from '../tbagentupdatecontainerstatuswithbooking/tbagentupdatecontainerstatuswithbooking.component';
import { DeliveryorderdateupdateComponent } from './deliveryorderdateupdate/deliveryorderdateupdate.component';
import { TbagentblupdateComponent } from './tbagentblupdate/tbagentblupdate.component';
import { TbagentupdatebookingequipmentComponent } from '../tbagentupdatebookingequipment/tbagentupdatebookingequipment.component';

@Component({
  selector: 'app-tbagentbookingdetails',
  templateUrl: './tbagentbookingdetails.component.html',
  styleUrls: ['./tbagentbookingdetails.component.css']
})
export class TbagentbookingdetailsComponent implements OnInit {
  trackboxbooking: TrackBoxBooking;
  trackboxbookingcontainersList: TrackBoxBookingContainers[];
  // trackboxbookingcontainer: TrackBoxBookingContainers;
  daysTravelled: number;
  DiffDatebet: any;
  DiffDatesmal: any;
  enddate: any;
  currentDate: any;
  pol;
  pod;
  message: string;
  constructor(private tbagentbookingdetailsService: TbagentbookingdetailsService, private router: Router,
    private route: ActivatedRoute, public dialog: MatDialog, private bookingcontainersviewService: BookingcontainersviewService,
    private _bottomSheet: MatBottomSheet) { }
  updateContainerStatusWithBooking(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(TbagentupdatecontainerstatuswithbookingComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.bookingcontainersviewService.getBookingContainerDetailsByID(this.route.snapshot.params.id)
        .subscribe(data1 => {
          this.trackboxbookingcontainersList = data1;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }

  addReturnedDetails(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(ContainerreturneddetailsComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.bookingcontainersviewService.getBookingContainerDetailsByID(this.route.snapshot.params.id)
        .subscribe(data1 => {
          this.trackboxbookingcontainersList = data1;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }


  updateContainerStatus(leaseUnitId, bookingId): void {
    const dialogoutRef = this.dialog.open(TbagentupdatebookingequipmentComponent, {
      width: '550px',
      data: { leaseUnitId, bookingId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.bookingcontainersviewService.getBookingContainerDetailsByID(this.route.snapshot.params.id)
        .subscribe(data1 => {
          this.trackboxbookingcontainersList = data1;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }

  updateDeliveryOrderDate(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(DeliveryorderdateupdateComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.bookingcontainersviewService.getBookingContainerDetailsByID(this.route.snapshot.params.id)
        .subscribe(data1 => {
          this.trackboxbookingcontainersList = data1;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }
  requestToPrinciple(leaseUnitId): void {
    this._bottomSheet.open(TbagentrequesttoprincipleComponent, {
      data: { leaseUnitId },
    });
    // const dialogoutRef = this.dialog.open(TbagentrequesttoprincipleComponent, {
    //   width: '550px',
    //   data: { leaseUnitId }
    // });
    // dialogoutRef.afterClosed().subscribe(result => {
    //   this.tbagentbookingdetailsService.getBookingsDetailsByID(this.route.snapshot.params.id)
    //       .subscribe(data1 => {
    //         this.trackboxbooking = data1;
    //       },
    //         error => {
    //           this.router.navigate(['']);
    //         });
    // });
  }

  blupdate(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(TbagentblupdateComponent, {
      width: '550px',
      data: { leaseUnitId }
    });
    dialogoutRef.afterClosed().subscribe(result => {
      this.tbagentbookingdetailsService.getBookingsDetailsByID(this.route.snapshot.params.id)
        .subscribe(data1 => {
          this.trackboxbooking = data1;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }

  addConsignee(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(TbagentaddconsigneeComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.tbagentbookingdetailsService.getBookingsDetailsByID(this.route.snapshot.params.id)
        .subscribe(data1 => {
          this.trackboxbooking = data1;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }

  tbagentaddcontainers(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(TbagentaddcontainerstobookingComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.tbagentbookingdetailsService.getBookingsDetailsByID(this.route.snapshot.params.id)
        .subscribe(data1 => {
          this.trackboxbooking = data1;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }

  addClearingAgent(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(TbagentaddclearingagentComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.tbagentbookingdetailsService.getBookingsDetailsByID(this.route.snapshot.params.id)
        .subscribe(data1 => {
          this.trackboxbooking = data1;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }
  ngOnInit() {
    this.trackboxbooking = new TrackBoxBooking();
    // this.trackboxbookingcontainer = new TrackBoxBookingContainers();

    this.tbagentbookingdetailsService.getBookingsDetailsByID(this.route.snapshot.params.id)
      .subscribe(data => {
        this.trackboxbooking = data;
        // let no: number = Math.abs(this.trackboxbooking.polEta - this.trackboxbooking.polEtd);
        this.currentDate = new Date();
        //  alert(JSON.stringify(this.startDate));
        if (this.currentDate.valueOf() > this.trackboxbooking.polEtd.valueOf() && this.currentDate.valueOf() < this.trackboxbooking.podEta.valueOf()) {

          this.enddate = Math.abs(this.trackboxbooking.podEta.valueOf() - this.currentDate.valueOf());
          this.DiffDatebet = Math.ceil(this.enddate / (1000 * 3600 * 24)) - 1;
          this.pol = this.trackboxbooking.pod.name;
          this.message = 'Days for Reaching To';

        }
        else if (this.currentDate.valueOf() < this.trackboxbooking.polEtd.valueOf()) {
          this.enddate = Math.abs(this.trackboxbooking.polEtd.valueOf() - this.currentDate.valueOf());
          this.DiffDatebet = Math.ceil(this.enddate / (1000 * 3600 * 24)) - 1;
          this.pol = this.trackboxbooking.pol.name;
          this.message = 'Days For Loading To';


        }
        else {

          this.enddate = Math.abs(this.currentDate.valueOf() - this.trackboxbooking.podEta.valueOf());
          this.DiffDatebet = Math.ceil(this.enddate / (1000 * 3600 * 24)) - 1;
          this.pol = this.trackboxbooking.pod.name;
          this.message = 'Days Over After Delivery To';


        }



        // this.trackboxbooking.podEta.valueOf() - this.trackboxbooking.polEta.valueOf();
        //  Math.ceil(this.enddate / (1000 * 3600 * 24));

        //     this.enddate=new Date (this.trackboxbooking.polEta.getFullYear(),this.trackboxbooking.polEta.getMonth(),this.trackboxbooking.polEta.getDay());

        // this.startDate=new Date (this.trackboxbooking.polEtd.getFullYear(),this.trackboxbooking.polEtd.getMonth(),this.trackboxbooking.polEtd.getDay());

        // this.DiffDate=Math.floor((Date.UTC(this.enddate.getFullYear(),this.enddate.getMonth(),this.enddate.getDate())-Date.UTC(this.startDate.getFullYear(),this.startDate.getMonth(),this.startDate.getDate()) )/(1000 * 60 * 60 * 24));

        //this.DiffDate=(this.DiffDate/86400000);
        // tslint:disable-next-line: max-line-length
        //   this.daysTravelled = Number(this.trackboxbooking.voyage.voyage.podEta) - Number(this.trackboxbooking.voyage.voyage.vesselStartsOn);
      },
        error => {
          this.router.navigate(['']);
        });

    this.bookingcontainersviewService.getBookingContainerDetailsByID(this.route.snapshot.params.id)
      .subscribe(data1 => {
        this.trackboxbookingcontainersList = data1;
      },
        error => {
          this.router.navigate(['']);
        });
  }
}


