import { AccountsPaymentTerms } from './accountspaymentterms.model';

export class Suppliers {
    id: number;
    paymentTerm: AccountsPaymentTerms;
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
    companyName: string;
    displayName: string;
    street: number;
    city: string;
    state: string;
    postalCode: number;
    country: string;
    phone: number;
    mobile: number;
    fax: number;
    other: number;
    webSite: string;
    billingRate: number;
    openingBalance: number;
    openingBalanceDate: Date;
    accountNumber: number;
    businessId: number;
    notes: string;
}
