import { TbagentaddclearingagentComponent } from './tbagentaddclearingagent.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TbAgentClearingAgentDTO } from 'src/app/model/tbagentclearingagentDTO.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentaddclearingagentService {

constructor(private http: HttpClient) { }

private appUrladdconsignee = ShipStreetProps.API_PATH + 'TrackboxBookings/addClearanceAgent.do';

public addAgent(agentadd: TbAgentClearingAgentDTO) {
  //  alert(JSON.stringify(agentadd));
  return this.http.post<TbAgentClearingAgentDTO[]>(this.appUrladdconsignee, agentadd);

}

}
