import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagenteditcustomerService {

constructor(private http: HttpClient) { 

  

 }
 private appUrl = ShipStreetProps.API_PATH + 'trackboxcustomers/trackboxcustomersViewbyId.do';
 private updateUrl = ShipStreetProps.API_PATH + 'trackboxcustomers/updatetbcustomers.do';
 public trackboxcustomersViewbyId( id: string) {

  return this.http.post<TrackBoxCustomers>(this.appUrl, id);
  }
  public updateCustomer(tbcustomer: TrackBoxCustomers) {

      return this.http.post<string>(this.updateUrl , tbcustomer);

}
}
