import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentcontrolService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/getAllContainerListByCompany.do';

  getAllMyContainers() {
    return this.http.get<TrackBoxContainer[]>(this.appUrl );
}
}
