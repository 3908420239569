import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsAccount } from 'src/app/model/accountsaccount.model';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';
import { AccountsProductServices } from 'src/app/model/accountsproductservices.model';
import { AccountsTax } from 'src/app/model/accountstax.model';
import { NewproductservicedetailsService } from './newproductservicedetails.service';

@Component({
  selector: 'app-newproductservicedetails',
  templateUrl: './newproductservicedetails.component.html',
  styleUrls: ['./newproductservicedetails.component.css']
})
export class NewproductservicedetailsComponent implements OnInit {

  categoryList: AccountsProductCategory[];
  assetsList: AccountsAccount[];
  incomeList: AccountsAccount[];
  expensseList: AccountsAccount[];
  taxList: AccountsTax[];
  productnservice: AccountsProductServices;
  constructor(private newproductservicedetailsService: NewproductservicedetailsService, private router: Router) { }

  ngOnInit() {
    this.productnservice = new AccountsProductServices();
    this.productnservice.purchaseTax = new AccountsTax();
    this.productnservice.expenseAccount = new AccountsAccount();
    this.productnservice.salesTax = new AccountsTax();
    this.productnservice.incomeAccount = new AccountsAccount();
    this.productnservice.inventoryAssets = new AccountsAccount();
    this.productnservice.category = new AccountsProductCategory();

    this.newproductservicedetailsService.getAccountsCategory()
      .subscribe(data => {
        this.categoryList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newproductservicedetailsService.getAccountsInventoryAssets()
      .subscribe(data => {
        this.assetsList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newproductservicedetailsService.getAccountsIncome()
      .subscribe(data => {
        this.incomeList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newproductservicedetailsService.getAccountsExpenses()
      .subscribe(data => {
        this.expensseList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newproductservicedetailsService.getAccountsTaxList()
      .subscribe(data => {
        this.taxList = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  newproductnservice() {
    this.newproductservicedetailsService.newproductnservice(this.productnservice)
      .subscribe(data => {
      //  this.taxList = data;
      this.router.navigate(['/accounts/salesproductsnservices']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
