import { Component, OnInit } from '@angular/core';
import { TradecontainersearchService } from './tradecontainersearch.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerPurchaseSales } from 'src/app/model/containerpurchasesales.model';

@Component({
  selector: 'app-tradecontainersearch',
  templateUrl: './tradecontainersearch.component.html',
  styleUrls: ['./tradecontainersearch.component.css']
})
export class TradecontainersearchComponent implements OnInit {
  searchText: string;
  p = 1;
  purchaseunits: ContainerPurchaseSales[];

  constructor(private tradecontainersearchService: TradecontainersearchService, private router: Router , 
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.tradecontainersearchService.tradeContainerSearch(this.route.snapshot.paramMap.get('searchtext'))
      .subscribe(data => {
        this.purchaseunits = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  // tradeContainerSearch() {
     
  // }

}
