import { Component, OnInit } from '@angular/core';
import { Person } from '../../model/person.model';
import { JoinnowService } from './joinnow.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';
import { CandidateCount } from 'src/app/model/candidatecount.model';
import { CommonService } from 'src/app/utils/common.service';

@Component({
	selector: 'app-joinnow',
	templateUrl: './joinnow.component.html',
	styleUrls: ['./joinnow.component.css']
})
export class JoinnowComponent implements OnInit {
	person: Person;
	loading = true;
	loading1 = true;
	isDisabled: boolean;
	errorMessageDiv: boolean;
	loginErrorDetails: string;
	cookieValue: string;
	catchaStatus = true;
	friendsRequestCount: CandidateCount;
	emailError: boolean;
	emailErrorMessage: string;
	newPerson: Person;

	constructor(private joinnowService: JoinnowService, private router: Router, private route: ActivatedRoute,
		        private cookieService: CookieService, private searchBarService: SearchbarService,
		        private commonService: CommonService) { }

	sessionCandidate = this.commonService.getSessionCandidate();

	resolved(captchaResponse: string) {
		this.catchaStatus = false;
		console.log(`Resolved captcha with response: ${captchaResponse}`);
	}

	ngOnInit() {
		this.person = new Person();
		// tslint:disable-next-line: indent
		this.newPerson = new Person();
		this.loading = false;
		this.loading1 = false;
		this.isDisabled = false;
		this.errorMessageDiv = false;
		this.emailError = false;

		// if (this.sessionCandidate != null && this.sessionCandidate.id != null && this.sessionCandidate.id != 0) {
		// this.router.navigate(['/m/dashboard']);
		// }

		// this.searchBarService.connectionCount()
		// 	.subscribe(data => {
		// 		this.friendsRequestCount = data;
		// 	},
		// 		error => {
		// 			this.router.navigate(['']);
		// 		});
	}


	newLink() {
		alert('yess......')
	}


	checkAndSubmitSignin(newPerson: Person) {
		// alert('in singup');
		this.loading1 = true;
		this.isDisabled = true;
		//  let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
		//   if (this.person.userName !== '' &&  !EMAIL_REGEXP.test(this.person.userName)) {
		//      console.log( 'Please provide a valid email' );
		//   } else {
		 alert( "Please provide a valid email" );
		//     console.log( 'valid email' );
		this.joinnowService.signupType(this.newPerson)
			.subscribe(data => {
				alert(JSON.stringify(data));
				if (data == null && data.id == null || data.status === false) {
					this.emailError = true;
					this.emailErrorMessage = 'E-Mail id already used';
					this.loading = false;
				} else {
					this.cookieService.set('CAND_ID', '' + data.id, null, null, null, null, null);
					this.cookieService.set('CAND_FN', data.firstName, null, null, null, null, null);
					this.cookieService.set('CAND_LN', data.lastName, null, null, null, null, null);
					this.cookieService.set('CAND_DESIG', data.designation, null, null, null, null, null);
					this.cookieService.set('CAND_COMPNM', data.companyName, null, null, null, null, null);
					this.cookieService.set('CAND_EA', data.emailAddress, null, null, null, null, null);
					this.cookieService.set('CAND_CONN', '' + data.connectionCount, null, null, null, null, null);
					this.cookieService.set('CAND_FOLL', '' + data.followingCount, null, null, null, null, null);
					this.cookieService.set('FIRST_FEED', data.firstFeed, null, null, null, null, null);
					this.cookieService.set('VERI_STAT', '' + data.verified, null, null, null, null, null);
					// this.cookieService.set('MSG_CNT', '' + this.friendsRequestCount.messages);
					// this.cookieService.set('CONN_CNT', '' + this.friendsRequestCount.connection);
					// this.cookieService.set('CART_CNT', '' + this.friendsRequestCount.shoppingcart);
					this.cookieService.set('MSG_CNT', '' + 5, null, null, null, null, null);
					this.cookieService.set('CONN_CNT', '' + 5, null, null, null, null, null);
					this.cookieService.set('CART_CNT', '' + 5, null, null, null, null, null);
					this.cookieService.set('TMB_STAT', data.trackmyboxStatus, null, null, null, null, null);
					if (data.adminStatus) {
						this.cookieService.set('ADM_STAT', 'ADMIN', null, null, null, null, null);
					} else {
						this.cookieService.set('ADM_STAT', 'CANDIDATE', null, null, null, null, null);
					}
					//  this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
					this.router.navigate(['/signupprivacy']);
				}
				this.loading = false;
			},
				error => {
					this.router.navigate(['']);
				});
		// }
	}


	onKeydownSignin(event, newPerson: Person) {
		if (event.key === 'Enter') {
			this.loading1 = true;
			this.isDisabled = true;
			this.joinnowService.signupType(this.newPerson)
				.subscribe(data => {
					if (data == null && data.id == null || data.status == false) {
						this.emailError = true;
						this.emailErrorMessage = 'E-Mail id already used';
						this.loading = false;
					//	this.router.navigate(['']);
					} else {
						this.cookieService.set('CAND_ID', '' + data.id, null, null, null, null, null);
						this.cookieService.set('CAND_FN', data.firstName, null, null, null, null, null);
						this.cookieService.set('CAND_LN', data.lastName, null, null, null, null, null);
						this.cookieService.set('CAND_DESIG', data.designation, null, null, null, null, null);
						this.cookieService.set('CAND_COMPNM', data.companyName, null, null, null, null, null);
						this.cookieService.set('CAND_EA', data.emailAddress, null, null, null, null, null);
						this.cookieService.set('CAND_CONN', '' + data.connectionCount, null, null, null, null, null);
						this.cookieService.set('CAND_FOLL', '' + data.followingCount, null, null, null, null, null);
						this.cookieService.set('FIRST_FEED', data.firstFeed, null, null, null, null, null);
						this.cookieService.set('VERI_STAT', '' + data.verified, null, null, null, null, null);
						// this.cookieService.set('MSG_CNT', '' + this.friendsRequestCount.messages);
						// this.cookieService.set('CONN_CNT', '' + this.friendsRequestCount.connection);
						// this.cookieService.set('CART_CNT', '' + this.friendsRequestCount.shoppingcart);
						this.cookieService.set('MSG_CNT', '' + 5, null, null, null, null, null);
						this.cookieService.set('CONN_CNT', '' + 5, null, null, null, null, null);
						this.cookieService.set('CART_CNT', '' + 5, null, null, null, null, null);
						this.cookieService.set('TMB_STAT', data.trackmyboxStatus, null, null, null, null, null);
						if (data.adminStatus) {
							this.cookieService.set('ADM_STAT', 'ADMIN', null, null, null, null, null);
						} else {
							this.cookieService.set('ADM_STAT', 'CANDIDATE', null, null, null, null, null);
						}
						// this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
						this.router.navigate(['/signupprivacy']);
					}
					this.loading1 = false;
				},
					error => {
						this.router.navigate(['']);
					});
		}
	}

}

