import { CompanyVessels } from './../../../../model/companyvessels.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterVessels } from 'src/app/model/mastervessels.model';
import { CompanyvesselsService } from '../../companyvessels/companyvessels.service';
import { NewvoyageService } from './newvoyage.service';
import { MasterVoyage } from 'src/app/model/mastervoyage.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Component({
  selector: 'app-newvoyage',
  templateUrl: './newvoyage.component.html',
  styleUrls: ['./newvoyage.component.css']
})
export class NewvoyageComponent implements OnInit {
  selectedValue: string;
  PortList: MasterPortsDepot[];


  voyage: MasterVoyage;
  vessels: CompanyVessels[];
  companyvesselList: CompanyVessels[];
   mastervessel: MasterVessels;

  constructor(private newvoyageService: NewvoyageService, private router: Router, private route: ActivatedRoute,
              private companyvesselsService: CompanyvesselsService) { }

  ngOnInit() {
    this.voyage = new MasterVoyage();
    this.mastervessel = new MasterVessels();
    this.voyage.vessel = new MasterVessels();
   // this.voyage.vessel.masterVessel = new MasterVessels();

    this.voyage.pol = new MasterPortsDepot();
    this.voyage.pod = new MasterPortsDepot();

    this.companyvesselsService.getAllMyCompanyVessels()
    .subscribe(data => {
      this.companyvesselList = data;
    },
      error => {
        this.router.navigate(['']);
      });

    this.newvoyageService.viewPort()
  .subscribe(data => {
    this.PortList = data;
},
    error => {
      this.router.navigate(['']);
    });
  }
  onSubmit() {

    this.newvoyageService.save(this.voyage)
    .subscribe(data => {
      this.router.navigate(['/trackmybox/voyages']);
       },
         error => {
           this.router.navigate(['']);
         });
  }

}
