import { Marketplace } from '../../../model/marketplace.model';
import { MarketplaceOrder } from '../../../model/marketplaceOrder.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarketShoppingCart } from '../../../model/marketshoppingcart.model';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceorderService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'getmarketplace.do';
  private saveUrl = ShipStreetProps.API_PATH + 'saveMarketplaceorderItem.do';
  private cartUrl = ShipStreetProps.API_PATH + 'getMarketplaceCart.do';
  private confirmBookingURl = ShipStreetProps.API_PATH + 'confirmMarketBooking.do';
  private updatecartURl = ShipStreetProps.API_PATH + 'updateCartItem.do';
  private removeCartItemUrl = ShipStreetProps.API_PATH + 'removeCartItem.do';

  public getmarketplaceorder(marketId: string) {
    return this.http.post<Marketplace>(this.appUrl , marketId);
  }
  public saveMarketplaceOrder(marketplaceorder: MarketplaceOrder) {
    return this.http.post<string>(this.saveUrl , marketplaceorder);
  }

  public getmarketplacecart() {
    return this.http.get<MarketShoppingCart[]>(this.cartUrl);
  }
  public confirmBooking() {
    return this.http.get<MarketShoppingCart[]>(this.confirmBookingURl);
  }

 public updatecartitems(shoppingCart: MarketShoppingCart[]) {
    return this.http.post<MarketShoppingCart[]>(this.updatecartURl , shoppingCart);
  }
  public removeCartItem(cartId: string) {
    return this.http.post<MarketShoppingCart[]>(this.removeCartItemUrl , cartId);
  }
}
