import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trackeremployeedetails',
  templateUrl: './trackeremployeedetails.component.html',
  styleUrls: ['./trackeremployeedetails.component.css']
})
export class TrackeremployeedetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
