import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewproductservicesService } from './newproductservices.service';
import { AccountsProductServices } from 'src/app/model/accountsproductservices.model';

@Component({
  selector: 'app-newproductservices',
  templateUrl: './newproductservices.component.html',
  styleUrls: ['./newproductservices.component.css']
})
export class NewproductservicesComponent implements OnInit {
  pnsList: AccountsProductServices[];

  constructor(private newproductservicesService: NewproductservicesService, private router: Router) { }

  ngOnInit() {
    this.newproductservicesService.getproductnservices()
      .subscribe(data => {
        this.pnsList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
