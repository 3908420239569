import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'src/app/model/company.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { CompanyFollow } from 'src/app/model/companyFollow.model';
import { Jobs } from 'src/app/model/jobs.model';
import { Marketplace } from 'src/app/model/marketplace.model';
import { RecruiterCompany } from 'src/app/model/recruiterCompany.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CompanydetailsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'company.do';
  private employementUrl = ShipStreetProps.API_PATH + 'employementWithCompany.do';
  private followUrl = ShipStreetProps.API_PATH + 'companyFollowList.do';
  private jobUrl = ShipStreetProps.API_PATH + 'companyJobList.do';
  private marketUrl = ShipStreetProps.API_PATH + 'companyMarketplaceItemList.do';
  private companyFollowUrl = ShipStreetProps.API_PATH + 'companyFollow.do';
  private companyUnFollowUrl = ShipStreetProps.API_PATH + 'companyUnFollow.do';
  private trackboxenroll = ShipStreetProps.API_PATH + 'enrolltrackmybox.do';
  private trademyboxenroll = ShipStreetProps.API_PATH + 'containercompany/enrollcontainercompany.do';
  private costCalcenroll = ShipStreetProps.API_PATH + 'enrollCompanyCostCalculator.do';

  public getCompanyByID(companyId: string) {
    return this.http.post<Company>(this.appUrl , companyId);
  }
  public employementWithCompany(companyEmp: CompanyEmployee) {
    return this.http.post<Company>(this.employementUrl , companyEmp);
  }
  public recruiterWithCompany(companyRec: RecruiterCompany) {
    return this.http.post<Company>(this.employementUrl , companyRec);
  }
  public companyFollowList(companyId: string) {
    return this.http.post<CompanyFollow[]>(this.followUrl , companyId);
  }
  public companyJobList(companyId: string) {
    return this.http.post<Jobs[]>(this.jobUrl , companyId);
  }
  public companyMarketplaceItemList(companyId: string) {
    return this.http.post<Marketplace[]>(this.marketUrl , companyId);
  }
  public companyEmployeesList(companyId: string) {
    return this.http.post<CompanyEmployee[]>(this.marketUrl , companyId);
  }
  public companyFollow(companyId: string) {
    return this.http.post<Company>(this.companyFollowUrl , companyId);
  }
   public companyUnFollow(companyId: string) {
    return this.http.post<Company>(this.companyUnFollowUrl , companyId);
  }

  public enrollCompanyTrackMyBox(company: Company) {
    return this.http.post<Company>(this.trackboxenroll , company);
  }

  public enrollCompanyTradeMyBox(company: Company) {
    return this.http.post<Company>(this.trademyboxenroll , company);
  }

  public enrollCompanyCostCalculator(company: Company) {
    return this.http.post<Company>(this.costCalcenroll , company);
  }
}
