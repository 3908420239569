import { Component, OnInit } from '@angular/core';
import { SalesinvoiceService } from './salesinvoice.service';
import { Router } from '@angular/router';
import { AccountsInvoice } from 'src/app/model/accountsnvoice.model';

@Component({
  selector: 'app-salesinvoice',
  templateUrl: './salesinvoice.component.html',
  styleUrls: ['./salesinvoice.component.css']
})
export class SalesinvoiceComponent implements OnInit {
  saleInvoiceListAll: AccountsInvoice[];
  saleInvoiceList: AccountsInvoice[];
  p = 1;

  constructor(private salesinvoiceService: SalesinvoiceService, private router: Router) { }

  ngOnInit() {

    this.salesinvoiceService.getAllSalesInvoices()
            .subscribe(data => {
                this.saleInvoiceListAll = data;
                this.saleInvoiceList = this.saleInvoiceListAll;
            },
                error => {
                    this.router.navigate(['']);
                });
  }

}
