import { AccountsAccountType } from './accountsaccounttype.model';
import { AccountsDetailType } from './accountsdetailtype.model';
import { AccountsTax } from './accountstax.model';
import { AccountProductType } from './accountproducttype.model';

export class AccountsAccount {
    id: number;
    accountType: AccountsAccountType;
    detailType: AccountsDetailType;
    description: string;
    name: string;
    invAssetDescription: string;
    defaultTaxCode: AccountsTax;
    productType: AccountProductType;
}
