import { CacheObject } from '../../model/cacheobject.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class Page2Service {
  private companyURL = ShipStreetProps.API_PATH + 'fetchCompanyCache.do';

  constructor(private http: HttpClient) { }

  public getCompanies() {
    return this.http.get<CacheObject[]>(this.companyURL);
  }

}
