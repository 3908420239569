import { CalcEquipmentType } from './CalcEquipmentType.model';
import { Company } from './company.model';
import { MasterPortsDepot } from './masterportsdepot.model';
import { MasterVesselOperator } from './mastervesseloperator.model';

export class MasterPortVesselOperatorCosts {
    id: number;
    company: Company;
    vesselOperator: MasterVesselOperator;
    loadport: MasterPortsDepot;
    finalport: MasterPortsDepot;
    containerSize: CalcEquipmentType;
    voSlotCost: number;
   // voSlotCost40: number;
   // voSlotCost45: number;
    voLssCost: number;
  //  voLssCost40: number;
  //  voLssCost45: number;
    voEwriCost: number;
  //  voEwriCost40: number;
  //  voEwriCost45: number;
    voGriCost: number;
 //   voGriCost40: number;
 //   voGriCost45: number;
    voDgSurcharge: number;
 //   voDgSurcharge40: number;
  //  voDgSurcharge45: number;
    voReeferSurcharge: number;
  //  voReeferSurcharge40: number;
  //  voReeferSurcharge45: number;
    voFlexiSurcharge: number;
 //   voFlexiSurcharge40: number;
  //  voFlexiSurcharge45: number;

}
