import { CandidateEducation } from '../../model/candidateEducation.model';
import { EditprofileService } from '../editprofile/editprofile.service';
import { EducationService } from './education.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  showedu: boolean;
  education: CandidateEducation;
  eduDiv: boolean;
  showme: boolean;
  neweducation: CandidateEducation;
  candidateEducations: CandidateEducation[];
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  degrees = [
                    {para : 'High School/Secondary' , text : 'High School/Secondary'},
                    {para : 'Some College' , text : 'Some College' },
                    {para : 'Associates/2 yr Degree' , text : 'Associates/2 yr Degree'},
                    {para : 'Bachelors/4 yr Degree' , text : 'Bachelors/4 yr Degree'},
                    {para : 'Masters Degree' , text : 'Masters Degree'},
                    {para : 'PhD/MD/Doctorate' , text : 'PhD/MD/Doctorate'},
                    {para : 'Tech/Vocational Cert.' , text : 'Tech/Vocational Cert.'}
                    ];

  constructor(private educationService: EducationService , private router: Router) { }

  ngOnInit() {
    this.showedu = false;
    this.eduDiv = true;
    this.neweducation = new CandidateEducation;
     this.educationService.candidateEducation()
      .subscribe( data => {
        this.candidateEducations = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  saveEducation() {
    this.educationService.saveType(this.neweducation)
      .subscribe( data => {
        this.candidateEducations = data;
        this.showedu = false;
      },
      error => {
        this.router.navigate(['']);
        });
    }
   updateCandidateEducation(education: CandidateEducation) {
   this.educationService.updateCandidateEducation(education)
      .subscribe( data => {
        education.eduDiv = false;
      },
      error => {
        this.router.navigate(['']);
        });
}
deleteEducation(education: CandidateEducation) {
   this.educationService.deleteEducation(education)
      .subscribe( data => {
        this.candidateEducations = data;
      //  education.eduDiv = false;
      },
      error => {
        this.router.navigate(['']);
        });
}
}
