import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { CmsGatein } from 'src/app/model/cmsgatein.model';

@Injectable({
  providedIn: 'root'
})
export class DepotcontainersService {

  private listUrl = ShipStreetProps.API_PATH + 'getAllDepotGateInUnits';

  constructor(private http: HttpClient) { }

  public findAllCmsGateinUnits() {
    return this.http.get<CmsGatein[]>(this.listUrl);
  }

}
