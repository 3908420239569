import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsPaymentMode } from 'src/app/model/accountspaymentmode.model';
import { Company } from 'src/app/model/company.model';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';
import { NewaccountspaymentmodeService } from './newaccountspaymentmode.service';

@Component({
  selector: 'app-newaccountspaymentmode',
  templateUrl: './newaccountspaymentmode.component.html',
  styleUrls: ['./newaccountspaymentmode.component.css']
})
export class NewaccountspaymentmodeComponent implements OnInit {

  paymentMode: AccountsPaymentMode;
 
   constructor(private newaccountspaymentmodeService: NewaccountspaymentmodeService, private router: Router) { }
 
   ngOnInit() {
    this.paymentMode = new AccountsPaymentMode();
   }
 
   saveAccountsPaymentMode() {
     this.newaccountspaymentmodeService.saveAccountsPaymentMode(this.paymentMode)
       .subscribe(data => {
         this.router.navigate(['/containers/accountspaymode']);
       },
         error => {
           this.router.navigate(['']);
         });
   }

}
