import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { ConsigneepaymentapprovalsService } from './consigneepaymentapprovals.service';

@Component({
    selector: 'app-consigneepaymentapprovals',
    templateUrl: './consigneepaymentapprovals.component.html',
    styleUrls: ['./consigneepaymentapprovals.component.css']
})
export class ConsigneepaymentapprovalsComponent implements OnInit {

    months: { id: number; name: string; }[];
    imagePath = ShipStreetProps.AWS_IMAGE_PATH;
   // consigneePaymenyList: Estimate[];

    constructor(private router: Router, private consigneepaymentapprovalsService: ConsigneepaymentapprovalsService) { }

    ngOnInit() {
        // this.consigneepaymentapprovalsService.getConsigneePaymentApprovalsList()
        //     .subscribe(data => {
        //        // this.consigneePaymenyList = data;
        //     },
        //         error => {
        //             this.router.navigate(['']);
        //         });
    }

    repairBillApprove(custBillId) {
        // this.consigneepaymentapprovalsService.repairBillApprove(custBillId)
        //     .subscribe(data => {
        //         // this.repairChargeList = data.billingDto;
        //         // this.netTotal = data.netTotal;
        //         // this.vatTotal = data.vatTotal;
        //         // this.grandTotal = data.grandTotal;
        //     },
        //         error => {
        //             this.router.navigate(['']);
        //         });
    }

    viewRepairBillApprove() {
        // this.monthlyrepairreportService.getMonthlyRepairExcel(this.equipment)
        //     .subscribe(data => {
        //          window.location.href = this.imagePath + 'CustomerStorageReport/Monthly-Repair-Charge.xlsx';
        //     },
        //         error => {
        //             this.router.navigate(['']);
        //         });
    }

}
