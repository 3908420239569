import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContainerleasedetailsService } from './containerleasedetails.service';
import { ContainerLease } from 'src/app/model/containerlease.model';
import { ContainerLeasingUnit } from 'src/app/model/containerleasingunit.model';
import { MatDialog } from '@angular/material';
import { AddcontainerleasingComponent } from '../addcontainerleasing/addcontainerleasing.component';
import { ContainerleasegateoutComponent } from '../containerleasegateout/containerleasegateout.component';
import { ContainerleasegateinComponent } from '../containerleasegatein/containerleasegatein.component';

@Component({
  selector: 'app-containerleasedetails',
  templateUrl: './containerleasedetails.component.html',
  styleUrls: ['./containerleasedetails.component.css']
})
export class ContainerleasedetailsComponent implements OnInit {
  leaseObj: ContainerLease;
  leasingUnits: ContainerLeasingUnit[];
  total = 0;
  private value;

  constructor(private containerleasedetailsService: ContainerleasedetailsService, private router: Router,
              private route: ActivatedRoute, public dialog: MatDialog) { }

  openDialog(leaseID): void {
    const dialogRef = this.dialog.open(AddcontainerleasingComponent, {
      width: '550px',
      data: { leaseID }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.containerleasedetailsService.findSingleContainerLeaseRecord(this.route.snapshot.paramMap.get('id'))
        .subscribe(data => {
          this.leaseObj = data;
        },
          error => {
            this.router.navigate(['']);
          });

      this.containerleasedetailsService.findallcontainers(this.route.snapshot.paramMap.get('id'))
        .subscribe(data => {
          this.leasingUnits = data;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }

  openGateOutDialog(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(ContainerleasegateoutComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.containerleasedetailsService.findSingleContainerLeaseRecord(this.route.snapshot.paramMap.get('id'))
        .subscribe(data => {
          this.leaseObj = data;
        },
          error => {
            this.router.navigate(['']);
          });

      this.containerleasedetailsService.findallcontainers(this.route.snapshot.paramMap.get('id'))
        .subscribe(data => {
          this.leasingUnits = data;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }

  openGateInDialog(leaseUnitId): void {
    const dialoginRef = this.dialog.open(ContainerleasegateinComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialoginRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.containerleasedetailsService.findSingleContainerLeaseRecord(this.route.snapshot.paramMap.get('id'))
        .subscribe(data => {
          this.leaseObj = data;
        },
          error => {
            this.router.navigate(['']);
          });

      this.containerleasedetailsService.findallcontainers(this.route.snapshot.paramMap.get('id'))
        .subscribe(data => {
          this.leasingUnits = data;
        },
          error => {
            this.router.navigate(['']);
          });
    });
  }

  ngOnInit() {
    this.containerleasedetailsService.findSingleContainerLeaseRecord(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.leaseObj = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.containerleasedetailsService.findallcontainers(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.leasingUnits = data;
        this.findsum(this.leasingUnits);
      },
        error => {
          this.router.navigate(['']);
        });

  }

  findsum(data) {
    //  debugger
    this.value = data
    for (let j = 0; j < data.length; j++) {
      this.total += this.value[j].netTotal;
    }
  }


}
