import { CacheObject } from '../../model/cacheobject.model';
import { Candidates } from '../../model/candidate.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class Page1Service {

  private desgURL = ShipStreetProps.API_PATH + 'fetchDesignationCache.do';
  private companyURL = ShipStreetProps.API_PATH + 'fetchCompanyCache.do';
  private appURL = ShipStreetProps.API_PATH + 'saveCurrentJobDetails.do';

  constructor(private http: HttpClient) { }

  public getDesignation() {
    return this.http.get<CacheObject[]>(this.desgURL);
  }

  public getCompanies() {
    return this.http.get<CacheObject[]>(this.companyURL);
  }
  public saveCurrentJobDetails(candidate: Candidates) {
    return this.http.post<string>(this.appURL , candidate);
  }
}
