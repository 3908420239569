import { TbagentmanageddepotsService } from './tbagentmanageddepots.service';
import { Component, OnInit } from '@angular/core';
import { CompanyPortDepot } from 'src/app/model/companyportdepot.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tbagentmanageddepots',
  templateUrl: './tbagentmanageddepots.component.html',
  styleUrls: ['./tbagentmanageddepots.component.css']
})
export class TbagentmanageddepotsComponent implements OnInit {

  managedmasterDepotList: CompanyPortDepot[];
  managedDepot:CompanyPortDepot;
  panelOpenState: boolean;
  p = 1;
  searchText: string;
  constructor(private tbagentmanageddepotsService: TbagentmanageddepotsService, private router: Router) { }

  ngOnInit() {

    this.managedDepot = new CompanyPortDepot();
    this.tbagentmanageddepotsService.getAllMyManagedDepots()
    .subscribe(data => {
      this.managedmasterDepotList = data;
    },
      error => {
        this.router.navigate(['']);
      });
  }

  search() {
    if (this.searchText !== '') {
      this.managedmasterDepotList = this.managedmasterDepotList.filter(res => {
        return (res.portId.name !== null && res.portId.name.toLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.portId.portCode !== null && res.portId.portCode.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.portId.unloCode !== null && res.portId.unloCode.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.portId.country !== null && res.portId.country.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.ngOnInit();
    }
  }

}
