import { Jobs } from '../../model/jobs.model';
import { GlobelsearchService } from '../globelsearch/globelsearch.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';

@Component({
  selector: 'app-globalsearchjob',
  templateUrl: './globalsearchjob.component.html',
  styleUrls: ['./globalsearchjob.component.css']
})
export class GlobalsearchjobComponent implements OnInit {
  searchTxt: string;
  jobs: Jobs[];

  constructor(private globelsearchService: GlobelsearchService, private route: ActivatedRoute, private router: Router) { }
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  ngOnInit() {
    this.searchTxt = this.route.snapshot.params.text;
    this.globelsearchService.searchJobs(this.route.snapshot.params.text)
      .subscribe(data => {
        this.jobs = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }
  onKeydown(e) { }
}
