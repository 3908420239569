import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerOnewayMove } from 'src/app/model/containeronewaymove.model';

@Injectable({
  providedIn: 'root'
})
export class NewmovecontainersService {

  private saveUrl = ShipStreetProps.API_PATH + 'containeronewaymove/saveContainerOnewayMove';

  constructor(private http: HttpClient) { }

  public saveContainerOnewayMove(onewaymove: ContainerOnewayMove) {
    return this.http.post<string>(this.saveUrl , onewaymove);
  }
}
