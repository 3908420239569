import { Candidates } from './candidate.model';

export class CandidateBillingAddress {
    id: number;
    candidate: Candidates;
    fullName: string;
    address: string;
    location: string;
    city: string;
    state: string;
    country: string;
    phone: string;
    postBox: string;
}
