import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortsService } from './ports.service';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Component({
  selector: 'app-ports',
  templateUrl: './ports.component.html',
  styleUrls: ['./ports.component.css']
})
export class PortsComponent implements OnInit {
  masterPortList: MasterPortsDepot[];
  panelOpenState: boolean;
  p = 1;
  searchText: string;
  masterPortListAll: MasterPortsDepot[];

  constructor(private portService: PortsService, private router: Router) { }

  ngOnInit() {
    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortListAll = data;
        this.masterPortList = this.masterPortListAll;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  search() {
    if (this.searchText !== '') {
      this.masterPortList = this.masterPortList.filter(res => {
        return (res.name !== null && res.name.toLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.portCode !== null && res.portCode.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.unloCode !== null && res.unloCode.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.country !== null && res.country.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.masterPortList = this.masterPortListAll;
    }
  }

}
