import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class BuildnetworkService {

  constructor(private http: HttpClient) { }

  private linkedInUrl = ShipStreetProps.API_PATH + 'buildNetworkLinkedIn.do';
  private yahooUrl = ShipStreetProps.API_PATH + 'buildNetworkYahoo.do';
  private gmailUrl = ShipStreetProps.API_PATH + 'buildNetworkGmail.do';
  private hotmailUrl = ShipStreetProps.API_PATH + 'buildNetworkHotmail.do';
  private outlookUrl = ShipStreetProps.API_PATH + 'buildNetworkOutlook.do';
  private otherUrl = ShipStreetProps.API_PATH + 'buildNetworkOthers.do';

  postFile(fileToUpload: FormData) {
    return this.http.post<string>(this.linkedInUrl , fileToUpload);
  }

  uploadGmailFile(fileToUpload: FormData) {
    return this.http.post<string>(this.gmailUrl , fileToUpload);
  }

  uploadYahooFile(fileToUpload: FormData) {
    return this.http.post<string>(this.yahooUrl , fileToUpload);
  }

  uploadHotmailFile(fileToUpload: FormData) {
    return this.http.post<string>(this.hotmailUrl , fileToUpload);
  }

  uploadOutlookFile(fileToUpload: FormData) {
    return this.http.post<string>(this.outlookUrl , fileToUpload);
  }

  uploadOtherFile(fileToUpload: FormData) {
    return this.http.post<string>(this.otherUrl , fileToUpload);
  }
}
