import { ForgotpasswordService } from './forgotpassword.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Person } from 'src/app/model/person.model';
import { PassWordResetDTO } from 'src/app/model/passwordresetdto.model';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  reply: string;
  errorDetails: any;
  emailAddress: string;
  reqPassCode = true;
  person: Person;
  PassWordReset: PassWordResetDTO;
  constructor(private forgotpasswordService: ForgotpasswordService , private router: Router) { }

  ngOnInit() {
    this.person = new Person();
    this.PassWordReset = new PassWordResetDTO();
  }

  requestResetPasscode() {
     this.forgotpasswordService.requestResetPasscode(this.emailAddress)
      .subscribe( data => {
     //   this.reply = data;
    //    this.PassWordReset.emailAddress = this.emailAddress;
        alert(data.errorMessage);
        if(data.errorMessage == "active"){
          this.router.navigate(['/passcodechecking' , data.id]);
     }else{
     //  this.errorDetails = data.errMsg;
      // alert("Error Message :"+errorDetails);
     }
      },
      error => {
        this.router.navigate(['']);
        });
  }

  onKeydownLogin(event , person) {}

  checkAndSubmitLogin() {}

}
