import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyPortDepot } from 'src/app/model/companyportdepot.model';
import { TbAgentManagedportListService } from './tbagentmanagedportList.service';

@Component({
  selector: 'app-tbagentmanagedportList',
  templateUrl: './tbagentmanagedportList.component.html',
  styleUrls: ['./tbagentmanagedportList.component.css']
})
export class TbAgentManagedportListComponent implements OnInit {
  managedmasterPortList: CompanyPortDepot[];
  managedport:CompanyPortDepot;
  panelOpenState: boolean;
  p = 1;
  searchText: string;


  constructor(private tbAgentManagedportListService: TbAgentManagedportListService, private router: Router) { }

  ngOnInit() {
   this.managedport = new CompanyPortDepot();
    this.tbAgentManagedportListService.getAllMyManagedPorts()
    .subscribe(data => {
      this.managedmasterPortList = data;
    },
      error => {
        this.router.navigate(['']);
      });
  }

  search() {
    if (this.searchText !== '') {
      this.managedmasterPortList = this.managedmasterPortList.filter(res => {
        return (res.portId.name !== null && res.portId.name.toLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.portId.portCode !== null && res.portId.portCode.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.portId.unloCode !== null && res.portId.unloCode.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.portId.country !== null && res.portId.country.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.ngOnInit();
    }
  }

}
