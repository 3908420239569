import { CacheObject } from '../../model/cacheobject.model';
import { CandidateEducation } from '../../model/candidateEducation.model';
import { CandidateExperience } from '../../model/candidateExperience.model';
import { Page2Service } from './page2.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page2',
  templateUrl: './page2.component.html',
  styleUrls: ['./page2.component.css']
})
export class Page2Component implements OnInit {
  education: CandidateEducation;
  experience: CandidateExperience;
  checked: boolean;
  showme: boolean;
  companies: CacheObject[];
  experiences = [];
  educations = [];
  degrees = ['High School/Secondary','Some College','Associates/2 yr Degree','Bachelors/4 yr Degree','Masters Degree','PhD/MD/Doctorate','Tech/Vocational Cert.'];
  
  constructor(private page2Service: Page2Service , private router: Router) { }

  ngOnInit() {
    this.experience = new CandidateExperience;
    this.education = new CandidateEducation;
    this.showme = true;
    this.checked = true;
    this.page2Service.getCompanies()
      .subscribe( data => {
        this.companies = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

 addExp(experience) {
//    this.page2Service.getCompanies()
//      .subscribe( data => {
//        this.companies = data;
//      });
  }
showedu(){}
  addEdu(candedu){}
  candedu(){}
}
