import { Company } from './../../../../model/company.model';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TbagentaddcontainerstobookingService } from './tbagentaddcontainerstobooking.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { TrackBoxBookingContainersDTO } from 'src/app/model/trackboxbookingcontainersDTO.model';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { TbagentAddBookingContainersDTO } from 'src/app/model/tbagentaddbookingcontainersDTO.model';

export interface DialogData {
  leaseUnitId: number;
}
@Component({
  selector: 'app-tbagentaddcontainerstobooking',
  templateUrl: './tbagentaddcontainerstobooking.component.html',
  styleUrls: ['./tbagentaddcontainerstobooking.component.css']
})
export class TbagentaddcontainerstobookingComponent implements OnInit {
principleCompanies:TrackBoxAgent[];
principle:number;
addContainer: TbagentAddBookingContainersDTO;
  bookingObj: TrackBoxBooking;
    containerbooking: TrackBoxContainer;
    containersbooking: TrackBoxContainer[];
    // containerBooking: TrackBoxBookingContainersDTO;
    trackboxcontainerbooking: TrackBoxBookingContainers;
  constructor(private tbagentaddcontainerstobookingService: TbagentaddcontainerstobookingService,private router: Router, private route: ActivatedRoute, public dialogoutRef: MatDialogRef<TbagentaddcontainerstobookingComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.trackboxcontainerbooking = new TrackBoxBookingContainers();
    this.bookingObj = new TrackBoxBooking();

    this.tbagentaddcontainerstobookingService.getPrincipleCompanies()
    .subscribe(data => {
       this.principleCompanies = data;
        // alert(JSON.stringify(this.principleCompanies));
        },
          error => {
            this.router.navigate(['']);
          });



    // this.tbagentaddcontainerstobookingService.getBookingsByID(this.route.snapshot.params.id)
    //   .subscribe(data1 => {
    //     this.bookingObj = data1;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });
  }
  addBookingContainers() {

      //  this.addContainer.bookingId = this.data.leaseUnitId;
      //  alert(JSON.stringify( this.addContainer.bookingId));
    //  this.addContainer.containers = this.containersbooking;
    this.addContainer = { bookingId: this.data.leaseUnitId, containers: this.containersbooking };
    this.tbagentaddcontainerstobookingService.updateBookingEquipment(this.addContainer)
      .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 500,

        });
        this.dialogoutRef.close(0);
      },
        error => {
          this.router.navigate(['']);
        });
   }
   onCompanyChange(){
    // principle= this.addContainer.principeCompany;
    // console.log(principle)
    // alert(JSON.stringify(this.principle));
    this.tbagentaddcontainerstobookingService.getPrincipleContainers(this.principle)
    .subscribe(data => {
       this.containersbooking = data;

        },
          error => {
            this.router.navigate(['']);
          });

  }
  onNoClick(): void {
    this.dialogoutRef.close();

  }
  }


