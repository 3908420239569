import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomersService } from './customers.service';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  customers: ContainerCustomer[];
  p = 1;
  searchText: string;
  customersAll: ContainerCustomer[];

  constructor(private customersService: CustomersService, private router: Router) { }

  ngOnInit() {
    this.customersService.containerCustomerList()
      .subscribe(data => {
        this.customersAll = data;
        this.customers = this.customersAll;
      },
        error => {
          this.router.navigate(['']);
        });
  }


  searchcustomer() {
    if (this.searchText !== '') {
      this.customers = this.customersAll.filter(res => {
        return res.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
          res.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    } else if (this.searchText === '') {
      this.customers = this.customersAll;
    }
  }
}
