import { TrackBoxContainerSupplier } from './../../../../model/trackboxcontainersupplier.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewtrackboxsupplierService {

constructor(private http: HttpClient) { }

private appUrl = ShipStreetProps.API_PATH + 'trackboxcontainersupplier/trackBoxContainerSupplierSave.do';
public save(trackboxsupplier: TrackBoxContainerSupplier) {
  return this.http.post<TrackBoxContainerSupplier>(this.appUrl, trackboxsupplier);
}

}
