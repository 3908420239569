import { Component, OnInit } from '@angular/core';
import { TbagentportsService } from './tbagentports.service';
import { Router } from '@angular/router';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Component({
  selector: 'app-tbagentports',
  templateUrl: './tbagentports.component.html',
  styleUrls: ['./tbagentports.component.css']
})
export class TbagentportsComponent implements OnInit {
  masterPortList: MasterPortsDepot[];
  panelOpenState: boolean;
  p = 1;
  searchText: string;

  constructor(private tbagentportsService: TbagentportsService, private router: Router) { }

  ngOnInit() {
    this.tbagentportsService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

    search() {
    if (this.searchText !== '') {
      this.masterPortList = this.masterPortList.filter(res => {
        return (res.name !== null && res.name.toLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.portCode !== null && res.portCode.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.unloCode !== null && res.unloCode.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
          (res.country !== null && res.country.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.ngOnInit();
    }
  }

}
