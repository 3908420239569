import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { ExistingemployeesService } from './existingemployees.service';

@Component({
  selector: 'app-existingemployees',
  templateUrl: './existingemployees.component.html',
  styleUrls: ['./existingemployees.component.css']
})
export class ExistingemployeesComponent implements OnInit {
  jsonList: CompanyEmployee[];

  constructor(private existingemployeesService: ExistingemployeesService, private router: Router, private route: ActivatedRoute) { }

  existingemployeecandidate: CompanyEmployee[];
  p: number;
  panelOpenState: boolean;
  ngOnInit() {

    this.existingemployeesService.getCompanyEmployee()
       .subscribe(data => {
         this.existingemployeecandidate = data;
       },
         error => {
           this.router.navigate(['']);
         });


        
  }


  asignTrackMyBoxPermission(comemployee) {
    this.existingemployeesService.asignTrackMyBoxPermission(comemployee)
      .subscribe(data => {
        this.jsonList = this.existingemployeecandidate;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === comemployee.id) {
            this.existingemployeecandidate[i].trackBoxStatus = true;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  removeTrackMyBoxPermission(comemployee) {
    this.existingemployeesService.removeTrackMyBoxPermission(comemployee)
      .subscribe(data => {
        this.jsonList = this.existingemployeecandidate;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === comemployee.id) {
            this.existingemployeecandidate[i].trackBoxStatus = false;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  }


