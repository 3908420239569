import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, ThemeService } from 'ng2-charts';
import { ContainerPurchaseReportDTO } from 'src/app/model/ContainerPurchaseReportDTO.model';
import { ContainerSalesReportDTO } from 'src/app/model/containerSalesReportDTO.model';
import { CntyearlypurchasereportsService } from './cntyearlypurchasereports.service';

@Component({
  selector: 'app-cntyearlypurchasereports',
  templateUrl: './cntyearlypurchasereports.component.html',
  styleUrls: ['./cntyearlypurchasereports.component.css']
})
export class CntyearlypurchasereportsComponent implements OnInit {

  dataList = [];

  barChartOptions: ChartOptions = {
    responsive: true,
  };

  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [];
  purchaseList: ContainerPurchaseReportDTO[];
  currentYear: number;

  constructor(private cntyearlypurchasereportsService: CntyearlypurchasereportsService, private router: Router,
    private themeService: ThemeService) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.cntyearlypurchasereportsService.getYearPurchaseReportBySuppliers(this.currentYear)
      .subscribe(data => {
        this.purchaseList = data;
        this.purchaseList.forEach((item) => {
          if (item != null && Number(item.totalSoldQty) > 0) {
            this.barChartLabels.push(item.supplier.name);
            this.dataList.push(Number(item.totalSoldQty));
          }
        });

        this.barChartData = [
          { data: this.dataList, label: '' }
        ];
      },
        error => {
          this.router.navigate(['']);
        });
  }

  searchyearlycntpurchase() {
    this.barChartLabels = [];
    this.dataList = [];
    this.cntyearlypurchasereportsService.getYearPurchaseReportBySuppliers(this.currentYear)
    .subscribe(data => {
      this.purchaseList = data;
      this.purchaseList.forEach((item) => {
        if (item != null && Number(item.totalSoldQty) > 0) {
          this.barChartLabels.push(item.supplier.name);
          this.dataList.push(Number(item.totalSoldQty));
        }
      });

      this.barChartData = [
        { data: this.dataList, label: '' }
      ];
    },
      error => {
        this.router.navigate(['']);
      });
  }

}
