import { NewequipmentcontrolService } from 'src/app/trackmybox/equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditequipmentcontrollerService } from './editequipmentcontroller.service';
import { Component, OnInit } from '@angular/core';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { DepotsService } from '../../master/depots/depots.service';
import { PortsService } from '../../master/ports/ports.service';
import { TrackboxsupplierService } from '../../admin/trackboxsupplier/trackboxsupplier.service';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { ContainerStatus } from 'src/app/model/containerstatus.model';
import { TrackboxContainerStatus } from '../../trackboxcontainerstatus.model';
import { TrackBoxCarrier } from 'src/app/model/trackboxcarrier.model';
import { TrackBoxPurchaseStatus } from 'src/app/model/trackboxpurchasestatus.model';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';
import { Containers } from 'src/app/model/containers.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-editequipmentcontroller',
  templateUrl: './editequipmentcontroller.component.html',
  styleUrls: ['./editequipmentcontroller.component.css']
})
export class EditequipmentcontrollerComponent implements OnInit {
  countryList: CountryInfo[];
  equipmentcontroler: TrackBoxContainer;
  masterDepotList: MasterPortsDepot[];
  masterPortList: MasterPortsDepot[];
  ContainerTypeList: ContainerType[];
  location: ContainerLocationType[];
  containerstatus: ContainerStatus[];
  status: TrackboxContainerStatus[];
  carrier: TrackBoxCarrier[];
  purchasestat: TrackBoxPurchaseStatus[];
  supplierList: ContainerSupplier[];

  myForm: FormGroup;

   containerNumberFormControl = new FormControl('', [Validators.required,Validators.pattern('[A-Z]{3}U[0-9]{7}') ]);

   onKeyUp(event) {
    const value = this.containerNumberFormControl.value.toUpperCase();
    this.containerNumberFormControl.setValue(value, { emitEvent: false });
    this.equipmentcontroler.container.containerNumber = value;
  }
   
  constructor(private editequipmentcontrollerService: EditequipmentcontrollerService, private router: Router,
              private route: ActivatedRoute, private newequipmentcontrolService: NewequipmentcontrolService,
              private depotsService: DepotsService, private portService: PortsService,
              private trackboxsupplierService: TrackboxsupplierService) { }

  ngOnInit() {
    this.equipmentcontroler = new TrackBoxContainer();
    this.equipmentcontroler.container = new Containers();
    this.equipmentcontroler.container.equipmentType = new ContainerType();
    this.equipmentcontroler.leaseFrom = new ContainerSupplier();
    this.equipmentcontroler.purchasedFrom = new ContainerSupplier();
    this.equipmentcontroler.country = new CountryInfo();

    this.myForm = new FormGroup({
      containerNumber: this.containerNumberFormControl
    });


    this.editequipmentcontrollerService.equipmentControllerbyid(this.route.snapshot.params.id)
      .subscribe(data => {
        this.equipmentcontroler = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newequipmentcontrolService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.depotsService.getAllMyDepots()
      .subscribe(data => {
        this.masterDepotList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newequipmentcontrolService.viewcontainerType()
      .subscribe(data => {
        this.ContainerTypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.newequipmentcontrolService.viewcontainerLocationType()
      .subscribe(data => {
        this.location = data;

      },
        error => {
          this.router.navigate(['']);
        });
    this.newequipmentcontrolService.viewcontainerStatus()
      .subscribe(data => {
        this.containerstatus = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newequipmentcontrolService.viewPurchaseStatus()
      .subscribe(data => {
        this.purchasestat = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.trackboxsupplierService.getAllMySupplier()
      .subscribe(data => {
        this.supplierList = data;
        // alert(JSON.stringify(this.trackboxsupplierList));
      },
        error => {
          this.router.navigate(['']);
        });

  }

  updateEquipmentController() {

    if (this.myForm.valid) {
      const formData  = {
        containerNumber: this.containerNumberFormControl.value
      };
    }

    this.editequipmentcontrollerService.updateVesselOperator(this.equipmentcontroler)
      .subscribe(data => {
        this.router.navigate(['/trackmybox/equipmentcontroller']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
}


