import { Container } from '@angular/compiler/src/i18n/i18n_ast';
import { ContainerStatus } from './../../../model/containerstatus.model';
import { TrackBoxBookingContainers } from './../../../model/trackboxbookingcontainers.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UpdatebookingService } from './updatebooking.service';
import { Component, OnInit, Inject } from '@angular/core';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrackboxContainerStatus } from '../../trackboxcontainerstatus.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Containers } from 'src/app/model/containers.model';
export interface DialogData {
    leaseUnitId: number;
  }
@Component({
  selector: 'app-updatebooking',
  templateUrl: './updatebooking.component.html',
  styleUrls: ['./updatebooking.component.css']
})
export class UpdatebookingComponent implements OnInit {
  bookingcontainers: TrackBoxBookingContainers;
  trackboxbookingcontainer: TrackBoxBookingContainers;
  containerstatusList: TrackboxContainerStatus[];

  constructor(private updatebookingService: UpdatebookingService, private router: Router, private route: ActivatedRoute,
              public dialogoutRef: MatDialogRef<UpdatebookingComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.trackboxbookingcontainer = new TrackBoxBookingContainers();
    this.bookingcontainers = new TrackBoxBookingContainers();
    this.bookingcontainers.container = new TrackBoxContainer();
    // this.bookingcontainers.containerStatus = new TrackboxContainerStatus();

    this.updatebookingService.getBookedContainersByID(this.route.snapshot.params.id);
    //   .subscribe(data => {
    //     this.bookingcontainers = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    this.updatebookingService.getContainerStatus()
      .subscribe(data => {
        this.containerstatusList = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  updateContainerStatus() {
    this.bookingcontainers.id = this.data.leaseUnitId;
    this.updatebookingService.updateContainerStatus(this.bookingcontainers)
        .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 500,

        });
        this.dialogoutRef.close(0);
        },

          error => {
            this.router.navigate(['']);
          });

    }


     onNoClick(): void {
      this.dialogoutRef.close();

  }
  }

