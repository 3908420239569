import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyPortDepot } from 'src/app/model/companyportdepot.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbAgentManagedportListService {

constructor(private http: HttpClient) { }

private appUrl = ShipStreetProps.API_PATH + 'masterports/getAllMyManagedPorts.do';

  public getAllMyManagedPorts() {
    return this.http.get<CompanyPortDepot[]>(this.appUrl);
  }
}
