import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPresales } from 'src/app/model/containerpresales.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewpresalesService {

  private saveUrl = ShipStreetProps.API_PATH + 'containerpresales/saveContainerPreSales';
  constructor(private http: HttpClient) { }

  public saveContainerPreSales(presales: ContainerPresales) {
    return this.http.post<string>(this.saveUrl , presales);
  }
}
