import { Component, OnInit } from '@angular/core';
import { NewcontainertypeService } from './newcontainertype.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/utils/common.service';
import { ContainerType } from 'src/app/model/containertype.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';

@Component({
  selector: 'app-newcontainertype',
  templateUrl: './newcontainertype.component.html',
  styleUrls: ['./newcontainertype.component.css']
})
export class NewcontainertypeComponent implements OnInit {
  sessionCandidate: SessionCandidate;
  containertype: ContainerType;
  codes: string[];

  constructor(private newcontainertypeService: NewcontainertypeService, private router: Router,
              private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.containertype = new ContainerType();
    this.codes = ['10' , '20' , '40' , '45'];
  }

  saveContainerType() {
  //  this.vendor.company.id = this.sessionCandidate.company;
    this.newcontainertypeService.saveContainerType(this.containertype)
      .subscribe(data => {
        this.router.navigate(['containers/containertype']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
