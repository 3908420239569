import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';

@Injectable({
  providedIn: 'root'
})
export class EditcontainerpurchaseService {

  private saveUrl = ShipStreetProps.API_PATH + 'containerpurchase/updateContainerPurchase';
  private sappUrl = ShipStreetProps.API_PATH + 'containerpurchase/getSingleContainerPurchase';

  constructor(private http: HttpClient) { }

  public updateContainerPurchase(purchase: ContainerPurchase) {
    return this.http.post<string>(this.saveUrl , purchase);
  }

  public getcontainerpurchasebyid(purchaseId: number) {
    return this.http.post<ContainerPurchase>(this.sappUrl , purchaseId);
  }

}
