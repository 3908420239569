import { Component, OnInit } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HelpHeaderService } from '../help-header/help-header.service';
import { Router } from '@angular/router';
import { Candidates } from 'src/app/model/candidate.model';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  isGuest: boolean;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  sessionCandidate: Candidates;
  constructor(private helpHeaderService: HelpHeaderService , private router: Router) { }

  ngOnInit() {
    this.helpHeaderService.sessionCandidate()
      .subscribe( data => {
        this.sessionCandidate = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
