import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentequipmentsService {

  constructor(private http: HttpClient) { }


  private appUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/getAllContainerListByPrincipleCompany.do';

  getAllMyPrincipleContainers() {
    return this.http.get<TrackBoxContainer[]>(this.appUrl);


  }
}
