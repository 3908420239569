import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditproductservicesService {

    constructor(private http: HttpClient) { }

    private appUrl = ShipStreetProps.API_PATH + 'accountsproductcategory/getAllCategoryList';
    private accUrl = ShipStreetProps.API_PATH + 'getAccountsAccount';
    private taxUrl = ShipStreetProps.API_PATH + 'getAccountsTaxList';
    private saveUrl = ShipStreetProps.API_PATH + 'newproductnservice';
    private pnsUrl = ShipStreetProps.API_PATH + 'getSalesProductAndService';

    public getSalesProductAndService(pnsId: string) {
  //    return this.http.post<AccountsProductServices>(this.pnsUrl , pnsId);
  }

    public getAccountsCategory() {
  //      return this.http.get<AccountsProductCategory[]>(this.appUrl);
    }

    public getAccountsInventoryAssets() {
      //  return this.http.post<AccountsAccount[]>(this.accUrl , 1);
    }

    public getAccountsIncome() {
      //  return this.http.post<AccountsAccount[]>(this.accUrl , 2);
    }

    public getAccountsExpenses() {
     //   return this.http.post<AccountsAccount[]>(this.accUrl , 3);
    }

     public getAccountsTaxList() {
       // return this.http.get<AccountsTax[]>(this.taxUrl);
    }

    // public newproductnservice(productnservice: AccountsProductServices) {
    //    return this.http.post<AccountsTax[]>(this.saveUrl , productnservice);
  //  }
}
