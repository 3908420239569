import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate130',
  templateUrl: './candidate130.component.html',
  styleUrls: ['./candidate130.component.css']
})
export class Candidate130Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
