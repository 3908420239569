import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CountryInfo } from 'src/app/model/countryInfo.model';

@Injectable({
  providedIn: 'root'
})
export class NewvendorService {

  private saveUrl = ShipStreetProps.API_PATH + 'containersupplier/saveContainerSupplier';
  private countryUrl = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';

  constructor(private http: HttpClient) { }

  public saveContainerSupplier(vendor: ContainerSupplier) {
    return this.http.post<string>(this.saveUrl, vendor);
  }

  viewCountry() {
    return this.http.get<CountryInfo[]>(this.countryUrl);
  }
}
