import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateconnectionService } from './candidateconnection.service';
import { CandidateConnect } from '../../../model/candidateConnect.model';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Component({
  selector: 'app-candidateconnection',
  templateUrl: './candidateconnection.component.html',
  styleUrls: ['./candidateconnection.component.css']
})
export class CandidateconnectionComponent implements OnInit {
  id: string;
  connections: CandidateConnect[];

  constructor(private candidateconnectionService: CandidateconnectionService , private route: ActivatedRoute , private router: Router) { }
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.candidateconnectionService.candidateConnections(this.id)
      .subscribe( data => {
        this.connections = data;
     //   alert(this.connections);
      },
      error => {
        this.router.navigate(['']);
        });


    //  this.route.params.subscribe(params => {// alert("hi");
    //   // this.setupComponent(params['someParam']);
    //   this.router.navigate(['/m/profile' , params[this.id]]);
    // })
  }

  friendProfile(friendId) {
     this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/m/profile', friendId]);
    });
  }


}
