import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managecompanyemployee',
  templateUrl: './managecompanyemployee.component.html',
  styleUrls: ['./managecompanyemployee.component.css']
})
export class ManagecompanyemployeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
