import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { helpRoutes } from './help-router.config';
import { RouterModule } from '@angular/router';

import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule , MatInputModule , MatSelectModule , MatNativeDateModule , MatCheckboxModule ,
  MatRadioModule , MatTabsModule} from '@angular/material';

import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HelpHomeComponent } from './help-home/help-home.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Profile20Component } from './profile20/profile20.component';
import { Profile21Component } from './profile21/profile21.component';
import { Profile22Component } from './profile22/profile22.component';
import { Profile23Component } from './profile23/profile23.component';
import { Company30Component } from './company30/company30.component';
import { Marketplace40Component } from './marketplace40/marketplace40.component';
import { Recruiter70Component } from './recruiter70/recruiter70.component';
import { Recruiter71Component } from './recruiter71/recruiter71.component';
import { Recruiter72Component } from './recruiter72/recruiter72.component';
import { Recruiter73Component } from './recruiter73/recruiter73.component';
import { Recruiter74Component } from './recruiter74/recruiter74.component';
import { Network90Component } from './network90/network90.component';
import { Jobs110Component } from './jobs110/jobs110.component';
import { Jobs111Component } from './jobs111/jobs111.component';
import { Jobs112Component } from './jobs112/jobs112.component';
import { Jobs113Component } from './jobs113/jobs113.component';
import { Jobs114Component } from './jobs114/jobs114.component';
import { Candidate130Component } from './candidate130/candidate130.component';
import { Candidate131Component } from './candidate131/candidate131.component';
import { Accounts150Component } from './accounts150/accounts150.component';
import { Accounts151Component } from './accounts151/accounts151.component';
import { Security101Component } from './security101/security101.component';
import { Feed101Component } from './feed101/feed101.component';
import { HelpcompaniesComponent } from './helpcompanies/helpcompanies.component';
import { KnowledgebaseComponent } from './knowledgebase/knowledgebase.component';
import { TypesofcontainersComponent } from './typesofcontainers/typesofcontainers.component';
import { AgentbookinghelpComponent } from './trackmybox/agent/agentbookinghelp/agentbookinghelp.component';


@NgModule({
  imports: [
    CommonModule, RouterModule.forChild(helpRoutes) , LayoutModule, FlexLayoutModule,
     MatToolbarModule, MatDatepickerModule , MatNativeDateModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule, MatTabsModule ,
    MatTableModule, MatRadioModule ,
    MatPaginatorModule, MatCheckboxModule,
    MatSortModule, MatSelectModule,
    MatFormFieldModule , MatInputModule
  ],
  declarations: [HelpHomeComponent, Profile20Component, Profile21Component, Profile22Component, Profile23Component,
    Company30Component, Marketplace40Component, Recruiter70Component, Recruiter71Component, Recruiter72Component,
    Recruiter73Component, Recruiter74Component, Network90Component, Jobs110Component, Jobs111Component,
    Jobs112Component, Jobs113Component, Jobs114Component, Candidate130Component, Candidate131Component,
    Accounts150Component, Accounts151Component, Security101Component, Feed101Component,
    HelpcompaniesComponent, KnowledgebaseComponent, TypesofcontainersComponent, AgentbookinghelpComponent, ]
})
export class HelpModule { }
