import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxContainerSupplier } from 'src/app/model/trackboxcontainersupplier.model';
import { TrackboxsupplierService } from '../../admin/trackboxsupplier/trackboxsupplier.service';

@Component({
  selector: 'app-tbagentsuppliers',
  templateUrl: './tbagentsuppliers.component.html',
  styleUrls: ['./tbagentsuppliers.component.css']
})
export class TbagentsuppliersComponent implements OnInit {
  trackboxsupplierList: TrackBoxContainerSupplier[];
  p: number;

  constructor(private trackboxsupplierService: TrackboxsupplierService, private router: Router) { }

  ngOnInit() {
    this.trackboxsupplierService.getAllMySupplier()
      .subscribe(data => {
        this.trackboxsupplierList = data;

      },
        error => {
          this.router.navigate(['']);
        });
  }

}
