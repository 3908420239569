import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { YourcompaniesService } from '../yourcompanies/yourcompanies.service';

@Component({
  selector: 'app-companyview',
  templateUrl: './companyview.component.html',
  styleUrls: ['./companyview.component.css']
})
export class CompanyviewComponent implements OnInit {
  mycompanies: CompanyEmployee[];
  defaultComp: CompanyEmployee;

  constructor(private yourcompaniesService: YourcompaniesService , private router: Router) { }

  ngOnInit() {
     this.yourcompaniesService.getAllMyCompanies()
      .subscribe( data => {
        this.mycompanies = data;
        for (let entry of this.mycompanies) {
          if(entry.defaultCompany)
            this.defaultComp  = entry; 
          }
      },
      error => {
        this.router.navigate(['']);
        });
  }

  loadDefaultCompany(compantId) {
    this.router.navigate(['m/companydetails' , compantId ]);
  }

}
