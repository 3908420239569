import { Component, OnInit } from '@angular/core';
import { Taxes } from 'src/app/model/taxes.model';
import { TaxAgency } from 'src/app/model/taxagency.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CommonService } from 'src/app/utils/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TaxagenciesService } from '../taxagencies/taxagencies.service';
import { EdittaxesService } from './edittaxes.service';
import { Company } from 'src/app/model/company.model';

@Component({
  selector: 'app-edittaxes',
  templateUrl: './edittaxes.component.html',
  styleUrls: ['./edittaxes.component.css']
})
export class EdittaxesComponent implements OnInit {

  tax: Taxes;
  taxagencies: TaxAgency[];
  sessionCandidate: SessionCandidate;

  constructor(private edittaxesService: EdittaxesService, private router: Router, private commonService: CommonService,
              private taxagenciesService: TaxagenciesService , private route: ActivatedRoute) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.tax = new Taxes();
    this.tax.taxAgency = new TaxAgency();
    this.tax.company = new Company();

    this.edittaxesService.getTaxById(this.route.snapshot.params.id)
      .subscribe(data => {
        this.tax = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.taxagenciesService.findAllTaxAgency()
      .subscribe(data => {
        this.taxagencies = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateTaxes() {
    this.edittaxesService.updateTaxes(this.tax)
      .subscribe(data => {
        this.router.navigate(['containers/taxes']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
