import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitefriendplug',
  templateUrl: './invitefriendplug.component.html',
  styleUrls: ['./invitefriendplug.component.css']
})
export class InvitefriendplugComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
