import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruiter70',
  templateUrl: './recruiter70.component.html',
  styleUrls: ['./recruiter70.component.css']
})
export class Recruiter70Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
