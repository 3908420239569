import { Jobs } from '../../model/jobs.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class SearchjobsService {

  private appUrl = ShipStreetProps.API_PATH + 'getSearchJob.do';
  private jobviewsUrl = ShipStreetProps.API_PATH + 'saveViewJobs.do';
  private wishListUrl = ShipStreetProps.API_PATH + 'saveJobWishlist.do';
  private unwishListUrl = ShipStreetProps.API_PATH + 'removeJobWishlist.do';

  constructor(private http: HttpClient) { }



  public getAllJobs() {
    return this.http.get<Jobs[]>(this.appUrl);
  }

   public jobviews(id: string) {
    return this.http.post<string>(this.jobviewsUrl , id);
  }

   public wishlist(id: string) {
    return this.http.post<string>(this.wishListUrl , id);
  }

  public unwishlist(id: string) {
    return this.http.post<string>(this.unwishListUrl , id);
  }
}
