import { Component, OnInit } from '@angular/core';
import { NewcontainerpurchaseService } from './newcontainerpurchase.service';
import { Router } from '@angular/router';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { VendorsService } from 'src/app/containers/admin/vendors/vendors.service';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';
import { ContainertypeService } from 'src/app/containers/master/containertype/containertype.service';
import { ContainerType } from 'src/app/model/containertype.model';
import { CommonService } from 'src/app/utils/common.service';
import { ContainerCompany } from 'src/app/model/containercompany.model';
import { PurchaselocationService } from 'src/app/containers/master/purchaselocation/purchaselocation.service';
import { ContainerPurchaseLocation } from 'src/app/model/containerpurchaselocation.model';
import { TaxesService } from 'src/app/containers/master/taxes/taxes.service';
import { Taxes } from 'src/app/model/taxes.model';
import { Company } from 'src/app/model/company.model';
import { FormControl, Validators, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-newcontainerpurchase',
  templateUrl: './newcontainerpurchase.component.html',
  styleUrls: ['./newcontainerpurchase.component.css']
})
export class NewcontainerpurchaseComponent implements OnInit {

  purchase: ContainerPurchase;
  supplierList: ContainerSupplier[];
  conttypeList: ContainerType[];
  containerPurLocation: ContainerPurchaseLocation[];
  taxeslist: Taxes[];
  myControlsupplier = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionssupplier: Observable<ContainerSupplier[]>;

  constructor(private newcontainerpurchaseService: NewcontainerpurchaseService, private vendorsService: VendorsService,
              private router: Router, private containertypeService: ContainertypeService, private commonService: CommonService,
              private purchaselocationService: PurchaselocationService, private taxesService: TaxesService) { }

  sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() {
    this.purchase = new ContainerPurchase();
    this.purchase.supplier = new ContainerSupplier();
    this.purchase.containerType = new ContainerType();
    this.purchase.company = new Company();
    this.purchase.purchasedLocation = new ContainerPurchaseLocation();
    this.purchase.vatPaid = new Taxes();

    this.vendorsService.findAllContainerSupplierbyCompany()
      .subscribe(data => {
        this.supplierList = data;
        this.filteredOptionssupplier = this.myControlsupplier.valueChanges.pipe(
          map(val => this._filtersupp(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });

    this.containertypeService.findAllContainerTypeByOrder()
      .subscribe(data => {
        this.conttypeList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.purchaselocationService.findAllContainerPurchaseLocationsByOrder()
      .subscribe(data => {
        this.containerPurLocation = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.taxesService.findAllTaxes()
      .subscribe(data => {
        this.taxeslist = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  saveContainerPurchase() {
    this.purchase.company.id = this.sessionCandidate.containerCompany;
    this.newcontainerpurchaseService.saveContainerPurchase(this.purchase)
      .subscribe(data => {
        this.router.navigate(['containers/containerpurchase']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  public _filtersupp(value: string): ContainerSupplier[] {
    const filterValuecust = value;
    return this.supplierList.filter(supplierList => supplierList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      supplierList.name.toLowerCase().indexOf(filterValuecust) === 0 ||
      supplierList.name.toUpperCase().indexOf(filterValuecust) === 0);
  }

  public displayCustomer(cntr: ContainerSupplier): string {
    if (cntr && cntr.name) {
      return cntr.name;
    }
    }


}


export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}
