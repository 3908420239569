import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TbagentblupdateComponent } from '../tbagentblupdate/tbagentblupdate.component';
import { TbagentblviewService } from './tbagentblview.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';

@Component({
  selector: 'app-tbagentblview',
  templateUrl: './tbagentblview.component.html',
  styleUrls: ['./tbagentblview.component.css']
})
export class TbagentblviewComponent implements OnInit {
  bookingId: number;
  blview: TrackBoxBookingContainers[];

  constructor(private tbagentblviewService: TbagentblviewService, private router: Router,
              public dialog: MatDialog, private route: ActivatedRoute) { }

  ngOnInit() {
     this.bookingId = this.route.snapshot.params.id;
    //  this.blview = new TrackBoxBookingContainers();
     this.tbagentblviewService.getBLviewByID(this.route.snapshot.params.id)
          .subscribe(data1 => {
            this.blview = data1;
            // alert(JSON.stringify(this.blview))
          },
            error => {
              this.router.navigate(['']);
            });


  }

  blupdate(bookingId): void {
    //  alert(bookingId);
      const dialogoutRef = this.dialog.open(TbagentblupdateComponent, {
        width: '550px',
        data: { bookingId }
      });
      dialogoutRef.afterClosed().subscribe(result => {
        // this.tbagentblviewService.getBookingsDetailsByID(this.route.snapshot.params.id)
        //     .subscribe(data1 => {
        //       this.trackboxbooking = data1;
        //     },
        //       error => {
        //         this.router.navigate(['']);
        //       });
      });
    }
}
