import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { EquipmentcontrollistService } from './equipmentcontrollist.service';
import * as XLSX from 'xlsx';
import * as jspdf from 'jspdf';
import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import html2canvas from 'html2canvas';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS_FACTORY } from '@angular/material';
import { MyBoxSearchExport } from 'src/app/model/mybox.export.model';
import { MyboxesComponent } from '../myboxes/myboxes.component';
// import * as FileSaver from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-equipmentcontrollist',
  templateUrl: './equipmentcontrollist.component.html',
  styleUrls: ['./equipmentcontrollist.component.css']
})
export class EquipmentcontrollistComponent implements OnInit {

  constructor(private equipmentcontrollistService: EquipmentcontrollistService, private router: Router, private route: ActivatedRoute) { }
 tbcontainerList: TrackBoxContainer[];
 p: number ;
 printList: MyBoxSearchExport[];
 myBox: MyBoxSearchExport;
 fileName = 'ExcelSheet.xlsx';
  pdfContent: string;
    tableContent = [];
    rowContent = [];
 panelOpenState: boolean;

  // exportexcel(): void {
  //      /* table id is passed over here */
  //      const element = document.getElementById('customers');
  //      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  //      /* generate workbook and add the worksheet */
  //      const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //      /* save to file */
  //      XLSX.writeFile(wb, this.fileName);

  //   }


  exportAsXLSX(): void {
   // this.exportAsExcelFile(this.tbcontainerList, 'sample');
   // printList: [];

    this.printList = new Array();

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.tbcontainerList.length; i = i + 1) {

    this.myBox = new MyBoxSearchExport();
    this.myBox.containerNumber = this.tbcontainerList[i].container.containerNumber;
    this.myBox.typeCode = this.tbcontainerList[i].container.equipmentType.typeCode;
    this.myBox.containerStatus = this.tbcontainerList[i].containerStatus.name;
        // this.myBox.gateIn = this.tbcontainerList[i].gateIn;
        // this.myBox.yom =this.tbcontainerList[i].container.manufacturingDate;
    this.myBox.country = this.tbcontainerList[i].country.name;
    this.myBox.port = this.tbcontainerList[i].port.name;
    this.printList.push(this.myBox);


      //  this.printList.push({ this.tbcontainerList[i].gateIn, this.tbcontainerList[i].gateOut})
    }
    this.exportAsExcelFile(this.printList, 'sample');

 }
 public exportAsExcelFile(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
   FileSaver.saveAs(data, fileName + 'export' + new  Date().getTime() + EXCEL_EXTENSION);
}
    // exportAsPDF(exceltable) {
    //     const data = document.getElementById('customers');
    //     html2canvas(data).then(canvas => {
    //     const contentDataURL = canvas.toDataURL('image/png');
    //     const pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
    //      // let pdf = new jspdf('1', 'cm', 'a4'); //Generates PDF in portrait mode
    //     pdf.addImage(contentDataURL, 'PNG', 0, 1, 32, 5);
    //     pdf.save('Filename.pdf');
    //     });
    //   }


    generatePDF() {


  this.tableContent.push([{text: 'Container Number', bold: true  }, {text: 'ISO Code', bold: true },
  {text: 'Country ', bold: true , color: 'red' }, {text: 'Purchase status', bold: true , color: 'red' },
  {text: 'current location', bold: true , color: 'red' }, {text: 'container status', bold: true , color: 'red' } ]);

  this.pdfContent = '<table ><tr>td>Container No</td><td>Code</td></tr>';

  this.printList = new Array();
  this.myBox = new MyBoxSearchExport();
  this.myBox.containerNumber = 'Container Number';
  this.myBox.containerStatus = 'Container status';

  this.myBox.country = 'Country';
  this.myBox.port = 'Current location';

  this.myBox.typeCode = 'ISO Code';
  this.printList.push(this.myBox);



      // this.printList.push({p.container.containerNumber, p.container.equipmentType.typeCode})
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < this.tbcontainerList.length; i = i + 1) {

        // tslint:disable-next-line: max-line-length
        // this.rowContent.push([this.searchResultList[i].container.containerNumber, this.searchResultList[i].container.equipmentType.typeCode]);
       // this.pdfContent = this.pdfContent+this.searchResultList[i].container.containerNumber;
        // this.pdfContent = this.pdfContent+this.searchResultList[i].containerStatus.name;
        // this.pdfContent = this.pdfContent+this.searchResultList[i].container.equipmentType.typeCode;
        // this.pdfContent= this.pdfContent+"\n";
       this.myBox = new MyBoxSearchExport();
       this.myBox.containerNumber = this.tbcontainerList[i].container.containerNumber;
       this.myBox.typeCode = this.tbcontainerList[i].container.equipmentType.typeCode;
       this.myBox.country = this.tbcontainerList[i].country.name;
           // this.myBox.carrier = this.containerList[i].carrier.name;
       this.myBox.port = this.tbcontainerList[i].port.name;

           // this.myBox.gateIn = this.tbcontainerList[i].gateIn;
           // this.myBox.yom =this.tbcontainerList[i].container.manufacturingDate;
       this.myBox.containerStatus = this.tbcontainerList[i].containerStatus.name;
       this.printList.push(this.myBox);
  //  alert(this.printList);
       // content: 'Sample PDF generated with Angular and PDFMake for C#Corner Blog'
     }
  this.tableContent.push(this.rowContent);
  const docDefinition1 = {

      content: '' + this.pdfContent

        //   content: this.searchResultList
        };

  console.log(this.printList);

  const docDefinition = {
          styles: {
            sectionHeader: {
                bold: true,
                decoration: 'underline',
                fontSize: 14,
                margin: [0, 15, 0, 15]
            }
        }  ,
          content:  [
            {
              text: 'Equipment ',
              style: 'sectionHeader'
            },




            {
              table: {
                headerRows: 1,
                widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                body: // this.tableContent
                   this.printList.map(p => ([p.containerNumber, p.typeCode, p.port, p.containerStatus, p.country]))
              }
            }
          ]



            };


  pdfMake.createPdf(docDefinition).open();

      // let docDefinition = {

      // content:  this.searchResultList

    //



    //   pdfMake.createPdf(docDefinition).open();

  }
  ngOnInit() {
    this.equipmentcontrollistService.getTransitContainers(this.route.snapshot.params.text)
      .subscribe(data => {
        this.tbcontainerList = data;
        // alert(JSON.stringify(this.tbcontainerList));
      },
        error => {
          this.router.navigate(['']);
        });
      }
    }
