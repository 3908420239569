import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewcontainertypeService } from 'src/app/containers/master/containertype/newcontainertype/newcontainertype.service';
import { CalcEquipmentType } from 'src/app/model/CalcEquipmentType.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CommonService } from 'src/app/utils/common.service';
import { NewcalcequipmenttypeService } from './newcalcequipmenttype.service';

@Component({
  selector: 'app-newcalcequipmenttype',
  templateUrl: './newcalcequipmenttype.component.html',
  styleUrls: ['./newcalcequipmenttype.component.css']
})
export class NewcalcequipmenttypeComponent implements OnInit {

  sessionCandidate: SessionCandidate;
  codes: string[];
  equipmentType: CalcEquipmentType;

  constructor(private newcalcequipmenttypeService: NewcalcequipmenttypeService, private router: Router,
              private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.equipmentType = new CalcEquipmentType();
    this.codes = ['10' , '20' , '40' , '45'];
  }

  saveContainerType() {
    this.newcalcequipmenttypeService.saveCalcEequipmentType(this.equipmentType)
      .subscribe(data => {
        this.router.navigate(['/calculator/calcequipmenttypes']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
