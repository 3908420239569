import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/model/customer.model';
import { EditcustomerService } from './editcustomer.service';

@Component({
    selector: 'app-editcustomer',
    templateUrl: './editcustomer.component.html',
    styleUrls: ['./editcustomer.component.css']
})
export class EditcustomerComponent implements OnInit {
    customer: Customer;
    currencies: string[];
    billingDuration: string[];

    constructor(private editcustomerService: EditcustomerService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.currencies = ['AED', 'USD', 'INR'];
        this.billingDuration = ['Daily', 'Weekly', 'Monthly'];
        this.editcustomerService.getCustomer(this.route.snapshot.paramMap.get('id'))
            .subscribe(data => {
                this.customer = data;
            },
                error => {
                    this.router.navigate(['']);
                });
    }

    updateCustomer() {
        this.editcustomerService.updateCustomer(this.customer)
            .subscribe(data => {
                this.router.navigate(['/accounts/customer']);
            },
                error => {
                    this.router.navigate(['']);
                });
    }

}
