import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'marketplacefilter'
})
export class MarketPlaceFilter implements PipeTransform {
  transform(marketplaces: any[], searchText: string): any[] {
    if (!marketplaces) { 
        return marketplaces; 
    }
    if (!searchText) { 
        return marketplaces; 
    }
  searchText = searchText.toLowerCase();
  return marketplaces.filter( marketplace => {
        if(marketplace && marketplace.itemName.toLowerCase().indexOf(searchText) != -1 ){
            return true;
        }else{
            return false;
        }
    });
   }
}