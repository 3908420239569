import { Component, OnInit } from '@angular/core';
import { CandidateEducation } from '../../../model/candidateEducation.model';
import { AddeducationService } from './addeducation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addeducation',
  templateUrl: './addeducation.component.html',
  styleUrls: ['./addeducation.component.css']
})
export class AddeducationComponent implements OnInit {
  neweducation: CandidateEducation;
  candidateEducations: CandidateEducation[];
  degrees: { value: string; text: string; }[];
  sexList: { value: string; text: string; }[];
  maritalStatusList: { value: string; text: string; }[];

  constructor(private addeducationService: AddeducationService, private router: Router) { }

  ngOnInit() {
    this.neweducation = new CandidateEducation;

    this.degrees = [
  {value : 'High School/Secondary' , text : 'High School/Secondary'},
  {value : 'Some College' , text : 'Some College' },
  {value : 'Associates/2 yr Degree' , text : 'Associates/2 yr Degree'},
  {value : 'Bachelors/4 yr Degree' , text : 'Bachelors/4 yr Degree'},
  {value : 'Masters Degree' , text : 'Masters Degree'},
  {value : 'PhD/MD/Doctorate' , text : 'PhD/MD/Doctorate'},
  {value : 'Tech/Vocational Cert.' , text : 'Tech/Vocational Cert.'}
  ];
  this.sexList = [
        {value: 'Male', text: 'Male'},
        {value: 'Female', text: 'Female'}
    ];

    this.maritalStatusList = [
        {value: 'Single', text: 'Single'},
        {value: 'Married', text: 'Married'},
        {value: 'Separated', text: 'Separated'},
        {value: 'Divorced', text: 'Divorced'},
        {value: 'Widowed', text: 'Widowed'}
    ];

  }

   saveEducation() {
    this.addeducationService.saveType(this.neweducation)
      .subscribe( data => {
        this.candidateEducations = data;
//        this.router.navigate(['editprofile']);
      },
      error => {
        this.router.navigate(['']);
        });
    }


}
