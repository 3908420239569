import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsProductServices } from 'src/app/model/accountsproductservices.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ProductservicesService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'productnservices/getAllProductNServices.do';

  public getAllProductNServices() {
    return this.http.get<AccountsProductServices[]>(this.appUrl);
  }
}
