import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from '../_layouts/app-layout/app-layout.component';
import { ProfileComponent } from '../profile/profile/profile.component';
import { EditprofileComponent } from '../profile/editprofile/editprofile.component';
import { CandidateComponent } from '../profile/candidate/candidate.component';
import { CandidatemessageComponent } from '../profile/candidate/candidatemessage/candidatemessage.component';
import { CandidatemessagedetailsComponent } from '../profile/candidate/candidatemessagedetails/candidatemessagedetails.component';
import { YourupdatesComponent } from '../profile/yourupdates/yourupdates.component';
import { WhoviewprofileComponent } from '../profile/whoviewprofile/whoviewprofile.component';
import { ConnectionsComponent } from '../network/connections/connections.component';
import { PeopleyoumayknowComponent } from '../network/peopleyoumayknow/peopleyoumayknow.component';
import { NewcompanyComponent } from '../company/newcompany/newcompany.component';
// import { CompanyeditComponent } from '../company/companyedit/companyedit.component';
import { MarketplaceComponent } from '../network/marketplace/marketplace.component';
import { MarketplaceinfoComponent } from '../network/marketplace/marketplaceinfo/marketplaceinfo.component';
import { MarketplaceplanComponent } from '../network/marketplace/marketplaceplan/marketplaceplan.component';
import { MarketplaceorderComponent } from '../network/marketplace/marketplaceorder/marketplaceorder.component';
import { AddresspageComponent } from '../network/marketplace/addresspage/addresspage.component';
import { BookingconfirmationComponent } from '../network/marketplace/bookingconfirmation/bookingconfirmation.component';
import { MarketplacequoteComponent } from '../network/marketplace/marketplacequote/marketplacequote.component';
import { MarketplacequoteviewComponent } from '../network/marketplace/marketplacequoteview/marketplacequoteview.component';
import { MarketplaceMyorderComponent } from '../network/marketplace/marketplace-myorder/marketplace-myorder.component';
import { TrackmyorderComponent } from '../network/marketplace/trackmyorder/trackmyorder.component';
import { MarketplacedetailsComponent } from '../network/marketplace/marketplacedetails/marketplacedetails.component';
import { PostsComponent } from '../posts/posts/posts.component';
import { NewpostComponent } from '../posts/newpost/newpost.component';
import { PostdetailsComponent } from '../posts/postdetails/postdetails.component';
import { EditpostComponent } from '../posts/editpost/editpost.component';
import { SearchjobsComponent } from '../jobs/searchjobs/searchjobs.component';
import { JobdetailsComponent } from '../jobs/jobdetails/jobdetails.component';
import { PreferencesComponent } from '../jobs/preferences/preferences.component';
import { MyjobsComponent } from '../jobs/myjobs/myjobs.component';
import { RecruitmentComponent } from '../interest/recruitment/recruitment.component';
import { SearchcandidateComponent } from '../interest/searchcandidate/searchcandidate.component';
import { BuildnetworkComponent } from '../more/buildnetwork/buildnetwork.component';
import { JobplanComponent } from '../recruiter/jobplan/jobplan.component';
import { RefundComponent } from '../more/refund/refund.component';
import { HowitworksComponent } from '../more/howitworks/howitworks.component';
import { AboutComponent } from '../more/about/about.component';
import { ContactComponent } from '../more/contact/contact.component';
import { GlobelsearchComponent } from '../header/globelsearch/globelsearch.component';
import { GlobalsearchpeopleComponent } from '../header/globalsearchpeople/globalsearchpeople.component';
import { GlobalsearchjobComponent } from '../header/globalsearchjob/globalsearchjob.component';
import { GlobalsearchcompanyComponent } from '../header/globalsearchcompany/globalsearchcompany.component';
import { GraphComponent } from '../more/graph/graph.component';
import { ExportpdfComponent } from '../more/exportpdf/exportpdf.component';
import { RigeventsComponent } from '../network/rigevents/rigevents.component';
import { VieweventsComponent } from '../network/rigevents/viewevents/viewevents.component';
import { AddeventguestComponent } from '../network/rigevents/addeventguest/addeventguest.component';
import { ContainerComponent } from '../container/container.component';
import { ServicelicensesComponent } from '../billing/servicelicenses/servicelicenses.component';
import { ContainerservicelicensesComponent } from '../billing/containerservicelicenses/containerservicelicenses.component';
import { SurvicerequestlistComponent } from '../helpcentre/survicerequestlist/survicerequestlist.component';
import { HelpcentreComponent } from '../helpcentre/helpcentre.component';
import { SubscriptionfirstComponent } from '../billing/subscriptionfirst/subscriptionfirst.component';
import { LicensepurchasedComponent } from '../billing/licensepurchased/licensepurchased.component';
import { BillspaymentsComponent } from '../billing/billspayments/billspayments.component';
import { BillingaccountsComponent } from '../billing/billingaccounts/billingaccounts.component';
import { PaymentmethodComponent } from '../billing/paymentmethod/paymentmethod.component';
import { TrackboxcandidateintroComponent } from '../trackboxcandidateintro/trackboxcandidateintro.component';
import { TrackboxcompanyintroComponent } from '../trackboxcompanyintro/trackboxcompanyintro.component';
import { TrackboxprofileintroComponent } from '../trackboxprofileintro/trackboxprofileintro.component';
import { ContainertradingprofilesComponent } from '../container/containertradingprofiles/containertradingprofiles.component';
import { DepotxpressinfoComponent } from '../depotxpressinfo/depotxpressinfo.component';
import { DashbaordComponent } from './dashbaord/dashbaord.component';
import { InvitefriendComponent } from '../network/invitefriend/invitefriend.component';
import { CalculatorintroComponent } from '../calculatorintro/calculatorintro.component';
import { CalculatornoaccessintroComponent } from '../calculatorintro/calculatornoaccessintro/calculatornoaccessintro.component';
import { CompaniesComponent } from '../company/companies/companies.component';
import { CompanyeditComponent } from '../company/companyedit/companyedit.component';
import { YourcompaniesComponent } from '../company/yourcompanies/yourcompanies.component';
import { CompanyviewComponent } from '../company/companyview/companyview.component';
import { PaypalComponent } from '../company/companies/paypal/paypal.component';
import { ManagecompanyemployeesComponent } from '../company/managecompanyemployees/managecompanyemployees.component';
import { CompanydetailsComponent } from '../company/companydetails/companydetails.component';
import { ViewallcompanyfollowersComponent } from '../company/viewallcompanyfollowers/viewallcompanyfollowers.component';
import { ManagecompanyfollowersComponent } from '../company/managecompanyfollowers/managecompanyfollowers.component';
import { ContainersearchesComponent } from '../containersearches/containersearches.component';
import { DisableconfirmationComponent } from '../company/disableconfirmation/disableconfirmation.component';
import { SharedeletedialogComponent } from './dashbaord/sharedeletedialog/sharedeletedialog.component';

export const anweighRoutes: Routes = [
  {
    path: 'm',
    component: AppLayoutComponent,
    children: [
      { path: '', component: DashbaordComponent },
      { path: 'dashboard', component: DashbaordComponent },
      { path: 'viewprofile', component: ProfileComponent },
      { path: 'editprofile', component: EditprofileComponent },
      // { path: 'shabykunjumon',   redirectTo:'profile/:id' },
      { path: 'profile/:id', component: CandidateComponent },
      { path: 'profilefr/:id', component: CandidateComponent },
      // { path: ':alias', component: CandidatebynameComponent },
      { path: 'candidatemessage/:id', component: CandidatemessageComponent },
      { path: 'candidatemessagedetails/:id/:canId', component: CandidatemessagedetailsComponent },
      { path: 'yourupdates', component: YourupdatesComponent },
      { path: 'whoviewprofile', component: WhoviewprofileComponent },
      { path: 'connections', component: ConnectionsComponent },
      { path: 'invitefriend', component: InvitefriendComponent },
      { path: 'peopleyoumayknow', component: PeopleyoumayknowComponent },
      { path: 'companies', component: CompaniesComponent },
      { path: 'newcompany', component: NewcompanyComponent },
      { path: 'companyedit/:id', component: CompanyeditComponent },
      { path: 'companydetails/:id/:type', component: CompanydetailsComponent },
      { path: 'companydetails/:id', component: CompanydetailsComponent },
      { path: 'mycompany', component: YourcompaniesComponent },
      { path: 'companyview', component: CompanyviewComponent },
      { path: 'viewcompanyfollowers/:id' , component: ViewallcompanyfollowersComponent},
      { path: 'managecompanyemployees/:id', component: ManagecompanyemployeesComponent },
      { path: 'managecompanyemployee/:id' , component: ManagecompanyemployeesComponent},
      { path: 'managecompanyfollowers/:id' , component: ManagecompanyfollowersComponent},
      { path: 'marketplaces', component: MarketplaceComponent },
      { path: 'marketplaceinfo', component: MarketplaceinfoComponent },
      { path: 'marketplacePlan', component: MarketplaceplanComponent },
      { path: 'marketplaceorder', component: MarketplaceorderComponent },
      { path: 'addresspage', component: AddresspageComponent },
      { path: 'bookingconfirmation', component: BookingconfirmationComponent },
      { path: 'marketplacequote/:id', component: MarketplacequoteComponent },
      { path: 'marketplacequoteview/:id', component: MarketplacequoteviewComponent },
      { path: 'marketplaceMyorder', component: MarketplaceMyorderComponent },
      { path: 'trackMyOrder/:id', component: TrackmyorderComponent },
      { path: 'viewmarketdetails/:id', component: MarketplacedetailsComponent },
      { path: 'viewposts', component: PostsComponent },
      { path: 'newposts', component: NewpostComponent },
      { path: 'postdetails/:id', component: PostdetailsComponent },
      { path: 'editpost/:id', component: EditpostComponent },
      { path: 'searchjobs', component: SearchjobsComponent },
      { path: 'jobdetails/:id', component: JobdetailsComponent },
      { path: 'preferences', component: PreferencesComponent },
      { path: 'myjobs', component: MyjobsComponent },
      { path: 'recruitement', component: RecruitmentComponent },
      { path: 'searchcandidate', component: SearchcandidateComponent },
      { path: 'buildnetwork', component: BuildnetworkComponent },
      { path: 'pricing', component: JobplanComponent },
      { path: 'refund', component: RefundComponent },
      { path: 'howitworks', component: HowitworksComponent },
      { path: 'aboutus', component: AboutComponent },
      { path: 'contactus', component: ContactComponent },
      { path: 'globelsearch/:text', component: GlobelsearchComponent },
      { path: 'globalsearchpeople/:text', component: GlobalsearchpeopleComponent },
      { path: 'globalsearchjob/:text', component: GlobalsearchjobComponent },
      { path: 'globalsearchcompany/:text', component: GlobalsearchcompanyComponent },
      { path: 'graph', component: GraphComponent },
      { path: 'exportpdf', component: ExportpdfComponent },
      { path: 'rigevents', component: RigeventsComponent },
      { path: 'viewevent/:id', component: VieweventsComponent },
      { path: 'addEventGuest/:id', component: AddeventguestComponent },
      { path: 'container', component: ContainerComponent },
      { path: 'servicelicense', component: ServicelicensesComponent },
      { path: 'containerservicelicense', component: ContainerservicelicensesComponent },
      { path: 'survicerequestlist', component: SurvicerequestlistComponent },
      { path: 'newServiceRequest', component: HelpcentreComponent },
      //     { path: 'servicelicensedetails', component: ServicelicensedetailsComponent },
      //    { path: 'subscriptiondetails', component: SubscriptiondetailsComponent },
      //    { path: 'subscriptioncheckout', component: SubscriptioncheckoutComponent },
      { path: 'subscriptionfirst', component: SubscriptionfirstComponent },
      { path: 'licensepurchased', component: LicensepurchasedComponent },
      { path: 'billspayments', component: BillspaymentsComponent },
      { path: 'billingaccounts', component: BillingaccountsComponent },
      { path: 'paymentmethod', component: PaymentmethodComponent },
      { path: 'tackboxcandidateintro', component: TrackboxcandidateintroComponent },
      { path: 'tackboxcompanyintro', component: TrackboxcompanyintroComponent },
      { path: 'trackboxprofileintro', component: TrackboxprofileintroComponent },
      { path: 'containertradingprofile', component: ContainertradingprofilesComponent },
      { path: 'depotxpressinfo', component: DepotxpressinfoComponent },
      { path: 'paypal', component: PaypalComponent },
      { path: 'calculatorintro', component: CalculatorintroComponent },
      { path: 'calculatornoaccessintro', component: CalculatornoaccessintroComponent },
      { path: 'containersearches', component: ContainersearchesComponent },
      { path: 'disableconfirmation/:id', component: DisableconfirmationComponent },
      { path: 'sharedeletedialog', component: SharedeletedialogComponent },
    ]
  }];



@NgModule({
  declarations: [],
  imports: [
    CommonModule,RouterModule.forRoot(anweighRoutes)
  ],
  exports: [RouterModule]
})
export class AnweighRouterModule { }
