import { ContainerPurchasedUnits } from './../../../../model/containerpurchasedunits.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ViewpurchasecontainersService {

constructor(private http: HttpClient) { }

private orderlistUrl = ShipStreetProps.API_PATH + 'containerpurchase/findAllPurchasedContainerRecords';
private typelistUrl = ShipStreetProps.API_PATH + 'containerpurchase/getPurchasedContainersByType';

public getAllPurchasedContainers() {
  return this.http.get<ContainerPurchasedUnits[]>(this.orderlistUrl);

}
public getPurchasedContainersByType(paramVal: string) {
  return this.http.post<ContainerPurchasedUnits[]>(this.typelistUrl , paramVal);

}

}
