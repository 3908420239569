import { Component, OnInit } from '@angular/core';
import { BookingsService } from 'src/app/trackmybox/bookings/bookings.service';
import { Router } from '@angular/router';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

@Component({
  selector: 'app-exportbooking',
  templateUrl: './exportbooking.component.html',
  styleUrls: ['./exportbooking.component.css']
})
export class ExportbookingComponent implements OnInit {
  bookingsList: TrackBoxBooking[];
  p = 1;
  itemsPerPage: 1;
  panelOpenState: boolean;
  searchText: string;
  bookingsListAll: TrackBoxBooking[];

  constructor(private bookingsService: BookingsService, private router: Router) { }

  ngOnInit() {
    this.bookingsService.getAllMyBookings()
      .subscribe(data => {
        this.bookingsListAll = data;
        this.bookingsList = this.bookingsListAll;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  searchbar() {
     if (this.searchText !== '') {
      this.bookingsList = this.bookingsListAll.filter(res => {
        return res.bookingNo.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.vessel.masterVessel.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.voyageNumber.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.pol.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.pod.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())
      });
    } else if (this.searchText === '') {
      this.bookingsList = this.bookingsListAll;
    } 
  }

}
