import { CandidateCertificate } from '../../model/candidateCertificate.model';
import { Certificate } from '../../model/certificate.model';
import { CertificateService } from './certificate.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheObject } from '../../model/cacheobject.model';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {
  certDiv: boolean;
  showcert: boolean;
  newcertificate: CandidateCertificate;

  candidateCertificates: CandidateCertificate[];
  certificate: CandidateCertificate;
  certificateList: CacheObject[];

  constructor(private certificateService: CertificateService , private router: Router) { }

  ngOnInit() {
    this.certDiv = true;
    this.newcertificate = new CandidateCertificate;
    this.newcertificate.certificate = new Certificate;
  // this.certificateService.candidateCertificates()
  //     .subscribe( data => {
  //       this.candidateCertificates = data;
  //     },
  //     error => {
  //       this.router.navigate(['']);
  //       });

    this.certificateService.getAllCertificates()
      .subscribe( data => {
        this.certificateList = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  saveCertificate() {
    this.certificateService.saveType(this.newcertificate)
      .subscribe( data => {
        this.candidateCertificates = data;
//        this.router.navigate(['editprofile']);
        this.showcert = false;
      },
      error => {
        this.router.navigate(['']);
        });
    }
  updateCandidateCertificate(certificate: CandidateCertificate) {
    this.certificateService.updateCandidateCertificate(certificate)
      .subscribe( data => {
      //  this.candidateCertificates = data;
//        this.router.navigate(['editprofile']);
        certificate.certDiv = false;
      },
      error => {
        this.router.navigate(['']);
        });
    }
deleteCertification(certificate: CandidateCertificate) {
    this.certificateService.deleteCertification(certificate)
      .subscribe( data => {
        this.candidateCertificates = data;
//        this.router.navigate(['editprofile']);
        certificate.certDiv = false;
      },
      error => {
        this.router.navigate(['']);
        });
    }
}
