import { Component, OnInit } from '@angular/core';
import { NewcustomerService } from './newcustomer.service';
import { Router } from '@angular/router';
import { Customer } from 'src/app/model/customer.model';

@Component({
  selector: 'app-newcustomer',
  templateUrl: './newcustomer.component.html',
  styleUrls: ['./newcustomer.component.css']
})
export class NewcustomerComponent implements OnInit {
    customer: Customer;
    currencies: string[];
    billingDuration: string[];

  constructor(private newcustomerService: NewcustomerService, private router: Router) { }

  ngOnInit() {
      this.customer = new Customer();
      this.currencies = ['AED' , 'USD' , 'INR'];
      this.billingDuration = ['Daily' , 'Weekly' , 'Monthly'];
  }

  saveCustomer() {
      this.newcustomerService.addnewcustomer(this.customer)
            .subscribe(data => {
                this.router.navigate(['/accounts/customer']);
            },
                error => {
                    this.router.navigate(['']);
                });
  }

}
