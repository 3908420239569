import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Company } from 'src/app/model/company.model';

@Injectable({
  providedIn: 'root'
})
export class ContainercompanyService {

  private appUrl = ShipStreetProps.API_PATH + 'containercompany/getcontainercompany';

  constructor(private http: HttpClient) { }

  public containerCustomerList() {
    return this.http.get<Company>(this.appUrl);
  }
}
