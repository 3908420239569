import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerLease } from 'src/app/model/containerlease.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ContainerleasehistoryService {

  private listUrl = ShipStreetProps.API_PATH + 'containerlease/findAllContainerLeaseHistoryListByCompany';

  constructor(private http: HttpClient) { }

  public findAllContainerLeaseListByCompany() {
    return this.http.get<ContainerLease[]>(this.listUrl);
  }

}
