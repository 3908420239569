import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentdoviewService {

constructor(private http: HttpClient) { }
private appUrlcontainer = ShipStreetProps.API_PATH + 'TrackboxBookings/doviewdetails.do';
  public getDOviewByID( bookingId: string) {
    return this.http.post<TrackBoxBooking>(this.appUrlcontainer, bookingId);
    }

}
