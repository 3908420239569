import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
// import { TrackBoxContainer } from './../../../../model/trackboxcontainer.model';
@Injectable({
  providedIn: 'root'
})
export class OffhireequipmentService {

constructor(private http: HttpClient) { }
private appUrlscontDetails = ShipStreetProps.API_PATH + 'cntequipmentcontainers/containersDetails.do';
private appurl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/offhireSave.do';

public getDetails( ContainerId: number) {
  // alert(JSON.stringify(ContainerId));
  return this.http.post<TrackBoxContainer>(this.appUrlscontDetails, ContainerId);
}

public offhireSave(offhireeqp: TrackBoxContainer) {
  //  alert(JSON.stringify(offhireeqp));
   return this.http.post<TrackBoxContainer>(this.appurl , offhireeqp);
 }

}
