import { Company } from '../../model/company.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'companies.do';
  private companyFollowUrl = ShipStreetProps.API_PATH + 'companyFollow.do';
  private companyUnFollowUrl = ShipStreetProps.API_PATH + 'companyUnFollow.do';

  public getAllCompanies() {
    return this.http.get<Company[]>(this.appUrl);
  }

   public companyFollow(companyId: Company) {
    return this.http.post<Company>(this.companyFollowUrl , companyId);
  }
   public companyUnFollow(companyId: Company) {
    return this.http.post<Company>(this.companyUnFollowUrl , companyId);
  }
}
