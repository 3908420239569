import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerSales } from 'src/app/model/containersales.model';

@Injectable({
  providedIn: 'root'
})
export class ContainersalesService {

  private listUrl = ShipStreetProps.API_PATH + 'containersales/findAllContainerSales';
  private orderlistUrl = ShipStreetProps.API_PATH + 'containersales/findAllContSalesByCompany';
  private dtorderlistUrl = ShipStreetProps.API_PATH + 'containersales/findAllContSalesByCompanyByDate';

  constructor(private http: HttpClient) { }

  public findAllContainerSales() {
    return this.http.get<ContainerSales[]>(this.listUrl);
  }

  public findAllContSalesByCompany() {
    return this.http.get<ContainerSales[]>(this.orderlistUrl);
  }

  public findAllContSalesByCompanyByDate() {
    return this.http.get<ContainerSales[]>(this.dtorderlistUrl);
  }

}
