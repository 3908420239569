import { CacheObject } from '../../model/cacheobject.model';
import { Candidates } from '../../model/candidate.model';
import { Company } from '../../model/company.model';
import { Designation } from '../../model/designation.model';
import { Page1Service } from './page1.service';
import { Component, OnInit, ViewChild, Directive, ElementRef } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-page1',
  templateUrl: './page1.component.html',
  styleUrls: ['./page1.component.css']
})



export class Page1Component implements OnInit {

  private element: HTMLInputElement;

  checked: boolean = false;
  companies: CacheObject[];
  designations: CacheObject[];
  candidate: Candidates;



  constructor( private page1Service: Page1Service , private router: Router, private elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }

  ngOnInit() {



    this.checked = false;

    this.candidate = new Candidates;
    this.candidate.designation = new Designation;
    this.candidate.company = new Company;

    this.page1Service.getDesignation()
      .subscribe( data => {
        this.designations = data;
      },
      error => {
        this.router.navigate(['']);
        });





    this.page1Service.getCompanies()
      .subscribe( data => {
        this.companies = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  saveCurrentJobDetails() {
     this.page1Service.saveCurrentJobDetails(this.candidate)
      .subscribe( data => {
      this.router.navigate(['page3']);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  // @ViewChild("placesRef") placesRef : GooglePlaceDirective;
// @Directive({
//   selector: '[google-place]'
// })


}
