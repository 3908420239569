import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Dashboard } from 'src/app/model/dashboard.model';
import { FileUploadResponse } from 'src/app/model/fileuploadResponse.model';
import { Feeds } from 'src/app/model/feeds.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { FeedsComments } from 'src/app/model/feedsComments.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  pageNo: number;
  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'getFeedsForLoggedCandidate.do';
  private publishpostUrl = ShipStreetProps.API_PATH + 'postNewFeed.do';
  private saveFeedCommentUrl = ShipStreetProps.API_PATH + 'saveFeedComment.do';
  private likefeedUrl = ShipStreetProps.API_PATH + 'updateLikeFeeds.do';
  private sharepostUrl = ShipStreetProps.API_PATH + 'shareexistingpost.do';
  private loadNextPageUrl = ShipStreetProps.API_PATH + 'getFeedsForLoggedCandidate.do';
  private verifyUrl = ShipStreetProps.API_PATH + 'candidateFromSession.do';
  private postImageUrl = ShipStreetProps.API_PATH + 'postNewImage.do';
  private deleteFeedUrl = ShipStreetProps.API_PATH + 'deleteFeed.do';
  private blockFeedUrl = ShipStreetProps.API_PATH + 'blockFeed.do';
  private reportFeedUrl = ShipStreetProps.API_PATH + 'reportFeed.do';
  private deleteFeedLikeUrl = ShipStreetProps.API_PATH + 'deleteFeedLike.do';
  private verificationUrl = ShipStreetProps.API_PATH + 'candidateVerification.do';
  private resendverificationUrl = ShipStreetProps.API_PATH + 'resendVerificationCode.do';
  private feedImageUplaodUrl = ShipStreetProps.API_PATH + 'FeedImageUpload.do';
  private deleteFeedCommentUrl = ShipStreetProps.API_PATH + 'deleteFeedComment.do';

  public getDashboardFeeds() {
    this.pageNo = 1;
    return this.http.post<Dashboard>(this.appUrl , this.pageNo);
  }

  public postImageUpload( selectedFile: FormData) {
    return this.http.post<FileUploadResponse>(this.feedImageUplaodUrl, selectedFile);
  }

  public publishpost(newpost: Feeds) {
    return this.http.post<Dashboard>(this.publishpostUrl, newpost);
  }
  public likefeed(feed: Feeds) {
    return this.http.post<Feeds>(this.likefeedUrl, feed);
  }

  public saveFeedComment(feed: Feeds) {
     return this.http.post<Feeds>(this.saveFeedCommentUrl, feed);
  }
  public sharepost(feed: Feeds) {
     return this.http.post<Dashboard>(this.sharepostUrl, feed);
  }
  public loadNextPage(pageNo: number) {
    return this.http.post<Dashboard>(this.loadNextPageUrl, pageNo);
  }
  public getVerifiedStatus() {
    return this.http.get<SessionCandidate>(this.verifyUrl);
  }
   public postNewImage(newpost: Feeds) {
     return this.http.post<Dashboard>(this.postImageUrl , newpost);
  }
  public deleteFeed(feed: Feeds) {
    return this.http.post<Feeds>(this.deleteFeedUrl , feed);
  }
  public blockFeed(feed: Feeds) {
    return this.http.post<Feeds>(this.blockFeedUrl , feed);
  }
   public reportFeed(feed: Feeds) {
    return this.http.post<Feeds>(this.reportFeedUrl , feed);
  }
  public deleteFeedLike(feed: Feeds) {
    return this.http.post<Feeds>(this.deleteFeedLikeUrl , feed);
  }
  public candidateVerification(code: string) {
    return this.http.post<string>(this.verificationUrl , code);
  }
  public resendVerification() {
    return this.http.get<string>(this.resendverificationUrl );
  }
  public deleteFeedComment(comment: FeedsComments) {
    return this.http.post<string>(this.deleteFeedCommentUrl , comment );
  }
}
