import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';

@Injectable({
  providedIn: 'root'
})
export class LocationtypeService {

  private appUrl = ShipStreetProps.API_PATH + 'containerLocationType/findAllContainerLocationType';
  private lstUrl = ShipStreetProps.API_PATH + 'containerLocationType/findAllContainerLocationTypeByOrder';

  constructor(private http: HttpClient) { }

  public findAllContainerLocationType() {
    return this.http.get<ContainerLocationType[]>(this.appUrl);
  }

  public findAllContainerLocationTypeByOrder() {
    return this.http.get<ContainerLocationType[]>(this.lstUrl);
  }
}
