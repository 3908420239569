import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profiletimeline',
  templateUrl: './profiletimeline.component.html',
  styleUrls: ['./profiletimeline.component.css']
})
export class ProfiletimelineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
