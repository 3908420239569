import { CandidateCertificate } from '../../../model/candidateCertificate.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CandidatecertificateService {

 // private appUrl = ShipStreetProps.API_PATH + 'candidateCertificate.do';

  constructor(private http: HttpClient) { }

  // public candidateCertificates(candidate: string) {
  //   return this.http.post<CandidateCertificate[]>(this.appUrl , candidate);
  // }
}
