import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalculatorService } from './calculator.service';
import { Company } from '../model/company.model';
import { CommonService } from '../utils/common.service';
import { CookieService } from 'ngx-cookie-service';
import { CalcvesseloperatorService } from './calcvesseloperator/calcvesseloperator.service';
import { MasterVesselOperator } from '../model/mastervesseloperator.model';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit {
  pricipleCompanies: Company[];
  vesselOperators: MasterVesselOperator[];

  constructor(private getAllPrincipleCompany: CalculatorService, private router: Router,
    private cookieService: CookieService, private commonService: CommonService) { }

  ngOnInit() {
    this.getAllPrincipleCompany.getAllPrincipleCompany()
      .subscribe(data => {
        this.pricipleCompanies = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateCalcCompId(companyId) {
    this.cookieService.set('CST_CALC_CMPI', '' + companyId, null, null, null, null, null);
    this.router.navigate(['/calculator/costcalculator']);
  }

}
