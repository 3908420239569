import { Company } from "./company.model";
import { ContainerCompany } from "./containercompany.model";
import { ContainerCustomer } from "./containercustomer.model";
import { ContainerType } from "./containertype.model";
import { Taxes } from "./taxes.model";

export class ContainerPresales {

    id: number;
    company: Company;
    customer: ContainerCustomer;
    type: ContainerType;
    vatPaid: Taxes;
    quantity: number;
    unitPrice: number;
    total: number;
    vatAmount: number;
    grandTotal: number;
    bookingDate: Date;
    salesStatus: boolean;
}
