import { CandidateEducation } from '../../../model/candidateEducation.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CandidateeducationService {
  private appUrl = ShipStreetProps.API_PATH + 'candidateEducation.do';
  private noeduAlertUrl = ShipStreetProps.API_PATH + 'sendNoEducationAlert.do';

  constructor(private http: HttpClient) { }

  public candidateEducation(candidate: string) {
    return this.http.post<CandidateEducation[]>(this.appUrl , candidate);
  }

  public sendNoEducationAlert(candidateId: string) {
    return this.http.post<CandidateEducation[]>(this.noeduAlertUrl , candidateId);
  }
}
