import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchaseLocation } from 'src/app/model/containerpurchaselocation.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaselocationService {

  private appUrl = ShipStreetProps.API_PATH + 'containerpurchaselocation/findAllContainerPurchaseLocations';
  private lstUrl = ShipStreetProps.API_PATH + 'containerpurchaselocation/findAllContainerPurchaseLocationsByOrder';

  constructor(private http: HttpClient) { }

  public findAllContainerPurchaseLocations() {
    return this.http.get<ContainerPurchaseLocation[]>(this.appUrl);
  }

  public findAllContainerPurchaseLocationsByOrder() {
    return this.http.get<ContainerPurchaseLocation[]>(this.lstUrl);
  }

}
