import { TbagentequipmentsService } from './tbagentequipments.service';
import { Component, OnInit } from '@angular/core';
import { EquipmentcontrolService } from '../../equipmentcontrol/equipmentcontrol.service';
import { Router } from '@angular/router';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';

@Component({
  selector: 'app-tbagentequipments',
  templateUrl: './tbagentequipments.component.html',
  styleUrls: ['./tbagentequipments.component.css']
})
export class TbagentequipmentsComponent implements OnInit {

  searchText: string;
  loading: boolean;
  containerList: TrackBoxContainer[];
  p = 1;
  panelOpenState: boolean;
  containerListAll: TrackBoxContainer[];

  constructor(private equipmentcontrolService: EquipmentcontrolService, private router: Router,
    private tbagentequipmentsService: TbagentequipmentsService) { }

  ngOnInit() {
    this.loading = true;
    this.tbagentequipmentsService.getAllMyPrincipleContainers()
      .subscribe(data => {
        this.containerListAll = data;
        this.containerList = this.containerListAll;
        this.loading = false;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  searchbar() {
    if (this.searchText !== '') {
      this.containerList = this.containerListAll.filter(res => {
        return res.container.containerNumber.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    } else if (this.searchText === '') {
      this.containerList = this.containerListAll;
    } 
  }

  exportAsXLSX() {}

  generatePDF() {}

}
