import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerLease } from 'src/app/model/containerlease.model';

@Injectable({
  providedIn: 'root'
})
export class NewcontainerleaseService {

  private saveUrl = ShipStreetProps.API_PATH + 'containerlease/saveContainerLease';
  constructor(private http: HttpClient) { }

  public saveContainerLease(leasing: ContainerLease) {
    return this.http.post<string>(this.saveUrl , leasing);
  }
}
