import { Candidates } from '../../model/candidate.model';
import { Company } from '../../model/company.model';
import { ProfileService } from './profile.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CompaniesService } from 'src/app/company/companies/companies.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  sessioncandidate: Candidates;
  companies: Company[];


  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private profileService: ProfileService, private companiesService: CompaniesService, private router: Router) { }

  experience = [
    {
      'startDate': '28-06-2018', 'endDate': '29-03-2018', 'positionTitle': 'positionTitle',
      'company': { 'id': "1", 'companyName': 'companyName' }, 'country': { 'name': 'Dubai' }
    }
  ]
  certificate = [{ 'key': "1", 'value': 'companyName' }, { 'key': "1", 'value': 'companyName' }, { 'key': "1", 'value': 'companyName' }]
  degrees = ['High School/Secondary', 'Some College', 'Associates/2 yr Degree', 'Bachelors/4 yr Degree', 'Masters Degree',
    'PhD/MD/Doctorate', 'Tech/Vocational Cert.'];
  education = [{ 'fieldOfStudy': 'fieldOfStudy' }]
  skills = [{ 'skillCategoryExp': 'skillCategoryExp' }]
  certificates = [{ 'id': '1', 'certificate': { 'name': 'companyName' } }]
  friendsList = [{
    'connectedBy': {
      'id': "1", 'firstName': 'Anish', 'lastName': 'Krishnan',
      'company': { 'companyName': 'Techno' }
    }
  }]

  ngOnInit() {

    this.profileService.sessionCandidate()
      .subscribe(data => {
        this.sessioncandidate = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.companiesService.getAllCompanies()
      .subscribe(data => {
        this.companies = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
