import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[appHistoryback]'
})
export class HistorybackDirective {

  constructor(private location: Location) { }

  @HostListener('click')
    onClick() {
        this.location.back();
    }
}
