import { Candidates } from '../../../model/candidate.model';
import { CandidateService } from '../../../profile/candidate/candidate.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preferenceemailsettings',
  templateUrl: './preferenceemailsettings.component.html',
  styleUrls: ['./preferenceemailsettings.component.css']
})
export class PreferenceemailsettingsComponent implements OnInit {
  sessioncandidate: Candidates;
  emaildiv = false;

  constructor(private candidateService: CandidateService) { }

  ngOnInit() {
//     this.candidateService.sessionCandidate()
//      .subscribe( data => {
//        this.sessioncandidate = data;
//      });
  }

}
