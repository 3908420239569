import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchaseLocation } from 'src/app/model/containerpurchaselocation.model';
import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';

@Injectable({
  providedIn: 'root'
})
export class NewpurchaselocationService {

  private saveUrl = ShipStreetProps.API_PATH + 'containerpurchaselocation/saveContainerPurchaseLocation';
  private typeUrl = ShipStreetProps.API_PATH + 'containerLocationType/findAllContainerLocationTypeByOrder';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';
  
  constructor(private http: HttpClient) { }

  public saveContainerPurchaseLocation(location: ContainerPurchaseLocation) {
    return this.http.post<string>(this.saveUrl , location);
  }

  public getLocationType() {
    return this.http.get<ContainerLocationType[]>(this.typeUrl);
  }

  viewCountry() {
    return this.http.get<CountryInfo[]>(this.appUrls);
  }
}
