import { TbagentupdatebookingequipmentService } from './tbagentupdatebookingequipment.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { TrackboxContainerStatus } from 'src/app/trackmybox/trackboxcontainerstatus.model';
import { UpdateequipmentService } from 'src/app/trackmybox/equipmentcontrol/equipmentdetails/updateequipment/updateequipment.service';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
export interface DialogData {

  leaseUnitId: number;
  bookingId: number;
}
@Component({
  selector: 'app-tbagentupdatebookingequipment',
  templateUrl: './tbagentupdatebookingequipment.component.html',
  styleUrls: ['./tbagentupdatebookingequipment.component.css']
})
export class TbagentupdatebookingequipmentComponent implements OnInit {

  containerstatusList: TrackboxContainerStatus[];
  trackboxbookingcontainer: TrackBoxBookingContainers;
  constructor(private tbagentupdatebookingequipmentService: TbagentupdatebookingequipmentService, private router: Router, private route: ActivatedRoute,
    public dialogoutRef: MatDialogRef<TbagentupdatebookingequipmentComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar, private updateequipmentService: UpdateequipmentService) { }

  ngOnInit() {

    this.trackboxbookingcontainer = new TrackBoxBookingContainers();
    this.trackboxbookingcontainer.container = new TrackBoxContainer();
    this.trackboxbookingcontainer.bookingId = new TrackBoxBooking();
    this.trackboxbookingcontainer.container.containerStatus = new TrackboxContainerStatus ;
    // alert(JSON.stringify(this.data.bookingId));
    // alert(JSON.stringify(this.data.leaseUnitId));
    this.updateequipmentService.getContainerStatus()
      .subscribe(data => {
        this.containerstatusList = data;
        // alert(JSON.stringify(this.containerstatusList));
      },
        error => {
          this.router.navigate(['']);
        });


  }
  updateContainerStatus() {


    this.trackboxbookingcontainer.container.id = this.data.leaseUnitId;
    this.trackboxbookingcontainer.bookingId.id = this.data.bookingId;
    this.tbagentupdatebookingequipmentService.updatebookingequipment(this.trackboxbookingcontainer)
      .subscribe(data => {
        this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
          duration: 2000,

        });
        this.dialogoutRef.close(0);

      },

        error => {
          this.router.navigate(['']);
        });


  }


  onNoClick(): void {
    this.dialogoutRef.close();

  }

}
