import { JobCategory } from '../../model/jobCategory.model';
import { Jobs } from '../../model/jobs.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewjobdetailsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'jobObjectById.do';
  private categoryUrl = ShipStreetProps.API_PATH + 'jobscategorylist.do';
  private updatejobUrl = ShipStreetProps.API_PATH + 'updatejob.do';

  public fetchJobById(jobId: string) {
    return this.http.post<Jobs>(this.appUrl , jobId);
  }
  public jobscategorylist() {
    return this.http.get<JobCategory[]>(this.categoryUrl);
  }
  public updatejob(jobs: Jobs) {
    return this.http.post<string>(this.updatejobUrl , jobs);
  }
}
