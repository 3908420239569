import { Component, OnInit } from '@angular/core';
import { ProductservicesService } from './productservices.service';
import { Router } from '@angular/router';
import { AccountsProductServices } from 'src/app/model/accountsproductservices.model';

@Component({
  selector: 'app-productservices',
  templateUrl: './productservices.component.html',
  styleUrls: ['./productservices.component.css']
})
export class ProductservicesComponent implements OnInit {
  pnsList: AccountsProductServices[];
  searchText: string;

  constructor(private productservicesService: ProductservicesService, private router: Router) { }

  ngOnInit() {
      this.productservicesService.getAllProductNServices()
            .subscribe(data => {
                this.pnsList = data;
            },
                error => {
                    this.router.navigate(['']);
                });
  }

}
