import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainertradingprofilesService } from './containertradingprofiles.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Component({
  selector: 'app-containertradingprofiles',
  templateUrl: './containertradingprofiles.component.html',
  styleUrls: ['./containertradingprofiles.component.css']
})
export class ContainertradingprofilesComponent implements OnInit {
  sessionCandidate: SessionCandidate;
  mycompanies: CompanyEmployee[];
  skipicon: string;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private containertradingprofilesService: ContainertradingprofilesService, private router: Router,
              private cookieService: CookieService, private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    if (this.sessionCandidate.tradingCompany != null && this.sessionCandidate.tradingCompany !== 0) {
      this.router.navigate(['/containers']);
    } else {
      this.containertradingprofilesService.getAllMyContainerCompanies()
        .subscribe(data => {
          this.mycompanies = data;
        },
          error => {
            this.router.navigate(['']);
          });
    }
  }

  updateCookies(companyId , name) {
    this.cookieService.set('TRD_CMP_ID', companyId, null, null, null, null, null);
  //  this.cookieService.set('TMB_COMP_NM', name, null, null, null, null, null);
    this.router.navigate(['/containers']);
  }

}
