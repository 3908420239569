import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { SubscriptioncheckoutService } from './subscriptioncheckout.service';
import { Company } from 'src/app/model/company.model';
import { YourcompaniesService } from 'src/app/company/yourcompanies/yourcompanies.service';

@Component({
  selector: 'app-subscriptioncheckout',
  templateUrl: './subscriptioncheckout.component.html',
  styleUrls: ['./subscriptioncheckout.component.css']
})
export class SubscriptioncheckoutComponent implements OnInit {
  mycompanies: CompanyEmployee[];
  company: Company;

  constructor(private subscriptioncheckoutService: SubscriptioncheckoutService,
              private yourcompaniesService: YourcompaniesService, private router: Router) { }

  ngOnInit() {
    this.company = new Company();
    this.yourcompaniesService.getAllMyCompanies()
      .subscribe(data => {
        this.mycompanies = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  enrollnow() {
    this.company.id = 3;
    this.subscriptioncheckoutService.enrollcompany(this.company)
      .subscribe(data => {
        //     this.mycompanies = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
