import { Component, OnInit } from '@angular/core';
import { PrinciplerequestdetailsService } from './principlerequestdetails.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Component({
  selector: 'app-principlerequestdetails',
  templateUrl: './principlerequestdetails.component.html',
  styleUrls: ['./principlerequestdetails.component.css']
})
export class PrinciplerequestdetailsComponent implements OnInit {
  agentRequest: TrackBoxAgent;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private principlerequestdetailsService: PrinciplerequestdetailsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.principlerequestdetailsService.getMyPrincipleRequests(this.route.snapshot.params.id)
      .subscribe(data => {
        this.agentRequest = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

   confirmRequest(agentId) {
    this.principlerequestdetailsService.confirmRequest(agentId)
      .subscribe(data => {
        this.router.navigate(['/tbagentmode/boxprinciples']);
      },

        error => {
          this.router.navigate(['']);
        });
  }

}
