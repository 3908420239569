import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerType } from 'src/app/model/containertype.model';

@Injectable({
  providedIn: 'root'
})
export class ContainertypeService {

  private appUrl = ShipStreetProps.API_PATH + 'containertype/findAllContainerType';
  private lstUrl = ShipStreetProps.API_PATH + 'containertype/findAllContainerTypeByOrder';

  constructor(private http: HttpClient) { }

  public findAllContainerType() {
    return this.http.get<ContainerType[]>(this.appUrl);
  }

  public findAllContainerTypeByOrder() {
    return this.http.get<ContainerType[]>(this.lstUrl);
  }


}
