import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerpurchaseService } from './containerpurchase.service';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';

@Component({
  selector: 'app-containerpurchase',
  templateUrl: './containerpurchase.component.html',
  styleUrls: ['./containerpurchase.component.css']
})
export class ContainerpurchaseComponent implements OnInit {
  purchaseList: ContainerPurchase[];
  p = 1;
  searchText: string;
  purchaseListAll: ContainerPurchase[];

  constructor(private containerpurchaseService: ContainerpurchaseService, private router: Router) { }

  ngOnInit() {
    this.containerpurchaseService.containerPurchaseListByCompany()
      .subscribe(data => {
        this.purchaseListAll = data;
        this.purchaseList = this.purchaseListAll;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  search() {
    if (this.searchText !== '') {
      this.purchaseList = this.purchaseListAll.filter(res => {
        return res.supplier.name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()) ||
          res.containerType.eqType.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    } else if (this.searchText === '') {
      this.purchaseList = this.purchaseListAll;
    }
  }

}
