import { Component, OnInit } from '@angular/core';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { Validators, FormControl } from '@angular/forms';
import { RequireMatch } from 'src/app/trackmybox/admin/trackboxcustomers/newtrackboxcustomers/newtrackboxcustomers.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TbagentnewvesseloperatorService } from './tbagentnewvesseloperator.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-tbagentnewvesseloperator',
  templateUrl: './tbagentnewvesseloperator.component.html',
  styleUrls: ['./tbagentnewvesseloperator.component.css']
})
export class TbagentnewvesseloperatorComponent implements OnInit {

  masterVesselOperator: MasterVesselOperator;
  CountryList: CountryInfo[];
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
  constructor(private tbagentnewvesseloperatorService: TbagentnewvesseloperatorService, private router: Router) { }

  ngOnInit() {
    this.masterVesselOperator = new MasterVesselOperator();
    this.masterVesselOperator.country = new CountryInfo();
    // this.trackboxcarrier.country = new CountryInfo();



    this.tbagentnewvesseloperatorService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveCompanyVesselOperator() {
    this.tbagentnewvesseloperatorService.saveCompanyVesselOperator(this.masterVesselOperator)
      .subscribe(data => {
        this.router.navigate(['/tbagentmode/agentvesseloperators']);
        if (data == null) {
          alert('Already Exist');
        }

      },
        error => {
          this.router.navigate(['']);
        });
  }
  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }

  }

}
