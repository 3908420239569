import { Candidates } from './candidate.model';
import { TrackBoxCustomers } from './trackboxcustomers.model';

export class Pipeline {
    id: number;
    candidate: Candidates;
    customer: TrackBoxCustomers;
    opportunity: string;
    expectedRevenue: number;
    pipelineStatus: string;
    createdOn: Date;
}
