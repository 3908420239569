import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditaccountscategoryService {

  constructor(private http: HttpClient) { }

  private getUrl = ShipStreetProps.API_PATH + 'accountsproductcategory/getAccountsCategory.do';
  private updateUrl = ShipStreetProps.API_PATH + 'accountsproductcategory/updateProductCategory.do';

  public getAccountsCategory(categoryId: string) {
    return this.http.post<AccountsProductCategory>(this.getUrl , categoryId);
  }

  public updateProductCategory(category: AccountsProductCategory) {
    return this.http.post<AccountsProductCategory>(this.updateUrl , category);
  }
}
