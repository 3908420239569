import { Policy } from './policy.model';
import { Candidates } from './candidate.model';

export class PoliciesContent {
    id: number;
    privacy: Policy;
    lastUpdatedBy: Candidates;
    description: string;
    editedDescription: string;
    lastUpdatedOn: Date;
}
