import { Component, OnInit } from '@angular/core';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MasterPortVesselOperatorCosts } from 'src/app/model/masterportvesseloperatorcosts.model';
import { Router, ActivatedRoute } from '@angular/router';
import { VesseloperatorcosteditService } from './vesseloperatorcostedit.service';
import { VesseloperatorcostdetailsService } from '../vesseloperatorcostdetails/vesseloperatorcostdetails.service';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';
import { CalcvesseloperatorService } from '../calcvesseloperator/calcvesseloperator.service';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';
import { CalcequipmenttypesService } from '../mastersettings/calcequipmenttypes/calcequipmenttypes.service';
import { CalcEquipmentType } from 'src/app/model/CalcEquipmentType.model';

@Component({
  selector: 'app-vesseloperatorcostedit',
  templateUrl: './vesseloperatorcostedit.component.html',
  styleUrls: ['./vesseloperatorcostedit.component.css']
})
export class VesseloperatorcosteditComponent implements OnInit {

  masterPortListAll: MasterPortsDepot[];
  pol: MasterPortsDepot;
  myControl3 = new FormControl();
  filteredOptionsports: Observable<MasterPortsDepot[]>;
  vocostObj: MasterPortVesselOperatorCosts;
  vesselOperators: MasterVesselOperator[];
  containerTypeList: CalcEquipmentType[];

  constructor(private portService: PortsService, private calcvesseloperatorService: CalcvesseloperatorService, private router: Router,
              private vesseloperatorcosteditService: VesseloperatorcosteditService, private route: ActivatedRoute,
              private vesseloperatorcostdetailsService: VesseloperatorcostdetailsService , 
              private calcequipmenttypesService: CalcequipmenttypesService) { }

  ngOnInit() {
    this.vocostObj = new MasterPortVesselOperatorCosts();
    this.vocostObj.loadport = new MasterPortsDepot();
    this.vocostObj.finalport = new MasterPortsDepot();
    this.vocostObj.vesselOperator = new MasterVesselOperator()
    this.vocostObj.containerSize = new CalcEquipmentType();

    this.vesseloperatorcostdetailsService.vocostCalculatorDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.vocostObj = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortListAll = data;
      },
        error => {
          this.router.navigate(['']);
        });
    
     this.calcvesseloperatorService.findAllMasterVesselOperatorSortByName()
      .subscribe(data => {
        this.vesselOperators = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.calcequipmenttypesService.findAllCalculatorEquipmentType()
        .subscribe(data => {
          this.containerTypeList = data;
        },
          error => {
            this.router.navigate(['']);
          });

  }

  updateVesselSlotCost() { console.log(JSON.stringify(this.vocostObj.vesselOperator));
    this.vesseloperatorcosteditService.updateVesselSlotCost(this.vocostObj)
      .subscribe(data => {
      this.router.navigate(['/calculator/vesseloperatorcostslist']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  

}
