import { SessionCandidate } from './../../model/sessioncandidate.model';

import { Candidates } from '../../model/candidate.model';
import { CandidateService } from '../../profile/candidate/candidate.service';
import { PreferenceService } from './preference.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CommonService } from 'src/app/utils/common.service';
import { FormControl } from '@angular/forms';
import { CandidateEmailNotification } from 'src/app/model/candidateemailnotification.model';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {
  dataStr: string;
  pass2: string;
  pass1: string;
  newPassCodeReType: string;
  newPassCode: string;
  currentPassCode: string;
  viewConnection: boolean;
  connectionRequest: boolean;
  sessioncandidate: Candidates;
  candidate: Candidates;

  deactivate: boolean;
  deactivateDiv: boolean;
  passworddiv: boolean;
  phone: boolean;
  phonediv: boolean;
  commEmailPrefDiv: boolean;
  emaildiv: boolean;
  commEmailJobsDiv: boolean;
  commEmailFeedDiv: boolean;
  commEmailpostsDiv: boolean;
  candidateemail: CandidateEmailNotification;

  constructor(private preferenceService: PreferenceService, private router: Router, private commonService: CommonService) { }

  reasons = ['I have a duplicate account', 'I am getting too many emails', 'I am not getting any value from my account'
    , 'I have a privacy concern', 'I am receiving unwanted contact', 'Other'];

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  ngOnInit() {
    this.phone = true;
    this.passworddiv = true;
    this.deactivateDiv = false;
    this.deactivate = true;
    this.connectionRequest = true;
    this.viewConnection = true;
    this.emaildiv = true;
    this.candidateemail = new CandidateEmailNotification();


    this.preferenceService.sessionCandidate()
      .subscribe(data => {
        this.candidate = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.preferenceService.candidateEmailPreferences()
      .subscribe(data => {
        this.candidateemail = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }




  checkAndUpdatePassWord() {
    if (this.currentPassCode === '') {

    } else {
      alert('Enter the current Password.');
      this.pass1 = this.newPassCode;
      this.pass2 = this.newPassCodeReType;
      if (this.pass1 === this.pass2 && this.pass1 !== '') {
        this.preferenceService.updateCandidatePassWord(this.newPassCode)
          .subscribe(data => {
            this.dataStr = data;
            if (this.dataStr.includes('success')) {
              alert('Enter the current Password.4');
              alert('Password Changed Successfully.');
              this.passworddiv = false;
            } else {
              alert('Enter the current Password.5');
              alert('Error occoured while changing password. Please try again.');
            }
            this.currentPassCode = '';
            this.newPassCode = '';
            this.newPassCodeReType = '';
          },
            error => {
              this.router.navigate(['']);
            });
      }
    }
  }
  accountDeactivate() {
    this.preferenceService.accountDeactivate(this.candidate)
      .subscribe(data => {
        this.commonService.removeRSCookieValues();
        this.router.navigate(['/accountdeactivated', data.id]);
      });
  }

  savePhone(candidate) {
    this.preferenceService.savephone(this.candidate)
      .subscribe(data => {
        this.phone = true;
        // this.sessioncandidate = data;
      });
  }

  onChangeConnection() {
    this.preferenceService.updateConnectionEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }

  onChangeFollow() {
    this.preferenceService.updateFollowEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }

  onChangeFeeds() {
    this.preferenceService.updateFeedsEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }
  onChangeFeedShare() {
    this.preferenceService.updateFeedShareEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }

  onChangeFeedComments() {
    this.preferenceService.updateFeedCommentsEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }

  onChangeFeedLike() {
    this.preferenceService.updateFeedLikesEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }

  onChangeJobs() {
    this.preferenceService.updateJobsEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }

  onChangePosts() {
    this.preferenceService.updatePostsEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }

  onChangePostLike() {
    this.preferenceService.updatePostLikeEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }

  onChangePostComments() {
    this.preferenceService.updatePostCommentEmailSettings(this.candidateemail)
      .subscribe(data => {
      });
  }
}
