import { Candidates } from '../../../model/candidate.model';
import { CandidateMessageMessage } from '../../../model/candidateMessageMessage.model';
import { CandidateMessage } from '../../../model/candidateMessage.model';
import { CandidatemessagedetailsService } from './candidatemessagedetails.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Component({
  selector: 'app-candidatemessagedetails',
  templateUrl: './candidatemessagedetails.component.html',
  styleUrls: ['./candidatemessagedetails.component.css']
})
export class CandidatemessagedetailsComponent implements OnInit {
  candidateMessagesPrev: CandidateMessageMessage[];
  msgmessage: CandidateMessageMessage;
  candidateMessages: CandidateMessageMessage[];
  canmsg: CandidateMessage;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private candidatemessagedetailsService: CandidatemessagedetailsService, private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.msgmessage = new CandidateMessageMessage();
    this.msgmessage.messageId = new CandidateMessage();
    this.msgmessage.sender = new Candidates();
    this.msgmessage.messageId.id = +this.route.snapshot.paramMap.get('id');
    this.msgmessage.sender.id = +this.route.snapshot.paramMap.get('canId');
    this.candidatemessagedetailsService.candidateMessages(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.candidateMessagesPrev = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.candidatemessagedetailsService.updateReadStatus(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.candidateMessages = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  sendReplyMsg() {
    this.candidatemessagedetailsService.candidateMessageReply(this.msgmessage)
      .subscribe(data => {
        // this.candidateMessages.push(this.msgmessage);
        this.msgmessage.message = '';
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
