import { Candidates } from './candidate.model';
import { Feeds } from './feeds.model';
export class FeedsComments {
    id: number;
    feeds: Feeds;
    candidate: Candidates;
    comments: string;
    commentedOn: Date;
    postTime: string;
    commentByLogCand: boolean;
}
