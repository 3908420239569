import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeliveryOrderUpdateDTO } from 'src/app/model/deliveryorderupdateDTO.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class DeliveryorderdateupdateService {

constructor(private http: HttpClient) { }

private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/updateDeliveryOrderDate.do';
public updateDeliveryOrderDate(deliveryOrderDetails: DeliveryOrderUpdateDTO){
  //  alert(JSON.stringify(deliveryOrderDetails));
  return this.http.post<DeliveryOrderUpdateDTO>(this.appUrl, deliveryOrderDetails);
}

}
