import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchasedUnits } from 'src/app/model/containerpurchasedunits.model';
import { ContainerPurchaseDTO } from 'src/app/model/ContainerPurchaseDTO.model';

@Injectable({
  providedIn: 'root'
})
export class PurchasedcontainersService {

  private objUrl = ShipStreetProps.API_PATH + 'containerpurchasedunits/getAllPurchasedContainers';
  private delUrl = ShipStreetProps.API_PATH + 'containerpurchasedunits/removeContainerFromPurchase';

  constructor(private http: HttpClient) { }

  public getAllPurchasedContainers(purchaseId: string) {
    return this.http.post<ContainerPurchasedUnits[]>(this.objUrl , purchaseId);
  }

  public removeContainerFromPurchase(containerpurchasedto: ContainerPurchaseDTO) {
    return this.http.post<ContainerPurchasedUnits[]>(this.delUrl , containerpurchasedto);
  }
}
