import { Company } from './company.model';
import { MasterVessels } from './mastervessels.model';
import { Candidates } from './candidate.model';

export class CompanyVessels {
    id: number;
    company: Company;
    masterVessel: MasterVessels;
    candidate: Candidates;
    createdOn: Date;
}
