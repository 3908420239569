import { Company } from './company.model';
import { Designation } from './designation.model';
export class Candidates {
    id: number;
    firstName: string;
    lastName: string;
    company: Company;
    designation: Designation;
    address: string;
    location: string;
    city: string;
    state: string;
    country: string;
    phone: string;
    password: string;
    email: string;
    postbox: string;
    mobile: string;
    sex: string;
    dob: Date;
    nationality: string;
    maritalStatus: string;
    createdOn: Date;
    lastUpdateOn: Date;
    newsletter: string;
    willTravel: string;
    followCount: string;
    followingCount: string;
    connectionCount: string;
    about: string;
    careers: string;
    expectedCompensation: string;
    compensationFreequency: string;
    experienceCount: string;
    educationCount: string;
    skillCount: string;
    industryExperience: string;
    aliasName: string;
    deactivate: string;
    deactivateReason: string;
    deactivateFeedback: string;
    compensationCurrency: string;
    rsAdmin: string;
    verificationStatus: boolean;
    verificationCode: string;
    status: boolean;
    shareCount: string;
    followedByCandidate = false;
    connectionStatus: string;
    firstFeed = false;
    onlineStatus: number;
    lastLoginDate: number;
    yearsExperience: number;
    primarySkills: string;
    accountActivateCode: string;
    trackboxStatus: string;
    tradingStatus: boolean;
    candidateCostCalculator: boolean;
    errorMessage: string;
    ownProfile: boolean;
}
