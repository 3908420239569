import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyPortDepot } from 'src/app/model/companyportdepot.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentaddmanagedportlistService {

constructor(private http: HttpClient) { }


private appUrl = ShipStreetProps.API_PATH + 'masterports/masterportsCountrywiseview.do';

private appUrladdport = ShipStreetProps.API_PATH + 'masterports/addManagedPorts.do';
  public getAllMyNearByPorts() {
    return this.http.get<MasterPortsDepot[]>(this.appUrl);
  }


  public addPort(addport: CompanyPortDepot) {
    // alert(JSON.stringify(addport));
    return this.http.post<string>(this.appUrladdport, addport);
  }

}
