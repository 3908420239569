import { Component, OnInit } from '@angular/core';
import { JobplanService } from './jobplan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyJobSlot } from 'src/app/model/companyjobslot.model';
import { NewjobdetailsService } from '../newjobdetails/newjobdetails.service';
import { Jobs } from 'src/app/model/jobs.model';
import { Company } from 'src/app/model/company.model';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-jobplan',
  templateUrl: './jobplan.component.html',
  styleUrls: ['./jobplan.component.css']
})
export class JobplanComponent implements OnInit {

  basicPrice: any;
  premiumPrice: number;
  ultimatePrice: number;
  basicSlot: number;
  preSlot: number;
  ultiSlot: number;
  durtion: number;
  val: any;
  val1: any;
  val2: any;
  basDur: number;
  preDur: number;
  ultDur: number;

  slots = [
    { id: 0, value: '1', viewValue: '1 Job', bas: '1', pre: '1.99', ult: '3.99' },
    { id: 1, value: '2', viewValue: '2 Slots', bas: '2', pre: '2.99', ult: '4.99' },
    { id: 2, value: '3', viewValue: '3 Slots', bas: '3', pre: '3.99', ult: '5.99' },
    { id: 3, value: '4', viewValue: '4 Slots', bas: '4', pre: '4.99', ult: '6.99' },
    { id: 4, value: '5', viewValue: '5 Slots', bas: '5', pre: '5.99', ult: '7.99' },
    { id: 5, value: '10', viewValue: '10 Slots', bas: '10', pre: '10.99', ult: '12.99' },
  ];

  durations = [
    { value: '30', viewValue: '30 Day' , bas: '1', pre: '1.99', ult: '3.99' },
    { value: '90', viewValue: '3 Months' , bas: '1', pre: '1.99', ult: '3.99' },
    { value: '180', viewValue: '6 Months' , bas: '1', pre: '1.99', ult: '3.99' },
    { value: '270', viewValue: '9 Months' , bas: '1', pre: '1.99', ult: '3.99' },
    { value: '365', viewValue: '1 Year' , bas: '1', pre: '1.99', ult: '3.99' },
    { value: '730', viewValue: '2 Years' , bas: '1', pre: '1.99', ult: '3.99' },
    { value: '1095', viewValue: '3 Years' , bas: '1', pre: '1.99', ult: '3.99' }
  ];
  jobSlot: CompanyJobSlot;
  job: Jobs;

  constructor(private jobplanService: JobplanService, private router: Router, private route: ActivatedRoute,
    private newjobdetailsService: NewjobdetailsService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.basicPrice = 6;
    this.premiumPrice = 9;
    this.ultimatePrice = 15;
    this.durtion = 30;

    this.jobSlot = new CompanyJobSlot;
    this.jobSlot.company = new Company;
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.newjobdetailsService.fetchJobById(this.route.snapshot.paramMap.get('id'))
        .subscribe(data => {
          this.job = data;
        },
          error => {
            this.router.navigate(['']);
          });
    }
  }

  doSomething(typ, dura) {// alert(dura)
    if (typ === 'bas') {
      this.val = this.slots[this.jobSlot.basicSlot].bas;
      // alert(this.val)
      this.basicPrice = this.val * dura;
    } else if (typ === 'pre') {
      //  alert(this.slots[this.preSlot].pre);
      this.val1 = this.slots[this.jobSlot.premiumSlot].pre;
      this.premiumPrice = this.val1 * dura;
    } else {
      this.val2 = this.slots[this.jobSlot.ultimateSlot].ult;
      this.ultimatePrice = this.val2 * dura;
    }
    // alert(this.slots[this.ultiSlot].ult);
  }

  basicBuy() { alert( this.job.company);
    if (null != this.job.company) {
      this.jobSlot.amount = this.basicPrice;
      this.jobSlot.company.id = this.job.company.id;
      this.jobplanService.newBasicBuy(this.jobSlot)
        .subscribe(data => {
          this._snackBar.open('Job Slots Added Successfully', '', {
            duration: 2000,
          });
        },
          error => {
            this.router.navigate(['']);
          });
    } else {
      this._snackBar.open('Please create a company to post the jobs', '', {
        duration: 2000,
      });
    }
  }

    premiumBuy() {
      this.jobSlot.amount = this.premiumPrice;
      this.jobSlot.company.id = this.job.company.id;
      this.jobplanService.newPremiumBuy(this.jobSlot)
        .subscribe(data => {
          this._snackBar.open('Job Slots Added Successfully', '', {
            duration: 2000,
          });
        },
          error => {
            this.router.navigate(['']);
          });
    }

    ultimateBuy() {
      this.jobSlot.amount = this.basicPrice;
      this.jobSlot.company.id = this.job.company.id;
      this.jobplanService.newUltimateBuy(this.jobSlot)
        .subscribe(data => {
          this._snackBar.open('Job Slots Added Successfully', '', {
            duration: 2000,
          });
        },
          error => {
            this.router.navigate(['']);
          });
    }

    getPricingQuote() {
      this.jobplanService.getPricingQuote()
        .subscribe(data => {
          this.router.navigate(['/recruiter/pricingquotesuccess']);
        },
          error => {
            this.router.navigate(['']);
          });
    }


  }
