import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrackerEmployees } from '../model/trackeremployees.model';
import { ShipStreetProps } from '../utils/rs.constants';
import { TrackeremployeesService } from './trackeremployees/trackeremployees.service';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.css']
})
export class TrackerComponent implements OnInit {
  trackerEmployeesList: TrackerEmployees[];
  breakpoint: number;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  searchTxt: number;

  constructor(private trackeremployeesService: TrackeremployeesService, private router: Router) { }

  ngOnInit() {
    this.trackeremployeesService.getAllTrackerEmployeesList()
    .subscribe(data => {
      this.trackerEmployeesList = data;
    },
      error => {
        this.router.navigate(['']);
      });

      this.breakpointFun(event)
  }

  onResize(event) {
    this.breakpointFun(event)
  }

  breakpointFun(event?: any): void{
    if(event){
      this.breakpoint = (event.target.innerWidth <= 681) ? 1 : (event.target.innerWidth <= 1000) ? 3 : (event.target.innerWidth <= 1300) ? 3 : 4;
    } 
    else {
      this.breakpoint = (window.innerWidth <= 681) ? 1 : (window.innerWidth <= 1000) ? 3 : (window.innerWidth <= 1300) ? 3 : 4;
    }
  }

  onKeydown(event) {}

}
