import { Component, OnInit } from '@angular/core';
import { TrackeremployeesService } from './trackeremployees.service';
import { Router } from '@angular/router';
import { TrackerEmployees } from 'src/app/model/trackeremployees.model';

@Component({
  selector: 'app-trackeremployees',
  templateUrl: './trackeremployees.component.html',
  styleUrls: ['./trackeremployees.component.css']
})
export class TrackeremployeesComponent implements OnInit {

  p: number;
  trackerEmployeesList: TrackerEmployees[];
  constructor(private trackeremployeesService: TrackeremployeesService, private router: Router) { }

  ngOnInit() {
    this.trackeremployeesService.getAllTrackerEmployeesList()
      .subscribe(data => {
        this.trackerEmployeesList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
