import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billingaccounts',
  templateUrl: './billingaccounts.component.html',
  styleUrls: ['./billingaccounts.component.css']
})
export class BillingaccountsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
