import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/common.service';
import { EmployeeService } from './employee.service';
import { Router } from '@angular/router';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  sessionCandidate: SessionCandidate;
  employees: CompanyEmployee[];
  p: number;

  constructor(private employeesService: EmployeeService, private router: Router, private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.employeesService.getAllEmployee()
      .subscribe(data => {
        this.employees = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
