import { Candidates } from './candidate.model';
import { Company } from './company.model';
export class CompanyEmployee {
  id: number;
  candidate: Candidates ;
  company: Company;
  startDate: Date;
  endDate: Date;
  present: boolean;
  status: boolean;
  userType: number;
  companyOwner: boolean;
  trackBoxStatus: boolean;
  costCalulator: boolean;
  containerTrading: boolean;
  defaultCompany: boolean;
}

