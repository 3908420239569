import { NewchargeheadsService } from './newchargeheads.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackboxChargeHeads } from 'src/app/model/trackboxchargeheads.model';
import { PortsService } from '../../ports/ports.service';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-newchargeheads',
  templateUrl: './newchargeheads.component.html',
  styleUrls: ['./newchargeheads.component.css']
})
export class NewchargeheadsComponent implements OnInit {


  chargehead: TrackboxChargeHeads;
  masterPortList: MasterPortsDepot[];
  filteredOptionsport: Observable<MasterPortsDepot[]>;
  myControl3 = new FormControl();
  constructor(private newchargeheadsService: NewchargeheadsService, private router: Router, private route: ActivatedRoute, private portService: PortsService) { }

  ngOnInit() {

    this.chargehead = new TrackboxChargeHeads();
    this.chargehead.port = new MasterPortsDepot();
    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortList = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.filteredOptionsport = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter2(val))
        );
  }


  public _filter2(value: string): MasterPortsDepot[] {
    const filterValue2 = value.toLowerCase();
    // console.log('=====>>' + filterValue2);

    return this.masterPortList.filter(port => port.name.toLowerCase().indexOf(filterValue2) === 0 ||
      port.name.toUpperCase().indexOf(filterValue2) === 0 ||
      port.portCode.toLowerCase().indexOf(filterValue2) === 0);

  }

  public displayPort(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
     return cntr.name;
    // } else {
    //   if (!this.myboxsearch) { this.myboxsearch = new MyBoxSearchDTO(); }
    //   this.myboxsearch.port = 0;
    //   return '';
    }
  }

  onSubmit() {
    this.newchargeheadsService.chargeHeadsave(this.chargehead)
      .subscribe(data => {
        this.router.navigate(['/trackmybox/chargeheads']);
      },
        error => {
          this.router.navigate(['']);
        });
  }
}




