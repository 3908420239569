import { Candidates } from './candidate.model';
import { Company } from './company.model';
import { Marketplace } from './marketplace.model';
export class MarketplaceQuoteRequest {
    id: number;
    marketplace: Marketplace;
    candidate: Candidates;
    companyId: Company;
    name: string;
    email: string;
    phone: string;
    notes: string;
    quantityRequested: number;
    requestDate: Date;
    vatAmount: string;
    totalAmount: string;
}
