import { Candidates } from '../../model/candidate.model';
import { ConnectionList } from '../../model/connectionList.model';
import { PeopleyoumayknowService } from './peopleyoumayknow.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CandidateConnect } from 'src/app/model/candidateConnect.model';

@Component({
  selector: 'app-peopleyoumayknow',
  templateUrl: './peopleyoumayknow.component.html',
  styleUrls: ['./peopleyoumayknow.component.css']
})
export class PeopleyoumayknowComponent implements OnInit {
  jsonList: any;
  candidates: ConnectionList[];
  friendsRequestList: CandidateConnect[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  skipicon: number;
  showExpIcon: boolean;
  showme: boolean;
  requesticon: boolean;
  customOptions: { loop: boolean; mouseDrag: boolean; touchDrag: boolean; pullDrag: boolean; dots: boolean; navSpeed: number; navText: string[]; autoplay: boolean; autoplayHoverPause: boolean; responsive: { 320: { items: number; }; 370: { items: number; }; 420: { items: number; }; 590: { items: number; }; }; nav: boolean; };

  constructor(private peopleyoumayknowService: PeopleyoumayknowService, private router: Router) { }

  ngOnInit() {
    // this.peopleyoumayknowService.getAllPeopleYouMayKnow()
    //   .subscribe(data => {
    //     this.candidates = data;
    //     if (this.candidates.length < 50) {
    //       this.peopleyoumayknowService.populatePeopleYouMayKnow()
    //         .subscribe(data1 => {
    //           this.candidates = data1;
    //         },
    //           error => {
    //             this.router.navigate(['']);
    //           });
    //     }
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    this.peopleyoumayknowService.requestedConnections()
      .subscribe(data => { // console.log(JSON.stringify(data));
        this.friendsRequestList = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.customOptions = {
          loop: false,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
          dots: false,
          navSpeed: 700,
          navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
          autoplay: false,
          autoplayHoverPause: false,
          responsive: {
            
            320: {
              items: 2
            },
            370: {
              items: 2
            },
            420: {
              items: 3
            },
            590: {
              items: 3
            },
      
          },
          nav: true
        };
  }
  candidateconnect(can) {
    this.peopleyoumayknowService.candidateconnect(can)
      .subscribe(data => {
        this.jsonList = data;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === can.id) {
            this.candidates[i].requestStatus = true;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }
  withdrawRequest(can) {
    this.peopleyoumayknowService.withdrawRequest(can)
      .subscribe(data => {
        this.jsonList = this.candidates;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === can.id) {
            this.candidates[i].requestStatus = false;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  removeFromConnectionList(can) {
    this.peopleyoumayknowService.removeFromConnectionList(can)
      .subscribe(data => {
        this.jsonList = this.candidates;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === can.id) {
            this.candidates.splice(i, 1);
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  acceptConnection(friend) { 
    this.peopleyoumayknowService.acceptConnection(friend)
      .subscribe(data => {
        this.jsonList = this.friendsRequestList;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.candidate.id === friend) {
            this.friendsRequestList.splice(i, 1);
            break;
          }
        }
        this.router.navigate(['m/editprofile']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  rejectConnection(friend) {
    this.peopleyoumayknowService.rejectConnection(friend)
      .subscribe(data => {
        this.jsonList = this.candidates;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === friend.id) {
            this.candidates.splice(i, 1);
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  populatePeopleYouMayKnow() {
    this.peopleyoumayknowService.populatePeopleYouMayKnow()
      .subscribe(data => {
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
