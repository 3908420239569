import { Component, OnInit } from '@angular/core';
import { EditvesseloperatorService } from './editvesseloperator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NewcompanyvesseloperatorService } from '../newcompanyvesseloperator/newcompanyvesseloperator.service';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';

@Component({
  selector: 'app-editvesseloperator',
  templateUrl: './editvesseloperator.component.html',
  styleUrls: ['./editvesseloperator.component.css']
})
export class EditvesseloperatorComponent implements OnInit {
  countryList: CountryInfo[];
  vsoperator: MasterVesselOperator;

  constructor(private editvesseloperatorService: EditvesseloperatorService, private router: Router,
              private route: ActivatedRoute, private newcompanyvesseloperatorService: NewcompanyvesseloperatorService) { }

  ngOnInit() {
    this.editvesseloperatorService.vesseloperatorbyid(this.route.snapshot.params.id)
      .subscribe(data => {
        this.vsoperator = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newcompanyvesseloperatorService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateVesselOperator() {
    this.editvesseloperatorService.updateVesselOperator(this.vsoperator)
      .subscribe(data => {
        this.router.navigate(['/trackmybox/tmbcompanyvesseloperator']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
