import { Component, OnInit } from '@angular/core';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-signupprivacy',
  templateUrl: './signupprivacy.component.html',
  styleUrls: ['./signupprivacy.component.css']
})
export class SignupprivacyComponent implements OnInit {
  isChecked: boolean = false;
  isPrivacyChecked: boolean = false;
  sessionCandidate: SessionCandidate;
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
  }

}
