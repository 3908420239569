import { ActivatedRoute, Router } from '@angular/router';
import { TrackboxsupplierService } from './trackboxsupplier.service';
import { Component, OnInit } from '@angular/core';
import { TrackBoxContainerSupplier } from 'src/app/model/trackboxcontainersupplier.model';

@Component({
  selector: 'app-trackboxsupplier',
  templateUrl: './trackboxsupplier.component.html',
  styleUrls: ['./trackboxsupplier.component.css']
})
export class TrackboxsupplierComponent implements OnInit {

  trackboxsupplierList: TrackBoxContainerSupplier[];
  p: number;
  panelOpenState: boolean;
  constructor(private trackboxsupplierService: TrackboxsupplierService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.trackboxsupplierService.getAllMySupplier()
      .subscribe(data => {
        this.trackboxsupplierList = data;
        // alert(JSON.stringify(this.trackboxsupplierList));
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
