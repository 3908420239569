import { Component, OnInit } from '@angular/core';
import { TrackerDepartment } from 'src/app/model/trackerdepartment.model';
import { Router } from '@angular/router';
import { NewtrackerdepartmentService } from './newtrackerdepartment.service';

@Component({
  selector: 'app-newtrackerdepartment',
  templateUrl: './newtrackerdepartment.component.html',
  styleUrls: ['./newtrackerdepartment.component.css']
})
export class NewtrackerdepartmentComponent implements OnInit {

  trackerdepartment: TrackerDepartment;

  constructor(private newtrackerdepartmentService: NewtrackerdepartmentService, private router: Router) { }

  ngOnInit() {
    this.trackerdepartment = new TrackerDepartment();
  }

  saveTrackerDepartment() {
    this.newtrackerdepartmentService.saveTrackerDepartment(this.trackerdepartment)
      .subscribe(data => {
        this.router.navigate(['tracker/trackerdepartments']);
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
