import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsPaymentMode } from 'src/app/model/accountspaymentmode.model';
import { AccountspaymodeService } from './accountspaymode.service';

@Component({
  selector: 'app-accountspaymode',
  templateUrl: './accountspaymode.component.html',
  styleUrls: ['./accountspaymode.component.css']
})
export class AccountspaymodeComponent implements OnInit {

  paymentModeList: AccountsPaymentMode[];

  constructor(private accountspaymodeService: AccountspaymodeService, private router: Router) { }

  ngOnInit() {
    this.accountspaymodeService.getaccountspaymentmodelist()
      .subscribe(data => {
        this.paymentModeList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  

}
