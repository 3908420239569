import { Company } from './company.model';
import { AgentPrincipleFreight } from './agentprinciplefreight.model';

export class AgentPrincipleFreightDetails {
    id: number;
    agentId: Company;
    principleId: Company;
    agentPrincipleFreight: AgentPrincipleFreight;
    requestFreight: string;
    requestedOn: Date;
    responseFreight: string;
    status: string;
    requestedBy: string;
}
