import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
//import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Observable } from 'rxjs';
import {HttpClientModule, HttpClient, HttpRequest, HttpResponse, HttpEventType} from '@angular/common/http';
import { SavesheetService } from './savesheet.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-savesheet',
  templateUrl: './savesheet.component.html',
  styleUrls: ['./savesheet.component.scss']
})
export class SavesheetComponent implements OnInit {
  selectedFile:File;
  //fileData = null;
  constructor(private savesheetService: SavesheetService, private router:Router, private route:ActivatedRoute) { }
   ngOnInit() {
    
   }
  // fileProgress(fileInput: any) {
  //   this.fileData = <File>fileInput.target.files[0];
  // }
 
  // onSubmit() {
  //   const formData = new FormData();
  //   formData.append('file', this.fileData);
  //   this.savesheetService.postFile( formData)
  //     .subscribe(res => {
  //       console.log(res);
  //       alert('SUCCESS !!');
  //     })
  // }
  
  
  onFileChanged(event) {
    if (event.target) {
      const reader = new FileReader();
      const target1: any =  event.target;
      this.selectedFile = target1.files[0];
      reader.readAsDataURL(target1.files[0]);
      const fileReader: FileReader = new FileReader();
    }
    
  }
    uploadEquip(){
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.savesheetService.postFile(uploadData)
    .subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }
  
    }
  

