import { Company } from './../../../../model/company.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { TbagentAddBookingContainersDTO } from 'src/app/model/tbagentaddbookingcontainersDTO.model';

@Injectable({
  providedIn: 'root'
})
export class TbagentaddcontainerstobookingService {


constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/getAllTbAgentPrinciples.do';
private appUrlcontaineradd = ShipStreetProps.API_PATH + 'TrackboxBookings/tbAgentaddcontainertobooking.do';
private updateUrl = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/tbAgentbookingcontainersave.do';
public getPrincipleCompanies() {
  return this.http.get<TrackBoxAgent[]>(this.appUrl);

}
public getPrincipleContainers(principleId: number) {
  // alert(JSON.stringify(principleId));
  return this.http.post<TrackBoxContainer[]>(this.appUrlcontaineradd, principleId);
}

public updateBookingEquipment(containerBooking: TbagentAddBookingContainersDTO) {
  // alert(JSON.stringify(containerBooking.bookingId));
  return this.http.post<string>(this.updateUrl, containerBooking);
}
}
