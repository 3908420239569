import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile23',
  templateUrl: './profile23.component.html',
  styleUrls: ['./profile23.component.css']
})
export class Profile23Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
