import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerPurchase } from '../model/containerpurchase.model';
import { ContainerDashboardCountDto } from '../model/containerdashboardcountDTO.model';
import { BoxCountByContainerTypeDTO } from '../model/boxcountbycontainertypeDto';

@Injectable({
  providedIn: 'root'
})
export class ContainersService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'containerdashboard/getAllDashboardCounts.do';
  private listUrl = ShipStreetProps.API_PATH + 'containerpurchase/containerPurchaseList';
  private appUrltypecount = ShipStreetProps.API_PATH + 'containerdashboard/getBoxCountByContainerType.do';


  public containerPurchaseList() {
    return this.http.get<ContainerPurchase[]>(this.listUrl);
  }

  getAllDashboardCounts() {
    return this.http.get<ContainerDashboardCountDto>(this.appUrl);
  }

  getBoxCountByContainerType(containerStatus: string) {
    return this.http.post<BoxCountByContainerTypeDTO[]>(this.appUrltypecount, containerStatus);
  }
}
