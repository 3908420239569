import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerDashboardCountDto } from 'src/app/model/containerdashboardcountDTO.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsdashboardService {

  private appUrl = ShipStreetProps.API_PATH + 'containerdashboard/getAllAccountsDashboardCounts.do';

  constructor(private http: HttpClient) { }

  getAllAccountsDashboardCounts() {
    return this.http.get<ContainerDashboardCountDto>(this.appUrl);
  }
}
