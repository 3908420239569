import { OffhireequipmentComponent } from './../offhireequipment/offhireequipment.component';
import { TrackBoxContainer } from './../../../model/trackboxcontainer.model';
import { Router, ActivatedRoute } from '@angular/router';
import { EquipmentdetailsService } from './equipmentdetails.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UpdateequipmentComponent } from './updateequipment/updateequipment.component';
import { SaleequipmentComponent } from '../saleequipment/saleequipment.component';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';


@Component({
  selector: 'app-equipmentdetails',
  templateUrl: './equipmentdetails.component.html',
  styleUrls: ['./equipmentdetails.component.css']
})
export class EquipmentdetailsComponent implements OnInit {
  tbcontainer: TrackBoxContainer;
  bookingcontainers: TrackBoxBookingContainers[];
  p = 1;

  constructor(private equipmentdetailsService: EquipmentdetailsService, private router: Router,
              private route: ActivatedRoute, public dialog: MatDialog) { }

  updateContainerStatus(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(UpdateequipmentComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.equipmentdetailsService.getDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.tbcontainer = data;
      },
        error => {
          this.router.navigate(['']);
        });
      console.log('The dialog was closed');
    });
  }
  offhireequipment(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(OffhireequipmentComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.equipmentdetailsService.getDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.tbcontainer = data;
      },
        error => {
          this.router.navigate(['']);
        });
      console.log('The dialog was closed');
    });
  }

  saleequipment(leaseUnitId): void {
    const dialogoutRef = this.dialog.open(SaleequipmentComponent, {
      width: '550px',
      data: { leaseUnitId }
    });

    dialogoutRef.afterClosed().subscribe(result => {
      this.equipmentdetailsService.getDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.tbcontainer = data;
      },
        error => {
          this.router.navigate(['']);
        });
      console.log('The dialog was closed');
    });

  }
  ngOnInit() {
    this.equipmentdetailsService.getDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.tbcontainer = data;
        // alert(JSON.stringify(this.tbcontainer));
      },
        error => {
          this.router.navigate(['']);
        });
      }

  containerhistory(containerId) {
      this.equipmentdetailsService.containerhistory(containerId)
      .subscribe(data => {
        this.bookingcontainers = data;
        // alert(JSON.stringify(this.bookingcontainers));
      },
        error => {
          this.router.navigate(['']);
        });
  }

}

