import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { Estimate } from 'src/app/model/estimate.model';
// import { DepotProps } from 'src/app/utils/rs.constants';
import { ConsigneegateinpaymentapprovalsService } from './consigneegateinpaymentapprovals.service';

@Component({
  selector: 'app-consigneegateinpaymentapprovals',
  templateUrl: './consigneegateinpaymentapprovals.component.html',
  styleUrls: ['./consigneegateinpaymentapprovals.component.css']
})
export class ConsigneegateinpaymentapprovalsComponent implements OnInit {

  months: { id: number; name: string; }[];
    // imagePath = DepotProps.AWS_IMAGE_PATH;
    // consigneePaymenyList: Estimate[];

    constructor(private router: Router, 
      private consigneegateinpaymentapprovalsService: ConsigneegateinpaymentapprovalsService) { }

    ngOnInit() {
        // this.consigneegateinpaymentapprovalsService.getConsigneePaymentApprovalsList()
        //     .subscribe(data => {
        //         this.consigneePaymenyList = data;
        //     },
        //         error => {
        //             this.router.navigate(['']);
        //         });
    }

    repairBillApprove(custBillId) {
        // this.consigneegateinpaymentapprovalsService.repairBillApprove(custBillId)
        //     .subscribe(data => {
        //         // this.repairChargeList = data.billingDto;
        //         // this.netTotal = data.netTotal;
        //         // this.vatTotal = data.vatTotal;
        //         // this.grandTotal = data.grandTotal;
        //     },
        //         error => {
        //             this.router.navigate(['']);
        //         });
    }

    viewRepairBillApprove() {
        // this.monthlyrepairreportService.getMonthlyRepairExcel(this.equipment)
        //     .subscribe(data => {
        //          window.location.href = this.imagePath + 'CustomerStorageReport/Monthly-Repair-Charge.xlsx';
        //     },
        //         error => {
        //             this.router.navigate(['']);
        //         });
    }

}
