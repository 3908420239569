import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { SearchByCountryDTO } from 'src/app/model/searchbycountryDTO.model';
import { LoginpageService } from '../../login/loginpage/loginpage.service';
import { ContainerbycountrydetailsService } from './containerbycountrydetails.service';

@Component({
  selector: 'app-containerbycountrydetails',
  templateUrl: './containerbycountrydetails.component.html',
  styleUrls: ['./containerbycountrydetails.component.css']
})
export class ContainerbycountrydetailsComponent implements OnInit {

  containerdetails: ContainerPurchase;
  countryList: CountryInfo[];
  countrysearch: SearchByCountryDTO;

  constructor(private containerbycountrydetailsService: ContainerbycountrydetailsService, 
    private loginpageService: LoginpageService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.countrysearch = new SearchByCountryDTO();
    this.countrysearch.country = new CountryInfo();

    this.loginpageService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.containerbycountrydetailsService.getContainerDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.containerdetails = data;
        // alert(JSON.stringify(this.containerList));
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onKeydown(event) {

    if (event.key === 'Enter') {
      this.containerbycountrydetailsService.getContainerDetails(String(this.countrysearch.country.id))
      .subscribe(data => {
        this.containerdetails = data;
      },
        error => {
          this.router.navigate(['']);
        });
    }
  }

  completecontainerPurchase() {
      this.containerbycountrydetailsService.completecontainerPurchase(this.containerdetails)
      .subscribe(data => {
        this.containerdetails = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
