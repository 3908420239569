import { Router, ActivatedRoute } from '@angular/router';
import { EquipmentcontrolService } from './equipmentcontrol.service';
import { Component, OnInit } from '@angular/core';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import * as XLSX from 'xlsx';
import { MyBoxSearchExport } from 'src/app/model/mybox.export.model';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-equipmentcontrol',
  templateUrl: './equipmentcontrol.component.html',
  styleUrls: ['./equipmentcontrol.component.css']
})
export class EquipmentcontrolComponent implements OnInit {
  containerList: TrackBoxContainer[];
  panelOpenState: boolean;
  p = 1;
  itemsPerPage: 1;
  printList: MyBoxSearchExport[];
  myBox: MyBoxSearchExport;
  loading = true;
  fileName = 'ExcelSheet.xlsx';
  searchText: string;
  // exportexcel(): void {
  //   /* table id is passed over here */
  //   const element = document.getElementById('customers');
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   /* save to file */
  //   XLSX.writeFile(wb, this.fileName);
  // }



  pdfContent: string;
  tableContent = [];
  rowContent = [];
  apiCOunt: number;
  containerListAll: TrackBoxContainer[];
  generatePDF() {


    this.tableContent.push([{ text: 'Container Number', bold: true, color: 'red' },
    { text: 'ISO Code', bold: true }, { text: 'Country ', bold: true, color: 'red' },
    { text: 'Purchase status', bold: true, color: 'red' }, { text: 'current location', bold: true, color: 'red' },
    { text: 'container status', bold: true, color: 'red' }]);

    this.pdfContent = '<table ><tr>td>Container No</td><td>Code</td></tr>';

    this.printList = new Array();
    this.myBox = new MyBoxSearchExport();
    this.myBox.containerNumber = 'Container Number';
    this.myBox.containerStatus = 'Container status';

    this.myBox.country = 'Country';
    this.myBox.port = 'Current location';

    this.myBox.typeCode = 'ISO Code';
    this.printList.push(this.myBox);



    // this.printList.push({p.container.containerNumber, p.container.equipmentType.typeCode})
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.containerList.length; i = i + 1) {

      // this.rowContent.push([this.searchResultList[i].container.containerNumber,
      // this.searchResultList[i].container.equipmentType.typeCode]);
      // this.pdfContent = this.pdfContent+this.searchResultList[i].container.containerNumber;
      // this.pdfContent = this.pdfContent+this.searchResultList[i].containerStatus.name;
      // this.pdfContent = this.pdfContent+this.searchResultList[i].container.equipmentType.typeCode;
      // this.pdfContent= this.pdfContent+"\n";
      this.myBox = new MyBoxSearchExport();
      this.myBox.containerNumber = this.containerList[i].container.containerNumber;
      this.myBox.typeCode = this.containerList[i].container.equipmentType.typeCode;
      this.myBox.country = this.containerList[i].country.name;
      // this.myBox.carrier = this.containerList[i].carrier.name;
      this.myBox.port = this.containerList[i].port.name;

      // this.myBox.gateIn = this.tbcontainerList[i].gateIn;
      // this.myBox.yom =this.tbcontainerList[i].container.manufacturingDate;
      this.myBox.containerStatus = this.containerList[i].containerStatus.name;
      this.printList.push(this.myBox);
      //  alert(this.printList);
      // content: 'Sample PDF generated with Angular and PDFMake for C#Corner Blog'
    }
    this.tableContent.push(this.rowContent);
    const docDefinition1 = {

      content: '' + this.pdfContent

      //   content: this.searchResultList
    };

    console.log(this.printList);

    const docDefinition = {
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        }
      },
      content: [
        {
          text: 'Equipment ',
          style: 'sectionHeader'
        },




        {
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
            body: // this.tableContent
              this.printList.map(p => ([p.containerNumber, p.typeCode, p.port, p.containerStatus, p.country]))
          }
        }
      ]



    };


    pdfMake.createPdf(docDefinition).open();

    // let docDefinition = {

    // content:  this.searchResultList

    //   pdfMake.createPdf(docDefinition).open();

  }


  exportAsXLSX(): void {
    // this.exportAsExcelFile(this.tbcontainerList, 'sample');
    // printList: [];

    this.printList = new Array();

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.containerList.length; i = i + 1) {

      this.myBox = new MyBoxSearchExport();
      this.myBox.containerNumber = this.containerList[i].container.containerNumber;
      this.myBox.typeCode = this.containerList[i].container.equipmentType.typeCode;
      // this.myBox.gateIn = this.tbcontainerList[i].gateIn;
      // this.myBox.yom =this.tbcontainerList[i].container.manufacturingDate;
      this.myBox.containerStatus = this.containerList[i].containerStatus.name;
      this.myBox.country = this.containerList[i].country.name;
      this.myBox.port = this.containerList[i].port.name;
      this.printList.push(this.myBox);


      // this.printList.push({ this.tbcontainerList[i].gateIn, this.tbcontainerList[i].gateOut})
    }
    this.exportAsExcelFile(this.printList, 'sample');

  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION);
  }
  constructor(private equipmentcontrolService: EquipmentcontrolService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.apiCOunt =0;
    this.apiCOunt = this.apiCOunt +1;
    this.equipmentcontrolService.getAllMyContainers()
      .subscribe(data => {
        this.containerListAll = data;
        this.containerList = this.containerListAll;
        this.apiCOunt = this.apiCOunt -1;
      },
        error => {
          this.apiCOunt = this.apiCOunt -1;
          this.router.navigate(['']);
        });
  }


  containerNumberSearch() {
    // this.equipmentcontrolService.save(this.trackboxcontainerDto)
    // .subscribe(data => {
    // this.router.navigate(['trackmybox/equipmentcontroller']);
    //  },
    //    error => {
    //      this.router.navigate(['']);
    //    });
  }

  search() {
    if (this.searchText !== '') {
      this.containerList = this.containerListAll.filter(res => {
        return res.container.containerNumber.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    } else if (this.searchText === '') {
      this.containerList = this.containerListAll;
    } 
  }


}