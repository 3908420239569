import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MasterVoyageRoute } from 'src/app/model/mastervoyageroute.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class VoyagerouteService {

  constructor(private http: HttpClient) { }


  private appUrl = ShipStreetProps.API_PATH + 'voyageroute/mastervoyageroutesave.do';

  public save(mastervoyageroute: MasterVoyageRoute) {
    return this.http.post<MasterVoyageRoute>(this.appUrl, mastervoyageroute);
}
}
