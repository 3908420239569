import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerSalesUnits } from 'src/app/model/containersalesunits.model';
import { ContainerSoldUnits } from 'src/app/model/containersoldunit.model';

@Injectable({
  providedIn: 'root'
})
export class ViewsoldsalesunitsService {

  private objUrl = ShipStreetProps.API_PATH + 'containersales/findSoldSalesUnits';
  private appUrl = ShipStreetProps.API_PATH + 'containersales/updatesalesunit';
  private delUrl = ShipStreetProps.API_PATH + 'containersales/DeleteSalesUnit';

  constructor(private http: HttpClient) { }

  public findSoldSalesUnits(salesId: string) {
    return this.http.post<ContainerSalesUnits[]>(this.objUrl, salesId);
  }

  public DeleteSalesUnit(soldunit: ContainerSoldUnits) {
    return this.http.post<ContainerSalesUnits[]>(this.delUrl, soldunit);
  }
}
