import { AgentPrincipleFreight } from '../../../../model/agentprinciplefreight.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { AgentPrincipleFreightDetails } from 'src/app/model/agentprinciplefreightdetails.model';
import { AgentPrincipleFreightDTO } from 'src/app/model/agentprinciplefreightDTO.model';

@Injectable({
  providedIn: 'root'
})
export class TbagentrequesttoprincipleService {

  constructor(private http: HttpClient) { }

  private saveUrl = ShipStreetProps.API_PATH + 'trackboxagent/saveTrackBoxAgentRequestToPrinciple.do';
  private appDetUrl = ShipStreetProps.API_PATH + 'trackboxagent/getAllFreightDetailsById.do';
  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/getAllFreightByBookingId.do';

  public getAllFreightDetailsById(freightId: number) {
    return this.http.post<AgentPrincipleFreightDetails[]>(this.appDetUrl, freightId);
  }

  public getAllFreightByBookingId(paramValue: AgentPrincipleFreightDTO) {
    return this.http.post<AgentPrincipleFreightDetails[]>(this.appUrl, paramValue);
  }

  public requestToPrinciple(tbRequest: AgentPrincipleFreight) {
    return this.http.post<AgentPrincipleFreight>(this.saveUrl, tbRequest);
  }
}
