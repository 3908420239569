import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountsInvoice } from 'src/app/model/accountsnvoice.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'accountssalesinvoice/getSalesInvoicesList';

  public getSalesInvoicesList() {
    return this.http.get<AccountsInvoice[]>(this.appUrl);
  }
}
