import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrackerdepartmentService } from './trackerdepartment.service';
import { TrackerDepartment } from 'src/app/model/trackerdepartment.model';

@Component({
  selector: 'app-trackerdepartment',
  templateUrl: './trackerdepartment.component.html',
  styleUrls: ['./trackerdepartment.component.css']
})
export class TrackerdepartmentComponent implements OnInit {

   p: number;
  trackerDepartmentList: TrackerDepartment[];
  constructor(private trackerdepartmentService: TrackerdepartmentService, private router: Router) { }

  ngOnInit() {
    this.trackerdepartmentService.getAllTrackerDepartmentList()
      .subscribe(data => {
        this.trackerDepartmentList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
