import { Component, OnInit } from '@angular/core';
import { Pipeline } from 'src/app/model/pipeline.model';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { NewmypipelineService } from './newmypipeline.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ScheduleactivityComponent } from '../scheduleactivity/scheduleactivity.component';

@Component({
  selector: 'app-newmypipeline',
  templateUrl: './newmypipeline.component.html',
  styleUrls: ['./newmypipeline.component.css']
})
export class NewmypipelineComponent implements OnInit {

  showme: boolean;
  pipeline: Pipeline;
  pipelineList: Pipeline[];
  pipe: Pipeline;

  constructor(private newmypipelineService: NewmypipelineService, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.pipeline = new Pipeline();
    this.pipe = new Pipeline();
    this.pipeline.customer = new TrackBoxCustomers();

    this.newmypipelineService.getnewpipeline('NEW').subscribe(data => {
      this.pipelineList = data;
    });

  }

  addnewpipeline() {
    this.pipeline.pipelineStatus = 'NEW';
    this.newmypipelineService.addnewpipeline(this.pipeline).subscribe(data => {
      this.pipelineList.push(this.pipeline);
      this.showme = false;
      this.snackBar.open('Pipeline Added Successfully', '', {
        duration: 5000
      });
    });
  }

  changestatus(pipe , statusref) {
    this.pipeline.id = pipe;
    this.pipeline.pipelineStatus = statusref;
    this.newmypipelineService.changestatus(this.pipeline).subscribe(data => {
      this.snackBar.open('Pipeline Update Successfully', '', {
        duration: 5000
      });
    });
  }

  scheduleactivity(pipeId): void {
    //  alert(bookingId);
      const dialogoutRef = this.dialog.open(ScheduleactivityComponent, {
        width: '600px',
        data: { pipeId }
      });
      dialogoutRef.afterClosed().subscribe(result => {
        // this.tbagentblviewService.getBookingsDetailsByID(this.route.snapshot.params.id)
        //     .subscribe(data1 => {
        //       this.trackboxbooking = data1;
        //     },
        //       error => {
        //         this.router.navigate(['']);
        //       });
      });
    }

}
