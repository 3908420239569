import { Component, OnInit } from '@angular/core';
import { TaxAgency } from 'src/app/model/taxagency.model';
import { NewtaxagencyService } from './newtaxagency.service';
import { CommonService } from 'src/app/utils/common.service';
import { Router } from '@angular/router';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { Company } from 'src/app/model/company.model';

@Component({
  selector: 'app-newtaxagency',
  templateUrl: './newtaxagency.component.html',
  styleUrls: ['./newtaxagency.component.css']
})
export class NewtaxagencyComponent implements OnInit {
  sessionCandidate: SessionCandidate;
  taxagent: TaxAgency;

  constructor(private newtaxagencyService: NewtaxagencyService, private router: Router, private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.taxagent = new TaxAgency();
    this.taxagent.company = new Company();
  }

  saveTaxAgency() {
    this.taxagent.company.id = this.sessionCandidate.containerCompany;
    this.newtaxagencyService.saveTaxAgency(this.taxagent)
      .subscribe(data => {
        this.router.navigate(['containers/taxagencies']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
