import { ContainerCustomer } from './containercustomer.model';
import { ContainerCompany } from './containercompany.model';
import { ContainerType } from './containertype.model';

export class ContainerLease {
    id: number;
    customer: ContainerCustomer;
    company: ContainerCompany;
    containerType: ContainerType;
    releaseId: string;
    noOfContainers: number;
    containerReleased: number;
    containerReturned: number;
    freeDays: number;
    perDayCharge: number;
    detentionStartDays: number;
    detentionAmount: number;
    totalAmount: number;
}
