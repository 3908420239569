import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConsigneepaymentdetailsService {

  constructor(private http: HttpClient) { }

  // private appUrl = DepotProps.API_PATH + '/accountdashboard/getConsigneePaymentDetails.do';
  // private appRepUrl = DepotProps.API_PATH + '/accountdashboard/approveCashCollection.do';

  // public getConsigneePaymentDetails(estimateId) {
  //   return this.http.post<EstimateAndItems>(this.appUrl, estimateId);
  // }

  // public approveCashCollection(estimateandItem: EstimateAndItems) {
  //   return this.http.post<string>(this.appRepUrl, estimateandItem);
  // }
}
