import { Candidates } from "./candidate.model";
import { Company } from "./company.model";

export class CalcChargeHeads {
    id: number;
    company: Company;
    createdBy: Candidates;
    headName: string;
    description: string;
    pol: boolean;
    fpod: boolean;
    transhipment: boolean;
    createdOn: Date;
    headActive: boolean;
}