import { SearchbarService } from '../../header/searchbar/searchbar.service';
import { Candidates } from '../../model/candidate.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';

@Component({
  selector: 'app-recruiter-head',
  templateUrl: './recruiter-head.component.html',
  styleUrls: ['./recruiter-head.component.css']
})
export class RecruiterHeadComponent implements OnInit {
  candidate: Candidates;
  isGuest: boolean;

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  sessionCandidate: SessionCandidate;

  constructor(private searchBarService: SearchbarService , private router: Router , private commonService: CommonService) { }

  ngOnInit() {

  this.sessionCandidate = this.commonService.getSessionCandidate();

  //   this.searchBarService.sessionCandidate()
  //     .subscribe( data => {
  //         this.candidate = data;
  //         if(this.candidate == null)
  //             this.isGuest = true;
  //         else
  //             this.isGuest = false;
  //     },
  //     error => {
  //       this.router.navigate(['']);
  //       });
   }

logout() {
    this.searchBarService.logout()
      .subscribe( data => {
        this.commonService.removeRSCookieValues();
         // this.sessioncandidate = data;
        this.router.navigate(['']);
        },
      error => {
        this.router.navigate(['']);
        });

  }

}
