import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditequipmentcontrollerService {

constructor(private http: HttpClient) { }

private appUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/tbcontainerbyid.do';
  private updateUrl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/updatetbcontainer.do';

  public equipmentControllerbyid(operatorid: number) {
    return this.http.post<TrackBoxContainer>(this.appUrl , operatorid);
  }

  public updateVesselOperator(equipmentcontroler: TrackBoxContainer) {
    return this.http.post<string>(this.updateUrl , equipmentcontroler);
  }
}
