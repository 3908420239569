import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/model/person.model';
import { LoginpageService } from '../loginpage/loginpage.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { SearchbarService } from 'src/app/header/searchbar/searchbar.service';
import { CandidateCount } from 'src/app/model/candidatecount.model';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-accountactivatelogin',
  templateUrl: './accountactivatelogin.component.html',
  styleUrls: ['./accountactivatelogin.component.css']
})
export class AccountactivateloginComponent implements OnInit {

  constructor(private loginpageService: LoginpageService , private cookieService: CookieService , private router: Router ,
    private searchBarService: SearchbarService , private commonService: CommonService) { }

    sessionCandidate = this.commonService.getSessionCandidate();

  person: Person;
  errorMessageDiv: boolean;
  loginErrorDetails: string;
  loading = true;
  loading1 = true;
  isDisabled: boolean;
  friendsRequestCount: CandidateCount;

  ngOnInit() {
      this.person = new Person;
      this.errorMessageDiv = false;
      this.loading = false;
      this.loading1 = false;
      this.isDisabled = false;

      this.searchBarService.connectionCount()
			.subscribe(data => {
				this.friendsRequestCount = data;
			},
				error => {
					this.router.navigate(['']);
				});
  }

  checkAndSubmitLogin() {
		this.loading = true;
		this.isDisabled = true;
		let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
		if (this.person.userName !== '' && (this.person.userName.length <= 5 || !EMAIL_REGEXP.test(this.person.userName))) {
			console.log('Please provide a valid email');
		} else {
			// alert( "Please provide a valid email" );
			console.log('valid email');

			this.loginpageService.saveType(this.person)
				.subscribe(data => {
					// if (data == null && data.id == null ) {
					//   this.loginErrorDetails = 'User name or password mismatch';
					//    this.loading = false;
					//   this.router.navigate(['']);
					// }else
					if (data.status === false) {
						this.errorMessageDiv = true;
						this.loginErrorDetails = data.message;
						this.loading = false;
					}
					else {

						this.cookieService.set('API_SESSION_ID', data.sessionId);
						this.cookieService.set('CAND_ID', '' + data.id);
						this.cookieService.set('CAND_FN', data.firstName);
						this.cookieService.set('CAND_LN', data.lastName);
						this.cookieService.set('CAND_DESIG', data.designation);
						this.cookieService.set('CAND_COMPNM', data.companyName);
						this.cookieService.set('CAND_EA', data.emailAddress);
						this.cookieService.set('CAND_CONN', '' + data.connectionCount);
						this.cookieService.set('CAND_FOLL', '' + data.followingCount);
						this.cookieService.set('FIRST_FEED', data.firstFeed);
						this.cookieService.set('VERI_STAT', '' + data.verified);
						this.cookieService.set('MSG_CNT', '' + this.friendsRequestCount.messages);
						this.cookieService.set('CONN_CNT', '' + this.friendsRequestCount.connection);
						this.cookieService.set('CART_CNT', '' + this.friendsRequestCount.shoppingcart);
						// this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
						this.router.navigate(['m/dashboard']);

					}

					this.loading = false;
				},
					error => {
						this.router.navigate(['']);
					});
		}
    }
    
    onKeydownLogin(event, person: Person) {

		if (event.key === 'Enter') {
			this.loading = true;
			this.isDisabled = true;
			this.loginpageService.saveType(this.person)
				.subscribe(data => {
					if (data == null && data.id == null) {
						this.router.navigate(['']);
					} else if (data.status == false) {
						this.errorMessageDiv = true;
						this.loginErrorDetails = data.message;
						this.loading = false;
					} else {
						this.cookieService.set('API_SESSION_ID', data.sessionId);
						this.cookieService.set('CAND_ID', '' + data.id);
						this.cookieService.set('CAND_FN', data.firstName);
						this.cookieService.set('CAND_LN', data.lastName);
						this.cookieService.set('CAND_DESIG', data.designation);
						this.cookieService.set('CAND_COMPNM', data.companyName);
						this.cookieService.set('CAND_EA', data.emailAddress);
						this.cookieService.set('CAND_CONN', '' + data.connectionCount);
						this.cookieService.set('CAND_FOLL', '' + data.followingCount);
						this.cookieService.set('FIRST_FEED', data.firstFeed);
						this.cookieService.set('VERI_STAT', '' + data.verified);
						this.cookieService.set('MSG_CNT', '' + this.friendsRequestCount.messages);
						this.cookieService.set('CONN_CNT', '' + this.friendsRequestCount.connection);
						this.cookieService.set('CART_CNT', '' + this.friendsRequestCount.shoppingcart);
						// this.cookieService.set( 'LOG_TM', '' + data.loggedInTime );
						this.router.navigate(['m/dashboard']);
					}
					this.loading1 = false;
				},
					error => {
						this.router.navigate(['']);
					});
		}
	}

}
