import { Component, OnInit } from '@angular/core';
import { BuildnetworkService } from './buildnetwork.service';

@Component({
  selector: 'app-buildnetwork',
  templateUrl: './buildnetwork.component.html',
  styleUrls: ['./buildnetwork.component.css']
})
export class BuildnetworkComponent implements OnInit {

  linkedInFile: File = null;
  fileToUpload: File = null;
  selectedFile: File;
  constructor(private buildnetworkService: BuildnetworkService) { }

  ngOnInit() {

  }

  onFileChanged(event) {
    if (event.target) {
      const reader = new FileReader();
      const target1: any =  event.target;
      this.selectedFile = target1.files[0];
      reader.readAsDataURL(target1.files[0]);
      const fileReader: FileReader = new FileReader();
    }
  }

  uploadLinkedInFile() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.buildnetworkService.postFile(uploadData).subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }

  uploadGmailFile() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.buildnetworkService.uploadGmailFile(uploadData).subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }

  uploadYahooFile() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.buildnetworkService.uploadYahooFile(uploadData).subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }

  uploadHotmailFile() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.buildnetworkService.uploadHotmailFile(uploadData).subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }

  uploadOutlookFile() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.buildnetworkService.uploadOutlookFile(uploadData).subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }

  uploadOtherFile() {
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    this.buildnetworkService.uploadOtherFile(uploadData).subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }

}
