import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerpurchaseService } from '../tradings/containerpurchase/containerpurchase.service';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ContainerType } from 'src/app/model/containertype.model';
import { ContainertypeService } from '../master/containertype/containertype.service';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardpurchaselistService } from './dashboardpurchaselist.service';

@Component({
  selector: 'app-dashboardpurchaselist',
  templateUrl: './dashboardpurchaselist.component.html',
  styleUrls: ['./dashboardpurchaselist.component.css']
})
export class DashboardpurchaselistComponent implements OnInit {
  purchaseList: ContainerPurchase[];
  purchase: ContainerPurchase;
  conType: ContainerType;
  conttypeList: ContainerType[];
  myControlContainerType = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionsType: Observable<ContainerType[]>;

  constructor(private containerpurchaseService: ContainerpurchaseService, private router: Router,
    private containertypeService: ContainertypeService,
    private dashboardpurchaselistService: DashboardpurchaselistService) { }

  ngOnInit() {

    this.purchase = new ContainerPurchase();
    this.purchase.containerType = new ContainerType();
    this.conType = new ContainerType();


    this.containerpurchaseService.containerPurchaseListByOrderDate()
      .subscribe(data => {
        this.purchaseList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.containertypeService.findAllContainerTypeByOrder()
      .subscribe(data => {
        this.conttypeList = data;
        this.filteredOptionsType = this.myControlContainerType.valueChanges.pipe(
          map(val => this._filtersType(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onSearchKeydown(event) {
    if (event.key === 'Enter') {
      this.purchase.containerType.id = this.conType.id;
      this.dashboardpurchaselistService.findPurchaseByType(this.purchase)
        .subscribe(data => {
          this.purchaseList = data;
        },
          error => {
            this.router.navigate(['']);
          });
    }
  }

  public _filtersType(value: string): ContainerType[] {
    const filterValuecust = value;
    return this.conttypeList.filter(conttypeList => conttypeList.eqType.toLowerCase().indexOf(filterValuecust) === 0 ||
      conttypeList.eqType.toLowerCase().indexOf(filterValuecust) === 0 ||
      conttypeList.eqType.toUpperCase().indexOf(filterValuecust) === 0);
  }

  public displayCustomer(cntr: ContainerType): string {
    if (cntr && cntr.eqType) {
      return cntr.eqType;
    }
  }

}

export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}
