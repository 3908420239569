import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionfirstComponent } from '../billing/subscriptionfirst/subscriptionfirst.component';
import { ProfileComponent } from '../profile/profile/profile.component';
import { YourupdatesComponent } from '../profile/yourupdates/yourupdates.component';
import { WhoviewprofileComponent } from '../profile/whoviewprofile/whoviewprofile.component';
import { PersonalComponent } from '../profile/personal/personal.component';
import { BuildnetworkComponent } from '../more/buildnetwork/buildnetwork.component';
import { AboutComponent } from '../more/about/about.component';
import { ContactComponent } from '../more/contact/contact.component';
import { SearchcandidateComponent } from '../interest/searchcandidate/searchcandidate.component';
import { SearchjobsComponent } from '../jobs/searchjobs/searchjobs.component';
import { PreferencesComponent } from '../jobs/preferences/preferences.component';
import { MarketPlaceFilter } from '../utils/marketplace.pipe';
import { CompanyFilter } from '../utils/company.pipe';
import { MyjobsComponent } from '../jobs/myjobs/myjobs.component';
import { ConnectionsComponent } from '../network/connections/connections.component';
import { PeopleyoumayknowComponent } from '../network/peopleyoumayknow/peopleyoumayknow.component';
import { PresubscriptionComponent } from '../billing/presubscription/presubscription.component';
import { MarketplaceComponent } from '../network/marketplace/marketplace.component';
import { HelpnavComponent } from '../_layouts/helpnav/helpnav.component';
import { RecruitmentComponent } from '../interest/recruitment/recruitment.component';
import { SearchbarComponent } from '../header/searchbar/searchbar.component';
import { InvoiceComponent } from '../billing/billspayments/invoice/invoice.component';
import { NewcompanyComponent } from '../company/newcompany/newcompany.component';
import { JobdetailsComponent } from '../jobs/jobdetails/jobdetails.component';
import { MarketplaceinfoComponent } from '../network/marketplace/marketplaceinfo/marketplaceinfo.component';
import { MarketplaceMyorderComponent } from '../network/marketplace/marketplace-myorder/marketplace-myorder.component';
import { MarketplacedetailsComponent } from '../network/marketplace/marketplacedetails/marketplacedetails.component';
import { EditprofileComponent } from '../profile/editprofile/editprofile.component';
import { EducationComponent } from '../profile/education/education.component';
import { SkillsComponent } from '../profile/skills/skills.component';
import { CertificateComponent } from '../profile/certificate/certificate.component';
import { ExperienceComponent } from '../profile/experience/experience.component';
import { CandidateaboutComponent } from '../profile/candidateabout/candidateabout.component';
import { NavbarComponent } from '../header/navbar/navbar.component';
import { MobnavbarComponent } from '../header/navbar/mobnavbar/mobnavbar.component';
import { CandidateComponent } from '../profile/candidate/candidate.component';
import { BuildnetworkplugComponent } from '../commons/buildnetworkplug/buildnetworkplug.component';
import { InvitefriendplugComponent } from '../profile/invitefriendplug/invitefriendplug.component';
import { FriendlistplugComponent } from '../profile/friendlistplug/friendlistplug.component';
import { AppLayoutComponent } from '../_layouts/app-layout/app-layout.component';
import { AppHederComponent } from '../_layouts/app-heder/app-heder.component';
import { CompanylayoutComponent } from '../_layouts/companylayout/companylayout.component';
import { RecruiterlayoutComponent } from '../_layouts/recruiterlayout/recruiterlayout.component';
import { RecruiterHeadComponent } from '../_layouts/recruiter-head/recruiter-head.component';
import { CompanyheaderComponent } from '../_layouts/companyheader/companyheader.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { CandidateexperienceComponent } from '../profile/candidate/candidateexperience/candidateexperience.component';
import { CandidateeducationComponent } from '../profile/candidate/candidateeducation/candidateeducation.component';
import { CandidateskillsComponent } from '../profile/candidate/candidateskills/candidateskills.component';
import { CandidatecertificateComponent } from '../profile/candidate/candidatecertificate/candidatecertificate.component';
import { GlobalsearchpeopleComponent } from '../header/globalsearchpeople/globalsearchpeople.component';
import { GlobalsearchjobComponent } from '../header/globalsearchjob/globalsearchjob.component';
import { GlobalsearchcompanyComponent } from '../header/globalsearchcompany/globalsearchcompany.component';
import { PostsComponent } from '../posts/posts/posts.component';
import { NewpostComponent } from '../posts/newpost/newpost.component';
import { PostdetailsComponent } from '../posts/postdetails/postdetails.component';
import { MarketplaceorderComponent } from '../network/marketplace/marketplaceorder/marketplaceorder.component';
import { CandidatemessageComponent } from '../profile/candidate/candidatemessage/candidatemessage.component';
import { PreferenceemailsettingsComponent } from '../jobs/preferences/preferenceemailsettings/preferenceemailsettings.component';
import { HelpcentreComponent } from '../helpcentre/helpcentre.component';
import { CandidatemessagedetailsComponent } from '../profile/candidate/candidatemessagedetails/candidatemessagedetails.component';
import { MarketplacequoteComponent } from '../network/marketplace/marketplacequote/marketplacequote.component';
import { MarketplacequoteviewComponent } from '../network/marketplace/marketplacequoteview/marketplacequoteview.component';
import { GlobelsearchComponent } from '../header/globelsearch/globelsearch.component';
import { MarketComponent } from '../market/market.component';
import { MarketLayoutComponent } from '../_layouts/market-layout/market-layout.component';
import { MarketHeadComponent } from '../_layouts/market-head/market-head.component';
import { JobsPipeFilter } from '../utils/jobs.pipe';
import { MarketplaceplanComponent } from '../network/marketplace/marketplaceplan/marketplaceplan.component';
import { CmsLayoutComponent } from '../_layouts/cms-layout/cms-layout.component';
import { CmsheaderComponent } from '../_layouts/cmsheader/cmsheader.component';
import { OfflinelayoutComponent } from '../_layouts/offlinelayout/offlinelayout.component';
import { OfflineheaderComponent } from '../_layouts/offlineheader/offlineheader.component';
import { OfflinenavComponent } from '../_layouts/offlinenav/offlinenav.component';
import { TrackmyorderComponent } from '../network/marketplace/trackmyorder/trackmyorder.component';
import { AddresspageComponent } from '../network/marketplace/addresspage/addresspage.component';
import { AddcompanyplugComponent } from '../commons/addcompanyplug/addcompanyplug.component';
import { BookingconfirmationComponent } from '../network/marketplace/bookingconfirmation/bookingconfirmation.component';
import { AddskillsComponent } from '../profile/skills/addskills/addskills.component';
import { AddeducationComponent } from '../profile/education/addeducation/addeducation.component';
import { EmailDirective } from '../directives/email.directive';
import { ReversePipe } from '../pipes/reverse.pipe';
import { CandidateconnectionComponent } from '../profile/candidate/candidateconnection/candidateconnection.component';
import { EditpostComponent } from '../posts/editpost/editpost.component';
import { CandidatePipe } from '../pipes/candidate.pipe';
import { UpdatepostdialogComponent } from '../posts/editpost/updatepostdialog/updatepostdialog.component';
import { ConnectionPipe } from '../pipes/connection.pipe';
import { AdminComponent } from '../admin/admin.component';
import { AdminLayoutComponent } from '../_layouts/admin-layout/admin-layout.component';
import { AdminHeaderComponent } from '../_layouts/admin-header/admin-header.component';
import { RecruitermobnavComponent } from '../_layouts/recruitermobnav/recruitermobnav.component';
import { CompanyheadermobComponent } from '../_layouts/companyheadermob/companyheadermob.component';
import { RefundComponent } from '../more/refund/refund.component';
import { HowitworksComponent } from '../more/howitworks/howitworks.component';
import { HistorybackDirective } from '../directives/historyback.directive';
import { GraphComponent } from '../more/graph/graph.component';
import { EventsComponent } from '../events/events.component';
import { EventLayoutComponent } from '../_layouts/event-layout/event-layout.component';
import { EventHeaderComponent } from '../_layouts/event-header/event-header.component';
import { RigeventsComponent } from '../network/rigevents/rigevents.component';
import { VieweventsComponent } from '../network/rigevents/viewevents/viewevents.component';
import { FilteruniquePipe } from '../pipes/filterunique.pipe';
import { AddeventguestComponent } from '../network/rigevents/addeventguest/addeventguest.component';
import { ContainerComponent } from '../container/container.component';
import { ContainerloginlayoutComponent } from '../_layouts/containerloginlayout/containerloginlayout.component';
import { CandidatebynameComponent } from '../profile/candidate/candidatebyname/candidatebyname.component';
import { ExportpdfComponent } from '../more/exportpdf/exportpdf.component';
import { PhotosComponent } from '../profile/photos/photos.component';
import { VideosComponent } from '../profile/videos/videos.component';
import { BlogpostsComponent } from '../profile/blogposts/blogposts.component';
import { ProfileconnectionsComponent } from '../profile/profileconnections/profileconnections.component';
import { ProfiletimelineComponent } from '../profile/profiletimeline/profiletimeline.component';
import { LicensepurchasedComponent } from '../billing/licensepurchased/licensepurchased.component';
import { BillspaymentsComponent } from '../billing/billspayments/billspayments.component';
import { PaymentmethodComponent } from '../billing/paymentmethod/paymentmethod.component';
import { BillingprofileComponent } from '../billing/billspayments/billingprofile/billingprofile.component';
import { BillingaccountsComponent } from '../billing/billingaccounts/billingaccounts.component';
import { TrackboxcandidateintroComponent } from '../trackboxcandidateintro/trackboxcandidateintro.component';
import { TrackboxcompanyintroComponent } from '../trackboxcompanyintro/trackboxcompanyintro.component';
import { TrackboxprofileintroComponent } from '../trackboxprofileintro/trackboxprofileintro.component';
import { ServicelicensedetailsComponent } from '../billing/servicelicensedetails/servicelicensedetails.component';
import { SubscriptiondetailsComponent } from '../billing/subscriptiondetails/subscriptiondetails.component';
import { SubscriptioncheckoutComponent } from '../billing/subscriptioncheckout/subscriptioncheckout.component';
import { VoyagedetailsComponent } from '../trackmybox/master/voyage/voyagedetails/voyagedetails.component';
import { ContainertradingprofilesComponent } from '../container/containertradingprofiles/containertradingprofiles.component';
import { ContainerservicelicensesComponent } from '../billing/containerservicelicenses/containerservicelicenses.component';
import { DepotxpressinfoComponent } from '../depotxpressinfo/depotxpressinfo.component';
import { ContainermobnavComponent } from '../containermobnav/containermobnav.component';
import { SurvicerequestlistComponent } from '../helpcentre/survicerequestlist/survicerequestlist.component';
import { HeaderappsComponent } from '../header/headerapps/headerapps.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { ChartsModule } from 'ng2-charts';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MatToolbarModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule,
  MatButtonModule, MatBadgeModule, MatStepperModule, MatBottomSheetModule, MatSidenavModule,
  MatIconModule, MatTooltipModule, MatSliderModule, MatListModule, MatSnackBarModule, MatGridListModule,
  MatCardModule, MatMenuModule, MatTabsModule, MatTableModule, MatRadioModule, MatPaginatorModule,
  MatCheckboxModule, MatSortModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatDialogModule,
  MatExpansionModule, MatSlideToggleModule
} from '@angular/material';
import { SlideshowModule } from 'ng-simple-slideshow';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { CKEditorModule } from 'ckeditor4-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { JobplanComponent } from '../recruiter/jobplan/jobplan.component';
import {
  NgxUiLoaderRouterModule, NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  NGX_POSITIONS, SPINNER_TYPES, PB_DIRECTIONS
} from 'ngx-ui-loader';
import { HeaderComponent } from '../header/header.component';
import { ServicelicensesComponent } from '../billing/servicelicenses/servicelicenses.component';
import { DashbaordComponent } from './dashbaord/dashbaord.component';
import { PeopleknowComponent } from './dashbaord/peopleknow/peopleknow.component';
import { RecentjobsComponent } from './dashbaord/recentjobs/recentjobs.component';
import { RecentpostsComponent } from './dashbaord/recentposts/recentposts.component';
import { SuggestedcompaniesComponent } from './dashbaord/suggestedcompanies/suggestedcompanies.component';
import { SharepostComponent } from './dashbaord/sharepost/sharepost.component';
import { FirstfeednotificationComponent } from './dashbaord/firstfeednotification/firstfeednotification.component';
import { CandidatestrengthComponent } from './dashbaord/candidatestrength/candidatestrength.component';
import { SharedialogComponent } from './dashbaord/sharedialog/sharedialog.component';
import { SharedeletedialogComponent } from './dashbaord/sharedeletedialog/sharedeletedialog.component';
import { HelpComponent } from '../help/help.component';
import { InvitefriendComponent } from '../network/invitefriend/invitefriend.component';
import { InvitefrienddumpComponent } from '../offline/invitefriend/invitefriend.component';
import { NewcompanydumpComponent } from '../company/companies/newcompany/newcompany.component';
import { YourcompaniesComponent } from '../company/yourcompanies/yourcompanies.component';
import { EnrollcontainersComponent } from '../company/enrollcontainers/enrollcontainers.component';
import { PaypalComponent } from '../company/companies/paypal/paypal.component';
import { CompanyviewComponent } from '../company/companyview/companyview.component';
import { CompanyhomepageComponent } from '../company/companyhomepage/companyhomepage.component';
import { CompaniesComponent } from '../company/companies/companies.component';
import { ManagecompanyemployeesComponent } from '../company/managecompanyemployees/managecompanyemployees.component';
import { CompanyeditComponent } from '../company/companyedit/companyedit.component';
import { CompanydetailsComponent } from '../company/companydetails/companydetails.component';
import { EnrolltrackboxcompanyComponent } from '../company/companydetails/enrolltrackboxcompany/enrolltrackboxcompany.component';
import { CompanysubscriptionsComponent } from '../company/companydetails/companysubscriptions/companysubscriptions.component';
import { AnweighRouterModule } from './anweigh-router.module';
import { AppRoutingModule } from '../app-routing.module';
import { ViewallcompanyfollowersComponent } from '../company/viewallcompanyfollowers/viewallcompanyfollowers.component';
import { ManagecompanyemployeeComponent } from '../company/managecompanyemployee/managecompanyemployee.component';
import { ManagecompanyfollowersComponent } from '../company/managecompanyfollowers/managecompanyfollowers.component';
import { DisableconfirmationComponent } from '../company/disableconfirmation/disableconfirmation.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: 'white',
  fgsPosition: NGX_POSITIONS.centerCenter,
  fgsSize: 40,
  fgsType: SPINNER_TYPES.foldingCube,
  pbDirection: PB_DIRECTIONS.leftToRight,
  pbThickness: 2
}


@NgModule({
  declarations: [SubscriptionfirstComponent, DashbaordComponent, ProfileComponent,
    YourupdatesComponent, WhoviewprofileComponent, PersonalComponent, BuildnetworkComponent, AboutComponent,
    ContactComponent, SearchcandidateComponent, SearchjobsComponent, PreferencesComponent, MarketPlaceFilter, CompanyFilter,
    //    FileSelectDirective,
    MyjobsComponent, ConnectionsComponent, PeopleyoumayknowComponent, CompaniesComponent, PresubscriptionComponent,
    MarketplaceComponent, RecruitmentComponent, SearchbarComponent, SearchbarComponent,
    SearchbarComponent, InvoiceComponent, NewcompanyComponent, CompanyeditComponent,
    JobdetailsComponent, CompanydetailsComponent, MarketplaceinfoComponent,
    MarketplaceMyorderComponent, MarketplacedetailsComponent, EditprofileComponent, EducationComponent,
    SkillsComponent, CertificateComponent, ExperienceComponent, CandidateaboutComponent, NavbarComponent, MobnavbarComponent,
    PeopleknowComponent, RecentjobsComponent, RecentpostsComponent, SuggestedcompaniesComponent, CandidateComponent,
    BuildnetworkplugComponent, InvitefriendplugComponent, FriendlistplugComponent, AppLayoutComponent,
    AppHederComponent, CompanylayoutComponent, RecruiterlayoutComponent,
    RecruitmentComponent, RecruiterHeadComponent, CompanyheaderComponent, FilterPipe, InvitefriendComponent,
    CandidateexperienceComponent, CandidateeducationComponent, CandidateskillsComponent, CandidatecertificateComponent,
    GlobalsearchpeopleComponent, GlobalsearchjobComponent, GlobalsearchcompanyComponent, SharepostComponent,
    PostsComponent, NewpostComponent, PostdetailsComponent, // PortsfilterComponent,
    MarketplaceorderComponent, InvitefrienddumpComponent ,  NewcompanydumpComponent, // EquipmentdetailsComponent,
    CandidatemessageComponent, PreferenceemailsettingsComponent, HelpcentreComponent,
    CandidatemessagedetailsComponent, MarketplacequoteComponent, MarketplacequoteviewComponent,
    GlobelsearchComponent, MarketComponent, MarketLayoutComponent, MarketHeadComponent, FirstfeednotificationComponent,
    JobsPipeFilter, MarketplaceplanComponent, CmsLayoutComponent, CmsheaderComponent, OfflinelayoutComponent, OfflineheaderComponent,
    OfflinenavComponent, MobnavbarComponent, TrackmyorderComponent, AddresspageComponent, AddcompanyplugComponent,
    BookingconfirmationComponent, HeaderComponent,
    CandidatestrengthComponent, AddskillsComponent, AddeducationComponent, EmailDirective, ReversePipe, CandidateconnectionComponent,
    EditpostComponent, CandidatePipe, SharedialogComponent, SharedeletedialogComponent, UpdatepostdialogComponent, ConnectionPipe,
    AdminComponent, AdminLayoutComponent, AdminHeaderComponent, RecruitermobnavComponent, CompanyheadermobComponent,
    RefundComponent, HowitworksComponent, HistorybackDirective, GraphComponent, EventsComponent, EventLayoutComponent,
    EventHeaderComponent, RigeventsComponent, VieweventsComponent, FilteruniquePipe, AddeventguestComponent,
    ContainerComponent, ContainerloginlayoutComponent, CandidatebynameComponent, ExportpdfComponent,
    PhotosComponent, VideosComponent, BlogpostsComponent, YourcompaniesComponent, ProfileconnectionsComponent,
    ProfiletimelineComponent, EnrollcontainersComponent,
    ServicelicensesComponent, LicensepurchasedComponent, BillspaymentsComponent,
    InvoiceComponent, PaymentmethodComponent, BillingprofileComponent, BillingaccountsComponent,
    TrackboxcandidateintroComponent, TrackboxcompanyintroComponent, TrackboxprofileintroComponent,
    ServicelicensedetailsComponent, SubscriptiondetailsComponent, SubscriptioncheckoutComponent, PaypalComponent,
    SubscriptionfirstComponent, PresubscriptionComponent, VoyagedetailsComponent,
    InvoiceComponent, PaymentmethodComponent, BillingprofileComponent, BillingaccountsComponent, TrackboxcandidateintroComponent,
    JobplanComponent, ManagecompanyemployeesComponent, ManagecompanyemployeeComponent, ManagecompanyfollowersComponent,
    EnrolltrackboxcompanyComponent, ContainertradingprofilesComponent, ContainerservicelicensesComponent, DepotxpressinfoComponent,
    CompanyviewComponent, HelpComponent, ViewallcompanyfollowersComponent,
    CompanyhomepageComponent, HelpcentreComponent, SurvicerequestlistComponent, HeaderappsComponent,
    CompanysubscriptionsComponent , DisableconfirmationComponent,],
  imports: [
    AnweighRouterModule ,BrowserAnimationsModule, HttpClientModule, RecaptchaModule, ChartsModule,
    // NgDatepickerModule ,
    LayoutModule, FlexLayoutModule, DragDropModule,
    MatToolbarModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule,
    MatButtonModule, MatBadgeModule, MatStepperModule, MatBottomSheetModule,
    MatSidenavModule, SlideshowModule, // PopoverModule,
    MatIconModule, MatTooltipModule, MatSliderModule,
    MatListModule, FormsModule, ReactiveFormsModule, MatSnackBarModule,
    // CustomFormsModule ,
    MatGridListModule,
    // Ng2GoogleChartsModule,
    MatCardModule,
    MatMenuModule, MatTabsModule,
    MatTableModule, MatRadioModule,
    MatPaginatorModule, MatCheckboxModule,
    MatSortModule, MatSelectModule,
    MatFormFieldModule, MatInputModule,
    NgxPaginationModule, MatDialogModule, MatExpansionModule, MatSlideToggleModule, CKEditorModule, CarouselModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
  ]
})
export class AnweighModule { }
