import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyEmployee } from '../model/companyemployee.model';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../utils/common.service';
import { SessionCandidate } from '../model/sessioncandidate.model';
import { TrackboxprofileintroService } from './trackboxprofileintro.service';

@Component({
  selector: 'app-trackboxprofileintro',
  templateUrl: './trackboxprofileintro.component.html',
  styleUrls: ['./trackboxprofileintro.component.css']
})
export class TrackboxprofileintroComponent implements OnInit {
  mycompanies: CompanyEmployee[];
  sessionCandidate: SessionCandidate;
  skipicon: string;

  constructor(private router: Router, private cookieService: CookieService, private commonService: CommonService,
              private trackboxprofileintroService: TrackboxprofileintroService) { }


  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    if (this.sessionCandidate.trackmyboxCompany != null && this.sessionCandidate.trackmyboxCompany !== 0 &&
      this.sessionCandidate.trackmyboxStatus === 'PRINCIPLE') {
      this.router.navigate(['/trackmybox']);
    } else if (this.sessionCandidate.trackmyboxCompany != null && this.sessionCandidate.trackmyboxCompany !== 0 &&
      this.sessionCandidate.trackmyboxStatus === 'AGENT') {
      this.router.navigate(['/tbagentmode']);
    } else {
      this.trackboxprofileintroService.getAllMyTbCompanies()
        .subscribe(data => {
          this.mycompanies = data;
          if (this.mycompanies.length === 1) {
              this.updateCookies(this.mycompanies[0].company.id , this.mycompanies[0].company.companyName,
                this.mycompanies[0].company.companyStatus);
          }
        },
          error => {
            this.router.navigate(['']);
          });
    }


  }

  updateCookies(companyId, name, tbstatus) {
    this.cookieService.set('TMB_COMP', companyId, null, null, null, null, null);
    this.cookieService.set('TMB_COMP_NM', name, null, null, null, null, null);
    if (tbstatus === 'PRINCIPLE') {
      this.cookieService.set('TMB_STAT', tbstatus, null, null, null, null, null);
      this.router.navigate(['/trackmybox']);
    } else if (tbstatus === 'AGENT') {
      this.cookieService.set('TMB_STAT', tbstatus, null, null, null, null, null);
      this.router.navigate(['/tbagentmode']);
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

}
