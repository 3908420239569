import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsInvoice } from 'src/app/model/accountsnvoice.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class SalesinvoiceService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'accountssalesinvoice/getAllSalesInvoices';

  public getAllSalesInvoices() {
    return this.http.get<AccountsInvoice[]>(this.appUrl);
  }

}
