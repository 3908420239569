import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'candidatefilter'
})
export class CandidatePipe implements PipeTransform {

  transform(candidates: any[], searchText: string): any[] {
    if (!candidates) {
        return candidates;
    }
    if (!searchText) {
        return candidates;
    }
    searchText = searchText.toLowerCase().trim();
  return candidates.filter( candidate => {
        if ( candidate && candidate.name.toLowerCase().indexOf(searchText) != -1 ) {
            return true;
        } else {
            return false;
        }
    });
  }

}
