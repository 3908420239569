import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edittrackerdesignation',
  templateUrl: './edittrackerdesignation.component.html',
  styleUrls: ['./edittrackerdesignation.component.css']
})
export class EdittrackerdesignationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
