import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accountsheader',
  templateUrl: './accountsheader.component.html',
  styleUrls: ['./accountsheader.component.css']
})
export class AccountsheaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  helpWindow() {}
  logout() {}

}
