import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OnewaymoverequestService } from './onewaymoverequest.service';
import { CommonService } from 'src/app/utils/common.service';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { ContainerOnewayMoveRequest } from 'src/app/model/containeronewaymoverequest.model';
import { ContainerCompany } from 'src/app/model/containercompany.model';
import { ContainerOnewayMove } from 'src/app/model/containeronewaymove.model';
import { OnewaymovedetailsService } from '../onewaymovedetails/onewaymovedetails.service';

@Component({
  selector: 'app-onewaymoverequest',
  templateUrl: './onewaymoverequest.component.html',
  styleUrls: ['./onewaymoverequest.component.css']
})
export class OnewaymoverequestComponent implements OnInit {

  sessionCandidate: SessionCandidate;
  moverequest: ContainerOnewayMoveRequest;
  onewayobj: ContainerOnewayMove;

  constructor(private router: Router, private onewaymoverequestService: OnewaymoverequestService,
              private commonService: CommonService , private route: ActivatedRoute,
              private onewaymovedetailsService: OnewaymovedetailsService) { }

  ngOnInit() {
    this.moverequest = new ContainerOnewayMoveRequest();
    this.moverequest.requestdCompany = new ContainerCompany();
    this.moverequest.onewayMove = new ContainerOnewayMove();
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.onewaymovedetailsService.findSingleContainerOnewayMove(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.onewayobj = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  saveContainerOnewayMoveRequest() {
    this.moverequest.requestdCompany.id = this.sessionCandidate.containerCompany;
    this.moverequest.onewayMove.id = Number(this.route.snapshot.paramMap.get('id'));
    this.onewaymoverequestService.saveContainerOnewayMoveRequest(this.moverequest)
      .subscribe(data => {
        this.router.navigate(['/containers/movecontainers']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
