import { Candidates } from '../../model/candidate.model';
import { CandidateJobs } from '../../model/candidatejobs.model';
import { JobWishlist } from '../../model/jobwishlist.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CandidateMessage } from 'src/app/model/candidateMessage.model';
import { CandidateMessageMessage } from 'src/app/model/candidateMessageMessage.model';

@Injectable({
  providedIn: 'root'
})
export class MyjobsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'getJobAppliedBySessionCandidate.do';
  private sessionUrl = ShipStreetProps.API_PATH + 'sessionCandidate.do';
  private wishlistUrl = ShipStreetProps.API_PATH + 'getLoggedWishList.do';
  private candidateJobReceivedMessageUrl = ShipStreetProps.API_PATH + 'getCandidateJobReceivedMessageList.do';
  private candidateJobReceivedMessageDetailsUrl = ShipStreetProps.API_PATH + 'getCandidateJobReceivedMessageDetailsList.do';
  public sessionCandidate() {
    return this.http.get<Candidates>(this.sessionUrl);
  }

  public jobAppliedBySessionCandidate() {
    return this.http.get<CandidateJobs[]>(this.appUrl);
  }

  public getLoggedWishList() {
    return this.http.get<JobWishlist[]>(this.wishlistUrl);
  }

  public getCandidateJobReceivedMessages() {
    return this.http.get<CandidateMessage[]>(this.candidateJobReceivedMessageUrl);
  }

  public receivedMessageDetails(messageId: string) {
    return this.http.post<CandidateMessageMessage[]>(this.candidateJobReceivedMessageDetailsUrl , messageId);
  }
}
