import { TrackBoxBooking } from './../../model/trackboxbooking.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/TrackboxBookingsView.do';
  private appliUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/TrackboxPrincipleBookingsList.do';
  private bookingUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/trackboxCurrentBookingsList.do';
  private upbookingUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/trackboxUpcommingBookingsList.do';

  getAllMyBookings() {
    return this.http.get<TrackBoxBooking[]>(this.appUrl);
  }

  getAllPrincipleBookings() {
    return this.http.get<TrackBoxBooking[]>(this.appliUrl);
  }

  getAllCurrentBookings() {
    return this.http.get<TrackBoxBooking[]>(this.bookingUrl);
  }

   getAllUpcommingBookings() {
    return this.http.get<TrackBoxBooking[]>(this.upbookingUrl);
  }
}
