import { Component, OnInit } from '@angular/core';
import { TbagentnewdepotService } from './tbagentnewdepot.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { FormControl, Validators } from '@angular/forms';
import { RequireMatch } from 'src/app/trackmybox/admin/trackboxcustomers/newtrackboxcustomers/newtrackboxcustomers.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-tbagentnewdepot',
  templateUrl: './tbagentnewdepot.component.html',
  styleUrls: ['./tbagentnewdepot.component.css']
})
export class TbagentnewdepotComponent implements OnInit {

  masterDepots: MasterPortsDepot;
  CountryList: CountryInfo[];
  hide = true;
  services = new FormControl();
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;


  serviceList: string[] = ['Container Storage', 'Container repair and maintenance', 'Container trading', 'Container Modification'
    , 'Transportation Services', 'Container Inspection', 'Reefer PTI', 'Clip on Genset Rental'];
  constructor(private tbagentnewdepotService: TbagentnewdepotService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.masterDepots = new MasterPortsDepot();
    this.masterDepots.country = new CountryInfo();

    this.tbagentnewdepotService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter4(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onSubmit() {
    this.tbagentnewdepotService.save(this.masterDepots)
      .subscribe(data => {
        this.router.navigate(['/tbagentmode/agentdepots']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }

  }

}
