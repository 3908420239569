import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackerDesignation } from 'src/app/model/trackerdesignation.module';

@Injectable({
  providedIn: 'root'
})
export class TrackerdesignationService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/trackermaster/getAllTrackerDesignationList.do';

  getAllTrackerDesignationList() {
    return this.http.get<TrackerDesignation[]>(this.appUrl);
  }

}
