import { Marketplace } from '../../../model/marketplace.model';
import { MarketplaceReview } from '../../../model/marketplacereview.model';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { MarketplacedetailsService } from './marketplacedetails.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplaceService } from '../marketplace.service';

@Component({
  selector: 'app-marketplacedetails',
  templateUrl: './marketplacedetails.component.html',
  styleUrls: ['./marketplacedetails.component.css']
})
export class MarketplacedetailsComponent implements OnInit {
  newreviews: MarketplaceReview;
  reviews: MarketplaceReview[];
  marketplace: Marketplace;
  marketplaceList = [];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private marketplacedetailsService: MarketplacedetailsService , private route: ActivatedRoute , private router: Router ,
    private marketplaceService: MarketplaceService) { }

  ngOnInit() {
    this.newreviews = new MarketplaceReview;
    this.newreviews.marketplace = new Marketplace;
     this.marketplacedetailsService.getmarketplace(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.marketplace = data;
       // alert(JSON.stringify(data))
      },
      error => {
        this.router.navigate(['']);
        });

    this.marketplacedetailsService.getMarketplaceReviews(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.reviews = data;
      //  alert(data);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  addtocart(itemId) {
    this.marketplaceService.addtoshoppingcart(itemId)
      .subscribe( data => {
        this.router.navigate(['/m/marketplaceorder']);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  saveMarketplaceReview() { alert(JSON.stringify(this.newreviews));
    this.newreviews.marketplace.id =  this.route.snapshot.params['id'];
    this.marketplacedetailsService.saveMarketplaceReview(this.newreviews)
      .subscribe( data => {alert(JSON.stringify(this.newreviews));
        this.reviews.push(this.newreviews);
       // this.marketplace = data;
       // this.descDiv = true;
      //   },
      // error => {
      //   this.router.navigate(['']);
        });
  }

}
