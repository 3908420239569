import { ContainerSupplier } from './containersupplier.model';
import { ContainerBranches } from './containerbranches.model';
import { ContainerType } from './containertype.model';
import { ContainerPurchaseLocation } from './containerpurchaselocation.model';
import { Taxes } from './taxes.model';
import { Company } from './company.model';

export class ContainerPurchase {
    id: number;
    supplier: ContainerSupplier;
    branch: ContainerBranches;
    company: Company;
    containerType: ContainerType;
    purchasedLocation: ContainerPurchaseLocation;
    createdOn: Date;
    vatPaid: Taxes;
    vatAmount: number;
    quantity: number;
    price: number;
    total: number;
    grandTotal: number;
    quantitySold: number;
    quantityReceived: number;
    purchasedOn: Date;
    invoiceNo: string;
    saleBox: boolean;
    sellingPrice: number;
    yearManufacturing: string;
    cscValidityUntil: string;
    cargoWorthy: boolean;
    cscCertificate: boolean;
    pickupReady: boolean;
    pickupLocation: string;
    exportReady: boolean;
    moveBox: boolean;
    pickupFrom: string;
    deliveredTo: string;
    freeDays: number;
    perDayCharge: number;
    pickupCharge: number;
    insurence: boolean;
    selectForExpense: boolean;
    payedAmount: number;
    selectForPurchase: boolean;
    balanceTotal: number;
    aditionalExpense: number;
}
