import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Suppliers } from 'src/app/model/suppliers.model';

@Injectable({
  providedIn: 'root'
})
export class NewsuppliersService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'supplier/savesupplier.do';

  public savesupplier(supplier: Suppliers) {
    return this.http.post<Suppliers>(this.appUrl, supplier);
  }
}
