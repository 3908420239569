import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Injectable({
  providedIn: 'root'
})
export class NewportService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'masterports/masterports.do';
private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';

public save(masterPorts: MasterPortsDepot) {
  return this.http.post<MasterPortsDepot>(this.appUrl, masterPorts);
}
viewCountry() {
  return this.http.get<CountryInfo[]>(this.appUrls);
}
}
