import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';

@Injectable({
  providedIn: 'root'
})
export class EditvesseloperatorService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'vesseloperator/vesseloperatorbyid.do';
  private updateUrl = ShipStreetProps.API_PATH + 'vesseloperator/updateCompanyVesselOperator.do';

  public vesseloperatorbyid(operatorid: number) {
    return this.http.post<MasterVesselOperator>(this.appUrl , operatorid);
  }

  public updateVesselOperator(vsoperator: MasterVesselOperator) {
    return this.http.post<string>(this.updateUrl , vsoperator);
  }

}
