import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, AbstractControl } from '@angular/forms';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';
import { Router } from '@angular/router';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { ContainertypesService } from 'src/app/trackmybox/master/containertypes/containertypes.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CostcalculatorService } from './costcalculator.service';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';
import { MasterPortVesselOperatorCosts } from 'src/app/model/masterportvesseloperatorcosts.model';
import { CalcvesseloperatorService } from '../calcvesseloperator/calcvesseloperator.service';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';
import { CalcequipmenttypesService } from '../mastersettings/calcequipmenttypes/calcequipmenttypes.service';
import { CalcEquipmentType } from 'src/app/model/CalcEquipmentType.model';

@Component({
  selector: 'app-costcalculator',
  templateUrl: './costcalculator.component.html',
  styleUrls: ['./costcalculator.component.css']
})
export class CostcalculatorComponent implements OnInit {

  companytype = ['Port', 'Depot', 'Shipping Lane', 'NVOCC', 'Leasing', 'Logistics', 'Transports', 'Trader', 'Other'];
  servicetype = ['TBL', 'DIRECT', 'TRANSHIPMENT'];
  cargotype = ['GENERAL', 'DANGEROUS', 'OUT GUAGE', 'IN GUAGE'];
  cservice: string;
  cargos: string;
  returns: string;
  returnType = ['SINGLE VOYAGE', 'EMPTY RETURN', 'EXPORT FROM POD'];
  dangerousList = ['Division 1.1', 'Division 1.2', 'Division 1.3', 'Division 1.4', 'Division 1.5',
    'Division 1.6', 'Division 2.1', 'Division 2.2', 'Division 2.3', 'Division 4.1',
    'Division 4.2', 'Division 4.3', 'Division 5.1', 'Division 5.2', 'Division 6.1', 'Division 6.2',
    'Division 7.1', 'Division 8.1', 'Division 9.1'];

  pol: MasterPortsDepot;
  myControl1 = new FormControl('', [Validators.required, RequireMatch]);
  myControl2 = new FormControl('', [Validators.required, RequireMatch]);
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  myControl4 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionsports: Observable<MasterPortsDepot[]>;
  filteredOptionspod: Observable<MasterPortsDepot[]>;
  filteredOptionsfpod: Observable<MasterPortsDepot[]>;
  filteredVesselOperator: Observable<MasterVesselOperator[]>;

  polThcCollection: number;
  polThcCost: number;

  polBLCollection: number;
  polBLCost: number;

  polChargeHeadCollection: number;
  polChargeHeadCost: number;

  polSpecialGearCollection: number;
  polSpecialGearCost: number;

  transhipmentCost: number;
  transhipmentOther1: number;
  transhipmentOther2: number;
  transhipmentOther3: number;

  leg1SlotQuantity: number;
  leg1SlotCost: number;
  leg1LssCost: number;
  leg1EwriCost: number;
  leg1GriCost: number;

  leg2SlotCollection: number;
  leg2SlotCost: number;
  leg2SlotBalance: number;

  leg2LssCollection: number;
  leg2LssCost: number;
  leg2LssBalance: number;

  leg2EwriCollection: number;
  leg2EwriCost: number;
  leg2EwriBalance: number;

  leg2GriCollection: number;
  leg2GriCost: number;
  leg2GriBalance: number;

  fpodLocalsDthcCollection: number;
  fpodLocalsDthcCost: number;
  fpodLocalsDthcBalance: number;

  fpodLocalsDoFeeCollection: number;
  fpodLocalsDoFeeCost: number;
  fpodLocalsDoFeeBalance: number;

  fpodLocalsChargeHeadCollection: number;
  fpodLocalsChargeHeadcCost: number;
  fpodLocalsChargeHeadcBalance: number;

  fpodLocalsOtherCollection: number;
  fpodLocalsOtherCost: number;
  fpodLocalsOtherBalance: number;

  masterPortListAll: MasterPortsDepot[];

  frieghtFreightCharge: number;
  freightDGSurcharge: number;
  freightFlexiSurcharge: number;
  freightOtherCharges: number;

  exportRevenueFreight: number;
  exportRevenueOtherChargeHead: number;

  emptyRepoThc: number;
  emptyRepoChargeHead: number;
  emptyRepoSlotCost: number;
  emptyRepoLss: number;
  emptyRepoEwri: number;
  emptyRepoGri: number;
  emptyRepoFpodThc: number;

  expotFpodLthcCollection: number;
  expotFpodChargeHeadCollection: number;
  expotFpodSlotCostCollection: number;
  expotFpodLssEwriCollection: number;
  expotFpodGriCollection: number;
  expotFpodFpodThcCollection: number;
  expotFpodOtherLocalsCollection: number;

  expotFpodLthcCosts: number;
  expotFpodChargeHeadCosts: number;
  expotFpodSlotCostCosts: number;
  expotFpodLssEwriCosts: number;
  expotFpodGriCosts: number;
  expotFpodFpodThcCosts: number;
  expotFpodOtherLocalsCosts: number;

  // serviceTab: boolean;
  cargoTab: boolean;
  returnsTab: string;
  costObj: MasterPortCostDetails;
  costDetails: MasterPortCostDetails;
  finalcostDetails: MasterPortCostDetails;
  vocostDetails: MasterPortVesselOperatorCosts;
  emptyRepoData: MasterPortVesselOperatorCosts;
  vocostTwoDetails: MasterPortVesselOperatorCosts;
  exportRepoData: MasterPortCostDetails;
  totalCollection: number;
  totalCosts: number;
  transhipDetails: MasterPortCostDetails;
  vesselOperators: MasterVesselOperator[];
  emptyFpodDetails: MasterPortCostDetails;
  breakpoint: number;
  containerTypeList: CalcEquipmentType[];
  //isActive = false;
  activeLabel: string | null = null;


  constructor(private portService: PortsService, private router: Router, private containertypesService: ContainertypesService,
    private costcalculatorService: CostcalculatorService, private calcvesseloperatorService: CalcvesseloperatorService , 
    private calcequipmenttypesService: CalcequipmenttypesService) { }

  ngOnInit() {
    this.costObj = new MasterPortCostDetails();
    this.costObj.port = new MasterPortsDepot();
    this.costObj.finalport = new MasterPortsDepot();
    this.costObj.transhipmentPort = new MasterPortsDepot();
    this.costObj.vesselOperator = new MasterVesselOperator();
    this.costObj.containerSize = new CalcEquipmentType();

    this.totalCollection = 0;
    this.totalCosts = 0;
    this.leg1SlotQuantity = 1;

    this.cargoTab = false;
    this.costObj.returnType = 'SINGLE VOYAGE';
    this.costObj.transhipment = 'TBL';
    this.costObj.cargos = 'GENERAL';

    this.polThcCollection = 0;
    this.polThcCost = 0;

    this.polBLCollection = 0;
    this.polBLCost = 0;

    this.polChargeHeadCollection = 0;
    this.polChargeHeadCost = 0;

    this.polSpecialGearCollection = 0;
    this.polSpecialGearCost = 0;

    this.fpodLocalsDthcCollection = 0;
    this.fpodLocalsDthcCost = 0;

    this.fpodLocalsDoFeeCollection = 0.00;
    this.fpodLocalsDoFeeCost = 0.00;

    this.fpodLocalsChargeHeadCollection = 0.00;
    this.fpodLocalsChargeHeadcCost = 0.00;

    this.fpodLocalsOtherCollection = 0.00;
    this.fpodLocalsOtherCost = 0.00;

    this.frieghtFreightCharge = 0;
    this.freightDGSurcharge = 0;
    this.freightFlexiSurcharge = 0;
    this.freightOtherCharges = 0;

    this.exportRevenueFreight = 0;
    this.exportRevenueOtherChargeHead = 0;

    this.leg1SlotCost = 0;
    this.leg1LssCost = 0;
    this.leg1EwriCost = 0;
    this.leg1GriCost = 0;

    this.leg2SlotCollection = 0;
    this.leg2SlotCost = 0;
    this.leg2SlotBalance = 0;

    this.leg2LssCollection = 0;
    this.leg2LssCost = 0;
    this.leg2LssBalance = 0;

    this.leg2EwriCollection = 0;
    this.leg2EwriCost = 0;
    this.leg2EwriBalance = 0;

    this.leg2GriCollection = 0;
    this.leg2GriCost = 0;
    this.leg2GriBalance = 0;

    this.transhipmentCost = 0;
    this.transhipmentOther1 = 0;
    this.transhipmentOther2 = 0;
    this.transhipmentOther3 = 0;

    this.emptyRepoThc = 0;
    this.emptyRepoChargeHead = 0;
    this.emptyRepoSlotCost = 0;
    this.emptyRepoLss = 0;
    this.emptyRepoEwri = 0;
    this.emptyRepoGri = 0;
    this.emptyRepoFpodThc = 0;

    this.expotFpodLthcCollection = 0;
    this.expotFpodChargeHeadCollection = 0;
    this.expotFpodSlotCostCollection = 0;
    this.expotFpodLssEwriCollection = 0;
    this.expotFpodGriCollection = 0;
    this.expotFpodFpodThcCollection = 0;
    this.expotFpodOtherLocalsCollection = 0;

    this.expotFpodLthcCosts = 0;
    this.expotFpodChargeHeadCosts = 0;
    this.expotFpodSlotCostCosts = 0;
    this.expotFpodLssEwriCosts = 0;
    this.expotFpodGriCosts = 0;
    this.expotFpodFpodThcCosts = 0;
    this.expotFpodOtherLocalsCosts = 0;

    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortListAll = data;
        this.filteredOptionsports = this.myControl3.valueChanges.pipe(
          map(val => this._filter3(val))
        );
        this.filteredOptionspod = this.myControl2.valueChanges.pipe(
          map(val => this._filter2(val))
        );
        this.filteredOptionsfpod = this.myControl4.valueChanges.pipe(
          map(val => this._filter4(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });

        this.calcequipmenttypesService.findAllCalculatorEquipmentType()
        .subscribe(data => {
          this.containerTypeList = data;
        },
          error => {
            this.router.navigate(['']);
          });

    this.calcvesseloperatorService.findAllMasterVesselOperatorSortByName()
      .subscribe(data => {
        this.vesselOperators = data;
        this.filteredVesselOperator = this.myControl1.valueChanges.pipe(
          map(val => this._filter1(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
        this.breakpointFun(event)

  }

  onResize(event) {
    this.breakpointFun(event)
  }
  onLabelClick(label: string): void {
    if (this.activeLabel === label) {
      // Deactivate the label if it's already active
      this.activeLabel = null;
    } else {
      // Activate the clicked label
      this.activeLabel = label;
    }
  }

  isActive(label: string): boolean {
    return this.activeLabel === label;
  }

  breakpointFun(event?: any): void{
    if(event){
      this.breakpoint = (event.target.innerWidth <= 681) ? 1 : (event.target.innerWidth <= 1000) ? 2 : (event.target.innerWidth <= 1300) ? 3 : 4;
    } else {
      this.breakpoint = (window.innerWidth <= 681) ? 1 : (window.innerWidth <= 1000) ? 2 : (window.innerWidth <= 1300) ? 3 : 4;
    }
  }

  onChangeDangerous(cargos) {
    if (cargos === 'DANGEROUS') {
      this.cargoTab = true;
    } else {
      this.cargoTab = false;
    }
  }


  public _filter1(value: string): MasterVesselOperator[] {
    const filterValue1 = value;
    return this.vesselOperators.filter(vess => vess.name.toLowerCase().indexOf(filterValue1) === 0 ||
      vess.name.toUpperCase().indexOf(filterValue1) === 0 );

  }
  public displayPol(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      return cntr.name;

    }
  }

  public _filter2(value: string): MasterPortsDepot[] {
    const filterValue2 = value;
    return this.masterPortListAll.filter(port => port.name.toLowerCase().indexOf(filterValue2) === 0 ||
      port.name.toUpperCase().indexOf(filterValue2) === 0 );

  }

  public _filter3(value: string): MasterPortsDepot[] {
    const filterValue3 = value;
    return this.masterPortListAll.filter(port => port.name.toLowerCase().indexOf(filterValue3) === 0 ||
      port.name.toUpperCase().indexOf(filterValue3) === 0 );

  }

  public _filter4(value: string): MasterPortsDepot[] {
    const filterValue4 = value;
    return this.masterPortListAll.filter(port => port.name.toLowerCase().indexOf(filterValue4) === 0 ||
      port.name.toUpperCase().indexOf(filterValue4) === 0 );

  }

  public displayfPod(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      return cntr.name;

    }
  }

  public displayPod(cntr: MasterPortsDepot): string {
    if (cntr && cntr.name) {
      return cntr.name;

    }
  }

  public displayVesselOp(cntr: MasterVesselOperator): string {
    if (cntr && cntr.name) {
      return cntr.name;

    }
  }
  exportCollectionTotals() {

    this.totalCollection = this.polThcCollection + this.polBLCollection + this.polChargeHeadCollection + this.polSpecialGearCollection +
      this.fpodLocalsDthcCollection + this.fpodLocalsDoFeeCollection + this.fpodLocalsChargeHeadCollection +
      this.fpodLocalsOtherCollection + this.frieghtFreightCharge + this.freightDGSurcharge + this.freightFlexiSurcharge +
      this.freightOtherCharges + this.exportRevenueFreight + this.exportRevenueOtherChargeHead;
    if (this.costObj.returnType === 'EXPORT FROM POD') {
      this.totalCollection = this.totalCollection + Number(this.expotFpodLthcCollection + this.expotFpodChargeHeadCollection +
        this.expotFpodFpodThcCollection + this.expotFpodOtherLocalsCollection);
    }
    return this.totalCollection;
  }

  exportCostTotals() {
    this.totalCosts = this.polThcCost + this.polBLCost + this.polChargeHeadCost + this.polSpecialGearCost + this.fpodLocalsDthcCost +
      this.fpodLocalsDoFeeCost + this.fpodLocalsChargeHeadcCost + this.fpodLocalsOtherCost +
      (this.leg1SlotQuantity * this.leg1SlotCost) + (this.leg1SlotQuantity * this.leg1LssCost) +
      (this.leg1SlotQuantity * this.leg1EwriCost) + (this.leg1SlotQuantity * this.leg1GriCost);

    if (this.costObj.transhipment === 'TRANSHIPMENT') {
      this.totalCosts = this.totalCosts + ((this.leg1SlotQuantity * this.leg2SlotCost) + (this.leg1SlotQuantity * this.leg2LssCost) +
        (this.leg1SlotQuantity * this.leg2EwriCost) + (this.leg1SlotQuantity * this.leg2GriCost) +
        this.transhipmentCost + this.transhipmentOther1 + this.transhipmentOther2 + this.transhipmentOther3);
    }
    if (this.costObj.returnType === 'EMPTY RETURN') {
      this.totalCosts = this.totalCosts + this.emptyRepoThc + this.emptyRepoChargeHead + this.emptyRepoSlotCost +
        this.emptyRepoLss + this.emptyRepoEwri + this.emptyRepoGri + this.emptyRepoFpodThc;
    }
    if (this.costObj.returnType === 'EXPORT FROM POD') {
      this.totalCosts = this.totalCosts + Number(this.expotFpodLthcCosts + this.expotFpodChargeHeadCosts + this.expotFpodSlotCostCosts +
        this.expotFpodLssEwriCosts + this.expotFpodGriCosts + this.expotFpodFpodThcCosts +
        this.expotFpodOtherLocalsCosts);
    }
    return this.totalCosts;

  }
  netTottalBalance() {
    return this.totalCollection - this.totalCosts;
  }


  fetchPortCostData() {
    this.costcalculatorService.fetchPortCostData(this.costObj)
      .subscribe(data => {
        this.costDetails = data;

        this.polThcCollection = this.costDetails.polThcCollection;
        this.polThcCost = this.costDetails.polThcCost;
        this.polBLCollection = this.costDetails.polBlCollection;
        this.polBLCost = this.costDetails.polBlCost;
        this.polChargeHeadCollection = this.costDetails.polChargeHeadCollection;
        this.polChargeHeadCost = this.costDetails.polChargeHeadCost;
        this.polSpecialGearCollection = this.costDetails.polSpecialGearChargeCollection;
        this.polSpecialGearCost = this.costDetails.polSpecialGearChargeCost;

      },
        () => {
          this.router.navigate(['']);
        });

    this.costcalculatorService.fetchFinalPortCostData(this.costObj)
      .subscribe(data1 => {
        this.finalcostDetails = data1;
        // alert(this.finalcostDetails.fpodThcEmptyCost);
        this.fpodLocalsDthcCollection = this.finalcostDetails.fpodThcCollection;
        this.fpodLocalsDthcCost = this.finalcostDetails.fpodThcCost;
        this.fpodLocalsDoFeeCollection = this.finalcostDetails.fpodDofeeCollection;
        this.fpodLocalsDoFeeCost = this.finalcostDetails.fpodDofeeCost;
        this.fpodLocalsChargeHeadCollection = this.finalcostDetails.fpodChargeHeadCollection;
        this.fpodLocalsChargeHeadcCost = this.finalcostDetails.fpodChargeHeadCost;

        this.emptyRepoThc = this.finalcostDetails.fpodThcEmptyCost;
        this.emptyRepoChargeHead = this.finalcostDetails.polChargeHeadCost;

        this.expotFpodLthcCollection = this.finalcostDetails.fpodThcCollection;
        this.expotFpodLthcCosts = this.finalcostDetails.fpodThcCost;
        this.expotFpodChargeHeadCollection = this.finalcostDetails.fpodChargeHeadCollection;
        this.expotFpodChargeHeadCosts = this.finalcostDetails.fpodChargeHeadCost;

      },
        () => {
          this.router.navigate(['']);
        });

    this.costcalculatorService.fetchSlotCostLegOneData(this.costObj)
      .subscribe(data2 => {
        this.vocostDetails = data2;
        // if (this.costObj.containerSize == 20) {
           this.leg1SlotCost = this.vocostDetails.voSlotCost;
           this.leg1LssCost = this.vocostDetails.voLssCost;
           this.leg1EwriCost = this.vocostDetails.voEwriCost;
           this.leg1GriCost = this.vocostDetails.voGriCost;
        // }
        // if (this.costObj.containerSize == 40) {
        //   this.leg1SlotCost = this.vocostDetails.voSlotCost40;
        //   this.leg1LssCost = this.vocostDetails.voLssCost40;
        //   this.leg1EwriCost = this.vocostDetails.voEwriCost40;
        //   this.leg1GriCost = this.vocostDetails.voGriCost40;
        // }
        // if (this.costObj.containerSize == 45) {
        //   this.leg1SlotCost = this.vocostDetails.voSlotCost45;
        //   this.leg1LssCost = this.vocostDetails.voLssCost45;
        //   this.leg1EwriCost = this.vocostDetails.voEwriCost45;
        //   this.leg1GriCost = this.vocostDetails.voGriCost45;
        // }


      },
        () => {
          this.router.navigate(['']);
        });

    this.costcalculatorService.fetchEmptyRepoFinalPort(this.costObj)
      .subscribe(data8 => {
        this.emptyFpodDetails = data8;
        this.emptyRepoFpodThc = this.emptyFpodDetails.fpodThcCost;

      },
        () => {
          this.router.navigate(['']);
        });

    this.costcalculatorService.fetchEmptyRepoData(this.costObj)
      .subscribe(data3 => {
        this.emptyRepoData = data3;
        // if (this.costObj.containerSize == 20) {
           this.emptyRepoSlotCost = this.leg1SlotQuantity * this.emptyRepoData.voSlotCost;
           this.emptyRepoLss = this.emptyRepoData.voLssCost;
           this.emptyRepoEwri = this.emptyRepoData.voEwriCost;
           this.emptyRepoGri = this.emptyRepoData.voGriCost;
        // }
        // if (this.costObj.containerSize == 40) {
        //   this.emptyRepoSlotCost = this.leg1SlotQuantity * this.emptyRepoData.voSlotCost40;
        //   this.emptyRepoLss = this.emptyRepoData.voLssCost40;
        //   this.emptyRepoEwri = this.emptyRepoData.voEwriCost40;
        //   this.emptyRepoGri = this.emptyRepoData.voGriCost40;
        // }
        // if (this.costObj.containerSize == 45) {
        //   this.emptyRepoSlotCost = this.leg1SlotQuantity * this.emptyRepoData.voSlotCost45;
        //   this.emptyRepoLss = this.emptyRepoData.voLssCost45;
        //   this.emptyRepoEwri = this.emptyRepoData.voEwriCost45;
        //   this.emptyRepoGri = this.emptyRepoData.voGriCost45;
        // }


         if (this.costObj.returnType === 'EXPORT FROM POD') {
        //   if (this.costObj.containerSize == 20) {
             this.expotFpodSlotCostCosts = this.emptyRepoData.voSlotCost;
             this.expotFpodLssEwriCosts = this.emptyRepoData.voLssCost + this.emptyRepoData.voEwriCost;
             this.expotFpodGriCosts = this.emptyRepoData.voGriCost;
        //   }
        //   if (this.costObj.containerSize == 40) {
        //     this.expotFpodSlotCostCosts = this.emptyRepoData.voSlotCost40;
        //     this.expotFpodLssEwriCosts = this.emptyRepoData.voLssCost40 + this.emptyRepoData.voEwriCost40;
        //     this.expotFpodGriCosts = this.emptyRepoData.voGriCost40;
        //   }
        //   if (this.costObj.containerSize == 45) {
        //     this.expotFpodSlotCostCosts = this.emptyRepoData.voSlotCost45;
        //     this.expotFpodLssEwriCosts = this.emptyRepoData.voLssCost45 + this.emptyRepoData.voEwriCost45;
        //     this.expotFpodGriCosts = this.emptyRepoData.voGriCost45;
        //   }

        }

      },
        () => {
          this.router.navigate(['']);
        });

    if (this.costObj.returnType === 'EXPORT FROM POD') {
      this.costcalculatorService.fetchExportFromPodData(this.costObj)
        .subscribe(data5 => {
          this.exportRepoData = data5;
          this.expotFpodFpodThcCollection = this.exportRepoData.fpodThcCollection;
          this.expotFpodFpodThcCosts = this.exportRepoData.fpodThcCost;
          this.expotFpodOtherLocalsCollection = this.exportRepoData.fpodChargeHeadCollection;
          this.expotFpodOtherLocalsCollection = this.exportRepoData.fpodChargeHeadCost;

        },
          () => {
            this.router.navigate(['']);
          });
    }

    if (this.costObj.transhipment === 'TRANSHIPMENT') {

      this.costcalculatorService.fetchSlotCostLegTwoData(this.costObj)
        .subscribe(data4 => {
          this.vocostTwoDetails = data4;
          this.leg2SlotCost = this.vocostTwoDetails.voSlotCost;
          this.leg2LssCost = this.vocostTwoDetails.voLssCost;
          this.leg2EwriCost = this.vocostTwoDetails.voEwriCost;
          this.leg2GriCost = this.vocostTwoDetails.voGriCost;

          this.expotFpodSlotCostCosts = this.vocostTwoDetails.voSlotCost;
          this.expotFpodLssEwriCosts = this.vocostTwoDetails.voLssCost + this.vocostTwoDetails.voEwriCost;
          this.expotFpodGriCosts = this.vocostTwoDetails.voGriCost;

        },
          () => {
            this.router.navigate(['']);
          });


      this.costcalculatorService.fetchTranshipmentPortData(this.costObj)
        .subscribe(data6 => {
          this.transhipDetails = data6;
          this.transhipmentCost = this.transhipDetails.transhipentCost;
          this.transhipmentOther1 = this.transhipDetails.transhipmentDesc1Cost;
          this.transhipmentOther2 = this.transhipDetails.transhipmentDesc2Cost;
          this.transhipmentOther3 = this.transhipDetails.transhipmentDesc3Cost;
          // this.leg2SlotCost = this.transhipDetails.voSlotCost;
          // this.leg2LssCollection = this.transhipDetails.voLssCollection;
          // this.leg2LssCost = this.transhipDetails.voLssCost;
          // this.leg2EwriCollection = this.transhipDetails.voEwriCollection;
          // this.leg2EwriCost = this.transhipDetails.voEwriCost;
          // this.leg2GriCollection = this.transhipDetails.voGriCollection;
          // this.leg2GriCost = this.transhipDetails.voGriCost;

        },
          () => {
            this.router.navigate(['']);
          });

    }
  }

}

export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}
