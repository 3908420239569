import { Candidates } from '../../model/candidate.model';
import { Posts } from '../../model/posts.model';
import { PostsService } from './posts.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { EditpostService } from '../editpost/editpost.service';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {

  postAuthors: Candidates[];
  blogs: Posts[];
  news: Posts[];
  post: Posts;
  constructor(private postService: PostsService , private router: Router , private snackBar: MatSnackBar , 
    private commonService: CommonService) { }

imagePath = ShipStreetProps.AWS_IMAGE_PATH;
sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() {
    this.post = new Posts;
    this.postService.getAllNews()
      .subscribe( data => {
        this.news = data;
      },
      error => {
        this.router.navigate(['']);
        });

    this.postService.getAllBlogs()
      .subscribe( data => {
        this.blogs = data;
      },
      error => {
        this.router.navigate(['']);
        });

    this.postService.getTopPostAuthors()
      .subscribe( data => {
        this.postAuthors = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }
  deletePost(postId) {
    this.postService.deletePost(postId)
      .subscribe(data => {
           for ( var i = 0; i < this.news.length; i++) {
              if (postId === this.news[i].id) {
                  this.news.splice(i , 1);
                  this.snackBar.open('Post Deleted Successfully', '', {
                    duration: 5000
                  });
                  break;
                }
            }
           for ( var j = 0 ; j < this.blogs.length; j++) {
                  if (postId === this.blogs[j].id) {
                     this.blogs.splice(j , 1);
                     this.snackBar.open('Blog Deleted Successfully', '', {
                      duration: 5000
                    });
                     break;
                  }
               }
    });
}
}
