import { Candidates } from '../../model/candidate.model';
import { GlobelsearchService } from '../globelsearch/globelsearch.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';

@Component({
  selector: 'app-globalsearchpeople',
  templateUrl: './globalsearchpeople.component.html',
  styleUrls: ['./globalsearchpeople.component.css']
})
export class GlobalsearchpeopleComponent implements OnInit {
  searchTxt: string;
  candidates: Candidates[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  constructor(private globelsearchService: GlobelsearchService , private route: ActivatedRoute , private router: Router) { }

  ngOnInit() {
    this.searchTxt = this.route.snapshot.params.text;
    this.globelsearchService.searchPeople(this.route.snapshot.params.text)
      .subscribe( data => {
        this.candidates = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  onKeydown(e) {}

}
