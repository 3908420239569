import { Component, OnInit } from '@angular/core';
import { NewtrackerpropertyService } from './newtrackerproperty.service';
import { Router } from '@angular/router';
import { TrackerProperties } from 'src/app/model/trackerproperties.model';

@Component({
  selector: 'app-newtrackerproperty',
  templateUrl: './newtrackerproperty.component.html',
  styleUrls: ['./newtrackerproperty.component.css']
})
export class NewtrackerpropertyComponent implements OnInit {

  trackerProperty: TrackerProperties;


  constructor(private newtrackerpropertyService: NewtrackerpropertyService, private router: Router) { }

  ngOnInit() {
    this.trackerProperty = new TrackerProperties();
  }

  saveTrackerProperty() {
    this.newtrackerpropertyService.saveTrackerProperty(this.trackerProperty)
      .subscribe(data => {
        this.router.navigate(['tracker/trackerproperties']);
      },
        error => {
          this.router.navigate(['']);
        });

  }


}
