import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxBookingContainers } from 'src/app/model/trackboxbookingcontainers.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentblviewService {

  constructor(private http: HttpClient) { }

  private appUrlcontainer = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/blviewdetails.do';
  public getBLviewByID( bookingId: string) {
    return this.http.post<TrackBoxBookingContainers[]>(this.appUrlcontainer, bookingId);
    }
}
