import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
//import { AccountsCompanyBalance } from 'src/app/model/accountscompanybalance.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerbalancesummaryService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'accountreports/getAllCustomerAccountSummaryReports';

  public getAllCustomerAccountSummaryReports() {
  //  return this.http.get<AccountsCompanyBalance[]>(this.appUrl);
  }

}

