import { TaxAgency } from './taxagency.model';
import { Company } from './company.model';

export class Taxes {
    id: number;
    company: Company;
    taxAgency: TaxAgency;
    name: string;
    description: string;
    sales: boolean;
    salesRate: number;
    purchase: boolean;
    purchaseRate: number;
}
