import { Component, OnInit } from '@angular/core';
import { BoxprinciplesService } from './boxprinciples.service';
import { Router } from '@angular/router';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';

@Component({
  selector: 'app-boxprinciples',
  templateUrl: './boxprinciples.component.html',
  styleUrls: ['./boxprinciples.component.css']
})
export class BoxprinciplesComponent implements OnInit {
  boxPricipleList: TrackBoxAgent[];
  p = 1;

  constructor(private boxprinciplesService: BoxprinciplesService, private router: Router) { }

  ngOnInit() {
    this.boxprinciplesService.getAllMyAgents()
      .subscribe(data => {
        this.boxPricipleList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
