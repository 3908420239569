import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';

@Injectable()
export class RigStreetHttpInt {

  constructor(private cookieService: CookieService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    console.log(req.url);
    //     // console.log("this.cookieService.get('API_SESSION_ID')"+this.cookieService.get('API_SESSION_ID'))


    //     console.log('Headers' + req.headers);

    const cookieString = 'API_SESSION_ID =MYSTR; FNAME=DAvis; LANME=Kurian';

    //     //         req = req.clone({headers: req.headers.set('Accept' , 'application/json')});
    //     // req = req.clone({headers: req.headers.set('Access-Control-Requuest-Headers' , '')});
    //     // req = req.clone({headers: req.headers.set('Access-Control-Allow-Orgin' , '*')});

    //     req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    //     req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    //     req = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin', 'http://support.rigstreet.com') });
    //     req = req.clone({ headers: req.headers.set('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT') });
    //     req = req.clone({ headers: req.headers.set('AAccess-Control-Request-Headers', '*') });
    //     req = req.clone({ headers: req.headers.set('Access-Control-Allow-Credentials', 'true') });



    req = req.clone({ headers: req.headers.set('CANDID', this.cookieService.get('CAND_ID')) });
    req = req.clone({ headers: req.headers.set('TMBCOMP', this.cookieService.get('TMB_COMP')) });
    req = req.clone({ headers: req.headers.set('CANDFN', this.cookieService.get('CAND_FN')) });
    req = req.clone({ headers: req.headers.set('CONTCMP', this.cookieService.get('CONT_CMP')) });
    req = req.clone({ headers: req.headers.set('TRDCMPID', this.cookieService.get('TRD_CMP_ID')) });
    req = req.clone({ headers: req.headers.set('CSTCALCCMPI', this.cookieService.get('CST_CALC_CMPI')) });
    //     console.log(this.cookieService.get('CAND_ID'));
    const abca = req.clone({ headers: req.headers.set('Cookie', cookieString) });

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        return event;
      }
    }
      //             (err: any) => {
      //   if (err instanceof HttpErrorResponse) {
      //    // this.getStatusCode(err.status);
      //   }
      // }
    ));

    //  console.log(abca.headers)
    //  return next.handle(req);
  }
}
