import { Component, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxContainerSupplier } from 'src/app/model/trackboxcontainersupplier.model';
import { TrackboxsupplierComponent } from 'src/app/trackmybox/admin/trackboxsupplier/trackboxsupplier.component';
import { NewequipmentcontrolService } from 'src/app/trackmybox/equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TbagenteditsupplierService } from './tbagenteditsupplier.service';

@Component({
  selector: 'app-tbagenteditsupplier',
  templateUrl: './tbagenteditsupplier.component.html',
  styleUrls: ['./tbagenteditsupplier.component.css']
})

  export class TbagenteditsupplierComponent implements OnInit {
    trackboxsuppliers:TrackBoxContainerSupplier;
    myControl3 = new FormControl('', [Validators.required, RequireMatch]);
    filteredOptionscountry: Observable<CountryInfo[]>;
    CountryList: CountryInfo[];
    constructor(private tbagenteditsupplierService: TbagenteditsupplierService, private router: Router,
    private route: ActivatedRoute,private newequipmentcontrolService: NewequipmentcontrolService) { }
  
    ngOnInit() {
      this.trackboxsuppliers = new TrackBoxContainerSupplier();

      this.tbagenteditsupplierService.trackboxsuppliersViewbyId(this.route.snapshot.params.id)
      .subscribe(data => {
        this.trackboxsuppliers = data;
        //alert(JSON.stringify(this.trackboxsuppliers));
   
        },
        error => {
          this.router.navigate(['']);
        });
  
        this.newequipmentcontrolService.viewCountry()
        .subscribe(data => {
          this.CountryList = data;
          this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
            // startWith(''),
            map(val => this._filter4(val))
          );
      
          console.log(this.filteredOptionscountry);
      },
          error => {
            this.router.navigate(['']);
          });
    }
    updateSupplier() {
      this.tbagenteditsupplierService.updatetbsupplier(this.trackboxsuppliers)
        .subscribe(data => {
          this.router.navigate(['/tbagentmode/tbagentsuppliers']);
        },
          error => {
            this.router.navigate(['']);
          });
    }
    public _filter4(value: string): CountryInfo[] {
      const filterValue4 = value;
      return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
        country.name.toLowerCase().indexOf(filterValue4) === 0 ||
        country.name.toUpperCase().indexOf(filterValue4) === 0);
    }
  
    public displayCountry(cntr: CountryInfo): string {
      if (cntr && cntr.name) {
        return cntr.name + '-' + cntr.code2;
  
      }
    }
    
  
  }
  export function RequireMatch(control: AbstractControl) {
    const selection: any = control.value;
  
    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    return null;
  }
  
