import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ContainerLeasingUnit } from 'src/app/model/containerleasingunit.model';
import { ContainerLease } from 'src/app/model/containerlease.model';
import { ContainerleasegateoutService } from './containerleasegateout.service';
export interface DialogData {
  leaseUnitId: number;
 // branch: number;
//  tsheet: number;
}
@Component({
  selector: 'app-containerleasegateout',
  templateUrl: './containerleasegateout.component.html',
  styleUrls: ['./containerleasegateout.component.css']
})
export class ContainerleasegateoutComponent implements OnInit {
  leaseUnits: ContainerLeasingUnit;

  constructor(private containerleasegateoutService: ContainerleasegateoutService,
              private router: Router, public dialogoutRef: MatDialogRef<ContainerleasegateoutComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.leaseUnits = new ContainerLeasingUnit();
    this.leaseUnits.leasing = new ContainerLease();
  }

  updateGateOut() {
    this.leaseUnits.id = this.data.leaseUnitId;
    this.containerleasegateoutService.updateGateOut(this.leaseUnits)
      .subscribe(data => {
        this.dialogoutRef.close(0);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onNoClick(): void {
    this.dialogoutRef.close();
  }

}
