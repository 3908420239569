import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContainerpurchasedetailsService } from './containerpurchasedetails.service';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';

@Component({
  selector: 'app-containerpurchasedetails',
  templateUrl: './containerpurchasedetails.component.html',
  styleUrls: ['./containerpurchasedetails.component.css']
})
export class ContainerpurchasedetailsComponent implements OnInit {

  purchase: ContainerPurchase;
  isOpen: boolean;
  constructor(private containerpurchasedetailsService: ContainerpurchasedetailsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
   // this.isOpen = false;
    this.containerpurchasedetailsService.getSingleContainerPurchase(this.route.snapshot.paramMap.get('id'))
      .subscribe(data => {
        this.purchase = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
