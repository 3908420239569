import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerType } from 'src/app/model/containertype.model';

@Injectable({
  providedIn: 'root'
})
export class NewcontainertypeService {

  private saveUrl = ShipStreetProps.API_PATH + 'containertype/saveContainerType';

  constructor(private http: HttpClient) { }

  public saveContainerType(containertype: ContainerType) {
    return this.http.post<string>(this.saveUrl , containertype);
  }
}
