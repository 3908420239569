import { CandidateMessageMessage } from '../../../model/candidateMessageMessage.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class CandidatemessagedetailsService {

  private appUrl = ShipStreetProps.API_PATH + 'candidateMessageDetails.do';
  private replyUrl = ShipStreetProps.API_PATH + 'candidateMessageReply.do';
  private readStatusUpdateUrl = ShipStreetProps.API_PATH + 'updateReadStatus.do';

  constructor(private http: HttpClient) { }
  public candidateMessages(message: string) {
    return this.http.post<CandidateMessageMessage[]>(this.appUrl , message);
  }
   public updateReadStatus(message: string) {
    return this.http.post<CandidateMessageMessage[]>(this.readStatusUpdateUrl , message);
  }
   public candidateMessageReply(message: CandidateMessageMessage) {// alert(JSON.stringify(message))
    return this.http.post<string>(this.replyUrl , message);
  }
}
