import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Company } from 'src/app/model/company.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Injectable({
  providedIn: 'root'
})
export class DisableconfirmationService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'updateDisableFlag';
  private primUrl = ShipStreetProps.API_PATH + 'makePrimaryCompanys';

  public updateDisableFlag(companyId: number) {
    
    return this.http.post<string>(this.appUrl , companyId);
   
}

 public makePrimaryCompanys(companyId: number) {
 return this.http.post<string>(this.primUrl , companyId);
  }
}
