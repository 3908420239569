import { RecentjobsService } from './recentjobs.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Jobs } from 'src/app/model/jobs.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Component({
  selector: 'app-recentjobs',
  templateUrl: './recentjobs.component.html',
  styleUrls: ['./recentjobs.component.css']
})
export class RecentjobsComponent implements OnInit {
  jobList: Jobs[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  constructor(private recentjobsService: RecentjobsService , private router: Router) { }


  ngOnInit() {
    this.recentjobsService.getSearchJob()
      .subscribe( data => {
        this.jobList = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
