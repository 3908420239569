import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';

@Injectable({
  providedIn: 'root'
})
export class EditcostcalculatorsettingService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'costcalculator/updateCostCalculator.do';

  public updateCostCalculator(costObj: MasterPortCostDetails) {
    return this.http.post<string>(this.appUrl, costObj);
  }


}
