import { TrackBoxAgent } from './../../model/trackboxagent.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { AgentDashboardCountDto } from 'src/app/model/agentdashboardcountDTO.model';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/getAllTbAgentPrinciples.do';
  private appUrlcount = ShipStreetProps.API_PATH + 'trackboxagentdashboard/getAllAgentDashboardCounts.do';
  getAllTbPrinciples(){
    return this.http.get<TrackBoxAgent>(this.appUrl);

  }
  getAllAgentDashboardCounts(principleId:number) {
    // alert (JSON.stringify(principleId));
    return this.http.post<AgentDashboardCountDto>(this.appUrlcount,principleId);
  }
}
