import { Component, OnInit } from '@angular/core';
import { TrackerpropertyService } from './trackerproperty.service';
import { Router } from '@angular/router';
import { TrackerProperties } from 'src/app/model/trackerproperties.model';

@Component({
  selector: 'app-trackerproperty',
  templateUrl: './trackerproperty.component.html',
  styleUrls: ['./trackerproperty.component.css']
})
export class TrackerpropertyComponent implements OnInit {

  p: number;
  trackerPropertiesList: TrackerProperties[];
  constructor(private trackerpropertyService: TrackerpropertyService, private router: Router) { }

  ngOnInit() {
    this.trackerpropertyService.getAllTrackerPropertiesList()
      .subscribe(data => {
        this.trackerPropertiesList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
