import { Candidates } from './candidate.model';
import { Company } from './company.model';
import { Jobs } from './jobs.model';
export class CandidateMessage {
    id: number;
    candidate: Candidates;
    sender: Candidates;
    lastSender: Candidates;
    company: Company;
    viewer: string;
    message: string;
    createOn: Date;
    readStatus: boolean;
    jobs: Jobs;
    messageTime: string;
}
