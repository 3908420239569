import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Company } from 'src/app/model/company.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptioncheckoutService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/companyenrollment/enrollcompany.do';

  public enrollcompany(company: Company) {
    return this.http.post<string>(this.appUrl , company);
  }
}
