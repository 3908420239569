import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountsPaymentDTO } from 'src/app/model/accountspaymentDTO.model';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ContainerpurchaseexpensedetailsService {

  constructor(private http: HttpClient) { }

  private listUrl = ShipStreetProps.API_PATH + 'containerpurchase/findByPurchaseBalanceId.do';
  private payUrl = ShipStreetProps.API_PATH + 'containerpurchase/containerpurchasemakepayment';

  public ContainerPurchaseexpenseDetails(supplierId: string) {
    return this.http.post<ContainerPurchase[]>(this.listUrl, supplierId);
  }
  public containerpurchasemakepayment(ccountsPaymentDTO: AccountsPaymentDTO) {
    return this.http.post<ContainerPurchase[]>(this.payUrl, ccountsPaymentDTO);
  }
}
