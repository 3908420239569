import { CandidateEducation } from '../../model/candidateEducation.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  private appUrl = ShipStreetProps.API_PATH + 'candidateEducation.do';
  private saveUrl = ShipStreetProps.API_PATH + 'newEducation.do';
  private updateUrl = ShipStreetProps.API_PATH + 'updateCandidateEducation.do';
  private deleteUrl = ShipStreetProps.API_PATH + 'deleteCandidateEducation.do';

  constructor(private http: HttpClient) { }

  public candidateEducation() {
    return this.http.get<CandidateEducation[]>(this.appUrl);
  }

  public saveType(education: CandidateEducation) {
    return this.http.post<CandidateEducation[]>(this.saveUrl, education);
  }

  public updateCandidateEducation(education: CandidateEducation) {
    return this.http.post<CandidateEducation[]>(this.updateUrl, education);
  }
  public deleteEducation(education: CandidateEducation) {
    return this.http.post<CandidateEducation[]>(this.deleteUrl, education);
  }
}
