import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Pipeline } from 'src/app/model/pipeline.model';

@Injectable({
  providedIn: 'root'
})
export class NewmypipelineService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/pipeline/addnewpipeline.do';
  private getUrl = ShipStreetProps.API_PATH + '/pipeline/getnewpipeline.do';
  private updateUrl = ShipStreetProps.API_PATH + '/pipeline/changestatus.do';

  public getnewpipeline(stat: string) {
    return this.http.post<Pipeline[]>(this.getUrl , stat);
  }

  public addnewpipeline(pipeline: Pipeline) {
    return this.http.post<string>(this.appUrl , pipeline);
  }

  public changestatus(pipeline: Pipeline) {
    return this.http.post<string>(this.updateUrl , pipeline);
  }
}
