import { TbagentequipmentlistService } from './tbagentequipmentlist.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { MyBoxSearchExport } from 'src/app/model/mybox.export.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as FileSaver from 'file-saver';
import { AgentTypeListDTO } from 'src/app/model/agenttypelistDTO.model';
@Component({
  selector: 'app-tbagentequipmentlist',
  templateUrl: './tbagentequipmentlist.component.html',
  styleUrls: ['./tbagentequipmentlist.component.css']
})
export class TbagentequipmentlistComponent implements OnInit {

  tbcontainerList: TrackBoxContainer[];
  typeList:AgentTypeListDTO;
  p: number;
  pdfContent: string;
  tableContent = [];
  rowContent = [];
  printList: MyBoxSearchExport[];
  myBox: MyBoxSearchExport;
  fileName = 'ExcelSheet.xlsx';
  panelOpenState: boolean;
  constructor( private tbagentequipmentlistService: TbagentequipmentlistService, private router: Router,
    private route: ActivatedRoute) { }

    exportAsXLSX(): void {
      // this.exportAsExcelFile(this.tbcontainerList, 'sample');
      // printList: [];

      this.printList = new Array();

      // tslint:disable-next-line: prefer-for-of
      for ( let i = 0; i < this.tbcontainerList.length; i = i + 1 ) {

        this.myBox = new MyBoxSearchExport();
        this.myBox.containerNumber = this.tbcontainerList[ i ].container.containerNumber;
        this.myBox.typeCode = this.tbcontainerList[ i ].container.equipmentType.typeCode;
        this.myBox.containerStatus = this.tbcontainerList[ i ].containerStatus.name;
        // this.myBox.gateIn = this.tbcontainerList[i].gateIn;
        // this.myBox.yom =this.tbcontainerList[i].container.manufacturingDate;
        this.myBox.country = this.tbcontainerList[ i ].country.name;
        this.myBox.port = this.tbcontainerList[ i ].port.name;
        this.printList.push( this.myBox );


        // this.printList.push({ this.tbcontainerList[i].gateIn, this.tbcontainerList[i].gateOut})
      }
      this.exportAsExcelFile( this.printList, 'sample' );

    }
    public exportAsExcelFile( json: any[], excelFileName: string ): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet( json );
      const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: [ 'data' ] };
      const excelBuffer: any = XLSX.write( workbook, { bookType: 'xlsx', type: 'array' } );
      this.saveAsExcelFile( excelBuffer, excelFileName );
    }
    private saveAsExcelFile( buffer: any, fileName: string ): void {
      const data: Blob = new Blob( [ buffer ], { type: EXCEL_TYPE } );
      FileSaver.saveAs( data, fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION );
    }
    generatePDF() {


      this.tableContent.push( [ { text: 'Container Number', bold: true }, { text: 'ISO Code', bold: true },
      { text: 'Country ', bold: true, color: 'red' }, { text: 'Purchase status', bold: true, color: 'red' },
      { text: 'current location', bold: true, color: 'red' }, { text: 'container status', bold: true, color: 'red' } ] );

      this.pdfContent = '<table ><tr>td>Container No</td><td>Code</td></tr>';

      this.printList = new Array();
      this.myBox = new MyBoxSearchExport();
      this.myBox.containerNumber = 'Container Number';
      this.myBox.containerStatus = 'Container status';

      this.myBox.country = 'Country';
      this.myBox.port = 'Current location';

      this.myBox.typeCode = 'ISO Code';
      this.printList.push( this.myBox );



      // this.printList.push({p.container.containerNumber, p.container.equipmentType.typeCode})
      // tslint:disable-next-line: prefer-for-of
      for ( let i = 0; i < this.tbcontainerList.length; i = i + 1 ) {

        // this.pdfContent = this.pdfContent+this.searchResultList[i].container.containerNumber;
        // this.pdfContent = this.pdfContent+this.searchResultList[i].containerStatus.name;
        // this.pdfContent = this.pdfContent+this.searchResultList[i].container.equipmentType.typeCode;
        // this.pdfContent= this.pdfContent+"\n";
        this.myBox = new MyBoxSearchExport();
        this.myBox.containerNumber = this.tbcontainerList[ i ].container.containerNumber;
        this.myBox.typeCode = this.tbcontainerList[ i ].container.equipmentType.typeCode;
        this.myBox.country = this.tbcontainerList[ i ].country.name;
        // this.myBox.carrier = this.containerList[i].carrier.name;
        this.myBox.port = this.tbcontainerList[ i ].port.name;

        // this.myBox.gateIn = this.tbcontainerList[i].gateIn;
        // this.myBox.yom =this.tbcontainerList[i].container.manufacturingDate;
        this.myBox.containerStatus = this.tbcontainerList[ i ].containerStatus.name;
        this.printList.push( this.myBox );
        //  alert(this.printList);
        // content: 'Sample PDF generated with Angular and PDFMake for C#Corner Blog'
      }
      this.tableContent.push( this.rowContent );
      const docDefinition1 = {

        content: '' + this.pdfContent

        //   content: this.searchResultList
      };

      console.log( this.printList );

      const docDefinition = {
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [ 0, 15, 0, 15 ]
          }
        },
        content: [
          {
            text: 'Equipment ',
            style: 'sectionHeader'
          },




          {
            table: {
              headerRows: 1,
              widths: [ 'auto', 'auto', 'auto', 'auto', 'auto' ],
              body: // this.tableContent
                this.printList.map( p => ( [ p.containerNumber, p.typeCode, p.port, p.containerStatus, p.country ] ) )
            }
          }
        ]



      };


      pdfMake.createPdf( docDefinition ).open();

      // let docDefinition = {

      // content:  this.searchResultList

      //



      //   pdfMake.createPdf(docDefinition).open();

    }

  ngOnInit() {
    this.typeList = new AgentTypeListDTO();
    this.typeList.type = this.route.snapshot.params.text;
    this.typeList.principleCompany =this.route.snapshot.params.id;
    this.tbagentequipmentlistService.getEquipmentcontrolTypeList( this.typeList )
    .subscribe( data => {
      this.tbcontainerList = data;
      // alert(JSON.stringify(this.tbcontainerList));
    },
      error => {
        this.router.navigate( [ '' ] );
      } );
}
  }




