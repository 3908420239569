import { TrackBoxBookingContainers } from './../../../model/trackboxbookingcontainers.model';
import { TrackBoxBookingContainersDTO } from './../../../model/trackboxbookingcontainersDTO.model';
import { TrackBoxContainer } from './../../../model/trackboxcontainer.model';
import { TrackBoxBooking } from './../../../model/trackboxbooking.model';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingcontainersaddService } from './bookingcontainersadd.service';
import { Component, OnInit } from '@angular/core';
import { MasterVoyage } from 'src/app/model/mastervoyage.model';
import { CompanyVoyage } from 'src/app/model/companyvoyage.model';

@Component({
  selector: 'app-bookingcontainersadd',
  templateUrl: './bookingcontainersadd.component.html',
  styleUrls: ['./bookingcontainersadd.component.css']
})

export class BookingcontainersaddComponent implements OnInit {
  bookingObj: TrackBoxBooking;
  // booking: TrackBoxBooking;
  voyage: MasterVoyage;
  containerbooking: TrackBoxContainer;
  containersbooking: TrackBoxContainer[];
  containerBooking: TrackBoxBookingContainersDTO;
  trackboxcontainerbooking: TrackBoxBookingContainers;
  searchText: string;
  constructor(private bookingcontainersaddService: BookingcontainersaddService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.trackboxcontainerbooking = new TrackBoxBookingContainers();
    this.bookingObj = new TrackBoxBooking();
    //  this.containerbooking.container = new Containers();
    //  this.containerbooking.container.equipmentType = new ContainerType();

    this.bookingcontainersaddService.getContainers(this.route.snapshot.params.id)
    .subscribe(data => {
       this.containersbooking = data;
        // alert(JSON.stringify(this.containerbooking));
        },
          error => {
            this.router.navigate(['']);
          });

    this.bookingcontainersaddService.getBookingsByID(this.route.snapshot.params.id)
      .subscribe(data1 => {
        this.bookingObj = data1;
      },
        error => {
          this.router.navigate(['']);
        });
  }
  addBookingContainers() {

    //  this.containerBooking.bookingId = this.bookingObj;
    //  this.containerBooking.containers = this.containersbooking;
    this.containerBooking = { bookingId: this.bookingObj, containers: this.containersbooking };
    this.bookingcontainersaddService.updateBookingEquipment(this.containerBooking)
      .subscribe(data => {
        this.router.navigate(['trackmybox/bookings']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

  search() {
    if (this.searchText !== '') {
      this.containersbooking = this.containersbooking.filter(res => {
        return res.container.containerNumber.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    } else if (this.searchText === '') {
      this.ngOnInit();
    } 
  }

}
