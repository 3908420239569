import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalcvesseloperatorService } from './calcvesseloperator.service';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';

@Component({
  selector: 'app-calcvesseloperator',
  templateUrl: './calcvesseloperator.component.html',
  styleUrls: ['./calcvesseloperator.component.css']
})
export class CalcvesseloperatorComponent implements OnInit {

  searchText: string;
  p = 1;
  vesseloperators: MasterVesselOperator[];
  vesseloperatorsAll: MasterVesselOperator[];

  constructor(private calcvesseloperatorService: CalcvesseloperatorService, private router: Router) { }
  panelOpenState: boolean;


  ngOnInit() {

    this.calcvesseloperatorService.findAllMasterVesselOperatorById()
      .subscribe(data => {
        this.vesseloperatorsAll = data;
        this.vesseloperators = this.vesseloperatorsAll;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  searchbar() {
    if (this.searchText !== '') {
      this.vesseloperators = this.vesseloperatorsAll.filter(res => {
        return (res.name !== null && res.name.toLowerCase().match(this.searchText.toLocaleLowerCase()) || 
        res.legalName !== null && res.legalName.toLowerCase().match(this.searchText.toLocaleLowerCase()) ||
        res.country !== null && res.country.name.toLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.vesseloperators = this.vesseloperatorsAll;
    }
  }

}
