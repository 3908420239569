import { InviteFriends } from '../../model/inviteFriends.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class InvitefriendService {

  private appUrl = ShipStreetProps.API_PATH + 'inviteIndividualFriends.do';

  constructor(private http: HttpClient) { }

   public saveType(invite: InviteFriends) {
    return this.http.post<string>(this.appUrl, invite);
  }
}
