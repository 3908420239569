import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPurchase } from '../model/containerpurchase.model';
import { CountryInfo } from '../model/countryInfo.model';
import { ShipStreetProps } from '../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ContainersearchService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'containerpurchase/containersSearchByCountry.do';

  public getContainerDetails(tbcontainers: string) {
    return this.http.post<ContainerPurchase[]>(this.appUrl, tbcontainers);
  }
}
