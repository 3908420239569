import { CompanyVessels } from './../../../model/companyvessels.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyvesselsService } from './companyvessels.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companyvessels',
  templateUrl: './companyvessels.component.html',
  styleUrls: ['./companyvessels.component.css']
})
export class CompanyvesselsComponent implements OnInit {
  companyvesselList: CompanyVessels[];
  p: number;
  panelOpenState: boolean;
  constructor(private companyvesselsService: CompanyvesselsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.companyvesselsService.getAllMyCompanyVessels()
      .subscribe(data => {
        this.companyvesselList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
