import { Company } from '../../model/company.model';
import { GlobelsearchService } from '../globelsearch/globelsearch.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';

@Component({
  selector: 'app-globalsearchcompany',
  templateUrl: './globalsearchcompany.component.html',
  styleUrls: ['./globalsearchcompany.component.css']
})
export class GlobalsearchcompanyComponent implements OnInit {
  companies: Company[];
  searchTxt: string;

  constructor(private globelsearchService: GlobelsearchService , private route: ActivatedRoute , private router: Router) { }
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;

  ngOnInit() {
    this.searchTxt = this.route.snapshot.params.text;

    this.globelsearchService.searchCompany(this.route.snapshot.params.text)
      .subscribe( data => {
        // console.log(JSON.stringify(data))
        this.companies = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

  onKeydown(e) {}
}
