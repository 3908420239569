import { HelpLayoutComponent } from '../_layouts/help-layout/help-layout.component';
import { RouterModule, Routes } from '@angular/router';

import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { Profile20Component } from './profile20/profile20.component';
import { Profile21Component } from './profile21/profile21.component';
import { Profile22Component } from './profile22/profile22.component';
import { Profile23Component } from './profile23/profile23.component';
import { Company30Component } from './company30/company30.component';
import { Marketplace40Component } from './marketplace40/marketplace40.component';
import { Recruiter70Component } from './recruiter70/recruiter70.component';
import { Recruiter71Component } from './recruiter71/recruiter71.component';
import { Recruiter72Component } from './recruiter72/recruiter72.component';
import { Recruiter73Component } from './recruiter73/recruiter73.component';
import { Recruiter74Component } from './recruiter74/recruiter74.component';
import { Network90Component } from './network90/network90.component';
import { Jobs110Component } from './jobs110/jobs110.component';
import { Jobs111Component } from './jobs111/jobs111.component';
import { Jobs112Component } from './jobs112/jobs112.component';
import { Jobs113Component } from './jobs113/jobs113.component';
import { Jobs114Component } from './jobs114/jobs114.component';
import { Candidate130Component } from './candidate130/candidate130.component';
import { Candidate131Component } from './candidate131/candidate131.component';
import { Accounts150Component } from './accounts150/accounts150.component';
import { Accounts151Component } from './accounts151/accounts151.component';
import { Security101Component } from './security101/security101.component';
import { Feed101Component } from './feed101/feed101.component';
import { HelpcompaniesComponent } from './helpcompanies/helpcompanies.component';
import { KnowledgebaseComponent } from './knowledgebase/knowledgebase.component';
import { TypesofcontainersComponent } from './typesofcontainers/typesofcontainers.component';
import { AgentbookinghelpComponent } from './trackmybox/agent/agentbookinghelp/agentbookinghelp.component';


export const helpRoutes: Routes = [
 { path: 'helps',
        component: HelpLayoutComponent,
        children: [
          { path: '' , component: HelpComponent},
          { path: 'home' , component: HelpComponent},
          { path: 'profile20' , component: Profile20Component},
          { path: 'profile21' , component: Profile21Component},
          { path: 'profile22' , component: Profile22Component},
          { path: 'profile23' , component: Profile23Component},
          { path: 'company30' , component: Company30Component},
          { path: 'marketplace40' , component: Marketplace40Component},
          { path: 'feeds101' , component: Feed101Component},
          { path: 'recruiter70' , component: Recruiter70Component},
          { path: 'recruiter71' , component: Recruiter71Component},
          { path: 'recruiter72' , component: Recruiter72Component},
          { path: 'recruiter73' , component: Recruiter73Component},
          { path: 'recruiter74' , component: Recruiter74Component},
          { path: 'network90' , component: Network90Component},
          { path: 'jobs110' , component: Jobs110Component},
          { path: 'jobs111' , component: Jobs111Component},
          { path: 'jobs112' , component: Jobs112Component},
          { path: 'jobs113' , component: Jobs113Component},
          { path: 'jobs114' , component: Jobs114Component},
          { path: 'candidate130' , component: Candidate130Component},
          { path: 'candidate131' , component: Candidate131Component},
          { path: 'accounts150' , component: Accounts150Component},
          { path: 'accounts151' , component: Accounts151Component},
          { path: 'security101' , component: Security101Component},
          { path: 'helpcompanies' , component: HelpcompaniesComponent},
          { path: 'knowledgebase' , component: KnowledgebaseComponent},
          { path: 'typesofcontainers' , component: TypesofcontainersComponent},
          { path: 'agentbookinghelp' , component: AgentbookinghelpComponent},
        ]
  }
  ];
