import { Company } from './company.model';

export class ContainerCustomer {
id: number;
company: Company;
name: string;
code: string;
address: string;
postBox: string;
phone: string;
fax: string;
contactName: string;
contactPhone: string;
contactEmail: string;
emailInvoice: string;
emailReporting: string;
emailRepair: string;
billingAttn: string;
billingAddress: string;
billingPhone: string;
billingCurrency: string;
billingDuration: string;
tradeLicenseExpiry: Date;
vatTrnNo: number;
freeDays: number;
}
