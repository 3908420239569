import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uploadcontainerpurchase',
  templateUrl: './uploadcontainerpurchase.component.html',
  styleUrls: ['./uploadcontainerpurchase.component.css']
})
export class UploadcontainerpurchaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
