import { Candidates } from '../../model/candidate.model';
import { ConnectionList } from '../../model/connectionList.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CandidateConnect } from 'src/app/model/candidateConnect.model';

@Injectable({
  providedIn: 'root'
})
export class PeopleyoumayknowService {

  constructor(private http: HttpClient) { }

 // private appUrl = ShipStreetProps.API_PATH + 'connectionCandidate.do';
  private connectUrl = ShipStreetProps.API_PATH + 'sendConnectionRequest.do';
  private cancelUrl = ShipStreetProps.API_PATH + 'withdrawConnectionRequest.do';
  private removeUrl = ShipStreetProps.API_PATH + 'removeFromConnectionRequest.do';
  private requestedConnectionsUrl = ShipStreetProps.API_PATH + 'requestedConnections.do';  //personalController //
  private acceptConnectionUrl = ShipStreetProps.API_PATH + 'acceptConnection.do';
  private rejectConnectionUrl = ShipStreetProps.API_PATH + 'rejectConnection.do';
  private popPYMKUrl = ShipStreetProps.API_PATH + 'populatePeopleYouKnow.do';

  // public getAllPeopleYouMayKnow() {
  //   return this.http.get<ConnectionList[]>(this.appUrl);
  // }
   public candidateconnect(candidate: ConnectionList) {
    return this.http.post<ConnectionList[]>(this.connectUrl , candidate);
  }
  public withdrawRequest(candidate: ConnectionList) {
    return this.http.post<ConnectionList>(this.cancelUrl , candidate);
  }
  public removeFromConnectionList(candidate: ConnectionList) {
    return this.http.post<ConnectionList>(this.removeUrl , candidate);
  }
  public requestedConnections() {
    return this.http.get<CandidateConnect[]>(this.requestedConnectionsUrl);
  }
  public acceptConnection(candidate: string) {
     return this.http.post<string>(this.acceptConnectionUrl , candidate);
  }
  public rejectConnection(candidate: string) {
    return this.http.post<ConnectionList[]>(this.rejectConnectionUrl , candidate);
  }
  public populatePeopleYouMayKnow() {
    return this.http.get<ConnectionList[]>(this.popPYMKUrl);
  }
}
