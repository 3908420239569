import { Candidates } from '../../model/candidate.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class HelpHeaderService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'sessionCandidate.do';

  public sessionCandidate() {
    return this.http.get<Candidates>(this.appUrl);
  }
}
