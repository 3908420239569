import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepotcontainersService } from './depotcontainers.service';
import { CmsGatein } from 'src/app/model/cmsgatein.model';

@Component({
  selector: 'app-depotcontainers',
  templateUrl: './depotcontainers.component.html',
  styleUrls: ['./depotcontainers.component.css']
})
export class DepotcontainersComponent implements OnInit {

  p = 1;
  cmsGateinList: CmsGatein[];

  constructor(private depotcontainersService: DepotcontainersService, private router: Router) { }

  ngOnInit() {
    this.depotcontainersService.findAllCmsGateinUnits()
      .subscribe(data => {
        this.cmsGateinList = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
