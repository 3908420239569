import { Routes } from '@angular/router';
import { AccountsdashboardComponent } from './accountsdashboard/accountsdashboard.component';
import { SalesComponent } from './sales/sales.component';
import { SalesinvoiceComponent } from './sales/salesinvoice/salesinvoice.component';
import { CustomersComponent } from './sales/customers/customers.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { SuppliersComponent } from './expenses/suppliers/suppliers.component';
import { NewinvoiceComponent } from './sales/salesinvoice/newinvoice/newinvoice.component';
import { NewexpensesComponent } from './expenses/newexpenses/newexpenses.component';
import { NewsuppliersComponent } from './expenses/suppliers/newsuppliers/newsuppliers.component';
import { ProductservicesComponent } from './sales/productservices/productservices.component';
import { NewproductservicesComponent } from './sales/productservices/newproductservices/newproductservices.component';
// tslint:disable-next-line:max-line-length
import { NewproductservicedetailsComponent } from './sales/productservices/newproductservices/newproductservicedetails/newproductservicedetails.component';
import { NewaccountscategoryComponent } from './sales/productservices/accountscategory/newaccountscategory/newaccountscategory.component';
import { AccountscategoryComponent } from './sales/productservices/accountscategory/accountscategory.component';
import { AccountingComponent } from './accounting/accounting.component';
import { AccountstypesComponent } from './accounting/accountstypes/accountstypes.component';
import { AccountsdetailstypeComponent } from './accounting/accountsdetailstype/accountsdetailstype.component';
import { NewaccountstypeComponent } from './accounting/accountstypes/newaccountstype/newaccountstype.component';
import { NewaccountsdetailstypeComponent } from './accounting/accountsdetailstype/newaccountsdetailstype/newaccountsdetailstype.component';
import { NewcustomerComponent } from './sales/customers/newcustomer/newcustomer.component';
import { EditcustomerComponent } from './sales/customers/editcustomer/editcustomer.component';
import { CustomerbalancesummaryComponent } from './reports/customerbalancesummary/customerbalancesummary.component';
import { AccountsreceivesalespaymentComponent } from './sales/salesinvoice/accountsreceivesalespayment/accountsreceivesalespayment.component';
import { ConsigneepaymentapprovalsComponent } from './sales/consigneepaymentapprovals/consigneepaymentapprovals.component';
import { ConsigneepaymentdetailsComponent } from './sales/consigneepaymentapprovals/consigneepaymentdetails/consigneepaymentdetails.component';
import { EditaccountscategoryComponent } from './sales/productservices/accountscategory/editaccountscategory/editaccountscategory.component';
import { EditproductservicesComponent } from './sales/productservices/editproductservices/editproductservices.component';
import { ConsigneegateinpaymentapprovalsComponent } from './sales/consigneegateinpaymentapprovals/consigneegateinpaymentapprovals.component';
import { AccountslayoutComponent } from '../_layouts/accountslayout/accountslayout.component';

export const accRoutes: Routes = [
 { path: 'accounts',
        component: AccountslayoutComponent,
        children: [
           { path: '' , component: AccountsdashboardComponent},
            { path: 'accountsdashboard' , component: AccountsdashboardComponent},
            { path: 'sales' , component: SalesComponent},
            { path: 'salesinvoice' , component: SalesinvoiceComponent},
            { path: 'newinvoice' , component: NewinvoiceComponent},
            { path: 'customers' , component: CustomersComponent},
            { path: 'newcustomer' , component: NewcustomerComponent},
            { path: 'editcustomer/:id' , component: EditcustomerComponent},
            { path: 'expenses' , component: ExpensesComponent},
            { path: 'newexpenses' , component: NewexpensesComponent},
            { path: 'suppliers' , component: SuppliersComponent},
            { path: 'newsupplier' , component: NewsuppliersComponent},
            { path: 'salesproductsnservices' , component: ProductservicesComponent},
            { path: 'newsalesproductsnservices' , component: NewproductservicesComponent},
            { path: 'editsalesproductsnservices/:id' , component: EditproductservicesComponent},
            { path: 'newsalesproductsnservicedetails' , component: NewproductservicedetailsComponent},
            { path: 'categorylist' , component: AccountscategoryComponent},
            { path: 'newaccountscategory' , component: NewaccountscategoryComponent},
            { path: 'editaccountscategory/:id' , component: EditaccountscategoryComponent},
            { path: 'accountings' , component: AccountingComponent},
            { path: 'accounttypelist' , component: AccountstypesComponent},
            { path: 'newaccounttype' , component: NewaccountstypeComponent},
            { path: 'accountdetailstypelist' , component: AccountsdetailstypeComponent},
            { path: 'newaccountdetailstype' , component: NewaccountsdetailstypeComponent},
            { path: 'newaccountdetailstype' , component: NewaccountsdetailstypeComponent},
            { path: 'customerbalancesummaryreports' , component: CustomerbalancesummaryComponent},
            { path: 'accountsreceivepayments/:id' , component: AccountsreceivesalespaymentComponent},
            { path: 'consigneepaymentapprovals' , component: ConsigneepaymentapprovalsComponent},
            { path: 'consigneepaymentdetails/:id' , component: ConsigneepaymentdetailsComponent},
            { path: 'consigneegateinpaymentapprovals' , component: ConsigneegateinpaymentapprovalsComponent},
        ]
  }
  ];
