import { Marketplace } from '../../model/marketplace.model';
import { ShipStreetProps } from '../../utils/rs.constants';
import { MarketplaceService } from './marketplace.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css']
})


export class MarketplaceComponent  implements OnInit {
  marketplaceList: Marketplace[];
  filteredMarketplaceList: Marketplace[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  p: number;
  b: number;
  skipicon: any;
  constructor(private marketplaceService: MarketplaceService, private router: Router) {
   }

  private _searchBox: string;

  get searchBox() {
   return this._searchBox;
  }

  set searchBox(value: string) {
    this._searchBox = value;
    this.filteredMarketplaceList = this.filterMarketplace(value);
//    console.log('Value :' + value);
  //  console.log(this.filteredMarketplaceList);
  }

  filterMarketplace(searchVal: String) {
   return this.marketplaceList.filter(marketPlace =>
    marketPlace.itemName.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1 );
  }

  ngOnInit() {
     this.marketplaceService.getAllMarketplaceItems()
      .subscribe( data => {
        this.marketplaceList = data;
        this.filteredMarketplaceList = this.marketplaceList;
   //     console.log(this.filteredMarketplaceList);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  customOptions: OwlOptions = {
    autoplay:true,
    autoplayTimeout:6000,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 100,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  
  addtocart(itemId: string) {
    this.marketplaceService.addtoshoppingcart(itemId)
      .subscribe( data => {
        this.router.navigate(['/m/marketplaceorder']);
      },
      error => {
        this.router.navigate(['']);
        });
  }

  viewmarketdetails(itemId) {
    this.marketplaceService.viewmarketdetails(itemId)
      .subscribe( data => {
        this.router.navigate(['/m/viewmarketdetails' , itemId]);
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
