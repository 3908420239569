import { CandidateCertificate } from '../../../model/candidateCertificate.model';
import { CandidatecertificateService } from './candidatecertificate.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-candidatecertificate',
  templateUrl: './candidatecertificate.component.html',
  styleUrls: ['./candidatecertificate.component.css']
})
export class CandidatecertificateComponent implements OnInit {
  candidate: string;
  candidateCertificates: CandidateCertificate[];

  constructor(private candidatecertificateService: CandidatecertificateService , private route: ActivatedRoute ,
              private router: Router , private commonService: CommonService) { }
sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() {
      // if (this.sessionCandidate === null && this.sessionCandidate.id === null && this.sessionCandidate.id === 0) {
      //     this.router.navigate(['']);
      // }

      // const id = this.route.snapshot.paramMap.get('id');
      // this.candidate = id;
      // this.candidatecertificateService.candidateCertificates(this.route.snapshot.paramMap.get('id'))
      // .subscribe( data => {
      //   this.candidateCertificates = data;
//      },
//      error => {
//        this.router.navigate(['']);
  //      });
  }



  sendMessage() {

  }

}
