import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { BookingBLNumber } from 'src/app/model/bookingblnumber.model';

@Injectable({
  providedIn: 'root'
})
export class BlsearchresultService {

  constructor(private http: HttpClient) { }

  private appUrlcontainer = ShipStreetProps.API_PATH + 'TrackboxBookingContainer/getByBLNumber.do';

  public getByBLNumber(blnum: string) {
    return this.http.post<BookingBLNumber>(this.appUrlcontainer, blnum);
  }
}
