import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerPurchase } from 'src/app/model/containerpurchase.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ContainerbycountrydetailsService {

  constructor(private http: HttpClient) { }
  private appUrl = ShipStreetProps.API_PATH + 'containerpurchase/containersSearchByCountryDetails.do';
  private comUrl = ShipStreetProps.API_PATH + 'containerpurchase/completecontainerPurchase.do';

  public getContainerDetails(tbcontainers: string) {
    return this.http.post<ContainerPurchase>(this.appUrl, tbcontainers);
  }

  public completecontainerPurchase(containerdetails: ContainerPurchase) {
    return this.http.post<ContainerPurchase>(this.comUrl, containerdetails);
  }
}
