import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';

@Injectable({
  providedIn: 'root'
})
export class CostcalculatorsettingsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'costcalculator/saveCalculatorCost.do';

  public saveCalculatorCost(costObj: MasterPortCostDetails) {
    return this.http.post<string>(this.appUrl, costObj);
  }
}
