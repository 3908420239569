import { Component, OnInit } from '@angular/core';
//import { NewexpensesService } from './newexpenses.service';
import { Router } from '@angular/router';
import { Suppliers } from 'src/app/model/suppliers.model';
import { AccountsPaymentAccount } from 'src/app/model/accountspaymentaccount.model';
import { AccountsPaymentMode } from 'src/app/model/accountspaymentmode.model';
import { NewexpensesService } from './newexpenses.service';


interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-newexpenses',
  templateUrl: './newexpenses.component.html',
  styleUrls: ['./newexpenses.component.css']
})
export class NewexpensesComponent implements OnInit {
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' }
  ];
  supplierList: Suppliers[];
  paymentaccount: AccountsPaymentAccount[];
  paymentmode: AccountsPaymentMode[];

  // 
  constructor(private newexpensesService: NewexpensesService, private router: Router) { }

  ngOnInit() {
    this.newexpensesService.getAllSuppliers()
      .subscribe(data => {
        this.supplierList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    // this.newexpensesService.getAllPaymentAccounts()
    //   .subscribe(data => {
    //     this.paymentaccount = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });

    //     this.newexpensesService.getAllPaymentModes()
    //   .subscribe(data => {
    //     this.paymentmode = data;
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });
  }

}
