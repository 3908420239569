import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EnrolltrackboxcompanyService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'enrolltrackmybox.do';

  public enrolltrackmybox(companyId: number) {
    return this.http.post<string>(this.appUrl , companyId);
  }
}
