import { Component, OnInit } from '@angular/core';
import { Suppliers } from 'src/app/model/suppliers.model';
import { NewsuppliersService } from './newsuppliers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newsuppliers',
  templateUrl: './newsuppliers.component.html',
  styleUrls: ['./newsuppliers.component.css']
})
export class NewsuppliersComponent implements OnInit {
  supplier: Suppliers;

  constructor(private newaccsuppliersService: NewsuppliersService, private router: Router) { }

  ngOnInit() {
    this.supplier = new Suppliers();
  }

  savesupplier() {
    this.newaccsuppliersService.savesupplier(this.supplier)
      .subscribe(data => {
        this.router.navigate(['/accounts/suppliers']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
