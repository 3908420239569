import { Component, OnInit } from '@angular/core';
import { TrackerEmployees } from 'src/app/model/trackeremployees.model';
import { TrackerDepartment } from 'src/app/model/trackerdepartment.model';
import { TrackerDesignation } from 'src/app/model/trackerdesignation.module';
import { EdittrackeremployeeService } from './edittrackeremployee.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackerdesignationService } from '../../master/trackerdesignation/trackerdesignation.service';
import { TrackerdepartmentService } from '../../master/trackerdepartment/trackerdepartment.service';

@Component({
  selector: 'app-edittrackeremployee',
  templateUrl: './edittrackeremployee.component.html',
  styleUrls: ['./edittrackeremployee.component.css']
})
export class EdittrackeremployeeComponent implements OnInit {

  trackeremployee: TrackerEmployees;
  trackerDepartmentList: TrackerDepartment[];
  trackerDesignationList: TrackerDesignation[];


  constructor(private edittrackeremployeeService: EdittrackeremployeeService, private router: Router,
    private trackerdepartmentService: TrackerdepartmentService, private trackerdesignationService: TrackerdesignationService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    // this.trackeremployee = new TrackerEmployees();
    // this.trackeremployee.department = new TrackerDepartment();
    // this.trackeremployee.designation = new TrackerDesignation();

    this.edittrackeremployeeService.getaTrackerEmployeeRecord(this.route.snapshot.params.id)
      .subscribe(data => {
        this.trackeremployee = data;
       // this.trackeremployee.dob = data.dob;
     //   this.trackeremployee.department = data.department;
     //   this.trackeremployee.designation = data.designation;
      },
        error => {
          this.router.navigate(['']);
        });

    this.trackerdepartmentService.getAllTrackerDepartmentList()
      .subscribe(data => {
        this.trackerDepartmentList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.trackerdesignationService.getAllTrackerDesignationList()
      .subscribe(data => {
        this.trackerDesignationList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  updateTrackerEmployee() {
    alert(JSON.stringify(this.trackeremployee));
    this.edittrackeremployeeService.updateTrackerEmployee(this.trackeremployee)
      .subscribe(data => {
        this.router.navigate(['tracker/trackeremployees']);
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
