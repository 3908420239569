import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginpageService } from '../login/loginpage/loginpage.service';
import { ContainerPurchase } from '../model/containerpurchase.model';
import { CountryInfo } from '../model/countryInfo.model';
import { SearchByCountryDTO } from '../model/searchbycountryDTO.model';
import { ContainersearchService } from './containersearch.service';

@Component({
  selector: 'app-containersearch',
  templateUrl: './containersearch.component.html',
  styleUrls: ['./containersearch.component.css']
})
export class ContainersearchComponent implements OnInit {
  containerList: ContainerPurchase[];
  countryList: CountryInfo[];
  countrysearch: SearchByCountryDTO;

  constructor(private containersearchService: ContainersearchService, private loginpageService: LoginpageService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.countrysearch = new SearchByCountryDTO();
    this.countrysearch.country = new CountryInfo();

    this.loginpageService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.containersearchService.getContainerDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.containerList = data;
        // alert(JSON.stringify(this.containerList));
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onKeydown(event) {

    if (event.key === 'Enter') {
      this.containersearchService.getContainerDetails(String(this.countrysearch.country.id))
      .subscribe(data => {
        this.containerList = data;
        // alert(JSON.stringify(this.containerList));
      },
        error => {
          this.router.navigate(['']);
        });
   //   this.router.navigate(['containerbycountry', this.countrysearch.country.id]);
    }
  }

}
