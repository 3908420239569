import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TradingsComponent } from './tradings/tradings.component';
import { ContainerpurchaseComponent } from './tradings/containerpurchase/containerpurchase.component';
import { ContainersalesComponent } from './tradings/containersales/containersales.component';
import { InitialinventoryComponent } from './initialinventory/initialinventory.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
  MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
  MatBottomSheetModule,
  MatSlideToggleModule
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContainerinventorydetailsComponent } from './tradings/containerinventorydetails/containerinventorydetails.component';
import { NewcontainerpurchaseComponent } from './tradings/containerpurchase/newcontainerpurchase/newcontainerpurchase.component';
import { CustomersComponent } from './admin/customers/customers.component';
import { VendorsComponent } from './admin/vendors/vendors.component';
import { ContainertypeComponent } from './master/containertype/containertype.component';
import { EmployeeComponent } from './admin/employee/employee.component';
import { NewcustomerComponent } from './admin/customers/newcustomer/newcustomer.component';
import { NewemployeeComponent } from './admin/employee/newemployee/newemployee.component';
import { NewvendorComponent } from './admin/vendors/newvendor/newvendor.component';
import { ContainerloginComponent } from './containerlogin/containerlogin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NewcontainertypeComponent } from './master/containertype/newcontainertype/newcontainertype.component';
// tslint:disable-next-line: max-line-length
import { ContainerpurchasepaymentComponent } from './tradings/containerpurchase/containerpurchasepayment/containerpurchasepayment.component';
import { NewcontainersalesComponent } from './tradings/containersales/newcontainersales/newcontainersales.component';
import { PurchaselocationComponent } from './master/purchaselocation/purchaselocation.component';
import { NewpurchaselocationComponent } from './master/purchaselocation/newpurchaselocation/newpurchaselocation.component';
// tslint:disable-next-line: max-line-length
import { ContainerpurchasedetailsComponent } from './tradings/containerpurchase/containerpurchasedetails/containerpurchasedetails.component';
import { AddpurchasecontainerComponent } from './tradings/containerpurchase/addpurchasecontainer/addpurchasecontainer.component';
import { AddpurchasecontainersComponent } from './tradings/containerpurchase/addpurchasecontainers/addpurchasecontainers.component';
import { UploadcontainerpurchaseComponent } from './tradings/containerpurchase/uploadcontainerpurchase/uploadcontainerpurchase.component';
import { LocationtypeComponent } from './master/locationtype/locationtype.component';
import { NewlocationtypeComponent } from './master/locationtype/newlocationtype/newlocationtype.component';
import { PurchasedcontainersComponent } from './tradings/containerpurchase/purchasedcontainers/purchasedcontainers.component';
import { ContainersalesincomeComponent } from './accounts/containersalesincome/containersalesincome.component';
import { ContainerpurchaseexpenseComponent } from './accounts/containerpurchaseexpense/containerpurchaseexpense.component';
import { TaxesComponent } from './master/taxes/taxes.component';
import { NewtaxesComponent } from './master/taxes/newtaxes/newtaxes.component';
import { TaxagenciesComponent } from './master/taxes/taxagencies/taxagencies.component';
import { NewtaxagencyComponent } from './master/taxes/taxagencies/newtaxagency/newtaxagency.component';
import { MovecontainersComponent } from './movecontainers/movecontainers.component';
import { BuycontainersComponent } from './buycontainers/buycontainers.component';
import { NewmovecontainersComponent } from './movecontainers/newmovecontainers/newmovecontainers.component';
import { OnewaymovedetailsComponent } from './movecontainers/onewaymovedetails/onewaymovedetails.component';
import { OnewaymoverequestComponent } from './movecontainers/onewaymoverequest/onewaymoverequest.component';
import { ContainerleaseComponent } from './containerlease/containerlease.component';
import { NewcontainerleaseComponent } from './containerlease/newcontainerlease/newcontainerlease.component';
import { ContainerleasedetailsComponent } from './containerlease/containerleasedetails/containerleasedetails.component';
import { AddcontainerleasingComponent } from './containerlease/addcontainerleasing/addcontainerleasing.component';
import { ContainerleasegateoutComponent } from './containerlease/containerleasegateout/containerleasegateout.component';
import { ContainerleasegateinComponent } from './containerlease/containerleasegatein/containerleasegatein.component';
// tslint:disable-next-line:max-line-length
import { ContainersalesreleaseunitComponent } from './tradings/containersales/containersalesreleaseunit/containersalesreleaseunit.component';
import { ContainersalesdetailsComponent } from './tradings/containersales/containersalesdetails/containersalesdetails.component';
import { ContainersalesinvoiceComponent } from './tradings/containersales/containersalesinvoice/containersalesinvoice.component';
import { ContainersalespaymentComponent } from './tradings/containersales/containersalespayment/containersalespayment.component';
import { AccountsdashboardComponent } from './accounts/accountsdashboard/accountsdashboard.component';
import { DashboardpurchaselistComponent } from './dashboardpurchaselist/dashboardpurchaselist.component';
import { DashboardsaleslistComponent } from './dashboardsaleslist/dashboardsaleslist.component';
// tslint:disable-next-line: max-line-length
import { ContainerpurchaseexpensedetailsComponent } from './accounts/containerpurchaseexpense/containerpurchaseexpensedetails/containerpurchaseexpensedetails.component';
import { ViewpurchasecontainersComponent } from './tradings/containerpurchase/viewpurchasecontainers/viewpurchasecontainers.component';
import { ViewsaledcontainerComponent } from './tradings/containersales/viewsaledcontainer/viewsaledcontainer.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { IncomeComponent } from './accounts/containersalesincome/income/income.component';
import { ViewsoldsalesunitsComponent } from './tradings/containersales/viewsoldsalesunits/viewsoldsalesunits.component';
import { DepotcontainersComponent } from './depotcontainers/depotcontainers.component';
import { EdittaxesComponent } from './master/taxes/edittaxes/edittaxes.component';
import { EditcontainerpurchaseComponent } from './tradings/containerpurchase/editcontainerpurchase/editcontainerpurchase.component';
import { ContainercompanyComponent } from './admin/containercompany/containercompany.component';
import { TradecontainersearchComponent } from './reports/tradecontainersearch/tradecontainersearch.component';
import { PurchasebysupplierreportComponent } from './reports/purchasebysupplierreport/purchasebysupplierreport.component';
import { SalesbycustomerreportComponent } from './reports/salesbycustomerreport/salesbycustomerreport.component';
import { EditcontainercustomerComponent } from './admin/customers/editcontainercustomer/editcontainercustomer.component';
import { EditcontainersalesComponent } from './tradings/containersales/editcontainersales/editcontainersales.component';
import { ContainersRouterModule } from './containers-router.module';
import { ContainerlayoutComponent } from '../_layouts/containerlayout/containerlayout.component';
import { ContainerheaderComponent } from '../_layouts/containerheader/containerheader.component';
import { ContainermobnavComponent } from '../containermobnav/containermobnav.component';
import { ContainersComponent } from './containers.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { EditcontainerinfoComponent } from './tradings/containerpurchase/editcontainerinfo/editcontainerinfo.component';
import { PresalesComponent } from './tradings/presales/presales.component';
import { NewpresalesComponent } from './tradings/presales/newpresales/newpresales.component';
import { EditpresalesComponent } from './tradings/presales/editpresales/editpresales.component';
import { PresalesdetailsComponent } from './tradings/presales/presalesdetails/presalesdetails.component';
import { PresalesconversationsComponent } from './tradings/presales/presalesconversations/presalesconversations.component';
import { SearchbycontainertypeComponent } from './searchbycontainertype/searchbycontainertype.component';
import { ContainerleasehistoryComponent } from './containerlease/containerleasehistory/containerleasehistory.component';
import { ContainerleasehistorydetailsComponent } from './containerlease/containerleasehistorydetails/containerleasehistorydetails.component';
import { YearlysalesreportsComponent } from './reports/sales/yearlysalesreports/yearlysalesreports.component';
import { ChartsModule } from 'ng2-charts';
import { CntyearlypurchasereportsComponent } from './reports/purchase/cntyearlypurchasereports/cntyearlypurchasereports.component';
import { TrademyboxpricingComponent } from './trademyboxpricing/trademyboxpricing.component';
import { ContainersalesbyhaulierComponent } from './tradings/containerpurchase/containersalesbyhaulier/containersalesbyhaulier.component';
import { ContainerstackchangeComponent } from './tradings/containerpurchase/containerstackchange/containerstackchange.component';
import { AccountspaymodeComponent } from './accounts/accountspaymode/accountspaymode.component';
import { NewaccountspaymentmodeComponent } from './accounts/accountspaymode/newaccountspaymentmode/newaccountspaymentmode.component';


@NgModule({
  declarations: [TradingsComponent, ContainerpurchaseComponent, ContainersalesComponent, InitialinventoryComponent,
    ContainerinventorydetailsComponent, NewcontainerpurchaseComponent, CustomersComponent, VendorsComponent,
    ContainertypeComponent, EmployeeComponent, NewcustomerComponent, NewemployeeComponent, NewvendorComponent,
    ContainerloginComponent, NewcontainertypeComponent, ContainerpurchasepaymentComponent, NewcontainersalesComponent,
    PurchaselocationComponent, NewpurchaselocationComponent, ContainersalesdetailsComponent,
    ContainerpurchasedetailsComponent, AddpurchasecontainerComponent, AddpurchasecontainersComponent,
    UploadcontainerpurchaseComponent, ViewpurchasecontainersComponent, LocationtypeComponent, NewlocationtypeComponent,
    PurchasedcontainersComponent, ContainersalesincomeComponent, ContainerpurchaseexpenseComponent, TaxesComponent,
    NewtaxesComponent, TaxagenciesComponent, NewtaxagencyComponent, MovecontainersComponent, BuycontainersComponent,
    NewmovecontainersComponent, OnewaymovedetailsComponent, OnewaymoverequestComponent, ContainerleaseComponent,
    NewcontainerleaseComponent, ContainerleasedetailsComponent, AddcontainerleasingComponent, ContainerleasegateoutComponent,
    ContainerleasegateinComponent, ContainersalesreleaseunitComponent, ContainersalesinvoiceComponent,
    ContainersalespaymentComponent, AccountsdashboardComponent, DashboardpurchaselistComponent, DashboardsaleslistComponent,
    ContainerpurchaseexpensedetailsComponent, ViewsaledcontainerComponent, IncomeComponent,
    ViewsoldsalesunitsComponent, DepotcontainersComponent, EdittaxesComponent, EditcontainerpurchaseComponent,
    ContainercompanyComponent, TradecontainersearchComponent, PurchasebysupplierreportComponent,
    SalesbycustomerreportComponent,EditcontainercustomerComponent,ContainerlayoutComponent,ContainerheaderComponent,
    ContainermobnavComponent, ContainersComponent,EditcontainersalesComponent, 
    EditcontainerinfoComponent, PresalesComponent, NewpresalesComponent, 
    EditpresalesComponent, PresalesdetailsComponent, PresalesconversationsComponent, 
    SearchbycontainertypeComponent, ContainerleasehistoryComponent, 
    ContainerleasehistorydetailsComponent, YearlysalesreportsComponent, 
    CntyearlypurchasereportsComponent, TrademyboxpricingComponent, ContainersalesbyhaulierComponent, 
    ContainerstackchangeComponent, AccountspaymodeComponent, NewaccountspaymentmodeComponent],
  imports: [
    CommonModule, ContainersRouterModule ,MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule,
    MatListModule, MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
    MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
    MatBottomSheetModule, MatSlideToggleModule, MatSnackBarModule, MatTooltipModule, MatStepperModule, MatAutocompleteModule,
    FlexLayoutModule, FormsModule, MatDatepickerModule, NgxPaginationModule, ReactiveFormsModule,
    NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule,CarouselModule,ChartsModule
  ],
  exports: [RouterModule, DashboardpurchaselistComponent, DashboardsaleslistComponent, DepotcontainersComponent],
  entryComponents: [AddcontainerleasingComponent, ContainerleasegateoutComponent, ContainerleasegateinComponent]
})
export class ContainersModule { }
