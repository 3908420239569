import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { MarketplaceOrder } from '../../../model/marketplaceOrder.model';
import { HttpClient } from '@angular/common/http';
import { MarketplaceOrderStatus } from 'src/app/model/marketplaceorderstatus.model';

@Injectable({
  providedIn: 'root'
})
export class TrackmyorderService {


  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackmyorder.do';
  private statusUrl = ShipStreetProps.API_PATH + 'trackmyorderstatus.do';

 public trackmyorder(orderId: number) {
    return this.http.post<MarketplaceOrder>(this.appUrl , orderId);
  }

  public trackmyorderstatus(orderId: number) {
    return this.http.post<MarketplaceOrderStatus>(this.statusUrl , orderId);
  }
}
