import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Taxes } from 'src/app/model/taxes.model';

@Injectable({
  providedIn: 'root'
})
export class EdittaxesService {

  private saveUrl = ShipStreetProps.API_PATH + 'taxes/updateTaxes';
  private appUrl = ShipStreetProps.API_PATH + 'taxes/getTaxById';
  constructor(private http: HttpClient) { }

   public getTaxById(taxId: number) {
    return this.http.post<Taxes>(this.appUrl , taxId);
  }

  public updateTaxes(tax: Taxes) {
    return this.http.post<string>(this.saveUrl , tax);
  }
}
