import { Component, OnInit } from '@angular/core';
import { ContainersalesService } from '../tradings/containersales/containersales.service';
import { Router } from '@angular/router';
import { ContainerSales } from 'src/app/model/containersales.model';
import { DashboardsaleslistService } from './dashboardsaleslist.service';
import { ContainerType } from 'src/app/model/containertype.model';
import { ContainertypeService } from '../master/containertype/containertype.service';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboardsaleslist',
  templateUrl: './dashboardsaleslist.component.html',
  styleUrls: ['./dashboardsaleslist.component.css']
})
export class DashboardsaleslistComponent implements OnInit {

  salesList: ContainerSales[];
  salesConType: ContainerType;
  sales: ContainerSales;
  conttypeList: ContainerType[];
  myControlContainerType = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionsType: Observable<ContainerType[]>;

  constructor(private containersalesService: ContainersalesService, private router: Router , 
    private dashboardsaleslistService: DashboardsaleslistService, private containertypeService: ContainertypeService) { }

  ngOnInit() {
    this.salesConType = new ContainerType();
    this.sales = new ContainerSales();
    this.sales.type = new ContainerType();

    this.containertypeService.findAllContainerTypeByOrder()
      .subscribe(data => {
        this.conttypeList = data;
        this.filteredOptionsType = this.myControlContainerType.valueChanges.pipe(
          map(val => this._filtersType(val))
        );
      },
        error => {
          this.router.navigate(['']);
        });
    
     this.containersalesService.findAllContSalesByCompanyByDate()
      .subscribe(data => {
        this.salesList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  onSalesKeydown(event) {
    if (event.key === 'Enter') {
    this.sales.type.id = this.salesConType.id;
    this.dashboardsaleslistService.findSalesByType(this.sales)
      .subscribe(data => {
        this.salesList = data;
      },
        error => {
          this.router.navigate(['']);
        });
      }
  }

  public _filtersType(value: string): ContainerType[] {
    const filterValuecust = value;
    return this.conttypeList.filter(conttypeList => conttypeList.eqType.toLowerCase().indexOf(filterValuecust) === 0 ||
    conttypeList.eqType.toLowerCase().indexOf(filterValuecust) === 0 ||
    conttypeList.eqType.toUpperCase().indexOf(filterValuecust) === 0);
  }

  public displayCustomer(cntr: ContainerType): string {
    if (cntr && cntr.eqType) {
      return cntr.eqType;
    }
    }

}

export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}