import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';
import { CostcalculatorsettinglistService } from '../costcalculatorsettinglist/costcalculatorsettinglist.service';

@Component({
  selector: 'app-calculatorlocalcharges',
  templateUrl: './calculatorlocalcharges.component.html',
  styleUrls: ['./calculatorlocalcharges.component.css']
})
export class CalculatorlocalchargesComponent implements OnInit {

  portcosts: MasterPortCostDetails[];
  searchText: string;
  p = 1;
  portcostsALL: MasterPortCostDetails[];

  constructor(private costcalculatorsettinglistService: CostcalculatorsettinglistService, private router: Router) { }

  ngOnInit() {

    this.costcalculatorsettinglistService.getAllCostCalculatorDetails()
      .subscribe(data => {
        this.portcostsALL = data;
        this.portcosts = this.portcostsALL;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  searchbar() {
    if (this.searchText !== '') {
      this.portcosts = this.portcostsALL.filter(res => {
        return (res.port !== null && res.port.name.toLowerCase().match(this.searchText.toLocaleLowerCase()));
      });
    } else if (this.searchText === '') {
      this.portcosts = this.portcostsALL;
    }
  }

}
