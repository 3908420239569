import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { AgentPrincipleFreightDetails } from 'src/app/model/agentprinciplefreightdetails.model';

@Injectable({
  providedIn: 'root'
})
export class TbagentresponsetoprincipleService {

  constructor(private http: HttpClient) { }

  private appDetUrl = ShipStreetProps.API_PATH + 'trackboxagent/getAllFreightDetailsById.do';
  private updateUrl = ShipStreetProps.API_PATH + 'trackboxagent/updateResponseToPrinciple.do';

  public getAllFreightDetailsById(freightId: number) {
    return this.http.post<AgentPrincipleFreightDetails[]>(this.appDetUrl, freightId);
  }

  public responseToPrinciple(freight: AgentPrincipleFreightDetails) {
    return this.http.post<AgentPrincipleFreightDetails[]>(this.updateUrl, freight);
  }

}
