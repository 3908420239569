import { Component, OnInit } from '@angular/core';
import { VesseloperatorcostdetailsService } from './vesseloperatorcostdetails.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterPortVesselOperatorCosts } from 'src/app/model/masterportvesseloperatorcosts.model';

@Component({
  selector: 'app-vesseloperatorcostdetails',
  templateUrl: './vesseloperatorcostdetails.component.html',
  styleUrls: ['./vesseloperatorcostdetails.component.css']
})
export class VesseloperatorcostdetailsComponent implements OnInit {
  vocostObj: MasterPortVesselOperatorCosts;


  constructor(private vesseloperatorcostdetailsService: VesseloperatorcostdetailsService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.vesseloperatorcostdetailsService.vocostCalculatorDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.vocostObj = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
