import { ContainerLocationType } from 'src/app/model/containerlocationtype.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NewtrackboxcarrierService } from './newtrackboxcarrier.service';
import { Component, OnInit } from '@angular/core';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxCarrier } from 'src/app/model/trackboxcarrier.model';
import { NewequipmentcontrolService } from 'src/app/trackmybox/equipmentcontrol/newequipmentcontrol/newequipmentcontrol.service';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-newtrackboxcarrier',
  templateUrl: './newtrackboxcarrier.component.html',
  styleUrls: ['./newtrackboxcarrier.component.css']
})
export class NewtrackboxcarrierComponent implements OnInit {
  CountryList: CountryInfo[];
  locationList: ContainerLocationType[];
  trackboxcarrier: TrackBoxCarrier;
  countryId = 0;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
  constructor(private newtrackboxcarrierService: NewtrackboxcarrierService, private router: Router, private route: ActivatedRoute,
              private newequipmentcontrolService: NewequipmentcontrolService) { }

  ngOnInit() {
    this.trackboxcarrier = new TrackBoxCarrier();
    this.trackboxcarrier.country = new CountryInfo();

    this.newequipmentcontrolService.viewCountry()
  .subscribe(data => {
    this.CountryList = data;
    this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter4(val))
    );

    console.log(this.filteredOptionscountry);
},
    error => {
      this.router.navigate(['']);
    });

  }
  saveTrackBoxCarrier() {
    this.newtrackboxcarrierService.saveTrackBoxCarrier(this.trackboxcarrier)
    .subscribe(data => {
    this.router.navigate(['trackmybox/trackboxcarriers']);
     },
       error => {
         this.router.navigate(['']);
       });
  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }

}
  }

  export function RequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    // alert(selection)
    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    return null;
  }
