import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { salesforceRoute } from './salesforcerouter';
import { SalesforcedashboardComponent } from './salesforcedashboard/salesforcedashboard.component';
import { SalesreportdashboardComponent } from './reports/salesreportdashboard/salesreportdashboard.component';
import { ForcastreportsComponent } from './reports/forcastreports/forcastreports.component';
import { TimelinereportsComponent } from './reports/timelinereports/timelinereports.component';
import { PipelinereportsComponent } from './reports/pipelinereports/pipelinereports.component';
import { ActivityreportsComponent } from './reports/activityreports/activityreports.component';
import { MypipelineComponent } from './mypipeline/mypipeline.component';
import { MyactivityComponent } from './myactivity/myactivity.component';
import { NewmypipelineComponent } from './mypipeline/newmypipeline/newmypipeline.component';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
  MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
  MatBottomSheetModule, MatAutocompleteModule, MatDatepickerModule, MatSlideToggleModule, MatProgressSpinnerModule
} from '@angular/material';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QualifiedpipelineComponent } from './mypipeline/qualifiedpipeline/qualifiedpipeline.component';
import { PropositionpipelineComponent } from './mypipeline/propositionpipeline/propositionpipeline.component';
import { WonpipelineComponent } from './mypipeline/wonpipeline/wonpipeline.component';
import { ScheduleactivityComponent } from './mypipeline/scheduleactivity/scheduleactivity.component';


@NgModule({
  declarations: [SalesforcedashboardComponent, SalesreportdashboardComponent,
    ForcastreportsComponent, TimelinereportsComponent, PipelinereportsComponent, ActivityreportsComponent,
    MypipelineComponent, MyactivityComponent, NewmypipelineComponent, QualifiedpipelineComponent,
    PropositionpipelineComponent, WonpipelineComponent, ScheduleactivityComponent, ],
  imports: [
    CommonModule, RouterModule.forChild(salesforceRoute), MatGridListModule, MatDividerModule,
    MatCardModule, LayoutModule, FlexLayoutModule, MatIconModule, MatFormFieldModule, MatInputModule,
    FormsModule, MatSnackBarModule, MatMenuModule, MatSelectModule, MatToolbarModule, MatButtonModule,
    MatSidenavModule, MatListModule,
    MatTableModule, MatPaginatorModule, MatSortModule,
    MatNativeDateModule, MatCheckboxModule,
  MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
  MatBottomSheetModule, MatAutocompleteModule, MatDatepickerModule, MatSlideToggleModule, MatProgressSpinnerModule
  ], entryComponents: [ScheduleactivityComponent]
})
export class SalesforceModule { }
