import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalcChargeHeads } from 'src/app/model/calcchargeheads.model';
import { EditlocalchargeheadsService } from './editlocalchargeheads.service';

@Component({
  selector: 'app-editlocalchargeheads',
  templateUrl: './editlocalchargeheads.component.html',
  styleUrls: ['./editlocalchargeheads.component.css']
})
export class EditlocalchargeheadsComponent implements OnInit {

  chargeheads: CalcChargeHeads;

  
  constructor(private editlocalchargeheadsService: EditlocalchargeheadsService, private router: Router ,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.chargeheads = new CalcChargeHeads();
    this.editlocalchargeheadsService.findCalcChargeHead(this.route.snapshot.params.id)
      .subscribe(data => {
        this.chargeheads = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  createChargeHeads() {
    this.editlocalchargeheadsService.createChargeHeads(this.chargeheads)
      .subscribe(data => {
        this.router.navigate(['/calculator/callocalchargeheads']);
      },
        error => {
          this.router.navigate(['']);
        });
   }

}
