import { Component, OnInit } from '@angular/core';
import { NewportService } from './newport.service';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Company } from 'src/app/model/company.model';
import { PortsService } from '../ports.service';
@Component({
  selector: 'app-newport',
  templateUrl: './newport.component.html',
  styleUrls: ['./newport.component.css']
})

export class NewportComponent implements OnInit {
  //myForm: FormGroup;
  port: MasterPortsDepot;
  comp: Company;
  masterPortList: MasterPortsDepot[];
  services = new FormControl();
  CountryList: CountryInfo[];
  countryId = 0;
  loading = true;
  errorMessage: string;
  myControl3 = new FormControl('', [Validators.required, RequireMatch]);
  filteredOptionscountry: Observable<CountryInfo[]>;
  serviceList: string[] = ['Container Storage', 'Container repair and maintenance', 'Container trading', 'Container Modification'
    , 'Transportation Services', 'Container Inspection', 'Reefer PTI', 'Clip on Genset Rental'];



  constructor(private newportService: NewportService, private router: Router, private route: ActivatedRoute) { }

 ngOnInit() {

    this.port = new MasterPortsDepot();
    this.port.country = new CountryInfo();
    this.comp = new Company();
   
    

    this.newportService.viewCountry()
      .subscribe(data => {
        this.CountryList = data;
        this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter4(val))
    );

      },
        error => {
          this.router.navigate(['']);
        });

         }

  onSubmit() {
       this.newportService.save(this.port)
      .subscribe(data => {
       
    if (data==null && data.name==null || data.errorString==='Port Already Exist' ) {
          this.errorMessage='Port Already Exist'
         }
      else{
       
       this.router.navigate(['/trackmybox/ports']);
       }
        
      },
        error => {
          this.router.navigate(['']);
        });
     
      }
    
  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

 
  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }

}

}

export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  // alert(selection)
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}




