import { CandidateSkill } from '../../model/candidateSkill.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class SkillsService {

  private appUrl = ShipStreetProps.API_PATH + 'candidateSkills.do';
   private saveUrl = ShipStreetProps.API_PATH + 'newSkill.do';
  private updateUrl = ShipStreetProps.API_PATH + 'updateCandidateSkill.do';
  private deleteUrl = ShipStreetProps.API_PATH + 'deleteCandidateSkill.do';

  constructor(private http: HttpClient) { }

  public candidateSkills() {
    return this.http.get<CandidateSkill[]>(this.appUrl);
  }

   public saveType(skills: CandidateSkill) {
    return this.http.post<string>(this.saveUrl, skills);
  }
  public updateCandidateSkill(skills: CandidateSkill) {
    return this.http.post<string>(this.updateUrl, skills);
  }
   public deleteSkill(skills: CandidateSkill) {
    return this.http.post<CandidateSkill[]>(this.deleteUrl, skills);
  }
}
