import { Component, OnInit } from '@angular/core';
import { ContainersalesincomeService } from './containersalesincome.service';
import { Router } from '@angular/router';
import { ContainerSalesBalance } from 'src/app/model/containersalesbalance.model';

@Component({
  selector: 'app-containersalesincome',
  templateUrl: './containersalesincome.component.html',
  styleUrls: ['./containersalesincome.component.css']
})
export class ContainersalesincomeComponent implements OnInit {
  salesBalanaceList: ContainerSalesBalance[];
  total = 0;
  private value;

  constructor(private containersalesincomeService: ContainersalesincomeService, private router: Router) { }

  ngOnInit() {
    this.containersalesincomeService.containerSalesBalanceList()
      .subscribe(data => {
        this.salesBalanaceList = data;
        this.findsum(this.salesBalanaceList);
      },
        error => {
          this.router.navigate(['']);
        });
  }

   findsum(data) {
    //  debugger
    this.value = data
    for (let j = 0; j < data.length; j++) {
      this.total += this.value[j].balanceAmount;
    }
  }

}
