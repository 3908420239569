import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaselocationService } from './purchaselocation.service';
import { ContainerPurchaseLocation } from 'src/app/model/containerpurchaselocation.model';

@Component({
  selector: 'app-purchaselocation',
  templateUrl: './purchaselocation.component.html',
  styleUrls: ['./purchaselocation.component.css']
})
export class PurchaselocationComponent implements OnInit {
  containerPurLocation: ContainerPurchaseLocation[];
  p = 1;

  constructor(private purchaselocationService: PurchaselocationService, private router: Router) { }

  ngOnInit() {
    this.purchaselocationService.findAllContainerPurchaseLocations()
      .subscribe(data => {
        this.containerPurLocation = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
