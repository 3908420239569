import { ViewprinciplerequestService } from './viewprinciplerequest.service';
import { Component, OnInit } from '@angular/core';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-viewprinciplerequest',
  templateUrl: './viewprinciplerequest.component.html',
  styleUrls: ['./viewprinciplerequest.component.css']
})
export class ViewprinciplerequestComponent implements OnInit {

  boxrequestList: TrackBoxAgent[];
  p: number;
  constructor(private viewprinciplerequestService: ViewprinciplerequestService, private router: Router) { }

  ngOnInit() {
    this.viewprinciplerequestService.getAllMyPrincipleRequests()
      .subscribe(data => {
        this.boxrequestList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }


}
