import { Router, ActivatedRoute } from '@angular/router';
import { DepotsService } from './depots.service';
import { Component, OnInit } from '@angular/core';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Component({
  selector: 'app-depots',
  templateUrl: './depots.component.html',
  styleUrls: ['./depots.component.css']
})
export class DepotsComponent implements OnInit {
masterDepotList: MasterPortsDepot[];
panelOpenState: boolean;
p = 1;

  constructor(private depotsService: DepotsService, private router: Router , private route: ActivatedRoute) { }

  ngOnInit() {
    this.depotsService.getAllMyDepots()
      .subscribe(data => {
        this.masterDepotList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }
  }


