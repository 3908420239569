import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgentPrincipleFreight } from 'src/app/model/agentprinciplefreight.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { AgentPrincipleFreightDetails } from 'src/app/model/agentprinciplefreightdetails.model';

@Injectable({
  providedIn: 'root'
})
export class TbagentviewrequestsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/viewMyRequeststoPrinciple.do';


  public getAllMyRequests(bookingId: number) {
    return this.http.post<AgentPrincipleFreight[]>(this.appUrl, bookingId);
  }

}
