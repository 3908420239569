import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TbAgentConsigneeDTO } from 'src/app/model/tbagentconsigneeDTO.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class TbagentaddconsigneeService {

constructor(private http : HttpClient) { }
private appUrladdconsignee = ShipStreetProps.API_PATH + 'TrackboxBookings/addConsignee.do';

public addConsignee(consigneeadd: TbAgentConsigneeDTO) {
   //alert(JSON.stringify(consigneeadd));
  return this.http.post<TbAgentConsigneeDTO[]>(this.appUrladdconsignee, consigneeadd);

}

}
