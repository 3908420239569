import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobs112',
  templateUrl: './jobs112.component.html',
  styleUrls: ['./jobs112.component.css']
})
export class Jobs112Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
