import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { TaxAgency } from 'src/app/model/taxagency.model';

@Injectable({
  providedIn: 'root'
})
export class NewtaxagencyService {

   private saveUrl = ShipStreetProps.API_PATH + 'taxagent/saveTaxAgency';
  constructor(private http: HttpClient) { }

  public saveTaxAgency(agent: TaxAgency) {
    return this.http.post<string>(this.saveUrl , agent);
  }

}
