import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';

@Component({
  selector: 'app-blsearch',
  templateUrl: './blsearch.component.html',
  styleUrls: ['./blsearch.component.css']
})
export class BlsearchComponent implements OnInit {
  blnumber: string;

  constructor(private router: Router) { }

  ngOnInit() {
    // this.tbcontainer = new TrackBoxBooking();
  }

   onKeydown(event) {

        if (event.key === 'Enter') {
            this.router.navigate(['blsearchresult', this.blnumber]);
        }
    }
}
