import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerOnewayMove } from 'src/app/model/containeronewaymove.model';

@Injectable({
  providedIn: 'root'
})
export class MovecontainersService {

  private listUrl = ShipStreetProps.API_PATH + 'containeronewaymove/findAllContainerOnewayMoves';
  private orderlistUrl = ShipStreetProps.API_PATH + 'containeronewaymove/findAllContainerOnewayMovesByOrder';

  constructor(private http: HttpClient) { }

  public findAllContainerOnewayMoves() {
    return this.http.get<ContainerOnewayMove[]>(this.listUrl);
  }

  public findAllContainerOnewayMovesByOrder() {
    return this.http.get<ContainerOnewayMove[]>(this.orderlistUrl);
  }
}
