import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Estimate } from 'src/app/model/estimate.model';
// import { DepotProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ConsigneegateinpaymentapprovalsService {

  constructor(private http: HttpClient) { }

  // private appUrl = DepotProps.API_PATH + '/accountdashboard/getConsigneeGateInPaymentApprovalsList.do';
  // private appRepUrl = DepotProps.API_PATH + 'repairBillApprove.do';

  // public getConsigneePaymentApprovalsList() {
  //   return this.http.get<Estimate[]>(this.appUrl);
  // }

  // public repairBillApprove(custBillId: number) {
  //   return this.http.post<string>(this.appRepUrl, custBillId);
  // }

}
