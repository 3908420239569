import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MasterVessels } from 'src/app/model/mastervessels.model';

@Injectable({
  providedIn: 'root'
})
export class NewcompanyvesselService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'vessels/saveCompanyVessel.do';

  public saveCompanyVessel(masterVessel: MasterVessels) {
    return this.http.post<MasterVessels>(this.appUrl, masterVessel);
  }
}
