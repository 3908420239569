import { Component, OnInit } from '@angular/core';
import { TrackerdesignationService } from './trackerdesignation.service';
import { Router } from '@angular/router';
import { TrackerDesignation } from 'src/app/model/trackerdesignation.module';

@Component({
  selector: 'app-trackerdesignation',
  templateUrl: './trackerdesignation.component.html',
  styleUrls: ['./trackerdesignation.component.css']
})
export class TrackerdesignationComponent implements OnInit {

   p: number;
  trackerDesignationList: TrackerDesignation[];
  constructor(private trackerdesignationService: TrackerdesignationService, private router: Router) { }

  ngOnInit() {
    this.trackerdesignationService.getAllTrackerDesignationList()
      .subscribe(data => {
        this.trackerDesignationList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
