import { Component, OnInit } from '@angular/core';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';
import { CostcalculatorsettingdetailsService } from '../costcalculatorsettingdetails/costcalculatorsettingdetails.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { EditcostcalculatorsettingService } from './editcostcalculatorsetting.service';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';
import { CalcequipmenttypesService } from '../mastersettings/calcequipmenttypes/calcequipmenttypes.service';
import { CalcEquipmentType } from 'src/app/model/CalcEquipmentType.model';

@Component({
  selector: 'app-editcostcalculatorsetting',
  templateUrl: './editcostcalculatorsetting.component.html',
  styleUrls: ['./editcostcalculatorsetting.component.css']
})
export class EditcostcalculatorsettingComponent implements OnInit {

  costObj: MasterPortCostDetails;
  masterPortListAll: MasterPortsDepot[];
  containerTypeList: CalcEquipmentType[];

  constructor(private costcalculatorsettingdetailsService: CostcalculatorsettingdetailsService, private router: Router,
              private route: ActivatedRoute, private portService: PortsService,
              private editcostcalculatorsettingService: EditcostcalculatorsettingService, 
              private calcequipmenttypesService: CalcequipmenttypesService) { }

  ngOnInit() {
    this.costObj = new MasterPortCostDetails();
    this.costObj.port = new MasterPortsDepot();
    this.costObj.containerSize = new CalcEquipmentType();

    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortListAll = data;
      },
        error => {
          this.router.navigate(['']);
        });
    this.costcalculatorsettingdetailsService.costCalculatorDetails(this.route.snapshot.params.id)
      .subscribe(data => {
        this.costObj = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.calcequipmenttypesService.findAllCalculatorEquipmentType()
        .subscribe(data => {
          this.containerTypeList = data;
        },
          error => {
            this.router.navigate(['']);
          });
  }

  updateCostCalculator() {
    this.editcostcalculatorsettingService.updateCostCalculator(this.costObj)
      .subscribe(data => {
      this.router.navigate(['/calculator/costcalculatorsettingslist']);
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
