import { CandidateConnect } from '../../model/candidateConnect.model';
import { ConnectionList } from '../../model/connectionList.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {
constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'connectionCandidate.do';
  private appListUrl = ShipStreetProps.API_PATH + 'myFriendsList.do';

  public connectionCandidate() {
    return this.http.get<ConnectionList[]>(this.appUrl);
  }

  public myFriendsList() {
    return this.http.get<CandidateConnect[]>(this.appListUrl);
  }
}
