import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxContainerSupplier } from 'src/app/model/trackboxcontainersupplier.model';

@Injectable({
  providedIn: 'root'
})
export class TrackboxsupplierService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'trackboxcontainersupplier/findAlltrackboxContainerSupplierbyCompany.do';

  getAllMySupplier() {
    return this.http.get<TrackBoxContainerSupplier[]>(this.appUrl );
  }

}
