import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ImportbookingService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/getAllMyImportBookings.do';
getAllMyImportBookings() {
  return this.http.get<TrackBoxBooking[]>(this.appUrl);
}
}
