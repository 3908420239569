
import { Component, OnInit } from '@angular/core';
import { DisableconfirmationService } from './disableconfirmation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/model/company.model';
import { YourcompaniesService } from '../yourcompanies/yourcompanies.service';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { Candidates } from 'src/app/model/candidate.model';
import { MasterPortCostDetails } from 'src/app/model/masterportcostdetails.model';
import { CompanyeditService } from '../companyedit/companyedit.service';

@Component({
  selector: 'app-disableconfirmation',
  templateUrl: './disableconfirmation.component.html',
  styleUrls: ['./disableconfirmation.component.css']
})
export class DisableconfirmationComponent implements OnInit {


  mycompanies:CompanyEmployee
  companylist: CompanyEmployee[];
  companylists: Company[];
  company:Company;
  companyId: number;
  disableFlag:boolean;
  

  constructor(private disableconfirmationService: DisableconfirmationService, private route: ActivatedRoute, private router: Router,
    private yourcompaniesService: YourcompaniesService,private companyeditService: CompanyeditService ) { }

  ngOnInit() {
    this.companyId = this.route.snapshot.params.id;
    this.mycompanies = new CompanyEmployee();
    this.mycompanies.company = new Company();
    this.company = new Company();
    this.disableFlag = true;
    

    this.yourcompaniesService.getAllMyCompanies()
      .subscribe( data => {
        this.companylist = data;
      },
      error => {
        this.router.navigate(['']);
        });


      }

       updateDisableFlag(){
        //alert(this.route.snapshot.params.id)

        this.disableconfirmationService.updateDisableFlag(this.route.snapshot.params.id)
                 .subscribe(data => {
                 this.disableFlag=true;
                 
                   },
              error => {
                 this.router.navigate(['']);
            });
          }


          makePrimaryCompanys(companyId) {
            
       this.disableconfirmationService.makePrimaryCompanys(companyId)
          .subscribe(data => {
             },
               error => {
                  this.router.navigate(['']);
               });
         }

      }
