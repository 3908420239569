import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from '../utils/rs.constants';
import { Company } from '../model/company.model';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'costcalculator/getAllEasyCalCompanyByCandidate.do';
  private appAllUrl = ShipStreetProps.API_PATH + 'costcalculator/getAllPrincipleCompany.do';

  public getAllEasyCalCompanyByCandidate() {
    return this.http.get<Company[]>(this.appUrl);
  }

  public getAllPrincipleCompany() {
    return this.http.get<Company[]>(this.appAllUrl);
  }
}
