import { Component, OnInit } from '@angular/core';
import { TrackmyorderService } from './trackmyorder.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplaceOrder } from '../../../model/marketplaceOrder.model';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { MarketplaceOrderStatus } from '../../../model/marketplaceorderstatus.model';

@Component({
  selector: 'app-trackmyorder',
  templateUrl: './trackmyorder.component.html',
  styleUrls: ['./trackmyorder.component.css']
})
export class TrackmyorderComponent implements OnInit {
  order: MarketplaceOrder;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  orderstatus: MarketplaceOrderStatus;

  constructor(private trackmyorderService: TrackmyorderService , private route: ActivatedRoute , private router: Router) { }

  ngOnInit() {
     this.trackmyorderService.trackmyorder(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.order = data;
       },
      error => {
        this.router.navigate(['']);
        });

        this.trackmyorderService.trackmyorderstatus(this.route.snapshot.params['id'])
      .subscribe( data => {
        this.orderstatus = data;
     //   alert(JSON.stringify(this.orderstatus));
      //  },
      // error => {
      //   this.router.navigate(['']);
        });
  }

}
