import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { TrackboxcustomersService } from '../../admin/trackboxcustomers/trackboxcustomers.service';

@Component({
  selector: 'app-tbagentcustomers',
  templateUrl: './tbagentcustomers.component.html',
  styleUrls: ['./tbagentcustomers.component.css']
})
export class TbagentcustomersComponent implements OnInit {

  constructor(private trackboxcustomersService: TrackboxcustomersService, private router: Router, private route: ActivatedRoute) { }
  trackboxCustomersList: TrackBoxCustomers[];
  p: number;
  ngOnInit() {
    this.trackboxcustomersService.getAllMyCustomers()
      .subscribe(data => {
        this.trackboxCustomersList = data;
  
      },
        error => {
          this.router.navigate(['']);
        });

  }

}
