import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Candidates } from 'src/app/model/candidate.model';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';

@Injectable({
  providedIn: 'root'
})
export class AccountactivationcodeverifyService {

  private activateURL = ShipStreetProps.API_PATH + 'accountActivationByCode.do';

  constructor(private http: HttpClient) { }

  public accountactivate(candidate: Candidates) {
    return this.http.post<SessionCandidate>(this.activateURL, candidate);
  }
}
