import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';

@Injectable({
  providedIn: 'root'
})
export class NewdepotService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'masterdepots/masterDepots.do';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';

  public save(masterDepots: MasterPortsDepot) {
    // alert(masterDepots);
    return this.http.post<string>(this.appUrl, masterDepots);
  }

  viewCountry() {
    return this.http.get<CountryInfo[]>(this.appUrls);
  }
}
