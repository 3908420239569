import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.css']
})
export class PaypalComponent implements OnInit {

  constructor() { }

  ngOnInit():void {
    //  console.log(window.paypal);
  paypal.Buttons({
       style:{
         layout:'horizontal',
         color: 'blue',
         shape : 'rect',
         label : 'paypal'
       },
       createOrder: (data, actions) =>{
       return actions.order.create({
        purchase_units: [{
          amount: {
            // value: '3000.00'
            // currency_code: 'USD'
          }
        }]
      });
    },
    onApprove: (data, actions) =>{
    return actions.order.capture().then(details => {
      alert("Transaction  Completed");
    });
  },
  onError : error => {
    console.log(TypeError);
  }
      })
      .rendor('#paypal-button-container');
  }

}
