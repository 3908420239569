import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Candidates } from 'src/app/model/candidate.model';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {

  private appUrl = ShipStreetProps.API_PATH + 'requestResetPasscode.do';
  private resetUrl = ShipStreetProps.API_PATH + 'resetPassword.rs';

  constructor(private http: HttpClient) { }

  public requestResetPasscode(emailAddress: string) {
    return this.http.post<Candidates>(this.appUrl , emailAddress);
  }
  public resetPasscode(emailAddress: string) {
    return this.http.post<string>(this.resetUrl , emailAddress);
  }
}
