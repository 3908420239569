import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerCustomer } from 'src/app/model/containercustomer.model';

@Injectable({
  providedIn: 'root'
})
export class EditcontainercustomerService {

  private appUrl = ShipStreetProps.API_PATH + 'containercustomer/getContainerCustomerById';
  private saveUrl = ShipStreetProps.API_PATH + 'containercustomer/updateContainerCustomer';
  constructor(private http: HttpClient) { }

  public getContainerCustomerById(customerId: string) {
    return this.http.post<ContainerCustomer>(this.appUrl , customerId);
  }

  public updateContainerCustomer(customer: ContainerCustomer) {
    return this.http.post<string>(this.saveUrl , customer);
  }
}
