import { Component, OnInit } from '@angular/core';
import { CustomerbalancesummaryService } from './customerbalancesummary.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customerbalancesummary',
  templateUrl: './customerbalancesummary.component.html',
  styleUrls: ['./customerbalancesummary.component.css']
})
export class CustomerbalancesummaryComponent implements OnInit {

  p = 1;
 // customerBalance: AccountsCompanyBalance[];
  total = 0;
  private value;

  constructor(private customerbalancesummaryService: CustomerbalancesummaryService, private router: Router) { }

  ngOnInit() {
    // this.customerbalancesummaryService.getAllCustomerAccountSummaryReports()
    //   .subscribe(data => {
    //     this.customerBalance = data;
    //     this.findsum(this.customerBalance);
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });
  }

  findsum(data) {
    //  debugger
    this.value = data
    for (let j = 0; j < data.length; j++) {
      this.total += this.value[j].balanceAmount;
    }
  }



}
