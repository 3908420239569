import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newaccountstype',
  templateUrl: './newaccountstype.component.html',
  styleUrls: ['./newaccountstype.component.css']
})
export class NewaccountstypeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
