import { Posts } from '../../model/posts.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';
import { FileUploadResponse } from 'src/app/model/fileuploadResponse.model';

@Injectable({
  providedIn: 'root'
})
export class NewpostService {

   private appUrl = ShipStreetProps.API_PATH + 'submitNewPost.do';
   private imageUploadUrl = ShipStreetProps.API_PATH + 'postCoveImageUpload.do';
  constructor(private http: HttpClient) { }

  public postCoverImageUpload(selectedFile: FormData) {
    return this.http.post<FileUploadResponse>(this.imageUploadUrl , selectedFile);
  }

   public submitPost(post: Posts) {
    return this.http.post<Posts>(this.appUrl , post);
  }
}
