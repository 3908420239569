import { AccountsProductServiceType } from './accountsproductservicetype.model';
import { AccountsProductCategory } from './accountsproductcategory.model';
import { AccountsAccount } from './accountsaccount.model';
import { Suppliers } from './suppliers.model';
import { AccountsTax } from './accountstax.model';

export class AccountsProductServices {
    id: number;
    accPnsType: AccountsProductServiceType;
    category: AccountsProductCategory;
    inventoryAssets: AccountsAccount;
    name: string;
    sku: string;
    initialQuantityInHand: number;
    initialquantityAspfDate: Date;
    reorderPoint: string;
    description: string;
    salesPrice: number;
    incomeAccount: AccountsAccount;
    inclusiveSalesTax: boolean;
    salesTax: AccountsTax;
    purchaseDesc: string;
    purchaseCost: string;
    expenseAccount: AccountsAccount;
    inclusivePurchaseTax: boolean;
    purchaseTax: AccountsTax;
    prefferedSupplier: Suppliers;
}
