import { Component, OnInit } from '@angular/core';
import { MasterVessels } from 'src/app/model/mastervessels.model';
import { TbagentnewvesselService } from './tbagentnewvessel.service';
import { Router } from '@angular/router';
import { MasterVesselOperator } from 'src/app/model/mastervesseloperator.model';
import { CompanyvesseloperatorService } from 'src/app/trackmybox/master/companyvesseloperator/companyvesseloperator.service';

@Component({
  selector: 'app-tbagentnewvessel',
  templateUrl: './tbagentnewvessel.component.html',
  styleUrls: ['./tbagentnewvessel.component.css']
})
export class TbagentnewvesselComponent implements OnInit {

  masterVessel: MasterVessels;
  companyVesselOperatorList: MasterVesselOperator[];

  constructor(private tbagentnewvesselService: TbagentnewvesselService, private router: Router,
              private companyvesseloperatorService: CompanyvesseloperatorService) { }

  ngOnInit() {
    this.masterVessel = new MasterVessels();
    this.masterVessel.vesselOperator = new MasterVesselOperator();

    this.companyvesseloperatorService.getAllVesselOperator()
      .subscribe(data => {
        this.companyVesselOperatorList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  saveCompanyVessel() {
    this.tbagentnewvesselService.saveCompanyVessel(this.masterVessel)
    .subscribe(data => {
       this.router.navigate(['/tbagentmode/tbagentcompanyvessels']);
       if (data == null) {
         alert('Already Exist');
       }
     },
       error => {
         this.router.navigate(['']);
       });

  }

}
