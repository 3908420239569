import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewtrackboxcustomersService {

constructor(private http: HttpClient) { }
private appUrl = ShipStreetProps.API_PATH + 'trackboxcustomers/tbCustomersSave.do';
public save(trackboxcustomers: TrackBoxCustomers) {
  return this.http.post<TrackBoxCustomers>(this.appUrl, trackboxcustomers);
}

}
