import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackerProperties } from 'src/app/model/trackerproperties.model';

@Injectable({
  providedIn: 'root'
})
export class NewtrackerpropertyService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + '/trackerproperty/saveTrackerProperty.do';

  public saveTrackerProperty(trackerProperty: TrackerProperties) {
    return this.http.post<string>(this.appUrl, trackerProperty);
  }
}
