import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { Company } from 'src/app/model/company.model';

@Injectable({
  providedIn: 'root'
})
export class MastersettingsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'costcalculator/getcostCalculatorCompany.do';
  private uppUrl = ShipStreetProps.API_PATH + 'costcalculator/updateCalcCompanyDefaultPort.do';

  public getcostCalculatorCompany() {
    return this.http.get<Company>(this.appUrl);
  }

  public updateCalcCompanyDefaultPort(calccompany: Company) {
    return this.http.post<Company>(this.uppUrl , calccompany);
  }
}

