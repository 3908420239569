import { Component, OnInit } from '@angular/core';
import { SurvicerequestlistService } from './survicerequestlist.service';
import { Router } from '@angular/router';
import { SurviceRequests } from 'src/app/model/survicerequests.model';

@Component({
  selector: 'app-survicerequestlist',
  templateUrl: './survicerequestlist.component.html',
  styleUrls: ['./survicerequestlist.component.css']
})
export class SurvicerequestlistComponent implements OnInit {
  serviceRequests1: SurviceRequests[];
  p = 1;

  constructor(private survicerequestlistService: SurvicerequestlistService , private router: Router) { }

  ngOnInit() {

      this.survicerequestlistService.getAllServiceRequest()
      .subscribe( data => {
        this.serviceRequests1 = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
