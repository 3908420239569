import { TrackBoxBooking } from './../../../model/trackboxbooking.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditbookingService {

  constructor(private http: HttpClient) { }

  private updateUrl = ShipStreetProps.API_PATH + 'TrackboxBookings/updateBookings.do';

  public updateBookings(tbbooking: TrackBoxBooking) {
    return this.http.post<string>(this.updateUrl, tbbooking);
  }
  
}
