import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { Candidates } from 'src/app/model/candidate.model';

@Injectable({
  providedIn: 'root'
})
export class AccountdeactivateedmessageService {

  constructor(private http: HttpClient) { }
  private activateUrl = ShipStreetProps.API_PATH + 'candidateAccountActivate.do';

  public activateAccount(candidateId: string) {
    return this.http.post<Candidates>(this.activateUrl , candidateId);
  }
}
