import { Component, OnInit } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { MastersettingsService } from './mastersettings.service';
import { Router } from '@angular/router';
import { Company } from 'src/app/model/company.model';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { Candidates } from 'src/app/model/candidate.model';
import { PreferenceService } from 'src/app/jobs/preferences/preference.service';

@Component({
  selector: 'app-mastersettings',
  templateUrl: './mastersettings.component.html',
  styleUrls: ['./mastersettings.component.css']
})
export class MastersettingsComponent implements OnInit {
  calccompany: Company;
  masterPortListAll: MasterPortsDepot[];
  candidate: Candidates;

  constructor(private preferenceService: PreferenceService,private mastersettingsService: MastersettingsService,
    private router: Router, private portService: PortsService) { }

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  phonediv: boolean;

  ngOnInit() {
    this.calccompany = new Company();
    this.calccompany.defaultPort = new MasterPortsDepot();
    this.phonediv = false;
    this.mastersettingsService.getcostCalculatorCompany()
      .subscribe(data => {
        this.calccompany = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.portService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortListAll = data;
      },
        error => {
          this.router.navigate(['']);
        });

        this.preferenceService.sessionCandidate()
      .subscribe(data => {
        this.candidate = data;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  updateCalcCompanyDefaultPort() { alert(JSON.stringify(this.calccompany));
    this.mastersettingsService.updateCalcCompanyDefaultPort(this.calccompany)
      .subscribe(data => {
        this.phonediv = false;
        this.calccompany = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
