import { Candidates } from '../../model/candidate.model';
import { Company } from '../../model/company.model';
import { Jobs } from '../../model/jobs.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class GlobelsearchService {

  private appUrl = ShipStreetProps.API_PATH + 'globalSearchPeople.do';
  private jobUrl = ShipStreetProps.API_PATH + 'globalSearchJobs.do';
  private compUrl = ShipStreetProps.API_PATH + 'globalSearchCompany.do';

  constructor(private http: HttpClient) { }

  public searchPeople(searchTxt: string) {
    return this.http.post<Candidates[]>(this.appUrl , searchTxt);
  }
   public searchJobs(searchTxt: string) {
    return this.http.post<Jobs[]>(this.jobUrl , searchTxt);
  }
   public searchCompany(searchTxt: string) {
    return this.http.post<Company[]>(this.compUrl , searchTxt);
  }
}
