import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { HttpClient } from '@angular/common/http';
import { ContainerLeasingUnit } from 'src/app/model/containerleasingunit.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerleasegateinService {

  private saveUrl = ShipStreetProps.API_PATH + 'containerleaseunit/updateGateIn';
  constructor(private http: HttpClient) { }

  public updateGateIn(leaseUnits: ContainerLeasingUnit) {
    return this.http.post<string>(this.saveUrl , leaseUnits);
  }
}
