import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';

@Injectable({
  providedIn: 'root'
})
export class SaleequipmentService {

constructor(private http: HttpClient) { }
private appUrls = ShipStreetProps.API_PATH + 'trackboxcustomers/findAllTrackBoxCustomersByCompany.do';
private appurl = ShipStreetProps.API_PATH + 'cntequipmentcontainers/saleSave.do';
viewCustomer() {
  return this.http.get<TrackBoxCustomers[]>(this.appUrls);
}

public saleSave(saleeqp: TrackBoxContainer) {
  //  alert(JSON.stringify(offhireeqp));
   return this.http.post<TrackBoxContainer>(this.appurl , saleeqp);
 }
}
