export class CountryInfo {
  id: number;
  name: string;
  code: string;
  continent: string;
  region: string;
  surfaceArea: number;
  indepYear: number;
  population: number;
  lifeExpectancy: number;
  gnp: number;
  code2: string;
}
