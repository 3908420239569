import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculatorsubscriptioncheckout',
  templateUrl: './calculatorsubscriptioncheckout.component.html',
  styleUrls: ['./calculatorsubscriptioncheckout.component.css']
})
export class CalculatorsubscriptioncheckoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
