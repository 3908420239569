import { Component, OnInit } from '@angular/core';
import { AddcalculatorusersService } from './addcalculatorusers.service';
import { Router } from '@angular/router';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';

@Component({
  selector: 'app-addcalculatorusers',
  templateUrl: './addcalculatorusers.component.html',
  styleUrls: ['./addcalculatorusers.component.css']
})
export class AddcalculatorusersComponent implements OnInit {
  jsonList: CompanyEmployee[];

  constructor(private addcalculatorusersService: AddcalculatorusersService, private router: Router) { }

  existingemployeecandidate: CompanyEmployee[];
  p: number;
  ngOnInit() {

    this.addcalculatorusersService.findAllCalcCompanyEmployeeRecords()
      .subscribe(data => {
        this.existingemployeecandidate = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  asignCalculatorPermission(comemployee) {
    this.addcalculatorusersService.asignCalculatorPermission(comemployee)
      .subscribe(data => {
        this.jsonList = this.existingemployeecandidate;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === comemployee.id) {
            this.existingemployeecandidate[i].costCalulator = true;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  removeCalculatorPermission(comemployee) {
    this.addcalculatorusersService.removeCalculatorPermission(comemployee)
      .subscribe(data => {
        this.jsonList = this.existingemployeecandidate;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === comemployee.id) {
            this.existingemployeecandidate[i].costCalulator = false;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
