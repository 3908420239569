import { Marketplace } from '../../../model/marketplace.model';
import { MarketplaceOrder } from '../../../model/marketplaceOrder.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from '../../../utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceMyorderService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'getMyMarketplaceItems.do';

 public getMyOrderDetails() {
    return this.http.get<MarketplaceOrder[]>(this.appUrl);
  }
}
