import { CacheObject } from '../../model/cacheobject.model';
import { Company } from '../../model/company.model';
import { CompanyIndustry } from '../../model/companyIndustry.model';
import { NewcompanyService } from './newcompany.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/utils/common.service';
import { NewboxagentService } from 'src/app/trackmybox/admin/boxagents/newboxagent/newboxagent.service';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { PortsService } from 'src/app/trackmybox/master/ports/ports.service';


@Component({
  selector: 'app-newcompany',
  templateUrl: './newcompany.component.html',
  styleUrls: ['./newcompany.component.css']
})
export class NewcompanyComponent implements OnInit {
  companyindustries: CacheObject;
  result: string;
  comp: Company;
  companytype = ['Port', 'Depot', 'Shipping Lane', 'NVOCC', 'Leasing', 'Logistics', 'Transports', 'Trader', 'Other'];
  compsize = ['1', '2-10', '11-50', '51-200', '201-500', '501-1,000', '1,001-5,000', '5,000+'];
  companystatus = ['PRINCIPLE', 'AGENT', 'OTHER'];
  showimg: boolean;
  loading = true;
  isDisabled: boolean;
  myControl1 = new FormControl();
  countryList: CountryInfo[];
  filteredOptionsContry: Observable<CountryInfo[]>;
  masterPortList: MasterPortsDepot[];
  myControl2 = new FormControl();
  filteredOptionsPort: Observable<MasterPortsDepot[]>;


  constructor(private newcompanyService: NewcompanyService, private router: Router, private cookieService: CookieService,
    private commonService: CommonService, private newboxagentService: NewboxagentService,private portsService: PortsService) { }

  selectedFile: File;
  previewFile: any;
  sessionCandidate = this.commonService.getSessionCandidate();

  onFileChanged(event) {



    if (event.target) {
      const reader = new FileReader();
      this.selectedFile = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);


      reader.onload = (event1: any) => { // called once readAsDataURL is completed
        this.previewFile = event1.target.result;
      };
    }



    //
  }


  ngOnInit() {
    this.loading = false;
    this.isDisabled = false;
    this.comp = new Company();
    this.comp.defaultPort=new MasterPortsDepot();
    this.comp.country = new CountryInfo();
    this.comp.industry = new CompanyIndustry();
    this.newcompanyService.companyIndustries()
      .subscribe(data => {
        this.companyindustries = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.newboxagentService.viewCountry()
      .subscribe(data => {
        this.countryList = data;
      },
        error => {
          this.router.navigate(['']);
        });

    this.filteredOptionsContry = this.myControl1.valueChanges.pipe(
      // startWith(''),
      map(val => this._filter1(val))
    );

    this.portsService.getAllMyPorts()
      .subscribe(data => {
        this.masterPortList = data;
      },
        error => {
          this.router.navigate(['']);
        });
    
    this.filteredOptionsPort= this.myControl2.valueChanges.pipe(
          // startWith(''),
          map(val => this._filter2(val))
        );
  }

  // public _filter1(value: string): CountryInfo[] {
  //   const filterValue1 = value.toLowerCase();
  //   return this.countryList.filter(country => country.name.toLowerCase().indexOf(filterValue1) === 0 ||
  //     country.id === 0);

  // }

  public _filter1(value: string): CountryInfo[] {
    const filterValue1 = value;
    return this.countryList.filter(country => country.name.toLowerCase().indexOf(filterValue1) === 0 ||
      country.name.toLowerCase().indexOf(filterValue1) === 0 ||
      country.name.toUpperCase().indexOf(filterValue1) === 0);
  }
  public _filter2(value: string): MasterPortsDepot[] {
    const filterValue2 = value;
    return this.masterPortList.filter(defaultPort => defaultPort.name.toLowerCase().indexOf(filterValue2) === 0 ||
    defaultPort.name.toLowerCase().indexOf(filterValue2) === 0 ||
    defaultPort.name.toUpperCase().indexOf(filterValue2) === 0);
  }
  newCompanyValues() {
    this.loading = true;
    this.isDisabled = true;
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile);
    //  console.log(uploadData)
    this.newcompanyService.companyImageUpload(uploadData).subscribe(data => {
      this.comp.companyLogo = data.fileName;
      this.newcompanyService.saveType(this.comp)
        .subscribe(data1 => {
          this.result = data1;
          // if (this.sessionCandidate.trackmyboxStatus !== 'PRINCIPLE' && this.sessionCandidate.trackmyboxStatus !== 'AGENT') {
          this.cookieService.set('TMB_STAT', this.sessionCandidate.trackmyboxStatus, null, null, null, null, null);
          //   } else {
          //    this.cookieService.set('TMB_STAT', this.sessionCandidate.trackmyboxStatus, null, null, null, null, null);
          //   }
          this.router.navigate(['/m/companyview']);
        },
          error => {
            this.router.navigate(['']);
          });
    },
      error => {
        this.router.navigate(['']);
      });


  }

  displayCountry(cntr: CountryInfo): string {
    return cntr && cntr.name ? cntr.name : '';
  }
  displayFn(user: MasterPortsDepot): string {
    return user && user.name ? user.name : '';
  }
}
