import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Candidates } from 'src/app/model/candidate.model';
import { CompanyEmployee } from 'src/app/model/companyemployee.model';
import { NewboxemployeeService } from 'src/app/trackmybox/admin/boxemployees/newboxemployee/newboxemployee.service';
import { NewemployeeService } from './newemployee.service';

@Component({
  selector: 'app-newemployee',
  templateUrl: './newemployee.component.html',
  styleUrls: ['./newemployee.component.css']
})
export class NewemployeeComponent implements OnInit {
  jsonList: CompanyEmployee[];

  constructor(private newemployeeService: NewemployeeService, private router: Router) { }

  existingemployeecandidate: CompanyEmployee[];
  p: number;
  ngOnInit() {

    this.newemployeeService.findAllContainerCompanyEmployees()
      .subscribe(data => {
        this.existingemployeecandidate = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  asignContainerTradingPermission(comemployee) {
    this.newemployeeService.asignContainerTradingPermission(comemployee)
      .subscribe(data => {
        this.jsonList = this.existingemployeecandidate;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === comemployee.id) {
            this.existingemployeecandidate[i].containerTrading = true;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

  removeContainerTradingPermission(comemployee) {
    this.newemployeeService.removeContainerTradingPermission(comemployee)
      .subscribe(data => {
        this.jsonList = this.existingemployeecandidate;
        for (let i = 0; i < this.jsonList.length; i++) {
          const obj = this.jsonList[i];
          if (obj.id === comemployee.id) {
            this.existingemployeecandidate[i].containerTrading = false;
            break;
          }
        }
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
