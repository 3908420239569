import { TrackBoxBooking } from 'src/app/model/trackboxbooking.model';
import { TbagentaddconsigneeService } from './tbagentaddconsignee.service';
import { Component, Inject, OnInit } from '@angular/core';
import { TrackboxcustomersService } from 'src/app/trackmybox/admin/trackboxcustomers/trackboxcustomers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TbAgentConsigneeDTO } from 'src/app/model/tbagentconsigneeDTO.model';

export interface DialogData {
  leaseUnitId: number;
}
@Component({
  selector: 'app-tbagentaddconsignee',
  templateUrl: './tbagentaddconsignee.component.html',
  styleUrls: ['./tbagentaddconsignee.component.css']
})
export class TbagentaddconsigneeComponent implements OnInit {
  trackboxCustomersList: TrackBoxCustomers[];
  tbbooking: TrackBoxBooking;
  consigneeadd:TbAgentConsigneeDTO;
  constructor(private tbagentaddconsigneeService: TbagentaddconsigneeService, private trackboxcustomersService: TrackboxcustomersService,
    private router: Router, private route: ActivatedRoute, public dialogoutRef: MatDialogRef<TbagentaddconsigneeComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.tbbooking = new TrackBoxBooking();
    this.consigneeadd = new TbAgentConsigneeDTO();
    this.tbbooking.consignee =new TrackBoxCustomers();
    this.tbbooking.id = this.data.leaseUnitId;
    this.trackboxcustomersService.getAllMyCustomers()
      .subscribe(data => {
        this.trackboxCustomersList = data;
         //alert(JSON.stringify(this.trackboxCustomersList));
      },
        error => {
          this.router.navigate(['']);
        });
  }
  addConsignee() {
    // this.tbbooking.bookingId = this.data.leaseUnitId;
      console.log(JSON.stringify(this.consigneeadd));
     this.consigneeadd = { booking: this.data.leaseUnitId, consignee: this.consigneeadd.consignee};

     this.tbagentaddconsigneeService.addConsignee(this.consigneeadd)
       .subscribe(data => {
         this.snackBar.open('STATUS SUCCESSFULLY UPDATED!!', 'End now', {
           duration: 500,

         });
         this.dialogoutRef.close(0);
       },

         error => {
           this.router.navigate(['']);
         });

   }


   onNoClick(): void {
     this.dialogoutRef.close();

   }


}
