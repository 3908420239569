import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { CandidateProfileStrength } from 'src/app/model/candidateprofilestrength.model';

@Injectable({
  providedIn: 'root'
})
export class CandidatestrengthService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'getCandidateProfileStrength.do';
   private updateProfileImageUrl = ShipStreetProps.API_PATH + 'updateProfileImage.do';
   private updateProfileStrengthUrl = ShipStreetProps.API_PATH + 'updateProfileStrength.do';

  public getCandidateStrength() {
    return this.http.get<CandidateProfileStrength>(this.appUrl);
  }

   public updateProfileImage(selectedFile: FormData) {
    return this.http.post<string>(this.updateProfileImageUrl , selectedFile);
  }

   public updateProfileStrength() {
    return this.http.get<string>(this.updateProfileStrengthUrl);
  }
}
