import { Component, OnInit } from '@angular/core';
import { AccountsdashboardService } from './accountsdashboard.service';
import { ContainerDashboardCountDto } from 'src/app/model/containerdashboardcountDTO.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accountsdashboard',
  templateUrl: './accountsdashboard.component.html',
  styleUrls: ['./accountsdashboard.component.css']
})
export class AccountsdashboardComponent implements OnInit {
  dashboardCount: ContainerDashboardCountDto;

  constructor(private accountsdashboardService: AccountsdashboardService, private router: Router) { }

  ngOnInit() {
    this.accountsdashboardService.getAllAccountsDashboardCounts()
    .subscribe(data => {
      this.dashboardCount = data;
    },
      error => {
        this.router.navigate(['']);
      });
  }

}
