import { ContainerCompany } from './containercompany.model';
import { ContainerLocationType } from './containerlocationtype.model';
import { ContainerType } from './containertype.model';

export class ContainerOnewayMove {
    id: number;
    company: ContainerCompany;
    locationType: ContainerLocationType;
    containerType: ContainerType;
    location: string;
    movingFrom: string;
    movingTo: string;
    quantity: number;
    freeDays: number;
    deliveryDays: number;
    detention: boolean;
    detentionAmount: number;
    createdOn: Date;
    pickupCharge: number;
    perDayCharge: number;
    movedQuantity: number;
}
