import { TrackBoxAgent } from './../../model/trackboxagent.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SessionCandidate } from 'src/app/model/sessioncandidate.model';
import { CommonService } from 'src/app/utils/common.service';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { AgentsService } from './agents.service';
import { AgentDashboardCountDto } from 'src/app/model/agentdashboardcountDTO.model';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {

tbAgentPrinciples: TrackBoxAgent;
   agentdashboardCount: AgentDashboardCountDto;
  // boxcountbycontainertype: BoxCountByContainerTypeDTO[];
  sessionCandidate: SessionCandidate;
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  panelOpenState: boolean;
  showme: boolean;
  customOptions: OwlOptions;
 selectPrinciple: string;
  constructor(private agentsService: AgentsService, private router: Router, private commonService: CommonService) { }

  ngOnInit() {
    this.sessionCandidate = this.commonService.getSessionCandidate();
    this.sessionCandidate.company = this.commonService.getSessionCandidate().company;

    this.agentsService.getAllTbPrinciples()
      .subscribe(data => {
        this.tbAgentPrinciples = data;
      },
        error => {
          this.router.navigate(['']);
        });

        // this.agentsService.getAllAgentDashboardCounts()
        // .subscribe(data => {
        //   this.agentdashboardCount = data;
        // },
        //   error => {
        //     this.router.navigate(['']);
        //   });

    // this.agentsService.getAllBoxCountByContainerType('ALL')
    //   .subscribe(data => {
    //     this.boxcountbycontainertype = data;
    //     //   console.log("Data Count :" +JSON.stringify(data));
    //   },
    //     error => {
    //       this.router.navigate(['']);
    //     });


    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      autoplay: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 6
        },
        400: {
          items: 1
        },
        740: {
          items: 1
        },
        940: {
          items: 8
        },
        1024: {
          items: 7
        }
      },
      nav: true
    };


  }
  getContinerCountByType(principleId){
    this.selectPrinciple = principleId;
    this.agentsService.getAllAgentDashboardCounts(principleId)
     .subscribe(data => {

       this.agentdashboardCount = data;
      //  alert (JSON.stringify(this.agentdashboardCount));
     },
       error => {
        this.router.navigate(['']);
       });
  }
  }


