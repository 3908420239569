import { Component, OnInit } from '@angular/core';
import { PrinciplerequestsService } from './principlerequests.service';

@Component({
  selector: 'app-principlerequests',
  templateUrl: './principlerequests.component.html',
  styleUrls: ['./principlerequests.component.css']
})
export class PrinciplerequestsComponent implements OnInit {
  serv: string;

  constructor(private principlerequestsService: PrinciplerequestsService) { }

  ngOnInit() {
    this.serv = 'shabi Kunjumon';
  }

}
