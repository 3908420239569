import { CompanyEmployee } from './companyemployee.model';
import { CompanyIndustry } from './companyIndustry.model';
import { CountryInfo } from './countryInfo.model';
import { MasterPortsDepot } from './masterportsdepot.model';
export class Company {
    id: number;
  //  candidate: Candidates;
    companyName: string;
    streetAddr1: string;
    streetAddr2: string;
    city: string;
    state: string;
    zipCode: string;
    country: CountryInfo;
    createdOn: string;
    yearFound: string;
    companySize: string;
    companyType: string;
    companyStatus: string;
    industry: CompanyIndustry;
    about: string;
    followCount: number;
    employeeCount: string;
    companyLogo: string;
    companyBanner: string;
    lastUpdatedOn: string;
    summary: string;
    status: string;
    followedByCandidate: boolean;
    loggedCandidateAnEmployee: boolean;
    trackMyBox: boolean;
    containerTrading: boolean;
    companyCostCalculator: boolean;
    defaultPort: MasterPortsDepot;
    costCalculatorStartsOn: Date;
    logCandEmployDetails: CompanyEmployee;
}

