import { TrackBoxCarrier } from './../../../model/trackboxcarrier.model';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackboxcarriersService } from './trackboxcarriers.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trackboxcarriers',
  templateUrl: './trackboxcarriers.component.html',
  styleUrls: ['./trackboxcarriers.component.css']
})
export class TrackboxcarriersComponent implements OnInit {
trackboxcarrierList: TrackBoxCarrier[];
p: number;
panelOpenState: boolean;
  constructor(private trackboxcarriersService: TrackboxcarriersService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.trackboxcarriersService.getAllMyCarriers()
      .subscribe(data => {
        this.trackboxcarrierList = data;
      },
        error => {
          this.router.navigate(['']);
        });
  }

}
