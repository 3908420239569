import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobsfilter'
})
export class JobsPipeFilter implements PipeTransform {
  transform(jobList: any[], searchText: string): any[] {
    if (!jobList) {
        return jobList;
    }
    if (!searchText) {
        return jobList;
    }
  searchText = searchText.toLowerCase();
  return jobList.filter( job => {
        if ( job && job.jobTitle.toLowerCase().indexOf(searchText) !== -1 ) {
            return true;
        } else {
            return false;
        }
    });
   }

}

