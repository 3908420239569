import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'src/app/model/customer.model';
import { CustomersService } from './customers.service';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
    customerList: Customer[];
    p = 1;
    searchText: string;
    customerListAll: Customer[];
 

    constructor( private customerService: CustomersService, private router: Router) { }

    ngOnInit() {
        this.customerService.getAllCustomers()
            .subscribe(data => {
                this.customerListAll = data;
                this.customerList = this.customerListAll;
            },
                error => {
                    this.router.navigate(['']);
                });

    }

    search() {
        if (this.searchText !== '') {
            this.customerList = this.customerListAll.filter(res => {
                return (res.name !== null && res.name.toLowerCase().match(this.searchText.toLocaleLowerCase())) ||
                    (res.address !== null && res.address.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
                    (res.phone !== null && res.phone.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase())) ||
                    (res.contactEmail !== null && res.contactEmail.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase()));
            });
        } else if (this.searchText === '') {
            this.customerList = this.customerListAll;
        }
    }

}
