import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace40',
  templateUrl: './marketplace40.component.html',
  styleUrls: ['./marketplace40.component.css']
})
export class Marketplace40Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
