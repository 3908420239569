import { SaleequipmentService } from './saleequipment.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewequipmentcontrolService } from '../newequipmentcontrol/newequipmentcontrol.service';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxCustomers } from 'src/app/model/trackboxcustomers.model';
import { TrackBoxContainer } from 'src/app/model/trackboxcontainer.model';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
export interface DialogData {
  leaseUnitId: number;
}
@Component({
  selector: 'app-saleequipment',
  templateUrl: './saleequipment.component.html',
  styleUrls: ['./saleequipment.component.css']
})

export class SaleequipmentComponent implements OnInit {

  trackboxeqpcontainer: TrackBoxContainer;
  CountryList: CountryInfo[];
  customerlist: TrackBoxCustomers[];
  countryId = 0;
  myControl3 = new FormControl();
  filteredOptionscountry: Observable<CountryInfo[]>;
  constructor(private saleequipmentService: SaleequipmentService, private router: Router,
              public dialogoutRef: MatDialogRef<SaleequipmentComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private snackBar: MatSnackBar, private newequipmentcontrolService: NewequipmentcontrolService) { }

  ngOnInit() {

    this.trackboxeqpcontainer = new TrackBoxContainer();
    this.trackboxeqpcontainer.saleTo = new TrackBoxCustomers();
    this.trackboxeqpcontainer.saleLocation = new CountryInfo();
    this.newequipmentcontrolService.viewCountry()
    .subscribe(data => {
      this.CountryList = data;
      this.filteredOptionscountry = this.myControl3.valueChanges.pipe(
        // startWith(''),
        map(val => this._filter4(val))
      );

      console.log(this.filteredOptionscountry);
  },
      () => {
        this.router.navigate(['']);
      });

    this.saleequipmentService.viewCustomer()
    .subscribe(data => {
      this.customerlist = data;
     // alert(JSON.stringify(this.customerlist));
  },
      () => {
        this.router.navigate(['']);
      });
  }


  onNoClick(): void {
    this.dialogoutRef.close();

  }
  salesubmit() {
     //  alert(JSON.stringify(this.trackboxeqpcontainer));
    // alert(this.data.leaseUnitId);
     this.trackboxeqpcontainer.id = this.data.leaseUnitId;
     this.saleequipmentService.saleSave(this.trackboxeqpcontainer)
       .subscribe(() => {
         this.snackBar.open('SOLD SUCCESSFULLY !!', 'End now', {
           duration: 2000,

         });
         this.dialogoutRef.close(0);
         this.router.navigate(['/trackmybox/equipmentcontroller']);
        },

        () => {
          this.router.navigate(['']);
        });

  }

  public _filter4(value: string): CountryInfo[] {
    const filterValue4 = value;
    return this.CountryList.filter(country => country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toLowerCase().indexOf(filterValue4) === 0 ||
      country.name.toUpperCase().indexOf(filterValue4) === 0);
  }

  public displayCountry(cntr: CountryInfo): string {
    if (cntr && cntr.name) {
      return cntr.name + '-' + cntr.code2;

    }
  }
  }


