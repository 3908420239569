import { from } from 'rxjs';
// import { MasterVoyageRoute } from './../model/mastervoyageroute.model';
// import { LoginLayoutComponent } from './../_layouts/login-layout/login-layout.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { trackmyboxRoutes } from './trackmybox.routerconfig';
import { EquipmentcontrolComponent } from './equipmentcontrol/equipmentcontrol.component';
import { NewequipmentcontrolComponent } from './equipmentcontrol/newequipmentcontrol/newequipmentcontrol.component';
import { BoxemployeesComponent } from './admin/boxemployees/boxemployees.component';
import { BoxagentsComponent } from './admin/boxagents/boxagents.component';
import { NewboxemployeeComponent } from './admin/boxemployees/newboxemployee/newboxemployee.component';
import { NewboxagentComponent } from './admin/boxagents/newboxagent/newboxagent.component';
import { ContainertypesComponent } from './master/containertypes/containertypes.component';
import { NewcontainertypeComponent } from './master/containertypes/newcontainertype/newcontainertype.component';
import { PortsComponent } from './master/ports/ports.component';
import { DepotsComponent } from './master/depots/depots.component';
import { LocationtypeComponent } from './master/locationtype/locationtype.component';
import { NewportComponent } from './master/ports/newport/newport.component';
import { NewdepotComponent } from './master/depots/newdepot/newdepot.component';
import { NewlocationtypeComponent } from './master/locationtype/newlocationtype/newlocationtype.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import {EquipmentdetailsComponent} from './equipmentcontrol/equipmentdetails/equipmentdetails.component';
import {TrackboxsupplierComponent} from './admin/trackboxsupplier/trackboxsupplier.component';
import {SaleequipmentComponent} from './equipmentcontrol/saleequipment/saleequipment.component';
import {TrackboxcustomersComponent} from './admin/trackboxcustomers/trackboxcustomers.component';
import {NewtrackboxcustomersComponent} from './admin/trackboxcustomers/newtrackboxcustomers/newtrackboxcustomers.component';

import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
  MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
  MatBottomSheetModule, MatAutocompleteModule, MatDatepickerModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MyboxesComponent } from './myboxes/myboxes.component';
import { BookingsComponent } from './bookings/bookings.component';
import { CompanyvesseloperatorComponent } from './master/companyvesseloperator/companyvesseloperator.component';
// tslint:disable-next-line: max-line-length
import { NewcompanyvesseloperatorComponent } from './master/companyvesseloperator/newcompanyvesseloperator/newcompanyvesseloperator.component';
import { CompanyvesselsComponent } from './master/companyvessels/companyvessels.component';
import { NewcompanyvesselComponent } from './master/companyvessels/newcompanyvessel/newcompanyvessel.component';
import { VoyageComponent } from './master/voyage/voyage.component';
import { NewvoyageComponent } from './master/voyage/newvoyage/newvoyage.component';
import { TrackboxcarriersComponent } from './admin/trackboxcarriers/trackboxcarriers.component';
import { NewtrackboxcarrierComponent } from './admin/trackboxcarriers/newtrackboxcarrier/newtrackboxcarrier.component';
import { NewbookingComponent } from './bookings/newbooking/newbooking.component';
import { SavesheetComponent } from './savesheet/savesheet.component';
import { ExistingemployeesComponent } from './admin/boxemployees/existingemployees/existingemployees.component';
import { VoyagestatusdetailsComponent } from './voyagestatusdetails/voyagestatusdetails.component';
import { BookingcontainersviewComponent } from './bookings/bookingcontainersview/bookingcontainersview.component';
import { VoyagerouteComponent } from './master/voyage/voyageroute/voyageroute.component';
import { EditvesseloperatorComponent } from './master/companyvesseloperator/editvesseloperator/editvesseloperator.component';
import {UpdatebookingComponent} from './bookings/updatebooking/updatebooking.component';
import {UpdateequipmentComponent} from './equipmentcontrol/equipmentdetails/updateequipment/updateequipment.component';
import {OffhireequipmentComponent} from './equipmentcontrol/offhireequipment/offhireequipment.component';
import {EditequipmentcontrollerComponent} from './equipmentcontrol/editequipmentcontroller/editequipmentcontroller.component';
import { UpcommingbookingComponent } from './upcommingbooking/upcommingbooking.component';
import {NewtrackboxsupplierComponent} from './admin/trackboxsupplier/newtrackboxsupplier/newtrackboxsupplier.component';
import { CurrentbookingComponent } from './currentbooking/currentbooking.component';
import { EquipmentcontrollistComponent } from './equipmentcontrollist/equipmentcontrollist.component';
import { EquipmentcontroltypelistComponent} from './equipmentcontrol/equipmentcontroltypelist/equipmentcontroltypelist.component';
import { BlupdateComponent } from './bookings/blupdate/blupdate.component';
import { AgentsComponent } from './agents/agents.component';
import { SearchboxagentComponent } from './admin/boxagents/searchboxagent/searchboxagent.component';
import {EditbookingComponent} from './bookings/editbooking/editbooking.component';

// import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from  'ngx-ui-loader';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";
import {RequestfromagentComponent} from './bookings/requestfromagent/requestfromagent.component';
import { TrackmyboxsitesComponent } from './trackmyboxsites/trackmyboxsites.component';
import {ChargeheadsComponent} from './master/chargeheads/chargeheads.component';
import {NewchargeheadsComponent} from './master/chargeheads/newchargeheads/newchargeheads.component';
import { CurrencysettingsComponent } from './admin/currencysettings/currencysettings.component';
import { ReponsetoagentComponent } from './bookings/reponsetoagent/reponsetoagent.component';
import { AgentoperationreportsComponent } from './reports/agentoperationreports/agentoperationreports.component';
import { TrackmyboxRouterModule } from './trackmybox-router.module';
import { EditportsComponent } from './master/ports/editports/editports.component';
import { EditdepotsComponent } from './master/depots/editdepots/editdepots.component';
import { TrackmyboxpricingComponent } from './trackmyboxpricing/trackmyboxpricing.component';
import { EdittrackboxsupplierComponent } from './admin/trackboxsupplier/edittrackboxsupplier/edittrackboxsupplier.component';
import { EdittrackboxcustomersComponent } from './admin/trackboxcustomers/edittrackboxcustomers/edittrackboxcustomers.component';
import { TrackmyboxsubscriptioncheckoutComponent } from './trackmyboxsubscriptioncheckout/trackmyboxsubscriptioncheckout.component';


@NgModule({
  declarations: [EquipmentcontrolComponent, NewequipmentcontrolComponent, BoxemployeesComponent,
    BoxagentsComponent, NewboxemployeeComponent, VoyagerouteComponent,
    BoxagentsComponent, NewboxemployeeComponent, EquipmentdetailsComponent,
    NewboxagentComponent, ContainertypesComponent, NewcontainertypeComponent, PortsComponent,
    DepotsComponent, LocationtypeComponent, NewportComponent, NewdepotComponent, NewlocationtypeComponent,
    MyboxesComponent, BookingsComponent, CompanyvesseloperatorComponent, NewcompanyvesseloperatorComponent,
    CompanyvesselsComponent, NewcompanyvesselComponent, VoyageComponent, NewvoyageComponent, TrackboxcarriersComponent,
    NewtrackboxcarrierComponent, NewbookingComponent, SavesheetComponent, ExistingemployeesComponent, VoyagestatusdetailsComponent ,
    BookingcontainersviewComponent, UpdatebookingComponent, UpdateequipmentComponent, TrackboxsupplierComponent,
    TrackboxcustomersComponent, NewtrackboxcustomersComponent, NewtrackboxsupplierComponent, OffhireequipmentComponent,
    SaleequipmentComponent, EquipmentcontroltypelistComponent, EditvesseloperatorComponent, EditequipmentcontrollerComponent,
    UpcommingbookingComponent, CurrentbookingComponent, EquipmentcontrollistComponent, BlupdateComponent, AgentsComponent,
    SearchboxagentComponent, EditbookingComponent, RequestfromagentComponent, TrackmyboxsitesComponent,
    ChargeheadsComponent, NewchargeheadsComponent, CurrencysettingsComponent, ReponsetoagentComponent, AgentoperationreportsComponent, EditportsComponent, EditdepotsComponent, TrackmyboxpricingComponent, EdittrackboxsupplierComponent, EdittrackboxcustomersComponent, TrackmyboxsubscriptioncheckoutComponent, 
    
  ],
  imports: [
    CommonModule, TrackmyboxRouterModule, MatToolbarModule, MatButtonModule,
     MatSidenavModule, MatIconModule, MatListModule, MatProgressSpinnerModule,
    MatGridListModule, MatCardModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatFormFieldModule, MatInputModule, MatSelectModule, MatNativeDateModule, MatCheckboxModule,
    MatRadioModule, MatTabsModule, MatBadgeModule, MatDialogModule, MatExpansionModule, MatSliderModule,
    MatBottomSheetModule, MatAutocompleteModule, MatDatepickerModule,
    MatSlideToggleModule, FormsModule, FlexLayoutModule, NgxPaginationModule,
    ReactiveFormsModule, NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule,
  ],
  exports: [UpcommingbookingComponent , CurrentbookingComponent, AgentoperationreportsComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [ReponsetoagentComponent]
})
export class TrackmyboxModule { }
