import { Candidates } from './candidate.model';
import { Certificate } from './certificate.model';
export class CandidateCertificate {
    id: number;
    candidate: Candidates;
    certificate: Certificate;
    issuedOn: Date;
    expiredOn: Date;
    certDiv: boolean;
}
