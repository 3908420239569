import { CompanyVoyage } from './../../../../model/companyvoyage.model';
import { Router, ActivatedRoute } from '@angular/router';
import { VoyagedetailsService } from './voyagedetails.service';
import { Component, OnInit } from '@angular/core';
import { MasterVessels } from 'src/app/model/mastervessels.model';
import { MasterVoyage } from 'src/app/model/mastervoyage.model';
import { CompanyVessels } from 'src/app/model/companyvessels.model';

@Component({
  selector: 'app-voyagedetails',
  templateUrl: './voyagedetails.component.html',
  styleUrls: ['./voyagedetails.component.css']
})
export class VoyagedetailsComponent implements OnInit {
  voyagedetails: CompanyVoyage;
  voyagesdetails: MasterVoyage[];
  constructor(private voyagedetailsService: VoyagedetailsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.voyagedetails = new CompanyVoyage();
    this.voyagedetails.voyage = new MasterVoyage();
    // this.mastervessel = new MasterVessels();
    // this.voyagedetails.vessel = new CompanyVessels();
    this.voyagedetails.voyage.vessel = new MasterVessels();
    this.voyagedetailsService.getVoyageDetails(this.route.snapshot.params.id)
    .subscribe(data => {
       this.voyagedetails = data;
       // alert(JSON.stringify(this.voyagedetails));
        },
          error => {
            this.router.navigate(['']);
          });
  }
  updateVoyages() {

  }

}
