import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsProductCategory } from 'src/app/model/accountsproductcategory.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewaccountscategoryService {

  constructor(private http: HttpClient) { }

  private saveUrl = ShipStreetProps.API_PATH + 'accountsproductcategory/savecategory.do';

  public save(category: AccountsProductCategory) {
    return this.http.post<string>(this.saveUrl , category);
  }
}
