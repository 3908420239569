import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalcEquipmentType } from 'src/app/model/CalcEquipmentType.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class NewcalcequipmenttypeService {

  private saveUrl = ShipStreetProps.API_PATH + 'calculatorequipmenttype/saveCalcEequipmentType';

  constructor(private http: HttpClient) { }

  public saveCalcEequipmentType(equipmentType: CalcEquipmentType) {
    return this.http.post<string>(this.saveUrl , equipmentType);
  }
}
