import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruiter72',
  templateUrl: './recruiter72.component.html',
  styleUrls: ['./recruiter72.component.css']
})
export class Recruiter72Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
