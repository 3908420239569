import { CountryInfo } from './countryInfo.model';
import { Company } from './company.model';
import { CompanyEmployee } from './companyemployee.model';

export class TrackBoxCarrier {
    id: number;
    name: string;
    company: Company;
    lineCode: string;
    location: string;
    country: CountryInfo;
    createdBy: CompanyEmployee;
}
