import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { MasterPortsDepot } from 'src/app/model/masterportsdepot.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class EditdepotsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'masterdepots/getDepots';
  private saveUrl = ShipStreetProps.API_PATH + 'masterdepots/updateDepots';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';


  public getDepots(id: string) {
    return this.http.post<MasterPortsDepot>(this.appUrl, id);
  }
public updateDepots(depot: MasterPortsDepot) {
  return this.http.post<string>(this.saveUrl, depot);
}
viewCountry() {
  return this.http.get<CountryInfo[]>(this.appUrls);
}
}
