import { Candidates } from './candidate.model';
import { Company } from './company.model';
import { MarketItemCategory } from './marketItemCategory.model';
export class Marketplace {
    id: number;
    candidate: Candidates;
    company: Company;
    location: string;
    itemName: string;
    adType: string;
    datePosted: string;
    price: number;
    condition: string;
    description: string;
    summary: string;
    primaryImage: string;
    brandName: string;
    modelName: string;
    versionNumber: string;
    yom: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    bannerImage: string;
    showMyContactInfo: string;
    adItemCategory: MarketItemCategory;
    itemInStock: string;
    views: string;
    height: string;
    width: string;
    length: string;
    weight: string;
    manufacturer: string;
    specification: string;
    reviewCount: string;
    primaryImage2: string;
    primaryImage3: string;
    priceRequest: string;
    namecheck: boolean;
}

