import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContainerType } from 'src/app/model/containertype.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';


@Injectable({
  providedIn: 'root'
})
export class ContainertypesService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'containertype/findAllContainerType.do';

  getAllMyContainerTypes() {
    return this.http.get<ContainerType[]>(this.appUrl);
  }
}
