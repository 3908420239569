export class ShipStreetProps {
    //  public static readonly API_PATH = 'http://localhost:8080/shipstreet/';
    //  public static readonly AWS_IMAGE_PATH = 'https://s3-us-west-2.amazonaws.com/rigtestenvbucket/shipstreet/Files/';

    public static readonly API_PATH = 'http://Anweigh2022may-env.eba-w4ijyfhf.us-west-2.elasticbeanstalk.com:80/';
     public static readonly AWS_IMAGE_PATH = 'https://s3-us-west-2.amazonaws.com/rigstreet/shipstreet/Files/';

    // public static readonly API_PATH = 'api/';
    // public static readonly AWS_IMAGE_PATH = '/rigstreet/shipstreet/Files/';
}
