import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitefriend',
  templateUrl: './invitefriend.component.html',
  styleUrls: ['./invitefriend.component.css']
})
export class InvitefrienddumpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
