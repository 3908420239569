import { Candidates } from '../../model/candidate.model';
import { CandidateMessage } from '../../model/candidateMessage.model';
import { CandidateService } from './candidate.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ShipStreetProps } from '../../utils/rs.constants';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/utils/common.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.css']
})
export class CandidateComponent implements OnInit {
  canmessage: CandidateMessage;
  candidateId: string;
  candidate: Candidates;
  unfollowBut = false;
  showExpIcon = false;
  showme = false;

  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  id: any;

  constructor(private candidateService: CandidateService, private route: ActivatedRoute, private router: Router,
              private cookieService: CookieService, private commonService: CommonService) {
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
  }

  sessionCandidate = this.commonService.getSessionCandidate();

  ngOnInit() {
    this.canmessage = new CandidateMessage();
    this.canmessage.candidate = new Candidates();
    const id = this.route.snapshot.paramMap.get('id');
    this.candidateId = id;

    this.candidateService.candidateById(this.candidateId)
      .subscribe(data => { 
        this.candidate = data;
        this.canmessage.candidate.id = data.id;
        
     //   if(this.candidate.ownProfile==true) {
     //  console.log(this.candidate.ownProfile);
     //     this.router.navigate(['/m/editprofile']);
        //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        //     this.router.navigate(['/m/editprofile']);
        // });
     //   }
      },
        error => {
          this.router.navigate(['']);
        });
  }
  candidatefollow(candidate) {
    this.candidateService.candidatefollow(candidate)
      .subscribe(data => {
        this.candidate.followedByCandidate = data.followedByCandidate;
        this.candidate.followingCount = data.followingCount;
        this.candidate.connectionCount = data.connectionCount;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  candidateUnfollow(candidate) {
    this.candidateService.candidateUnfollow(candidate)
      .subscribe(data => {
        this.candidate.followedByCandidate = data.followedByCandidate;
        this.candidate.followingCount = data.followingCount;
        this.candidate.connectionCount = data.connectionCount;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  candidateconnect(candidate) {
    this.candidateService.candidateconnect(candidate)
      .subscribe(data => {
        this.candidate.connectionStatus = 'requestsent';// data.connectionStatus;
      },
        error => {
          this.router.navigate(['']);
        });
  }

  sendMessage() {
    // this.canmessage = {"candidate" : this.candidate.id};
    this.candidateService.sendMessage(this.canmessage)
      .subscribe(data => {
        this.cookieService.set('MSG_CNT', '' + this.sessionCandidate.newMessageCount++);
        this.showme = false;
      },
        error => {
          this.router.navigate(['']);
        });

  }

  showConfirm(event) { }

  showAdvanced(event) { }

}
