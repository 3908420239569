import { CompanyVoyage } from './../../../../model/companyvoyage.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Injectable({
  providedIn: 'root'
})
export class VoyagedetailsService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'voyage/getVoyagesbyId.do';

  getVoyageDetails(voyageId: string) {
    return this.http.post<CompanyVoyage>(this.appUrl, voyageId);
  }
}
