import { Candidates } from './candidate.model';

export class SurviceRequests {
    id: number;
    candidate: Candidates;
    title: string;
    message: string;
    ticketNumber: number;
    status: string;
    createdOn: Date;
    lastUpdatedOn: Date;
}
