import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from '../../../utils/rs.constants';
import { Candidates } from '../../../model/candidate.model';
import { CandidateAddress } from '../../../model/candidateaddress.model';

@Injectable({
  providedIn: 'root'
})
export class AddresspageService {


  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'candidate.do';
  private saveUrl = ShipStreetProps.API_PATH + 'candidateAddressAndConfirm.do';

  public getCandidateAddress() {
    return this.http.get<Candidates>(this.appUrl);
  }

  /**
   * name
   */
  public createAddress(candidateAddress: CandidateAddress) {
    return this.http.post<string>(this.saveUrl , candidateAddress);
  }
}
