import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { SurviceRequests } from 'src/app/model/survicerequests.model';

@Injectable({
  providedIn: 'root'
})
export class SurvicerequestlistService {

  constructor(private http: HttpClient) {}

  private appUrl = ShipStreetProps.API_PATH + 'helpcentre/getAllServiceRequest';

   public getAllServiceRequest() {
    return this.http.get<SurviceRequests[]>(this.appUrl);
  }
}
