import { ContainerLease } from './containerlease.model';

export class ContainerLeasingUnit {
    id: number;
    leasing: ContainerLease;
    containerNumber: string;
    gateOut: Date;
    gateIn: Date;
    staticCharge: number;
    detentionCharge: number;
    total: number;
    vatAmount: number;
    netTotal: number;
}
