import { TrackBoxContainerSupplier } from './trackboxcontainersupplier.model';
import { ContainerSupplier } from 'src/app/model/containersupplier.model';
import { TrackBoxPurchaseStatus } from './trackboxpurchasestatus.model';
import { ContainerStatus } from './containerstatus.model';
import { Company } from './company.model';
import { Containers } from './containers.model';
import { Candidates } from './candidate.model';
import { CountryInfo } from './countryInfo.model';
import { ContainerLocationType } from './containerlocationtype.model';
import { TrackBoxCarrier } from './trackboxcarrier.model';
import { MasterPortsDepot } from './masterportsdepot.model';
import { TrackboxContainerStatus } from '../trackmybox/trackboxcontainerstatus.model';
import { TrackBoxCustomers } from './trackboxcustomers.model';

export class TrackBoxContainer {
    id: number;
    company: Company;
    container: Containers;
    candidate: Candidates;
    country: CountryInfo;
    locationType: ContainerLocationType;
    port: MasterPortsDepot;
    gateIn: Date;
    gateOut: Date;
    containerStatus: TrackboxContainerStatus;
    purchaseStatus: TrackBoxPurchaseStatus;
    leaseStart: Date;
    loeaseTo: Date;
    carrier: TrackBoxCarrier;
    selectForContainerBooking: boolean;
    leaseFrom: TrackBoxContainerSupplier;
    leaseRefNo: string;
    purchasedOn: Date;
    purchasedFrom: TrackBoxContainerSupplier;
    purchaseRefNo: string;
    offHire: boolean;
    offHireTo: TrackBoxContainerSupplier;
    offHireOn: Date;
    offHirePrice: number;
    offHireLocation: CountryInfo;
    saleTo: TrackBoxCustomers;
    saleOn: Date;
    salePrice: number;
    saleLocation: CountryInfo;
    statusUpdatedOn: Date;

}
