//import { Customer } from './customer.model';
import { AccountsPaymentTerms } from './accountspaymentterms.model';
import { Customer } from './customer.model';

export class Invoice {
    id: number;
  //  customer: Customer;
    invoiceTerms: AccountsPaymentTerms;
    invoiceNumber: string;
    invoiceDate: Date;
    invoiceAmount: number;
    vatAmount: number;
    grandTotal: number;
    selectForInvoice: boolean;
    dueDate: Date;
    salesLocation: string;
    location: string;
  customer: Customer;
}
