import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Candidates } from 'src/app/model/candidate.model';
import { NewboxemployeeService } from './newboxemployee.service';

@Component({
  selector: 'app-newboxemployee',
  templateUrl: './newboxemployee.component.html',
  styleUrls: ['./newboxemployee.component.css']
})
export class NewboxemployeeComponent implements OnInit {
  trackboxemployee: Candidates;

  constructor(private newboxemployeeService: NewboxemployeeService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.trackboxemployee = new Candidates();
  }
  onSubmit() {
    this.newboxemployeeService.save(this.trackboxemployee)
      .subscribe(data => {
        this.router.navigate(['trackmybox/boxemployees']);
      },
        error => {
          this.router.navigate(['']);
        });

  }
}
