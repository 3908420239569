import { RecentpostsService } from './recentposts.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Posts } from 'src/app/model/posts.model';
import { ShipStreetProps } from 'src/app/utils/rs.constants';

@Component({
  selector: 'app-recentposts',
  templateUrl: './recentposts.component.html',
  styleUrls: ['./recentposts.component.css']
})
export class RecentpostsComponent implements OnInit {
  posts: Posts[];
  imagePath = ShipStreetProps.AWS_IMAGE_PATH;
  constructor(private recentpostsService: RecentpostsService , private router: Router) { }


  ngOnInit() {
    this.recentpostsService.getPosts()
      .subscribe( data => {
        this.posts = data;
      },
      error => {
        this.router.navigate(['']);
        });
  }

}
