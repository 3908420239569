import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipStreetProps } from 'src/app/utils/rs.constants';
import { TrackBoxAgent } from 'src/app/model/trackboxagent.model';
import { Company } from 'src/app/model/company.model';
import { CountryInfo } from 'src/app/model/countryInfo.model';
import { TrackBoxAgentSearchDTO } from 'src/app/model/trackboxagentsearchDTO.model';

@Injectable({
  providedIn: 'root'
})
export class SearchboxagentService {

  constructor(private http: HttpClient) { }

  private appUrl = ShipStreetProps.API_PATH + 'trackboxagent/saveTrackBoxAgent.do';
  private findUrl = ShipStreetProps.API_PATH + 'trackboxagent/findAllAgentCompany.do';
  private appUrls = ShipStreetProps.API_PATH + 'cntcountrycontroller/countryView.do';
  private appUrlcompany = ShipStreetProps.API_PATH + '/getAllCompanies.do';

  public saveTrackBoxAgent(trackboxagent: number) {
    // alert(JSON.stringify(trackboxagent));
    return this.http.post<TrackBoxAgent>(this.appUrl, trackboxagent);
  }

  public findAgentCompany(newtrackboxagent: TrackBoxAgentSearchDTO) {
    // alert(JSON.stringify(newtrackboxagent));
    return this.http.post<Company[]>(this.findUrl, newtrackboxagent);
  }

  viewCountry() {
    return this.http.get<CountryInfo[]>(this.appUrls);
  }

  // viewCompany() {
  //   return this.http.get<Company[]>(this.appUrlcompany);
  // }

}
